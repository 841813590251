import { Error404Page } from '@domains/index';
import { ErrorLayout } from '@components/index';
import { RouterConfig } from '..';

export const commonRoutes: RouterConfig = [
    {
        type: 'invalid',
        routes: [
            {
                component: Error404Page,
                path: '*',
            },
        ],
        layoutComponent: ErrorLayout,
    },
];
