import React from 'react';
import { BillingCycle } from '@@types/graphql/GraphqlGlobalTypes';
import { IChildsList } from '@domains/Subscription/ChooseYourPlanPage/ChooseYourPlanPage';
import { IconManager } from '@components/_universal';
import ChildrenList from '@domains/Subscription/_components/ChildrenList/ChildrenList';
import ChoosePayment from '@domains/Auth/_components/ChoosePayment/ChoosePayment';
import {
    ChildrenListHeader,
    ChildrenListIconWrapper,
    ChildrenTitle,
    ChoosePaymentWrapper,
    ChoosePlanChildren,
    ChoosePlanColumns,
    ChoosePlanHeader,
    ChoosePlanReminder,
    ChoosePlanTexts,
    ChooseTrialLeftInformation,
    DesktopSubscriptionPageWrapper,
    Discount,
    StyledDescription,
    StyledTip,
    StyledTitle,
} from './DesktopChooseYourPlanPage.styled';

interface IProps {
    checkedChildren: number;
    childrenList: IChildsList[];
    handleChange: (id: number, value: boolean) => void;
    handleSubscribe: (billingCycle: BillingCycle) => void;
    setAddChildModal: (state: boolean) => void;
}

const DesktopSubscriptionPage: React.FC<IProps> = ({
    checkedChildren,
    childrenList,
    handleChange,
    handleSubscribe,
    setAddChildModal,
}) => {
    return (
        <DesktopSubscriptionPageWrapper>
            <ChooseTrialLeftInformation variant="big-green" withoutAction />
            <ChoosePlanHeader>
                <ChoosePlanTexts>
                    <StyledTitle color="text_white" fontWeight="semiBold">
                        Choose your plan
                    </StyledTitle>
                    <StyledDescription
                        color="text_white"
                        fontSize="l"
                        fontWeight="semiBold"
                    >
                        To stay with us after the free trial period, subscribe
                        to our platform.
                    </StyledDescription>
                </ChoosePlanTexts>
                <ChoosePlanReminder>
                    Remember, with the annual plan you
                    <Discount>Get 4 months free</Discount>
                </ChoosePlanReminder>
            </ChoosePlanHeader>
            <ChoosePlanColumns>
                <ChoosePlanChildren>
                    <ChildrenListHeader>
                        <ChildrenTitle color="text_white" fontWeight="semiBold">
                            Your children accounts:
                        </ChildrenTitle>
                        <ChildrenListIconWrapper>
                            <IconManager
                                cursor="pointer"
                                name="InfoIcon"
                                size={38}
                            />
                            <StyledTip>
                                If you want to cancel any account uncheck it and
                                see the proposed calculation for monthly and
                                annually plan.
                            </StyledTip>
                        </ChildrenListIconWrapper>
                    </ChildrenListHeader>

                    {childrenList && (
                        <ChildrenList
                            childrenList={childrenList}
                            handleChange={handleChange}
                            setAddChildModal={setAddChildModal}
                        />
                    )}
                </ChoosePlanChildren>
                <ChoosePaymentWrapper>
                    <ChoosePayment
                        buttonType={'secondary'}
                        handleSubscribe={handleSubscribe}
                        iconFill={['border']}
                        readOnlyChildren={checkedChildren}
                    >
                        Monthly
                    </ChoosePayment>
                    <ChoosePayment
                        currentValue={320}
                        handleSubscribe={handleSubscribe}
                        paymentType={BillingCycle.YEAR}
                        readOnlyChildren={checkedChildren}
                    >
                        Annual
                    </ChoosePayment>
                </ChoosePaymentWrapper>
            </ChoosePlanColumns>
        </DesktopSubscriptionPageWrapper>
    );
};

export default DesktopSubscriptionPage;
