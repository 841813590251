import { gql, useLazyQuery } from '@apollo/client';
import {
    GetLinkForActivityMaterialsQuery,
    GetLinkForActivityMaterialsQueryVariables,
} from '@@types/graphql/GetLinkForActivityMaterialsQuery';

const GET_LINK_FOR_ACTIVITY_MATERIALS_QUERY = gql`
    query GetLinkForActivityMaterialsQuery($id: ID!) {
        getLinkForActivityMaterials(id: $id)
    }
`;

export default (contentId: string) =>
    useLazyQuery<
        GetLinkForActivityMaterialsQuery,
        GetLinkForActivityMaterialsQueryVariables
    >(GET_LINK_FOR_ACTIVITY_MATERIALS_QUERY, {
        variables: {
            id: contentId,
        },
        fetchPolicy: 'network-only',
    });
