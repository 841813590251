import React, { useState } from 'react';
import SearchForm from '@components/__forms/SearchForm/SearchForm';
import { useSearchContentQuery } from '@state/lists/queries';
import {
    SearchImage,
    SearchModalTitle,
    SearchModalWrapper,
    SearchResults,
} from './SearchModal.styled';
import EmptyStateSearch from '@components/_modals/SearchModal/EmptyStateSearch/EmptyStateSearch';
import Paginate from '@components/_universal/Paginate/Paginate';
import parseContentToCards from '@utils/parseContentToCards';

const SEARCH_LIMIT = 10;

export interface IProps {}

const SearchModal: React.FC<IProps> = () => {
    const [offset, setOffset] = useState(0);
    const [query, setQuery] = useState('');
    const [paginateLoading, setPaginateLoading] = useState(false);

    const [
        searchQuery,
        { data, called, loading, fetchMore },
    ] = useSearchContentQuery();

    const isVisible = called && !loading;
    const dataLength = data?.searchForContent?.length ?? 0;

    return (
        <SearchModalWrapper>
            <SearchModalTitle color={'text_white'} fontFamily={'sketched'}>
                What are you looking for?
            </SearchModalTitle>
            <SearchForm
                onSearch={(searchValue) => {
                    setQuery(searchValue);
                    setOffset(0);

                    searchQuery({
                        variables: {
                            query: searchValue,
                            paginationInput: {
                                take: SEARCH_LIMIT,
                                offset: 0,
                            },
                        },
                    });
                }}
            />
            {isVisible && !!dataLength && (
                <Paginate
                    loading={paginateLoading}
                    queryLoading={loading}
                    hasNextPage={!!(dataLength && offset < dataLength)}
                    onLoadMore={() => {
                        setPaginateLoading(true);

                        fetchMore?.({
                            variables: {
                                query: query,
                                paginationInput: {
                                    take: SEARCH_LIMIT,
                                    offset: dataLength,
                                },
                            },
                        }).then(() => {
                            setOffset(dataLength);
                            setPaginateLoading(false);
                        });
                    }}
                >
                    <SearchResults
                        cards={parseContentToCards(
                            data?.searchForContent || [],
                        )}
                        isDifferentHover
                    />
                </Paginate>
            )}

            {!dataLength && isVisible && <EmptyStateSearch />}

            {!dataLength && !isVisible && (
                <SearchImage name="SearchingMan" size={386} sizeY={300} />
            )}
        </SearchModalWrapper>
    );
};

export default SearchModal;
