import React from 'react';
import { StyledHeaderWrapper } from './ParentHeader.styled';
import { useHistory } from 'react-router-dom';
import Notifications from '@components/Notifications/Notifications';
import paths from '@shared/paths';
import useHeader from '@components/_global/Header/useHeader';
import {
    AccountIcon,
    NotificationWrapper,
    NotificationIcon,
    HeaderTrialLeftInformation,
} from '@components/_global/Header/Header.styled';

interface IProps {
    className?: string;
    isTransparent?: boolean;
    trialIcon?: boolean;
}

const Header: React.FC<IProps> = ({ className, trialIcon }) => {
    const {
        accountActive,
        isUnread,
        notificationsActive,
        onClose,
        refIcon,
        refNotifications,
        setNotificationsActive,
    } = useHeader('/parent/account/*');

    const history = useHistory();

    return (
        <StyledHeaderWrapper {...{ className }}>
            <AccountIcon
                activeFill={['text_white', 'border', 'primary']}
                activeStroke={3}
                cursor="pointer"
                hoverFill={['icons_hover']}
                isActive={accountActive}
                name="CircleProfile"
                onClick={() => history.push(paths.account_parent)}
                size={40}
            />

            <NotificationWrapper ref={refIcon} unreadNotifications={isUnread}>
                <NotificationIcon
                    activeFill={['text_white', 'border']}
                    cursor="pointer"
                    hoverFill={['icons_hover']}
                    isActive={notificationsActive}
                    name="CircleBell"
                    onClick={() =>
                        notificationsActive
                            ? onClose()
                            : setNotificationsActive(true)
                    }
                    size={40}
                />
            </NotificationWrapper>
            {notificationsActive && (
                <Notifications
                    linkToSettings={paths.account_notification_settings_parent}
                    ref={refNotifications}
                />
            )}
            {trialIcon && <HeaderTrialLeftInformation variant="default" />}
        </StyledHeaderWrapper>
    );
};

export default Header;
