import React from 'react';
import { CardTypes, IMosaicCard } from '@@types/CommonTypes';
import LiveExperienceCard from '@components/_universal/Cards/LiveExperienceCard/LiveExperienceCard';
import SplitCard from '@components/_universal/Cards/SplitCard/SplitCard';
import TextBoxCard from '@components/_universal/Cards/TextBoxCard/TextBoxCard';
import { useHistory } from 'react-router';

const getCardComponent = (
    card: IMosaicCard,
    isDifferentHover?: boolean,
): Record<CardTypes, React.ReactNode> => {
    return {
        COURSE: (
            <SplitCard
                featured={card.featured}
                image={card.image}
                isDifferentHover={!!isDifferentHover}
                link={card.url}
                text={card.text}
                width="100%"
            />
        ),
        LIVE_EXPERIENCE: (
            <LiveExperienceCard
                allSlots={card.availableSlots ?? 0}
                availableSlots={card.availableSlots ?? 0}
                featured={card.featured}
                image={card.image}
                isDifferentHover={!!isDifferentHover}
                link={card.url}
                startDate={card.startDate}
                text={card.text}
                width="100%"
            />
        ),
        STANDALONE_VIDEO: (
            <TextBoxCard
                featured={card.featured}
                image={card.image}
                isDifferentHover={!!isDifferentHover}
                link={card.url}
                text={card.text}
                type="STANDALONE_VIDEO"
                width="100%"
            />
        ),
    };
};

export default getCardComponent;
