import styled, { css } from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const EmptyDataSearchWrapper = styled.div(
    ({ theme: { colors, borderRadius } }) => css`
        align-items: center;
        background: ${colors.background_secondary};
        border-radius: ${borderRadius.button}px;
        border: 1px solid ${colors.stroke};
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 64px 30px 88px;
        position: relative;
    `,
);

export const BigText = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 16px;
    `,
);

export const SmallText = styled(DefaultText)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 0;
    `,
);

export const PencilsRulerOrnament = styled(IconManager)`
    bottom: -30px;
    position: absolute;
    right: 15%;
`;
