import React from 'react';
import { GetCourseQuery_getCourse_homework } from '@@types/graphql/GetCourseQuery';
import { GetLiveExperienceQuery_getLiveExperience_homework } from '@@types/graphql/GetLiveExperienceQuery';
import { HOMEWORK_FILE_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { useDeleteHomeworkMutation } from '@state/files/mutations';
import { useHomeworkFileQuery } from '@state/files/queries';
import { useParams } from 'react-router-dom';
import MentorFeedbackCard from '@components/_universal/SubmitWork/MentorFeedbackCard/MentorFeedbackCard';
import UploadedFileCard from '@components/_universal/SubmitWork/UploadedFileCard/UploadedFileCard';
import {
    InnerWrapper,
    MentorsFeedback,
    SubmitWorkWrapper,
    Subtitle,
    Title,
    UploadedFiles,
} from './SubmitWork.styled';

interface IProps {
    children: React.ReactNode;
    contentType: 'Course' | 'LiveExperience';
    getQueryLoading: boolean;
    homework?:
        | GetLiveExperienceQuery_getLiveExperience_homework
        | GetCourseQuery_getCourse_homework
        | null;
}

const SubmitWork: React.FC<IProps> = ({
    children,
    contentType,
    getQueryLoading,
    homework,
}) => {
    const { courseId, liveExperienceId } = useParams<{
        courseId: string;
        liveExperienceId: string;
    }>();

    const homeworkFile = homework?.files.find(
        (item) => item.type === HOMEWORK_FILE_TYPE.USER_HOMEWORK,
    );
    const feedbackFile = homework?.files.find(
        (item) => item.type === HOMEWORK_FILE_TYPE.FEEDBACK,
    );
    const [getFile] = useHomeworkFileQuery();
    const [
        deleteHomework,
        { loading: deleteLoading },
    ] = useDeleteHomeworkMutation(
        contentType === 'Course' ? courseId : liveExperienceId,
        contentType,
    );

    const handleRemove = () => {
        homework?.id &&
            deleteHomework({
                variables: {
                    homeworkId: homework.id,
                },
            });
    };

    return (
        <SubmitWorkWrapper>
            <Title>Submit your work</Title>
            {getQueryLoading ? null : (
                <>
                    {homework && homeworkFile?.filename ? (
                        <InnerWrapper>
                            <UploadedFiles>
                                <Subtitle>Uploaded file</Subtitle>
                                <UploadedFileCard
                                    canRemove={!homework?.mentorFeedback}
                                    fileName={homeworkFile?.filename}
                                    getFile={() =>
                                        getFile({
                                            variables: {
                                                homeworkId: homework.id,
                                                type:
                                                    HOMEWORK_FILE_TYPE.USER_HOMEWORK,
                                            },
                                        })
                                    }
                                    loading={deleteLoading}
                                    onRemove={handleRemove}
                                    uploadedDate={homework?.createdAt}
                                />
                            </UploadedFiles>
                            <MentorsFeedback>
                                <Subtitle>Mentor's feedback</Subtitle>
                                <MentorFeedbackCard
                                    content={homework?.mentorFeedback ?? ''}
                                    fileName={feedbackFile?.filename}
                                    getFile={() =>
                                        getFile({
                                            variables: {
                                                homeworkId: homework.id,
                                                type:
                                                    HOMEWORK_FILE_TYPE.FEEDBACK,
                                            },
                                        })
                                    }
                                    mentorName={
                                        homework?.feedbackBy?.name ?? ''
                                    }
                                    uploadedDate={homework?.feedbackGivenAt}
                                />
                            </MentorsFeedback>
                        </InnerWrapper>
                    ) : (
                        children
                    )}
                </>
            )}
        </SubmitWorkWrapper>
    );
};

export default SubmitWork;
