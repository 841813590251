import styled from 'styled-components';

export const SearchBarWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 526px;
`;

export const StyledFormWrapper = styled.form`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

export const SearchWrapper = styled.div`
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
`;
