import styled from 'styled-components';
import AccountNavigation from '@domains/Child/Account/_components/AccountNavigation/AccountNavigation';
import { IconManager } from '@components/_universal';
import { SubTitle } from '@components/_universal/Typography.styled';

export const AccountSubLayoutWrapper = styled.div`
    display: grid;
    gap: 0 4px;
    grid-template-areas: 'title title' 'navigation content';
    grid-template-columns: 340px 1fr;
    grid-template-rows: 110px 1fr;
    height: 100%;
`;

export const PageTitle = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    grid-area: title;
    padding-left: 100px;
    padding-top: 34px;
`;

export const SubNavigationBar = styled(AccountNavigation)`
    grid-area: navigation;
`;

export const Content = styled.div`
    background: ${({ theme }) => theme.colors.background_primary};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.circle}px;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.circle}px;
    grid-area: content;
    padding: 80px 95px 0;
    position: relative;
    z-index: 0;
`;

export const BackgroundDecorator = styled(IconManager)`
    position: absolute;
    right: 55px;
    top: 55px;
    z-index: -9;
`;
