import React from 'react';
import { externalLinks } from '@shared/paths';
import {
    PageHeader,
    PageText,
    PageTitle,
    SectionTitle,
} from '@domains/Teacher/Account/AccountTeacherPage/AccountTeacherPage.styled';
import {
    ActionButton,
    ContentWrapper,
    HelpFaqTeacherPageWrapper,
    SectionText,
    SectionWrapper,
} from './HelpFaqTeacherPage.styled';

interface IProps {}

const HelpFaqTeacherPage: React.FC<IProps> = () => {
    return (
        <HelpFaqTeacherPageWrapper>
            <PageHeader>
                <PageTitle>Help {'&'} FAQ</PageTitle>
            </PageHeader>
            <PageText>
                Feel free to talk with our support. To do it click on the chat
                widget in the bottom right corner of the screen.
            </PageText>
            <SectionWrapper>
                <ContentWrapper>
                    <SectionTitle>FAQ</SectionTitle>
                    <SectionText>
                        Explore our Frequently Asked Questions and find answers
                        to them!
                    </SectionText>
                </ContentWrapper>
                <ActionButton
                    buttonType={'secondary'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        isLeft: true,
                        type: 'RedirectIcon',
                    }}
                    onPress={() =>
                        window.open(externalLinks.help_faq_teacher, '_blank')
                    }
                    size={'small'}
                >
                    Check faq
                </ActionButton>
            </SectionWrapper>
        </HelpFaqTeacherPageWrapper>
    );
};

export default HelpFaqTeacherPage;
