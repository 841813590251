import React from 'react';
import { useHistory } from 'react-router-dom';
import paths from '@shared/paths';
import ProgressCarousel from '@domains/Child/Courses/_components/Progress/ProgressCarousel/ProgressCarousel';
import useOngoingContentQuery from '@state/lists/queries/useOngoingContentQuery';
import {
    ButtonWrapper,
    Description,
    LoupeIcon,
    ProgressWrapper,
    StyledButton,
    StyledTitle,
} from './Progress.styled';

interface IProps {
    smallPadding?: boolean;
}

const Progress: React.FC<IProps> = ({ smallPadding }) => {
    const history = useHistory();
    const { data } = useOngoingContentQuery();
    const ongoingContent = data?.getOngoingContent;
    const isEmptyState = !ongoingContent?.length;

    return (
        <ProgressWrapper smallPadding={smallPadding}>
            <StyledTitle>Your progress</StyledTitle>
            {isEmptyState ? (
                <>
                    <Description>
                        You haven't started any courses yet! Head over to the
                        library to get started.
                    </Description>
                    <ButtonWrapper>
                        <StyledButton
                            onPress={() => history.push(paths.library)}
                        >
                            Browse library
                        </StyledButton>
                        <LoupeIcon name={'Loupe'} size={32} sizeY={46} />
                    </ButtonWrapper>
                </>
            ) : (
                <ProgressCarousel />
            )}
        </ProgressWrapper>
    );
};

export default Progress;
