import React, { useState } from 'react';
import { IconManager } from '@components/_universal';
import { IStudentForm } from '@domains/Teacher/Students/_forms/StudentForm/StudentForm';
import { Nullable } from '@@types/CommonTypes';
import { useUpdateStudentAccount } from '@state/students/mutations';
import Avatar from '@components/_universal/Avatar/Avatar';
import EditStudentModal from '@domains/Teacher/Students/_modals/EditStudentModal/EditStudentModal';
import {
    ClassDetailsElement,
    ClassDetailsText,
    ClassDetials,
    DetailsCardWrapper,
    EditButton,
    Email,
    StudentName,
} from './DetailsCard.styled';

export interface IStudent {
    avatar?: Nullable<number>;
    class?: string;
    email: string;
    id: number;
    name: string;
    surname: string;
    year?: number;
}

export interface IProps {
    studentData: IStudent;
}

const DetailsCard: React.FC<IProps> = ({ studentData }) => {
    const [editModal, setEditModal] = useState(false);

    const [updateStudentAccount] = useUpdateStudentAccount(() =>
        setEditModal(false),
    );

    const handleEditStudent = (data: IStudentForm) => {
        updateStudentAccount({
            variables: {
                input: {
                    ...data,
                    className: data.className?.value as string,
                    studentId: studentData.id,
                    year: Number(data.year?.value),
                    email:
                        data.email === studentData.email
                            ? undefined
                            : data.email,
                },
            },
        });
    };

    return (
        <>
            {editModal && (
                <EditStudentModal
                    defaultData={{
                        ...studentData,
                        className: {
                            label: studentData.class as string,
                            value: studentData.class as string,
                        },
                        year: {
                            label: `${studentData.year as number}`,
                            value: `${studentData.year as number}`,
                        },
                    }}
                    onClose={() => setEditModal(false)}
                    onSubmit={handleEditStudent}
                />
            )}
            <DetailsCardWrapper>
                <EditButton onClick={() => setEditModal(true)}>
                    <IconManager name="IconEdit" size={24} />
                </EditButton>
                <Avatar avatar={studentData.avatar} iconSize={76} />
                <StudentName>
                    {studentData.name} {studentData.surname}
                </StudentName>
                <Email>{studentData.email}</Email>
                <ClassDetials>
                    <ClassDetailsElement>
                        <ClassDetailsText>Class:</ClassDetailsText>
                        <ClassDetailsText>{studentData.class}</ClassDetailsText>
                    </ClassDetailsElement>
                    <ClassDetailsElement>
                        <ClassDetailsText>Year:</ClassDetailsText>
                        <ClassDetailsText>{studentData.year}</ClassDetailsText>
                    </ClassDetailsElement>
                </ClassDetials>
            </DetailsCardWrapper>
        </>
    );
};

export default DetailsCard;
