import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import { clearChildId, setChildId } from './actions';

export default createReducer(initialState)
    .handleAction(setChildId, (state, { payload }) => ({
        ...state,
        childId: payload,
    }))
    .handleAction(clearChildId, (state) => ({ ...state, childId: undefined }));
