import styled from 'styled-components';
import { IconManager } from '@components/_universal';

export const AvatarWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`;

export const AvatarImageIcon = styled(IconManager)`
    cursor: ${({ theme }) => theme.cursors.pointer};
`;

export const AvatarSelectedIcon = styled(IconManager)`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-51%, -51%);
`;
