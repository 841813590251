export type SignupModule = {
    correctAccountData: boolean;
    isAdminAsTeacher: boolean;
    isFirstLogin: boolean;
    skipSetPassword: boolean;
    successPayment: boolean;
};

export const initialState: SignupModule = {
    correctAccountData: false,
    isAdminAsTeacher: false,
    isFirstLogin: false,
    skipSetPassword: false,
    successPayment: false,
};
