import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { Button, IconManager } from '@components/_universal';

export const ExperiencesWrapper = styled.div`
    padding: 80px 0 100px 100px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
`;

export const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
`;

export const LiveIcon = styled(IconManager)`
    margin-right: 32px;
`;

export const Description = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 32px;
    max-width: 544px;
`;

export const ButtonWrapper = styled.div``;

export const StyledButton = styled(Button)``;
