import { gql, useMutation } from '@apollo/client';
import {
    CreateCheckoutSessionMutation,
    CreateCheckoutSessionMutationVariables,
} from '@@types/graphql/CreateCheckoutSessionMutation';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { loadStripe } from '@stripe/stripe-js';

const CREATE_CHECKOUT_SESSION_MUTATION = gql`
    mutation CreateCheckoutSessionMutation($input: CreateCheckoutSession!) {
        createCheckoutSession(input: $input)
    }
`;

export default (publishableKey: string) =>
    useMutation<
        CreateCheckoutSessionMutation,
        CreateCheckoutSessionMutationVariables
    >(CREATE_CHECKOUT_SESSION_MUTATION, {
        onCompleted: async (payload) => {
            const stripe = await loadStripe(publishableKey);
            stripe?.redirectToCheckout({
                sessionId: payload.createCheckoutSession,
            });
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
