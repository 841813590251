import React from 'react';
import {
    LoginLayout,
    ResetPasswordLayout,
    SignupLayout,
} from '@components/index';
import {
    AuthorizationTokenPage,
    CreateAccountPage,
    CreatePasswordPage,
    ForgotPasswordPage,
    LoginLinkPage,
    LoginPasswordPage,
    MagicLinkSentPage,
    ResetLinkSentPage,
    ResetPasswordPage,
    SignupCreatePage,
    SignupEmailVerifiedPage,
    SignupFailedPaymentPage,
    SignupPaymentProcessingPage,
    SignupPricingPage,
    SignupRegisterPage,
    SignupRegisterSuccessfulPage,
    SignupSuccessfulPaymentPage,
} from '@domains/index';
import { RouterConfig } from '..';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import paths from '@shared/paths';
import renderRedirect from '@services/RenderRedirect';

export const authRoutes: RouterConfig = [
    // Authorization Token
    {
        routes: [
            {
                component: AuthorizationTokenPage,
                exact: true,
                path: paths.authorization_token,
            },
        ],
        layoutComponent: LoginLayout,
    },

    // Login Layout
    {
        routes: [
            {
                component: LoginLinkPage,
                exact: true,
                path: paths.link_login,
            },
            {
                component: LoginPasswordPage,
                exact: true,
                path: paths.login,
            },
            {
                component: ForgotPasswordPage,
                exact: true,
                path: paths.forgot_password,
            },
        ],
        layoutComponent: LoginLayout,
    },

    // Signup Layout
    {
        routes: [
            {
                component: renderRedirect(paths.signup_pricing),
                exact: true,
                path: paths.signup,
            },
            {
                component: SignupPricingPage,
                exact: true,
                path: paths.signup_pricing,
            },
            {
                component: SignupCreatePage,
                path: paths.signup_create,
                subRoutes: {
                    routes: [
                        {
                            component: renderRedirect(
                                paths.signup_create_account,
                            ),
                            exact: true,
                            path: paths.signup_create,
                        },
                        {
                            component: CreateAccountPage,
                            exact: true,
                            path: paths.signup_create_account,
                        },
                        {
                            component: CreatePasswordPage,
                            exact: true,
                            path: paths.signup_create_password,
                        },
                    ],
                    layoutComponent: React.Fragment,
                },
            },
            {
                component: SignupRegisterSuccessfulPage,
                exact: true,
                path: paths.signup_register_success,
            },
            {
                component: MagicLinkSentPage,
                exact: true,
                path: paths.link_sent,
            },
        ],
        layoutComponent: SignupLayout,
    },

    // Reset password Layout
    {
        routes: [
            {
                component: ResetPasswordPage,
                exact: true,
                path: paths.reset_password,
            },
            {
                component: ResetLinkSentPage,
                exact: true,
                path: paths.reset_link_sent,
            },
        ],
        layoutComponent: ResetPasswordLayout,
    },

    // Signup Parent login Layout
    {
        allowFor: USER_TYPE.PARENT,
        isPrivate: true,
        type: 'signUpProcess',
        routes: [
            {
                component: SignupRegisterPage,
                exact: true,
                path: paths.signup_register,
            },
            {
                component: SignupEmailVerifiedPage,
                exact: true,
                path: paths.signup_verified,
            },
        ],
        layoutComponent: SignupLayout,
    },
    {
        allowFor: USER_TYPE.PARENT,
        isPrivate: true,
        routes: [
            {
                component: SignupPaymentProcessingPage,
                exact: true,
                path: paths.signup_payment_processing,
            },
            {
                component: SignupSuccessfulPaymentPage,
                exact: true,
                path: paths.signup_success,
            },
            {
                component: SignupFailedPaymentPage,
                exact: true,
                path: paths.signup_failed,
            },
        ],
        layoutComponent: SignupLayout,
    },
];
