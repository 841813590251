import TextBoxCard from '@components/_universal/Cards/TextBoxCard/TextBoxCard';
import styled from 'styled-components';

export const SwiperWrapper = styled.div`
    overflow: hidden;
    padding-top: 16px;
    width: calc(100vw - 200px);
`;

export const StyledTextBoxCard = styled(TextBoxCard)`
    width: 255px;
`;
