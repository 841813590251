import React from 'react';
import { ColorType } from '@@types/CommonTypes';
import { ProgressBarWrapper, ProgressBarContent } from './ProgressBar.styled';

export interface IProps {
    backgroundColor?: ColorType;
    className?: string;
    contentColor?: ColorType;
    progress: number;
}

const ProgressBar: React.FC<IProps> = ({
    backgroundColor,
    className,
    contentColor,
    progress,
}) => {
    return (
        <ProgressBarWrapper color={backgroundColor}>
            <ProgressBarContent
                className={className}
                color={contentColor}
                progress={progress}
            />
        </ProgressBarWrapper>
    );
};

export default ProgressBar;
