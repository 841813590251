import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';
import { breakpoints } from '@shared/constants';

export const SignupSuccessfulPaymentWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;

    @media (max-width: ${breakpoints.sm}px) {
        flex-direction: column;
    }
`;

export const PaymentSuccessIcon = styled(IconManager)`
    @media (max-width: ${breakpoints.md}px) {
        font-size: 300px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        font-size: 190px;
    }
`;

export const TextWrapper = styled.div`
    margin-left: 32px;

    @media (max-width: ${breakpoints.sm}px) {
        margin: 24px auto 0;
    }
`;

export const InformationText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 16px;
    max-width: 550px;

    @media (max-width: ${breakpoints.sm}px) {
        text-align: center;
        font-size: ${({ theme }) => theme.fontSizes.s}px;
    }
`;

export const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.xxl}px;
        text-align: center;
    }
`;
