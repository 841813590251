import React, { useCallback, useState } from 'react';
import { IconManager } from '@components/_universal';
import { useDropzone } from 'react-dropzone';
import ProgressBar from '@components/_universal/ProgressBar/ProgressBar';
import {
    UploadAreaHighlightedText,
    UploadAreaInfoText,
    UploadAreaInput,
    UploadAreaText,
    UploadAreaWrapper,
    UploadProgressFileName,
    UploadProgressInfo,
    UploadProgressInfoText,
    UploadProgressValue,
    UploadProgressWrapper,
} from './UploadArea.styled';

interface IProps {
    accept?: string;
    maxSize?: number;
    onChange: (file: File) => void;
    progress?: string;
}

const UploadArea: React.FC<IProps> = ({
    accept,
    maxSize,
    onChange,
    progress,
}) => {
    const [fileName, setFileName] = useState('');

    const onDrop = useCallback(
        (droppedFiles: File[]) => {
            if (!droppedFiles.length) return;
            setFileName(droppedFiles[0].name);
            onChange(droppedFiles[0]);
        },
        [onChange],
    );

    const { getInputProps, getRootProps } = useDropzone({
        accept,
        maxSize,
        onDrop,
    });

    return (
        <UploadAreaWrapper>
            {progress ? (
                <UploadProgressWrapper>
                    <UploadProgressInfo>
                        <UploadProgressFileName>
                            {fileName}
                        </UploadProgressFileName>
                        <UploadProgressValue>{progress}%</UploadProgressValue>
                    </UploadProgressInfo>
                    <ProgressBar progress={parseInt(progress)} />
                    <UploadProgressInfoText>
                        Uploading...
                    </UploadProgressInfoText>
                </UploadProgressWrapper>
            ) : (
                <UploadAreaInput {...getRootProps()}>
                    <input {...getInputProps()} />
                    <IconManager name={'Upload'} size={24} />
                    <UploadAreaText as={'span'}>
                        <UploadAreaHighlightedText>
                            Upload{' '}
                        </UploadAreaHighlightedText>
                        {' your work'}
                    </UploadAreaText>
                    <UploadAreaInfoText>
                        (max. file size 20 MB)
                    </UploadAreaInfoText>
                </UploadAreaInput>
            )}
        </UploadAreaWrapper>
    );
};

export default UploadArea;
