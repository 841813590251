import React, { useEffect } from 'react';
import { useGetLinkForActivityMaterialsQuery } from '@state/files/queries';
import { useGetLiveExperienceQuery } from '@state/liveExperience/queries';
import { useParams } from 'react-router-dom';
import filesize from 'filesize';
import {
    ActivityMaterialsWrapper,
    DownloadIcon,
    EmptyText,
    FileName,
    FileSize,
    GhostIcon,
    MaterialItem,
    MaterialsBox,
    MaterialsList,
    StyledBigHeader,
} from './ActivityMaterials.styled';

interface IProps {
    isEmpty?: boolean;
}

const ActivityMaterials: React.FC<IProps> = ({ isEmpty }) => {
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();
    const { data } = useGetLiveExperienceQuery(liveExperienceId);

    const activityMaterials = data?.getLiveExperience.activityMaterials;

    const [
        getActivityMaterials,
        { data: activityMaterialData },
    ] = useGetLinkForActivityMaterialsQuery(liveExperienceId);

    useEffect(() => {
        activityMaterialData &&
            window.open(activityMaterialData.getLinkForActivityMaterials);
    }, [activityMaterialData]);

    return (
        <ActivityMaterialsWrapper>
            {activityMaterials?.filename && (
                <>
                    <StyledBigHeader>Activity materials</StyledBigHeader>
                    <MaterialsBox>
                        {isEmpty ? (
                            <>
                                <GhostIcon
                                    name={'Ghost'}
                                    size={50}
                                    sizeY={47}
                                />
                                <EmptyText>
                                    All materials will be available after the
                                    reservation.
                                </EmptyText>
                            </>
                        ) : (
                            <MaterialsList>
                                <MaterialItem
                                    onClick={() => getActivityMaterials()}
                                >
                                    <DownloadIcon
                                        name={'DownloadIcon'}
                                        size={24}
                                    />
                                    <FileName>
                                        {activityMaterials.filename}
                                    </FileName>
                                    <FileSize>
                                        {activityMaterials.filesize &&
                                            filesize(
                                                activityMaterials.filesize,
                                                {
                                                    round: 2,
                                                },
                                            )}
                                    </FileSize>
                                </MaterialItem>
                            </MaterialsList>
                        )}
                    </MaterialsBox>
                </>
            )}
        </ActivityMaterialsWrapper>
    );
};

export default ActivityMaterials;
