import { IUploadStudentsSummaryTableItem } from '@domains/Teacher/UploadStudents/UploadStudentsSummaryPage/_components/UploadStudentsSummaryList/UploadStudentsSummaryList';
import { IUploadStudentsTableItem } from '@domains/Teacher/UploadStudents/UploadStudentsPage/_components/UploadStudentsList/UploadStudentsList';

export type UploadCSVModule = {
    errorStudentsList: IUploadStudentsSummaryTableItem[];
    studentsList: IUploadStudentsTableItem[];
};

export const initialState: UploadCSVModule = {
    errorStudentsList: [],
    studentsList: [],
};
