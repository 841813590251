import { IMosaicCard } from '@@types/CommonTypes';

type IElementsType = IMosaicCard[];

export enum ContentType {
    liveExperience = 'LIVE_EXPERIENCE',
    course = 'COURSE',
    video = 'STANDALONE_VIDEO',
}

interface IGroupedData {
    liveExperiences: IElementsType;
    courses: IElementsType;
    videos: IElementsType;
}

interface IPreparedData {
    liveExperiences: IElementsType;
    courses: IElementsType;
    videos: Array<IElementsType>;
}

const getMaxLength = (data: IPreparedData) => {
    return Math.max(
        data.courses.length,
        data.liveExperiences.length,
        data.videos.length,
    );
};

const pairElements = (elements: IElementsType) => {
    return elements.reduce((result, value, index, array) => {
        if (index % 2 === 0) result.push(array.slice(index, index + 2));
        return result;
    }, [] as IElementsType[]);
};

const groupData = (elements: IElementsType): IPreparedData => {
    const groupedData: IGroupedData = {
        liveExperiences: [],
        courses: [],
        videos: [],
    };

    elements.map((data) => {
        if (data.type === ContentType.course) {
            groupedData.courses.push(data);
        }
        if (data.type === ContentType.video) {
            groupedData.videos.push(data);
        }
        if (data.type === ContentType.liveExperience) {
            groupedData.liveExperiences.push(data);
        }
    });

    return { ...groupedData, videos: pairElements(groupedData.videos) };
};

function parseRepetableDisplayData(elements: IElementsType) {
    const data = groupData(elements);

    const parsedData = [];

    for (let i = 0; i < getMaxLength(data); i++) {
        if (data.courses[i]) parsedData.push([data.courses[i]]);
        if (data.videos[i]) parsedData.push(data.videos[i]);
        if (data.liveExperiences[i]) parsedData.push([data.liveExperiences[i]]);
    }
    return parsedData;
}

export default parseRepetableDisplayData;
