import styled from 'styled-components';
import { BigHeader } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const StepperWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    padding: 0 12px;
`;

export const StepperButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: ${({ theme }) => theme.cursors.pointer};
    flex-shrink: 0;
    outline: none;
    padding: 4px 2px;
`;

export const StepperIcon = styled(IconManager)``;

export const StepperValue = styled(BigHeader)`
    flex-grow: 1;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
    text-align: center;
`;
