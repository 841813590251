import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const FooterWrapper = styled.div<{
    isWhiteText?: boolean;
    hideElement?: boolean;
}>(
    ({ hideElement }) => css`
        align-items: center;
        display: ${hideElement ? 'none' : 'flex'};
        justify-content: space-between;
        padding: 0 100px;
    `,
);

export const FooterLogo = styled.div``;

export const BackpackIcon = styled(IconManager)<{ isWhiteText?: boolean }>`
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
`;

export const FooterText = styled(DefaultText)<{ isWhiteText?: boolean }>(
    ({ isWhiteText, theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${isWhiteText ? colors.text_white : colors.text};
        font-size: ${fontSizes.xs}px;
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 0;
        margin-top: 14px;
        position: relative;
    `,
);
