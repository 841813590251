import React from 'react';
import { JoinBoxWrapper, MainText, StyledButton, Text } from './JoinBox.styled';
import { useParams } from 'react-router-dom';
import { useGetLiveExperienceQuery } from '@state/liveExperience/queries';

interface IProps {}

const JoinBox: React.FC<IProps> = () => {
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();
    const { data } = useGetLiveExperienceQuery(liveExperienceId);
    return (
        <JoinBoxWrapper>
            <MainText>
                Keep your eye on the timer above. Not too long to go until your
                Live Experience begins! When the time is right hit the join
                button below to meet your facilitator and get stuck in.
            </MainText>
            <Text>
                Remember, we will be recording the class so keep your video and
                audio feeds appropriate.
            </Text>
            <StyledButton
                buttonType={'primary'}
                onPress={() => window.open(data?.getLiveExperience.link)}
            >
                Join
            </StyledButton>
        </JoinBoxWrapper>
    );
};

export default JoinBox;
