import React from 'react';
import { IBadge } from '@@types/CommonTypes';
import { BadgeWrapper, BadgeText, BadgeIcon } from './Badge.styled';

const Badge: React.FC<IBadge> = ({
    backgroundColor,
    children = 'text',
    className,
    image,
    textColor = 'text',
}) => {
    return (
        <BadgeWrapper
            backgroundColor={backgroundColor}
            className={className}
            isImage={!!image}
        >
            {image && <BadgeIcon name={image.name} size={image.size} />}
            <BadgeText color={textColor}>{children}</BadgeText>
        </BadgeWrapper>
    );
};

export default Badge;
