import { gql, useLazyQuery } from '@apollo/client';
import { HasSubscriptionQuery } from '@@types/graphql/HasSubscriptionQuery';

export const HAS_SUBSCRIPTION_QUERY = gql`
    query HasSubscriptionQuery {
        getUserSubscriptionInfo {
            plan {
                currency
                currencySymbol
                price
            }
        }
    }
`;

export default () =>
    useLazyQuery<HasSubscriptionQuery>(HAS_SUBSCRIPTION_QUERY, {
        onError: () => {},
    });
