import React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { StudentsListQuery } from '@@types/graphql/StudentsListQuery';
import { useDeleteStudentAccount } from '@state/students/mutations';
import ModalConfirmation from '@components/_modals/ModalConfirmation/ModalConfirmation';

export interface IProps {
    onClose: () => void;
    refetch: () => Promise<ApolloQueryResult<StudentsListQuery>>;
    studentId: number;
}

const DeleteStudentModal: React.FC<IProps> = ({
    onClose,
    studentId,
    refetch,
}) => {
    const [deleteUser, { loading }] = useDeleteStudentAccount(() => {
        onClose();
        refetch();
    });

    return (
        <ModalConfirmation
            closeOutside
            firstButton={{
                onClick: onClose,
                text: 'cancel',
                type: 'secondary',
            }}
            onClose={onClose}
            secondButton={{
                onClick: () =>
                    deleteUser({
                        variables: {
                            input: {
                                studentId,
                            },
                        },
                    }),
                text: 'Delete',
                type: 'primaryError',
                disabled: loading,
            }}
            title="Delete student account"
        >
            Are you sure you want to delete this account? After deleting the
            account, it cannot be restored.
        </ModalConfirmation>
    );
};

export default DeleteStudentModal;
