import { useAllStudentsList } from '@utils/hooks/useAllStudentsList';
import Loader from '@components/_universal/Loader/Loader';
import { CSVWrapper } from './StudentsListCsvDownloader.styled';

interface StudentsListCsvDownloaderProps {
    children: React.ReactNode;
    sampleData?: boolean;
}

export const StudentsListCsvDownloader = ({
    children,
    sampleData,
}: StudentsListCsvDownloaderProps) => {
    const headers = [
        { label: 'Id', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Surname', key: 'surname' },
        { label: 'Email', key: 'email' },
    ];

    const { students, studentsLoading } = useAllStudentsList();

    return (
        <>
            {studentsLoading ? (
                <Loader size={'small'} center />
            ) : (
                <CSVWrapper
                    data={sampleData ? students.slice(0, 2) : students}
                    headers={headers}
                    filename={
                        sampleData
                            ? 'sample-students-data'
                            : 'students-data.csv'
                    }
                >
                    {children}
                </CSVWrapper>
            )}
        </>
    );
};
