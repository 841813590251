import React from 'react';
import Badge from '@components/_universal/Badge/Badge';
import paths from '@shared/paths';
import {
    ArrowIcon,
    ButtonLink,
    ImageOverlay,
    ImageIcon,
    ImageWrapper,
    WorkDetailsWrapper,
    WorkInner,
    WorkName,
    WorkTypeInfo,
} from '@domains/Parent/Dashboard/DashboardParentPage/_components/WorkDetails/WorkDetails.styled';
import { CONTENT_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { Nullable } from '@@types/CommonTypes';
import { contentTypes } from '@shared/keyToCopy';

interface IProps {
    childId: string;
    homeworkId: string;
    name: Nullable;
    thumbnail: string;
    type: CONTENT_TYPE;
}

const WorkDetails: React.FC<IProps> = ({
    childId,
    homeworkId,
    name,
    thumbnail,
    type,
}) => {
    return (
        <WorkDetailsWrapper>
            <ImageWrapper image={thumbnail}>
                <ImageOverlay />
                <ImageIcon
                    name={
                        type === CONTENT_TYPE.LIVE_EXPERIENCE
                            ? 'Live'
                            : 'Course'
                    }
                    size={20}
                />
            </ImageWrapper>
            <WorkTypeInfo>
                <WorkName>{name}</WorkName>
                <WorkInner>
                    <Badge
                        backgroundColor={
                            type === CONTENT_TYPE.LIVE_EXPERIENCE
                                ? 'live_experience'
                                : 'course'
                        }
                        textColor={'text_white'}
                    >
                        {contentTypes[type].toUpperCase()}
                    </Badge>
                    <ButtonLink
                        to={
                            type === CONTENT_TYPE.LIVE_EXPERIENCE
                                ? paths.live_experience_parent(
                                      childId,
                                      homeworkId,
                                  )
                                : paths.course_parent(childId, homeworkId)
                        }
                    >
                        See more
                        <ArrowIcon name={'ArrowTextButtonRight'} size={24} />
                    </ButtonLink>
                </WorkInner>
            </WorkTypeInfo>
        </WorkDetailsWrapper>
    );
};

export default WorkDetails;
