import { setTypeformModal } from '@state/_redux/layout/actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
    BoldTipText,
    TipContent,
    TipText,
    TypeformTipWrapper,
    StyledButton,
    MeditatingLadyIcon,
} from './TypeformTip.styled';

interface IProps {
    className?: string;
}

const TypeformTip: React.FC<IProps> = ({ className }) => {
    const dispatch = useDispatch();

    return (
        <TypeformTipWrapper
            className={className}
            onClick={(e) => e.stopPropagation()}
        >
            <TipContent>
                <TipText>
                    Do you have{' '}
                    <BoldTipText>
                        any creative idea of how we can improve our platfrom or
                        what courses or topics we could add?
                    </BoldTipText>{' '}
                    Feel free to share it with us!
                </TipText>
                <StyledButton
                    buttonType="secondary"
                    onPress={() => dispatch(setTypeformModal(true))}
                    size="small"
                >
                    share your idea
                </StyledButton>
                <MeditatingLadyIcon
                    name="MeditatingLady"
                    size={105}
                    sizeY={119}
                />
            </TipContent>
        </TypeformTipWrapper>
    );
};

export default TypeformTip;
