import styled from 'styled-components';

export const SaveForLaterWrapper = styled.button<{ size: number }>`
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    transition: ${({ theme }) => theme.transitions.medium}s;
    width: ${({ size }) => size}px;
    will-change: transform;

    &:hover {
        transform: scale(1.1);
    }
`;
