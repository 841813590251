import { SelectType } from '@@types/CommonTypes';
import { useForm } from 'react-hook-form';
import { useMatchMedia } from '@utils/hooks';
import { useSchoolGroups } from '@state/students/queries/useSchoolGroups';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import Select from '@components/_form-elements/Select/Select';
import {
    ButtonsRow,
    StudentFormWrapper,
    StyledButton,
    StyledFormRow,
    StyledInputWrapper,
} from '@domains/Teacher/Students/_forms/Forms.styled';

export interface IStudentGroupForm {
    group: SelectType[];
}

interface IProps {
    onClose: () => void;
    onSubmit: (data: IStudentGroupForm) => void;
}

const StudentGroupForm: React.FC<IProps> = ({ onClose, onSubmit }) => {
    const { match } = useMatchMedia('md');
    const { data: groupsList, loading } = useSchoolGroups({});
    const { control, errors, handleSubmit } = useForm<IStudentGroupForm>({
        resolver: yupResolver(validation),
    });
    const options = groupsList?.getSchoolGroups?.groups.map((group) => ({
        label: group.title,
        value: group.id,
    }));

    return (
        <StudentFormWrapper noValidate={true} onSubmit={handleSubmit(onSubmit)}>
            <StyledInputWrapper>
                <StyledFormRow>
                    <SelectForm
                        control={control}
                        errors={errors.group}
                        isLoading={loading}
                        isMulti={true}
                        isSearchable={true}
                        label={'group'}
                        marginBottom={match ? 8 : 24}
                        name={'group'}
                        options={options}
                        placeholder={'Select...'}
                        staticPosition={match}
                    />
                </StyledFormRow>
            </StyledInputWrapper>
            <ButtonsRow>
                <StyledButton
                    buttonType={'secondary'}
                    onPress={onClose}
                    type="button"
                >
                    cancel
                </StyledButton>
                <StyledButton buttonType={'primary'} onPress={() => {}}>
                    save
                </StyledButton>
            </ButtonsRow>
        </StudentFormWrapper>
    );
};

const SelectForm = FieldMessage(Select);

export default StudentGroupForm;
