import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import Avatar from '@components/_universal/Avatar/Avatar';

export const UsersListWrapper = styled.div`
    margin: 24px 0 45px;
`;

export const ListItem = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 16px;
`;

export const NameColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 60px;
    width: 220px;
`;

export const TextValue = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 0;
`;

export const TextLabel = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
`;

export const Action = styled(TextLabel)`
    cursor: pointer;
    transition: all ${({ theme }) => theme.transitions.default}s;

    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
`;

export const ActionDelete = styled(Action)`
    &:hover {
        color: ${({ theme }) => theme.colors.error};
    }
`;

export const AvatarIcon = styled(Avatar)`
    margin-right: 24px;
`;

export const StatusColumn = styled.div``;

export const ActionsColumn = styled.div`
    align-items: center;
    display: flex;
    margin-left: auto;
`;

export const ActionsDecorator = styled.div`
    background: ${({ theme }) => theme.colors.text_assistive};
    height: 19px;
    margin: 0 12px;
    width: 1px;
`;
