import styled from 'styled-components';
import Footer from '@components/_global/Footer/Footer';
import ChildHeader from '@components/_global/Header/ChildHeader/ChildHeader';
import Navigation from '@components/_global/Navigation/ChildNavigation/ChildNavigation';

export const VideoViewLayoutWrapper = styled.div`
    display: grid;
    grid-template-areas:
        'navigation content'
        'navigation content'
        'navigation content';
    grid-template-columns: 120px 1fr;
    grid-template-rows: 100px 1fr;
    height: 100%;
`;

export const HeaderBar = styled(ChildHeader)``;

export const NavigationBar = styled(Navigation)`
    grid-area: navigation;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: content;
    padding: 0 100px 0 100px;
    width: 100%;
`;

export const FooterBar = styled(Footer)``;
