import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import {
    ChangePasswordMutationMutation,
    ChangePasswordMutationMutationVariables,
} from '@@types/graphql/ChangePasswordMutationMutation';

const CHANGE_PASSWORD_MUTATION = gql`
    mutation ChangePasswordMutationMutation($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            refreshToken
            sessionToken
        }
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<
        ChangePasswordMutationMutation,
        ChangePasswordMutationMutationVariables
    >(CHANGE_PASSWORD_MUTATION, {
        onCompleted: (data) => {
            LocalStorageManager.setValue(
                'sessionToken',
                data.changePassword.sessionToken,
            );
            LocalStorageManager.setValue(
                'refreshToken',
                data.changePassword.refreshToken,
            );
            onCompleted?.();
            notify('Your password has been successfully changed.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: (error) => {
            if (
                [
                    'Value is not a valid password',
                    'Wrong old password',
                ].includes(error.message)
            ) {
                notify('Invalid current password')[ToastTypes.ERROR]();
            } else {
                notify(error.message)[ToastTypes.ERROR]();
            }
        },
    });
};
