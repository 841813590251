import styled from 'styled-components';
import Button from '@components/_universal/Button/Button';
import IconManager from '@components/_universal/IconManager/IconManager';
import {
    BigTitle,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const EmptyStateSearchWrapper = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.secondary_hover};
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    border: 1px solid ${({ theme }) => theme.colors.secondary_hover};
    display: flex;
    flex-direction: column;
    margin: 64px auto 0;
    max-width: 852px;
    padding: 52px;
    position: relative;
    width: 100%;
`;

export const EmptyStateSearchTitle = styled(BigTitle)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    letter-spacing: 1px;
    margin: 0 0 24px;
`;

export const EmptyStateSearchText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 0 32px;
    max-width: 544px;
`;

export const EmptyStateSearchLink = styled(Button)`
    &:hover {
        box-shadow: ${({ theme }) => theme.blue_shadow};
    }
`;

export const EmptyStateSearchPencilsImage = styled(IconManager)`
    bottom: -23px;
    position: absolute;
    right: 62px;
`;
