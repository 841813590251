import styled from 'styled-components';
import TextButton from '@components/_universal/TextButton/TextButton';
import { Button, Tip } from '@components/_universal';
import { breakpoints } from '@shared/constants';
import {
    BigTitle,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const RegisterChildWrapper = styled.div<{ isVisible: boolean }>`
    margin: 0 auto;
    max-width: 750px;
    position: relative;
    ${({ isVisible }) => !isVisible && 'display: none;'}

    @media (max-width: ${breakpoints.lg}px) {
        margin-top: 45px;
    }

    @media (max-width: ${breakpoints.md}px) {
        max-width: 650px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        margin-bottom: 24px;
        margin-top: 0;
        max-width: 100%;
    }
`;

export const RegisterChildInnerWrapper = styled.div`
    background: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    box-shadow: 5px 5px 16px 0px rgba(36, 48, 224, 0.15);
    padding: 56px 56px 40px;
    position: relative;
    z-index: 9;

    @media (max-width: ${breakpoints.md}px) {
        border-radius: ${({ theme }) => theme.borderRadius.button}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        padding: 40px 28px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        padding: 40px 16px;
    }
`;

const TemplateChild = styled.div`
    background: ${({ theme }) => theme.colors.background_tertiary};
    bottom: 36px;
    position: absolute;
    top: 36px;
    width: 50px;

    @media (max-width: ${breakpoints.sm}px) {
        text-align: center;
        margin-bottom: 4px;
    }
`;

export const InformationText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 8px;
    text-transform: uppercase;

    @media (max-width: ${breakpoints.sm}px) {
        text-align: center;
        margin-bottom: 4px;
    }
`;

export const StyledBigTitle = styled(BigTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 32px;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
        text-align: center;
    }
`;

export const StyledTip = styled(Tip)`
    bottom: calc(100% - 130px);
    left: calc(100% - 304px);
    position: absolute;
    width: 468px;

    @media (max-width: ${breakpoints.lg}px) {
        bottom: calc(100% - 160px);
        left: calc(100% - 340px);
        margin-bottom: 20px;
        width: 420px;

        &::after {
            border-right-width: 22px;
            border-top-width: 20px;
            left: 50px;
        }
    }

    @media (max-width: ${breakpoints.md}px) {
        left: calc(100% - 250px);
        width: 300px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        display: none;
    }
`;

export const RegisterChildContentWrapper = styled.div`
    position: relative;
`;

export const ButtonsRow = styled.div`
    display: flex;

    @media (max-width: ${breakpoints.xs}px) {
        flex-direction: column;
    }
`;

export const StyledButton = styled(Button)`
    margin: 16px 0 24px;
    width: 166px;

    @media (max-width: ${breakpoints.xs}px) {
        margin: 16px 0 12px;
        width: 100%;
    }
`;

export const StyledNextButton = styled(StyledButton)`
    margin-left: auto;
`;

export const RemoveButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: ${({ theme }) => theme.cursors.pointer};
    height: 33px;
    padding: 0;
    position: absolute;
    right: 32px;
    top: 32px;
    width: 33px;

    @media (max-width: ${breakpoints.sm}px) {
        right: 22px;
        top: 22px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        right: 18px;
        top: 12px;
    }
`;
