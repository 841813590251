import React, { useEffect, useState } from 'react';
import { ButtonRow } from '@domains/Auth/_forms/GlobalForm.styled';
import { errorMessages } from '@utils/shared/validations/formErrors';
import { FieldError, useForm } from 'react-hook-form';
import { InputPassword } from '@components/_form-elements';
import { useConfirmResetPasswordMutation } from '@state/auth/mutations';
import { useCustomRegister, useMatchMedia } from '@utils/hooks';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import PasswordValidation from '@components/_form-elements/PasswordValidation/PasswordValidation';
import {
    ResetPasswordFormWrapper,
    StyledButton,
    StyledInputWrapper,
} from './ResetPasswordForm.styled';

interface IProps {
    token: string;
}

interface IForm {
    newPassword: string;
    confirmPassword: string;
}

const ResetPasswordForm: React.FC<IProps> = ({ token }) => {
    const { match } = useMatchMedia('md');
    const { errors, handleSubmit, register, watch } = useForm<IForm>({
        resolver: yupResolver(validation),
        mode: 'onBlur',
    });
    const { refElement: passwordInputEl } = useCustomRegister<HTMLInputElement>(
        register,
    );
    const { refElement: confirmInputEl } = useCustomRegister<HTMLInputElement>(
        register,
    );
    const [
        confirmResetPassword,
        { loading },
    ] = useConfirmResetPasswordMutation();

    const password = watch('newPassword');
    const confirmPassword = watch('confirmPassword');
    const [isValid, setIsValid] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const isValidConfirmPassword =
        password === confirmPassword || !confirmPassword.length;

    const onSubmit = handleSubmit(({ newPassword }: IForm) => {
        if (!isValid || loading) return;
        passwordInputEl.current?.blur();
        confirmResetPassword({
            variables: {
                input: {
                    password: newPassword,
                    token,
                },
            },
        });
    });

    useEffect(() => {
        const onFocus = () => setIsFocused(true);

        passwordInputEl.current?.addEventListener('focus', onFocus);

        return () => {
            passwordInputEl.current?.removeEventListener('focus', onFocus);
        };
    }, []);

    return (
        <ResetPasswordFormWrapper noValidate onSubmit={onSubmit}>
            <StyledInputWrapper>
                <InputForm
                    errors={errors.newPassword}
                    inputType={'secondary'}
                    label={`new password`}
                    marginBottom={match || isFocused ? 8 : 16}
                    maxLength={256}
                    name={'newPassword'}
                    placeholder={'Enter your new password'}
                    register={passwordInputEl}
                    staticPosition={match}
                    type="password"
                />
            </StyledInputWrapper>
            {isFocused && (
                <PasswordValidation
                    color="text_white"
                    marginTop={errors.newPassword && 16}
                    password={password}
                    setIsValid={setIsValid}
                />
            )}
            <StyledInputWrapper>
                <InputForm
                    backgroundTypeError
                    errors={
                        errors.confirmPassword || !isValidConfirmPassword
                            ? ({
                                  message: errorMessages.CONFIRM_PASSWORD,
                              } as FieldError)
                            : undefined
                    }
                    inputType={'secondary'}
                    isValid={!errors.confirmPassword && isValidConfirmPassword}
                    label={`confirm new password`}
                    marginBottom={16}
                    maxLength={256}
                    name={'confirmPassword'}
                    placeholder={'Re-enter your new password'}
                    register={confirmInputEl}
                    staticPosition
                    type="password"
                />
            </StyledInputWrapper>
            <ButtonRow>
                <StyledButton disabled={loading} onPress={() => {}}>
                    change my password and log me in
                </StyledButton>
            </ButtonRow>
        </ResetPasswordFormWrapper>
    );
};

const InputForm = FieldMessage(InputPassword);

export default ResetPasswordForm;
