import { useParentTrialInfo, useChildTrialInfo } from '@state/auth/queries';
import dayjs from 'dayjs';

export default () => {
    const { data: trialParentInfo } = useParentTrialInfo();
    const { data: trialChildInfo } = useChildTrialInfo();

    const hasPlan =
        trialParentInfo?.getUserSubscriptionInfo.plan ||
        trialChildInfo?.me.subscription?.plan;

    const trialEndAt =
        trialParentInfo?.getUserSubscriptionInfo.trialEndAt ||
        trialChildInfo?.me.subscription?.trialEndAt;
    const hadTrialNoPlan = !!trialEndAt && !hasPlan;
    const isTrialEnded = dayjs(trialEndAt).diff(dayjs()) <= 0;
    const daysText = dayjs(trialEndAt).fromNow(true);

    return { daysText, hadTrialNoPlan, isTrialEnded, trialParentInfo };
};
