import { gql, useMutation } from '@apollo/client';
import { GET_COURSE_QUERY } from '@state/selfServeCourse/queries/useCourseQuery';
import { GetCourseQuery } from '@@types/graphql/GetCourseQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { useParams } from 'react-router';
import {
    SendCourseMutation,
    SendCourseMutationVariables,
} from '@@types/graphql/SendCourseMutation';

const SEND_COURSE_MUTATION = gql`
    mutation SendCourseMutation($courseId: String!, $homeworkFile: Upload!) {
        sendCourseHomework(courseId: $courseId, homeworkFile: $homeworkFile) {
            id
            feedbackGivenAt
            feedbackBy {
                name
                surname
            }
            mentorFeedback
            createdAt
            files {
                id
                filename
                type
            }
        }
    }
`;

export default (onError?: () => void) => {
    const { courseId } = useParams<{ courseId: string }>();

    return useMutation<SendCourseMutation, SendCourseMutationVariables>(
        SEND_COURSE_MUTATION,
        {
            onError: (error) => {
                onError?.();
                notify(error.message)[ToastTypes.ERROR]();
            },
            update: (cache, { data }) => {
                if (!data) return;
                const query = cache.readQuery<GetCourseQuery>({
                    query: GET_COURSE_QUERY,
                    variables: {
                        id: courseId,
                    },
                });

                query &&
                    cache.writeQuery({
                        query: GET_COURSE_QUERY,
                        variables: { id: courseId },
                        data: {
                            getCourse: {
                                ...query.getCourse,
                                isConsumed: true,
                                homework: data.sendCourseHomework,
                            },
                        },
                    });
            },
        },
    );
};
