import React from 'react';
import { Redirect } from 'react-router';
import { useQuery } from '@utils/hooks';
import paths from '@shared/paths';
import ResetPasswordForm from '@domains/Auth/ResetPassword/_forms/ResetPasswordForm/ResetPasswordForm';
import {
    ResetPasswordPageWrapper,
    StyledTitle,
    Subtitle,
} from './ResetPasswordPage.styled';

interface IProps {}

const ResetPasswordPage: React.FC<IProps> = () => {
    const { searchParams } = useQuery();
    const token = searchParams.get('token');

    if (!token) return <Redirect to={paths.forgot_password} />;

    return (
        <ResetPasswordPageWrapper>
            <StyledTitle>Password reset</StyledTitle>
            <Subtitle>
                Enter your new password for your 8billionideas account
            </Subtitle>
            <ResetPasswordForm token={token} />
        </ResetPasswordPageWrapper>
    );
};

export default ResetPasswordPage;
