import { useEffect, useState } from 'react';
import { StudentsListQuery_getStudentsList_users } from '@@types/graphql/StudentsListQuery';
import { useStudentsListQuery } from '@state/students/queries';

const PAGE_LIMIT = 100;

export const useAllStudentsList = () => {
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const { data: students } = useStudentsListQuery({
        pagination: {
            limit: PAGE_LIMIT,
            offset: (page - 1) * PAGE_LIMIT,
        },
    });

    const [studentsData, setStudentsData] = useState(
        students?.getStudentsList.users ?? [],
    );

    useEffect(() => {
        if (students && students?.getStudentsList.count > studentsData.length) {
            setStudentsData([
                ...(studentsData as StudentsListQuery_getStudentsList_users[]),
                ...students?.getStudentsList.users,
            ]);
        }
    }, [page]);

    useEffect(() => {
        if (
            studentsData &&
            students &&
            students?.getStudentsList.count > studentsData.length
        ) {
            setPage(page + 1);
        }
    }, [students]);

    useEffect(() => {
        if (
            students &&
            students?.getStudentsList.count === studentsData.length
        ) {
            setLoading(false);
        }
    }, [studentsData]);

    return { students: studentsData, studentsLoading: loading };
};
