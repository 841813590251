import React from 'react';
import { useHistory } from 'react-router-dom';
import LoginFooter from '@domains/Auth/_components/LoginFooter/LoginFooter';
import LoginFormBreak from '@domains/Auth/_components/LoginFormBreak/LoginFormBreak';
import LoginLinkForm from '@domains/Auth/_forms/LoginLinkForm/LoginLinkForm';
import paths, { externalLinks } from '@shared/paths';
import {
    ButtonLink,
    DesktopLoginLinkPageWrapper,
    EmailText,
    FormWrapper,
    LogoIcon,
    LogoLink,
} from './DesktopLoginLinkPage.styled';

interface IProps {}

const DesktopLoginLinkPage: React.FC<IProps> = () => {
    const history = useHistory();

    return (
        <DesktopLoginLinkPageWrapper>
            <LogoLink href={externalLinks.homepage} target="_blank">
                <LogoIcon
                    fill={['text_white', 'text_white']}
                    name={'LogoWithText'}
                    size={190}
                    sizeY={45}
                />
            </LogoLink>

            <FormWrapper>
                <EmailText fontFamily="sketched">
                    Type your email address!
                </EmailText>
                <LoginLinkForm />
                <LoginFormBreak />
                <ButtonLink
                    buttonType="tertiary"
                    onPress={() => history.push(paths.login)}
                >
                    log in with password
                </ButtonLink>
            </FormWrapper>
            <LoginFooter />
        </DesktopLoginLinkPageWrapper>
    );
};

export default DesktopLoginLinkPage;
