import React from 'react';
import { Redirect, useLocation } from 'react-router';
import paths from '@shared/paths';
import {
    OrnamentIcon,
    ResetLinkSentPageWrapper,
    StyledTitle,
    Subtitle,
    WrongEmailLink,
    WrongEmailText,
} from './ResetLinkSentPage.styled';

export interface IProps {}

const ResetLinkSentPage: React.FC<IProps> = () => {
    const location = useLocation<{ email?: string }>();

    if (!location?.state?.email) return <Redirect to={paths.forgot_password} />;

    return (
        <ResetLinkSentPageWrapper>
            <StyledTitle>Reset link has been sent!</StyledTitle>
            <Subtitle>
                Check your {location?.state?.email} inbox for email with
                instructions how to reset your password.
            </Subtitle>
            <OrnamentIcon name={'MailIcon'} size={250} sizeY={200} />
            <WrongEmailText>
                Wrong email address? Please{' '}
                <WrongEmailLink to={paths.forgot_password}>
                    re-enter email address.
                </WrongEmailLink>
            </WrongEmailText>
        </ResetLinkSentPageWrapper>
    );
};

export default ResetLinkSentPage;
