import { gql, useQuery } from '@apollo/client';
import { ChildQuery, ChildQueryVariables } from '@@types/graphql/ChildQuery';

const CHILD_QUERY = gql`
    query ChildQuery($childId: Int!) {
        getChildForParent(childId: $childId) {
            birthday
            email
            gender
            id
            name
            status
            surname
            type
        }
    }
`;

export default (childId: number) =>
    useQuery<ChildQuery, ChildQueryVariables>(CHILD_QUERY, {
        variables: {
            childId,
        },
    });
