import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button } from '@components/_universal';
import { InputWrapper } from '@components/_form-elements/Input/Input.styled';

export const ResetPasswordFormWrapper = styled.form`
    max-width: 554px;
    width: 100%;
`;

export const StyledInputWrapper = styled(InputWrapper)`
    flex: 1;
    margin: 0;
`;

export const StyledButton = styled(Button)`
    flex: 1;
    margin: 8px 16px 24px;

    &:hover {
        box-shadow: ${({ theme }) => theme.blue_shadow};
    }

    @media (max-width: ${breakpoints.sm}px) {
        margin: 8px 16px 16px;
    }
`;
