import styled, { css } from 'styled-components';
import { Button, Tip } from '@components/_universal';
import { breakpoints } from '@shared/constants';

const TRIANLGE_DISTANCE = 16;

export const TrialLeftInformationWrapper = styled.div`
    display: inline-flex;
    filter: drop-shadow(5px 5px 16px rgba(92, 193, 155, 0.2));
    position: relative;
`;

export const TrialLeftInformationButton = styled.button<{
    withoutAction?: boolean;
}>`
    background: 0;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    ${({ withoutAction }) =>
        withoutAction &&
        css`
            pointer-events: none;
        `}
`;

export const TrialLeftInformationTooltip = styled.div<{ size: number }>`
    position: absolute;
    right: calc(${({ size }) => size / 2}px - ${TRIANLGE_DISTANCE}px);
    top: calc(100% + 16px);
    width: 465px;

    @media (max-width: ${breakpoints.md}px) {
        width: 320px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        width: 260px;
    }
`;

export const TrialLeftInformationTip = styled(Tip)`
    display: grid;
    gap: 20px;
    justify-items: center;
    left: unset;
    width: 100%;

    &::after {
        left: unset;
        right: ${TRIANLGE_DISTANCE}px;
        bottom: 100%;
        top: unset;
        transform: rotate(180deg);
    }
`;

export const TrialLeftInformationTipButton = styled(Button)`
    &:hover {
        box-shadow: ${({ theme }) => theme.blue_shadow_light};
    }
`;
