import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button } from '@components/_universal';
import { Link } from 'react-router-dom';

export const LoginPasswordFormWrapper = styled.form`
    position: relative;
`;

export const StyledButton = styled(Button)`
    flex: 1;
    margin: 8px 16px 24px;

    &:hover {
        box-shadow: ${({ theme }) => theme.blue_shadow};
    }

    @media (max-width: ${breakpoints.sm}px) {
        margin: 8px 16px 16px;
    }
`;

export const ForgotPassword = styled(Link)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    position: absolute;
    right: 36px;
    text-decoration: none;
    top: 4px;
    z-index: 9;

    &:hover {
        text-decoration: underline;
    }
`;
