import React from 'react';
import { CardTypes, IMosaicCard } from '@@types/CommonTypes';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { XBlock } from 'react-xmasonry';
import LiveExperienceCard from '@components/_universal/Cards/LiveExperienceCard/LiveExperienceCard';
import SplitCard from '@components/_universal/Cards/SplitCard/SplitCard';
import TextBoxCard from '@components/_universal/Cards/TextBoxCard/TextBoxCard';
import { MosaicContentItem } from './MosaicContent.styled';

const getCardComponent = (
    card: IMosaicCard,
    isDifferentHover?: boolean,
    userType?: USER_TYPE,
): Record<CardTypes, React.ReactNode> => {
    return {
        COURSE: (
            <XBlock key={card.key}>
                <MosaicContentItem>
                    <SplitCard
                        featured={card.featured}
                        image={card.image}
                        isDifferentHover={!!isDifferentHover}
                        link={
                            userType === USER_TYPE.TEACHER
                                ? `library${card.url}`
                                : card.url
                        }
                        text={card.text}
                        width="100%"
                    />
                </MosaicContentItem>
            </XBlock>
        ),
        LIVE_EXPERIENCE: (
            <XBlock key={card.key}>
                <MosaicContentItem>
                    <LiveExperienceCard
                        allSlots={card.availableSlots ?? 0}
                        availableSlots={card.availableSlots ?? 0}
                        endDate={card.endDate}
                        featured={card.featured}
                        image={card.image}
                        isDifferentHover={!!isDifferentHover}
                        userType={userType}
                        link={
                            userType === USER_TYPE.TEACHER
                                ? `library${card.url}`
                                : card.url
                        }
                        startDate={card.startDate}
                        text={card.text}
                        width="100%"
                    />
                </MosaicContentItem>
            </XBlock>
        ),
        STANDALONE_VIDEO: (
            <XBlock key={card.key}>
                <MosaicContentItem>
                    <TextBoxCard
                        featured={card.featured}
                        image={card.image}
                        isDifferentHover={!!isDifferentHover}
                        link={
                            userType === USER_TYPE.TEACHER
                                ? `library${card.url}`
                                : card.url
                        }
                        text={card.text}
                        type="STANDALONE_VIDEO"
                        width="100%"
                    />
                </MosaicContentItem>
            </XBlock>
        ),
    };
};

export default getCardComponent;
