import React from 'react';
import DesktopLoginPasswordPage from './_desktop/DesktopLoginPasswordPage';
import MobileLoginPasswordPage from './_mobile/MobileLoginPasswordPage';
import useMobile from '@utils/hooks/useMobile';

interface IProps {}

const LoginPasswordPage: React.FC<IProps> = () => {
    const { isMobile } = useMobile();

    return isMobile ? (
        <MobileLoginPasswordPage />
    ) : (
        <DesktopLoginPasswordPage />
    );
};

export default LoginPasswordPage;
