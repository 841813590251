import React from 'react';
import { Nullable } from '@@types/CommonTypes';
import UploadedFile from '@domains/Parent/Dashboard/DashboardParentPage/_components/UploadedFile/UploadedFile';
import {
    FeedbackWrapper,
    MentorAdditionalInfo,
    MentorFeedback,
    MentorFeedbackText,
    MentorIcon,
    MentorInfo,
    MentorName,
    MentorWrapper,
} from './Feedback.styled';
import { ChildHomeworkQuery_getChildHomeworks_files } from '@@types/graphql/ChildHomeworkQuery';

interface IProps {
    date?: Date;
    description: Nullable<string | number>;
    file?: ChildHomeworkQuery_getChildHomeworks_files;
    homeworkId: number;
    name: Nullable<string | number>;
    type: string;
}

const Feedback: React.FC<IProps> = ({
    date,
    description,
    file,
    homeworkId,
    name,
    type,
}) => {
    return (
        <FeedbackWrapper>
            <MentorInfo>
                <MentorIcon name={'Mentor'} size={46} sizeY={46} />
                <MentorWrapper>
                    <MentorName>{name}</MentorName>
                    <MentorAdditionalInfo>{type}</MentorAdditionalInfo>
                </MentorWrapper>
            </MentorInfo>
            <MentorFeedback>
                <MentorFeedbackText>{description}</MentorFeedbackText>
                {file && (
                    <UploadedFile
                        date={date}
                        file={file}
                        homeworkId={homeworkId}
                    />
                )}
            </MentorFeedback>
        </FeedbackWrapper>
    );
};

export default Feedback;
