import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import {
    UpdateStudentsAccountsClassAndYearGroup,
    UpdateStudentsAccountsClassAndYearGroupVariables,
} from '@@types/graphql/UpdateStudentsAccountsClassAndYearGroup';

export const UPDATE_STUDENTS_ACCOUNTS_CLASS_AND_YEAR_GROUP = gql`
    mutation UpdateStudentsAccountsClassAndYearGroup(
        $input: UpdateStudentsAccountsClassAndYearInput!
    ) {
        updateStudentsAccountsClassAndYear(input: $input) {
            id
        }
    }
`;

export const useUpdateStudentsAccountsClassAndYear = (callback: () => void) => {
    return useMutation<
        UpdateStudentsAccountsClassAndYearGroup,
        UpdateStudentsAccountsClassAndYearGroupVariables
    >(UPDATE_STUDENTS_ACCOUNTS_CLASS_AND_YEAR_GROUP, {
        onCompleted: () => {
            callback();
            notify('You have successfully updated students class and year.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
