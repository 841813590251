import styled from 'styled-components';
import { breakpoints } from '@shared/constants';

export const RuleWrapper = styled.div`
    background: ${({ theme }) => theme.colors.background_quaternary};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    margin-bottom: 24px;
    padding: 19px 24px;
    position: relative;
    text-align: center;
    width: 100%;

    @media (max-width: ${breakpoints.lg}px) {
        min-height: 120px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        min-height: 100px;
    }
`;

export const RuleTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    margin-top: -24px;
`;

export const RuleText = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.4;
    margin-top: 12px;
`;

export const RuleTextBold = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
