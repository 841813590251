import styled, { css } from 'styled-components';

export const DEFAULT_CARD_WIDTHS = {
    liveExperience: 255,
    placeholder: 255,
    playMe: 112,
    reading: 112,
    split: 255,
    splitHorizontal: 541,
    textBox: 255,
};

export const DEFAULT_CARD_HEIGHTS = {
    liveExperience: 350,
    placeholder: 350,
    playMe: 156,
    reading: 346,
    split: 346,
    splitHorizontal: 157,
    textBox: 157,
};

export const imageOverlayCss = css`
    position: relative;

    &::before {
        background-color: ${({ theme }) => theme.colors.secondary};
        content: '';
        height: 100%;
        left: 0;
        opacity: 0.4;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;

export const CardWrapper = styled.div<{ isDifferentHover: boolean }>`
    background-color: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    box-shadow: ${({ theme }) => theme.light_green_shadow};
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: ${({ theme }) => theme.transitions.default}s;
    width: 100%;

    &:hover {
        box-shadow: ${({ theme, isDifferentHover }) =>
            isDifferentHover ? theme.blue_shadow : theme.dark_green_shadow};
    }
`;

export const CardImage = styled.div<{ image: string }>`
    background-image: url(${({ image }) => image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    ${imageOverlayCss};
`;
