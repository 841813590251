import { Button } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';
import styled, { css } from 'styled-components';

export const EmptyGroupsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const EmptyBox = styled.div(
    ({ theme: { colors, borderRadius } }) => css`
        align-items: center;
        background: ${colors.background_secondary};
        border-radius: ${borderRadius.button}px;
        border: 1px solid ${colors.stroke};
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 56px 30px;
        position: relative;
    `,
);

export const EmptyBoxText = styled.div(
    ({ theme: { colors } }) => css`
        color: ${colors.text_secondary};
        margin: 0;
        max-width: 700px;
        text-align: center;
    `,
);

export const TextBold = styled(DefaultText)(
    ({ theme: { colors, fontWeights } }) => css`
        color: ${colors.text_secondary};
        font-weight: ${fontWeights.semiBold};
        margin: 0;
        max-width: 700px;
        text-align: center;
    `,
);

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 32px;
`;

export const StyledButton = styled(Button)`
    margin-right: 16px;
`;
