import * as yup from 'yup';
import {
    dateValidation,
    emailValidation,
    genderValidation,
    textValidation,
} from '@utils/shared/validations/formErrors';

export const validation = yup.object().shape({
    birthday: dateValidation,
    email: emailValidation,
    gender: genderValidation,
    name: textValidation,
    surname: textValidation,
});
