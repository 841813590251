import React from 'react';
import AnimatedDots from '@components/_universal/AnimatedDots/AnimatedDots';
import {
    Content,
    DesktopSignupPaymentProcessingPageWrapper,
    LoadingScooterIcon,
    LoadingText,
    LoadingTitle,
} from './DesktopSignupPaymentProcessingPage.styled';

export interface IProps {}

const DesktopSignupPaymentProcessingPage: React.FC<IProps> = () => {
    return (
        <DesktopSignupPaymentProcessingPageWrapper>
            <Content>
                <LoadingTitle>
                    Payment processing
                    <AnimatedDots />
                </LoadingTitle>

                <LoadingScooterIcon
                    name={'LoadingScooter'}
                    size={356}
                    sizeY={170}
                />
                <LoadingText>
                    Please do not close this window. After processing the
                    payment, you will be automatically redirected to the next
                    step.
                </LoadingText>
            </Content>
        </DesktopSignupPaymentProcessingPageWrapper>
    );
};

export default DesktopSignupPaymentProcessingPage;
