import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import Rating from '@components/_form-elements/Rating/Rating';
import useRateCourseMutation from '@state/selfServeCourse/mutations/useRateCourseMutation';
import {
    BigHeader,
    RateCourseFormWrapper,
    RateTextArea,
    SubmitButton,
} from './RateCourseForm.styled';

interface IProps {}

interface IForm {
    comment: string;
    rate: number;
}

const RateCourseForm: React.FC<IProps> = () => {
    const { courseId } = useParams<{ courseId: string }>();

    const { control, errors, handleSubmit, register, watch } = useForm<IForm>({
        defaultValues: {
            rate: 0,
            comment: '',
        },
        resolver: yupResolver(validation),
    });
    const rate = watch('rate');

    const [rateCourse] = useRateCourseMutation(courseId);

    const onSubmit = (formData: IForm) => {
        rateCourse({
            variables: {
                courseId: courseId,
                ...formData,
            },
        });
    };

    return (
        <RateCourseFormWrapper>
            <Rating {...{ control }} name={'rate'} />
            <BigHeader>
                Did you like this course? Tell us something more!
            </BigHeader>
            <TextArea
                errors={errors.comment}
                inputType={'secondary'}
                marginBottom={40}
                name={'comment'}
                placeholder={'Enter your answer (optional)'}
                register={register}
            />
            <SubmitButton disabled={!rate} onPress={handleSubmit(onSubmit)}>
                Send
            </SubmitButton>
        </RateCourseFormWrapper>
    );
};

const TextArea = FieldMessage(RateTextArea);

export default RateCourseForm;
