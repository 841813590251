import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { HeaderWrapper } from '@components/_global/Header/Header.styled';

export const StyledHeaderWrapper = styled(HeaderWrapper)<{
    displayLogout?: boolean;
}>(
    ({ displayLogout }) => css`
        z-index: 99;

        ${displayLogout &&
        css`
            border-radius: unset;
            justify-content: space-between;
            width: calc(100% - 260px);
        `};
    `,
);

export const IconWrapper = styled.div`
    display: flex;
`;

export const AdminLogoutWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 192px;
    padding-right: 10px;
    width: 100%;
`;

export const SchoolInfo = styled(DefaultText)(
    ({ theme: { borderRadius, colors, fontSizes, fontWeights } }) => css`
        background-color: ${colors.details_teacher};
        border-radius: ${borderRadius.card}px;
        display: flex;
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        gap: 8px;
        letter-spacing: 0.01em;
        margin-bottom: 0;
        padding: 12px 32px;
        width: max-content;
    `,
);

export const BoldText = styled.span(
    ({ theme: { fontWeights } }) => css`
        display: contents;
        font-weight: ${fontWeights.bold};
    `,
);
