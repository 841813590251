import React, { useState } from 'react';
import { ColorType, IconName } from '@@types/CommonTypes';
import { IconButtonWrapper } from './IconButton.styled';
import { IconManager } from '@components/_universal';

interface IProps {
    className?: string;
    disabled?: boolean;
    icon: {
        activeColor?: ColorType[];
        color?: ColorType[];
        size?: number;
        type: IconName;
    };
    onPress?: () => void;
    size?: number;
}

const IconButton: React.FC<IProps> = ({
    className,
    disabled,
    icon,
    onPress,
    size,
}) => {
    const [active, setActive] = useState(false);

    return (
        <IconButtonWrapper
            className={className}
            disabled={!!disabled}
            onClick={onPress}
            onMouseOut={() => setActive(false)}
            onMouseOver={() => setActive(true)}
            {...{ size }}
        >
            <IconManager
                activeFill={disabled ? ['icon_disabled'] : icon?.activeColor}
                fill={disabled ? ['icon_disabled'] : icon?.color}
                isActive={active}
                name={icon.type}
                size={icon?.size ?? 24}
            />
        </IconButtonWrapper>
    );
};

export default IconButton;
