export const palette = {
    white: '#FFFFFF',
    limeade: '#32BC01',
    keppel: '#3BBA8A',
    vista_blue: '#90D8BC',
    narvik: '#E4F6EF',
    royal_blue: '#5059E6',
    portage: '#7E84EC',
    pickled_bluewood: '#243A47',
    shuttle_gray: '#5B666D',
    gray_chateau: '#99A3B1',
    mischka: '#CBD0DE',
    white_smoke: '#F6F6F9',
    moon_raker: '#D7D9F9',
    athens_gray: '#E9E9F1',
    off_green: '#E2F8EE',
    selago: '#EDEEFC',
    persian_blue: '#2430e0',
    cotton_candy: '#FFB2C9',
    wild_watermelon: '#FF4B81',
    radical_red: '#FF195E',
    coral_red: '#FF4242',
    bright_sun: '#FFD04A',
    cosmic_latte: '#F2FBF7',
    emerald: '#2F936D',
    dulux_lemon: '#FFE087',
    shiraz: '#B30036',
};

export const shadows = {
    dark_green_shadow:
        '-12px -12px 20px #F0FDF9, 10px 10px 24px rgba(92, 193, 155, 0.4)',
    light_green_shadow: '5px 5px 16px rgba(92, 193, 155, 0.2)',
    dark_grey_shadow: '10px 20px 24px rgba(36, 58, 71, 0.3)',
    light_grey_shadow: '4px 4px 16px rgba(36, 58, 71, 0.2)',
    blue_shadow: '10px 10px 24px rgba(36, 48, 224, 0.6)',
    blue_shadow_light: '10px 10px 24px rgba(36, 48, 224, 0.3)',
    dark_red_shadow: '5px 5px 16px rgba(255, 66, 66, 0.4)',
    light_red_shadow: '5px 5px 16px rgba(255, 66, 66, 0.2)',
    silver_shadow: '0 30px 120px rgba(233, 233, 241, 0.5);',
    silver_shadow_reverse: '-100px 30px 120px rgba(233, 233, 241, 0.5);',
    icon_shadow: '5px 8px 16px rgba(36, 48, 224, 0.25)',
    icon_shadow_light: '5px 5px 16px rgba(36, 48, 224, 0.15)',
};

export const global = {
    fontSizes: {
        xxs: 10,
        xs: 12,
        s: 14,
        m: 16,
        l: 18,
        xl: 20,
        xxl: 24,
        xxxl: 32,
        big: 36,
        max: 64,

        mobile_title: 26,
    },

    fontWeights: {
        light: 300,
        regular: 400,
        semiBold: 600,
        bold: 700,
        black: 900,
    },

    borderRadius: {
        smaller: 2,
        small: 4,
        default: 6,
        card: 10,
        switch: 15,
        bubble: 20,
        button: 30,
        input: 40,
        circle: 50,
    },

    transitions: {
        default: 0.5,
        medium: 0.25,
    },

    fontFamily: {
        open_sans: `'Open Sans', sans-serif`,
        sketched: `'Just Me Again Down Here', cursive`,
    },

    cursors: {
        auto: 'auto',
        default: 'default',
        disabled: 'not-allowed',
        pointer: 'pointer',
        text: 'text',
        grab: 'grab',
    },

    opacityColors: {
        violet: 'rgba(80, 89, 230, 0.3)',
    },
};
