import { gql, useQuery } from '@apollo/client';
import {
    MostPopularContentsQuery,
    MostPopularContentsQueryVariables,
} from '@@types/graphql/MostPopularContentsQuery';

const MOST_POPULAR_CONTENTS_QUERY = gql`
    query MostPopularContentsQuery(
        $expected: ExpectedContentsInput
        $paginationInput: SearchPaginationInput
        $typeFilter: ContentTypeFilter
    ) {
        getMostPopularContents(
            expected: $expected
            paginationInput: $paginationInput
            typeFilter: $typeFilter
        ) {
            ... on LiveExperience {
                _id
                featured
                name
                thumbnail
                type
            }
            ... on Course {
                _id
                featured
                name
                thumbnail
                type
            }
            ... on StandaloneVideo {
                _id
                featured
                name
                thumbnail
                type
            }
        }
    }
`;

export default () => {
    return useQuery<
        MostPopularContentsQuery,
        MostPopularContentsQueryVariables
    >(MOST_POPULAR_CONTENTS_QUERY, {
        variables: {
            paginationInput: {
                take: 10,
            },
            expected: {
                courseCount: 1,
                standaloneVideoCount: 3,
            },
            typeFilter: {
                COURSE: true,
                LIVE_EXPERIENCE: false,
                STANDALONE_VIDEO: true,
            },
        },
    });
};
