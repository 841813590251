import { gql, useMutation } from '@apollo/client';
import { IUploadStudentsSummaryTableItem } from '@domains/Teacher/UploadStudents/UploadStudentsSummaryPage/_components/UploadStudentsSummaryList/UploadStudentsSummaryList';
import { notify } from '@components/ToastNotification/ToastNotification';
import { setStudentsUploadModal } from '@state/_redux/layout/actions';
import { ToastTypes } from '@@types/CommonTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import paths from '@shared/paths';
import {
    addErrorStudentsList,
    clearStudentsList,
} from '@state/_redux/uploadCSV/actions';
import {
    CreateManyStudentsMutation,
    CreateManyStudentsMutationVariables,
} from '@@types/graphql/CreateManyStudentsMutation';

const CREATE_MANY_STUDENTS_MUTATION = gql`
    mutation CreateManyStudentsMutation($input: CreateManyStudentsInput!) {
        createManyStudents(input: $input) {
            successfullyAdded
            errors {
                error
                index
            }
        }
    }
`;

export default (isSummary?: boolean) => {
    const { studentsList, errorStudentsList } = useSelector(
        (state) => state.uploadCSV,
    );
    const list = isSummary ? errorStudentsList : studentsList;

    const dispatch = useDispatch();
    const history = useHistory();

    return useMutation<
        CreateManyStudentsMutation,
        CreateManyStudentsMutationVariables
    >(CREATE_MANY_STUDENTS_MUTATION, {
        onCompleted: (data) => {
            const summaryStudents: IUploadStudentsSummaryTableItem[] = [];
            data.createManyStudents.errors.forEach((studentError) => {
                summaryStudents.push({
                    ...list[studentError.index],
                    status: studentError.error,
                    id: summaryStudents.length + 1,
                    number: summaryStudents.length + 1,
                });
            });

            dispatch(clearStudentsList());
            dispatch(addErrorStudentsList(summaryStudents));
            dispatch(setStudentsUploadModal(false));

            if (summaryStudents.length)
                history.push({
                    pathname: paths.upload_summary_teacher,
                    state: {
                        count: data.createManyStudents.successfullyAdded,
                    },
                });
            else {
                history.push(paths.students_teacher);
                notify('You have successfully added all students')[
                    ToastTypes.SUCCESS
                ]();
            }
        },
        onError: (error) => {
            dispatch(setStudentsUploadModal(false));
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
