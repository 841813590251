import React from 'react';
import { Button } from '@components/_universal';
import {
    ActivityFeedEmptyStateWrapper,
    BoldText,
    Text,
} from './ActivityFeedEmptyState.styled';

interface IProps {
    resetFilters: () => void;
}

const ActivityFeedEmptyState: React.FC<IProps> = ({ resetFilters }) => {
    return (
        <ActivityFeedEmptyStateWrapper>
            <BoldText>It seems there is no activity here yet.</BoldText>
            <Text>
                Please use different filters or go back to the default view.
            </Text>
            <Button buttonType="secondary" onPress={resetFilters}>
                reset all filters
            </Button>
        </ActivityFeedEmptyStateWrapper>
    );
};

export default ActivityFeedEmptyState;
