import React from 'react';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';

import {
    SignedUpExperienceWrapper,
    StyledBigHeader,
    StyledCounter,
} from '../ExperienceInfo.styled';

interface ExperienceNotStartProps {
    className?: string;
    onTimerComplete: () => void;
}

export const ExperienceNotStart = ({
    className,
    onTimerComplete,
}: ExperienceNotStartProps) => {
    return (
        <SignedUpExperienceWrapper className={className}>
            <StyledBigHeader>This experience will start in...</StyledBigHeader>
            <StyledCounter
                onComplete={() => {
                    onTimerComplete();
                }}
                userType={USER_TYPE.TEACHER}
            />
        </SignedUpExperienceWrapper>
    );
};
