import React from 'react';
import { FormRow, InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';
import { Input } from '@components/_form-elements';
import { useForm } from 'react-hook-form';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import {
    useChangeEmailMutation,
    useUpdateTeacherMutation,
} from '@state/settings/mutations';
import {
    ActionButton,
    Actions,
    EditAccountInformationFormWrapper,
    InputAdditionalTextWrapper,
} from './EditAccountInformationForm.styled';

interface IProps {
    closeEdit: () => void;
    defaultValues: {
        email: string;
        name: string;
        surname: string;
    };
}

interface IForm {
    email: string;
    name: string;
    surname: string;
}

const EditAccountInformationForm: React.FC<IProps> = ({
    closeEdit,
    defaultValues,
}) => {
    const [
        updateTeacherMutation,
        { loading: updateTeacherLoading },
    ] = useUpdateTeacherMutation(() => closeEdit());

    const [
        changeEmailMutation,
        { loading: changeEmailLoading },
    ] = useChangeEmailMutation();

    const { errors, handleSubmit, register } = useForm<IForm>({
        defaultValues,
        resolver: yupResolver(validation),
        mode: 'onBlur',
    });

    const onSubmit = (formValues: IForm) => {
        updateTeacherMutation({
            variables: {
                input: {
                    name: formValues.name,
                    surname: formValues.surname,
                },
            },
        });
        formValues.email !== defaultValues.email &&
            changeEmailMutation({
                variables: {
                    input: {
                        newEmail: formValues.email,
                    },
                },
            });
    };

    return (
        <EditAccountInformationFormWrapper>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.name}
                        label={'Name'}
                        name={'name'}
                        placeholder={'Enter name'}
                        register={register}
                    />
                </InputWrapper>
                <InputWrapper>
                    <InputForm
                        errors={errors.surname}
                        label={'Surname'}
                        name={'surname'}
                        placeholder={'Enter surname'}
                        register={register}
                    />
                </InputWrapper>
            </FormRow>
            <FormRow>
                <InputAdditionalTextWrapper>
                    <InputForm
                        additionalText={
                            'Email address is necessary to login to the 8Billionideas platform.'
                        }
                        errors={errors.email}
                        label={'Parent email address'}
                        name={'email'}
                        placeholder={'e.g. joedoe@mail.com'}
                        register={register}
                        type={'email'}
                    />
                </InputAdditionalTextWrapper>
            </FormRow>
            <Actions>
                <ActionButton
                    buttonType={'secondary'}
                    onPress={() => closeEdit()}
                    type="button"
                >
                    Cancel
                </ActionButton>
                <ActionButton
                    buttonType={'primary'}
                    disabled={updateTeacherLoading || changeEmailLoading}
                    onPress={handleSubmit(onSubmit)}
                >
                    Save
                </ActionButton>
            </Actions>
        </EditAccountInformationFormWrapper>
    );
};

const InputForm = FieldMessage(Input);

export default EditAccountInformationForm;
