import styled, { css } from 'styled-components';
import { FieldError } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';

export const CreatableSelectWrapper = styled.div<{ marginBottom?: number }>`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin-bottom: ${({ marginBottom }) =>
        typeof marginBottom === 'number' ? marginBottom : 24}px;
`;

export const Label = styled.label<{ isSearchable?: boolean }>(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.text};
        font-size: ${fontSizes.xs}px;
        font-weight: ${fontWeights.bold};
        margin: 8px 0 8px 25px;
        text-align: left;
        text-transform: uppercase;
        width: 100%;
    `,
);

export const InnerWrapper = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    width: 100%;
`;

const errorBorder = css`
    border: 1px solid ${({ theme }) => theme.colors.error};

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.error};
    }
`;

export const StyledCreatableSelect = styled(CreatableSelect)<{
    errors?: FieldError;
    isDisabled?: boolean;
    renderInnerIcon?: boolean;
}>(
    ({
        errors,
        isDisabled,
        renderInnerIcon,
        theme: {
            borderRadius,
            colors,
            cursors,
            dark_grey_shadow,
            fontSizes,
            fontWeights,
        },
    }) => css`
        flex: 1;
        opacity: ${isDisabled ? '0.4' : 1};
        outline: none;
        text-align: left;

        .react-select {
            &__value-container {
                color: ${colors.text};
                font-size: ${fontSizes.s}px;
                line-height: 1.3;
                padding: 2px 22px;

                ${renderInnerIcon && 'padding-left: 50px'};

                &--has-value {
                    color: ${colors.text};
                    font-weight: ${fontWeights.semiBold};
                }
            }

            &__single-value {
                color: ${colors.text};
            }

            &__control {
                background: ${colors.background_secondary};
                border-radius: ${borderRadius.default}px;
                box-shadow: none;
                color: ${colors.primary};
                cursor: ${cursors.pointer};
                font-size: ${fontSizes.s}px;
                min-height: 45px;
                outline: none;
                text-align: left;

                &:hover {
                    border: 1px solid ${colors.text};
                }

                ${!!errors && errorBorder};

                &--is-focused {
                    border: 1px solid ${colors.text};
                }

                &--is-disabled {
                    border: 1px solid ${colors.text};
                    color: ${colors.text};
                }

                &--menu-is-open {
                    .react-select__single-value {
                        color: ${colors.text_assistive};
                    }

                    &:hover {
                        border: 1px solid ${colors.text};
                    }
                }
            }

            &__menu {
                background: ${colors.background_secondary};
                border-radius: ${borderRadius.default}px;
                box-shadow: ${dark_grey_shadow};
                font-size: ${fontSizes.s}px;
                list-style-type: none;
                margin-top: 4px;
                min-width: 10px;
                outline: none;
                overflow: hidden;
                position: absolute;
                z-index: 99;
            }

            &__menu-list {
                padding-bottom: 0;
                padding-top: 0;
            }

            &__option {
                align-items: center;
                background: transparent;
                color: ${colors.text};
                cursor: ${cursors.pointer};
                display: flex;
                font-size: ${fontSizes.s}px;
                height: 45px;
                outline: none;
                padding-left: 22px;
                padding-right: 22px;
                text-align: left;

                &:hover {
                    background: ${colors.background_quaternary};
                }

                &--is-focused {
                    background: ${colors.background_quaternary};

                    &:hover,
                    &:focus,
                    &:active {
                        background: ${colors.background_quaternary};
                    }
                }

                &--is-selected {
                    background: ${colors.background_secondary};
                    color: ${colors.text};
                    display: flex;
                    font-weight: ${fontWeights.bold};
                    justify-content: space-between;

                    &:hover,
                    &:focus,
                    &:active {
                        background: ${colors.background_quaternary};
                    }
                }
            }

            &__indicator-separator {
                display: none;
            }
        }

        .react-select {
            &__control {
                border-radius: ${borderRadius.button}px;
                border: 1px solid ${colors.background_tertiary};
                min-height: 54px;

                &:hover {
                    border: 1px solid ${colors.background_tertiary};
                }

                ${!!errors && errorBorder};
            }
            &__menu {
                border-radius: ${borderRadius.button}px;
            }
        }
    `,
);
