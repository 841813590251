import { gql, useQuery } from '@apollo/client';
import { SubscriptionChildsListQuery } from '@@types/graphql/SubscriptionChildsListQuery';

export const SUBSCRIPTION_CHILDS_LIST_QUERY = gql`
    query SubscriptionChildsListQuery {
        me {
            id
            childs {
                id
                avatar
                name
            }
        }
    }
`;

export default () =>
    useQuery<SubscriptionChildsListQuery>(SUBSCRIPTION_CHILDS_LIST_QUERY, {
        fetchPolicy: 'network-only',
    });
