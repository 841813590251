import styled from 'styled-components';

export const FormRow = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: -16px;
    margin-right: -16px;
    position: relative;
`;

export const InputWrapper = styled.div`
    flex: 1;
    padding: 0 16px;
`;

export const ButtonRow = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: -16px;
    margin-right: -16px;
`;
