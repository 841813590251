import { setIsUnreadNotifications } from '@state/_redux/notifications/actions';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
    useCheckForNewNotificationQuery,
    useNotificationsQuery,
} from '@state/notifications/queries';
import { useAuthMeQuery } from '@state/auth/queries';

export default () => {
    const [lastNotificationId, setLastNotificationId] = useState<
        number | null | undefined
    >();
    const { data: authData } = useAuthMeQuery();

    const dispatch = useDispatch();
    const [getNotificationsList, { data }] = useNotificationsQuery(true);
    const [
        checkNewNotifications,
        { data: newNotificationsData },
    ] = useCheckForNewNotificationQuery();

    useEffect(() => {
        authData && getNotificationsList();
        // eslint-disable-next-line
    }, [authData]);

    useEffect(() => {
        if (data?.getUserNotifications) {
            setLastNotificationId(data.getUserNotifications[0]?.id ?? null);
            const unreadNotifications = data.getUserNotifications.filter(
                (notification) => !notification.completedAt,
            );

            !!unreadNotifications.length &&
                dispatch(setIsUnreadNotifications(true));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (lastNotificationId !== undefined) {
            checkNewNotifications({
                variables: {
                    cursor: lastNotificationId,
                },
            });
        }
        // eslint-disable-next-line
    }, [lastNotificationId]);

    useEffect(() => {
        if (newNotificationsData?.checkForNewNotifications) {
            getNotificationsList();
        }
        // eslint-disable-next-line
    }, [newNotificationsData]);
};
