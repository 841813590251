import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';

export const DetailsCardWrapper = styled.div`
    align-items: center;
    display: flex;

    & + & {
        margin-top: 8px;
    }
`;

export const IconWrapper = styled.div<{ userType?: USER_TYPE }>(
    ({ userType }) => css`
        align-items: center;
        background: ${({ theme }) => theme.colors.live_experience};
        border-radius: ${({ theme }) => theme.borderRadius.card}px;
        display: flex;
        flex-shrink: 0;
        height: 88px;
        justify-content: center;
        width: 88px;
        ${userType === USER_TYPE.TEACHER &&
        css`
            background: ${({ theme }) => theme.colors.live_experience_teacher};
        `}
    `,
);

export const CardIcon = styled(IconManager)``;

export const DetailsWrapper = styled.div`
    margin-left: 24px;
`;

export const StyledTitle = styled(DefaultText)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 0;
`;

export const Description = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 2px;
    text-transform: uppercase;
`;
