import React from 'react';
import { useHistory } from 'react-router-dom';
import paths from '@shared/paths';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Nullable } from '@@types/CommonTypes';
import {
    MobileAvatarDescription,
    MobileAvatarIcon,
    MobileAvatarList,
    MobileAvatarTitle,
    MobileChooseAvatarPageWrapper,
    MobileOrnamentIcon,
    MobilePageContent,
    MobilePageFooter,
    StyledAvatar,
    StyledContinueButton,
} from './MobileChooseAvatarPage.styled';

export interface IProps {
    avatar: Nullable<number>;
    control: UseFormMethods['control'];
    selectAvatar: (avatar: number) => void;
}

const MobileChooseAvatarPage: React.FC<IProps> = ({
    avatar,
    control,
    selectAvatar,
}) => {
    const history = useHistory();

    return (
        <MobileChooseAvatarPageWrapper>
            <MobilePageContent>
                <MobileAvatarTitle>
                    Welcome to your 8billionideas account
                </MobileAvatarTitle>
                <MobileAvatarDescription>
                    It's great to meet you! Choose your avatar to personalise
                    your account.
                </MobileAvatarDescription>
                <MobileAvatarIcon
                    name={'ChooseYourAvatarText'}
                    size={276}
                    sizeY={162}
                />
                <MobileOrnamentIcon name={'ChooseAvatarOrnaments'} size={320} />
                <Controller
                    control={control}
                    name={'avatar'}
                    render={(props) => (
                        <MobileAvatarList>
                            {[...Array(8).keys()].map((_, index) => (
                                <StyledAvatar
                                    avatar={index}
                                    iconSelectedSize={113}
                                    iconSize={75}
                                    isActive={index === props.value}
                                    key={index}
                                    onClick={() => props.onChange(index ?? 0)}
                                />
                            ))}
                        </MobileAvatarList>
                    )}
                />
            </MobilePageContent>
            <MobilePageFooter>
                <StyledContinueButton
                    buttonType={'primary'}
                    disabled={typeof avatar !== 'number'}
                    onPress={() => history.push(paths.set_password)}
                    size={'small'}
                >
                    Continue
                </StyledContinueButton>
            </MobilePageFooter>
        </MobileChooseAvatarPageWrapper>
    );
};

export default MobileChooseAvatarPage;
