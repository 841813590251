import styled, { css } from 'styled-components';
import lePlaceholder from '@assets/images/le_placeholder.png';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';

export const VideoThumbnailWrapper = styled.div<{ userType?: USER_TYPE }>(
    ({ userType }) => css`
        left: 50%;
        position: absolute;
        top: 0;
        transform: translate(-50%, -50%);
        width: 354px;

        ${userType === USER_TYPE.TEACHER &&
        css`
            top: unset;
        `}

        img {
            height: 100%;
            width: 100%;
        }
    `,
);

export const VideoPlaceholder = styled.div(
    ({
        theme: {
            borderRadius,
            colors,
            cursors,
            fontSizes,
            fontWeights,
            light_grey_shadow,
        },
    }) => css`
        align-items: center;
        background-image: url(${lePlaceholder});
        background-position: center;
        background-size: cover;
        border-radius: ${borderRadius.card}px;
        cursor: ${cursors.pointer};
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        width: 100%;

        &::before {
            background-color: ${colors.background_secondary};
            border-radius: ${borderRadius.default}px;
            box-shadow: ${light_grey_shadow};
            content: 'ABOUT';
            font-size: ${fontSizes.xxs}px;
            font-weight: ${fontWeights.bold};
            left: -4px;
            line-height: 1.5;
            padding: 5px 24px;
            position: absolute;
            top: 24px;
            z-index: 9;
        }
    `,
);
