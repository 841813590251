import styled, { css } from 'styled-components';
import { ColorType } from '@@types/CommonTypes';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const PasswordValidationContainer = styled.div<{ marginTop?: number }>`
    padding-left: 28px;
    ${({ marginTop }) =>
        marginTop &&
        css`
            margin-top: ${marginTop}px;
        `}
`;

export const ValidationText = styled(DefaultText)<{
    color?: ColorType;
    isBold?: boolean;
}>`
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    line-height: 1.7;
    margin: 0;
    ${({ color, theme }) => `color: ${theme.colors[color ?? 'text']};`}
    ${({ isBold, theme }) =>
        isBold && `font-weight: ${theme.fontWeights.semiBold};`}
`;

export const ValidationList = styled.ul<{ color?: ColorType }>`
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    margin: 0 0 16px;
    padding-left: 24px;
    ${({ color, theme }) => `color: ${theme.colors[color ?? 'text']};`}
`;

export const ValidationElement = styled.li``;

export const ValidationIcon = styled(IconManager)`
    margin-left: 8px;
    display: inline-block;
    transform: translateY(2px);
`;
