import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import TrialInformation from '@components/_universal/TrialInformation/TrialInformation';
import { IconName } from '@@types/CommonTypes';
import {
    TrialLeftInformationButton,
    TrialLeftInformationTip,
    TrialLeftInformationTooltip,
    TrialLeftInformationWrapper,
    TrialLeftInformationTipButton,
} from './TrialLeftInformation.styled';
import { useOnClickOutside } from '@utils/hooks';
import logout from '@utils/logout';
import paths from '@shared/paths';
import { useHistory } from 'react-router';
import useTrial from '@utils/hooks/useTrial';
import { useAuthMeQuery } from '@state/auth/queries';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';

export interface IProps {
    className?: string;
    variant: 'default' | 'medium' | 'big-green';
    withoutAction?: boolean;
}

const variants: Record<
    IProps['variant'],
    {
        icon: IconName;
        size: number;
    }
> = {
    default: {
        icon: 'TrailNormalFrame',
        size: 104,
    },
    medium: {
        icon: 'TrailNormalFrame',
        size: 159,
    },
    'big-green': {
        icon: 'TrailGreenFrame',
        size: 189,
    },
};

const TrialLeftInformation: React.FC<IProps> = ({
    className,
    variant,
    withoutAction,
}) => {
    const [tooltipState, setTooltipState] = useState(false);
    const { daysText, isTrialEnded, hadTrialNoPlan } = useTrial();
    const { data: meData } = useAuthMeQuery();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const history = useHistory();

    useOnClickOutside(buttonRef, () => setTooltipState(false), tooltipRef);

    const isChild = meData?.me.type === USER_TYPE.CHILD;
    const shouldDisplay = hadTrialNoPlan && !isTrialEnded;

    const handleClick = () => {
        isChild
            ? setTooltipState((prevState) => !prevState)
            : history.push(paths.choose_your_plan);
    };

    if (!shouldDisplay) return null;
    return (
        <TrialLeftInformationWrapper className={className}>
            <TrialLeftInformationButton
                onClick={handleClick}
                ref={buttonRef}
                withoutAction={withoutAction}
            >
                <TrialInformation
                    backgroundIcon={variants[variant].icon}
                    daysText={daysText}
                    trialText="FREE TRIAL LEFT"
                    size={variants[variant].size}
                    varaint={
                        variant === 'medium' || variant === 'big-green'
                            ? 'large'
                            : 'default'
                    }
                />
            </TrialLeftInformationButton>

            {isChild && tooltipState && (
                <TrialLeftInformationTooltip
                    ref={tooltipRef}
                    size={variants[variant].size}
                >
                    <TrialLeftInformationTip backgroundColor="trial_tip_background">
                        There {/* TODO: temporary solution, do it better */}
                        {daysText.includes('a ') || daysText.includes('an ')
                            ? 'is'
                            : 'are'}{' '}
                        {daysText} left in the free trial period. To continue
                        using our platform please ask your parent to start a
                        subscription.
                        <TrialLeftInformationTipButton
                            onPress={() => logout()}
                            buttonType="secondary"
                            size="small"
                        >
                            login as parent
                        </TrialLeftInformationTipButton>
                    </TrialLeftInformationTip>
                </TrialLeftInformationTooltip>
            )}
        </TrialLeftInformationWrapper>
    );
};

export default TrialLeftInformation;
