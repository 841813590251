import { SelectType } from '@@types/CommonTypes';
import { sortBy } from '@shared/filters';
import {
    CONTENT_TYPE,
    LIBRARY_VIEW_CONTENT_SORTING,
} from '@@types/graphql/GraphqlGlobalTypes';

export type FiltersModule = {
    library: {
        sortBy: SelectType<LIBRARY_VIEW_CONTENT_SORTING>;
        topic: string[];
        type: CONTENT_TYPE;
    };
};

export const initialState: FiltersModule = {
    library: {
        sortBy: sortBy[0],
        topic: [],
        type: CONTENT_TYPE.LIVE_EXPERIENCE,
    },
};
