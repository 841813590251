import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button, IconManager } from '@components/_universal';

export const MobileLogoIcon = styled(IconManager)`
    @media (max-width: ${breakpoints.sm}px) {
        font-size: 126px;
    }
`;

export const MobileLogoLink = styled.a``;

export const MobileLoginLinkPageWrapper = styled.div`
    @media (max-width: ${breakpoints.lg}px) {
        align-items: center;
        align-self: center;
        background: rgba(81, 91, 230, 0.95);
        border-radius: ${({ theme }) => theme.borderRadius.circle}px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        max-height: 800px;
        min-height: 734px;
        overflow: hidden;
        padding-top: 54px;
        width: 100%;
    }

    @media (max-width: ${breakpoints.md}px) {
        border-radius: ${({ theme }) => theme.borderRadius.button}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        min-height: 630px;
        padding-top: 24px;
    }
`;

export const FormWrapper = styled.div`
    @media (max-width: ${breakpoints.lg}px) {
        width: 554px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        margin-top: 0;
        padding: 0 28px;
        width: 100%;
    }

    @media (max-width: ${breakpoints.xs}px) {
        padding: 0 16px;
    }
`;

export const MobileHelloIcon = styled(IconManager)`
    @media (max-width: ${breakpoints.lg}px) {
        font-size: 100px;
        margin-bottom: 16px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        font-size: 64px;
        margin: -14px 0 0 28px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        margin-left: 20px;
    }
`;

export const ButtonLink = styled(Button)`
    margin: 24px 0 16px;
    width: 100%;

    @media (max-width: ${breakpoints.sm}px) {
        margin: 16px 0 16px;
    }
`;
