import styled from 'styled-components';
import { ModalTitle, ModalText } from '@components/_modals/GlobalModals.styled';

export const StyledModalTitle = styled(ModalTitle)`
    margin-bottom: 16px;
`;

export const StyledModalText = styled(ModalText)`
    margin-bottom: 48px;
`;
