import React from 'react';
import { IconManager } from '@components/_universal';
import {
    InnerWrapper,
    RateCourseWrapper,
    RateSubmitedBakcgound,
    RateSubmitedText,
    RateSubmitedWrapper,
    Text,
    Title,
} from './RateCourse.styled';

interface IProps {
    isRated?: boolean;
}

const RateCourse: React.FC<IProps> = ({ children, isRated = false }) => {
    return (
        <RateCourseWrapper>
            {!isRated ? (
                <InnerWrapper>
                    <Title>Rate a course</Title>
                    <Text>Your opinion is important to us. Share it!</Text>
                    {children}
                </InnerWrapper>
            ) : (
                <RateSubmitedWrapper>
                    <IconManager name={'ThankYou'} size={198} sizeY={51} />
                    <RateSubmitedText>
                        Your feedback is much appreciated. It helps us to
                        improve our content!
                    </RateSubmitedText>
                    <IconManager name={'Laptop'} size={328} sizeY={224} />
                    <RateSubmitedBakcgound
                        name={'ThankYouBackground'}
                        size={369}
                        sizeY={417}
                    />
                </RateSubmitedWrapper>
            )}
        </RateCourseWrapper>
    );
};

export default RateCourse;
