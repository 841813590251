import styled from 'styled-components';
import TrialLeftInformation from '@components/TrialLeftInformation/TrialLeftInformation';
import { Tip } from '@components/_universal';
import {
    BigHeader,
    DefaultText,
    Title,
} from '@components/_universal/Typography.styled';

export const DesktopSubscriptionPageWrapper = styled.div`
    margin-bottom: 60px;
`;

export const ChoosePlanHeader = styled.div`
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
`;

export const StyledTitle = styled(Title)`
    margin: 0;
`;

export const StyledDescription = styled(BigHeader)`
    margin: 0;
`;

export const ChildrenTitle = styled(DefaultText)`
    margin: 0;
`;

export const StyledTip = styled(Tip)`
    background-color: ${({ theme }) => theme.colors.course};
    display: none;
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    left: 2px;
    line-height: 1.65;
    padding: 16px 20px;
    position: absolute;
    top: 50px;
    width: 292px;
    z-index: 9;

    &::after {
        border-bottom: 14px solid ${({ theme }) => theme.colors.course};
        border-right: 16px solid transparent;
        border-top: none;
        content: '';
        height: 0;
        left: 16px;
        position: absolute;
        top: -14px;
        width: 0;
    }
`;

export const ChildrenListIconWrapper = styled.div`
    position: relative;

    &:hover ${StyledTip} {
        display: block;
    }
`;

export const ChoosePlanTexts = styled.div`
    display: grid;
    gap: 16px;
    margin-bottom: 16px;
`;

export const ChoosePlanReminder = styled.div`
    background-color: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.course},
        inset 0 0 0 3px ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.text_white};
    display: flex;
    flex-shrink: 0;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.8;
    padding: 16px 28px;
`;

export const Discount = styled.div`
    background-color: ${({ theme }) => theme.colors.live_experience};
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xxs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.4;
    margin-left: 10px;
    padding: 6px 24px;
    text-transform: uppercase;
`;

export const ChoosePlanChildren = styled.div`
    display: grid;
    gap: 8px;
    align-content: flex-start;
`;

export const ChildrenListHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const ChoosePlanColumns = styled.div`
    align-items: flex-start;
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: 1fr 728px;
    margin: 0 auto;
`;

export const ChoosePaymentWrapper = styled.div`
    display: grid;
    grid-column-gap: 12px;
    grid-template-columns: 1fr 1fr;
`;

export const ChooseTrialLeftInformation = styled(TrialLeftInformation)`
    position: absolute;
    top: 12px;
    right: 36px;

    @media (max-height: 768px) {
        right: -12px;
        top: -22px;
        transform: scale(0.7);
    }
`;
