import React, { useEffect, useState } from 'react';
import { errorMessages } from '@utils/shared/validations/formErrors';
import { FieldError, useFormContext } from 'react-hook-form';
import { FormRow, InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';
import { InputPassword } from '@components/_form-elements';
import { SetPasswordFormWrapper } from './SetPasswordForm.styled';
import { useCustomRegister } from '@utils/hooks';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import PasswordValidation from '@components/_form-elements/PasswordValidation/PasswordValidation';

export interface IProps {
    setIsValid: (value: boolean) => void;
}

const SetPasswordForm: React.FC<IProps> = ({ setIsValid }) => {
    const { errors, register, watch } = useFormContext();
    const password = watch('newPassword');
    const confirmPassword = watch('confirmPassword');
    const [isFocused, setIsFocused] = useState(password.length > 0);

    const isValidConfirmPassword =
        password === confirmPassword || !confirmPassword.length;

    const { refElement: passwordInputEl } = useCustomRegister<HTMLInputElement>(
        register,
    );

    useEffect(() => {
        const onFocus = () => setIsFocused(true);

        passwordInputEl.current?.addEventListener('focus', onFocus);

        return () => {
            passwordInputEl.current?.removeEventListener('focus', onFocus);
        };
    }, []);

    return (
        <SetPasswordFormWrapper>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.newPassword}
                        label={`password`}
                        marginBottom={isFocused ? 8 : 16}
                        maxLength={256}
                        name={'newPassword'}
                        placeholder={'Enter your password '}
                        register={passwordInputEl}
                        staticPosition
                        type="password"
                    />
                </InputWrapper>
            </FormRow>
            {isFocused && (
                <PasswordValidation
                    marginTop={errors.newPassword && 8}
                    password={password}
                    setIsValid={setIsValid}
                />
            )}
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={
                            errors.confirmPassword || !isValidConfirmPassword
                                ? ({
                                      message: errorMessages.CONFIRM_PASSWORD,
                                  } as FieldError)
                                : undefined
                        }
                        label={`confirm Password`}
                        marginBottom={0}
                        maxLength={256}
                        name={'confirmPassword'}
                        placeholder={'Re-enter your password'}
                        register={register}
                        staticPosition
                        type="password"
                    />
                </InputWrapper>
            </FormRow>
        </SetPasswordFormWrapper>
    );
};

const InputForm = FieldMessage(InputPassword);

export default SetPasswordForm;
