import React from 'react';
import InfoTiles from '@domains/Parent/Course/_components/InfoTiles/InfoTiles';
import VideoPlayerThumbnail from '@assets/images/temp/videoPlayerThumbnail.svg';
import { IconManager } from '@components/_universal';
import { useChildCourseDetailsQuery } from '@state/childManagement/queries';
import { useParams } from 'react-router';
import {
    CourseDescription,
    CourseParentPageWrapper,
    CourseWrapper,
    InnerWrapper,
    PageTitle,
    SectionHeader,
    PageSubtitle,
    ThumbnailWrapper,
    VideoThumbnail,
    ThumbnailBadge,
} from './CourseParentPage.styled';
import Loader from '@components/_universal/Loader/Loader';

const CourseParentPage: React.FC = () => {
    const { courseId } = useParams<{
        courseId: string;
    }>();
    const { data, loading } = useChildCourseDetailsQuery(courseId);

    return (
        <CourseParentPageWrapper>
            <PageTitle>Course Page</PageTitle>
            <SectionHeader>
                {loading ? <Loader center /> : data?.getCourse.name}
            </SectionHeader>
            {!loading && (
                <CourseWrapper>
                    <InnerWrapper>
                        <InfoTiles
                            lessons={data?.getCourse.lessons.length ?? 0}
                            totalTime={`${data?.getCourse.duration ?? '-'} h`}
                        />
                        <PageSubtitle>Course description</PageSubtitle>
                        <CourseDescription>
                            {data?.getCourse.description}
                        </CourseDescription>
                    </InnerWrapper>
                    <ThumbnailWrapper>
                        <VideoThumbnail>
                            <ThumbnailBadge backgroundColor="background_secondary">
                                About
                            </ThumbnailBadge>
                            {/* TODO: add video */}
                            <img
                                src={
                                    data?.getCourse.thumbnail ??
                                    VideoPlayerThumbnail
                                }
                            />
                        </VideoThumbnail>
                        <IconManager name={'Grass'} size={418} sizeY={40} />
                    </ThumbnailWrapper>
                </CourseWrapper>
            )}
        </CourseParentPageWrapper>
    );
};

export default CourseParentPage;
