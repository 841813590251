import { Button } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';
import styled, { css } from 'styled-components';

export const EmptyStateSearchWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        align-items: center;
        border-radius: ${borderRadius.card}px;
        border: 1px solid ${colors.stroke};
        display: flex;
        flex-direction: column;
        margin: 8px 0 32px;
        padding: 56px 224px;
    `,
);

export const Text = styled(DefaultText)(
    ({ theme: { colors } }) => css`
        color: ${colors.text_secondary};
        line-height: 1.75;
        margin-bottom: 32px;
        text-align: center;
    `,
);

export const BoldText = styled(Text)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin: 0;
    `,
);

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 12px;
    position: relative;
`;

export const UploadButton = styled(Button)`
    width: 294px;
`;

export const SampleCsvText = styled.p(
    ({ theme: { colors, cursors, fontSizes, fontWeights } }) => css`
        color: ${colors.primary};
        cursor: ${cursors.pointer};
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.65;
        margin-bottom: 12px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            text-underline-position: under;
        }
    `,
);

export const UploadWrapper = styled.div`
    margin: 0 12px 8px;
`;
