import client from '@boot/apolloClient';
import { CHILDS_LIST_QUERY } from '@state/childManagement/queries/useChildsListQuery';
import { CancelRenewSubscriptionMutation } from '@@types/graphql/CancelRenewSubscriptionMutation';
import { FragmentChildsListQuery } from '@@types/graphql/FragmentChildsListQuery';
import { ToastTypes } from '@@types/CommonTypes';
import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';

const CANCEL_RENEW_SUBSCRIPTION_MUTATION = gql`
    mutation CancelRenewSubscriptionMutation {
        cancelDeactivationSubscription
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<CancelRenewSubscriptionMutation>(
        CANCEL_RENEW_SUBSCRIPTION_MUTATION,
        {
            onCompleted: async ({ cancelDeactivationSubscription }) => {
                onCompleted?.();
                client.writeFragment<FragmentChildsListQuery>({
                    id: `User:${cancelDeactivationSubscription}`,
                    fragment: gql`
                        fragment FragmentChildsListQuery on User {
                            deactivateAtCurrentPeriodEnd
                        }
                    `,
                    data: {
                        deactivateAtCurrentPeriodEnd: false,
                    },
                });
                notify(
                    'Congratulations! Your subscription has been successfully renewed.',
                )[ToastTypes.SUCCESS]();
            },
            onError: () => {
                notify(
                    'Unfortunately, we cannot renew your subscription now. Please try again later or contact our support!',
                )[ToastTypes.ERROR]();
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: CHILDS_LIST_QUERY,
                },
            ],
        },
    );
};
