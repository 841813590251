import { CONTENT_CONSUMER_USER } from '@@types/CommonTypes';
import { RouterConfig } from '..';
import paths from '@shared/paths';
import React from 'react';
import renderRedirect from '@services/RenderRedirect';
import {
    AccountLayout,
    AccountSubLayout,
    CoursesLayout,
    CourseViewLayout,
    LibraryLayout,
    LiveExperienceLayout,
    MainLayout,
    OnboardingLayout,
    VideoViewLayout,
} from '@components/index';
import {
    AccountPage,
    AgreeRulesPage,
    BehaviourAgreementPage,
    ChooseAvatarPage,
    CoursePage,
    CoursesPage,
    DashboardPage,
    HelpFaqPage,
    LessonPage,
    LibraryPage,
    LiveExperiencePage,
    NotificationSettingsPage,
    Onboarding,
    ProfilPage,
    SaveForLaterPage,
    SetPasswordPage,
    VideoViewPage,
} from '@domains/index';

export const contentConsumerRoutes: RouterConfig = [
    // Account Layout
    {
        allowFor: CONTENT_CONSUMER_USER,
        isPrivate: true,
        routes: [
            {
                component: AccountPage,
                path: paths.account,
                subRoutes: {
                    routes: [
                        {
                            component: renderRedirect(paths.account_profile),
                            exact: true,
                            path: paths.account,
                        },
                        {
                            component: ProfilPage,
                            exact: true,
                            path: paths.account_profile,
                        },
                        {
                            component: NotificationSettingsPage,
                            exact: true,
                            path: paths.account_notification_settings,
                        },
                        {
                            component: HelpFaqPage,
                            exact: true,
                            path: paths.account_help_and_faq,
                        },
                        {
                            component: BehaviourAgreementPage,
                            exact: true,
                            path: paths.account_behaviour_agreement,
                        },
                    ],
                    layoutComponent: AccountSubLayout,
                },
            },
        ],
        layoutComponent: AccountLayout,
    },

    // Onboarding Layout
    {
        allowFor: CONTENT_CONSUMER_USER,
        isPrivate: true,
        type: 'onboarding',
        routes: [
            {
                component: Onboarding,
                path: paths.onboarding,
                subRoutes: {
                    routes: [
                        {
                            component: renderRedirect(paths.choose_avatar),
                            exact: true,
                            path: paths.onboarding,
                        },
                        {
                            component: ChooseAvatarPage,
                            exact: true,
                            path: paths.choose_avatar,
                        },
                        {
                            component: SetPasswordPage,
                            exact: true,
                            path: paths.set_password,
                        },
                        {
                            component: AgreeRulesPage,
                            exact: true,
                            path: paths.agree_rules,
                        },
                    ],
                    layoutComponent: React.Fragment,
                },
            },
        ],
        layoutComponent: OnboardingLayout,
    },

    // Courses Layout
    {
        allowFor: CONTENT_CONSUMER_USER,
        isPrivate: true,
        routes: [
            {
                component: CoursesPage,
                exact: true,
                path: paths.courses,
            },
            {
                component: SaveForLaterPage,
                exact: true,
                path: paths.save_for_later,
            },
        ],
        layoutComponent: CoursesLayout,
    },

    // Video View Layout
    {
        allowFor: CONTENT_CONSUMER_USER,
        isPrivate: true,
        routes: [
            {
                component: VideoViewPage,
                exact: true,
                path: paths.video_view(':videoId'),
            },
        ],
        layoutComponent: VideoViewLayout,
    },

    // Library Layout
    {
        allowFor: CONTENT_CONSUMER_USER,
        isPrivate: true,
        routes: [
            {
                component: LibraryPage,
                exact: true,
                path: paths.library,
            },
        ],
        layoutComponent: LibraryLayout,
    },

    // Course Layout
    {
        allowFor: CONTENT_CONSUMER_USER,
        isPrivate: true,
        routes: [
            {
                component: CoursePage,
                exact: true,
                path: paths.course(':courseId'),
            },
            {
                component: LessonPage,
                exact: true,
                path: paths.course_lesson(':courseId', ':courseLessonId'),
            },
        ],
        layoutComponent: CourseViewLayout,
    },

    // Live experience Layout
    {
        allowFor: CONTENT_CONSUMER_USER,
        isPrivate: true,
        routes: [
            {
                component: LiveExperiencePage,
                exact: true,
                path: paths.live_experience(':liveExperienceId'),
            },
        ],
        layoutComponent: LiveExperienceLayout,
    },

    // Main Layout
    {
        allowFor: CONTENT_CONSUMER_USER,
        isPrivate: true,
        routes: [
            {
                component: DashboardPage,
                exact: true,
                path: paths.dashboard,
            },
        ],
        layoutComponent: MainLayout,
    },
];
