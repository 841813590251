import React from 'react';
import { useChildLiveExperienceVisibility } from '@domains/Child/Library/LibraryPage/useChildLiveExperienceVisibility';
import {
    Content,
    FooterBar,
    HeaderBar,
    MainLayoutWrapper,
    NavigationBar,
    Sidebar,
} from './MainLayout.styled';

interface IProps {}

export const MainLayout: React.FC<IProps> = ({ children }) => {
    useChildLiveExperienceVisibility();

    return (
        <MainLayoutWrapper>
            <HeaderBar isTransparent trialIcon />
            <NavigationBar />
            <Content>
                {children}
                <FooterBar isBackpack isLogo />
            </Content>
            <Sidebar />
        </MainLayoutWrapper>
    );
};
export default MainLayout;
