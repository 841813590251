import { gql, useLazyQuery } from '@apollo/client';
import { TypeUserQuery } from '@@types/graphql/TypeUserQuery';

const TYPE_USER_QUERY = gql`
    query TypeUserQuery {
        me {
            type
        }
    }
`;

export default () =>
    useLazyQuery<TypeUserQuery>(TYPE_USER_QUERY, {
        fetchPolicy: 'network-only',
    });
