import { gql, useQuery } from '@apollo/client';
import { YourUpcomingLiveExperiencesQuery } from '@@types/graphql/YourUpcomingLiveExperiencesQuery';

const UPCOMING_LIVE_EXPERIENCE_QUERY = gql`
    query YourUpcomingLiveExperiencesQuery {
        getYourUpcomingLiveExperiences {
            _id
            thumbnail
            type
            name
            description
            featured
            status
            topicTags {
                _id
            }
            skillTags {
                _id
            }
            duration
            maxNoParticipants
            startDate
            link
            facilitatedBy {
                id
            }
            createdBy
            createdAt
            activityMaterials {
                filename
                filesize
            }
            homeworkMaterials {
                filename
                filesize
            }
            isUserSignuped
            noOfSignups
        }
    }
`;

export default () =>
    useQuery<YourUpcomingLiveExperiencesQuery>(UPCOMING_LIVE_EXPERIENCE_QUERY);
