import { ADMIN_URL } from '@shared/constants';
import { Button, IconManager } from '@components/_universal';
import { useHistory } from 'react-router';
import { useSchoolQuery } from '@state/teachers/queries';
import { useSelector } from 'react-redux';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import logout from '@utils/logout';
import Notifications from '@components/Notifications/Notifications';
import paths from '@shared/paths';
import React from 'react';
import useHeader from '@components/_global/Header/useHeader';
import {
    AccountIcon,
    NotificationWrapper,
    HeaderTrialLeftInformation,
    NotificationIcon,
} from '@components/_global/Header/Header.styled';
import {
    AdminLogoutWrapper,
    BoldText,
    IconWrapper,
    SchoolInfo,
    StyledHeaderWrapper,
} from './TeacherHeader.styled';

interface IProps {
    className?: string;
    isTransparent?: boolean;
    trialIcon?: boolean;
}

const Header: React.FC<IProps> = ({ className, trialIcon }) => {
    const {
        accountActive,
        isUnread,
        notificationsActive,
        onClose,
        refIcon,
        refNotifications,
        setNotificationsActive,
    } = useHeader('/teacher/account/*');

    const history = useHistory();
    const { isAdminAsTeacher } = useSelector((state) => state.signup);
    const { data: school, loading } = useSchoolQuery(!isAdminAsTeacher);

    const schoolName = school?.getSchool.name;
    const schoolId = school?.getSchool.id;

    const handleLogOut = () => {
        LocalStorageManager.removeValue('sessionToken');
        LocalStorageManager.removeValue('refreshToken');
        window.location.href = ADMIN_URL + '/schools/' + schoolId;
        window.history.replaceState(null, '', ADMIN_URL + '/schools/');
    };

    return (
        <>
            {!loading && (
                <StyledHeaderWrapper
                    {...{ className }}
                    displayLogout={isAdminAsTeacher}
                >
                    {isAdminAsTeacher && (
                        <AdminLogoutWrapper>
                            <SchoolInfo>
                                <IconManager
                                    name="EyeOnTeacherPage"
                                    size={24}
                                />
                                You are viewing {schoolName} school as a{' '}
                                <BoldText>Teacher</BoldText>
                            </SchoolInfo>
                            <Button
                                buttonType={'secondary'}
                                onPress={() => {
                                    handleLogOut();
                                }}
                                size="small"
                                type="button"
                            >
                                Back to admin view
                            </Button>
                        </AdminLogoutWrapper>
                    )}
                    <IconWrapper>
                        <AccountIcon
                            activeFill={['text_white', 'primary', 'border']}
                            activeStroke={3}
                            cursor="pointer"
                            hoverFill={['icons_hover']}
                            isActive={accountActive}
                            name="CircleTeacherProfile"
                            onClick={() => history.push(paths.account_teacher)}
                            size={40}
                        />

                        <NotificationWrapper
                            ref={refIcon}
                            unreadNotifications={isUnread}
                        >
                            <NotificationIcon
                                activeFill={['text_white', 'border']}
                                cursor="pointer"
                                hoverFill={['icons_hover']}
                                isActive={notificationsActive}
                                name="CircleTeacherBell"
                                onClick={() =>
                                    notificationsActive
                                        ? onClose()
                                        : setNotificationsActive(true)
                                }
                                size={40}
                            />
                        </NotificationWrapper>
                        {notificationsActive && (
                            <Notifications ref={refNotifications} />
                        )}
                        <AccountIcon
                            cursor="pointer"
                            hoverFill={['icons_hover']}
                            isActive={accountActive}
                            name="CircleTeacherLogout"
                            onClick={() => logout()}
                            size={40}
                        />
                        {trialIcon && (
                            <HeaderTrialLeftInformation variant="default" />
                        )}
                    </IconWrapper>
                </StyledHeaderWrapper>
            )}
        </>
    );
};

export default Header;
