import React from 'react';
import Carousel from '@components/_universal/Carousel/Carousel';
import paths from '@shared/paths';
import { SwiperSlide } from 'swiper/react';
import { useHistory } from 'react-router-dom';
import { useMatchMedia } from '@utils/hooks';
import {
    MobileDescription,
    MobilePricingWrapper,
    MobileSignupPricingPageWrapper,
    MobileTitle,
    StyledChoosePayment,
} from './MobileSignupPricingPage.styled';
import FreeTrial from '@domains/Auth/_components/FreeTrial/FreeTrial';
import { BillingCycle } from '@@types/graphql/GraphqlGlobalTypes';

export interface IProps {}

const MobileMobileSignupPricingPage: React.FC<IProps> = () => {
    const history = useHistory();
    const { match } = useMatchMedia('sm');

    return (
        <MobileSignupPricingPageWrapper>
            <MobileTitle>
                Wide range of possibilities with one easy subscription.
            </MobileTitle>
            <MobileDescription>
                Check out the platform prices if you decide to buy a
                subscription after the 10-day trial
            </MobileDescription>
            <MobilePricingWrapper>
                <Carousel
                    disabled={!match}
                    withArrows={false}
                    spaceBetween={16}
                >
                    <SwiperSlide style={{ width: 320 }}>
                        <StyledChoosePayment
                            currentValue={320}
                            paymentType={BillingCycle.YEAR}
                        >
                            Annual
                        </StyledChoosePayment>
                    </SwiperSlide>
                    <SwiperSlide style={{ width: 320 }}>
                        <StyledChoosePayment buttonType={'secondary'}>
                            Monthly
                        </StyledChoosePayment>
                    </SwiperSlide>
                </Carousel>
            </MobilePricingWrapper>
            <FreeTrial pricingVersion />
        </MobileSignupPricingPageWrapper>
    );
};

export default MobileMobileSignupPricingPage;
