import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import { Title, DefaultText } from '@components/_universal/Typography.styled';
import Avatar from '@components/_universal/Avatar/Avatar';

export const DashboardPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DashboardHeaderWrapper = styled.div`
    display: flex;
    padding: 70px 85px 55px;
    position: relative;
`;

export const DashboardHeader = styled.div`
    margin-left: 16px;
    width: 45%;
`;

export const AvatarWrapper = styled.div`
    height: 76px;
    width: 76px;
`;

export const AvatarIcon = styled(Avatar)`
    margin-top: 28px;
`;

export const TrumpetIcon = styled(IconManager)`
    margin-left: 32px;
`;

export const DashboardOrnament = styled(IconManager)`
    bottom: -60px;
    position: absolute;
    right: 85px;
    z-index: 9;
`;

export const DashboardTitle = styled(Title)`
    align-items: center;
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.max}px;
    font-family: ${({ theme }) => theme.fontFamily.sketched};
    margin-bottom: 0;
`;

export const PersonName = styled.span`
    display: inline-flex;
    font-family: ${({ theme }) => theme.fontFamily.sketched};
    margin-left: 8px;
`;

export const DashboardDescription = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

// childManagement content wrapper
export const DashboardPageContent = styled.div`
    background: ${({ theme }) => theme.colors.background_primary};
    border-radius: ${({ theme }) =>
        `${theme.borderRadius.button}px ${theme.borderRadius.button}px 0 0`};
    flex: 1;
    padding: 160px 0 120px 85px;
`;
