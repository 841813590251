import { useEffect, useRef } from 'react';

export const useSynchronizeRef = (ref: any, indeterminate: boolean) => {
    const internalRef = useRef<HTMLInputElement>();

    function synchronizeRefs(htmlInputElement: HTMLInputElement) {
        internalRef.current = htmlInputElement;

        if (ref)
            typeof ref === 'object'
                ? (ref.current = htmlInputElement)
                : ref(htmlInputElement);
    }

    useEffect(() => {
        if (internalRef.current) {
            internalRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);

    return synchronizeRefs;
};
