import React from 'react';
import IconManager from '@components/_universal/IconManager/IconManager';
import { SaveForLaterWrapper } from './SaveForLater.styled';

export interface IProps {
    isActive: boolean;
    disabled?: boolean;
    onClick: (value: boolean) => void;
    size?: number;
}

const SaveForLater: React.FC<IProps> = ({
    disabled,
    isActive,
    onClick,
    size = 32,
}) => {
    return (
        <SaveForLaterWrapper
            disabled={disabled}
            size={size}
            onClick={() => onClick(!isActive)}
        >
            <IconManager
                activeFill={['primary', 'primary']}
                cursor="pointer"
                hoverFill={['background_quaternary']}
                isActive={isActive}
                name="Heart"
                size={size}
            />
        </SaveForLaterWrapper>
    );
};

export default SaveForLater;
