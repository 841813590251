import StudentsListUploader from '@components/StudentsListUploader/StudentsListUploader';
import React from 'react';
import {
    ButtonWrapper,
    EmptyBox,
    EmptyBoxText,
    EmptyStudentsListWrapper,
    HeaderText,
    StyledButton,
    StyledLinkText,
    TextBold,
} from './EmptyStudentsList.styled';

const EmptyStudentsList: React.FC = () => {
    return (
        <EmptyStudentsListWrapper>
            <HeaderText>Students list</HeaderText>
            <EmptyBox>
                <EmptyBoxText>
                    <TextBold>
                        It seems you haven't uploaded the list of students yet.
                    </TextBold>
                    Upload a csv file with the division of children into classes
                    and year groups according to the example shown in the{' '}
                    <StyledLinkText href={''} target={'_blank'}>
                        instructional video
                    </StyledLinkText>
                    , and we will send them invitations to the platform.
                </EmptyBoxText>
                <ButtonWrapper>
                    <StyledButton
                        buttonType={'secondary'}
                        onPress={() =>
                            window.open(
                                '/students-example.csv',
                                '_blank',
                                'noopener,noreferrer',
                            )
                        }
                    >
                        Download example file
                    </StyledButton>
                    <StudentsListUploader />
                </ButtonWrapper>
            </EmptyBox>
        </EmptyStudentsListWrapper>
    );
};

export default EmptyStudentsList;
