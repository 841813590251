import { gql, useQuery } from '@apollo/client';
import { SchoolQuery } from '@@types/graphql/SchoolQuery';

const GET_SCHOOL_QUERY = gql`
    query SchoolQuery {
        getSchool {
            address
            city
            enableHubspotChat
            enableLE
            id
            licensesInUse
            name
            noLicenses
        }
    }
`;

export default (skip?: boolean) =>
    useQuery<SchoolQuery>(GET_SCHOOL_QUERY, {
        fetchPolicy: 'network-only',
        skip: skip,
    });
