import React from 'react';
import { ColorType, IconName } from '@@types/CommonTypes';
import {
    RuleText,
    RuleTextBold,
} from '@domains/Auth/Onboarding/_components/Rule/Rule.styled';

interface IRule {
    backgroundColor: ColorType;
    image: {
        name: IconName;
        size: number;
    };
    ruleCard: React.ReactNode;
    text: string;
    textColor?: ColorType;
}

export const RULES: IRule[] = [
    {
        backgroundColor: 'live_experience',
        image: { name: 'Respect', size: 26 },
        ruleCard: (
            <>
                <RuleTextBold>
                    Speak online as you would in real life.{' '}
                </RuleTextBold>
                <RuleText>
                    Never be mean, negative or rude on 8billionideas.
                </RuleText>
            </>
        ),
        text: 'Respect for others',
        textColor: 'text_white',
    },
    {
        backgroundColor: 'secondary',
        image: { name: 'RightTime', size: 26 },
        ruleCard: (
            <>
                <RuleTextBold>
                    Show up to Experiences before they go live,{' '}
                </RuleTextBold>
                <RuleText>so you can be ready for when they start!</RuleText>
            </>
        ),
        text: 'Right place, right time',
        textColor: 'text_white',
    },
    {
        backgroundColor: 'video',
        image: { name: 'BadLanguage', size: 26 },
        ruleCard: (
            <>
                <RuleText>8billionideas is </RuleText>
                <RuleTextBold>
                    not the place for swearing or rude words.
                </RuleTextBold>
            </>
        ),
        text: 'Never use bad language',
    },
    {
        backgroundColor: 'article',
        image: { name: 'Player', size: 26 },
        ruleCard: (
            <>
                <RuleText>
                    Sometimes your video will be shared with facilitators during
                    mentoring.{' '}
                </RuleText>
                <RuleTextBold>
                    Be sure to keep your feed appropiate.
                </RuleTextBold>
            </>
        ),
        text: 'Keep your video appropiate',
    },
    {
        backgroundColor: 'course',
        image: { name: 'Doubts', size: 26 },
        ruleCard: (
            <>
                <RuleText>
                    Our team is here to answer you questions. If you need
                    anything at all -{' '}
                </RuleText>
                <RuleTextBold>feel free to ask them!</RuleTextBold>
            </>
        ),
        text: 'If in doubt, ask',
        textColor: 'text_white',
    },
];
