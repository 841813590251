import React from 'react';
import {
    Content,
    PageTitle,
    ParentAccountSubLayoutWrapper,
    SubNavigationBar,
} from './ParentAccountSubLayout.styled';

interface IProps {}

const ParentAccountSubLayout: React.FC<IProps> = ({ children }) => {
    return (
        <ParentAccountSubLayoutWrapper>
            <PageTitle>My account</PageTitle>
            <SubNavigationBar />
            <Content>{children}</Content>
        </ParentAccountSubLayoutWrapper>
    );
};

export default ParentAccountSubLayout;
