import React from 'react';
import { ModalContent } from '@components/_modals/GlobalModals.styled';
import { StyledTitle } from './TeacherCreatePasswordModal.styled';
import CreatePasswordForm from '@components/_modals/GlobalModals/__forms/CreatePasswordForm/CreatePasswordForm';
import CreatePasswordTip from '@components/CreatePasswordTip/CreatePasswordTip';
import Modal from '@components/_universal/Modal/Modal';

export interface IProps {}

const TeacherCreatePasswordModal: React.FC<IProps> = () => {
    return (
        <Modal>
            <ModalContent>
                <StyledTitle>Create your password</StyledTitle>
                <CreatePasswordForm />
                <CreatePasswordTip />
            </ModalContent>
        </Modal>
    );
};

export default TeacherCreatePasswordModal;
