import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import { setLiveExperiencesVisibility } from './actions';

export default createReducer(initialState).handleAction(
    setLiveExperiencesVisibility,
    (state, { payload }) => ({
        ...state,
        liveExperiencesVisibility: payload,
    }),
);
