import React from 'react';
import { useHistory } from 'react-router-dom';
import paths from '@shared/paths';
import {
    ButtonWrapper,
    ExperienceManIcon,
    FullyBookedExperienceWrapper,
    FullyBookedIcon,
    StyledBigHeader,
    StyledButton,
    StyledCounter,
} from '@domains/Child/LiveExperience/_components/ExperienceInfo/ExperienceInfo.styled';

interface IProps {
    className?: string;
}

export const FullyBookedExperience: React.FC<IProps> = ({ className }) => {
    const history = useHistory();

    return (
        <FullyBookedExperienceWrapper className={className}>
            <ExperienceManIcon
                name={'ExperienceInfoMan'}
                size={143}
                sizeY={139}
            />
            <FullyBookedIcon name={'FullyBooked'} size={167} sizeY={80} />
            <StyledBigHeader>This experience will start in...</StyledBigHeader>
            <StyledCounter />
            <ButtonWrapper>
                <StyledButton
                    buttonType={'secondary'}
                    onPress={() => history.push(paths.library)}
                >
                    Check out other experiences
                </StyledButton>
            </ButtonWrapper>
        </FullyBookedExperienceWrapper>
    );
};

export default FullyBookedExperience;
