import paths from '@shared/paths';

export const LINKS = [
    {
        label: 'Profile',
        path: paths.account_profile,
    },
    {
        label: 'Notification settings',
        path: paths.account_notification_settings,
    },
    {
        label: 'Help & FAQ',
        path: paths.account_help_and_faq,
    },
    {
        label: 'Good behaviour agreement',
        path: paths.account_behaviour_agreement,
    },
];
