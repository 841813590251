import styled, { css } from 'styled-components';
import {
    DefaultText,
    SubTitle,
} from '@components/_universal/Typography.styled';

export const LibraryTeacherPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PageTitle = styled(SubTitle)`
    align-items: center;
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    padding-top: 34px;
`;

export const FilterWrapper = styled.div`
    margin-bottom: 28px;
`;

export const FilterTitle = styled(DefaultText)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.secondary};
        font-size: ${fontSizes.xs}px;
        font-weight: ${fontWeights.bold};
        margin-bottom: 16px;
        text-transform: uppercase;
    `,
);
