import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';

export const ActivityFeedEmptyStateWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        align-items: center;
        border-radius: ${borderRadius.card}px;
        border: 1px solid ${colors.stroke};
        display: flex;
        flex-direction: column;
        margin: 8px 0 32px;
        padding: 56px;
    `,
);

export const Text = styled(DefaultText)(
    ({ theme: { colors } }) => css`
        color: ${colors.text_secondary};
        line-height: 1.75;
        margin-bottom: 32px;
        text-align: center;
    `,
);

export const BoldText = styled(Text)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin: 0;
    `,
);
