import { gql, useMutation } from '@apollo/client';
import { ToastTypes } from '@@types/CommonTypes';
import { notify } from '@components/ToastNotification/ToastNotification';
import {
    DeleteStudentsAccountsMutation,
    DeleteStudentsAccountsMutationVariables,
} from '@@types/graphql/DeleteStudentsAccountsMutation';

const DELETE_STUDENTS_ACCOUNTS_MUTATION = gql`
    mutation DeleteStudentsAccountsMutation(
        $input: DeleteStudentsAccountsInput!
    ) {
        deleteStudentsAccounts(input: $input) {
            id
        }
    }
`;

export default (callback: () => void) => {
    return useMutation<
        DeleteStudentsAccountsMutation,
        DeleteStudentsAccountsMutationVariables
    >(DELETE_STUDENTS_ACCOUNTS_MUTATION, {
        onCompleted: () => {
            callback();
            notify('Changes have been successfully saved.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
