import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import { setLibraryTabs } from './actions';

export default createReducer(initialState).handleAction(
    setLibraryTabs,
    (state, { payload }) => ({
        ...state,
        library: {
            ...state.library,
            ...payload,
        },
    }),
);
