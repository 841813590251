import React from 'react';
import { externalLinks } from '@shared/paths';
import {
    Content,
    FooterBar,
    FooterMobileOrnamentIcon,
    FooterWrapper,
    Header,
    LogoSignupIcon,
    SignUpLink,
    SignupLayoutWrapper,
} from './SignupLayout.styled';

interface IProps {
    backgroundPosition?: 'right' | 'left';
}

const SignupLayout: React.FC<IProps> = ({
    backgroundPosition = 'left',
    children,
}) => {
    return (
        <SignupLayoutWrapper backgroundPosition={backgroundPosition}>
            <Header>
                <SignUpLink href={externalLinks.homepage} target="_blank">
                    <LogoSignupIcon
                        fill={['background_secondary', 'background_secondary']}
                        name={'LogoWithText'}
                        size={206}
                        sizeY={45}
                    />
                </SignUpLink>
            </Header>
            <Content>{children}</Content>
            <FooterWrapper>
                <FooterMobileOrnamentIcon
                    size={90}
                    sizeY={52}
                    name={'FooterMobileOrnamentIcon'}
                />
                <FooterBar isWhiteText />
            </FooterWrapper>
        </SignupLayoutWrapper>
    );
};

export default SignupLayout;
