import { gql, useMutation } from '@apollo/client';
import { AUTH_ME_QUERY } from '@state/auth/queries/useAuthMeQuery';
import { FinishOnboardingMutation } from '@@types/graphql/FinishOnboardingMutation';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

const FINISH_ONBOARDING_MUTATION = gql`
    mutation FinishOnboardingMutation($avatarId: Int, $password: String) {
        finishOnboarding(input: { avatarId: $avatarId, password: $password }) {
            id
            avatar
            name
            surname
            type
            status
            gender
            email
            birthday
            address
            city
            zipCode
            countryCode
            parent {
                id
            }
            onboardingCompletedAt
            createdAt
            updatedAt
            hasPassword
        }
    }
`;

export default () =>
    useMutation<FinishOnboardingMutation>(FINISH_ONBOARDING_MUTATION, {
        refetchQueries: [
            {
                query: AUTH_ME_QUERY,
            },
        ],
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
