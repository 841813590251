import React, { useEffect, useState } from 'react';
import { agreeRulesValidation, createPasswordValidation } from './validation';
import { FormProvider, useForm } from 'react-hook-form';
import { IAppSubRoutes } from '@routes/index';
import { Nullable } from '@@types/CommonTypes';
import { OnboardingWrapper } from './Onboarding.styled';
import { useHistory, useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import paths from '@shared/paths';
import SubRoutes from '@routes/SubRoutes';

export enum onboardingSteps {
    AVATAR,
    PASSWORD,
    RULES,
}

interface IProps extends IAppSubRoutes {}

export interface IOnboardingForm {
    agreeRules: boolean;
    avatar: Nullable<number>;
    confirmPassword: string;
    newPassword: string;
}

const validations = [undefined, createPasswordValidation, agreeRulesValidation];

const steps = {
    [paths.choose_avatar]: onboardingSteps.AVATAR,
    [paths.set_password]: onboardingSteps.PASSWORD,
    [paths.agree_rules]: onboardingSteps.RULES,
};

const Onboarding: React.FC<IProps> = ({ routes, layoutComponent }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [step, setStep] = useState<onboardingSteps>(steps[pathname]);
    const validation = validations[step];
    const formMethods = useForm<IOnboardingForm>({
        mode: 'onChange',
        shouldUnregister: false,
        resolver: validation && yupResolver(validation),
        defaultValues: {
            agreeRules: false,
            avatar: null,
            confirmPassword: '',
            newPassword: '',
        },
    });

    useEffect(() => {
        history.push(paths.choose_avatar);
    }, []);

    useEffect(() => {
        setStep(steps[pathname]);
    }, [pathname]);

    return (
        <OnboardingWrapper>
            <FormProvider {...formMethods}>
                <SubRoutes {...{ routes, layoutComponent }} />
            </FormProvider>
        </OnboardingWrapper>
    );
};

export default Onboarding;
