import styled, { css } from 'styled-components';

const activeChip = css`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.chip_color_active};
    /* NOTE: 
        Animate font-weight cause strange shifting, so this is a working replacement. 
        0.5 value replies bold font-weight.
    */
    -webkit-text-stroke: 0.5px ${({ theme }) => theme.colors.chip_color_active};

    &:hover {
        background-color: ${({ theme }) =>
            theme.colors.chip_color_active_hover};
        color: ${({ theme }) => theme.colors.chip_color_active};
    }
`;

export const ChipButton = styled.button<{ active?: boolean }>`
    background-color: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    outline: none;
    padding: 12px 16px;
    transition: all ${({ theme }) => theme.transitions.medium}s;
    -webkit-text-stroke: 0.5px transparent;

    &:hover {
        background-color: ${({ theme }) => theme.colors.background_quaternary};
    }

    &:active {
        box-shadow: ${({ theme }) => theme.light_green_shadow};
    }

    ${({ active }) => active && activeChip}
`;
