import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { IconManager } from '@components/_universal';

export const NavigationWrapper = styled.div`
    height: 100%;
    padding: 28px 0;
    position: fixed;
    z-index: 99;

    @media (max-width: ${breakpoints.lg}px) {
        padding: 10px 0;
    }
`;

export const NavigationLogoIcon = styled(IconManager)`
    margin-left: 30px;

    @media (max-width: ${breakpoints.lg}px) {
        margin-left: 16px;
    }

    @media (max-width: ${breakpoints.md}px) {
        font-size: 44px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        font-size: 36px;
    }
`;
