import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';
import { breakpoints } from '@shared/constants';

export const SignupSuccessfulPaymentWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;

    @media (max-width: ${breakpoints.lg}px) {
        padding-top: 12px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        flex-direction: column;
    }
`;

export const PaymentSuccessIcon = styled(IconManager)`
    @media (max-width: ${breakpoints.md}px) {
        font-size: 300px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        font-size: 190px;
    }
`;

export const TextWrapper = styled.div`
    margin-left: 32px;

    @media (max-width: ${breakpoints.sm}px) {
        margin: 24px auto 0;
    }
`;

export const RegisterSuccessfulTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 10px;
    position: relative;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.xxl}px;
        text-align: center;
    }
`;

export const SignUpRegisterOrnament = styled(IconManager)`
    position: absolute;
    right: -100px;
    top: -70px;

    @media (max-width: ${breakpoints.md}px) {
        right: 0;
        top: -100px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        right: -50px;
        top: -120px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        display: none;
    }
`;

export const RegisterSuccessfulDescription = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
    max-width: 555px;

    @media (max-width: ${breakpoints.md}px) {
        padding-right: 30px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        max-width: 100%;
        padding-right: 0;
        text-align: center;
    }
`;
