import styled from 'styled-components';
import Footer from '@components/_global/Footer/Footer';
import ParentHeader from '@components/_global/Header/ParentHeader/ParentHeader';
import Navigation from '@components/_global/Navigation/ParentNavigation/ParentNavigation';

export const ParentMainLayoutWrapper = styled.div`
    display: grid;
    grid-template-areas: 'navigation content';
    grid-template-columns: 120px 1fr;
    grid-template-rows: 1fr;
    height: 100%;
`;

export const HeaderBar = styled(ParentHeader)``;

export const NavigationBar = styled(Navigation)`
    grid-area: navigation;
`;

export const InnerWrapper = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    padding: 0 100px;
    width: 100%;
`;

export const Content = styled.div`
    display: grid;
    flex-direction: column;
    grid-area: content;
    grid-template-columns: auto;
    grid-template-rows: 1fr 90px;
    width: 100%;
`;

export const FooterBar = styled(Footer)``;
