import React, { useRef } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { DefaultText } from '@components/_universal/Typography.styled';
import { ModalForm } from '@domains/Teacher/Library/LibraryTeacherPage/_modals/RecommendTheContentModal/RecommendTheContentModal.styled';
import { multiSelectValidation } from '@utils/shared/validations/formErrors';
import { useForm } from 'react-hook-form';
import { useMatchMedia, useOnClickOutside } from '@utils/hooks';
import { useAllStudentsList } from '@utils/hooks/useAllStudentsList';
import { useUpdateStudentsGroup } from '@state/students/mutations/useUpdateStudentsGroup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import Modal from '@components/_universal/Modal/Modal';
import Select from '@components/_form-elements/Select/Select';
import {
    StudentsListQuery,
    StudentsListQuery_getStudentsList_users,
} from '@@types/graphql/StudentsListQuery';
import {
    ButtonsWrapper,
    ModalButton,
    ModalContent,
    ModalTitle,
} from '@components/_modals/GlobalModals.styled';

interface AssignStudentsFormProps {
    students: { value: string; label: string }[];
}

interface AssignStudentsModalProps {
    groupId: string;
    groupStudentsList?: StudentsListQuery_getStudentsList_users[];
    onClose: () => void;
    refetch: () => Promise<ApolloQueryResult<StudentsListQuery>>;
}

const validation = yup.object().shape({
    students: multiSelectValidation,
});

export const AssignStudentsModal = ({
    groupId,
    groupStudentsList,
    onClose,
    refetch,
}: AssignStudentsModalProps) => {
    const { match } = useMatchMedia('md');
    const ref = useRef(null);
    useOnClickOutside(ref, onClose);

    const { control, errors, handleSubmit } = useForm<AssignStudentsFormProps>({
        resolver: yupResolver(validation),
    });

    const { students: studentsList, studentsLoading } = useAllStudentsList();

    const [updateStudentsGroup] = useUpdateStudentsGroup(() => {
        onClose();
        refetch();
    });

    const onSubmit = (data: AssignStudentsFormProps) => {
        updateStudentsGroup({
            variables: {
                input: {
                    assignUsers: data.students.map((user) => +user.value),
                    groupId: +groupId,
                },
            },
        });
    };

    const studentsFromOutsideGroup = studentsList?.filter(
        (student) =>
            !groupStudentsList?.some(
                (groupStudent) => groupStudent.id === student.id,
            ),
    );
    const options = studentsLoading
        ? []
        : studentsFromOutsideGroup?.map((student) => ({
              label: `${student.name} ${student.surname}`,
              value: student.id,
          }));

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={onClose} />
                <ModalTitle>Assign students</ModalTitle>
                <DefaultText>
                    Please select students you would like to assign to this
                    group.
                </DefaultText>
                <ModalForm noValidate onSubmit={handleSubmit(onSubmit)}>
                    <SelectForm
                        control={control}
                        errors={errors.students}
                        isLoading={studentsLoading}
                        isMulti={true}
                        isSearchable={true}
                        label={'students'}
                        marginBottom={match ? 8 : 24}
                        name={'students'}
                        options={options}
                        placeholder={'Select...'}
                        staticPosition={match}
                    />
                    <ButtonsWrapper>
                        <ModalButton
                            buttonType="secondary"
                            onPress={onClose}
                            type="button"
                        >
                            Cancel
                        </ModalButton>
                        <ModalButton
                            type="submit"
                            buttonType={'primary'}
                            onPress={() => {}}
                        >
                            Confirm
                        </ModalButton>
                    </ButtonsWrapper>
                </ModalForm>
            </ModalContent>
        </Modal>
    );
};

const SelectForm = FieldMessage(Select);
