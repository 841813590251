import React from 'react';
import AgreementText from '@components/AgreementText/AgreementText';
import CreatePasswordForm from '@domains/Auth/_forms/CreatePasswordForm/CreatePasswordForm';
import {
    CreatePasswordWrapper,
    InformationText,
    StyledBigTitle,
} from './CreatePassword.styled';

export interface IProps {}

const CreatePassword: React.FC<IProps> = () => {
    return (
        <CreatePasswordWrapper>
            <InformationText>PARENT SIGNUP - step 2</InformationText>
            <StyledBigTitle>Create your password</StyledBigTitle>
            <CreatePasswordForm />
            <AgreementText />
        </CreatePasswordWrapper>
    );
};

export default CreatePassword;
