import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import checkIcon from '@assets/images/checkIcon.svg';

export const InputWrapper = styled.div<{ marginBottom?: number }>`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin-bottom: ${({ marginBottom }) =>
        typeof marginBottom === 'number' ? marginBottom : 25}px;
`;

export const CheckboxWrapper = styled(InputWrapper)`
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    width: auto;
`;

export const CheckboxLabel = styled.label<{ error?: boolean }>`
    background: transparent;
    border: 1px solid
        ${({ theme, error }) =>
            error ? theme.colors.error : theme.colors.border};
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all ${({ theme }) => theme.transitions.default}s;
    width: 24px;

    &::after {
        background: url(${checkIcon}) center no-repeat;
        content: '';
        height: 24px;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all ${({ theme }) => theme.transitions.default}s;
        width: 24px;
    }
`;

export const CheckboxLabelText = styled.label`
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    padding-left: 16px;
    width: calc(100% - 24px);
`;

export const CheckboxInput = styled.input`
    background: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    cursor: pointer;
    height: 24px;
    visibility: hidden;
    width: 24px;

    &:checked + ${CheckboxLabel} {
        background: ${({ theme }) => theme.colors.primary};
        border-color: ${({ theme }) => theme.colors.primary};

        &::after {
            opacity: 1;
        }
    }
    &:not(:checked):hover + ${CheckboxLabel} {
        background: ${({ theme }) => theme.colors.background_primary};
    }
`;

export const CheckboxError = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.error};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    left: 40px;
    position: absolute;
    top: 100%;
`;
