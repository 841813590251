import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Badge from '@components/_universal/Badge/Badge';
import IconManager from '@components/_universal/IconManager/IconManager';

export const CardHolderWrapper = styled(Link)<{
    width: number | string;
}>(
    ({ width }) => css`
        display: block;
        position: relative;
        text-decoration: none;
        width: ${typeof width === 'number' ? `${width}px` : width};
        &:hover,
        &:focus {
            text-decoration: none;
        }
    `,
);

export const CardHolderBadge = styled(Badge)`
    position: absolute;
    right: -4px;
    top: 16px;
`;

export const FeaturedIcon = styled(IconManager)(
    ({ theme: { light_grey_shadow } }) => css`
        filter: drop-shadow(${light_grey_shadow});
        left: 8px;
        position: absolute;
        top: 8px;
    `,
);
