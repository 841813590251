import React from 'react';
import { IconManager } from '@components/_universal';
import { IFeedback } from '../ActivityFeed/ActivityFeed';
import { StudentsActivityFeedQuery_getActivityFeeds_feeds } from '@@types/graphql/StudentsActivityFeedQuery';
import Avatar from '@components/_universal/Avatar/Avatar';
import dayjs from 'dayjs';
import HomeworkButton from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/HomeworkButton/HomeworkButton';
import {
    ActivityContent,
    ActivityFeedCardWrapper,
    ActivityInnerWrapper,
    ActivityWrapper,
    ContentMark,
    ContentWrapper,
    FeedbackButton,
    PeriodText,
    TimeText,
} from './ActivityFeedCard.styled';
import {
    FEED_TYPE,
    HOMEWORK_FILE_TYPE,
} from '@@types/graphql/GraphqlGlobalTypes';
import {
    activityFeedIcons,
    getContentColor,
    getContentDetails,
    getFeedText,
} from './activityFeedHelpers';

export interface IProps {
    activities: StudentsActivityFeedQuery_getActivityFeeds_feeds[];
    period: string;
    setFeedbackModal: (value: IFeedback) => void;
}

const ActivityFeedCard: React.FC<IProps> = ({
    activities,
    period,
    setFeedbackModal,
}) => {
    return (
        <ActivityFeedCardWrapper>
            <PeriodText>{period}</PeriodText>
            <ContentWrapper>
                {activities.map((activity) => {
                    const contentType = activity.contentType;
                    const homework = activity.homework;
                    const homeworkFiles = homework?.files;
                    const homeworkFile = homeworkFiles?.find(
                        (file) =>
                            file.type === HOMEWORK_FILE_TYPE.USER_HOMEWORK,
                    );

                    return (
                        <ActivityWrapper key={activity._id}>
                            <ActivityInnerWrapper>
                                <ContentMark
                                    contentColor={getContentColor(
                                        activity.contentType,
                                    )}
                                >
                                    <IconManager
                                        size={24}
                                        name={activityFeedIcons[activity.type]}
                                    />
                                </ContentMark>
                                <Avatar
                                    avatar={activity.user?.avatar}
                                    iconSize={40}
                                />
                                <ActivityContent>
                                    {
                                        getFeedText({
                                            activity,
                                        })[activity.type]
                                    }
                                    <TimeText>
                                        {period === 'Today'
                                            ? dayjs(activity.date).from(
                                                  new Date(),
                                              )
                                            : dayjs(activity.date).format(
                                                  'HH:mm',
                                              )}
                                    </TimeText>
                                </ActivityContent>
                            </ActivityInnerWrapper>
                            {activity.type === FEED_TYPE.FEEDBACK &&
                                homework &&
                                contentType && (
                                    <FeedbackButton
                                        buttonType="secondary"
                                        onPress={() =>
                                            setFeedbackModal({
                                                avatar: activity.user?.avatar,
                                                contentName: getContentDetails(
                                                    activity,
                                                )?.contentName as string,
                                                contentType,
                                                homework,
                                                userId: activity.user
                                                    ?.id as number,
                                                username: `${activity.user?.name} ${activity.user?.surname}`,
                                            })
                                        }
                                        size="small"
                                    >
                                        see feedback
                                    </FeedbackButton>
                                )}
                            {activity.type === FEED_TYPE.HOMEWORK &&
                                homeworkFile &&
                                activity.homework && (
                                    <HomeworkButton
                                        file={homeworkFile}
                                        homeworkId={activity.homework.id}
                                    />
                                )}
                        </ActivityWrapper>
                    );
                })}
            </ContentWrapper>
        </ActivityFeedCardWrapper>
    );
};

export default ActivityFeedCard;
