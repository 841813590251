import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal/index';

export const TileIcon = styled(IconManager)`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
`;

export const TileValue = styled(DefaultText)(
    ({ theme: { fontWeights } }) => css`
        color: inherit;
        font-weight: ${fontWeights.semiBold};
        margin: 0;
        min-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
);

export const TileTitle = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        color: inherit;
        font-size: ${fontSizes.xs}px;
        font-weight: ${fontWeights.semiBold};
        margin: 0;
        text-transform: uppercase;
    `,
);
