import {
    CONTENT_TYPE,
    NOTIFICATION_TYPE,
} from '@@types/graphql/GraphqlGlobalTypes';
import dayjs from 'dayjs';
import paths from '@shared/paths';

export type NotificationTypes = Exclude<
    NOTIFICATION_TYPE,
    | NOTIFICATION_TYPE.LE_MENTOR_ASSIGNED_AS_FACILITATOR
    | NOTIFICATION_TYPE.CHILD_FLAG_FOR_BAN
>;

export interface SendByType {
    name: string;
    surname: string;
}

export const getNotification = (
    redirectionId: string,
    contentName?: string,
    contentType?: CONTENT_TYPE,
    sendBy?: SendByType | null,
    startDate?: string,
    userName?: string,
    userSurname?: string,
): Record<
    NotificationTypes,
    { description: string; redirect?: string; title: string }
> => {
    return {
        [NOTIFICATION_TYPE.LE_CHILD_SIGNUP]: {
            description: `${userName} signed up for an ${contentName} at ${dayjs(
                startDate,
            ).format('HH:mm')} on ${dayjs(startDate).format('DD/MM/YYYY')}`,
            title: contentName ?? '',
        },
        [NOTIFICATION_TYPE.CHILD_BEING_BANNED]: {
            description:
                'Your child seems to have violated our Good Behaviour rules and is temporarily banned from our platform. Contact our support team to resolve the issue.',
            title: 'Your child is temporarily banned',
        },
        [NOTIFICATION_TYPE.LE_REMINDER_ONE_DAY]: {
            description: 'This Live Experience starts in 1 day.',
            redirect: paths.live_experience(redirectionId),
            title: contentName ?? '',
        },
        [NOTIFICATION_TYPE.LE_REMINDER_ONE_HOUR]: {
            description: 'This Live Experience starts in 1 hour.',
            redirect: paths.live_experience(redirectionId),
            title: contentName ?? '',
        },
        [NOTIFICATION_TYPE.LE_REMINDER_TEN_MINUTES]: {
            description: 'This Live Experience starts in 10 minutes.',
            redirect: paths.live_experience(redirectionId),
            title: contentName ?? '',
        },
        [NOTIFICATION_TYPE.HOMEWORK_FEEDBACK_LE]: {
            description: 'You received feedback to submitted work.',
            redirect: paths.live_experience(redirectionId),
            title: contentName ?? '',
        },
        [NOTIFICATION_TYPE.HOMEWORK_FEEDBACK_COURSE]: {
            description: 'You received feedback to submitted work.',
            redirect: paths.course(redirectionId),
            title: contentName ?? '',
        },
        [NOTIFICATION_TYPE.STUDENT_BEING_BANNED]: {
            description: `${userName} ${userSurname} seems to have violated our Good Behaviour rules and is temporarily banned from our platform. Contact our support team to resolve the issue.`,
            title: `${userName} ${userSurname} is temporarily banned`,
        },
        [NOTIFICATION_TYPE.CONTENT_RECOMMENDATION]: {
            description: `${sendBy?.name} ${sendBy?.surname} recommended this to you`,
            redirect:
                contentType === CONTENT_TYPE.LIVE_EXPERIENCE
                    ? paths.live_experience(redirectionId)
                    : contentType === CONTENT_TYPE.COURSE
                    ? paths.course(redirectionId)
                    : paths.video_view(redirectionId),
            title: contentName ?? '',
        },
        [NOTIFICATION_TYPE.LE_NOTIFY]: {
            description: `${sendBy?.name} ${sendBy?.surname} shared this to you`,
            redirect: paths.live_experience(redirectionId),
            title: contentName ?? '',
        },
    };
};
