import React from 'react';
import { AuthorizationTokenPage } from '@domains/index';
import { LoginLayout } from '@components/index';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNotifications } from '@utils/hooks';
import CustomRoute from '@components/CustomRoute/CustomRoute';
import paths from '@shared/paths';
import routerConfig, { IMainRouteConfig } from './index';
import useScrollToTop from '@utils/hooks/useScrollToTop';

const RootRouter = () => {
    useScrollToTop();
    useNotifications();

    const authPathMatch = useRouteMatch(paths.authorization_token);

    const prepareLayoutPaths = (routes?: IMainRouteConfig[]) => {
        const layoutPaths: string[] = [];
        routes?.forEach((route) => {
            const subRoutes = route?.subRoutes?.routes?.map(({ path }) => path);
            layoutPaths.push(route.path, ...(subRoutes ?? []));
        });
        return layoutPaths;
    };

    return (
        <Switch>
            {authPathMatch && (
                <LoginLayout>
                    <Route
                        path={paths.authorization_token}
                        component={AuthorizationTokenPage}
                    />
                </LoginLayout>
            )}

            {routerConfig.map(
                (
                    {
                        allowFor,
                        isPrivate,
                        layoutComponent: Layout,
                        routes,
                        type,
                    },
                    index,
                ) => {
                    return (
                        <CustomRoute
                            allowFor={allowFor}
                            exact
                            isPrivate={isPrivate}
                            key={index}
                            path={prepareLayoutPaths(routes)}
                            routeType={type}
                        >
                            <Layout>
                                <Switch>
                                    {routes?.map(
                                        (
                                            { component, subRoutes, ...rest },
                                            routeIndex,
                                        ) => (
                                            <Route
                                                key={routeIndex}
                                                render={(props) => {
                                                    const Component = component as React.ElementType;
                                                    return (
                                                        <Component
                                                            {...props}
                                                            {...subRoutes}
                                                        />
                                                    );
                                                }}
                                                {...rest}
                                            />
                                        ),
                                    )}
                                </Switch>
                            </Layout>
                        </CustomRoute>
                    );
                },
            )}
        </Switch>
    );
};

export default RootRouter;
