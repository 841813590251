import React, { useRef } from 'react';
import { FEEDBACK_VIDEO_MIME_TYPES } from '@shared/constants';
import { HOMEWORK_FILE_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { IFeedback } from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/ActivityFeed/ActivityFeed';
import { Nullable } from '@@types/CommonTypes';
import { useOnClickOutside } from '@utils/hooks';
import Avatar from '@components/_universal/Avatar/Avatar';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import dayjs from 'dayjs';
import HomeworkButton from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/HomeworkButton/HomeworkButton';
import Modal from '@components/_universal/Modal/Modal';
import {
    contentNames,
    getContentName,
    getUserName,
} from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/ActivityFeedCard/activityFeedHelpers';
import {
    ModalContent,
    ModalTitle,
} from '@components/_modals/GlobalModals.styled';
import {
    ActivityInnerWrapper,
    ActivityText,
    ActivityWrapper,
    FeedbackFile,
    FeedbackInnerWrapper,
    FeedbackText,
    FeedbackWrapper,
    MentorData,
    MentorName,
    MentorSecondaryText,
    VideoFeedbackText,
} from './FeedbackModal.styled';

export interface IProps {
    feedback: IFeedback;
    setIsModalOpen: (value: Nullable<IFeedback>) => void;
}

const FeedbackModal: React.FC<IProps> = ({ feedback, setIsModalOpen }) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsModalOpen(null));

    const homeworkFiles = feedback.homework?.files;
    const homeworkFile = homeworkFiles?.find(
        (file) => file.type === HOMEWORK_FILE_TYPE.USER_HOMEWORK,
    );
    const feedbackFile = homeworkFiles?.find(
        (file) => file.type === HOMEWORK_FILE_TYPE.FEEDBACK,
    );

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={() => setIsModalOpen(null)} />
                <ModalTitle>Feedback</ModalTitle>
                <ActivityWrapper>
                    <ActivityInnerWrapper>
                        <Avatar avatar={feedback.avatar} iconSize={40} />
                        <ActivityText>
                            {getUserName(feedback.userId, feedback.username)}{' '}
                            project to{' '}
                            {getContentName({
                                contentName: feedback.contentName,
                            })}{' '}
                            {contentNames[feedback.contentType]}
                        </ActivityText>
                    </ActivityInnerWrapper>
                    {homeworkFile && feedback.homework && (
                        <HomeworkButton
                            file={homeworkFile}
                            homeworkId={feedback.homework.id}
                        />
                    )}
                </ActivityWrapper>
                <FeedbackWrapper>
                    <MentorData>
                        <MentorSecondaryText>Mentor</MentorSecondaryText>
                        <MentorName>
                            {feedback.homework.feedbackBy?.name}{' '}
                            {feedback.homework.feedbackBy?.surname}
                        </MentorName>
                        <MentorSecondaryText>
                            {dayjs(feedback.homework.feedbackGivenAt).format(
                                'DD.MM.YYYY; HH:mm',
                            )}
                        </MentorSecondaryText>
                    </MentorData>
                    <FeedbackInnerWrapper>
                        <FeedbackText>
                            {feedback.homework.mentorFeedback}
                        </FeedbackText>
                        <FeedbackFile>
                            {feedbackFile && feedback.homework && (
                                <HomeworkButton
                                    file={feedbackFile}
                                    homeworkId={feedback.homework.id}
                                >
                                    {FEEDBACK_VIDEO_MIME_TYPES.includes(
                                        feedbackFile.mimetype,
                                    ) && (
                                        <VideoFeedbackText
                                            name="VideoFeedbackText"
                                            size={270}
                                            sizeY={38}
                                        />
                                    )}
                                </HomeworkButton>
                            )}
                        </FeedbackFile>
                    </FeedbackInnerWrapper>
                </FeedbackWrapper>
            </ModalContent>
        </Modal>
    );
};

export default FeedbackModal;
