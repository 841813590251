import {
    selectValidation,
    yearValidation,
} from '@utils/shared/validations/formErrors';
import * as yup from 'yup';

export const validation = yup.object().shape({
    className: selectValidation,
    year: yearValidation,
});
