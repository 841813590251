import styled, { css } from 'styled-components';
import { SubTitle } from '@components/_universal/Typography.styled';
import AccountNavigation from '@domains/Teacher/Account/_components/AccountNavigation/AccountNavigation';

export const TeacherAccountSubLayoutWrapper = styled.div<{
    paddingTop?: boolean;
}>(
    ({ paddingTop }) => css`
        display: grid;
        grid-template-areas: 'title' 'content';
        grid-template-rows: 110px 1fr;
        height: 100%;
        padding: ${paddingTop ? '80px' : '0'};
    `,
);

export const PageTitle = styled(SubTitle)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        grid-area: title;
        padding-left: 100px;
        padding-top: 34px;
    `,
);

export const SubNavigationBar = styled(AccountNavigation)`
    grid-area: navigation;
`;

export const Content = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background: ${colors.background_secondary};
        border-bottom-right-radius: ${borderRadius.circle}px;
        border-top-right-radius: ${borderRadius.circle}px;
        border: 1px solid ${colors.background_tertiary};
        grid-area: content;
        padding: 80px 95px 0;
    `,
);

export const InnerWrapper = styled.div(
    ({ theme: { borderRadius, silver_shadow } }) => css`
        border-radius: ${borderRadius.circle}px;
        box-shadow: ${silver_shadow};
        display: grid;
        gap: 0 4px;
        grid-template-areas: 'navigation content';
        grid-template-columns: 340px 1fr;
        height: 100%;
    `,
);
