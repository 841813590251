import * as yup from 'yup';
import {
    emailValidation,
    errorMessages,
} from '@utils/shared/validations/formErrors';

const loginPasswordValidation = yup.string().required(errorMessages.REQUIRED);

export const validation = yup.object().shape({
    email: emailValidation,
    password: loginPasswordValidation,
});
