import { ADMIN_URL, MENTOR_URL } from '@shared/constants';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import paths from '@shared/paths';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';

export type UserTypeRoutes = Record<
    USER_TYPE,
    {
        landingRoute: string;
        externalLandingRoute?: boolean;
        onboardingRoute?: string;
    }
>;

const isAdminAsTeacher = LocalStorageManager.getValue('adminSessionToken');

export const userTypeRoutes: UserTypeRoutes = {
    [USER_TYPE.ADMIN]: {
        landingRoute: isAdminAsTeacher
            ? paths.dashboard_teacher
            : window.location.origin + ADMIN_URL,
        externalLandingRoute: isAdminAsTeacher ? false : true,
    },
    [USER_TYPE.MENTOR]: {
        landingRoute: window.location.origin + MENTOR_URL,
        externalLandingRoute: true,
    },
    [USER_TYPE.CHILD]: {
        landingRoute: paths.dashboard,
        onboardingRoute: paths.onboarding,
    },
    [USER_TYPE.STUDENT]: {
        landingRoute: paths.dashboard,
        onboardingRoute: paths.onboarding,
    },
    [USER_TYPE.PARENT]: { landingRoute: paths.dashboard_parent() },
    [USER_TYPE.TEACHER]: { landingRoute: paths.dashboard_teacher },
};
