import React from 'react';
import { ArrayField, FieldError } from 'react-hook-form';
import { GENDERS } from '@utils/shared/constans';
import { IChild } from '@domains/Auth/SignupRegisterPage/SignupRegisterPage';
import { ICreateChildForm } from '@domains/Auth/SignupRegisterPage/SignupRegisterPage';
import { Input } from '@components/_form-elements';
import { useFormContext } from 'react-hook-form';
import { useMatchMedia } from '@utils/hooks';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import Select from '@components/_form-elements/Select/Select';
import {
    CreateAccountFormWrapper,
    StyledFormRow,
    StyledInputWrapper,
} from './RegisterChildForm.styled';

interface IProps {
    child: Partial<ArrayField<IChild, 'id'>>;
    currentChild: number;
    emailsAlreadyTaken: string[];
}

const RegisterChildForm: React.FC<IProps> = ({
    child,
    currentChild,
    emailsAlreadyTaken,
}) => {
    const { match } = useMatchMedia('md');
    const {
        control,
        errors,
        register,
        watch,
    } = useFormContext<ICreateChildForm>();
    const email = watch(`childs[${currentChild}].email`);
    const isEmailTaken = emailsAlreadyTaken.includes(email as string);

    return (
        <CreateAccountFormWrapper>
            <StyledFormRow>
                <StyledInputWrapper>
                    <InputForm
                        defaultValue={child.name || ''}
                        errors={errors.childs?.[currentChild]?.name}
                        label={'child name'}
                        marginBottom={match ? 8 : 24}
                        name={`childs[${currentChild}].name`}
                        placeholder={'Enter child name'}
                        register={register()}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
                <StyledInputWrapper>
                    <InputForm
                        defaultValue={child.surname || ''}
                        errors={errors.childs?.[currentChild]?.surname}
                        label={'child surname'}
                        marginBottom={match ? 8 : 24}
                        name={`childs[${currentChild}].surname`}
                        placeholder={'Enter child surname'}
                        register={register()}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
            </StyledFormRow>
            <StyledFormRow>
                <StyledInputWrapper>
                    <InputForm
                        defaultValue={child.email || ''}
                        errors={
                            errors.childs?.[currentChild]?.email ||
                            (isEmailTaken
                                ? ({
                                      message:
                                          'E-mail is already taken. Please use a different one.',
                                  } as FieldError)
                                : undefined)
                        }
                        label={'child email address'}
                        marginBottom={match ? 8 : 24}
                        name={`childs[${currentChild}].email`}
                        placeholder={'e.g. joedoe@mail.com'}
                        register={register()}
                        staticPosition={match}
                        type={'email'}
                    />
                </StyledInputWrapper>
            </StyledFormRow>
            <StyledFormRow>
                <StyledInputWrapper>
                    <SelectForm
                        control={control}
                        errors={errors.childs?.[currentChild]?.gender}
                        isSearchable
                        label={'child gender'}
                        marginBottom={match ? 8 : 24}
                        name={`childs[${currentChild}].gender`}
                        options={GENDERS}
                        placeholder={'Select...'}
                        register={register()}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
                <StyledInputWrapper>
                    <InputForm
                        defaultValue={child.birthday || ''}
                        errors={errors.childs?.[currentChild]?.birthday}
                        label={'child date of birth'}
                        marginBottom={match ? 8 : 24}
                        name={`childs[${currentChild}].birthday`}
                        placeholder={'DD/MM/YYYY'}
                        register={register()}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
            </StyledFormRow>
        </CreateAccountFormWrapper>
    );
};

const InputForm = FieldMessage(Input);
const SelectForm = FieldMessage(Select);

export default RegisterChildForm;
