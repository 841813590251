import styled, { css } from 'styled-components';
import {
    DefaultText,
    SubTitle,
    Title,
} from '@components/_universal/Typography.styled';

export const StudentDetailsPageWrapper = styled.div``;

export const PageTitle = styled(SubTitle)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin: 34px 0 88px;
    `,
);

export const ContentWrapper = styled.div`
    align-items: flex-start;
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: 1fr 298px;
`;

export const Content = styled.div``;

export const StudentName = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 16px;
    `,
);

export const FilterWrapper = styled.div`
    margin-bottom: 48px;
`;

export const FilterTitle = styled(DefaultText)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.secondary};
        font-size: ${fontSizes.xs}px;
        font-weight: ${fontWeights.bold};
        margin-bottom: 8px;
        text-transform: uppercase;
    `,
);

export const ActivitiCardsWrapper = styled.div`
    margin-top: 58px;
`;

export const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: center;
`;
