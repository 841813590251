import useStandaloneVideoQuery from '@state/video/queries/useStandaloneVideoQuery';
import { Nullable } from '@@types/CommonTypes';
import { PlayerSdk } from '@api.video/player-sdk';
import { useEffect, useRef } from 'react';
import {
    useMarkLessonVideoProgressMutation,
    useMarkVideoProgressMutation,
    useStartLessonVideoMutation,
    useStartVideoMutation,
} from '@state/video/mutations';
import { useCourseLessonQuery } from '@state/selfServeCourse/queries';
import { useVideoLinkQuery } from '@state/video/queries';

// TODO: split it to 2 hooks
export default (videoId: string, iframeId: string, lessonId?: string) => {
    const [
        getStandaloneVideo,
        { data: standaloneVideoData, loading: standaloneVideoLoading },
    ] = useStandaloneVideoQuery(videoId);
    const [
        getCourseLessonVideo,
        { data: lessonData, loading: lessonLoading },
    ] = useCourseLessonQuery(videoId, lessonId ?? '');
    const [getVideo, { data: videoData }] = useVideoLinkQuery();

    const [startVideo] = useStartVideoMutation();
    const [markVideoProgress] = useMarkVideoProgressMutation();

    const [startLessonVideo] = useStartLessonVideoMutation();
    const [markLessonVideoProgress] = useMarkLessonVideoProgressMutation();

    const data = lessonId
        ? lessonData?.getCourseLesson
        : standaloneVideoData?.getStandaloneVideo;
    const loading = lessonLoading || standaloneVideoLoading;
    const apiVideoId = data?.video._id;
    const videoLink = videoData?.getVideoById.link;

    const playerRef = useRef<Nullable<PlayerSdk>>(null);
    const intervalId = useRef<Nullable<NodeJS.Timeout>>(null);

    const isLongerThan3Minutes = data && data.video.duration / 5 > 60 * 3;
    const step = !!isLongerThan3Minutes
        ? 60 * 3
        : (data && data.video.duration / 5) || 30;

    const markProgress = async () => {
        if (data) {
            const progress = (await playerRef.current?.getCurrentTime()) || 0;

            if (lessonId) {
                await markLessonVideoProgress({
                    variables: {
                        lessonId: data._id,
                        checkpoint: parseInt(`${progress * 0.95}`) || 0,
                    },
                });
                return;
            }

            await markVideoProgress({
                variables: {
                    standaloneVideoId: data._id,
                    checkpoint: parseInt(`${progress * 0.95}`) || 0,
                },
            });
        }
    };

    const playListener = () => {
        if (!intervalId.current && data) {
            intervalId.current = setInterval(
                async () => markProgress(),
                step * 1000,
            );
        }
    };

    const firstPlayListener = () => {
        if (data) {
            playerRef.current?.setCurrentTime(
                data.consumption?.progress.lastCheckpoint || 0,
            );
            lessonId
                ? startLessonVideo({ variables: { lessonId: data._id } })
                : startVideo({
                      variables: {
                          standaloneVideoId: data._id,
                      },
                  });
        }
    };

    const endedListener = async () => {
        await markProgress();
        intervalId.current && clearInterval(intervalId.current);
        intervalId.current = null;
    };

    useEffect(() => {
        lessonId ? getCourseLessonVideo() : getStandaloneVideo();
    }, []);

    useEffect(() => {
        apiVideoId &&
            getVideo({
                variables: {
                    id: apiVideoId,
                },
            });
    }, [apiVideoId]);

    useEffect(() => {
        if (!!videoLink && !playerRef.current) {
            playerRef.current = new PlayerSdk(`#${iframeId}`);

            playerRef.current?.addEventListener('play', playListener);

            playerRef.current?.addEventListener('firstplay', firstPlayListener);

            playerRef.current?.addEventListener('ended', endedListener);

            playerRef.current?.addEventListener('seeking', markProgress);
        }
        return () => {
            intervalId.current && clearInterval(intervalId.current);
            playerRef.current = null;
        };
    }, [videoLink]);

    return { currentVideo: data, loading, link: videoLink };
};
