import styled from 'styled-components';

export const Label = styled.label`
    margin-left: 10px;
    user-select: none;
`;

export const SwitchWrapper = styled.div`
    display: inline-flex;
    flex: 0;
    position: relative;
    width: auto;
`;

export const SwitchLabel = styled.label`
    background: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.switch}px;
    box-shadow: ${({ theme }) => theme.light_grey_shadow};
    cursor: pointer;
    height: 30px;
    left: 2px;
    position: absolute;
    top: 0;
    transition: all ${({ theme }) => theme.transitions.default}s;
    width: 50px;

    &::after {
        background: ${({ theme }) => theme.colors.background_tertiary};
        border-radius: 50%;
        content: '';
        cursor: pointer;
        display: block;
        height: 26px;
        left: 1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all ${({ theme }) => theme.transitions.default}s;
        width: 26px;
    }
`;

export const SwitchInput = styled.input`
    border-radius: ${({ theme }) => theme.borderRadius.switch}px;
    cursor: pointer;
    height: 30px;
    opacity: 0;
    width: 50px;
    z-index: 9;

    &:checked + ${SwitchLabel} {
        background: ${({ theme }) => theme.colors.secondary};

        &::after {
            background: ${({ theme }) => theme.colors.background_secondary};
            left: calc(100% - 28px);
        }
    }
`;
