import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { useHistory } from 'react-router';
import { useAuthorize } from '@utils/hooks';
import paths from '@shared/paths';
import {
    UseAuthTokenMutation,
    UseAuthTokenMutationVariables,
} from '@@types/graphql/UseAuthTokenMutation';

const USE_AUTH_TOKEN_MUTATION = gql`
    mutation UseAuthTokenMutation($token: String!) {
        useAuthToken(token: $token) {
            sessionToken
            refreshToken
            user {
                id
                type
                status
                onboardingCompletedAt
                hasPassword
            }
            operationType
        }
    }
`;

export default (isFirstLogin?: boolean) => {
    const history = useHistory();
    const authorize = useAuthorize();

    return useMutation<UseAuthTokenMutation, UseAuthTokenMutationVariables>(
        USE_AUTH_TOKEN_MUTATION,
        {
            onCompleted: (payload) => {
                const data = payload.useAuthToken;

                authorize({
                    data,
                    isFirstLogin,
                });
            },
            onError: (error) => {
                setTimeout(() => {
                    history.push(paths.login);
                    notify(error.message)[ToastTypes.ERROR]();
                }, 3500);
            },
        },
    );
};
