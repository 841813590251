import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const MentorFeedbackCardWrapper = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.background_quaternary};
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    justify-content: center;
    min-height: 204px;
`;

export const MentorFeedback = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 78px 56px;
    width: 100%;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const HeaderBox = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 48px;
    text-decoration: none;
    width: 50%;
`;

export const FileBox = styled(HeaderBox)`
    cursor: ${({ theme }) => theme.cursors.pointer};
`;

export const Icon = styled(IconManager)`
    margin-right: 16px;
`;

export const InnerWrapper = styled.div`
    width: calc(100% - 70px);
`;

export const Name = styled(DefaultText)`
    display: block;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
`;

export const AdditionalInfo = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 0;
`;

export const FeedbackText = styled(DefaultText)`
    margin: 0;
`;

export const EmptyInfo = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 16px 0 0;
    text-align: center;
`;

export const EmptyWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0;
`;
