import React, { useEffect } from 'react';
import { changeLockLayout } from '@state/_redux/layout/actions';
import { notify } from '@components/ToastNotification/ToastNotification';
import { resolveEnv } from '@utils/helpers';
import { setTypeformModal } from '@state/_redux/layout/actions';
import { ToastTypes } from '@@types/CommonTypes';
import { useDispatch } from 'react-redux';
import {
    StyledCloseModalButton,
    StyledWidget,
    TypeformModalWrapper,
} from './TypeformModal.styled';

export interface IProps {}

const TypeformModal: React.FC<IProps> = ({}) => {
    const typeformId = resolveEnv('REACT_APP_TYPEFORM_ID');

    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(setTypeformModal(false));
    };

    const handleTypeformSubmit = () => {
        handleCloseModal();
        notify(
            'The form has been successfully sent! We appreciate your opinion.',
        )[ToastTypes.SUCCESS]();
    };

    useEffect(() => {
        dispatch(changeLockLayout(true));

        return () => {
            dispatch(changeLockLayout(false));
        };
    }, []);

    return (
        <TypeformModalWrapper>
            <StyledCloseModalButton onClose={handleCloseModal} />
            {typeformId && (
                <StyledWidget
                    id={typeformId}
                    hideHeaders={true}
                    onSubmit={handleTypeformSubmit}
                />
            )}
        </TypeformModalWrapper>
    );
};

export default TypeformModal;
