import styled from 'styled-components';
import { Button } from '@components/_universal';

export const ChangeEmailFormWrapper = styled.div``;

export const Actions = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 45px 0 25px;
`;

export const ActionButton = styled(Button)`
    margin: 0 12px;
    min-width: 160px;
`;
