import styled, { css } from 'styled-components';

export const BannerWrapper = styled.div<{
    background?: string;
    dark?: boolean;
}>(
    ({ background, dark }) => css`
        background: url(${background});
        background-position: center;
        height: 100%;
        position: relative;
        width: 100%;
        ${dark &&
        css`
            background-image: linear-gradient(
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)
                ),
                url(${background});
            background-size: cover;
        `}
    `,
);
