import { gql, useMutation } from '@apollo/client';
import { ToastTypes } from '@@types/CommonTypes';
import { notify } from '@components/ToastNotification/ToastNotification';
import {
    DeleteStudentAccountMutation,
    DeleteStudentAccountMutationVariables,
} from '@@types/graphql/DeleteStudentAccountMutation';

const DELETE_STUDENT_ACCOUNT_MUTATION = gql`
    mutation DeleteStudentAccountMutation($input: DeleteStudentAccountInput!) {
        deleteStudentAccount(input: $input) {
            id
        }
    }
`;

export default (callback: () => void) => {
    return useMutation<
        DeleteStudentAccountMutation,
        DeleteStudentAccountMutationVariables
    >(DELETE_STUDENT_ACCOUNT_MUTATION, {
        onCompleted: () => {
            callback();
            notify('Changes have been successfully saved.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
