import dayjs from 'dayjs';

export const lessThan12Hours = (date: number | Date) => {
    const twelveHours = 1000 * 60 * 60 * 12;

    return dayjs(date).valueOf() - dayjs().valueOf() < twelveHours;
};

export const lessThan10Percent = (slots: number, allSlots: number) => {
    const percentage = 10;
    return (slots / allSlots) * 100 < percentage;
};
