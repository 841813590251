import React, { useEffect } from 'react';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import Select from '@components/_form-elements/Select/Select';
import useListOfCountriesQuery from '@state/lists/queries/useListOfCountriesQuery';
import { FormRow, InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';
import { Input } from '@components/_form-elements';
import { SelectType } from '@@types/CommonTypes';
import { useForm } from 'react-hook-form';
import { useUserMeQuery } from '@state/auth/queries';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useChangeEmailMutation,
    useUpdateParentMutation,
} from '@state/settings/mutations';
import {
    ActionButton,
    Actions,
    EditAccountInformationFormWrapper,
    InputAdditionalTextWrapper,
} from './EditAccountInformationForm.styled';

interface IProps {
    closeEdit: () => void;
}

interface IForm {
    address: string;
    city: string;
    country: SelectType;
    email: string;
    name: string;
    surname: string;
    zipCode: string;
}

const EditAccountInformationForm: React.FC<IProps> = ({ closeEdit }) => {
    const { data: userData } = useUserMeQuery();
    const {
        data: countriesData,
        loading: loadingCountries,
    } = useListOfCountriesQuery();

    const countries = countriesData?.getListOfCountries;
    const currentCountry = countries?.find(
        (country) => country.countryCode === userData?.me.countryCode,
    );

    const [
        updateParentMutation,
        { loading: updateParentLoading },
    ] = useUpdateParentMutation(() => closeEdit());

    const [
        changeEmailMutation,
        { loading: changeEmailLoading },
    ] = useChangeEmailMutation();

    const { control, errors, handleSubmit, register, reset } = useForm<IForm>({
        defaultValues: {
            address: userData?.me.address ?? '',
            city: userData?.me.city ?? '',
            country: {
                label: currentCountry?.countryName,
                value: currentCountry?.countryCode,
            },
            email: userData?.me.email,
            name: userData?.me.name,
            surname: userData?.me.surname,
            zipCode: userData?.me.zipCode ?? '',
        },
        resolver: yupResolver(validation),
        mode: 'onBlur',
    });

    useEffect(() => {
        reset({
            country: {
                label: currentCountry?.countryName,
                value: currentCountry?.countryCode,
            },
        });
    }, [currentCountry]);

    const onSubmit = (formValues: IForm) => {
        updateParentMutation({
            variables: {
                input: {
                    address: formValues.address,
                    city: formValues.city,
                    countryCode: formValues?.country?.value,
                    name: formValues.name,
                    surname: formValues.surname,
                    zipCode: formValues.zipCode,
                },
            },
        });
        formValues.email !== userData?.me.email &&
            changeEmailMutation({
                variables: {
                    input: {
                        newEmail: formValues.email,
                    },
                },
            });
    };

    return (
        <EditAccountInformationFormWrapper>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.name}
                        label={'Name'}
                        name={'name'}
                        placeholder={'Enter name'}
                        register={register}
                    />
                </InputWrapper>
                <InputWrapper>
                    <InputForm
                        errors={errors.surname}
                        label={'Surname'}
                        name={'surname'}
                        placeholder={'Enter surname'}
                        register={register}
                    />
                </InputWrapper>
            </FormRow>
            <FormRow>
                <InputAdditionalTextWrapper>
                    <InputForm
                        additionalText={
                            'Email address is necessary to login to the 8Billionideas platform.'
                        }
                        errors={errors.email}
                        label={'Parent email address'}
                        name={'email'}
                        placeholder={'e.g. joedoe@mail.com'}
                        register={register}
                        type={'email'}
                    />
                </InputAdditionalTextWrapper>
            </FormRow>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.address}
                        label={'Address'}
                        name={'address'}
                        placeholder={'Enter your address'}
                        register={register}
                    />
                </InputWrapper>
                <InputWrapper>
                    <SelectForm
                        control={control}
                        errors={errors.country}
                        key={JSON.stringify(currentCountry)}
                        isLoading={loadingCountries}
                        isSearchable
                        label={'Country'}
                        name={'country'}
                        options={countries?.map((item) => ({
                            label: item.countryName,
                            value: item.countryCode,
                        }))}
                        placeholder={'Select your country'}
                        register={register}
                    />
                </InputWrapper>
            </FormRow>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.city}
                        label={'City'}
                        name={'city'}
                        placeholder={'City'}
                        register={register}
                    />
                </InputWrapper>
                <InputWrapper>
                    <InputForm
                        errors={errors.zipCode}
                        label={'Postcode'}
                        name={'zipCode'}
                        placeholder={'Enter your postcode'}
                        register={register}
                    />
                </InputWrapper>
            </FormRow>
            <Actions>
                <ActionButton
                    buttonType={'secondary'}
                    onPress={() => closeEdit()}
                >
                    Cancel
                </ActionButton>
                <ActionButton
                    buttonType={'primary'}
                    disabled={updateParentLoading || changeEmailLoading}
                    onPress={handleSubmit(onSubmit)}
                >
                    Save changes
                </ActionButton>
            </Actions>
        </EditAccountInformationFormWrapper>
    );
};

const InputForm = FieldMessage(Input);
const SelectForm = FieldMessage(Select);

export default EditAccountInformationForm;
