import styled, { css } from 'styled-components';
import { IconManager } from '@components/_universal';
import VideoList from '@components/_universal/VideoList/VideoList';
import {
    BigHeader,
    DefaultText,
    SubTitle,
    Title,
} from '@components/_universal/Typography.styled';

export const CoursePageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 120px 0;
`;

export const PageTitle = styled(SubTitle)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        padding-top: 34px;
    `,
);

export const PageSubtitle = styled(BigHeader)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 16px;
    `,
);

export const SectionHeader = styled.div`
    align-items: center;
    display: flex;
    margin-top: 60px;
`;

export const SectionTitle = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin: 0 24px 0 0;
        min-height: 44px;
    `,
);

export const CourseLessonsNavigation = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background: ${colors.background_primary};
        border-radius: ${borderRadius.circle}px;
        height: 852px;
        margin-top: 62px;
        min-width: 416px;
        padding: 154px 30px 0 48px;
        position: relative;
    `,
);

export const ListTitle = styled(BigHeader)(
    ({ theme: { fontWeights } }) => css`
        display: inline-flex;
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 16px;
    `,
);

export const CourseWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InnerWrapper = styled.div`
    margin-right: 60px;
    width: 100%;
`;

export const CourseDescription = styled(DefaultText)`
    margin-bottom: 40px;
`;

export const LessonsList = styled(VideoList)`
    max-height: 650px;
`;

export const VideoPlayerWrapper = styled.div(
    ({ theme: { borderRadius } }) => css`
        border-radius: ${borderRadius.card}px;
        position: relative;

        img {
            width: 100%;
        }
    `,
);

export const PlayButton = styled(IconManager)`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;
