import { gql, useMutation } from '@apollo/client';
import { UpdatePaymentMethodMutation } from '@@types/graphql/UpdatePaymentMethodMutation';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { loadStripe } from '@stripe/stripe-js';

const UPDATE_PAYMENT_METHOD_MUTATION = gql`
    mutation UpdatePaymentMethodMutation {
        updatePaymentMethod
    }
`;

export default (publishableKey: string) => {
    return useMutation<UpdatePaymentMethodMutation>(
        UPDATE_PAYMENT_METHOD_MUTATION,
        {
            onCompleted: async (payload) => {
                const stripe = await loadStripe(publishableKey);
                stripe?.redirectToCheckout({
                    sessionId: payload.updatePaymentMethod,
                });
            },
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
        },
    );
};
