import { gql, useLazyQuery } from '@apollo/client';
import {
    ConsumedContentQuery,
    ConsumedContentQueryVariables,
} from '@@types/graphql/ConsumedContentQuery';

const CONSUMED_CONTENT_QUERY = gql`
    query ConsumedContentQuery($pagination: ConsumedContentPagination) {
        getConsumedContent(pagination: $pagination) {
            __typename
            ... on Course {
                _id
                type
                name
                featured
                thumbnail
            }
            ... on StandaloneVideo {
                _id
                type
                name
                featured
                thumbnail
            }
            ... on LiveExperience {
                _id
                endDate
                featured
                maxNoParticipants
                name
                noOfSignups
                schoolsWithAccess {
                    id
                }
                showToChildren
                showToStudents
                startDate
                thumbnail
                type
            }
        }
    }
`;

export default () => {
    return useLazyQuery<ConsumedContentQuery, ConsumedContentQueryVariables>(
        CONSUMED_CONTENT_QUERY,
        {
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
        },
    );
};
