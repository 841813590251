import React from 'react';
import {
    Content,
    ErrorLayoutWrapper,
    FooterBar,
    NavigationBar,
} from './ErrorLayout.styled';
import ChatWithSupport from '@components/_universal/ChatWithSupport/ChatWithSupport';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import { useAuthMeQuery } from '@state/auth/queries';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';

interface IProps {}

export const ErrorLayout: React.FC<IProps> = ({ children }) => {
    const { data } = useAuthMeQuery();

    const userType = {
        [USER_TYPE.ADMIN]: null,
        [USER_TYPE.CHILD]: <ChatWithMentor />,
        [USER_TYPE.MENTOR]: null,
        [USER_TYPE.PARENT]: <ChatWithSupport />,
        [USER_TYPE.STUDENT]: <ChatWithMentor />,
        [USER_TYPE.TEACHER]: <ChatWithMentor />,
    };

    return (
        <ErrorLayoutWrapper>
            <NavigationBar disabledLogoLinks disabledLinks disabledFormButton />
            <Content>{children}</Content>
            <FooterBar />
            {/*TODO: add button*/}
            {data?.me ? userType[data?.me.type] : null}
        </ErrorLayoutWrapper>
    );
};
export default ErrorLayout;
