import {
    OngoingContentQuery_getOngoingContent_content,
    OngoingContentQuery_getOngoingContent_content_Course,
    OngoingContentQuery_getOngoingContent_content_StandaloneVideo,
    OngoingContentQuery_getOngoingContent_progress,
} from '@@types/graphql/OngoingContentQuery';

type ProgressDataType = OngoingContentQuery_getOngoingContent_progress;
type ContentDataType = OngoingContentQuery_getOngoingContent_content;
type CourseType = OngoingContentQuery_getOngoingContent_content_Course;
type StandaloneVideoType = OngoingContentQuery_getOngoingContent_content_StandaloneVideo;

const getPercentageValue = (from: number, total: number) => {
    const precentageValue = Math.round(+((100 * from) / total));
    if (precentageValue > 100) return 100;

    return precentageValue;
};

export default (
    content: ContentDataType,
    progress: ProgressDataType,
    type: string,
) => {
    let returnedValue = 0;
    if (type === 'COURSE') {
        const { lessons } = content as CourseType;
        returnedValue = getPercentageValue(
            progress?.completedLessons ?? 0,
            lessons.length,
        );
    }
    if (type === 'STANDALONE_VIDEO') {
        const { video } = content as StandaloneVideoType;
        returnedValue = getPercentageValue(
            progress?.lastCheckpoint ?? 0,
            video.duration,
        );
    }

    return returnedValue;
};
