import TrialLeftInformation from '@components/TrialLeftInformation/TrialLeftInformation';
import { IconManager } from '@components/_universal';
import styled, { css } from 'styled-components';

const hiddenIconsCss = css`
    opacity: 0;
    pointer-events: none;
`;

export const HeaderWrapper = styled.div<{
    hideElement?: boolean;
    isTransparent?: boolean;
}>(
    ({ hideElement, isTransparent, theme: { borderRadius, colors } }) => css`
        align-items: center;
        background: ${isTransparent
            ? 'transparent'
            : colors.background_secondary};
        border-radius: ${borderRadius.button}px;
        display: ${hideElement ? 'none' : 'flex'};
        height: 64px;
        justify-content: center;
        margin-top: 22px;
        position: fixed;
        right: 50px;
        width: 225px;
        z-index: 99;
    `,
);

export const SearchIcon = styled(IconManager)`
    margin: 0 10px;
`;

export const CloseIcon = styled(IconManager)`
    left: 4px;
    position: relative;
    top: 4px;
    z-index: 999;
`;

export const AccountIcon = styled(IconManager)<{ isHidden?: boolean }>(
    ({ isHidden }) => css`
        margin: 0 10px;
        ${isHidden && hiddenIconsCss}
    `,
);

export const NotificationWrapper = styled.div<{
    isHidden?: boolean;
    unreadNotifications?: boolean;
}>(
    ({ isHidden, theme: { borderRadius, colors }, unreadNotifications }) => css`
        position: relative;
        ${isHidden && hiddenIconsCss}

        &:after {
            ${!!unreadNotifications && `content: ''`};
            background: ${colors.notifications};
            border-radius: ${borderRadius.circle}px;
            border: 2px solid ${colors.background_secondary};
            height: 13px;
            position: absolute;
            right: 10px;
            top: -2px;
            width: 13px;
        }
    `,
);

export const NotificationIcon = styled(IconManager)`
    margin: 0 10px;
`;

export const HeaderTrialLeftInformation = styled(TrialLeftInformation)`
    position: absolute;
    right: 60px;
    top: 65px;
`;
