import React from 'react';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
import Loader from '@components/_universal/Loader/Loader';
import {
    ImageCornerDecorator,
    ImageDecorator,
    ListItem,
    ListItemContent,
    ListItemIcon,
    ListItemImage,
    ListItemImageWrapper,
    ListItemTime,
    ListItemTitle,
    LoaderWrapper,
    VideoListWrapper,
    ImageOverlay,
} from './VideoList.styled';
dayjs.extend(duration);

interface IProps {
    className?: string;
    currentVideo?: string;
    loading?: boolean;
    list?: {
        done?: boolean;
        duration: number;
        id: string;
        link: string;
        thumbnail: string;
        title: string;
    }[];
    variant: 'primary' | 'secondary';
}

const VideoList: React.FC<IProps> = ({
    className,
    currentVideo,
    loading = false,
    list,
    variant,
}) => {
    return (
        <VideoListWrapper {...{ className, variant }}>
            {loading && (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            )}
            {!loading &&
                list?.map(
                    ({ done, thumbnail, duration, id, link, title }, index) => (
                        <ListItem
                            $active={currentVideo === id}
                            key={`${id}-${index}`}
                            to={link}
                            {...{ variant }}
                        >
                            <ListItemImageWrapper>
                                <ImageOverlay />
                                <ListItemImage src={thumbnail} />
                                <ImageDecorator name={'Video'} size={24} />
                                <ImageCornerDecorator name={'Play'} size={16} />
                            </ListItemImageWrapper>
                            <ListItemContent>
                                <ListItemTitle active={currentVideo === id}>
                                    {title}
                                </ListItemTitle>
                                <ListItemTime active={currentVideo === id}>
                                    {dayjs
                                        .duration(duration, 'minutes')
                                        .format('HH:mm')}
                                </ListItemTime>
                            </ListItemContent>
                            {!!done && <ListItemIcon name={'Done'} size={30} />}
                        </ListItem>
                    ),
                )}
        </VideoListWrapper>
    );
};

export default VideoList;
