import React from 'react';
import { TextBoxCardWrapper, TextBoxCardTextBox } from './TextBoxCard.styled';
import { ColorType, IconName } from '@@types/CommonTypes';
import CardHolder from '@components/_universal/Cards/CardHolder/CardHolder';
import { DEFAULT_CARD_WIDTHS } from '@components/_universal/Cards/Cards.styled';

interface IProps {
    className?: string;
    featured?: boolean;
    image: string;
    isDifferentHover?: boolean;
    link: string;
    onClick?: () => void;
    text?: string;
    type: 'STANDALONE_VIDEO' | 'article';
    width?: number | string;
}

const cardType: {
    [key in IProps['type']]: {
        backgroundColor: ColorType;
        image?: {
            name: IconName;
            size: number;
        };
        name: string;
        textPosition: 'left' | 'bottom';
    };
} = {
    STANDALONE_VIDEO: {
        backgroundColor: 'video',
        image: { name: 'Video', size: 16 },
        name: 'Video',
        textPosition: 'left',
    },
    article: {
        backgroundColor: 'video',
        image: { name: 'Article', size: 16 },
        name: 'Article',
        textPosition: 'bottom',
    },
};

const TextBoxCard: React.FC<IProps> = ({
    className,
    featured,
    image,
    isDifferentHover = false,
    link,
    onClick,
    text,
    type,
    width,
}) => {
    return (
        <CardHolder
            badgeOptions={{ ...cardType[type] }}
            badgeText={cardType[type].name}
            className={className}
            featured={featured}
            link={link}
            onClick={onClick}
            width={width ?? DEFAULT_CARD_WIDTHS.textBox}
        >
            <TextBoxCardWrapper
                image={image}
                isDifferentHover={!!isDifferentHover}
            >
                <TextBoxCardTextBox
                    isBottom={cardType[type].textPosition === 'bottom'}
                >
                    {text}
                </TextBoxCardTextBox>
            </TextBoxCardWrapper>
        </CardHolder>
    );
};

export default TextBoxCard;
