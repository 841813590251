import React, { useState } from 'react';
import { MAX_HOMEWORK_SIZE, HOMEWORK_MIME_TYPES } from '@shared/constants';
import { SubmitWorkFormWrapper } from './SubmitWorkForm.styled';
import { useParams } from 'react-router';
import { useSendLiveExperienceHomeworkMutation } from '@state/files/mutations';
import UploadArea from '@components/_form-elements/UploadArea/UploadArea';

interface IProps {}

const SubmitWorkForm: React.FC<IProps> = () => {
    const [progress, setProgress] = useState<string | undefined>();
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();
    const [
        sendLiveExperienceHomework,
    ] = useSendLiveExperienceHomeworkMutation(() => setProgress(undefined));

    const onSubmit = (file: File) =>
        sendLiveExperienceHomework({
            variables: {
                liveExperienceId,
                homeworkFile: file,
            },
            context: {
                fetchOptions: {
                    onUploadProgress: (progress: ProgressEvent) => {
                        setProgress(
                            ((progress.loaded / progress.total) * 100).toFixed(
                                0,
                            ),
                        );
                    },
                },
            },
        });

    return (
        <SubmitWorkFormWrapper>
            <UploadArea
                accept={HOMEWORK_MIME_TYPES.join(', ')}
                maxSize={MAX_HOMEWORK_SIZE}
                onChange={onSubmit}
                progress={progress}
            />
        </SubmitWorkFormWrapper>
    );
};

export default SubmitWorkForm;
