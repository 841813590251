import styled, { css, keyframes } from 'styled-components';
import { breakpoints } from '@shared/constants';
import Footer from '@components/_global/Footer/Footer';
import loginBg from '@assets/images/login_bg.svg';

const LoginAnimation = keyframes`
    0% {
        background-size: 70%;
    }
    50% {
        background-size: 70%;
    }
    100% {
        background-size: 115%;
    }
`;

const LoginContainerAnimation = keyframes`
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const contentAnimation = css`
    animation: ${LoginContainerAnimation} 1.5s ease-in-out;
`;

const layoutAnimation = css`
    animation: ${LoginAnimation} 1.2s ease-in-out;
`;

//don't sort
export const LoginLayoutWrapper = styled.div<{ withAnimation: boolean }>`
    background: url(${loginBg}) center center no-repeat;
    background-size: 115%;
    display: grid;
    grid-template-areas:
        '. . .'
        '. content .'
        'footer footer footer';
    grid-template-columns: 1fr 1140px 1fr;
    grid-template-rows: 60px 1fr 60px;
    min-height: 100%;
    width: 100%;
    ${({ withAnimation }) => withAnimation && layoutAnimation}

    @media (max-height: 768px) {
        grid-template-rows: 30px 1fr 30px;
    }

    @media (max-width: ${breakpoints.lg}px) {
        grid-template-columns: 1fr 960px 1fr;
        grid-template-rows: 30px 1fr 30px;
    }

    @media (max-width: ${breakpoints.md}px) {
        grid-template-columns: 1fr 720px 1fr;
    }

    @media (max-width: ${breakpoints.sm}px) {
        grid-template-columns: 1fr 540px 1fr;
        grid-template-rows: 16px 1fr 30px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        grid-template-columns: 1fr calc(100% - 32px) 1fr;
    }
`;

export const Content = styled.div<{ withAnimation: boolean }>`
    display: flex;
    grid-area: content;
    justify-content: center;
    ${({ withAnimation }) => withAnimation && contentAnimation}
`;

export const FooterBar = styled(Footer)`
    grid-area: footer;
    padding-right: 100px;

    @media (max-width: ${breakpoints.lg}px) {
        padding-right: 50px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        padding-right: 16px;
    }
`;
