import React from 'react';
import { AdditionalText, Content, ErrorMessage } from './FieldMessage.styled';

interface IProps {
    additionalText?: React.ReactNode;
    backgroundTypeError?: boolean;
    centered?: boolean;
    name: string;
    errors?: any;
    marginBottom?: number;
    staticPosition?: boolean;
}

export default <P extends object>(
    Component: React.ComponentType<P>,
): React.FC<P & IProps> => ({ ...props }) => {
    const {
        additionalText,
        backgroundTypeError = false,
        centered = false,
        errors,
        marginBottom = 24,
        staticPosition,
    } = props;

    return (
        <Content {...{ staticPosition }}>
            <Component {...(props as P)} />
            {errors ? (
                <ErrorMessage
                    position={marginBottom}
                    {...{ backgroundTypeError, centered, staticPosition }}
                >
                    {errors?.label?.message ?? errors.message}
                </ErrorMessage>
            ) : (
                additionalText && (
                    <AdditionalText
                        position={marginBottom}
                        {...{ staticPosition }}
                    >
                        {additionalText}
                    </AdditionalText>
                )
            )}
        </Content>
    );
};
