import React from 'react';
import { IChildsList } from '@domains/Subscription/ChooseYourPlanPage/ChooseYourPlanPage';
import { IconManager } from '@components/_universal';
import { IconName } from '@@types/CommonTypes';
import {
    AddChildButton,
    AddChildInner,
    AddChildText,
    AddChildWrapper,
    Avatar,
    ChildElement,
    ChildName,
    ChildrenListWrapper,
    StyledCheckbox,
} from './ChildrenList.styled';

export interface IProps {
    childrenList: IChildsList[];
    handleChange: (id: number, value: boolean) => void;
    setAddChildModal: (state: boolean) => void;
}

const ChildrenList: React.FC<IProps> = ({
    childrenList,
    handleChange,
    setAddChildModal,
}) => {
    return (
        <ChildrenListWrapper>
            {childrenList.map((child) => (
                <ChildElement key={child.id}>
                    <StyledCheckbox
                        name={`child_${child.id}`}
                        value={child.checked}
                        onChange={(value) => handleChange(child.id, value)}
                    />
                    <Avatar
                        name={
                            child.child.avatar === null
                                ? 'PlaceholderAvatar'
                                : (`Avatar_${child.child.avatar}` as IconName)
                        }
                        size={40}
                    />
                    <ChildName>{child.child.name}</ChildName>
                </ChildElement>
            ))}
            <AddChildWrapper>
                <AddChildInner
                    onClick={() => {
                        setAddChildModal(true);
                    }}
                >
                    <AddChildButton>
                        <IconManager name="Plus" size={15} fill={['text']} />
                    </AddChildButton>
                    <AddChildText>Add child</AddChildText>
                </AddChildInner>
            </AddChildWrapper>
        </ChildrenListWrapper>
    );
};

export default ChildrenList;
