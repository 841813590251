import React from 'react';
import { Switch } from '@components/_form-elements';
import useNotificationSettingsForm from '@utils/hooks/useNotificationSettingsForm';
import {
    Icon,
    PageHeader,
    PageTitle,
    SectionTitle,
} from '@domains/Parent/Account/AccountParentPage/AccountParentPage.styled';
import {
    NotificationSettingsPageWrapper,
    SectionDivider,
    SectionHeader,
    SectionSubtitle,
    SectionText,
    SectionWrapper,
} from './NotificationSettingsParentPage.styled';

interface IProps {}

const NotificationSettingsPage: React.FC<IProps> = () => {
    const { handleChange, register } = useNotificationSettingsForm();

    return (
        <NotificationSettingsPageWrapper>
            <PageHeader>
                <PageTitle>Notification Settings</PageTitle>
                <Icon name={'Trumpet'} size={70} sizeY={40} />
            </PageHeader>
            <SectionWrapper>
                <SectionHeader>
                    <SectionTitle>in app notifications</SectionTitle>
                    <Switch
                        onChange={handleChange}
                        name={'appNotification'}
                        register={register}
                    />
                </SectionHeader>
                <SectionSubtitle>Experiences notifications</SectionSubtitle>
                <SectionText>
                    Receive notifications letting you know that your child has
                    registered for the live experience.
                </SectionText>
                <SectionSubtitle>Ban notifications</SectionSubtitle>
                <SectionText>
                    Receive notofications informing you that your child has been
                    banned.
                </SectionText>
            </SectionWrapper>
            <SectionDivider />
            <SectionWrapper>
                <SectionHeader>
                    <SectionTitle>email notifications</SectionTitle>
                    <Switch
                        onChange={handleChange}
                        name={'emailNotification'}
                        register={register}
                    />
                </SectionHeader>
                <SectionSubtitle>Experiences notifications</SectionSubtitle>
                <SectionText>
                    Receive emails letting you know that your child has
                    registered for the live experience.
                </SectionText>
                <SectionSubtitle>Ban notifications</SectionSubtitle>
                <SectionText>
                    Receive emails informing you that your child has been
                    banned.
                </SectionText>
                <SectionSubtitle>Messages from mentors</SectionSubtitle>
                <SectionText>
                    Receive emails with information about your child directly
                    from the mentors.
                </SectionText>
                <SectionSubtitle>Subscription notifications</SectionSubtitle>
                <SectionText>
                    Receive an email informing you that your card has expired.
                </SectionText>
            </SectionWrapper>
        </NotificationSettingsPageWrapper>
    );
};

export default NotificationSettingsPage;
