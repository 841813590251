import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import paths from '@shared/paths';
import {
    CreateMultipleAccountsMutation,
    CreateMultipleAccountsMutationVariables,
} from '@@types/graphql/CreateMultipleAccountsMutation';

const CREATE_MULTIPLE_ACCOUNTS_MUTATION = gql`
    mutation CreateMultipleAccountsMutation($childs: [CreateChildAccount!]!) {
        createMultipleChildAccount(childs: $childs) {
            parent {
                id
            }
        }
    }
`;

export default () => {
    const history = useHistory();

    return useMutation<
        CreateMultipleAccountsMutation,
        CreateMultipleAccountsMutationVariables
    >(CREATE_MULTIPLE_ACCOUNTS_MUTATION, {
        onCompleted: () => {
            history.push(paths.dashboard_parent());
        },
        onError: () => {},
    });
};
