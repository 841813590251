import React from 'react';
import useInfiniteScroll, {
    UseInfiniteScrollArgs,
} from 'react-infinite-scroll-hook';
import { StyledLoader } from './Paginate.styled';

interface IProps extends UseInfiniteScrollArgs {
    queryLoading?: boolean;
}

const Paginate: React.FC<IProps> = ({
    children,
    hasNextPage,
    loading,
    onLoadMore,
    queryLoading,
}) => {
    const infiniteRef = useInfiniteScroll<HTMLDivElement>({
        hasNextPage,
        loading,
        onLoadMore,
    });

    return (
        <div ref={infiniteRef}>
            {children} {(queryLoading || loading) && <StyledLoader />}
        </div>
    );
};

export default Paginate;
