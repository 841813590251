import { MostPopularContentsQuery_getMostPopularContents } from '@@types/graphql/MostPopularContentsQuery';

type IElementType = MostPopularContentsQuery_getMostPopularContents;

interface IReturnedData {
    courses: IElementType[];
    rest: IElementType[];
    videos: IElementType[];
}

function parseDashboardMostPopularData(elements: IElementType[]) {
    const returnedData: IReturnedData = {
        courses: [],
        rest: [],
        videos: [],
    };

    elements.forEach((element) => {
        if (
            element.type === 'STANDALONE_VIDEO' &&
            returnedData.videos.length < 3
        ) {
            returnedData.videos.push(element);
            return;
        }
        if (element.type === 'COURSE' && returnedData.courses.length < 1) {
            returnedData.courses.push(element);
            return;
        }
        returnedData.rest.push(element);
    });
    return returnedData;
}

export default parseDashboardMostPopularData;
