import React from 'react';
import InputSearch from '@components/_form-elements/InputSearch/InputSearch';
import { useForm } from 'react-hook-form';
import {
    SearchBarWrapper,
    SearchWrapper,
    StyledFormWrapper,
} from '@components/_universal/Table/SearchBar/SearchBar.styled';

interface IProps {
    placeholder?: string;
    setFilter: (value: string) => void;
}

interface IForm {
    search: string;
}

const SearchBar: React.FC<IProps> = ({ placeholder, setFilter }) => {
    const { handleSubmit, getValues, register, reset } = useForm<IForm>({
        defaultValues: {
            search: '',
        },
    });

    const onSubmit = (data: IForm) => {
        setFilter(data.search);
    };

    const onChange = () => {
        handleSubmit(onSubmit)();
    };

    const onClear = () => {
        reset({
            search: '',
        });
        handleSubmit(onSubmit)();
    };

    return (
        <SearchBarWrapper>
            <StyledFormWrapper autoComplete={'off'}>
                <SearchWrapper>
                    <InputSearch
                        inputRef={register}
                        isFillInput={!!getValues().search}
                        marginBottom={0}
                        name={'search'}
                        onChange={onChange}
                        onClear={onClear}
                        placeholder={
                            placeholder ? placeholder : 'Search for student ...'
                        }
                    />
                </SearchWrapper>
            </StyledFormWrapper>
        </SearchBarWrapper>
    );
};

export default SearchBar;
