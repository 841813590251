import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { ModalTitle } from '@components/_modals/GlobalModals.styled';

export const CreatePasswordModalWrapper = styled.div`
    position: relative;
    width: 960px;
    height: 100%;
`;

export const ContentWrapper = styled.div`
    background: ${({ theme }) => theme.colors.app_bg};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding: 56px 80px;
    position: relative;
`;

export const ModalFooter = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.app_bg};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    display: flex;
    padding: 22px 80px;
`;

export const FooterText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.7;
    margin: 0 20px;
`;

export const StyledTitle = styled(ModalTitle)`
    margin-bottom: 32px;
`;
