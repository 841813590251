import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button, IconManager } from '@components/_universal';
import {
    BigHeader,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const FreeTrialWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    max-width: 358px;
    position: relative;

    @media (max-width: ${breakpoints.lg}px) {
        max-width: 656px;
        width: 100%;
    }
`;

export const FreeTrialInnerWrapper = styled.div<{ pricingVersion?: boolean }>`
    background: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    margin-bottom: ${({ pricingVersion }) => (pricingVersion ? 0 : 56)}px;
    padding: 40px 24px 40px;
    position: relative;
    width: 100%;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 40px;
    }

    @media (max-width: ${breakpoints.md}px) {
        margin-bottom: 0;
    }
`;

export const HumanIcon = styled(IconManager)`
    position: absolute;
    right: 30px;
    top: -172px;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: 160px;
        right: 10px;
        top: -150px;
    }
`;

export const FreeTrialSunIcon = styled(IconManager)`
    justify-content: center;
    margin-bottom: 24px;
`;

export const StyledBigTitle = styled(BigHeader)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin: 0 auto 24px;
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.l}px;
    }
`;

export const ListWrapper = styled.div``;

export const ListElement = styled.div`
    display: flex;

    & + & {
        margin-top: 9px;
    }
`;

export const ListText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    display: inline-flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin: 0;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.s}px;
        line-height: 1.4;
    }
`;

export const TickIcon = styled(IconManager)`
    margin-right: 4px;
`;

export const FreeTrialStand = styled.div`
    background: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    bottom: 0;
    height: 4px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 106px;
`;

export const FreeTrialColumn = styled.div`
    background: ${({ theme }) => theme.colors.course};
    bottom: 0;
    height: 56px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 21px;
`;

export const FreeTrialBackground = styled.div`
    background: ${({ theme }) => theme.colors.course};
    display: none;
    height: 80px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;

    @media (max-width: ${breakpoints.sm}px) {
        display: block;
    }
`;

export const ButtonPricing = styled(Button)`
    margin: 24px auto 0;
    width: 200px;

    &:hover {
        box-shadow: ${({ theme }) => theme.blue_shadow};
    }
`;
