import { gql, useMutation } from '@apollo/client';
import { AUTH_ME_QUERY } from '@state/auth/queries/useAuthMeQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import {
    CreatePasswordMutationMutation,
    CreatePasswordMutationMutationVariables,
} from '@@types/graphql/CreatePasswordMutationMutation';

const CREATE_PASSWORD_MUTATION = gql`
    mutation CreatePasswordMutationMutation($input: CreatePasswordInput!) {
        createPassword(input: $input) {
            id
        }
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<
        CreatePasswordMutationMutation,
        CreatePasswordMutationMutationVariables
    >(CREATE_PASSWORD_MUTATION, {
        onCompleted: () => {
            onCompleted?.();
            notify('Your password has been successfully saved.')[
                ToastTypes.SUCCESS
            ]();
        },
        refetchQueries: [
            {
                query: AUTH_ME_QUERY,
            },
        ],
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
