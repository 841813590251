export interface ILeaveUploadStudentsModal {
    isOpen: boolean;
    redirectTo: string;
}

export type LayoutModule = {
    leaveUploadStudentsModal: ILeaveUploadStudentsModal;
    lockLayout: number;
    loginLayoutAnimation: boolean;
    searchModal: boolean;
    studentsUploadModal: boolean;
    tooManyStudnetsUploadModal: boolean;
    trialEndedModal: boolean;
    typeformModal: boolean;
};

export const initialState: LayoutModule = {
    leaveUploadStudentsModal: {
        isOpen: false,
        redirectTo: '',
    },
    lockLayout: 0,
    loginLayoutAnimation: true,
    searchModal: false,
    studentsUploadModal: false,
    tooManyStudnetsUploadModal: false,
    trialEndedModal: false,
    typeformModal: false,
};
