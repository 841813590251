import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button } from '@components/_universal';
import bg from '@assets/images/payment_ornament.png';
import mobileBg from '@assets/images/mobile_payment_ornament.png';
import {
    BigTitle,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const ChoosePaymentWrapper = styled.div`
    background: url(${bg}) no-repeat top center
        ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    box-shadow: ${({ theme }) => theme.blue_shadow};
    display: flex;
    flex-wrap: wrap;
    padding: 60px 40px 32px;

    @media (max-width: ${breakpoints.lg}px) {
        padding: 24px 24px 32px;
        background: url(${mobileBg}) no-repeat top center
            ${({ theme }) => theme.colors.background_secondary};
        border-radius: ${({ theme }) => theme.borderRadius.button}px;
    }
`;

export const ChoosePaymentInnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export const AdditionalText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 14px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 6px;
    }
`;

export const StyledTitle = styled(BigTitle)<{ isOnceAYear: boolean }>`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: ${({ isOnceAYear }) => (isOnceAYear ? 14 : 48)}px;
    text-align: center;
    width: 100%;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 24px;
        font-size: ${({ theme }) => theme.fontSizes.xxxl}px;
    }
`;

export const Discount = styled(DefaultText)`
    align-items: center;
    background: ${({ theme }) => theme.colors.live_experience};
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    color: ${({ theme }) => theme.colors.text_white};
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.xxs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.4;
    margin-bottom: 8px;
    padding: 6px 24px;
    text-transform: uppercase;

    @media (max-width: ${breakpoints.lg}px) {
        padding: 6px 14px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 194px;
    }
`;

export const StyledButton = styled(Button)`
    flex: 1;
`;
