import { useEffect, useState } from 'react';
import { CONTENT_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { Control, useController } from 'react-hook-form';
import { IChip } from '@@types/CommonTypes';
import { IconManager } from '@components/_universal';
import { setLibraryTabs } from '@state/_redux/tabs/actions';
import { sortBy } from '@shared/filters';
import { Tab } from '@components/_universal/Tab/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { TabWrapper } from './TabInput.styled';

export interface TabInputProps {
    chips: IChip[];
    className?: string;
    control: Control;
    name: string;
}

export const TabInput = ({
    chips,
    className,
    control,
    name,
}: TabInputProps) => {
    const { liveExperiencesVisibility } = useSelector(
        (state) => state.liveExperiencesVisibility,
    );

    const [activeTab, setActiveTab] = useState(
        liveExperiencesVisibility
            ? CONTENT_TYPE.LIVE_EXPERIENCE
            : CONTENT_TYPE.COURSE,
    );

    useEffect(() => {
        !liveExperiencesVisibility && setActiveTab(CONTENT_TYPE.COURSE);
    }, [liveExperiencesVisibility]);

    const {
        field: { onChange },
    } = useController({
        name,
        control,
        defaultValue: [activeTab],
    });

    const dispatch = useDispatch();

    const handleChange = (chipValue: string) => {
        setActiveTab(chipValue as CONTENT_TYPE);
        dispatch(
            setLibraryTabs({
                sortBy: sortBy[0],
                topic: [],
                type: chipValue as CONTENT_TYPE,
            }),
        );
        onChange(chipValue);
    };

    const generateIcon = (tab: string) => {
        switch (tab) {
            case CONTENT_TYPE.STANDALONE_VIDEO:
                return <IconManager name="VideoIcon" size={16} />;
                break;
            case CONTENT_TYPE.COURSE:
                return <IconManager name="CourseIcon" size={16} />;
                break;
            default:
                return <IconManager name="LiveExpIcon" size={16} />;
        }
    };

    return (
        <TabWrapper className={className}>
            {chips.map((chip) => (
                <Tab
                    active={chip.value === activeTab}
                    key={chip.value}
                    onClick={handleChange}
                    value={chip.value}
                >
                    {generateIcon(chip.value)}
                    {chip.text}s
                </Tab>
            ))}
        </TabWrapper>
    );
};
