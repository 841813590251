import styled from 'styled-components';
import { Button } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';

export const HelpFaqParentPageWrapper = styled.div`
    padding-bottom: 80px;
`;

export const SectionWrapper = styled.div`
    display: flex;
    margin-top: 48px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 32px;
`;

export const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ActionButton = styled(Button)`
    height: 40px;
    margin-left: auto;
    min-width: 160px;
    padding: 10px 27px;
`;

export const SectionText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 16px 0 40px;
`;
