import React, { useEffect } from 'react';
import { BillingCycle } from '@@types/graphql/GraphqlGlobalTypes';
import { ButtonTypes, ColorType } from '@@types/CommonTypes';
import { SignupModule } from '@state/_redux/signup/module';
import { useForm } from 'react-hook-form';
import Stepper from '@components/_form-elements/Stepper/Stepper';
import {
    AdditionalInformation,
    Currency,
    PaymentFormWrapper,
    StepperInformation,
    StepperWrapper,
    StyledButton,
    SumOfPayment,
} from './PaymentForm.styled';

interface IProps {
    buttonType?: ButtonTypes;
    currentValue: number;
    handleSubscribe?: (billingCycle: BillingCycle) => void;
    iconFill?: ColorType[];
    paymentType: BillingCycle;
    readOnlyChildren?: number;
}

interface IForm {
    payment: number;
}

const PaymentForm: React.FC<IProps> = ({
    buttonType = 'primary',
    currentValue,
    handleSubscribe,
    iconFill,
    paymentType,
    readOnlyChildren,
}) => {
    const { handleSubmit, control, watch } = useForm<IForm>({
        defaultValues: {
            payment: readOnlyChildren ?? 1,
        },
    });

    let sumOfPayment = currentValue * watch('payment');
    let currentPayment = watch('payment');

    const onSubmit = () => {
        if (!handleSubscribe) return;
        handleSubscribe(paymentType);
    };

    useEffect(() => {
        sumOfPayment = currentValue * currentPayment;
    }, [currentPayment]);

    return (
        <>
            <PaymentFormWrapper>
                <SumOfPayment>
                    <Currency>£</Currency>
                    {sumOfPayment}
                </SumOfPayment>
                <StepperWrapper>
                    <Stepper
                        control={control}
                        max={10}
                        name={'payment'}
                        readOnlyChildren={readOnlyChildren}
                    />
                    <StepperInformation>+ 1 parent account</StepperInformation>
                </StepperWrapper>
                <StyledButton
                    buttonType={buttonType}
                    disabled={+sumOfPayment === 0}
                    icon={{
                        activeColor: iconFill,
                        color: iconFill,
                        type: 'Lock',
                    }}
                    onPress={handleSubmit(onSubmit)}
                    withoutSubscribe={!handleSubscribe}
                >
                    Subscribe now
                </StyledButton>
            </PaymentFormWrapper>
            <AdditionalInformation withoutSubscribe={!handleSubscribe}>
                After clicking the button ‘Subscribe now’ you will be redirected
                to Stripe payment provider.
            </AdditionalInformation>
        </>
    );
};

export default PaymentForm;
