import styled from 'styled-components';
import TextButton from '@components/_universal/TextButton/TextButton';

export const SearchFormWrapper = styled.form`
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.background_secondary};
    display: flex;
    padding: 12px 0;
`;

export const SearchFormInput = styled.input`
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.text_white};
    flex-grow: 1;
    font-size: ${({ theme }) => theme.fontSizes.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.45;
    outline: none;

    &::placeholder {
        color: ${({ theme }) => theme.colors.icons};
    }
`;

export const SearchFormClearButton = styled(TextButton)`
    color: ${({ theme }) => theme.colors.text_white};
    cursor: ${({ theme }) => theme.cursors.pointer};
    flex-shrink: 0;
    padding: 10px 40px;

    &:hover {
        text-decoration: none;
    }
`;
