import React, { useRef } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { ModalContent } from '@components/_modals/GlobalModals.styled';
import { SelectType } from '@@types/CommonTypes';
import { StudentsListQuery } from '@@types/graphql/StudentsListQuery';
import { useForm } from 'react-hook-form';
import { useMatchMedia, useOnClickOutside } from '@utils/hooks';
import { useUpdateStudentsAccountsClassAndYear } from '@state/students/mutations/useUpdateStudentsAccountsClassAndYear';
import { useUpdateStudentsAccountsGroup } from '@state/students/mutations/useUpdateStudentsAccountsGroups';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import CreatableSelect from '@components/_form-elements/CreatableSelect/CreatableSelect';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import Modal from '@components/_universal/Modal/Modal';
import StudentClassYearForm, {
    IStudentClassYearForm,
} from '@domains/Teacher/Students/_forms/StudentClassYearForm/StudentClassYearForm';
import StudentGroupForm, {
    IStudentGroupForm,
} from '@domains/Teacher/Students/_forms/StudentGroupForm/StudentGroupForm';
import {
    StyledModalText,
    StyledModalTitle,
} from './EditStudentsPropertiesModal.styled';
import {
    ButtonsRow,
    StyledButton,
} from '@domains/Teacher/Students/_forms/Forms.styled';

export interface IStudentPropertiesForm {
    property: SelectType;
}

export interface IProps {
    onClose: () => void;
    refetch: () => Promise<ApolloQueryResult<StudentsListQuery>>;
    studentsIds: number[];
}

const EditStudentsPropertiesModal: React.FC<IProps> = ({
    onClose,
    refetch,
    studentsIds,
}) => {
    const { control, watch } = useForm<IStudentPropertiesForm>();
    const { property } = watch();
    const { match } = useMatchMedia('md');

    const ref = useRef(null);
    useOnClickOutside(ref, onClose);

    const selectProperty = [
        { label: 'Class/Year', value: 'Class/Year' },
        { label: 'Group', value: 'Group' },
    ];

    const [updateStudentsGroups] = useUpdateStudentsAccountsGroup(() => {
        onClose();
        refetch();
    });
    const [updateStudentsClassAndYear] = useUpdateStudentsAccountsClassAndYear(
        () => {
            onClose();
            refetch();
        },
    );

    const onSubmitUpdateStudentsGroup = (data: IStudentGroupForm) => {
        const groupsIds = data?.group?.map((item) => Number(item?.value));

        updateStudentsGroups({
            variables: {
                input: {
                    studentsIds: studentsIds,
                    groupsIds: groupsIds,
                },
            },
        });
    };

    const onSubmitUpdateStudentsClassAndYear = (
        data: IStudentClassYearForm,
    ) => {
        updateStudentsClassAndYear({
            variables: {
                input: {
                    studentsIds: studentsIds,
                    className: data.className?.value as string,
                    year: Number(data?.year?.value),
                },
            },
        });
    };

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={onClose} />
                <StyledModalTitle>Edit students properties</StyledModalTitle>
                <StyledModalText>
                    Please select a property you would like to edit.
                </StyledModalText>
                <CreatableSelectForm
                    control={control}
                    label={'Property'}
                    marginBottom={match ? 8 : 24}
                    name={'property'}
                    options={selectProperty}
                    placeholder={'Select...'}
                    staticPosition={match}
                />
                {property?.value === 'Class/Year' && (
                    <StudentClassYearForm
                        onClose={onClose}
                        onSubmit={onSubmitUpdateStudentsClassAndYear}
                    />
                )}
                {property?.value === 'Group' && (
                    <StudentGroupForm
                        onClose={onClose}
                        onSubmit={onSubmitUpdateStudentsGroup}
                    />
                )}
                {property?.value === undefined && (
                    <ButtonsRow>
                        <StyledButton
                            buttonType={'secondary'}
                            onPress={onClose}
                            type="button"
                        >
                            cancel
                        </StyledButton>
                        <StyledButton
                            buttonType={'primary'}
                            disabled={true}
                            onPress={() => {}}
                        >
                            save
                        </StyledButton>
                    </ButtonsRow>
                )}
            </ModalContent>
        </Modal>
    );
};

const CreatableSelectForm = FieldMessage(CreatableSelect);

export default EditStudentsPropertiesModal;
