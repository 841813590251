import styled, { css } from 'styled-components';
import { InputTypes } from '@components/_form-elements/Input/Input';

const wrapperCss = css`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const InputWrapper = styled.div<{
    backgroundTypeError?: boolean;
    isValid?: boolean;
    marginBottom?: number;
}>`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-bottom: ${({ marginBottom }) =>
        typeof marginBottom === 'number' ? marginBottom : 24}px;
    ${wrapperCss}
    ${({ backgroundTypeError, isValid }) =>
        backgroundTypeError && !isValid && `margin-bottom: 0`};
`;

export const InputInnerWrapper = styled.div`
    position: relative;
    ${wrapperCss}
`;

export const RevealButton = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    height: 52px;
    outline: none;
    padding: 15px;
    position: absolute;
    right: 8px;
    top: 0;
`;

export const Label = styled.label<{ inputType?: InputTypes }>`
    color: ${({ theme, inputType }) =>
        inputType === 'default' ? theme.colors.text : theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin: 8px 0 8px 25px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
`;

const errorInputCss = css`
    border: 1px solid ${({ theme }) => theme.colors.error};
    caret-color: ${({ theme }) => theme.colors.error};
`;

export const StyledInput = styled.input<{
    disabled?: boolean;
    errors?: boolean;
}>`
    background-color: ${({ theme }) => theme.colors.text_white};
    border-radius: ${({ theme }) => theme.borderRadius.button}px;
    border: 1px solid ${({ theme }) => theme.colors.background_tertiary};
    caret-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.3;
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
    padding: 16px 60px 16px 25px;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${({ theme }) => theme.colors.text_secondary};
        font-size: ${({ theme }) => theme.fontSizes.s}px;
        font-weight: ${({ theme }) => theme.fontWeights.regular};
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    &:focus {
        ::placeholder,
        ::-webkit-input-placeholder {
            color: ${({ theme }) => theme.colors.text};
        }
    }

    ${({ errors }) => errors && errorInputCss}
`;
