import { gql, useQuery } from '@apollo/client';
import {
    MostPopularTypesTags,
    MostPopularTypesTagsVariables,
} from '@@types/graphql/MostPopularTypesTags';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

export const MOST_POPULAR_TYPES_TAGS = gql`
    query MostPopularTypesTags($typeFilter: ContentTypeFilter) {
        typesAndTags: getMostPopularContentTypesAndTags(
            typeFilter: $typeFilter
        ) {
            topicTags {
                totalCount
                data {
                    name
                    count
                }
            }
            contentTypes {
                totalCount
                data {
                    name
                    count
                }
            }
        }
    }
`;

export const useMostPopularTypesTags = (
    variables?: MostPopularTypesTagsVariables,
) => {
    return useQuery<MostPopularTypesTags>(MOST_POPULAR_TYPES_TAGS, {
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
        variables,
    });
};
