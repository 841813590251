import styled from 'styled-components';
import { BigHeader, Title } from '@components/_universal/Typography.styled';
import ChoosePayment from '@domains/Auth/_components/ChoosePayment/ChoosePayment';

export const DesktopSubscriptionPageWrapper = styled.div``;

export const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 auto;
    max-width: 480px;
    padding-bottom: 24px;
    text-align: center;
`;

export const DescriptionWrapper = styled.div`
    background: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    margin: 0 auto 60px;
    max-width: 416px;
    position: relative;
`;

export const StyledDescription = styled(BigHeader)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    position: relative;
    text-align: center;
    z-index: 9;
`;

export const SubscriptionWrapper = styled.div`
    display: grid;
    grid-column-gap: 32px;
    grid-template-columns: 1fr 1fr;
    max-width: 750px;
    margin: 0 auto;
`;

export const SubscriptionChoosePayment = styled(ChoosePayment)``;
