import React from 'react';
import CardHolder from '@components/_universal/Cards/CardHolder/CardHolder';
import { DEFAULT_CARD_WIDTHS } from '@components/_universal/Cards/Cards.styled';
import {
    SplitCardImage,
    SplitCardWrapper,
    SplitCardText,
} from './SplitCard.styled';

interface IProps {
    arrangement?: 'horizontal' | 'vertical';
    className?: string;
    featured?: boolean;
    image: string;
    isDifferentHover?: boolean;
    link: string;
    onClick?: () => void;
    text?: string;
    width?: number | string;
}

const SplitCard: React.FC<IProps> = ({
    arrangement = 'vertical',
    className,
    featured,
    image,
    isDifferentHover = false,
    link,
    onClick,
    text,
    width,
}) => {
    const isHorizontal = arrangement === 'horizontal';
    const cardWidth =
        width ??
        (isHorizontal
            ? DEFAULT_CARD_WIDTHS.splitHorizontal
            : DEFAULT_CARD_WIDTHS.split);

    return (
        <CardHolder
            badgeText="Course"
            badgeOptions={{
                backgroundColor: 'course',
                image: { name: 'Course', size: 16 },
                textColor: 'text_white',
            }}
            className={className}
            featured={featured}
            link={link}
            onClick={onClick}
            width={cardWidth}
        >
            <SplitCardWrapper
                isDifferentHover={!!isDifferentHover}
                isHorizontal={isHorizontal}
            >
                <SplitCardImage image={image} />
                <SplitCardText isHorizontal={isHorizontal}>
                    {text}
                </SplitCardText>
            </SplitCardWrapper>
        </CardHolder>
    );
};

export default SplitCard;
