import { ToastTypes } from '@@types/CommonTypes';
import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import {
    RecommendContent,
    RecommendContentVariables,
} from '@@types/graphql/RecommendContent';

export const RECOMMEND_CONTENT = gql`
    mutation RecommendContent($input: RecommendContentInput!) {
        recommendContent(input: $input) {
            status
        }
    }
`;

export const useRecommendContent = () => {
    return useMutation<RecommendContent, RecommendContentVariables>(
        RECOMMEND_CONTENT,
        {
            onCompleted: () => {
                notify('You have successfully recommended content.')[
                    ToastTypes.SUCCESS
                ]();
            },
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
        },
    );
};
