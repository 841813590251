import { gql, useMutation } from '@apollo/client';
import {
    ReadManyNotificationsMutation,
    ReadManyNotificationsMutationVariables,
} from '@@types/graphql/ReadManyNotificationsMutation';

const READ_MANY_NOTIFICATIONS_MUTATION = gql`
    mutation ReadManyNotificationsMutation($deliveriesIds: [Int!]!) {
        readManyNotifications(deliveriesIds: $deliveriesIds)
    }
`;

export default () => {
    return useMutation<
        ReadManyNotificationsMutation,
        ReadManyNotificationsMutationVariables
    >(READ_MANY_NOTIFICATIONS_MUTATION);
};
