import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button, IconManager } from '@components/_universal';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import onboardingCheckboxField from '@assets/images/onboarding_checkbox_field.png';

export const MobileAgreeRulesPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const MobilePageContent = styled.div`
    flex-grow: 1;
`;

export const MobilePageFooter = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    @media (max-width: ${breakpoints.xs}px) {
        align-items: stretch;
        flex-direction: column;
    }
`;

export const MobileReadOurRulesIcon = styled(IconManager)`
    margin-top: -65px;

    @media (max-width: ${breakpoints.md}px) {
        font-size: 150px;
        margin-bottom: -16px;
        margin-top: -50px;
    }
`;

export const MobileOrnamentIcon = styled(IconManager)`
    position: absolute;
    right: -30px;
    top: -50px;
    z-index: 9;
    font-size: 200px;

    @media (max-width: ${breakpoints.sm}px) {
        display: none;
    }
`;

export const MobileTitle = styled(Title)`
    font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 8px;

    @media (max-width: ${breakpoints.xs}px) {
        font-size: ${({ theme }) => theme.fontSizes.xl}px;
    }
`;

export const MobileDescription = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.6;
    max-width: 520px;
    position: relative;

    @media (max-width: ${breakpoints.xs}px) {
        font-size: ${({ theme }) => theme.fontSizes.xs}px;
        line-height: 1.45;
    }
`;

export const MobileRulesList = styled.div`
    display: grid;
    grid-column-gap: 32px;
    grid-template-columns: calc(50% - 16px) calc(50% - 16px);

    @media (max-width: ${breakpoints.md}px) {
        grid-column-gap: 16px;
        grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    }

    @media (max-width: ${breakpoints.sm}px) {
        grid-template-columns: 100%;
    }
`;

export const CheckboxWrapper = styled.div`
    margin-bottom: 24px;
    padding: 0 16px;
    position: relative;
    text-align: center;
    width: 100%;

    @media (max-width: ${breakpoints.sm}px) {
        min-height: 100px;
    }
`;

export const CheckboxBackground = styled.div`
    background: url(${onboardingCheckboxField});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: calc(100% + 36px);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

export const StyledButton = styled(Button)`
    & + & {
        @media (max-width: ${breakpoints.xs}px) {
            margin-bottom: 16px;
            order: -1;
        }
    }
`;
