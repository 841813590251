import React, { useMemo, useState } from 'react';
import { Column, useTable } from 'react-table';
import { IconManager } from '@components/_universal';
import { IStudentForm } from '@domains/Teacher/Students/_forms/StudentForm/StudentForm';
import { Nullable } from '@@types/CommonTypes';
import { useDispatch, useSelector } from 'react-redux';
import EditStudentModal from '@domains/Teacher/Students/_modals/EditStudentModal/EditStudentModal';
import Table from '@components/_universal/Table/Table';
import {
    TableIconButton,
    TableWrapper,
} from '@components/_universal/Table/Table.styled';
import {
    UploadStudentsSummaryListWrapper,
    StyledTableCell,
} from './UploadStudentsSummaryList.styled';
import {
    deleteFromErrorStudentsList,
    editErrorStudentsList,
} from '@state/_redux/uploadCSV/actions';

export interface IProps {}

export interface IUploadStudentsSummaryTableItem {
    className: string;
    email: string;
    id: number;
    name: string;
    number: number;
    status: string;
    surname: string;
    year: string;
}

const UploadStudentsSummaryList: React.FC<IProps> = () => {
    const errorStudentsList = useSelector(
        (state) => state.uploadCSV.errorStudentsList,
    );
    const [editedStudent, setEditedStudent] = useState<
        Nullable<IUploadStudentsSummaryTableItem>
    >(null);

    const dispatch = useDispatch();

    const columns = useMemo<Column<IUploadStudentsSummaryTableItem>[]>(
        () => [
            {
                Header: 'No.',
                accessor: 'number',
                width: 60,
            },
            {
                Header: 'Name',
                accessor: 'name',
                width: 100,
            },
            {
                Header: 'Surname',
                accessor: 'surname',
                width: 120,
            },
            {
                Header: 'E-mail',
                accessor: 'email',
                width: 200,
            },
            {
                Header: 'Class',
                accessor: 'className',
                width: 60,
            },
            {
                Header: 'Year',
                accessor: 'year',
                width: 60,
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: 170,
            },
        ],
        [],
    );

    const data = useMemo<IUploadStudentsSummaryTableItem[]>(
        () => errorStudentsList,
        [errorStudentsList],
    );

    const schoolClasses = data.map((student) => ({
        className: student.className,
        year: student.year,
    }));

    const tableProps = useTable<IUploadStudentsSummaryTableItem>({
        columns,
        data,
    });

    const handleEditStudent = (data: IStudentForm) => {
        if (!editedStudent) return;

        dispatch(
            editErrorStudentsList({
                ...data,
                className: data.className?.value as string,
                id: editedStudent.id,
                number: editedStudent.number,
                status: editedStudent.status,
                year: data.year?.value as string,
            }),
        );
        setEditedStudent(null);
    };

    return (
        <UploadStudentsSummaryListWrapper>
            {editedStudent && (
                <EditStudentModal
                    defaultData={{
                        ...editedStudent,
                        className: {
                            label: editedStudent.className,
                            value: editedStudent.className,
                        },
                        year: {
                            label: editedStudent.year,
                            value: editedStudent.year,
                        },
                    }}
                    defaultSchoolClasses={schoolClasses}
                    onClose={() => setEditedStudent(null)}
                    onSubmit={handleEditStudent}
                />
            )}
            <TableWrapper>
                <Table<IUploadStudentsSummaryTableItem> table={tableProps}>
                    {({
                        getTableBodyProps,
                        getTableProps,
                        headerGroups,
                        prepareRow,
                        rows,
                    }) => (
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(
                                            (column, index) => (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    key={`header ${index}`}
                                                    style={{
                                                        width: column.width,
                                                    }}
                                                >
                                                    {column.render('Header')}
                                                </th>
                                            ),
                                        )}
                                        <th style={{ width: 80 }} />
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, index) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            key={`row ${index}`}
                                        >
                                            {row.cells.map((cell, index) => {
                                                return (
                                                    <StyledTableCell
                                                        {...cell.getCellProps()}
                                                        key={`cell ${index}`}
                                                    >
                                                        {cell.render('Cell')}
                                                    </StyledTableCell>
                                                );
                                            })}
                                            <td
                                                className={'td_icons'}
                                                key={'td_icons'}
                                            >
                                                <TableIconButton
                                                    onClick={() =>
                                                        setEditedStudent(
                                                            data[index],
                                                        )
                                                    }
                                                >
                                                    <IconManager
                                                        cursor="pointer"
                                                        hoverFill={['primary']}
                                                        name={'IconEdit'}
                                                        size={24}
                                                    />
                                                </TableIconButton>
                                                <TableIconButton
                                                    onClick={() =>
                                                        dispatch(
                                                            deleteFromErrorStudentsList(
                                                                [
                                                                    errorStudentsList[
                                                                        index
                                                                    ].id,
                                                                ],
                                                            ),
                                                        )
                                                    }
                                                >
                                                    <IconManager
                                                        cursor="pointer"
                                                        hoverFill={['primary']}
                                                        name={'IconTrash'}
                                                        size={24}
                                                    />
                                                </TableIconButton>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </Table>
            </TableWrapper>
        </UploadStudentsSummaryListWrapper>
    );
};

export default UploadStudentsSummaryList;
