import React from 'react';
import {
    LiveExperienceWrapper,
    Content,
    FooterBar,
    HeaderBar,
    NavigationBar,
} from './LiveExperienceLayout.styled';

interface IProps {}

const LiveExperienceLayout: React.FC<IProps> = ({ children }) => {
    return (
        <LiveExperienceWrapper>
            <HeaderBar trialIcon />
            <NavigationBar borderLine />
            <Content>{children}</Content>
            <FooterBar isBackpack isLogo />
        </LiveExperienceWrapper>
    );
};

export default LiveExperienceLayout;
