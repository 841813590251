import { useEffect, useState } from 'react';
import { SelectType } from '@@types/CommonTypes';
import { useForm } from 'react-hook-form';
import { useMatchMedia } from '@utils/hooks';
import { useSchoolClassesQuery } from '@state/teachers/queries';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import CreatableSelect from '@components/_form-elements/CreatableSelect/CreatableSelect';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import {
    ButtonsRow,
    StudentFormWrapper,
    StyledButton,
    StyledFormRow,
    StyledInputWrapper,
} from '@domains/Teacher/Students/_forms/Forms.styled';

export interface IStudentClassYearForm {
    className: SelectType;
    year: SelectType;
}

type ClassesType = { [key: string]: string[] };

interface IProps {
    onClose: () => void;
    onSubmit: (data: IStudentClassYearForm) => void;
}

const StudentClassYearForm: React.FC<IProps> = ({ onClose, onSubmit }) => {
    const [classes, setClasses] = useState<ClassesType>({});
    const { match } = useMatchMedia('md');

    const {
        control,
        errors,
        handleSubmit,
        watch,
    } = useForm<IStudentClassYearForm>({
        resolver: yupResolver(validation),
    });
    const { year } = watch();

    const { data } = useSchoolClassesQuery();
    const classesData = data?.getSchoolClasses;

    const years = Object.keys(classes).map((classData) => ({
        label: classData,
        value: classData,
    }));

    const selectClasses =
        year?.value && classes[year.value]
            ? classes[year.value].map((classData) => ({
                  label: classData,
                  value: classData,
              }))
            : [];

    useEffect(() => {
        if (!classesData) return;

        let classes: ClassesType = {};

        classesData.map((classData) => {
            const classTable = classes[`${classData.year}`];

            return (classes[`${classData.year}`] = classTable
                ? [...classTable, classData.className]
                : [classData.className]);
        });

        // Remove duplicated classes from table
        for (const key in classes) {
            classes[key] = [...new Set(classes[key])];
        }

        setClasses(classes);
    }, [classesData]);

    return (
        <StudentFormWrapper noValidate={true} onSubmit={handleSubmit(onSubmit)}>
            <StyledInputWrapper>
                <StyledFormRow>
                    <CreatableSelectForm
                        control={control}
                        errors={errors.className}
                        label={'class'}
                        marginBottom={match ? 8 : 24}
                        name={'className'}
                        options={selectClasses}
                        placeholder={'Select...'}
                        staticPosition={match}
                    />
                </StyledFormRow>
                <StyledFormRow>
                    <CreatableSelectForm
                        control={control}
                        errors={errors.year}
                        label={'year'}
                        marginBottom={match ? 8 : 24}
                        name={'year'}
                        options={years}
                        placeholder={'Select...'}
                        staticPosition={match}
                    />
                </StyledFormRow>
            </StyledInputWrapper>
            <ButtonsRow>
                <StyledButton
                    buttonType={'secondary'}
                    onPress={onClose}
                    type="button"
                >
                    cancel
                </StyledButton>
                <StyledButton buttonType={'primary'} onPress={() => {}}>
                    save
                </StyledButton>
            </ButtonsRow>
        </StudentFormWrapper>
    );
};

const CreatableSelectForm = FieldMessage(CreatableSelect);

export default StudentClassYearForm;
