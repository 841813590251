import React from 'react';
import { useSelector } from 'react-redux';
import {
    Content,
    PageTitle,
    TeacherAccountSubLayoutWrapper,
    SubNavigationBar,
    InnerWrapper,
} from './TeacherAccountSubLayout.styled';

interface IProps {}

const TeacherAccountSubLayout: React.FC<IProps> = ({ children }) => {
    const { isAdminAsTeacher } = useSelector((state) => state.signup);

    return (
        <TeacherAccountSubLayoutWrapper paddingTop={isAdminAsTeacher}>
            <PageTitle>My account</PageTitle>
            <InnerWrapper>
                <SubNavigationBar />
                <Content>{children}</Content>
            </InnerWrapper>
        </TeacherAccountSubLayoutWrapper>
    );
};

export default TeacherAccountSubLayout;
