import styled from 'styled-components';
import { BigHeader, Title } from '@components/_universal/Typography.styled';

export const DesktopSignupPricingPageWrapper = styled.div``;

export const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 auto;
    max-width: 544px;
    padding-bottom: 24px;
    text-align: center;
`;

export const DescriptionWrapper = styled(BigHeader)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.l}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 auto 56px;
    position: relative;
    text-align: center;
    line-height: 28px;
`;

export const PricingWrapper = styled.div`
    display: grid;
    grid-column-gap: 32px;
    grid-template-columns: 1fr 1fr 1fr;
`;
