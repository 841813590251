import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const UploadedFileCardWrapper = styled.div`
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    border: 3px solid ${({ theme }) => theme.colors.icons_hover};
    display: flex;
    height: 204px;
    justify-content: space-between;
    padding: 0 56px;
`;

export const InnerLink = styled.span`
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 0;
    text-decoration: none;
`;

export const FileName = styled(DefaultText)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
`;

export const FileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
`;

export const FileInfo = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 0;
`;

export const FileIcon = styled(IconManager)`
    margin-right: 16px;
`;

export const DeleteIcon = styled(IconManager)`
    cursor: pointer;
    margin-left: 28px;
`;

export const DeleteButton = styled.button`
    background: none;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
`;
