import { gql, useQuery } from '@apollo/client';
import { UpcomingLiveExperiencesContentQuery } from '@@types/graphql/UpcomingLiveExperiencesContentQuery';

export const UPCOMING_LIVE_EXPERIENCES_CONTENT_QUERY = gql`
    query UpcomingLiveExperiencesContentQuery {
        getYourUpcomingLiveExperiences {
            _id
            featured
            maxNoParticipants
            name
            noOfSignups
            startDate
            thumbnail
            type
        }
    }
`;

export default () =>
    useQuery<UpcomingLiveExperiencesContentQuery>(
        UPCOMING_LIVE_EXPERIENCES_CONTENT_QUERY,
    );
