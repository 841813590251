import { gql, useQuery } from '@apollo/client';
import { ChildsListQuery } from '@@types/graphql/ChildsListQuery';

export const CHILDS_LIST_QUERY = gql`
    query ChildsListQuery {
        getChildsForParent {
            id
            avatar
            name
            surname
            email
            status
            birthday
            deactivateAtCurrentPeriodEnd
            subscriptionCurrentPeriodEnd
        }
    }
`;

export default () => useQuery<ChildsListQuery>(CHILDS_LIST_QUERY);
