import { DescriptionBox } from '@components/_universal/DescriptionBox/DescriptionBox';
import { DetailsInfo } from '@components/_universal/DetailsInfo/DetailsInfo';
import { LiveExperienceSteps } from '@@types/CommonTypes';
import { useLiveExperience } from './useLiveExperience';
import ActivityMaterials from '@components/ActivityMaterials/ActivityMaterials';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import ExperienceInfo from '@domains/Child/LiveExperience/_components/ExperienceInfo/ExperienceInfo';
import JoinBox from '@domains/Child/LiveExperience/_components/JoinBox/JoinBox';
import RateModal from '@domains/Child/LiveExperience/_modals/RateModal/RateModal';
import SaveForLater from '@components/_universal/SaveForLater/SaveForLater';
import SubmitWork from '@components/_universal/SubmitWork/SubmitWork';
import SubmitWorkForm from '@domains/Child/LiveExperience/_forms/SubmitWorkForm/SubmitWorkForm';
import {
    DividedContainer,
    LeftBox,
    LiveExperienceWrapper,
    PageTitle,
    RightBox,
    SectionHeader,
    SectionTitle,
} from './LiveExperience.styled';

const LiveExperiencePage = () => {
    const {
        data,
        isConsumed,
        isFromNotify,
        isFullyBooked,
        isOngoing,
        isSignedUp,
        liveExperienceData,
        loading,
        loadingRemove,
        loadingSave,
        onTimerComplete,
        removeSavedForLater,
        saveForLater,
        setShowModal,
        showModal,
        workFileSubmitted,
    } = useLiveExperience();

    const stepVariant = (): LiveExperienceSteps => {
        if (isConsumed && workFileSubmitted) {
            return 'submitted';
        }
        if (isConsumed || isFromNotify) {
            return 'experienceDone';
        }
        if (isSignedUp && isOngoing) {
            return 'ongoing';
        }
        if (isSignedUp) {
            return 'signedUp';
        }
        if (isFullyBooked) {
            return 'fullyBooked';
        }
        return 'signUp';
    };

    return (
        <LiveExperienceWrapper>
            <PageTitle>Experience details</PageTitle>
            <SectionHeader>
                <SectionTitle>{data?.getLiveExperience.name}</SectionTitle>
                <SaveForLater
                    disabled={loadingRemove || loadingSave}
                    isActive={!!data?.getLiveExperience.isSavedForLater}
                    onClick={() => {
                        data?.getLiveExperience.isSavedForLater
                            ? removeSavedForLater({
                                  variables: {
                                      contentId:
                                          data?.getLiveExperience._id || '',
                                  },
                              })
                            : saveForLater({
                                  variables: {
                                      contentId:
                                          data?.getLiveExperience._id || '',
                                  },
                              });
                    }}
                    size={32}
                />
            </SectionHeader>
            <DividedContainer>
                <LeftBox>
                    <ExperienceInfo
                        link={liveExperienceData?.link || ''}
                        loading={loading}
                        stepVariant={stepVariant()}
                        {...{ onTimerComplete }}
                    />
                </LeftBox>
                <RightBox>
                    <DetailsInfo />
                </RightBox>
            </DividedContainer>
            {stepVariant() === 'signedUp' && <JoinBox />}

            {(stepVariant() === 'experienceDone' ||
                stepVariant() === 'submitted') && (
                <SubmitWork
                    contentType="LiveExperience"
                    getQueryLoading={loading}
                    homework={liveExperienceData?.homework}
                >
                    <SubmitWorkForm />
                </SubmitWork>
            )}

            <DividedContainer>
                <LeftBox>
                    <DescriptionBox title="Description">
                        {liveExperienceData?.description}
                    </DescriptionBox>
                </LeftBox>
                <RightBox>
                    <ActivityMaterials
                        isEmpty={
                            stepVariant() === 'signUp' ||
                            stepVariant() === 'fullyBooked'
                        }
                    />
                </RightBox>
            </DividedContainer>
            <ChatWithMentor />

            {(stepVariant() === 'experienceDone' ||
                stepVariant() === 'submitted') &&
                !liveExperienceData?.isRated &&
                liveExperienceData?.isUserSignuped &&
                showModal && (
                    <RateModal
                        setIsModalOpen={() => setShowModal(false)}
                        subtitle={'Your opinion is important to us. Share it!'}
                        title={'Rate experience'}
                    />
                )}
        </LiveExperienceWrapper>
    );
};

export default LiveExperiencePage;
