import styled from 'styled-components';
import {
    BigHeader,
    SubTitle,
    Title,
} from '@components/_universal/Typography.styled';

export const LiveExperienceWrapper = styled.div`
    padding-left: 100px;
`;

export const PageTitle = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    padding-top: 34px;
`;

export const SectionHeader = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 48px;
    margin-top: 86px;
`;

export const SectionTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
    margin-right: 24px;
    min-height: 44px;
`;

export const DividedContainer = styled.div`
    display: flex;
    margin-bottom: 80px;
`;

export const LeftBox = styled.div`
    flex: 2;
`;

export const RightBox = styled.div`
    flex: 1;
    margin-left: 64px;
`;

export const DefaultTextBold = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
