import { setIsAdminAsTeacher } from '@state/_redux/signup/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useStudentsCountQuery } from '@state/students/queries';
import DashboardTeacher from './DashboardTeacher/DashboardTeacher';
import DashboardTeacherUploadStudents from './DashboardTeacherUploadStudents/DashboardTeacherUploadStudents';
import Loader from '@components/_universal/Loader/Loader';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import { DashboardTeacherPageWrapper } from './DashboardTeacherPage.styled';

const DashboardTeacherPage: React.FC = () => {
    const { data, loading } = useStudentsCountQuery();
    const isExistStudent = !!data?.getStudentsCount.count;

    const adminToken = LocalStorageManager.getValue('adminSessionToken');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsAdminAsTeacher(!!adminToken));
    }, []);

    return (
        <DashboardTeacherPageWrapper removePadding={!!adminToken}>
            {loading ? (
                <Loader />
            ) : isExistStudent ? (
                <DashboardTeacher />
            ) : (
                <DashboardTeacherUploadStudents
                    isExistStudent={isExistStudent}
                    title={'Welcome to your dashboard'}
                    subtitle={'We are glad you are here! So what happens next?'}
                />
            )}
        </DashboardTeacherPageWrapper>
    );
};

export default DashboardTeacherPage;
