import { IMosaicCard } from '@@types/CommonTypes';
import { LibraryContentQueryVariables } from '@@types/graphql/LibraryContentQuery';
import { LibraryPageWrapper, PageTitle } from './LibraryPage.styled';
import { useChildLiveExperienceVisibility } from './useChildLiveExperienceVisibility';
import { useLibraryContentPage } from './useLibraryContentPage';
import { useSelector } from 'react-redux';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import MosaicContent from '@components/MosaicContent/MosaicContent';
import Paginate from '@components/_universal/Paginate/Paginate';
import parseContentToCards from '@utils/parseContentToCards';

const SEARCH_LIMIT = 10;

const LibraryPage: React.FC = () => {
    useChildLiveExperienceVisibility();
    const { liveExperiencesVisibility } = useSelector(
        (state) => state.liveExperiencesVisibility,
    );
    const libraryFilters = useSelector(({ filters }) => filters.library);
    const generateVariables = (
        offset?: number,
    ): LibraryContentQueryVariables => ({
        paginationInput: {
            take: SEARCH_LIMIT,
            offset: offset ?? 0,
        },
        sorting: libraryFilters.sortBy?.value,
        typeFilter: libraryFilters.type.reduce(
            (previous, current) => ({ ...previous, [current]: true }),
            {},
        ),
        topicFilter: libraryFilters.topic,
    });
    const {
        data,
        hasNextPage,
        loading,
        onLoadMore,
        paginateLoading,
    } = useLibraryContentPage({
        generateVariables,
        libraryFilters,
    });
    const parsedContentToCards = parseContentToCards(data?.content ?? []);

    return (
        <LibraryPageWrapper>
            <PageTitle>Library</PageTitle>
            <Paginate
                loading={paginateLoading}
                queryLoading={loading}
                hasNextPage={hasNextPage}
                onLoadMore={onLoadMore}
            >
                <MosaicContent
                    cards={
                        liveExperiencesVisibility
                            ? parsedContentToCards
                            : parsedContentToCards.filter(
                                  (item: IMosaicCard) =>
                                      item.type !== 'LIVE_EXPERIENCE',
                              )
                    }
                />
                <ChatWithMentor />
            </Paginate>
        </LibraryPageWrapper>
    );
};

export default LibraryPage;
