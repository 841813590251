import React from 'react';
import { BillingCycle } from '@@types/graphql/GraphqlGlobalTypes';
import { ButtonTypes, ColorType } from '@@types/CommonTypes';
import PaymentForm from '@domains/Auth/_forms/PaymentForm/PaymentForm';
import {
    AdditionalText,
    ChoosePaymentInnerWrapper,
    ChoosePaymentWrapper,
    Discount,
    StyledTitle,
} from './ChoosePayment.styled';

interface IProps {
    buttonType?: ButtonTypes;
    currentValue?: number;
    handleSubscribe?: (billingCycle: BillingCycle) => void;
    iconFill?: ColorType[];
    paymentType?: BillingCycle;
    readOnlyChildren?: number;
}

const ChoosePayment: React.FC<IProps> = ({
    buttonType = 'primary',
    children = 'Monthly',
    currentValue = 40,
    handleSubscribe,
    iconFill,
    paymentType = BillingCycle.MONTH,
    readOnlyChildren,
}) => {
    const isOnceAYear = paymentType === BillingCycle.YEAR;
    return (
        <ChoosePaymentWrapper>
            <ChoosePaymentInnerWrapper>
                <AdditionalText>Pay once a {paymentType}</AdditionalText>
                <StyledTitle isOnceAYear={isOnceAYear}>{children}</StyledTitle>
                {isOnceAYear && <Discount>Get 4 months free</Discount>}
                <PaymentForm
                    buttonType={buttonType}
                    currentValue={currentValue}
                    handleSubscribe={handleSubscribe}
                    iconFill={iconFill}
                    paymentType={paymentType}
                    readOnlyChildren={readOnlyChildren}
                />
            </ChoosePaymentInnerWrapper>
        </ChoosePaymentWrapper>
    );
};

export default ChoosePayment;
