import React from 'react';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import Courses from '@domains/Child/Courses/_components/Courses/Courses';
import ExperiencesSaveForLater from '@domains/Child/Courses/_components/ExperiencesSaveForLater/ExperiencesSaveForLater';
import Videos from '@domains/Child/Courses/_components/Videos/Videos';
import { SaveForLaterPageWrapper, PageTitle } from './SaveForLaterPage.styled';

interface IProps {}

const SaveForLaterPage: React.FC<IProps> = () => {
    return (
        <SaveForLaterPageWrapper>
            <PageTitle>Save for later</PageTitle>
            <ExperiencesSaveForLater />
            <Courses />
            <Videos />
            <ChatWithMentor />
        </SaveForLaterPageWrapper>
    );
};

export default SaveForLaterPage;
