import React, { useRef } from 'react';
import { ModalContent } from '@components/_modals/GlobalModals.styled';
import { StyledModalTitle } from './NewStudentModal.styled';
import { useCreateStudentMutation } from '@state/students/mutations';
import { useOnClickOutside } from '@utils/hooks';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import Modal from '@components/_universal/Modal/Modal';
import StudentForm, {
    IStudentForm,
} from '@domains/Teacher/Students/_forms/StudentForm/StudentForm';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const NewStudentModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsModalOpen(false));

    const [createStudent] = useCreateStudentMutation(() =>
        setIsModalOpen(false),
    );

    const onSubmit = (data: IStudentForm) => {
        createStudent({
            variables: {
                input: {
                    ...data,
                    className: data.className?.value as string,
                    year: +(data.year?.value as string),
                },
            },
        });
    };

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={() => setIsModalOpen(false)} />
                <StyledModalTitle>Add student</StyledModalTitle>

                <StudentForm
                    onClose={() => setIsModalOpen(false)}
                    onSubmit={onSubmit}
                />
            </ModalContent>
        </Modal>
    );
};

export default NewStudentModal;
