import React from 'react';
import { useCourse } from '@domains/Child/Course/CoursePage/useCourse';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { VideoThumbnail } from '@components/_universal/VideoThumbnail/VideoThumbnail';
import InfoTiles from '@components/_universal/InfoTiles/InfoTiles';
import Loader from '@components/_universal/Loader/Loader';
import paths from '@shared/paths';
import RateCourse from '@domains/Child/Course/_components/RateCourse/RateCourse';
import RateCourseForm from '@domains/Child/Course/_forms/RateCourseForm/RateCourseForm';
import SaveForLater from '@components/_universal/SaveForLater/SaveForLater';
import SimilarContent from '@domains/Child/Course/_components/SimilarContent/SimilarContent';
import SubmitWork from '@components/_universal/SubmitWork/SubmitWork';
import SubmitWorkForm from '@domains/Child/Course/_forms/SubmitWorkForm/SubmitWorkForm';
import {
    CourseDescription,
    CourseLessonsNavigation,
    CoursePageWrapper,
    CourseWrapper,
    InnerWrapper,
    LessonsList,
    ListTitle,
    LoaderWrapper,
    PageContent,
    PageSubtitle,
    PageTitle,
    PlayButton,
    SectionHeader,
    SectionTitle,
    VideoPlayerWrapper,
} from './CoursePage.styled';

const CoursePage = () => {
    const history = useHistory();
    const { courseId } = useParams<{ courseId: string }>();
    const {
        aboutVideoData,
        aboutVideoLoading,
        activityMaterialData,
        courseData,
        data,
        isVideoPlayed,
        loading,
        loadingRemove,
        loadingSave,
        removeSavedForLater,
        saveForLater,
        setIsVideoPlayed,
    } = useCourse(courseId);

    return (
        <CoursePageWrapper>
            <PageTitle>Course details</PageTitle>
            {!loading && (
                <SectionHeader>
                    <SectionTitle>{courseData?.name}</SectionTitle>
                    <SaveForLater
                        disabled={loadingRemove || loadingSave}
                        isActive={!!courseData?.isSavedForLater}
                        onClick={() => {
                            courseData?.isSavedForLater
                                ? removeSavedForLater({
                                      variables: {
                                          contentId: courseData?._id || '',
                                      },
                                  })
                                : saveForLater({
                                      variables: {
                                          contentId: courseData?._id || '',
                                      },
                                  });
                        }}
                        size={32}
                    />
                </SectionHeader>
            )}
            <PageContent>
                <CourseWrapper>
                    <InnerWrapper>
                        {!loading && (
                            <InfoTiles
                                activityMaterials={{
                                    link:
                                        activityMaterialData?.getLinkForActivityMaterials ??
                                        '',
                                    name:
                                        courseData?.activityMaterials
                                            ?.filename ?? '',
                                    size:
                                        courseData?.activityMaterials
                                            ?.filesize ?? 0,
                                }}
                                courseDuration={courseData?.duration}
                                lessonsCount={courseData?.lessons.length}
                            />
                        )}
                        {loading && (
                            <LoaderWrapper>
                                <Loader size={'medium'} />
                            </LoaderWrapper>
                        )}
                        {!loading && (
                            <>
                                <PageSubtitle>Course description</PageSubtitle>
                                <CourseDescription>
                                    {courseData?.description}
                                </CourseDescription>
                                {data?.getCourse.isConsumed ? (
                                    <RateCourse
                                        isRated={data?.getCourse.isRated}
                                    >
                                        <RateCourseForm />
                                    </RateCourse>
                                ) : !data?.getCourse.consumption?.progress
                                      .allLessonsCompleted ? (
                                    <VideoPlayerWrapper>
                                        <img
                                            alt={'thumbnail'}
                                            src={data?.getCourse.thumbnail}
                                        />
                                        <PlayButton
                                            cursor={'pointer'}
                                            name={'PlayXL'}
                                            onClick={() =>
                                                history.push(
                                                    paths.course_lesson(
                                                        courseId,
                                                        data?.getCourse
                                                            .lessons?.[0]._id ||
                                                            '',
                                                    ),
                                                )
                                            }
                                            size={98}
                                        />
                                    </VideoPlayerWrapper>
                                ) : null}
                            </>
                        )}
                    </InnerWrapper>
                    <CourseLessonsNavigation>
                        <VideoThumbnail
                            aboutVideoData={aboutVideoData}
                            aboutVideoLoading={aboutVideoLoading}
                            isVideoPlayed={isVideoPlayed}
                            setIsVideoPlayed={setIsVideoPlayed}
                        />
                        <ListTitle>Lessons</ListTitle>
                        <LessonsList
                            list={courseData?.lessons?.map((item) => ({
                                done: item.consumption?.consumed,
                                duration: item.video.duration,
                                id: item.video._id,
                                link: paths.course_lesson(courseId, item._id),
                                thumbnail: item.video.thumbnail ?? '',
                                title: item.name,
                            }))}
                            loading={loading}
                            variant={'secondary'}
                        />
                    </CourseLessonsNavigation>
                </CourseWrapper>
                <SubmitWork
                    contentType="Course"
                    getQueryLoading={loading}
                    homework={courseData?.homework}
                >
                    <SubmitWorkForm />
                </SubmitWork>
                <SimilarContent />
            </PageContent>
        </CoursePageWrapper>
    );
};

export default CoursePage;
