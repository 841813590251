import React, { useRef, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { StudentsListQuery } from '@@types/graphql/StudentsListQuery';
import { useOnClickOutside } from '@utils/hooks';
import { useParams } from 'react-router';
import { useUpdateStudentsGroup } from '@state/students/mutations/useUpdateStudentsGroup';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import Modal from '@components/_universal/Modal/Modal';
import {
    GroupForm,
    StudentsGroupForm,
} from '@domains/Teacher/Groups/_forms/GroupForm/GroupForm';
import {
    ModalContent,
    ModalTitle,
} from '@components/_modals/GlobalModals.styled';

export interface EditStudentsGroupModalProps {
    defaultData: StudentsGroupForm;
    onClose: () => void;
    refetch: () => Promise<ApolloQueryResult<StudentsListQuery>>;
}

export const EditStudentsGroupModal = ({
    defaultData,
    onClose,
    refetch,
}: EditStudentsGroupModalProps) => {
    const ref = useRef(null);
    const { groupId } = useParams<{ groupId: string }>();
    useOnClickOutside(ref, onClose);

    const [isError, setIsError] = useState(false);

    const [updateStudentsGroup] = useUpdateStudentsGroup(() => {
        onClose();
        refetch();
    });

    const handleEditStudent = (data: StudentsGroupForm) => {
        if (isError) return;

        if (data.groupTitle === defaultData.groupTitle) {
            onClose();
            return;
        }

        updateStudentsGroup({
            variables: {
                input: {
                    groupId: +groupId,
                    title: data.groupTitle,
                },
            },
        });
    };

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={onClose} />
                <ModalTitle>Edit group title</ModalTitle>
                <GroupForm
                    buttonText="Save"
                    defaultData={defaultData}
                    isError={isError}
                    onClose={onClose}
                    onSubmit={handleEditStudent}
                    setIsError={setIsError}
                />
            </ModalContent>
        </Modal>
    );
};
