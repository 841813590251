import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import IconButton from '@components/_universal/IconButton/IconButton';

export const CarouselWrapper = styled.div<{
    arrowsOffset?: number;
}>`
    overflow: visible;
    position: relative;

    .swiper-button-prev,
    .swiper-button-next {
        width: 42px;
        height: 42px;

        &:after {
            display: none;
        }
    }

    .swiper-button-disabled {
        display: none;
    }

    .swiper-button-prev {
        left: ${({ arrowsOffset }) => arrowsOffset ?? '40'}px;
    }

    .swiper-button-next {
        right: ${({ arrowsOffset }) => arrowsOffset ?? '40'}px;
    }
`;

export const PrevButton = styled.div``;

export const NextButton = styled.div``;

export const StyledIconButton = styled(IconButton)`
    background: ${({ theme }) => theme.colors.secondary};
    box-shadow: none;
    cursor: ${({ theme }) => theme.cursors.pointer};
    margin-top: -20px;
    transition: all ${({ theme }) => theme.transitions.default}s;

    &:hover {
        background: ${({ theme }) => theme.colors.secondary_hover};
        box-shadow: none;
    }
`;

export const SwiperWrapper = styled(Swiper)`
    margin-left: auto;
    margin-right: auto;
    overflow: visible;

    .swiper-slide {
        width: auto;
    }
`;
