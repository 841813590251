import { useSelector } from 'react-redux';
import Loader from '@components/_universal/Loader/Loader';
import SearchBar from '@components/_universal/Table/SearchBar/SearchBar';
import {
    GroupsPageWrapper,
    HeaderPageWrapper,
    LoaderWrapper,
    PageTitle,
} from './PageWrapper.styled';

export interface PageWrapperProps {
    children: JSX.Element;
    groupsCountLoading?: boolean;
    isGroupExists?: boolean;
    setSearch: (value: string) => void;
}

export const PageWrapper = ({
    children,
    groupsCountLoading = false,
    isGroupExists,
    setSearch,
}: PageWrapperProps) => {
    const { isAdminAsTeacher } = useSelector((state) => state.signup);

    return (
        <GroupsPageWrapper>
            <HeaderPageWrapper extended={isAdminAsTeacher}>
                <PageTitle>Groups</PageTitle>
                {isGroupExists && (
                    <SearchBar
                        placeholder={'Search for group title, student etc.'}
                        setFilter={setSearch}
                    />
                )}
            </HeaderPageWrapper>
            {groupsCountLoading ? (
                <LoaderWrapper>
                    <Loader size={'medium'} center />
                </LoaderWrapper>
            ) : (
                children
            )}
        </GroupsPageWrapper>
    );
};
