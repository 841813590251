import React from 'react';
import {
    BigText,
    EmptyDataSearchWrapper,
    PencilsRulerOrnament,
    SmallText,
} from '@components/_universal/Table/EmptyDataSearch/EmptyDataSearch.styled';

interface IProps {}

const EmptyDataSearch: React.FC<IProps> = ({}) => {
    return (
        <EmptyDataSearchWrapper>
            <BigText>Sorry...</BigText>
            <SmallText>
                We couldn’t find a student who you were looking for.{' '}
            </SmallText>
            <PencilsRulerOrnament name={'IconPencilsRuler'} size={112} />
        </EmptyDataSearchWrapper>
    );
};

export default EmptyDataSearch;
