import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { Link } from 'react-router-dom';
import { IconManager } from '@components/_universal';

export const ActivityWrapper = styled.div`
    align-items: center;
    display: flex;
    margin: 28px 0 32px;
    padding-right: 32px;
`;

export const ActivityInnerWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-grow: 1;
    margin-right: 32px;
`;

export const ActivityText = styled(DefaultText)(
    () => css`
        line-height: 1.5;
        margin: 0 0 0 14px;
        max-width: 505px;
    `,
);

export const FeedbackWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background-color: ${colors.background_quaternary};
        border-radius: ${borderRadius.card}px;
        display: grid;
        grid-gap: 24px;
        grid-template-columns: 1fr 542px;
        padding: 24px 32px;
    `,
);

export const MentorData = styled.div``;

export const FeedbackInnerWrapper = styled.div``;

export const MentorName = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.7;
        margin-bottom: 4px;
    `,
);

export const MentorSecondaryText = styled(DefaultText)(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.text_secondary};
        font-size: ${fontSizes.xs}px;
        line-height: 1.7;
        margin: 0;
    `,
);

export const FeedbackText = styled(DefaultText)(
    ({ theme: { fontSizes } }) => css`
        font-size: ${fontSizes.s}px;
        margin-bottom: 16px;
    `,
);

export const FeedbackFile = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const VideoFeedbackText = styled(IconManager)`
    pointer-events: none;
    position: absolute;
    right: calc(100% + 14px);
    top: 16px;
`;
