import styled from 'styled-components';

export const RatingWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;
export const StarWrapper = styled.div`
    margin: 0 8px;

    &:last-child {
        margin-right: 10px;
    }
`;
