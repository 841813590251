import styled from 'styled-components';
import Footer from '@components/_global/Footer/Footer';
import Navigation from '@components/_global/Navigation/ChildNavigation/ChildNavigation';
import { IconManager } from '@components/_universal';

export const ErrorLayoutWrapper = styled.div`
    display: grid;
    grid-template-areas:
        'navigation . .'
        'navigation content .'
        'navigation footer .';
    grid-template-columns: 1fr 1140px 1fr;
    grid-template-rows: 100px 1fr 90px;
    min-height: 100%;
    width: 100%;
`;

export const FooterBar = styled(Footer)`
    grid-area: footer;
`;

export const NavigationBar = styled(Navigation)`
    grid-area: navigation;
`;

export const Content = styled.div`
    grid-area: content;
    justify-self: center;
    align-self: center;
    position: relative;
`;
