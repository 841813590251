import { gql, useMutation } from '@apollo/client';
import {
    CancelDeactivationChildAccountMutation,
    CancelDeactivationChildAccountMutationVariables,
} from '@@types/graphql/CancelDeactivationChildAccountMutation';
import { ToastTypes } from '@@types/CommonTypes';
import { notify } from '@components/ToastNotification/ToastNotification';
import { SetUserStatusFragment } from '@@types/graphql/SetUserStatusFragment';
import { USER_STATUS } from '@@types/graphql/GraphqlGlobalTypes';
import client from '@boot/apolloClient';

const CANCEL_DEACTIVATION_CHILD_ACCOUNT_MUTATION = gql`
    mutation CancelDeactivationChildAccountMutation($childId: Int!) {
        cancelDeactivationChildAccount(childId: $childId)
    }
`;

const FRAGMENT = gql`
    fragment SetUserStatusFragment on User {
        status
        deactivateAtCurrentPeriodEnd
    }
`;

export default () => {
    return useMutation<
        CancelDeactivationChildAccountMutation,
        CancelDeactivationChildAccountMutationVariables
    >(CANCEL_DEACTIVATION_CHILD_ACCOUNT_MUTATION, {
        onCompleted: (data) => {
            client.writeFragment<SetUserStatusFragment>({
                id: `User:${data.cancelDeactivationChildAccount}`,
                fragment: FRAGMENT,
                data: {
                    status: USER_STATUS.ACTIVE,
                    deactivateAtCurrentPeriodEnd: false,
                },
            });
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
