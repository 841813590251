import React from 'react';
import { DetailsCard } from '../DetailsCard/DetailsCard';
import { useGetLiveExperienceQuery } from '@state/liveExperience/queries';
import { useParams } from 'react-router-dom';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { DetailsInfoWrapper, StyledBigHeader } from './DetailsInfo.styled';

dayjs.extend(duration);

interface DetailsInfoProps {
    className?: string;
    userType?: USER_TYPE;
}

export const DetailsInfo = ({
    className,
    userType = USER_TYPE.CHILD,
}: DetailsInfoProps) => {
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();
    const { data } = useGetLiveExperienceQuery(liveExperienceId);
    const title =
        (data?.getLiveExperience.duration &&
            dayjs
                .duration(data?.getLiveExperience.duration, 'minute')
                .format('H:mm')) ||
        '';
    const date =
        (data?.getLiveExperience.startDate &&
            dayjs(data?.getLiveExperience.startDate).format(
                'MMM DD.MM.YYYY | h:mm a',
            )) ||
        '';

    return (
        <DetailsInfoWrapper className={className}>
            <StyledBigHeader>Date & time</StyledBigHeader>
            <DetailsCard
                iconName={'Mentor'}
                title={data?.getLiveExperience.facilitatedBy.name ?? '-'}
                userType={userType}
            >
                Facilitator
            </DetailsCard>
            <DetailsCard iconName={'Schedule'} title={date} userType={userType}>
                {`Date & Time ${userType === USER_TYPE.TEACHER && `(UK Time)`}`}
            </DetailsCard>
            <DetailsCard
                iconName={'TimeDuration'}
                title={`${title} ${
                    userType === USER_TYPE.TEACHER ? 'h' : 'hours'
                }`}
                userType={userType}
            >
                Duration
            </DetailsCard>
        </DetailsInfoWrapper>
    );
};
