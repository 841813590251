import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import { Title } from '@components/_universal/Typography.styled';

export const DesktopLoginLinkPageWrapper = styled.div`
    align-items: center;
    align-self: center;
    background: rgba(81, 91, 230, 0.95);
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    max-height: 910px;
    min-height: 840px;
    overflow: hidden;
    padding-top: 54px;
    width: 100%;
`;

export const LogoIcon = styled(IconManager)``;

export const LogoLink = styled.a``;

export const FormWrapper = styled.div`
    width: 554px;
`;

export const EmailText = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.big}px;
    margin-bottom: 46px;
    position: relative;
`;

export const ButtonLink = styled(Button)`
    margin: 24px 0 32px;
    width: 100%;
`;
