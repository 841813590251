import { gql, useQuery } from '@apollo/client';
import { NotificationSettingsQuery } from '@@types/graphql/NotificationSettingsQuery';

export const GET_USER_NOTIFICATION_SETTINGS_QUERY = gql`
    query NotificationSettingsQuery {
        getUserNotificationSettings {
            emailNotificationEnabled
            inAppNotificationEnabled
        }
    }
`;

export default () =>
    useQuery<NotificationSettingsQuery>(GET_USER_NOTIFICATION_SETTINGS_QUERY, {
        // TODO: handle completed action
        onCompleted: (payload) => {},
        onError: (error) => {
            console.log(error);
        },
    });
