import React from 'react';
import { DoneExperience } from './DoneExperience/DoneExperience';
import { ExperienceNotStart } from './ExperienceNotStart/ExperienceNotStart';
import { JoinExperience } from './JoinExperience/Joinexperience';
import { LiveExperienceType } from '@@types/CommonTypes';
import Loader from '@components/_universal/Loader/Loader';
import { ExperienceInfoWrapper } from './ExperienceInfo.styled';

interface ExperienceInfoProps {
    className?: string;
    link: string;
    loading: boolean;
    onTimerComplete: () => void;
    stepVariant: LiveExperienceType;
}

export const ExperienceInfo = ({
    className,
    link,
    loading,
    onTimerComplete,
    stepVariant,
}: ExperienceInfoProps) => {
    const experienceVariants = () => {
        return {
            experienceDone: <DoneExperience />,
            experienceNotStart: (
                <ExperienceNotStart onTimerComplete={onTimerComplete} />
            ),
            join: <JoinExperience {...{ link }} />,
        };
    };
    return (
        <ExperienceInfoWrapper className={className}>
            {loading ? (
                <Loader size={'medium'} />
            ) : (
                experienceVariants()[stepVariant]
            )}
        </ExperienceInfoWrapper>
    );
};
