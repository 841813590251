import { useEffect, useRef, useState } from 'react';
import { Nullable } from '@@types/CommonTypes';
import { PlayerSdk } from '@api.video/player-sdk';
import { useCourseQuery } from '@state/selfServeCourse/queries';
import { useGetLinkForActivityMaterialsQuery } from '@state/files/queries';
import { useSaveForLaterMutation } from '@state/savedForLater/mutations';
import { useVideoLinkQuery } from '@state/video/queries';
import useRemoveSavedForLater from '@state/savedForLater/mutations/useRemoveSavedForLater';

export const useCourse = (courseId: string) => {
    const [isVideoPlayed, setIsVideoPlayed] = useState(false);

    const { data, loading } = useCourseQuery(courseId);
    const courseData = data?.getCourse;
    const [
        getVideoLink,
        { data: aboutVideoData, loading: aboutVideoLoading },
    ] = useVideoLinkQuery();
    const playerRef = useRef<Nullable<PlayerSdk>>(null);
    const [
        getActivityMaterial,
        { data: activityMaterialData },
    ] = useGetLinkForActivityMaterialsQuery(courseId);

    const [
        removeSavedForLater,
        { loading: loadingRemove },
    ] = useRemoveSavedForLater(courseId, 'Course');

    const [saveForLater, { loading: loadingSave }] = useSaveForLaterMutation(
        courseId,
        'Course',
    );

    useEffect(() => {
        getActivityMaterial();
    }, [courseId]);

    useEffect(() => {
        courseData &&
            getVideoLink({
                variables: {
                    id: courseData.aboutVideo._id,
                },
            });
    }, [courseData?.aboutVideo._id]);

    useEffect(() => {
        if (!!aboutVideoData && !playerRef.current && isVideoPlayed)
            playerRef.current = new PlayerSdk(`#about_video`);

        return () => {
            playerRef.current = null;
        };
    }, [aboutVideoData, isVideoPlayed]);

    return {
        aboutVideoData,
        aboutVideoLoading,
        activityMaterialData,
        courseData,
        data,
        isVideoPlayed,
        loading,
        loadingRemove,
        loadingSave,
        removeSavedForLater,
        saveForLater,
        setIsVideoPlayed,
    };
};
