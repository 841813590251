import { IconManager } from '@components/_universal';
import { DeleteGroupModal } from '@domains/Teacher/Groups/GroupPage/_modals/DeleteGroupModal/DeleteGroupModal';
import { GroupsTableItem } from '@domains/Teacher/Groups/GroupsTeacherPage/GroupsTeacherPage';
import { TableInstance } from 'react-table';
import { useHistory } from 'react-router';
import { useState } from 'react';
import EmptyDataSearch from '@components/_universal/Table/EmptyDataSearch/EmptyDataSearch';
import EmptyGroupsList from '@domains/Teacher/Groups/GroupsTeacherPage/_components/EmptyGroupsList/EmptyGroupsList';
import Loader from '@components/_universal/Loader/Loader';
import Table from '@components/_universal/Table/Table';
import paths from '@shared/paths';
import { StudentsListWrapper, TableBodyRow } from './GroupsList.styled';
import {
    LoaderWrapper,
    TableIconButton,
    TableWrapper,
} from '@components/_universal/Table/Table.styled';

interface GroupsListProps {
    data: GroupsTableItem[];
    isGroupExists?: boolean;
    groupsListLoading: boolean;
    tableProps: TableInstance<GroupsTableItem>;
}

export interface GroupToDelete {
    groupId: number;
    groupTitle: string;
}

export const GroupsList = ({
    data,
    isGroupExists,
    groupsListLoading,
    tableProps,
}: GroupsListProps) => {
    const [groupToDelete, setGroupToDelete] = useState<GroupToDelete | null>(
        null,
    );
    const history = useHistory();

    if (!isGroupExists && !groupsListLoading) return <EmptyGroupsList />;

    return (
        <StudentsListWrapper>
            <TableWrapper>
                {groupsListLoading ? (
                    <LoaderWrapper>
                        <Loader size={'medium'} center />
                    </LoaderWrapper>
                ) : (
                    <Table<GroupsTableItem> table={tableProps}>
                        {({
                            getTableProps,
                            headerGroups,
                            getTableBodyProps,
                            page,
                            prepareRow,
                        }) =>
                            page.length === 0 ? (
                                <EmptyDataSearch />
                            ) : (
                                <table {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr
                                                {...headerGroup.getHeaderGroupProps()}
                                            >
                                                {headerGroup.headers.map(
                                                    (column, index) => (
                                                        <th
                                                            {...column.getHeaderProps()}
                                                            key={`header ${index}`}
                                                            style={{
                                                                width:
                                                                    column.width,
                                                            }}
                                                        >
                                                            {column.render(
                                                                'Header',
                                                            )}
                                                        </th>
                                                    ),
                                                )}
                                                <th style={{ width: 80 }} />
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, index) => {
                                            prepareRow(row);
                                            return (
                                                <TableBodyRow
                                                    {...row.getRowProps()}
                                                    key={`row ${index}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        history.push(
                                                            paths.students_group_teacher(
                                                                row.original.id.toString(),
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    {row.cells.map(
                                                        (cell, index) => {
                                                            return (
                                                                <td
                                                                    {...cell.getCellProps()}
                                                                    key={`cell ${index}`}
                                                                >
                                                                    {cell.render(
                                                                        'Cell',
                                                                    )}
                                                                </td>
                                                            );
                                                        },
                                                    )}
                                                    <td
                                                        className={'td_icons'}
                                                        key={'td_icons'}
                                                    >
                                                        <TableIconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setGroupToDelete(
                                                                    {
                                                                        groupId:
                                                                            data[
                                                                                index
                                                                            ]
                                                                                .id,
                                                                        groupTitle:
                                                                            data[
                                                                                index
                                                                            ]
                                                                                .groupTitle,
                                                                    },
                                                                );
                                                            }}
                                                        >
                                                            <IconManager
                                                                cursor="pointer"
                                                                hoverFill={[
                                                                    'error',
                                                                ]}
                                                                name={
                                                                    'IconTrash'
                                                                }
                                                                size={24}
                                                            />
                                                        </TableIconButton>
                                                    </td>
                                                </TableBodyRow>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )
                        }
                    </Table>
                )}
            </TableWrapper>

            {/* MODALS */}
            {groupToDelete && (
                <DeleteGroupModal
                    groupId={groupToDelete.groupId}
                    groupTitle={groupToDelete.groupTitle}
                    onClose={() => setGroupToDelete(null)}
                />
            )}
        </StudentsListWrapper>
    );
};
