import styled from 'styled-components';
import { ColorType } from '@@types/CommonTypes';
import { DefaultText } from '@components/_universal/Typography.styled';
import IconManager from '@components/_universal/IconManager/IconManager';

export const BadgeWrapper = styled.div<{
    backgroundColor: ColorType;
    isImage: boolean;
}>`
    align-items: center;
    background-color: ${({ backgroundColor, theme }) =>
        theme.colors[backgroundColor]};
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    display: flex;
    padding: ${({ isImage }) => (isImage ? `5px 24px` : `6px 16px`)};
`;

export const BadgeIcon = styled(IconManager)`
    margin-right: 8px;
`;

export const BadgeText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.xxs}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: 1.4;
    margin: 0;
    text-transform: uppercase;
    white-space: nowrap;
`;
