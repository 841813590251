import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import ChoosePayment from '@domains/Auth/_components/ChoosePayment/ChoosePayment';
import {
    BigHeader,
    DefaultText,
    Title,
} from '@components/_universal/Typography.styled';

export const MobileSubscriptionPageWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    @media (max-width: ${breakpoints.md}px) {
        padding-top: 0;
    }
`;

export const MobileTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 auto 26px;
    max-width: 520px;
    padding-top: 16px;
    text-align: center;

    @media (max-width: ${breakpoints.md}px) {
        padding-top: 32px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
        line-height: 1.45;
        max-width: 310px;
        padding-top: 0;
    }
`;

export const MobileDescription = styled(BigHeader)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 auto 36px;
    max-width: 532px;
    position: relative;
    text-align: center;
    line-height: 28px;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.m}px;
    }
`;

export const StyledMobileDescription = styled(DefaultText)`
    background: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    color: ${({ theme }) => theme.colors.text_white};
    display: inline-block;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
    padding: 2px 8px;
    position: relative;
`;

export const MobileSubscriptionWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 -16px 0 0;
    position: relative;
    width: 720px;

    @media (max-width: ${breakpoints.md}px) {
        width: 540px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        display: block;
        margin: 0 0 190px 0;
    }

    @media (max-width: ${breakpoints.xs}px) {
        width: calc(100vw - 36px);
    }
`;

export const StyledChoosePayment = styled(ChoosePayment)`
    width: 360px;
`;
