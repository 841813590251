import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button, IconManager } from '@components/_universal';
import { DefaultText, Title } from '@components/_universal/Typography.styled';

export const SetPasswordPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;

export const PageContent = styled.div`
    flex: 1;
`;

export const PageFooter = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    @media (max-height: 768px) and (min-width: ${breakpoints.lg}px) {
        margin-top: 16px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        align-items: stretch;
        flex-direction: column;
    }
`;

export const SetUpYourPasswordIcon = styled(IconManager)`
    margin-top: -30px;

    @media (max-height: 768px) and (min-width: ${breakpoints.lg}px) {
        margin-top: -52px;
    }

    @media (max-width: ${breakpoints.lg}px) {
        margin-top: -52px;
    }

    @media (max-width: ${breakpoints.md}px) {
        margin-bottom: -22px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        font-size: 200px;
        margin-bottom: -32px;
    }
`;

export const OrnamentIcon = styled(IconManager)`
    position: absolute;
    right: -150px;
    top: -80px;
    z-index: 9;

    @media (max-width: ${breakpoints.lg}px) {
        right: -90px;
        top: -100px;
        font-size: 320px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        display: none;
    }
`;

export const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 16px;

    @media (max-width: ${breakpoints.lg}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
        margin-bottom: 8px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        font-size: ${({ theme }) => theme.fontSizes.xl}px;
    }
`;

export const Description = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.7;
    max-width: 585px;
    position: relative;

    @media (max-width: ${breakpoints.lg}px) {
        line-height: 1.6;
        max-width: 520px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        font-size: ${({ theme }) => theme.fontSizes.xs}px;
        line-height: 1.45;
    }
`;

export const FormWrapper = styled.div`
    align-items: flex-start;
    display: flex;

    @media (max-width: ${breakpoints.md}px) {
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const Tip = styled.div`
    background-color: ${({ theme }) => theme.colors.icons};
    border-radius: ${({ theme }) => theme.borderRadius.bubble}px;
    flex-shrink: 0;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.5;
    margin-left: 34px;
    margin-top: 4px;
    order: 1;
    padding: 34px 26px;
    position: relative;
    width: 358px;

    @media (max-width: ${breakpoints.lg}px) {
        width: 300px;
        margin-top: 0;
    }

    @media (max-width: ${breakpoints.md}px) {
        width: 100%;
        margin: 50px 0 16px;
    }
`;

export const Bulb = styled(IconManager)`
    left: 26px;
    position: absolute;
    top: -24px;
`;

export const StyledButton = styled(Button)`
    & + & {
        @media (max-width: ${breakpoints.xs}px) {
            margin-bottom: 16px;
            order: -1;
        }
    }
`;
