import { alphabeticalGroupSort } from '@utils/alphabeticalSortFunctions';
import { useForm } from 'react-hook-form';
import { useNotifyAboutLE } from '@state/mutations/notifyAboutLE';
import { useParams } from 'react-router';
import { useRecommendContent } from '@state/teachers/mutations/recommendContent';
import { useStudentsPage } from '@domains/Teacher/Students/StudentsPage/useStudentsPage';
import { validation } from '@components/SelectStudentsGroup/validation';
import { yupResolver } from '@hookform/resolvers/yup';

export const useContentRecommend = () => {
    const { control, errors, handleSubmit, register, reset, watch } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validation),
    });
    const { group } = watch();
    const param = useParams<{
        courseId: string;
        liveExperienceId: string;
        videoId: string;
    }>();
    const [recommendContent] = useRecommendContent();
    const [notifyAboutLE] = useNotifyAboutLE();

    const handleRecommend = (contentType: string) => {
        recommendContent({
            variables: {
                input: {
                    groupId: group.value,
                    contentId:
                        contentType === 'Course'
                            ? param.courseId
                            : contentType === 'Video'
                            ? param.videoId
                            : param.liveExperienceId,
                },
            },
        });
    };

    const handleNotifyAboutLE = () => {
        notifyAboutLE({
            variables: {
                input: {
                    groupId: group.value,
                    liveExperienceId: param.liveExperienceId,
                },
            },
        });
    };

    const { studentsList } = useStudentsPage();

    const groupsTitles = [
        ...new Set(
            studentsList
                ?.flatMap((student) => student.groups)
                .map((group) => group)
                .sort(alphabeticalGroupSort),
        ),
    ];

    const groups = [
        ...groupsTitles.map((group) => ({
            label: group?.title,
            value: group?.id,
        })),
    ];

    return {
        control,
        errors,
        groups,
        handleNotifyAboutLE,
        handleRecommend,
        handleSubmit,
        register,
        reset,
        watch,
    };
};
