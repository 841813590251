import { useSelector } from 'react-redux';
import Loader from '@components/_universal/Loader/Loader';
import SearchBar from '@components/_universal/Table/SearchBar/SearchBar';
import {
    HeaderPageWrapper,
    LoaderWrapper,
    PageTitle,
    StudentsPageWrapper,
} from './PageWrapper.styled';

export interface PageWrapperProps {
    children: JSX.Element;
    setSearch: (value: string) => void;
    studentsCountLoading: boolean;
    studentsUploadCount?: number;
    title?: string;
}

export const PageWrapper = ({
    children,
    setSearch,
    studentsCountLoading,
    studentsUploadCount,
    title,
}: PageWrapperProps) => {
    const { isAdminAsTeacher } = useSelector((state) => state.signup);

    return (
        <StudentsPageWrapper>
            <HeaderPageWrapper extended={isAdminAsTeacher}>
                <PageTitle>{title ?? 'Students'}</PageTitle>
                {!!studentsUploadCount && <SearchBar setFilter={setSearch} />}
            </HeaderPageWrapper>
            {studentsCountLoading ? (
                <LoaderWrapper>
                    <Loader size={'medium'} center />
                </LoaderWrapper>
            ) : (
                children
            )}
        </StudentsPageWrapper>
    );
};
