import React from 'react';
import { ArrayField } from 'react-hook-form';
import { IChild } from '@domains/Auth/SignupRegisterPage/SignupRegisterPage';
import { IconManager } from '@components/_universal';
import RegisterChildForm from '@domains/Auth/_forms/RegisterChildForm/RegisterChildForm';
import {
    ButtonsRow,
    InformationText,
    RegisterChildContentWrapper,
    RegisterChildInnerWrapper,
    RegisterChildWrapper,
    RemoveButton,
    StyledBigTitle,
    StyledButton,
    StyledNextButton,
    StyledTip,
} from './RegisterChild.styled';

interface IProps {
    child: Partial<ArrayField<IChild, 'id'>>;
    currentChild: number;
    emailsAlreadyTaken: string[];
    handleChangePage: (page: number) => void;
    isVisible: boolean;
    remove: (index: number) => void;
    removingProcess: boolean;
    total: number;
}

const getNumeral = (number: number) => {
    let numeral = 'th';
    switch (number) {
        case 2:
            numeral = 'nd';
            break;
        case 3:
            numeral = 'rd';
            break;
    }
    return ` ${number}${numeral}`;
};

const RegisterChild: React.FC<IProps> = ({
    child,
    currentChild,
    emailsAlreadyTaken,
    handleChangePage,
    isVisible,
    remove,
    removingProcess,
    total,
}) => {
    return (
        <RegisterChildWrapper isVisible={isVisible}>
            <RegisterChildContentWrapper>
                <RegisterChildInnerWrapper>
                    {!!currentChild && (
                        <RemoveButton onClick={() => remove(currentChild)}>
                            <IconManager name="Close" size={33} />
                        </RemoveButton>
                    )}
                    <InformationText>
                        {`child ${
                            !!currentChild ? currentChild + 1 : ''
                        } personal information`}
                    </InformationText>
                    <StyledBigTitle>
                        Register your
                        {currentChild > 0 && getNumeral(currentChild + 1)} child
                    </StyledBigTitle>
                    {currentChild === 0 && (
                        <StyledTip backgroundColor="course">
                            Now it's time to register your child! We need your
                            child email address so they can log in to
                            personalised account. Once your child has signed up,
                            they will receive an email to log in, set up an
                            account, and sign up to the first experience!
                        </StyledTip>
                    )}
                    <RegisterChildForm
                        child={child}
                        currentChild={currentChild}
                        emailsAlreadyTaken={emailsAlreadyTaken}
                    />
                    <ButtonsRow>
                        {currentChild > 0 && (
                            <StyledButton
                                buttonType="secondary"
                                onPress={() =>
                                    handleChangePage(currentChild - 1)
                                }
                                size="small"
                            >
                                previous child
                            </StyledButton>
                        )}
                        {total > 1 &&
                            currentChild < total - 1 &&
                            !removingProcess && (
                                <StyledNextButton
                                    buttonType="secondary"
                                    onPress={() =>
                                        handleChangePage(currentChild + 1)
                                    }
                                    size="small"
                                >
                                    next child
                                </StyledNextButton>
                            )}
                    </ButtonsRow>
                </RegisterChildInnerWrapper>
            </RegisterChildContentWrapper>
        </RegisterChildWrapper>
    );
};

export default RegisterChild;
