import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { Button, IconManager } from '@components/_universal';

export const SavedWrapper = styled.div`
    margin-right: 120px;
    padding: 80px 100px 120px 100px;
`;

export const TitleWrapper = styled.div<{ withMosaic?: boolean }>`
    align-items: center;
    display: flex;
    margin-bottom: ${({ withMosaic }) => (withMosaic ? 40 : 24)}px;
`;

export const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
`;

export const Description = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 32px;
    max-width: 544px;
`;

export const StyledButton = styled(Button)``;

export const StyledSeeAllButton = styled(Button)`
    margin-left: auto;
`;

export const HeartIcon = styled(IconManager)`
    margin-left: 24px;
`;

export const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 80px 0;
`;
