import { useAuthMeQuery } from '@state/auth/queries';
import { useHistory } from 'react-router-dom';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import paths from '@shared/paths';
import React from 'react';
import {
    Description,
    Error404PageWrapper,
    StyledButton,
    StyledIconManager,
    StyledTitle,
} from './Error404Page.styled';

interface IProps {}

const Error404Page: React.FC<IProps> = () => {
    const { data } = useAuthMeQuery();
    const history = useHistory();
    const isAdminAsTeacher = LocalStorageManager.getValue('adminSessionToken');

    const userType = {
        [USER_TYPE.ADMIN]: isAdminAsTeacher ? paths.dashboard_teacher : '',
        [USER_TYPE.CHILD]: paths.dashboard,
        [USER_TYPE.MENTOR]: '',
        [USER_TYPE.PARENT]: paths.dashboard_parent(),
        [USER_TYPE.STUDENT]: '',
        [USER_TYPE.TEACHER]: paths.dashboard_teacher,
    };

    return (
        <Error404PageWrapper>
            <StyledIconManager name={'Error404'} size={618} sizeY={421} />
            <StyledTitle>Oh no, something went wrong!</StyledTitle>
            <Description>
                We cannot find the page you are looking for. But do not worry,
                with the help of this button below you will easily find your way
                back.
            </Description>
            {data?.me?.type ? (
                <StyledButton
                    onPress={() => history.push(userType[data.me.type])}
                >
                    Go back to dashboard
                </StyledButton>
            ) : (
                <StyledButton onPress={() => history.push(paths.login)}>
                    Log in
                </StyledButton>
            )}
        </Error404PageWrapper>
    );
};

export default Error404Page;
