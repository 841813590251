import React from 'react';
import {
    AccountLayoutWrapper,
    Content,
    FooterBar,
    HeaderBar,
    NavigationBar,
} from './AccountLayout.styled';

interface IProps {}

const AccountLayout: React.FC<IProps> = ({ children }) => {
    return (
        <AccountLayoutWrapper>
            <HeaderBar trialIcon />
            <NavigationBar />
            <Content>{children}</Content>
            <FooterBar isBackpack isLogo />
        </AccountLayoutWrapper>
    );
};

export default AccountLayout;
