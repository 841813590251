import React from 'react';
import Pagination from '@domains/Auth/Onboarding/_components/Pagination/Pagination';
import paths from '@shared/paths';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Nullable } from '@@types/CommonTypes';
import {
    AvatarList,
    ChooseAvatarDescription,
    ChooseAvatarOrnamentIcon,
    ChooseAvatarPageContent,
    ChooseAvatarPageFooter,
    ChooseAvatarTitle,
    ChooseYourAvatarIcon,
    DesktopChooseAvatarPageWrapper,
    StyledAvatar,
    StyledContinueButton,
} from './DesktopChooseAvatarPage.styled';

export interface IProps {
    avatar: Nullable<number>;
    control: UseFormMethods['control'];
    selectAvatar: (avatar: number) => void;
}

const DesktopChooseAvatarPage: React.FC<IProps> = ({
    avatar,
    control,
    selectAvatar,
}) => {
    const history = useHistory();

    return (
        <DesktopChooseAvatarPageWrapper>
            <ChooseAvatarPageContent>
                <ChooseAvatarTitle>
                    Welcome to your 8billionideas account
                </ChooseAvatarTitle>
                <ChooseAvatarDescription>
                    It's great to meet you! Choose your avatar to personalise
                    your account.
                </ChooseAvatarDescription>
                <ChooseYourAvatarIcon
                    name={'ChooseYourAvatarText'}
                    size={276}
                    sizeY={162}
                />
                <ChooseAvatarOrnamentIcon
                    name={'ChooseAvatarOrnaments'}
                    size={440}
                />
                <Controller
                    control={control}
                    name={'avatar'}
                    render={(props) => (
                        <AvatarList>
                            {[...Array(8).keys()].map((_, index) => (
                                <StyledAvatar
                                    avatar={index}
                                    iconSelectedSize={113}
                                    iconSize={75}
                                    isActive={index === props.value}
                                    key={index}
                                    onClick={() => props.onChange(index ?? 0)}
                                />
                            ))}
                        </AvatarList>
                    )}
                />
            </ChooseAvatarPageContent>
            <ChooseAvatarPageFooter>
                <Pagination>Step 1</Pagination>
                <StyledContinueButton
                    buttonType={'primary'}
                    disabled={typeof avatar !== 'number'}
                    onPress={() => history.push(paths.set_password)}
                    size={'small'}
                >
                    Continue
                </StyledContinueButton>
            </ChooseAvatarPageFooter>
        </DesktopChooseAvatarPageWrapper>
    );
};

export default DesktopChooseAvatarPage;
