import styled, { css } from 'styled-components';
import { ColorType } from '@@types/CommonTypes';
import { DefaultText } from '@components/_universal/Typography.styled';

export const StudentsActivityCardWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        border-radius: ${borderRadius.card}px;
        border: 1px solid ${colors.stroke};
        display: flex;
        flex-direction: column;
        overflow: hidden;
    `,
);

export const CardContent = styled.div<{ isSmaller: boolean }>(
    ({ isSmaller }) => css`
        flex-grow: 1;
        padding: ${isSmaller ? 32 : 40}px 40px 0;
    `,
);

export const FrequencyText = styled(DefaultText)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.secondary};
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.3;
        margin-bottom: 4px;
        text-align: center;
        text-transform: uppercase;
    `,
);

export const StudentsNumber = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.l}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.65;
        margin-bottom: 4px;
        text-align: center;
    `,
);

export const ContentValuesWrapper = styled.div`
    margin-top: 16px;
`;

export const ContentValue = styled.div(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        align-items: center;
        border-bottom: 1px solid ${colors.stroke};
        display: flex;
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        padding: 8px 0;

        &:last-of-type {
            border: none;
        }
    `,
);

export const ContentValueIcon = styled.div<{ color: ColorType }>(
    ({ color, theme: { borderRadius, colors } }) => css`
        align-items: center;
        background-color: ${colors[color]};
        border-radius: ${borderRadius.circle}%;
        display: flex;
        flex-shrink: 0;
        height: 32px;
        justify-content: center;
        margin-right: 8px;
        width: 32px;
    `,
);

export const CardFooter = styled.div(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        align-items: center;
        background-color: ${colors.background_tertiary};
        display: flex;
        flex-shrink: 0;
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        height: 46px;
        justify-content: center;
        text-align: center;
        margin-top: 12px;
    `,
);
