import React from 'react';
import useHubspot from '@utils/hooks/useHubspot';

export interface IProps {
    className?: string;
}

const ChatWithSupport: React.FC<IProps> = ({ className }) => {
    useHubspot();

    return <></>;
};

export default ChatWithSupport;
