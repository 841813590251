import { gql, useQuery } from '@apollo/client';
import { SearchPaginationInput } from '@@types/graphql/GraphqlGlobalTypes';
import {
    SimilarContentsQuery,
    SimilarContentsQueryVariables,
} from '@@types/graphql/SimilarContentsQuery';

const SIMILAR_CONTENTS_QUERY = gql`
    query SimilarContentsQuery(
        $contentId: ID!
        $paginationInput: SearchPaginationInput
    ) {
        getSimilarContents(
            contentId: $contentId
            paginationInput: $paginationInput
        ) {
            __typename
            ... on Course {
                _id
                type
                name
                featured
                thumbnail
            }
            ... on StandaloneVideo {
                _id
                type
                name
                featured
                thumbnail
            }
            ... on LiveExperience {
                _id
                endDate
                featured
                maxNoParticipants
                name
                noOfSignups
                schoolsWithAccess {
                    id
                }
                showToChildren
                showToStudents
                startDate
                thumbnail
                type
            }
        }
    }
`;

export default (contentId: string, pagination?: SearchPaginationInput) =>
    useQuery<SimilarContentsQuery, SimilarContentsQueryVariables>(
        SIMILAR_CONTENTS_QUERY,
        {
            variables: {
                paginationInput: pagination,
                contentId: contentId,
            },
            onCompleted: () => {},
            onError: () => {},
        },
    );
