import styled from 'styled-components';
import { breakpoints } from '@shared/constants';

export const CloseModalButtonWrapper = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 2px;
    position: absolute;
    right: 36px;
    top: 36px;

    @media (max-width: ${breakpoints.sm}px) {
        right: 22px;
        top: 22px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        right: 18px;
        top: 12px;
    }
`;
