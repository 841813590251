import React from 'react';
import {
    Content,
    LoadingScooterIcon,
    LoadingText,
    LoadingTitle,
    MobileSignupPaymentProcessingPageWrapper,
    StyledAnimatedDots,
} from './MobileSignupPaymentProcessingPage.styled';

export interface IProps {}

const MobileSignupPaymentProcessingPage: React.FC<IProps> = () => {
    return (
        <MobileSignupPaymentProcessingPageWrapper>
            <Content>
                <LoadingTitle>
                    Payment processing
                    <StyledAnimatedDots />
                </LoadingTitle>

                <LoadingScooterIcon
                    name={'LoadingScooter'}
                    size={356}
                    sizeY={170}
                />
                <LoadingText>
                    Please do not close this window. After processing the
                    payment, you will be automatically redirected to the next
                    step.
                </LoadingText>
            </Content>
        </MobileSignupPaymentProcessingPageWrapper>
    );
};

export default MobileSignupPaymentProcessingPage;
