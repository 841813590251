import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';
import Badge from '@components/_universal/Badge/Badge';
import HomeworkButton from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/HomeworkButton/HomeworkButton';
import TextButton from '@components/_universal/TextButton/TextButton';

export const ActivityCardWrapper = styled.div(
    ({ theme: { borderRadius, colors, silver_shadow } }) => css`
        border-radius: ${borderRadius.card}px;
        border: 1px solid ${colors.background_tertiary};
        box-shadow: ${silver_shadow};
        margin-bottom: 34px;
        padding: 32px;
        position: relative;
    `,
);

export const CardHeader = styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
`;

export const CardHeaderTexts = styled.div`
    max-width: 390px;
`;

export const ContentName = styled(DefaultText)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        line-height: 1.5;
        margin-bottom: 8px;
    `,
);

export const SeeMoreButton = styled(TextButton)(
    ({ theme: { colors } }) => css`
        color: ${colors.text_assistive};
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 0;
        width: 90px;
    `,
);

export const FeedbackWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background-color: ${colors.background_quaternary};
        border-radius: ${borderRadius.card}px;
        display: flex;
        margin-top: 32px;
        padding: 24px 24px 24px 32px;
    `,
);

export const MentorData = styled.div`
    flex-shrink: 0;
    margin-right: 32px;
    min-width: 192px;
`;

export const FeedbackInnerWrapper = styled.div`
    flex-grow: 1;
`;

export const MentorName = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.7;
        margin-bottom: 4px;
    `,
);

export const MentorSecondaryText = styled(DefaultText)(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.text_secondary};
        font-size: ${fontSizes.xs}px;
        line-height: 1.7;
        margin: 0;
    `,
);

export const FeedbackText = styled(DefaultText)(
    ({ theme: { fontSizes } }) => css`
        font-size: ${fontSizes.s}px;
        margin-bottom: 8px;
        padding-right: 8px;
    `,
);

export const FeedbackFile = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
`;

export const VideoFeedbackText = styled(IconManager)`
    pointer-events: none;
    position: absolute;
    right: calc(100% + 14px);
    top: 16px;
`;

export const StyledBadge = styled(Badge)`
    left: 32px;
    padding: 6px 24px;
    position: absolute;
    top: -12px;
`;

export const StyledHomeworkButton = styled(HomeworkButton)`
    margin: 4px 24px 0;
    padding: 12px 16px;
`;

export const DateText = styled(DefaultText)(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.text_secondary};
        font-size: ${fontSizes.xs}px;
        line-height: 1.65;
        margin: 2px 24px 0 0;
    `,
);
