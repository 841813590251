import React from 'react';
import { FieldError, UseFormMethods } from 'react-hook-form';
import {
    CheckboxError,
    CheckboxInput,
    CheckboxLabel,
    CheckboxLabelText,
    CheckboxWrapper,
} from '@components/_form-elements/Checkbox/Checkbox.styled';

interface IProps {
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    errors?: FieldError;
    marginBottom?: number;
    name: string;
    onChange?: (value: boolean) => void;
    register?: UseFormMethods['register'];
    value?: boolean;
}

const Checkbox: React.FC<IProps> = ({
    children,
    className,
    disabled,
    errors,
    marginBottom,
    name,
    onChange,
    register,
    value,
}) => {
    return (
        <CheckboxWrapper className={className} marginBottom={marginBottom}>
            <CheckboxInput
                checked={value}
                disabled={disabled}
                id={name}
                name={name}
                onChange={(e) => onChange?.(e.target.checked)}
                ref={register}
                type={'checkbox'}
            />
            <CheckboxLabel error={!!errors} htmlFor={name} />
            {children && (
                <CheckboxLabelText htmlFor={name}>{children}</CheckboxLabelText>
            )}
            {errors && <CheckboxError>{errors.message}</CheckboxError>}
        </CheckboxWrapper>
    );
};

export default Checkbox;
