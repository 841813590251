import React from 'react';
import { USER_STATUS } from '@@types/graphql/GraphqlGlobalTypes';
import { capitalize } from '@utils/helpers';
import { setChildId } from '@state/_redux/childManagement/actions';
import { useChildsListQuery } from '@state/childManagement/queries';
import { useDispatch } from 'react-redux';
import { useResendEmailMutation } from '@state/settings/mutations';
import {
    Action,
    ActionsColumn,
    ActionsDecorator,
    AvatarIcon,
    ListItem,
    NameColumn,
    StatusColumn,
    TextLabel,
    TextValue,
    UsersListWrapper,
} from './UsersList.styled';

interface IProps {
    onCancelDeactivationUser: (id: number) => void;
    onDeactivateUser: () => void;
    onEditUser: () => void;
}

const getStatus = (
    status: USER_STATUS,
    deactivateAtCurrentPeriodEnd: boolean,
) => {
    if (
        (status === USER_STATUS.ACTIVE && deactivateAtCurrentPeriodEnd) ||
        status === USER_STATUS.DEACTIVATED
    )
        return 'Inactive';

    if (status === USER_STATUS.PENDING) return 'Pending';

    if (status === USER_STATUS.ACTIVE) return 'Active';
};

const UsersList: React.FC<IProps> = ({
    onCancelDeactivationUser,
    onDeactivateUser,
    onEditUser,
}) => {
    const { data: childListData } = useChildsListQuery();
    const [resendEmail] = useResendEmailMutation();
    const dispatch = useDispatch();

    return (
        <UsersListWrapper>
            {childListData?.getChildsForParent.map(
                ({
                    avatar,
                    id,
                    name,
                    surname,
                    email,
                    status,
                    deactivateAtCurrentPeriodEnd,
                }) => {
                    const userStatus = getStatus(
                        status,
                        deactivateAtCurrentPeriodEnd,
                    );

                    return (
                        <ListItem key={id}>
                            <AvatarIcon avatar={avatar} iconSize={40} />
                            <NameColumn>
                                <TextLabel>
                                    {name} {surname}
                                </TextLabel>
                                <TextValue>{email}</TextValue>
                            </NameColumn>
                            <StatusColumn>
                                <TextValue>{userStatus}</TextValue>
                            </StatusColumn>
                            <ActionsColumn>
                                <>
                                    <Action
                                        onClick={() => {
                                            dispatch(setChildId(id));
                                            onEditUser();
                                        }}
                                    >
                                        Edit
                                    </Action>
                                    <ActionsDecorator />
                                    {userStatus === 'Pending' && (
                                        <Action
                                            onClick={() =>
                                                resendEmail({
                                                    variables: { email: email },
                                                })
                                            }
                                        >
                                            Resend
                                        </Action>
                                    )}
                                    {userStatus === 'Inactive' && (
                                        <Action
                                            onClick={() => {
                                                dispatch(setChildId(id));
                                                onCancelDeactivationUser(id);
                                            }}
                                        >
                                            Activate
                                        </Action>
                                    )}
                                    {userStatus === 'Active' && (
                                        <Action
                                            onClick={() => {
                                                dispatch(setChildId(id));
                                                onDeactivateUser();
                                            }}
                                        >
                                            Deactivate
                                        </Action>
                                    )}
                                </>
                            </ActionsColumn>
                        </ListItem>
                    );
                },
            )}
        </UsersListWrapper>
    );
};

export default UsersList;
