import { useEffect, useMemo, useState } from 'react';
import { capitalize } from '@utils/helpers';
import { CreateGroupModal } from '@domains/Teacher/Groups/_modals/CreateGroupModal/CreateGroupModal';
import { IndeterminateCheckbox } from './_components/IndeterminateCheckbox/IndeterminateCheckbox';
import { PageWrapper } from '@domains/Teacher/Students/_components/PageWrapper/PageWrapper';
import { StudentsList } from '@domains/Teacher/Students/StudentsPage/_components/StudentsList/StudentsList';
import { TableOptions } from '@domains/Teacher/Students/StudentsPage/_components/TableOptions/TableOptions';
import { UploadBox } from '@components/_universal/UploadBox/UploadBox';
import { useHistory } from 'react-router';
import { USER_STATUS } from '@@types/graphql/GraphqlGlobalTypes';
import { useStudentsPage } from './useStudentsPage';
import DeleteStudentsModal from '@domains/Teacher/Students/_modals/DeleteStudentsModal/DeleteStudentsModal';
import EditStudentsPropertiesModal from '@domains/Teacher/Students/_modals/EditStudentsPropertiesModal/EditStudentsPropertiesModal';
import NewChildModal from '@domains/Teacher/Students/_modals/NewStudentModal/NewStudentModal';
import paths from '@shared/paths';
import {
    Column,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useTable,
} from 'react-table';
import {
    GroupName,
    IconClose,
    Info,
    UploadWrapper,
} from './StudentsPage.styled';

interface Group {
    id: number;
    title: string;
}

export interface StudentsTableItem {
    class?: string;
    email: string;
    group?: Group[];
    id: number;
    name: string;
    status: string;
    surname: string;
    year?: number;
}

const PAGE_LIMIT = 25;

export const StudentsPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [showStudentModal, setShowStudentModal] = useState(false);
    const [showDeleteStudentModal, setShowDeleteStudentModal] = useState(false);
    const [
        showEditStudentsPropertiesModal,
        setShowEditStudentsPropertiesModal,
    ] = useState(false);

    const {
        filters,
        refetch,
        search,
        setFilters,
        setPaginationState,
        setSearch,
        studentsCountLoading,
        studentsFilteredCount,
        studentsList,
        studentsListLoading,
        studentsUploadCount,
    } = useStudentsPage();

    const numberOfFilteredPages =
        studentsFilteredCount && Math.ceil(studentsFilteredCount / PAGE_LIMIT);

    const columns = useMemo<Column<StudentsTableItem>[]>(
        () => [
            {
                id: 'select',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <IndeterminateCheckbox
                        name={'indeterminated'}
                        {...getToggleAllRowsSelectedProps()}
                    />
                ),
                Cell: ({ row }: any) => (
                    <div onClick={(e) => e?.stopPropagation()}>
                        <IndeterminateCheckbox
                            name={row.id}
                            {...row.getToggleRowSelectedProps()}
                        />
                    </div>
                ),
                width: 50,
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Surname',
                accessor: 'surname',
            },
            {
                Header: 'E-mail',
                accessor: 'email',
                width: 300,
            },
            {
                Header: 'Class',
                accessor: 'class',
                width: 100,
            },
            {
                Header: 'Year',
                accessor: 'year',
                width: 100,
            },
            {
                Header: 'Group',
                accessor: 'group',
                width: 300,
                Cell: ({ cell }: any) => {
                    if (!cell.value.length) {
                        return <Info>Not assigned to a group</Info>;
                    }
                    return (
                        <>
                            {cell.value.map((group: Group) => {
                                return (
                                    <GroupName
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            history.push(
                                                paths.students_group_teacher(
                                                    group.id.toString(),
                                                ),
                                            );
                                        }}
                                    >
                                        {group.title}
                                    </GroupName>
                                );
                            })}
                        </>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell }: any) => {
                    if (cell.value === USER_STATUS.BANNED) {
                        return (
                            <span className={'banned'}>
                                {capitalize(cell.value)}
                            </span>
                        );
                    }
                    if (cell.value === USER_STATUS.PENDING) {
                        return (
                            <span className={'pending'}>
                                {capitalize(cell.value)}
                            </span>
                        );
                    }
                    return capitalize(cell.value);
                },
            },
        ],
        [],
    );

    const data = useMemo<StudentsTableItem[]>(
        () =>
            studentsList?.map((student) => ({
                class: student.class?.className,
                email: student.email,
                group: student.groups?.map((group) => group),
                id: student.id,
                name: student.name,
                status: student.status,
                surname: student.surname,
                year: student.class?.year,
            })) ?? [],
        [studentsList],
    );

    const tableProps = useTable<StudentsTableItem>(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: PAGE_LIMIT,
            },
            manualPagination: true,
            pageCount: numberOfFilteredPages,
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
    );

    const selectedStudentsIds = tableProps.selectedFlatRows.map(
        (item) => item?.original?.id,
    );
    const showSelectButtons = selectedStudentsIds?.length >= 1;

    const getFilters = (
        className?: string,
        groupId?: number,
        year?: string,
    ) => {
        setFilters({
            className,
            groupId,
            year,
        });
    };

    const history = useHistory();

    useEffect(() => {
        setPaginationState({
            pageIndex: tableProps.state.pageIndex,
            pageSize: tableProps.state.pageSize,
        });
    }, [tableProps.state.pageIndex]);

    useEffect(() => {
        tableProps.gotoPage(0);
    }, [search, filters]);

    return (
        <PageWrapper
            setSearch={setSearch}
            studentsUploadCount={studentsUploadCount}
            studentsCountLoading={studentsCountLoading}
        >
            <>
                {!!studentsUploadCount && (
                    <TableOptions
                        getFilters={getFilters}
                        isGroupView={false}
                        firstButtonProps={{
                            onClick: setIsOpen,
                            title: 'Add studentS',
                        }}
                        secondButtonProps={{
                            onClick: setShowGroupModal,
                            title: 'create group',
                        }}
                        deleteButtonProps={{
                            onClick: setShowDeleteStudentModal,
                            title: 'delete',
                        }}
                        editButtonProps={{
                            onClick: setShowEditStudentsPropertiesModal,
                            title: 'edit properties',
                        }}
                        removeStudents={() => {}}
                        showGroupFilter
                        showSelectButton={showSelectButtons}
                        title="Students list"
                    />
                )}
                {isOpen && (
                    <UploadWrapper>
                        <UploadBox
                            onClick={() => setShowStudentModal(true)}
                            variant="students_page"
                        />
                        <IconClose
                            name={'Close'}
                            onClick={() => setIsOpen(false)}
                            size={24}
                        />
                    </UploadWrapper>
                )}
                {showStudentModal && (
                    <NewChildModal setIsModalOpen={setShowStudentModal} />
                )}
                {showGroupModal && (
                    <CreateGroupModal setIsModalOpen={setShowGroupModal} />
                )}
                {showDeleteStudentModal && (
                    <DeleteStudentsModal
                        onClose={() => setShowDeleteStudentModal(false)}
                        refetch={refetch}
                        studentsIds={selectedStudentsIds}
                    />
                )}
                {showEditStudentsPropertiesModal && (
                    <EditStudentsPropertiesModal
                        onClose={() =>
                            setShowEditStudentsPropertiesModal(false)
                        }
                        refetch={refetch}
                        studentsIds={selectedStudentsIds}
                    />
                )}
                <StudentsList
                    data={data}
                    isExistStudent={!!studentsUploadCount}
                    isGroupView={false}
                    refetch={refetch}
                    studentsListLoading={studentsListLoading}
                    tableProps={tableProps}
                />
            </>
        </PageWrapper>
    );
};
