import client from '@boot/apolloClient';
import { CHILDS_LIST_QUERY } from '@state/childManagement/queries/useChildsListQuery';
import { FragmentChildsListQuery } from '@@types/graphql/FragmentChildsListQuery';
import { ToastTypes } from '@@types/CommonTypes';
import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import {
    DeleteChildAccountMutation,
    DeleteChildAccountMutationVariables,
} from '@@types/graphql/DeleteChildAccountMutation';

const DELETE_CHILD_ACCOUNT_MUTATION = gql`
    mutation DeleteChildAccountMutation($childId: Int!) {
        deactivateChildAccount(childId: $childId)
    }
`;

export default (onCompleted?: () => void) =>
    useMutation<
        DeleteChildAccountMutation,
        DeleteChildAccountMutationVariables
    >(DELETE_CHILD_ACCOUNT_MUTATION, {
        onCompleted: (payload) => {
            onCompleted?.();
            client.writeFragment<FragmentChildsListQuery>({
                id: `User:${payload.deactivateChildAccount}`,
                fragment: gql`
                    fragment FragmentChildsListQuery on User {
                        deactivateAtCurrentPeriodEnd
                    }
                `,
                data: {
                    deactivateAtCurrentPeriodEnd: true,
                },
            });
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: CHILDS_LIST_QUERY,
            },
        ],
    });
