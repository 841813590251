import { TabButton } from './Tab.styled';

interface TabProps {
    active?: boolean;
    children: React.ReactNode;
    className?: string;
    onClick: (value: string) => void;
    value: string;
}

export const Tab = ({
    active,
    children,
    className,
    onClick,
    value,
}: TabProps) => {
    return (
        <TabButton
            className={className}
            active={active}
            onClick={() => onClick(value)}
        >
            {children}
        </TabButton>
    );
};
