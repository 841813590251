import React, { useRef } from 'react';
import { ModalContent } from '@components/_modals/GlobalModals.styled';
import { StyledModalTitle } from './EditStudentModal.styled';
import { useOnClickOutside } from '@utils/hooks';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import Modal from '@components/_universal/Modal/Modal';
import StudentForm, {
    IStudentForm,
} from '@domains/Teacher/Students/_forms/StudentForm/StudentForm';

export interface IProps {
    defaultData: IStudentForm;
    defaultSchoolClasses?: {
        className: string;
        year: string;
    }[];
    onClose: () => void;
    onSubmit: (data: IStudentForm) => void;
}

const EditStudentModal: React.FC<IProps> = ({
    defaultData,
    defaultSchoolClasses,
    onClose,
    onSubmit,
}) => {
    const ref = useRef(null);
    useOnClickOutside(ref, onClose);

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={onClose} />
                <StyledModalTitle>Edit student details</StyledModalTitle>

                <StudentForm
                    defaultData={defaultData}
                    defaultSchoolClasses={defaultSchoolClasses}
                    onClose={onClose}
                    onSubmit={onSubmit}
                />
            </ModalContent>
        </Modal>
    );
};

export default EditStudentModal;
