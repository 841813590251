import { gql, useQuery } from '@apollo/client';
import {
    GetStandaloneVideoQuery,
    GetStandaloneVideoQueryVariables,
} from '@@types/graphql/GetStandaloneVideoQuery';

const VIDEO_DETAILS = gql`
    query VideoDetails($id: ID!) {
        getStandaloneVideo(id: $id) {
            _id
            description
            name
            thumbnail
            video {
                _id
                duration
            }
        }
    }
`;

export const useVideoDetails = (id: string) => {
    return useQuery<GetStandaloneVideoQuery, GetStandaloneVideoQueryVariables>(
        VIDEO_DETAILS,
        {
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        },
    );
};
