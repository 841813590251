import styled, { css, keyframes } from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';

const fadeIn = keyframes`
    from {
        opacity: 0
    }
    to{
        opacity: 1
    }
`;

export const TypeformTipWrapper = styled.div`
    bottom: 8px;
    cursor: auto;
    left: calc(100% - 33px);
    padding-left: 24px;
    position: absolute;
    width: 476px;
    z-index: 99;
`;

export const TipContent = styled.div(
    ({ theme: { colors, borderRadius } }) => css`
        animation: ${fadeIn} 0.2s linear forwards;
        background-color: ${colors.course};
        border-radius: ${borderRadius.bubble}px;
        padding: 32px;
        position: relative;

        &::after {
            border-bottom: 10px solid transparent;
            border-right: 14px solid ${colors.course};
            border-top: 10px solid transparent;
            content: '';
            height: 0;
            left: -14px;
            position: absolute;
            bottom: 34px;
            width: 0;
        }
    `,
);

export const TipText = styled(DefaultText)(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.text_white};
        font-size: ${fontSizes.s}px;
        line-height: 1.5;
    `,
);

export const BoldTipText = styled.span(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
    `,
);

export const StyledButton = styled(Button)(
    ({ theme: { blue_shadow_light } }) => css`
        margin: 0 auto;
        min-width: 190px;

        &:hover {
            box-shadow: ${blue_shadow_light};
        }
    `,
);

export const MeditatingLadyIcon = styled(IconManager)`
    bottom: -18px;
    position: absolute;
    right: -18px;
`;
