import styled, { css } from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import {
    BigHeader,
    DefaultText,
    LinkText,
    Title,
} from '@components/_universal/Typography.styled';

export const DashboardTeacherWrapper = styled.div(
    ({ theme: { borderRadius } }) => css`
        border-radius: ${borderRadius.card}px;
        display: flex;
        flex: 1;
        flex-direction: column;
    `,
);

export const DashboardTeacherInnerWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const DashboardTitle = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 8px;
    `,
);

export const DashboardSubtitle = styled(BigHeader)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights};
        margin-bottom: 50px;
    `,
);

// Dashboard list

export const EnumerationsListWrapper = styled.div`
    counter-reset: listCounter;
    flex: 1;
    margin-right: 44px;
`;

export const EnumerationsItemWrapper = styled.div(
    ({ theme: { colors, borderRadius, silver_shadow } }) => css`
        background: ${colors.background_secondary};
        border: 1px solid ${colors.background_tertiary};
        border-radius: ${borderRadius.card}px;
        box-shadow: ${silver_shadow};
        counter-increment: listCounter;
        padding: 40px 96px 48px 74px;

        & + & {
            margin-top: 24px;
        }
    `,
);

export const EnumerationsTitle = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        align-items: flex-start;
        display: flex;
        font-size: ${fontSizes.m}px;
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 4px;
        position: relative;

        &:before {
            content: counter(listCounter) '.';
            font-size: ${fontSizes.l}px;
            font-weight: ${fontWeights.semiBold};
            left: -32px;
            top: -2px;
            position: absolute;
        }
    `,
);

export const EnumerationsDescription = styled(DefaultText)(
    ({ theme: { fontSizes } }) => css`
        font-size: ${fontSizes.s}px;
        max-width: 650px;
    `,
);

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 18px;
`;

export const StyledButton = styled(Button)`
    margin-right: 16px;
`;

export const StyledLinkText = styled(LinkText)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.primary};
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.bold};
        line-height: 1.65;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            text-underline-position: under;
        }
    `,
);

// Ornament

export const DashboardTeacherAdditionalWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const DashboardTeacherOrnament = styled(IconManager)`
    justify-content: center;
`;
