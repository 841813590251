import { gql, useMutation } from '@apollo/client';
import {
    ChangeEmailMutation,
    ChangeEmailMutationVariables,
} from '@@types/graphql/ChangeEmailMutation';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

const CHANGE_EMAIL = gql`
    mutation ChangeEmailMutation($input: ChangeEmailInput!) {
        changeEmail(input: $input) {
            id
            email
        }
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(
        CHANGE_EMAIL,
        {
            onCompleted: () => {
                notify('Verify new email address')[ToastTypes.SUCCESS]();
                onCompleted?.();
            },
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
        },
    );
};
