import styled, { keyframes } from 'styled-components';

const comeIn = keyframes`
    0% {
        transform: scale(0);
    }
    75% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
`;

export const MosaicContentWrapper = styled.div`
    .xmasonry .xblock {
        animation: ${comeIn} ease 0.8s;
        animation-iteration-count: 1;
        transition: left 0.8s ease, top 0.8s ease;
    }

    /* NOTE: Negative margins to remove the space around that created by the mosaic items */
    margin: -16px;
`;

export const MosaicContentItem = styled.div`
    display: block;
    margin: 16px;
    text-decoration: none;
`;
