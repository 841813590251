import React, { useRef } from 'react';
import { ButtonsContainer, StyledButton } from './ModalConfirmation.styled';
import { ButtonTypes } from '@@types/CommonTypes';
import { useOnClickOutside } from '@utils/hooks';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import Modal from '@components/_universal/Modal/Modal';
import {
    ModalContent,
    ModalText,
    ModalTitle,
} from '@components/_modals/GlobalModals.styled';

interface IButton {
    disabled?: boolean;
    onClick: () => void;
    text: string;
    type: ButtonTypes;
}

export interface IProps {
    className?: string;
    closeOutside?: boolean;
    firstButton?: IButton;
    onClose?: () => void;
    secondButton?: IButton;
    title: string;
}

const ModalConfirmation: React.FC<IProps> = ({
    children,
    className,
    closeOutside,
    firstButton,
    onClose,
    secondButton,
    title,
}) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => closeOutside && onClose?.());

    return (
        <Modal>
            <ModalContent {...{ className, ref }}>
                {onClose && <CloseModalButton {...{ onClose }} />}
                <ModalTitle>{title}</ModalTitle>
                <ModalText>{children}</ModalText>
                <ButtonsContainer>
                    {[firstButton, secondButton].map(
                        (button) =>
                            button && (
                                <StyledButton
                                    buttonType={button.type}
                                    onPress={button.onClick}
                                    disabled={button?.disabled}
                                >
                                    {button.text}
                                </StyledButton>
                            ),
                    )}
                </ButtonsContainer>
            </ModalContent>
        </Modal>
    );
};

export default ModalConfirmation;
