import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';

export const LoginFormBreakWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export const Line = styled.div`
    background-color: ${({ theme }) => theme.colors.icons};
    flex-grow: 1;
    height: 1px;
`;

export const Text = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: 1.35;
    margin-bottom: 0;
    text-align: center;
    width: 80px;
`;
