import React from 'react';
import Modal from '@components/_universal/Modal/Modal';
import {
    LoadingScooterIcon,
    LoadingText,
    LoadingTitle,
    StudentsUploadModalWrapper,
    StyledAnimatedDots,
} from './StudentsUploadModal.styled';

export interface IProps {}

const StudentsUploadModal: React.FC<IProps> = () => {
    return (
        <Modal>
            <StudentsUploadModalWrapper>
                <LoadingTitle>
                    Please wait
                    <StyledAnimatedDots color="text" />
                </LoadingTitle>

                <LoadingScooterIcon
                    name={'LoadingScooterSecondary'}
                    size={356}
                    sizeY={170}
                />
                <LoadingText>
                    We are in the process of uploading the student list from a
                    csv file. Please wait and do not close the browser window.
                </LoadingText>
            </StudentsUploadModalWrapper>
        </Modal>
    );
};

export default StudentsUploadModal;
