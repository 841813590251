import { gql, useLazyQuery } from '@apollo/client';
import { setCorrectAccountData } from '@state/_redux/signup/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import paths from '@shared/paths';
import {
    ValidateParentSignupQuery,
    ValidateParentSignupQueryVariables,
} from '@@types/graphql/ValidateParentSignupQuery';

const VALIDATE_PARENT_SIGNUP_QUERY = gql`
    query ValidateParentSignupQuery($input: ValidateParentSignupInput!) {
        validateParentSignup(input: $input) {
            email
            countryCode
            zipCode
        }
    }
`;

export default () => {
    const history = useHistory();
    const dispatch = useDispatch();

    return useLazyQuery<
        ValidateParentSignupQuery,
        ValidateParentSignupQueryVariables
    >(VALIDATE_PARENT_SIGNUP_QUERY, {
        onCompleted: () => {
            dispatch(setCorrectAccountData(true));
            history.push(paths.signup_create_password);
        },
        onError: () => {},
        fetchPolicy: 'network-only',
    });
};
