import React, { useState } from 'react';
import CancelSubscriptionModal from '@domains/Parent/Account/_modals/CancelSubscriptionModal/CancelSubscriptionModal';
import DeactivateUserModal from '@domains/Parent/Account/_modals/DeactivateUserModal/DeactivateUserModal';
import EditUserDetailsModal from '@domains/Parent/Account/_modals/EditUserDetailsModal/EditUserDetailsModal';
import NewChildModal from '@domains/Parent/Account/_modals/NewChildModal/NewChildModal';
import PaymentDetails from '@domains/Parent/Account/_components/PaymentDetails/PaymentDetails';
import RenewSubscriptionModal from '@domains/Parent/Account/_modals/RenewSubscriptionModal/RenewSubscriptionModal';
import UsersList from '@domains/Parent/Account/_components/UsersList/UsersList';
import { useChildsListQuery } from '@state/childManagement/queries';
import { useSelector } from 'react-redux';
import { useUserMeQuery, useUserSubscriptionInfo } from '@state/auth/queries';
import {
    useCancelDeactivationChildAccountMutation,
    useDeactivateChildAccountMutation,
} from '@state/settings/mutations';

import {
    ActionButton,
    HeaderText,
    PageTitle,
    SectionActions,
    SectionDivider,
    SectionHeader,
    SectionInfo,
    SectionWrapper,
    SubscriptionParentPageWrapper,
} from './SubscriptionParentPage.styled';
import {
    Icon,
    PageHeader,
    SectionTitle,
} from '@domains/Parent/Account/AccountParentPage/AccountParentPage.styled';
import dayjs from 'dayjs';

interface IProps {}

enum ModalNames {
    addChildModal = 'addChildModal',
    cancelSubscriptionModal = 'cancelSubscriptionModal',
    editUserDetailsModal = 'editUserDetailsModal',
    renewSubscriptionModal = 'renewSubscriptionModal',
    deactivateUserModal = 'deactivateUserModal',
}

const initialModalsState = {
    addChildModal: false,
    cancelSubscriptionModal: false,
    deactivateUserModal: false,
    editUserDetailsModal: false,
    renewSubscriptionModal: false,
};

const SubscriptionParentPage: React.FC<IProps> = () => {
    const { data: childsListData } = useChildsListQuery();
    const { data: meData } = useUserMeQuery();
    const [modalsState, setModalsState] = useState(initialModalsState);
    const childId = useSelector((state) => state.childManagement.childId);
    const child = childsListData?.getChildsForParent.find(
        (item) => item.id === childId,
    );

    const changeModalState = (modalName: ModalNames, state: boolean) => {
        setModalsState({ ...initialModalsState, [modalName]: state });
    };

    const { data: subscriptionInfoData } = useUserSubscriptionInfo();
    const [
        deactivateChild,
        { loading: deactivateChildLoading },
    ] = useDeactivateChildAccountMutation(() =>
        changeModalState(ModalNames.deactivateUserModal, false),
    );

    const [
        cancelDeactivationChild,
    ] = useCancelDeactivationChildAccountMutation();

    return (
        <SubscriptionParentPageWrapper>
            <PageHeader>
                <PageTitle>Subscription</PageTitle>
                <Icon name={'WalletIcon'} size={70} sizeY={40} />
            </PageHeader>
            {subscriptionInfoData && (
                <>
                    <PaymentDetails
                        hasSubscription={
                            !meData?.me.deactivateAtCurrentPeriodEnd ?? false
                        }
                        onCancelSubscription={() =>
                            changeModalState(
                                ModalNames.cancelSubscriptionModal,
                                true,
                            )
                        }
                        onRenewSubscription={() =>
                            changeModalState(
                                ModalNames.renewSubscriptionModal,
                                true,
                            )
                        }
                        subscriptionEndDate={dayjs(
                            meData?.me.subscriptionCurrentPeriodEnd,
                        ).format('DD.MM.YYYY')}
                    />
                    <SectionDivider />
                </>
            )}
            <SectionWrapper>
                <SectionHeader>
                    <SectionInfo>
                        <SectionTitle>Your children</SectionTitle>
                        <HeaderText>
                            {childsListData?.getChildsForParent.length
                                ? 'You can add, edit or deactivate users (without losing any data) in your plan. Activate inactive users at any time.'
                                : `Information about users in your subscription plan
                            will be displayed here. You can start adding a child
                            by clicking the button ‘Add a child’.`}
                        </HeaderText>
                    </SectionInfo>
                    <SectionActions>
                        <ActionButton
                            buttonType={'primary'}
                            disabled={
                                childsListData?.getChildsForParent.length === 10
                            }
                            onPress={() =>
                                changeModalState(ModalNames.addChildModal, true)
                            }
                            size={'small'}
                        >
                            add a child
                        </ActionButton>
                    </SectionActions>
                </SectionHeader>
                <UsersList
                    onCancelDeactivationUser={(childId: number) =>
                        cancelDeactivationChild({ variables: { childId } })
                    }
                    onDeactivateUser={() =>
                        changeModalState(ModalNames.deactivateUserModal, true)
                    }
                    onEditUser={() =>
                        changeModalState(ModalNames.editUserDetailsModal, true)
                    }
                />
            </SectionWrapper>

            {/* MODALS */}
            {modalsState.cancelSubscriptionModal && (
                <CancelSubscriptionModal
                    setIsModalOpen={() =>
                        changeModalState(
                            ModalNames.cancelSubscriptionModal,
                            false,
                        )
                    }
                />
            )}

            {childId && modalsState.editUserDetailsModal && (
                <EditUserDetailsModal
                    setIsModalOpen={() =>
                        changeModalState(ModalNames.editUserDetailsModal, false)
                    }
                />
            )}

            {modalsState.addChildModal && (
                <NewChildModal
                    setIsModalOpen={() =>
                        changeModalState(ModalNames.addChildModal, false)
                    }
                />
            )}

            {modalsState.renewSubscriptionModal && (
                <RenewSubscriptionModal
                    setIsModalOpen={() =>
                        changeModalState(
                            ModalNames.renewSubscriptionModal,
                            false,
                        )
                    }
                />
            )}

            {childId && modalsState.deactivateUserModal && (
                <DeactivateUserModal
                    childName={`${child?.name} ${child?.surname}`}
                    loading={deactivateChildLoading}
                    onDeactivate={() =>
                        deactivateChild({ variables: { childId } })
                    }
                    setIsModalOpen={() =>
                        changeModalState(
                            ModalNames.renewSubscriptionModal,
                            false,
                        )
                    }
                />
            )}
        </SubscriptionParentPageWrapper>
    );
};

export default SubscriptionParentPage;
