import styled, { css } from 'styled-components';
import {
    DefaultText,
    LinkText,
} from '@components/_universal/Typography.styled';

export const AgreementTextWrapper = styled(DefaultText)(
    ({ theme: { fontSizes } }) => css`
        font-size: ${fontSizes.xs}px;
        line-height: 1.65;
        margin-bottom: 0;
        text-align: center;
    `,
);

export const StyledLinkText = styled(LinkText)(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.primary};
        font-size: ${fontSizes.xs}px;
        line-height: 1.65;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            text-underline-position: under;
        }
    `,
);
