import { useEffect, useState } from 'react';
import { breakpoints } from '@shared/constants';

export default (breakpoint: keyof typeof breakpoints) => {
    const [match, setMatch] = useState<boolean>(
        window.innerWidth < breakpoints[breakpoint],
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia(
            `(max-width: ${breakpoints[breakpoint]}px)`,
        );
        const checkMediaQuery = (e: MediaQueryListEvent) => setMatch(e.matches);

        mediaQuery.addEventListener('change', checkMediaQuery);

        return () => mediaQuery.removeEventListener('change', checkMediaQuery);
    }, []);

    return { match };
};
