import { gql, useQuery } from '@apollo/client';
import {
    GetChildCourseDetailsQuery,
    GetChildCourseDetailsQueryVariables,
} from '@@types/graphql/GetChildCourseDetailsQuery';
import { useHistory, useParams } from 'react-router';
import paths from '@shared/paths';

const GET_CHILD_COURSE_DETAILS_QUERY = gql`
    query GetChildCourseDetailsQuery($id: ID!) {
        getCourse(id: $id) {
            _id
            thumbnail
            name
            description
            aboutVideo {
                _id
                name
                apiVideoId
                duration
                creator
                status
                captions
                chapters
                link
                usedInContent
            }
            lessons {
                name
            }
            duration
        }
    }
`;

export default (id: string) => {
    const history = useHistory();
    const { childId } = useParams<{ childId: string }>();

    return useQuery<
        GetChildCourseDetailsQuery,
        GetChildCourseDetailsQueryVariables
    >(GET_CHILD_COURSE_DETAILS_QUERY, {
        variables: {
            id,
        },
        onError: () => {
            history.push(paths.dashboard_parent(childId));
        },
    });
};
