import styled from 'styled-components';
import { Button } from '@components/_universal';

export const SetPasswordFormWrapper = styled.div``;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const StyledButton = styled(Button)`
    justify-content: center;
    margin: 16px 8px 0;
    min-width: 234px;
`;
