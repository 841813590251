import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button } from '@components/_universal';
import { FormRow, InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';

export const StudentFormWrapper = styled.form``;

export const GroupFormWrapper = styled.form`
    display: flex;
    flex-direction: column;
`;

export const StyledButton = styled(Button)`
    width: 234px;

    & + & {
        margin-left: 16px;
    }
`;

export const StyledFormRow = styled(FormRow)`
    @media (max-width: ${breakpoints.sm}px) {
        flex-direction: column;
    }
`;

export const StyledInputWrapper = styled(InputWrapper)`
    @media (max-width: ${breakpoints.sm}px) {
        width: 100%;
    }
`;

export const ButtonsRow = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 8px;
`;
