import { gql, useMutation } from '@apollo/client';
import { GET_SCHOOL_CLASSES_QUERY } from '@state/teachers/queries/useSchoolClassesQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { SchoolClassesQuery } from '@@types/graphql/SchoolClassesQuery';
import { ToastTypes } from '@@types/CommonTypes';
import {
    CreateStudentMutation,
    CreateStudentMutationVariables,
} from '@@types/graphql/CreateStudentMutation';

const CREATE_STUDENT_MUTATION = gql`
    mutation CreateStudentMutation($input: CreateStudentInput!) {
        createStudent(input: $input) {
            id
            name
            surname
            status
            email
            class {
                id
                year
                className
            }
        }
    }
`;

export default (callback: () => void) => {
    return useMutation<CreateStudentMutation, CreateStudentMutationVariables>(
        CREATE_STUDENT_MUTATION,
        {
            onCompleted: () => {
                callback();
                notify('You have successfully added this student.')[
                    ToastTypes.SUCCESS
                ]();
            },
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
            update: (cache, { data }) => {
                if (!data) return;
                const query = cache.readQuery<SchoolClassesQuery>({
                    query: GET_SCHOOL_CLASSES_QUERY,
                });

                if (query) {
                    const newSchoolClass = data.createStudent.class;
                    const isExistingClass = !!(
                        newSchoolClass &&
                        query.getSchoolClasses.find(
                            (schoolClass) =>
                                schoolClass.id === newSchoolClass.id,
                        )
                    );

                    !isExistingClass &&
                        newSchoolClass &&
                        cache.writeQuery<SchoolClassesQuery>({
                            query: GET_SCHOOL_CLASSES_QUERY,
                            data: {
                                getSchoolClasses: [
                                    ...query.getSchoolClasses,
                                    newSchoolClass,
                                ],
                            },
                        });
                }
            },
        },
    );
};
