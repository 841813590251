import React from 'react';
import getContentProgress from '@utils/getContentProgress';
import ProgressElement from '@components/_universal/ProgressElement/ProgressElement';
import useOngoingContentQuery from '@state/lists/queries/useOngoingContentQuery';
import {
    Description,
    EmptyTitle,
    GhostIcon,
    KeepGoingIconManager,
    ProgressElementsWrapper,
    ProgressSidebarInnerWrapper,
    ProgressSidebarWrapper,
    SimpleBarWrapper,
    StyledButton,
    Title,
    TitleWrapper,
} from './ProgressSidebar.styled';
import { useHistory } from 'react-router-dom';
import paths from '@shared/paths';

interface IProps {
    className?: string;
}

const returnContentType = (type: string) =>
    type === 'COURSE' ? 'Course' : 'Video';

const getUrl = (type: string, id: string) =>
    type === 'COURSE'
        ? paths.course(id)
        : type === 'STANDALONE_VIDEO'
        ? paths.video_view(id)
        : '';

const ProgressSidebar: React.FC<IProps> = ({ className }) => {
    const history = useHistory();
    const { data } = useOngoingContentQuery(10);
    const ongoingContent = data?.getOngoingContent;
    const isEmpty = !ongoingContent?.length;

    return (
        <ProgressSidebarWrapper className={className}>
            <SimpleBarWrapper>
                {isEmpty ? (
                    <ProgressSidebarInnerWrapper>
                        <EmptyTitle>Your progress</EmptyTitle>
                        <GhostIcon name={'Ghost'} size={50} sizeY={47} />
                        <Description>
                            You haven't started any courses yet! Head over to
                            the library to get started.
                        </Description>
                        <StyledButton
                            buttonType={'secondary'}
                            onPress={() => history.push(paths.library)}
                            size={'small'}
                        >
                            Go to library
                        </StyledButton>
                    </ProgressSidebarInnerWrapper>
                ) : (
                    <ProgressSidebarInnerWrapper>
                        <TitleWrapper>
                            <Title>Your progress</Title>
                            <KeepGoingIconManager
                                name={'KeepGoing'}
                                size={93}
                                sizeY={59}
                            />
                        </TitleWrapper>
                        <ProgressElementsWrapper>
                            {ongoingContent?.map(
                                ({
                                    thumbnail,
                                    name,
                                    id,
                                    content,
                                    progress,
                                    type,
                                }) => (
                                    <ProgressElement
                                        image={thumbnail ?? ''}
                                        isVideo={type === 'STANDALONE_VIDEO'}
                                        progress={getContentProgress(
                                            content,
                                            progress,
                                            type,
                                        )}
                                        url={getUrl(type, content._id)}
                                        progressType={returnContentType(type)}
                                        key={id}
                                    >
                                        {name}
                                    </ProgressElement>
                                ),
                            )}
                        </ProgressElementsWrapper>
                        <StyledButton
                            buttonType={'secondary'}
                            onPress={() => history.push(paths.courses)}
                            size={'small'}
                        >
                            See all ongoing
                        </StyledButton>
                    </ProgressSidebarInnerWrapper>
                )}
            </SimpleBarWrapper>
        </ProgressSidebarWrapper>
    );
};

export default ProgressSidebar;
