import { IconManager } from '@components/_universal';
import styled, { css } from 'styled-components';

export const UploadWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background: ${colors.background_quaternary};
        border-radius: ${borderRadius.card}px;
        display: grid;
        grid-gap: 50px;
        grid-template-columns: 428px 1fr;
        justify-items: flex-start;
        padding: 32px;
        position: relative;
    `,
);

export const IconClose = styled(IconManager)(
    ({ theme: { cursors } }) => css`
        cursor: ${cursors.pointer};
        margin-left: 30px;
        position: absolute;
        right: 24px;
        top: 16px;
    `,
);

export const IconSeparator = styled(IconManager)`
    margin: 5px 12px 13px 12px;
`;
