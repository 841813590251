import React, { useState } from 'react';
import { useMatchMedia } from '@utils/hooks';
import CreateAccount from '@domains/Auth/_components/CreateAccount/CreateAccount';
import FreeTrial from '@domains/Auth/_components/FreeTrial/FreeTrial';
import SignupPricingPage from '@domains/Auth/SignupPricingPage/SignupPricingPage';
import {
    AccountPageInnerWrapper,
    ClosePricePage,
    CreateAccountPageWrapper,
    SignupPricingWrapper,
    StyledTitle,
} from './CreateAccountPage.styled';

export interface IProps {}

const CreateAccountPage: React.FC<IProps> = () => {
    const [visiblePricePage, setVisiblePricePage] = useState(false);
    const { match } = useMatchMedia('md');

    return (
        <>
            <CreateAccountPageWrapper visible={!visiblePricePage}>
                <StyledTitle>
                    A world of experiences is just around the corner
                </StyledTitle>
                <AccountPageInnerWrapper>
                    <CreateAccount />
                    <FreeTrial
                        isMobile={match}
                        setVisible={() => setVisiblePricePage(true)}
                    />
                </AccountPageInnerWrapper>
            </CreateAccountPageWrapper>
            <SignupPricingWrapper visible={visiblePricePage}>
                <ClosePricePage
                    onClick={() => setVisiblePricePage(false)}
                    fill={['app_bg']}
                    name={'Close'}
                    size={34}
                />
                <SignupPricingPage key={JSON.stringify(visiblePricePage)} />
            </SignupPricingWrapper>
        </>
    );
};

export default CreateAccountPage;
