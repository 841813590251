import React from 'react';
import AgreeRulesForm from '@domains/Auth/Onboarding/_forms/AgreeRulesForm/AgreeRulesForm';
import Button from '@components/_universal/Button/Button';
import Pagination from '@domains/Auth/Onboarding/_components/Pagination/Pagination';
import Rule from '@domains/Auth/Onboarding/_components/Rule/Rule';
import paths, { externalLinks } from '@shared/paths';
import { IOnboardingForm } from '@domains/Auth/Onboarding/Onboarding';
import { RULES } from '@domains/Auth/Onboarding/AgreeRulesPage/AgreeRulesPage.data';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
    AgreeRulesDescription,
    AgreeRulesOrnamentIcon,
    AgreeRulesPageContent,
    AgreeRulesPageFooter,
    AgreeRulesTitle,
    CheckboxWrapper,
    DesktopAgreeRulesPageWrapper,
    OnboardingCheckboxFieldIcon,
    ReadOurRulesIcon,
    RulesList,
} from './DesktopAgreeRulesPage.styled';

export interface IProps {
    loading: boolean;
    onSubmit: () => void;
}

const DesktopAgreeRulesPage: React.FC<IProps> = ({ loading, onSubmit }) => {
    const history = useHistory();
    const { watch } = useFormContext<IOnboardingForm>();

    const agreeRules = watch('agreeRules');

    return (
        <DesktopAgreeRulesPageWrapper>
            <AgreeRulesPageContent>
                <AgreeRulesTitle>Good behaviour is essential</AgreeRulesTitle>
                <AgreeRulesDescription>
                    Let's make 8billionideas a safe and respectful space for
                    everyone. Read through our rules below.
                </AgreeRulesDescription>
                <ReadOurRulesIcon name={'ReadOurRulesText'} size={190} />
                <AgreeRulesOrnamentIcon
                    name={'AgreeRulesOrnaments'}
                    size={280}
                />

                <RulesList>
                    {RULES.map(({ ruleCard, text, ...badgeProps }, index) => (
                        <Rule
                            badgeProps={badgeProps}
                            key={index}
                            ruleCard={ruleCard}
                            text={text}
                        />
                    ))}
                    <CheckboxWrapper>
                        <OnboardingCheckboxFieldIcon
                            name={'OnboardingCheckboxField'}
                            size={455}
                            sizeY={135}
                        />
                        <AgreeRulesForm />
                    </CheckboxWrapper>
                </RulesList>
            </AgreeRulesPageContent>

            <AgreeRulesPageFooter>
                <Button
                    buttonType={'secondary'}
                    onPress={() => history.push(paths.set_password)}
                    size={'small'}
                >
                    previous step
                </Button>
                <Pagination>Step 3</Pagination>
                <Button
                    buttonType={'primary'}
                    disabled={loading}
                    disabledWithAction={!agreeRules}
                    onPress={onSubmit}
                    size={'small'}
                >
                    let’s get started
                </Button>
            </AgreeRulesPageFooter>
        </DesktopAgreeRulesPageWrapper>
    );
};

export default DesktopAgreeRulesPage;
