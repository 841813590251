import styled, { css } from 'styled-components';
import { CourseInfoTileCss } from '@components/_universal/InfoTiles/InfoTiles.styled';
import { SubTitle } from '@components/_universal/Typography.styled';

export const LoaderWrapper = styled.div`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const VideoDetailsWrapper = styled.div``;

export const PageTitle = styled(SubTitle)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        padding-top: 34px;
    `,
);

export const InnerWrapper = styled.div`
    display: flex;
`;

export const TileWrapper = styled.div(
    ({ theme: { colors } }) => css`
        ${CourseInfoTileCss}
        background: ${colors.details_teacher};
        color: ${colors.text};
        margin-right: 32px;
        margin-top: 48px;
        width: 224px;
    `,
);
