import React, { useEffect } from 'react';
import { Content, FooterBar, LoginLayoutWrapper } from './LoginLayout.styled';
import { setLoginLayoutAnimation } from '@state/_redux/layout/actions';
import { useDispatch, useSelector } from 'react-redux';

interface IProps {}

export const LoginLayout: React.FC<IProps> = ({ children }) => {
    const loginLayoutAnimation = useSelector(
        (state) => state.layout.loginLayoutAnimation,
    );
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatch(setLoginLayoutAnimation(false));
        }, 1500);
    }, []);

    return (
        <LoginLayoutWrapper withAnimation={loginLayoutAnimation}>
            <Content withAnimation={loginLayoutAnimation}>{children}</Content>
            <FooterBar />
        </LoginLayoutWrapper>
    );
};

export default LoginLayout;
