import { gql, useQuery } from '@apollo/client';
import { GetStudentForTeacherInput } from '@@types/graphql/GraphqlGlobalTypes';
import {
    StudentForTeacherQuery,
    StudentForTeacherQueryVariables,
} from '@@types/graphql/StudentForTeacherQuery';

const STUDENTS_FOR_TEACHER_QUERY = gql`
    query StudentForTeacherQuery($input: GetStudentForTeacherInput!) {
        getStudentForTeacher(input: $input) {
            avatar
            email
            id
            name
            surname
            class {
                year
                className
            }
        }
    }
`;

export default (input: GetStudentForTeacherInput) =>
    useQuery<StudentForTeacherQuery, StudentForTeacherQueryVariables>(
        STUDENTS_FOR_TEACHER_QUERY,
        {
            onError: (err) => console.log(err.message),
            variables: {
                input,
            },
        },
    );
