import styled, { css } from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import { ColorType } from '@@types/CommonTypes';
import { DefaultText, Header } from '@components/_universal/Typography.styled';
import { Link } from 'react-router-dom';

export const ActivityFeedCardWrapper = styled.div`
    margin-bottom: 24px;
`;

export const PeriodText = styled(Header)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin: 0 0 16px 32px;
    `,
);

export const ContentWrapper = styled.div(
    ({ theme: { borderRadius, colors, silver_shadow } }) => css`
        background-color: ${colors.background_secondary};
        border-radius: ${borderRadius.card}px;
        border: 1px solid ${colors.background_tertiary};
        box-shadow: ${silver_shadow};
        padding: 32px;
    `,
);

export const ActivityWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 24px;

    &:last-of-type {
        margin: 0;
    }
`;

export const ActivityInnerWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-grow: 1;
`;

export const ActivityContent = styled.div`
    margin: 0 32px 0 16px;
`;

export const ActivityText = styled(DefaultText)(
    () => css`
        line-height: 1.5;
        margin-bottom: 2px;
    `,
);

export const TimeText = styled(DefaultText)(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.text_secondary};
        font-size: ${fontSizes.s}px;
        line-height: 1.5;
        margin: 0;
    `,
);

export const NameText = styled(Link)(
    ({ theme: { colors, fontWeights } }) =>
        css`
            color: ${colors.text};
            font-weight: ${fontWeights.semiBold};
            text-decoration: none;
        `,
);

export const ContentName = styled.span(
    ({ theme: { fontWeights } }) =>
        css`
            font-weight: ${fontWeights.semiBold};
        `,
);

export const FeedbackButton = styled(Button)`
    flex-shrink: 0;
    min-width: 150px;
`;

export const ContentMark = styled.div<{
    contentColor?: ColorType;
}>(
    ({ contentColor, theme: { borderRadius, colors } }) => css`
        height: 24px;
        margin: 0 20px 0 4px;
        position: relative;
        width: 24px;

        ${contentColor &&
        css`
            &::after {
                background-color: ${colors[contentColor]};
                border-radius: ${borderRadius.circle}%;
                bottom: 0;
                content: '';
                height: 9px;
                position: absolute;
                right: 1px;
                width: 9px;
                z-index: 9;
            }
        `}
    `,
);
