import styled from 'styled-components';
import {
    NavigationWrapper,
    StyledSimpleBar,
    NavigationItemWrapper,
    NavigationItemText,
} from '@components/_global/Navigation/Navigation.styled';

export const StyledNavigationWrapper = styled(NavigationWrapper)`
    background: ${({ theme }) => theme.colors.background_secondary};
    z-index: 99;
`;

export const StyledParentSimpleBar = styled(StyledSimpleBar)`
    .simplebar-scrollbar::before {
        background: ${({ theme }) => theme.fontWeights.semiBold};
    }
`;

export const StyledNavigationItemText = styled(NavigationItemText)`
    line-height: 1.4;
    margin: 4px 0;
    overflow: hidden;
    padding: 0 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;
