import React, { useEffect, useState } from 'react';
import {
    alphabeticalClassSort,
    alphabeticalGroupSort,
} from '@utils/alphabeticalSortFunctions';
import { SelectType } from '@@types/CommonTypes';
import { StudentsListQuery_getStudentsList_users_class } from '@@types/graphql/StudentsListQuery';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSchoolClassesQuery } from '@state/teachers/queries';
import { useStudentsPage } from '@domains/Teacher/Students/StudentsPage/useStudentsPage';
import {
    FilterElement,
    FilterName,
    SchoolFiltersWrapper,
    FilterSelect,
} from './SchoolFilters.styled';

export interface SchoolFiltersProps {
    getFilters: (className?: string, groupId?: number, year?: string) => void;
    reset?: boolean;
    setReset?: (value: boolean) => void;
    showGroupFilter?: boolean;
}

interface FiltersForm {
    className: SelectType<string | undefined>;
    groupId: SelectType<number | undefined>;
    year: SelectType<string | undefined>;
}

type ClassesType = { [key: string]: string[] };

const selectedAll = {
    label: 'All',
    value: undefined,
};

export const SchoolFilters = ({
    getFilters,
    reset,
    setReset,
    showGroupFilter = false,
}: SchoolFiltersProps) => {
    const [classes, setClasses] = useState<ClassesType>({});

    const { control, watch, setValue } = useForm<FiltersForm>({
        defaultValues: {
            groupId: selectedAll,
            year: selectedAll,
        },
    });
    const { data } = useSchoolClassesQuery();
    const classesData = data?.getSchoolClasses;
    const { className, groupId, year } = watch();
    const { groupId: urlGroupId } = useParams<{ groupId: string }>();

    const { studentsList } = useStudentsPage(+urlGroupId);
    const sortedGroupTitles = [
        ...new Set(
            studentsList
                ?.flatMap((student) => student.groups)
                .map((group) => group)
                .sort(alphabeticalGroupSort),
        ),
    ];

    const studentsClass = [
        ...new Set(studentsList?.map((student) => student.class)),
    ];

    const filteredClass = studentsClass.reduce(
        (
            acc: Array<StudentsListQuery_getStudentsList_users_class | null>,
            current,
        ) => {
            const items = acc.find((item) => item!.year === current!.year);
            if (!items) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        },
        [],
    );

    const groups = [
        selectedAll,
        ...sortedGroupTitles.map((group) => ({
            label: group?.title,
            value: group?.id,
        })),
    ];

    const years = [
        selectedAll,
        ...filteredClass!.map((classData) => ({
            label: classData!.year,
            value: +classData!.year,
        })),
    ];

    const selectClasses = year?.value
        ? [
              selectedAll,
              ...classes[year.value]
                  .map((classData) => ({
                      label: classData,
                      value: classData,
                  }))
                  .sort(alphabeticalClassSort),
          ]
        : [];

    const onReset = () => {
        setValue('className', undefined);
        setValue('groupId', selectedAll);
        setValue('year', selectedAll);
    };

    useEffect(() => {
        if (!classesData) return;

        let classes: ClassesType = {};

        classesData.map((classData) => {
            const classTable = classes[`${classData.year}`];

            return (classes[`${classData.year}`] = classTable
                ? [...classTable, classData.className]
                : [classData.className]);
        });

        setClasses(classes);
    }, [classesData]);

    useEffect(() => {
        setValue('className', year?.value ? selectedAll : null);
    }, [year]);

    useEffect(() => {
        getFilters(className?.value, groupId?.value, year?.value);
    }, [className, groupId, year]);

    useEffect(() => {
        if (reset) {
            onReset();
            setReset?.(false);
        }
    }, [reset]);

    return (
        <SchoolFiltersWrapper>
            {showGroupFilter && (
                <FilterElement>
                    <FilterName>Group:</FilterName>
                    <FilterSelect
                        control={control}
                        marginBottom={0}
                        name={'groupId'}
                        options={groups}
                        placeholder="-"
                        variant="secondary"
                    />
                </FilterElement>
            )}
            <FilterElement>
                <FilterName>Year:</FilterName>
                <FilterSelect
                    control={control}
                    marginBottom={0}
                    name={'year'}
                    options={years}
                    placeholder="-"
                    variant="secondary"
                />
            </FilterElement>
            <FilterElement>
                <FilterName>Class:</FilterName>
                <FilterSelect
                    control={control}
                    disabled={year === selectedAll}
                    marginBottom={0}
                    name={'className'}
                    options={selectClasses}
                    placeholder="-"
                    variant="secondary"
                />
            </FilterElement>
        </SchoolFiltersWrapper>
    );
};
