import React from 'react';
import { useRenewSubscriptionMutation } from '@state/settings/mutations';
import ModalConfirmation from '@components/_modals/ModalConfirmation/ModalConfirmation';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const RenewSubscriptionModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const [renewSubscription, { loading }] = useRenewSubscriptionMutation(() =>
        setIsModalOpen(false),
    );

    return (
        <ModalConfirmation
            closeOutside
            firstButton={{
                onClick: () => setIsModalOpen(false),
                text: 'Go back',
                type: 'secondary',
            }}
            onClose={() => setIsModalOpen(false)}
            secondButton={{
                onClick: renewSubscription,
                text: 'Renew subscription',
                type: 'primary',
                disabled: loading,
            }}
            title="Renew subscription"
        >
            We're really pleased that you want to keep subscribing to your
            platform. Renew your subscription today to continue enjoying our
            service. Thanks for staying with us!
        </ModalConfirmation>
    );
};

export default RenewSubscriptionModal;
