import { useEffect, useState } from 'react';
import { useAuthMeQuery } from '@state/auth/queries';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { useSelector } from 'react-redux';

export default () => {
    const { data } = useAuthMeQuery();

    const { skipSetPassword } = useSelector((state) => state.signup);
    const [createPasswordModal, setCreatePasswordModal] = useState(false);

    const isChild = data?.me.type === USER_TYPE.CHILD;
    const isParent = data?.me.type === USER_TYPE.PARENT;
    const isOnboardingCompleted = data?.me.onboardingCompletedAt;
    const hasPassword = data?.me.hasPassword;

    useEffect(() => {
        if (!data) return;

        if (!hasPassword && !skipSetPassword && (isChild || isParent)) {
            if (isChild && !isOnboardingCompleted) return;

            setTimeout(() => {
                setCreatePasswordModal(true);
            }, 4000);
        } else {
            setCreatePasswordModal(false);
        }
    }, [data, hasPassword, skipSetPassword]);

    return {
        createPasswordModal,
    };
};
