import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import { breakpoints } from '@shared/constants';
import AnimatedDots from '@components/_universal/AnimatedDots/AnimatedDots';

export const MobileAuthorizationTokenPageWrapper = styled.div`
    align-items: center;
    align-self: center;
    background: rgba(81, 91, 230, 0.95);
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 800px;
    padding: 54px;
    width: 100%;

    @media (max-width: ${breakpoints.md}px) {
        padding: 40px;
    }
`;

export const AuthorizationTokenContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    justify-content: center;
`;

export const LoadingTitle = styled.div`
    color: ${({ theme }) => theme.colors.text_white};
    display: flex;
    font-family: ${({ theme }) => theme.fontFamily.sketched};
    font-size: ${({ theme }) => theme.fontSizes.max}px;

    @media (max-width: ${breakpoints.xs}px) {
        font-size: 50px;
        padding-right: 12px;
    }
`;

export const LogoIcon = styled(IconManager)`
    @media (max-width: ${breakpoints.sm}px) {
        font-size: 126px;
    }
`;

export const LoadingScooterIcon = styled(IconManager)`
    margin: 72px 0;

    @media (max-width: ${breakpoints.md}px) {
        font-size: 300px;
        margin: 54px 0 32px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        margin: 48px 0 24px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        font-size: 220px;
        margin: 48px 0 12px;
    }
`;

export const StyledDots = styled(AnimatedDots)`
    @media (max-width: ${breakpoints.xs}px) {
        left: 30px;
        top: 40px;
    }
`;
