import { gql, useMutation } from '@apollo/client';
import {
    MarkLessonVideoProgressMutation,
    MarkLessonVideoProgressMutationVariables,
} from '@@types/graphql/MarkLessonVideoProgressMutation';

const MARK_LESSON_VIDEO_PROGRESS_MUTATION = gql`
    mutation MarkLessonVideoProgressMutation(
        $lessonId: String!
        $checkpoint: Int!
    ) {
        markLessonProgress(lessonId: $lessonId, checkpoint: $checkpoint) {
            id
        }
    }
`;

export default () => {
    return useMutation<
        MarkLessonVideoProgressMutation,
        MarkLessonVideoProgressMutationVariables
    >(MARK_LESSON_VIDEO_PROGRESS_MUTATION, {
        onError: (error) => console.log(error),
    });
};
