import { gql, useQuery } from '@apollo/client';
import { GetSchoolGroupsInput } from '@@types/graphql/GraphqlGlobalTypes';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import {
    SchoolGroups,
    SchoolGroupsVariables,
} from '@@types/graphql/SchoolGroups';

export const SCHOOL_GROUPS = gql`
    query SchoolGroups($input: GetSchoolGroupsInput!) {
        getSchoolGroups(input: $input) {
            groups {
                id
                title
                schoolId
                students {
                    name
                    surname
                }
            }
            count
        }
    }
`;

export const useSchoolGroups = (input: GetSchoolGroupsInput) => {
    return useQuery<SchoolGroups, SchoolGroupsVariables>(SCHOOL_GROUPS, {
        fetchPolicy: 'network-only',
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
        variables: { input },
    });
};
