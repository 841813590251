import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import Footer from '@components/_global/Footer/Footer';
import Navigation from '@components/_global/Navigation/OnboardingNavigation/OnboardingNavigation';
import onboardingBackground from '@assets/images/onboarding_background.svg';

export const OnboardingLayoutWrapper = styled.div`
    display: grid;
    grid-template-areas:
        'navigation . .'
        'navigation content .'
        'navigation footer .';
    grid-template-columns: 1fr 1140px 1fr;
    grid-template-rows: 100px 1fr 90px;
    min-height: 100%;
    width: 100%;

    @media (max-height: 768px) {
        grid-template-rows: 90px 1fr 60px;
    }

    @media (max-width: ${breakpoints.lg}px) {
        grid-template-columns: 1fr 960px 1fr;
        grid-template-rows: 100px 1fr 60px;
    }

    @media (max-width: ${breakpoints.md}px) {
        grid-template-columns: 1fr 720px 1fr;
    }

    @media (max-width: ${breakpoints.sm}px) {
        grid-template-columns: 1fr 540px 1fr;
        grid-template-rows: 60px 1fr 60px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        grid-template-columns: 1fr calc(100% - 32px) 1fr;
    }
`;

export const FooterBar = styled(Footer)`
    grid-area: footer;
    padding-right: 16px;
`;

export const NavigationBar = styled(Navigation)`
    grid-area: navigation;
`;

export const ContentWrapper = styled.div`
    align-items: center;
    display: flex;
    grid-area: content;
`;

export const Content = styled.div`
    position: relative;

    background: url(${onboardingBackground})
        ${({ theme }) => theme.colors.background_primary} no-repeat;
    background-position: 98% 50px;
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    height: 100%;
    max-height: 900px;
    min-height: 860px;
    padding: 80px 100px;
    position: relative;
    width: 100%;

    @media (max-height: 768px) {
        padding: 48px 100px;
        min-height: 800px;
    }

    @media (max-width: ${breakpoints.lg}px) {
        height: fit-content;
        max-height: unset;
        min-height: unset;
        padding: 48px 80px;
    }

    @media (max-width: ${breakpoints.md}px) {
        padding: 40px 60px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        border-radius: ${({ theme }) => theme.borderRadius.button}px;
        padding: 32px 40px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        padding: 32px 24px;
    }
`;
