import styled, { css } from 'styled-components';
import { IconManager } from '@components/_universal';
import Input from '@components/_form-elements/Input/Input';

const wrapperCss = css`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const InputSearchWrapper = styled.div<{ marginBottom?: number }>(
    ({ marginBottom }) => css`
        margin-bottom: ${typeof marginBottom === 'number'
            ? marginBottom
            : 24}px;
        ${wrapperCss}
    `,
);

export const InputSearchInnerWrapper = styled.div`
    position: relative;
    ${wrapperCss}
`;

export const StyledInputSearch = styled.input(
    ({ theme: { borderRadius, colors, fontSizes, fontWeights } }) => css`
        background: ${colors.even_table};
        border-radius: ${borderRadius.input}px;
        border: 0;
        caret-color: ${colors.primary};
        color: ${colors.text};
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        padding: 14px 44px 14px 64px;

        ::placeholder,
        ::-webkit-input-placeholder {
            color: ${colors.text_secondary};
            font-size: ${fontSizes.s}px;
            font-weight: ${fontWeights.regular};
        }

        &:hover,
        &:focus,
        &:active {
            outline: none;
        }
    `,
);

export const SearchInput = styled(Input)`
    background: ${({ theme }) => theme.colors.error};
    border-radius: 50px;
    padding: 0 50px;
`;

export const StyledIconSearch = styled(IconManager)`
    left: 32px;
    top: 10px;
    position: absolute;
`;

export const StyledIconClearButton = styled.button`
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 10px;
    right: 20px;
`;
