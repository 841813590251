import { gql, useQuery } from '@apollo/client';
import { StripePublishableKeyQuery } from '@@types/graphql/StripePublishableKeyQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

export const STRIPE_PUBLISHABLE_KEY_QUERY = gql`
    query StripePublishableKeyQuery {
        getStripeSetup {
            publishableKey
        }
    }
`;

export default () =>
    useQuery<StripePublishableKeyQuery>(STRIPE_PUBLISHABLE_KEY_QUERY, {
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
