import React from 'react';
import DoneExperience from '@domains/Child/LiveExperience/_components/ExperienceInfo/DoneExperience/DoneExperience';
import FullyBookedExperience from '@domains/Child/LiveExperience/_components/ExperienceInfo/FullyBookedExperience/FullyBookedExperience';
import SignedUpExperience from '@domains/Child/LiveExperience/_components/ExperienceInfo/SignedUpExperience/SignedUpExperience';
import SignUpExperience from '@domains/Child/LiveExperience/_components/ExperienceInfo/SignUpExperience/SignUpExperience';
import SubmittedExperience from '@domains/Child/LiveExperience/_components/ExperienceInfo/SubmittedExperience/SubmittedExperience';
import OngoingExperience from '@domains/Child/LiveExperience/_components/ExperienceInfo/OngoingExperience/OngoingExperience';
import { ExperienceInfoWrapper } from './ExperienceInfo.styled';
import { LiveExperienceSteps } from '@@types/CommonTypes';
import Loader from '@components/_universal/Loader/Loader';

interface IProps {
    className?: string;
    link: string;
    loading: boolean;
    onTimerComplete: () => void;
    stepVariant: LiveExperienceSteps;
}

export const ExperienceInfo: React.FC<IProps> = ({
    className,
    link,
    loading,
    onTimerComplete,
    stepVariant,
}) => {
    const experienceVariants = () => {
        return {
            experienceDone: <DoneExperience />,
            fullyBooked: <FullyBookedExperience />,
            signedUp: <SignedUpExperience {...{ onTimerComplete }} />,
            signUp: <SignUpExperience />,
            submitted: <SubmittedExperience />,
            ongoing: <OngoingExperience {...{ link }} />,
        };
    };
    return (
        <ExperienceInfoWrapper
            backgroundVariant={stepVariant}
            className={className}
        >
            {loading ? (
                <Loader size={'medium'} />
            ) : (
                experienceVariants()[stepVariant]
            )}
        </ExperienceInfoWrapper>
    );
};

export default ExperienceInfo;
