import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import { Title } from '@components/_universal/Typography.styled';

export const ExperiencesCarouselWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
`;

export const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 40px;
    width: 100%;
    z-index: 9;
`;

export const SwiperWrapper = styled.div`
    max-width: calc(100vw - 525px);
    overflow: hidden;
    padding-bottom: 35px;
`;

export const BackgroundDecorator = styled(IconManager)`
    position: absolute;
    right: 40px;
    top: -110px;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-right: 85px;
    margin: 48px 0;
    width: 100%;
`;
