import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import {
    CardWrapper,
    DEFAULT_CARD_HEIGHTS,
    DEFAULT_CARD_WIDTHS,
    imageOverlayCss,
} from '@components/_universal/Cards/Cards.styled';

export const TextBoxCardWrapper = styled(CardWrapper)<{
    image: string;
}>`
    background-image: url(${({ image }) => image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    min-height: ${DEFAULT_CARD_HEIGHTS.textBox}px;
    padding: 16px;
    width: 100%;

    ${imageOverlayCss};
`;

export const TextBoxCardTextBox = styled(DefaultText)<{ isBottom?: boolean }>`
    background: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 20px;
    margin: 0;
    padding: 16px;
    position: relative;
    width: 50%;

    ${({ isBottom }) =>
        isBottom &&
        css`
            align-self: flex-end;
            height: 50%;
            padding: 6px 16px;
            width: 100%;
        `}
`;
