import { LibraryContentQueryVariables } from '@@types/graphql/LibraryContentQuery';
import { TabInput } from '@components/_form-elements/TabInput/TabInput';
import { useFilterContent } from './useFilterContent';
import { useLibraryContentPage } from '@domains/Child/Library/LibraryPage/useLibraryContentPage';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { useSelector } from 'react-redux';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import ChipsInput from '@components/_form-elements/ChipsInput/ChipsInput';
import MosaicContent from '@components/MosaicContent/MosaicContent';
import Paginate from '@components/_universal/Paginate/Paginate';
import parseContentToCards from '@utils/parseContentToCards';
import {
    FilterTitle,
    FilterWrapper,
    LibraryTeacherPageWrapper,
    PageTitle,
} from './LibraryTeacherPage.styled';

const SEARCH_LIMIT = 10;

export const LibraryTeacherPage = () => {
    const libraryFilters = useSelector(({ tabs }) => tabs.library);

    const generateVariables = (
        offset?: number,
    ): LibraryContentQueryVariables => ({
        paginationInput: {
            offset: offset ?? 0,
            take: SEARCH_LIMIT,
        },
        sorting: libraryFilters.sortBy?.value,
        topicFilter: libraryFilters.topic,
        typeFilter: { [libraryFilters.type]: true },
    });

    const {
        data,
        hasNextPage,
        loading,
        onLoadMore,
        paginateLoading,
    } = useLibraryContentPage({
        generateVariables,
        libraryFilters,
    });
    const { control, topicFilter, typeFilter } = useFilterContent();

    return (
        <LibraryTeacherPageWrapper>
            <PageTitle>Library</PageTitle>
            <TabInput chips={typeFilter} control={control} name="type" />
            <FilterWrapper>
                <FilterTitle>Topic</FilterTitle>
                <ChipsInput
                    chips={topicFilter}
                    control={control}
                    name="topic"
                />
            </FilterWrapper>
            <Paginate
                loading={paginateLoading}
                queryLoading={loading}
                hasNextPage={hasNextPage}
                onLoadMore={onLoadMore}
            >
                <MosaicContent
                    cards={parseContentToCards(data?.content ?? [])}
                    userType={USER_TYPE.TEACHER}
                />
                <ChatWithMentor />
            </Paginate>
        </LibraryTeacherPageWrapper>
    );
};
