import styled from 'styled-components';
import { Button } from '@components/_universal';

export const EditUserDetailsFormWrapper = styled.div``;

export const StyledButton = styled(Button)`
    justify-content: center;
    margin: 8px auto 0;
    min-width: 276px;
`;
