import styled, { css } from 'styled-components';
import { SubTitle } from '@components/_universal/Typography.styled';

export const StudentsPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeaderPageWrapper = styled.div<{ extended?: boolean }>(
    ({ extended }) => css`
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 88px;
        padding-top: ${extended ? '0' : '34px'};
        width: ${extended ? '100%' : 'calc(100% - 176px)'};
    `,
);

export const PageTitle = styled(SubTitle)(
    ({ theme: { fontWeights } }) => css`
        align-items: center;
        display: flex;
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 0;
    `,
);

export const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
`;
