import { gql, useLazyQuery } from '@apollo/client';
import {
    GetCourseLessonQuery,
    GetCourseLessonQueryVariables,
} from '@@types/graphql/GetCourseLessonQuery';

export const GET_COURSE_LESSON_QUERY = gql`
    query GetCourseLessonQuery($courseId: ID!, $lessonId: ID!) {
        getCourseLesson(courseId: $courseId, lessonId: $lessonId) {
            _id
            name
            description
            consumption {
                id
                consumed
                progress {
                    completedLessons
                    lastCheckpoint
                }
            }
            video {
                _id
                duration
            }
        }
    }
`;

export default (courseId: string, lessonId: string) => {
    return useLazyQuery<GetCourseLessonQuery, GetCourseLessonQueryVariables>(
        GET_COURSE_LESSON_QUERY,
        {
            onError: () => {},
            variables: { courseId: courseId, lessonId: lessonId },
            fetchPolicy: 'no-cache',
        },
    );
};
