import React from 'react';
import { IconName } from '@@types/CommonTypes';
import IconManager from '@components/_universal/IconManager/IconManager';
import {
    DaysText,
    InnerWrapper,
    TrialInformationWrapper,
    TrialText,
} from './TrialInformation.styled';

export interface IProps {
    backgroundIcon?: IconName;
    className?: string;
    daysText: string;
    size?: number;
    trialText: string;
    varaint?: 'default' | 'large';
}

const TrialInformation: React.FC<IProps> = ({
    backgroundIcon = 'TrailLightFrame',
    className,
    daysText,
    size = 104,
    trialText,
    varaint = 'default',
}) => {
    return (
        <TrialInformationWrapper className={className}>
            <IconManager name={backgroundIcon} size={size} />
            <InnerWrapper>
                <DaysText varaint={varaint}>{daysText}</DaysText>
                <TrialText varaint={varaint}>{trialText}</TrialText>
            </InnerWrapper>
        </TrialInformationWrapper>
    );
};

export default TrialInformation;
