import { Observable } from '@apollo/client';
import logout from './logout';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';

export default (promise: Promise<unknown>) => {
    return new Observable((subscriber) => {
        promise.then(
            (value: unknown) => {
                if (subscriber.closed) {
                    return;
                }
                subscriber.next(value);
                subscriber.complete();
            },
            (err: unknown) => {
                logout();
                subscriber.error(err);
            },
        );
    });
};
