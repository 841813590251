import React from 'react';
import { IconManager } from '@components/_universal';
import { NavLink } from 'react-router-dom';
import logout from '@utils/logout';
import paths from '@shared/paths';
import {
    AccountNavigationWrapper,
    InnerWrapper,
    Link,
    LogoutButton,
} from './AccountNavigation.styled';

interface IProps {}

const LINKS = [
    {
        label: 'Account information',
        path: paths.account_information_teacher,
    },
    {
        label: 'Notification settings',
        path: paths.account_notification_settings_teacher,
    },
    {
        label: 'Help & FAQ',
        path: paths.account_help_and_faq_teacher,
    },
    {
        label: 'Legal',
        path: paths.account_legal_teacher,
    },
];

const AccountNavigation: React.FC<IProps> = () => {
    return (
        <AccountNavigationWrapper>
            <InnerWrapper>
                {LINKS.map(({ label, path }, index) => (
                    <Link
                        activeClassName={'accountNavActive'}
                        as={NavLink}
                        key={index}
                        to={path}
                    >
                        {label}
                    </Link>
                ))}
                <LogoutButton
                    buttonType={'secondary'}
                    onPress={() => logout()}
                    size={'small'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        isLeft: true,
                        type: 'Logout',
                    }}
                >
                    log out
                </LogoutButton>
            </InnerWrapper>
            <IconManager name={'HandsParent'} size={233} sizeY={156} />
        </AccountNavigationWrapper>
    );
};

export default AccountNavigation;
