import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import paths from '@shared/paths';
import {
    InformationText,
    SignupSuccessfulPaymentWrapper,
    PaymentSuccessIcon,
    StyledTitle,
    TextWrapper,
} from './SignupSuccessfulPaymentPage.styled';

interface IProps {}

const SignupSuccessfulPaymentPage: React.FC<IProps> = () => {
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            history.push(paths.dashboard_parent());
        }, 5000);
    }, []);

    return (
        <SignupSuccessfulPaymentWrapper>
            <PaymentSuccessIcon size={455} name={'PaymentSuccessful'} />
            <TextWrapper>
                <StyledTitle>Successful payment setup</StyledTitle>
                <InformationText>
                    Your payment was successful and your plan has been
                    activated. Thank you for choosing 8billionideas.
                </InformationText>
            </TextWrapper>
        </SignupSuccessfulPaymentWrapper>
    );
};

export default SignupSuccessfulPaymentPage;
