import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import {
    BigHeader,
    DefaultText,
    SubTitle,
    Title,
} from '@components/_universal/Typography.styled';

export const WrapperVideoPlayer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
`;

export const LoaderWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const VideoViewPageWrapper = styled.div``;

export const ListTitle = styled(BigHeader)`
    display: inline-flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: -8px 0 16px;
    position: relative;
`;

export const NavigationHeaderIcon = styled(IconManager)`
    left: 100%;
    position: absolute;
    top: -34px;
`;

export const PageTitle = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    padding-top: 34px;
`;

export const SectionHeader = styled.div`
    align-items: center;
    display: flex;
    margin: 80px 0 48px;
`;

export const SectionTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 24px 0 0;
    min-height: 44px;
`;

export const DescriptionTitle = styled(BigHeader)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 30px 0 16px;
`;

export const DescriptionText = styled(DefaultText)`
    margin-bottom: 100px;
`;

export const InnerWrapper = styled.div`
    display: flex;
`;

export const NavigationListWrapper = styled.div`
    margin-left: 32px;
`;

export const VideoWrapper = styled.div`
    width: 100%;
`;
