import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import SimpleBar from 'simplebar-react';

export const FilterSidebarWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background-color: ${colors.background_primary};
        border-bottom-left-radius: ${borderRadius.circle}px;
        border-top-left-radius: ${borderRadius.circle}px;
        height: 100%;
        overflow-y: auto;
        padding: 150px 28px 48px 48px;
    `,
);

export const SimpleBarWrapper = styled(SimpleBar)(
    ({ theme: { colors } }) => css`
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: calc(100vh - 260px);
        padding-right: 20px;
        width: 100%;

        .simplebar-scrollbar::before {
            background: ${colors.primary};
            width: 6px;
        }
        .simplebar-visible::before {
            opacity: 1;
        }
        .simplebar-track::before {
            background: ${colors.background_tertiary};
            content: '';
            height: 98%;
            left: 4px;
            position: absolute;
            top: 5px;
            width: 2px;
        }
    `,
);

export const FilterWrapper = styled.div`
    margin-bottom: 24px;
`;

export const FilterTitle = styled(DefaultText)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.secondary};
        font-size: ${fontSizes.xs}px;
        font-weight: ${fontWeights.bold};
        margin-bottom: 16px;
        text-transform: uppercase;
    `,
);
