import React, { useEffect } from 'react';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import Select from '@components/_form-elements/Select/Select';
import { FormRow, InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';
import { GENDER } from '@@types/graphql/GraphqlGlobalTypes';
import { GENDERS } from '@utils/shared/constans';
import { Input } from '@components/_form-elements';
import { SelectType } from '@@types/CommonTypes';
import { clearChildId } from '@state/_redux/childManagement/actions';
import { useChangeEmailMutation } from '@state/settings/mutations';
import { useChildQuery } from '@state/childManagement/queries';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useUpdateChildAccountMutation } from '@state/childManagement/mutations';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    EditUserDetailsFormWrapper,
    StyledButton,
} from './EditUserDetailsForm.styled';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

interface IProps {
    callback?: () => void;
}

interface IForm {
    birthday: string;
    email: string;
    gender: SelectType<GENDER>;
    name: string;
    surname: string;
}

const EditUserDetailsForm: React.FC<IProps> = ({ callback }) => {
    const dispatch = useDispatch();
    const childId = useSelector(
        (state) => state.childManagement.childId,
    ) as number;
    const { data: childData } = useChildQuery(childId);
    const [
        updateChildAccount,
        { loading: loadingChildAccount },
    ] = useUpdateChildAccountMutation(callback);

    const [
        changeEmailMutation,
        { loading: changeEmailLoading },
    ] = useChangeEmailMutation();

    const { control, errors, handleSubmit, register, reset } = useForm<IForm>({
        resolver: yupResolver(validation),
        mode: 'onBlur',
    });

    useEffect(() => {
        reset({
            name: childData?.getChildForParent.name,
            surname: childData?.getChildForParent.surname,
            email: childData?.getChildForParent.email,
            gender: {
                label: childData?.getChildForParent.gender as string,
                value: childData?.getChildForParent.gender as GENDER,
            },
            birthday: dayjs(childData?.getChildForParent.birthday).format(
                'DD/MM/YYYY',
            ),
        });
    }, [childData]);

    const onSubmit = (formValues: IForm) => {
        updateChildAccount({
            variables: {
                childId: childId,
                data: {
                    name: formValues.name,
                    surname: formValues.surname,
                    gender: formValues.gender?.value as GENDER,
                    birthday: dayjs(
                        formValues.birthday,
                        'DD/MM/YYYY',
                    ).toISOString(),
                },
            },
        });

        formValues.email !== childData?.getChildForParent.email &&
            changeEmailMutation({
                variables: {
                    input: {
                        newEmail: formValues.email,
                    },
                },
            });

        dispatch(clearChildId());
    };

    return (
        <EditUserDetailsFormWrapper>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.name}
                        label={'Name'}
                        name={'name'}
                        placeholder={'Enter name'}
                        register={register}
                    />
                </InputWrapper>
                <InputWrapper>
                    <InputForm
                        errors={errors.surname}
                        label={'Surname'}
                        name={'surname'}
                        placeholder={'Enter surname'}
                        register={register}
                    />
                </InputWrapper>
            </FormRow>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.email}
                        label={'Child email address'}
                        name={'email'}
                        placeholder={'e.g. joedoe@mail.com'}
                        register={register}
                        type={'email'}
                    />
                </InputWrapper>
            </FormRow>
            <FormRow>
                <InputWrapper>
                    <SelectForm
                        control={control}
                        errors={errors.gender}
                        key={JSON.stringify(
                            childData?.getChildForParent.gender,
                        )}
                        isSearchable
                        label={'Gender'}
                        name={'gender'}
                        options={GENDERS}
                        placeholder={'Select...'}
                        register={register}
                    />
                </InputWrapper>
                <InputWrapper>
                    <InputForm
                        errors={errors.birthday}
                        label={'Date of birth'}
                        name={'birthday'}
                        placeholder={'DD/MM/YYYY'}
                        register={register}
                        maxLength={10}
                    />
                </InputWrapper>
            </FormRow>

            <StyledButton
                disabled={loadingChildAccount || changeEmailLoading}
                onPress={handleSubmit(onSubmit)}
            >
                Save
            </StyledButton>
        </EditUserDetailsFormWrapper>
    );
};

const InputForm = FieldMessage(Input);
const SelectForm = FieldMessage(Select);

export default EditUserDetailsForm;
