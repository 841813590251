import React, { useRef } from 'react';
import { ModalContent } from '@components/_modals/GlobalModals.styled';
import { StyledTitle } from './SetPasswordModal.styled';
import { useOnClickOutside } from '@utils/hooks';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import Modal from '@components/_universal/Modal/Modal';
import SetPasswordForm from '@domains/Parent/Account/_forms/SetPasswordForm/SetPasswordForm';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const SetPasswordModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsModalOpen(false));

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={() => setIsModalOpen(false)} />
                <StyledTitle>Set password</StyledTitle>

                <SetPasswordForm closeModal={() => setIsModalOpen(false)} />
            </ModalContent>
        </Modal>
    );
};

export default SetPasswordModal;
