import React from 'react';
import ModalConfirmation from '@components/_modals/ModalConfirmation/ModalConfirmation';

export interface IProps {
    content: string;
    loading?: boolean;
    onDelete: () => void;
    setIsModalOpen: (state: boolean) => void;
    title: string;
}

const DeleteAccountModal: React.FC<IProps> = ({
    content,
    loading,
    onDelete,
    setIsModalOpen,
    title,
}) => {
    return (
        <ModalConfirmation
            closeOutside
            firstButton={{
                onClick: () => setIsModalOpen(false),
                text: 'Cancel',
                type: 'secondary',
            }}
            onClose={() => setIsModalOpen(false)}
            secondButton={{
                onClick: onDelete,
                text: 'Delete',
                type: 'primaryError',
                disabled: loading,
            }}
            title={title}
        >
            {content}
        </ModalConfirmation>
    );
};

export default DeleteAccountModal;
