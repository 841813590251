import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { Link } from 'react-router-dom';

export const ArticleWrapper = styled(Link)`
    display: block;
    text-decoration: none;
    width: 100%;

    &:hover,
    &:focus {
        text-decoration: none;
    }
`;

export const ArticleType = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 4px;
    margin-top: -1px;
    padding-bottom: 1px;
    text-transform: uppercase;
`;

export const StyledTitle = styled(DefaultText)`
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    cursor: pointer;
    display: -webkit-box;
    display: block;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 0;
    max-height: 74px;
    overflow: hidden;
    padding-bottom: 1px;
    text-overflow: ellipsis;

    &:hover {
        text-decoration: underline;
        text-underline-position: under;
    }
`;
