import { gql, useMutation } from '@apollo/client';
import { FragmentGetLiveExperienceQuery } from '@@types/graphql/FragmentGetLiveExperienceQuery';
import {
    RateLiveExperienceMutation,
    RateLiveExperienceMutationVariables,
} from '@@types/graphql/RateLiveExperienceMutation';

const RATE_LIVE_EXPERIENCE_MUTATION = gql`
    mutation RateLiveExperienceMutation(
        $liveExperienceId: String!
        $rate: Int!
        $comment: String!
    ) {
        rateLiveExperience(
            liveExperienceId: $liveExperienceId
            rate: $rate
            comment: $comment
        ) {
            id
            contentId
            rate
            comment
            postedBy
        }
    }
`;

const fragment = gql`
    fragment FragmentGetLiveExperienceQuery on LiveExperience {
        isRated
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<
        RateLiveExperienceMutation,
        RateLiveExperienceMutationVariables
    >(RATE_LIVE_EXPERIENCE_MUTATION, {
        onCompleted: () => {
            onCompleted?.();
        },
        update: (cache, { data }) => {
            if (!data) return;
            cache.writeFragment<FragmentGetLiveExperienceQuery>({
                id: `LiveExperience:${data.rateLiveExperience.contentId}`,
                fragment,
                data: {
                    isRated: true,
                },
            });
        },
        onError: () => {},
    });
};
