import React, { useEffect } from 'react';
import { setIsFirstLogin } from '@state/_redux/signup/actions';
import { useDispatch } from 'react-redux';
import DesktopSignupPricingPage from './_desktop/DesktopSignupPricingPage';
import MobileSignupPricingPage from './_mobile/MobileSignupPricingPage';
import useMobile from '@utils/hooks/useMobile';

interface IProps {}

const SignupPricingPage: React.FC<IProps> = () => {
    const { isMobile } = useMobile();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setIsFirstLogin(false));
        };
    }, []);

    return isMobile ? (
        <MobileSignupPricingPage />
    ) : (
        <DesktopSignupPricingPage />
    );
};

export default SignupPricingPage;
