import React from 'react';
import {
    ActionButton,
    ContentWrapper,
    LegalTeacherPageWrapper,
    PageTitle,
    SectionDivider,
    SectionText,
    SectionWrapper,
} from './LegalTeacherPage.styled';
import {
    PageHeader,
    SectionTitle,
} from '@domains/Teacher/Account/AccountTeacherPage/AccountTeacherPage.styled';
import { externalLinks } from '@shared/paths';

interface IProps {}

const LegalTeacherPage: React.FC<IProps> = () => {
    return (
        <LegalTeacherPageWrapper>
            <PageHeader>
                <PageTitle>Legal</PageTitle>
            </PageHeader>
            <SectionWrapper>
                <ContentWrapper>
                    <SectionTitle>Terms & Conditions</SectionTitle>
                    <SectionText>
                        Please read these terms and conditions of use carefully
                        before using our platform.
                    </SectionText>
                </ContentWrapper>
                <ActionButton
                    buttonType={'secondary'}
                    onPress={() => window.open(externalLinks.terms, '_blank')}
                    size={'small'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        isLeft: true,
                        type: 'RedirectIcon',
                    }}
                >
                    Read More
                </ActionButton>
            </SectionWrapper>
            <SectionDivider />
            <SectionWrapper>
                <ContentWrapper>
                    <SectionTitle>Privacy Policy</SectionTitle>
                    <SectionText>
                        Please read our privacy policy carefully to get a clear
                        understanding of how we collect, use and protect your
                        personal information.
                    </SectionText>
                </ContentWrapper>
                <ActionButton
                    buttonType={'secondary'}
                    onPress={() =>
                        window.open(externalLinks.privacy_policy, '_blank')
                    }
                    size={'small'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        isLeft: true,
                        type: 'RedirectIcon',
                    }}
                >
                    Read More
                </ActionButton>
            </SectionWrapper>
        </LegalTeacherPageWrapper>
    );
};

export default LegalTeacherPage;
