import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { BadgeBorderProps } from './BadgeBorder';

export const BadgeBorderWrapper = styled.div<{
    variant?: BadgeBorderProps['variant'];
}>(
    ({ theme, variant }) => css`
        align-items: center;
        border-radius: ${theme.borderRadius.default}px;
        border: 1px solid ${theme.colors.secondary};
        display: inline-flex;
        min-width: 68px;
        padding: 5px 7px;

        ${variant === 'light' && `opacity: 0.5`}
        ${variant === 'gray' && `border-color:  ${theme.colors.stroke}`}
    `,
);

export const BadgeBorderText = styled(DefaultText)<{
    variant?: BadgeBorderProps['variant'];
}>(
    ({ theme, variant }) => css`
        color: ${({ theme }) => theme.colors.secondary};
        font-size: ${({ theme }) => theme.fontSizes.xxs}px;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        line-height: 1.4;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        width: 100%;

        ${variant === 'gray' && `color:  ${theme.colors.stroke}`}
    `,
);
