import React from 'react';
import ChatWithSupport from '@components/_universal/ChatWithSupport/ChatWithSupport';
import {
    Content,
    FooterBar,
    HeaderBar,
    NavigationBar,
    ParentAccountLayoutWrapper,
} from './ParentAccountLayout.styled';

interface IProps {}

const ParentAccountLayout: React.FC<IProps> = ({ children }) => {
    return (
        <ParentAccountLayoutWrapper>
            <HeaderBar trialIcon />
            <NavigationBar />
            <Content>{children}</Content>
            <FooterBar isBackpack isLogo />
            <ChatWithSupport />
        </ParentAccountLayoutWrapper>
    );
};

export default ParentAccountLayout;
