import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import {
    changeLockLayout,
    setEndedModal,
    setLeaveUploadStudentsModal,
    setLoginLayoutAnimation,
    setSearchModal,
    setStudentsUploadModal,
    setTooManyStudnetsUploadModal,
    setTypeformModal,
} from './actions';

export default createReducer(initialState)
    .handleAction(changeLockLayout, (state, { payload }) => ({
        ...state,
        lockLayout: payload
            ? state.lockLayout + 1
            : state.lockLayout > 0
            ? state.lockLayout - 1
            : 0,
    }))
    .handleAction(setSearchModal, (state, { payload }) => ({
        ...state,
        searchModal: payload,
    }))
    .handleAction(setEndedModal, (state, { payload }) => ({
        ...state,
        trialEndedModal: payload,
    }))
    .handleAction(setLoginLayoutAnimation, (state, { payload }) => ({
        ...state,
        loginLayoutAnimation: payload,
    }))
    .handleAction(setLeaveUploadStudentsModal, (state, { payload }) => ({
        ...state,
        leaveUploadStudentsModal: payload,
    }))
    .handleAction(setTooManyStudnetsUploadModal, (state, { payload }) => ({
        ...state,
        tooManyStudnetsUploadModal: payload,
    }))
    .handleAction(setStudentsUploadModal, (state, { payload }) => ({
        ...state,
        studentsUploadModal: payload,
    }))
    .handleAction(setTypeformModal, (state, { payload }) => ({
        ...state,
        typeformModal: payload,
    }));
