import { useEffect, useMemo, useState } from 'react';
import { AssignStudentsModal } from './_modals/AssignStudentsModal/AssignStudentsModal';
import { capitalize } from '@utils/helpers';
import { DeleteStudentFromGroupModal } from './_modals/DeleteStudentFromGroup/DeleteStudentFromGroupModal';
import { EditStudentsGroupModal } from './_modals/EditStudentsGroupModal/EditStudentsGroupModal';
import { EmptyGroupState } from '@domains/Teacher/Groups/_components/EmptyGroupState/EmptyGroupState';
import { FakeTooltip } from '@domains/Teacher/Groups/_components/FakeTooltip/FakeTooltip';
import { IconClose, IconSeparator, UploadWrapper } from './GroupPage.styled';
import { IndeterminateCheckbox } from '@domains/Teacher/Students/StudentsPage/_components/IndeterminateCheckbox/IndeterminateCheckbox';
import { PageWrapper } from '@domains/Teacher/Students/_components/PageWrapper/PageWrapper';
import { StudentsList } from '@domains/Teacher/Students/StudentsPage/_components/StudentsList/StudentsList';
import { StudentsTableItem } from '@domains/Teacher/Students/StudentsPage/StudentsPage';
import { TableOptions } from '@domains/Teacher/Students/StudentsPage/_components/TableOptions/TableOptions';
import { UploadBox } from '@components/_universal/UploadBox/UploadBox';
import { useParams } from 'react-router';
import { USER_STATUS } from '@@types/graphql/GraphqlGlobalTypes';
import { useStudentsGroupDetails } from '@state/students/queries/useStudentsGroupDetails';
import { useStudentsPage } from '@domains/Teacher/Students/StudentsPage/useStudentsPage';
import {
    Column,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useTable,
} from 'react-table';

const PAGE_LIMIT = 25;

export const GroupPage = () => {
    const { groupId } = useParams<{ groupId: string }>();
    const [editGroupModal, setEditGroupModal] = useState(false);
    const [assignStudents, setAssignStudents] = useState(false);
    const [isRemoveStudentsModalOpen, setIsRemoveStudentsModalOpen] = useState(
        false,
    );
    const [isOpen, setIsOpen] = useState(false);

    const { data: groupData } = useStudentsGroupDetails({
        groupId: Number(groupId),
    });

    const {
        filters,
        refetch,
        search,
        setFilters,
        setPaginationState,
        setSearch,
        studentsCountLoading,
        studentsFilteredCount,
        studentsList,
        studentsListLoading,
        studentsUploadCount,
    } = useStudentsPage(+groupId);

    const numberOfFilteredPages =
        studentsFilteredCount && Math.ceil(studentsFilteredCount / PAGE_LIMIT);

    const columns = useMemo<Column<StudentsTableItem>[]>(
        () => [
            {
                id: 'select',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <IndeterminateCheckbox
                        name={'indeterminated'}
                        {...getToggleAllRowsSelectedProps()}
                    />
                ),
                Cell: ({ row }: any) => (
                    <div onClick={(e) => e?.stopPropagation()}>
                        <IndeterminateCheckbox
                            name={row.id}
                            {...row.getToggleRowSelectedProps()}
                        />
                    </div>
                ),
                width: 50,
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Surname',
                accessor: 'surname',
            },
            {
                Header: 'E-mail',
                accessor: 'email',
                width: 300,
            },
            {
                Header: 'Class',
                accessor: 'class',
                width: 100,
            },
            {
                Header: 'Year',
                accessor: 'year',
                width: 100,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell }: any) => {
                    if (cell.value === USER_STATUS.BANNED) {
                        return (
                            <span className={'banned'}>
                                {capitalize(cell.value)}
                            </span>
                        );
                    }
                    if (cell.value === USER_STATUS.PENDING) {
                        return (
                            <span className={'pending'}>
                                {capitalize(cell.value)}
                            </span>
                        );
                    }
                    return capitalize(cell.value);
                },
            },
        ],
        [],
    );
    const data = useMemo<StudentsTableItem[]>(
        () =>
            studentsList?.map((student) => ({
                class: student.class?.className,
                email: student.email,
                id: student.id,
                name: student.name,
                status: student.status,
                surname: student.surname,
                year: student.class?.year,
            })) ?? [],
        [studentsList],
    );

    const tableProps = useTable<StudentsTableItem>(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: PAGE_LIMIT,
            },
            manualPagination: true,
            pageCount: numberOfFilteredPages,
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
    );

    const selectedStudentsIds = tableProps.selectedFlatRows.map(
        (item) => item?.original?.id,
    );
    const showRemoveStudentsButton = selectedStudentsIds?.length;

    const getFilters = (
        className?: string,
        groupId?: number,
        year?: string,
    ) => {
        setFilters({
            className,
            year,
        });
    };

    useEffect(() => {
        setPaginationState({
            pageIndex: tableProps.state.pageIndex,
            pageSize: tableProps.state.pageSize,
        });
    }, [tableProps.state.pageIndex]);

    useEffect(() => {
        tableProps.gotoPage(0);
    }, [search, filters]);

    return (
        <PageWrapper
            setSearch={setSearch}
            studentsCountLoading={studentsCountLoading}
            studentsUploadCount={studentsUploadCount}
            title="Groups"
        >
            <>
                {!!studentsUploadCount && (
                    <TableOptions
                        getFilters={getFilters}
                        isGroupView
                        firstButtonProps={{
                            onClick: () => {
                                setIsOpen(true);
                            },
                            title: 'assign students',
                        }}
                        removeStudents={setIsRemoveStudentsModalOpen}
                        secondButtonProps={{
                            onClick: () => setEditGroupModal(true),
                            title: 'edit group title',
                        }}
                        showRemoveStudentsButton={!!showRemoveStudentsButton}
                        title={`Group '${groupData?.details.title}'`}
                        groupName={groupData?.details?.title}
                    />
                )}
                {isOpen && (
                    <UploadWrapper>
                        <UploadBox
                            onClick={() => setAssignStudents(true)}
                            children={
                                <IconSeparator name={'Separator'} size={21} />
                            }
                            variant="group_page"
                        />
                        <FakeTooltip />
                        <IconClose
                            name={'Close'}
                            onClick={() => setIsOpen(false)}
                            size={24}
                        />
                    </UploadWrapper>
                )}
                {!studentsListLoading && !data.length && !search ? (
                    <EmptyGroupState />
                ) : (
                    <StudentsList
                        data={data}
                        isExistStudent={!!studentsUploadCount}
                        isGroupView
                        refetch={refetch}
                        studentsListLoading={studentsListLoading}
                        tableProps={tableProps}
                    />
                )}
                {assignStudents && (
                    <AssignStudentsModal
                        groupId={groupId}
                        groupStudentsList={studentsList}
                        onClose={() => {
                            setAssignStudents(false);
                            setIsOpen(false);
                        }}
                        refetch={refetch}
                    />
                )}

                {editGroupModal && (
                    <EditStudentsGroupModal
                        defaultData={{
                            groupTitle: groupData?.details.title ?? '',
                        }}
                        onClose={() => setEditGroupModal(false)}
                        refetch={refetch}
                    />
                )}
                {isRemoveStudentsModalOpen && (
                    <DeleteStudentFromGroupModal
                        groupId={groupId}
                        onClose={() => setIsRemoveStudentsModalOpen(false)}
                        refetch={refetch}
                        studentsId={selectedStudentsIds}
                    />
                )}
            </>
        </PageWrapper>
    );
};
