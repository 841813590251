import styled from 'styled-components';
import Navigation from '@components/_global/Navigation/ChildNavigation/ChildNavigation';
import ChildHeader from '@components/_global/Header/ChildHeader/ChildHeader';
import FilterSidebar from '@components/_global/FilterSidebar/FilterSidebar';

export const LibraryLayoutWrapper = styled.div`
    display: grid;
    grid-template-areas:
        'navigation content sidebar'
        'navigation content sidebar'
        'navigation content sidebar';
    grid-template-columns: 120px 1fr 320px;
    grid-template-rows: 30px 1fr;
    min-height: 100%;
    width: 100%;
`;

export const HeaderBar = styled(ChildHeader)`
    grid-area: header;
`;

export const NavigationBar = styled(Navigation)`
    grid-area: navigation;
`;

export const Content = styled.div`
    display: grid;
    grid-area: content;
    grid-template-columns: auto;
    grid-template-rows: 1fr 90px;
`;

export const Sidebar = styled(FilterSidebar)`
    grid-area: sidebar;
    position: fixed;
    right: 0;
    width: 320px;
`;
