import React from 'react';
import paths from '@shared/paths';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { IOnboardingForm } from '@domains/Auth/Onboarding/Onboarding';
import {
    PaginationItem,
    PaginationItemWrapper,
    PaginationWrapper,
    PaginationText,
} from './Pagination.styled';

interface IProps {}

const Pagination: React.FC<IProps> = ({ children }) => {
    const { pathname } = useLocation();
    const { errors, watch } = useFormContext<IOnboardingForm>();
    const formValues = watch();

    return (
        <PaginationWrapper>
            <PaginationItemWrapper>
                <PaginationItem
                    active={pathname === paths.choose_avatar}
                    $fill={true}
                />
                <PaginationItem
                    active={pathname === paths.set_password}
                    $fill={
                        !!(
                            !errors.confirmPassword?.message &&
                            formValues.confirmPassword
                        )
                    }
                />
                <PaginationItem
                    active={pathname === paths.agree_rules}
                    $fill={formValues.agreeRules}
                />
            </PaginationItemWrapper>
            <PaginationText>{children}</PaginationText>
        </PaginationWrapper>
    );
};

export default Pagination;
