import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { Button } from '@components/_universal';

export const NotifyWrapper = styled.div`
    background: ${({ theme }) => theme.colors.background_quaternary};
    border-radius: ${({ theme }) => `${theme.borderRadius.card}px `};
    display: grid;
    height: 340px;
    margin-bottom: 48px;
    padding: 56px 100px 56px;
    place-items: center;
    position: relative;
`;

export const NotifyForm = styled.form`
    display: grid;
    grid-gap: 8px;
    place-items: center;
    width: 608px;
`;

export const StyledTitle = styled(DefaultText)`
    text-align: center;
`;

export const ButtonWrapper = styled.div``;

export const NotifyButton = styled(Button)`
    width: 240px;
`;
