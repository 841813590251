import React from 'react';
import {
    BannedDescription,
    BannedTitle,
    BannedWrapper,
    StyledButton,
} from './Banned.styled';

interface IProps {}

const Banned: React.FC<IProps> = () => {
    return (
        <BannedWrapper>
            <BannedTitle>It seems that you’ve been banned</BannedTitle>
            <BannedDescription>
                It is likely that your behaviour on our platform was not in line
                with the rules and you were flagged to ban by one of our
                mentors. If you would like to continue using our platform or
                think there is a mistake, please contact us.
            </BannedDescription>
            <StyledButton
                buttonType={'primary'}
                onPress={() => console.log('clicked')}
            >
                Contact us
            </StyledButton>
        </BannedWrapper>
    );
};

export default Banned;
