import { ADMIN_URL, MENTOR_URL } from '@shared/constants';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import paths from '@shared/paths';
import {
    setIsFirstLogin,
    setSkipSetPassword,
} from '@state/_redux/signup/actions';
import {
    AUTH_OPERATION_TYPE,
    USER_STATUS,
    USER_TYPE,
} from '@@types/graphql/GraphqlGlobalTypes';

interface IData {
    sessionToken: string;
    refreshToken: string;
    user: {
        id: number;
        type: USER_TYPE;
        status: USER_STATUS;
        onboardingCompletedAt: string | null;
        hasPassword: boolean;
    };
    operationType: AUTH_OPERATION_TYPE;
}

interface IProps {
    data: IData;
    isFirstLogin?: boolean;
    timeout?: number;
}

export default () => {
    const history = useHistory();
    const dispatch = useDispatch();

    return ({ data, isFirstLogin, timeout = 3500 }: IProps) => {
        const isAdmin = data.user.type === USER_TYPE.ADMIN;
        const isMentor = data.user.type === USER_TYPE.MENTOR;
        const isTeacher = data.user.type === USER_TYPE.TEACHER;

        const isAdminAsTeacher = LocalStorageManager.getValue(
            'adminSessionToken',
        );

        if (isAdmin || isMentor) {
            LocalStorageManager.setValue(
                'adminSessionToken',
                data.sessionToken,
            );
            LocalStorageManager.setValue(
                'adminRefreshToken',
                data.refreshToken,
            );
        } else {
            LocalStorageManager.setValue('sessionToken', data.sessionToken);
            LocalStorageManager.setValue('refreshToken', data.refreshToken);
        }
        dispatch(setSkipSetPassword(false));

        setTimeout(() => {
            if (data.user.type === USER_TYPE.PARENT)
                if (isFirstLogin) {
                    dispatch(setIsFirstLogin(true));
                    history.push(paths.signup_verified);
                } else {
                    history.push(paths.dashboard_parent());
                }
            else if (!isAdminAsTeacher && (isAdmin || isMentor)) {
                window.location.href = isMentor ? MENTOR_URL : ADMIN_URL;
            } else if (isTeacher || isAdminAsTeacher) {
                history.push(paths.dashboard_teacher);
            } else {
                data.user.onboardingCompletedAt
                    ? history.push(paths.dashboard)
                    : history.push(paths.onboarding);
            }
        }, timeout);
    };
};
