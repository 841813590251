import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { useHistory } from 'react-router';
import paths from '@shared/paths';
import {
    CreateStudentsGroup,
    CreateStudentsGroupVariables,
} from '@@types/graphql/CreateStudentsGroup';

export const CREATE_STUDENTS_GROUP = gql`
    mutation CreateStudentsGroup($input: CreateStudentsGroupInput!) {
        createStudentsGroup(input: $input) {
            id
        }
    }
`;

export const useCreateStudentsGroup = (callback: () => void) => {
    const history = useHistory();
    return useMutation<CreateStudentsGroup, CreateStudentsGroupVariables>(
        CREATE_STUDENTS_GROUP,
        {
            onCompleted: (payload) => {
                callback();
                notify('You have successfully added this group.')[
                    ToastTypes.SUCCESS
                ]();
                history.push(
                    paths.students_group_teacher(
                        payload.createStudentsGroup.id.toString(),
                    ),
                );
            },
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
        },
    );
};
