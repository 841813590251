import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { useHistory } from 'react-router-dom';
import paths from '@shared/paths';
import {
    CreateParentAccountMutation,
    CreateParentAccountMutationVariables,
} from '@@types/graphql/CreateParentAccountMutation';

const CREATE_PARENT_ACCOUNT_MUTATION = gql`
    mutation CreateParentAccountMutation($input: CreateParentAccountInput!) {
        createParentAccount(input: $input) {
            id
        }
    }
`;

export default () => {
    const history = useHistory();

    return useMutation<
        CreateParentAccountMutation,
        CreateParentAccountMutationVariables
    >(CREATE_PARENT_ACCOUNT_MUTATION, {
        onCompleted: () => {
            history.replace(paths.signup_register_success);
        },
        onError: (error) => {
            const message =
                error.message === 'Email is already taken'
                    ? 'E-mail is already taken. Please use a different one.'
                    : error.message;
            notify(message)[ToastTypes.ERROR]();
        },
    });
};
