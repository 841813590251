import styled from 'styled-components';
import { SubTitle } from '@components/_universal/Typography.styled';
import IconManager from '@components/_universal/IconManager/IconManager';

export const IconArrowWrapper = styled(IconManager)`
    transition: ${({ theme }) => theme.transitions.default}s;
    will-change: transform;
`;

export const DashboardBottomCardWrapper = styled.div`
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    box-shadow: ${({ theme }) => theme.light_green_shadow};
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 194px;
    padding: 40px 0;
    transition: all ${({ theme }) => theme.transitions.default}s;
    width: 100%;

    &:nth-child(1) {
        background-color: ${({ theme }) => theme.colors.course};
    }

    &:nth-child(2) {
        background-color: ${({ theme }) => theme.colors.live_experience};
        margin: 0 32px;
    }

    &:nth-child(3) {
        background-color: ${({ theme }) => theme.colors.video};
    }

    &:hover {
        cursor: ${({ theme }) => theme.cursors.pointer};
        box-shadow: ${({ theme }) => theme.dark_green_shadow};

        ${IconArrowWrapper} {
            transform: translateX(8px);
        }
    }
`;

export const CardTitle = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 20px 0 16px;
`;
