import React from 'react';
import { ColorType, IconName } from '@@types/CommonTypes';
import { RuleText, RuleTitleWrapper, RuleWrapper } from './Rule.styled';
import Badge from '@components/_universal/Badge/Badge';

export interface IProps {
    ruleCard: React.ReactNode;
    text: string;
    badgeProps: {
        backgroundColor: ColorType;
        image: {
            name: IconName;
            size: number;
        };
        textColor?: ColorType;
    };
}

const Rule: React.FC<IProps> = ({ badgeProps, ruleCard, text }) => {
    return (
        <RuleWrapper>
            <RuleTitleWrapper>
                <Badge {...badgeProps}>{text}</Badge>
            </RuleTitleWrapper>
            <RuleText>{ruleCard}</RuleText>
        </RuleWrapper>
    );
};

export default Rule;
