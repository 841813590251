import styled, { css } from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import SimpleBar from 'simplebar-react';
import {
    BigHeader,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const ProgressSidebarWrapper = styled.div`
    height: 100%;
    position: fixed;
    right: 0;
    width: 320px;
`;

export const SimpleBarWrapper = styled(SimpleBar)(
    ({ theme: { colors } }) => css`
        left: 0;
        max-height: calc(100vh - 260px);
        padding: 30px 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        .simplebar-scrollbar::before {
            background: ${colors.primary};
            width: 6px;
        }
        .simplebar-visible::before {
            opacity: 1;
        }
        .simplebar-track::before {
            background: ${colors.background_tertiary};
            content: '';
            height: 98%;
            left: 4px;
            position: absolute;
            top: 5px;
            width: 2px;
        }
    `,
);

export const ProgressSidebarInnerWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 48px;
    width: 100%;
`;

export const TitleWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const Title = styled(BigHeader)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 32px;
        width: 100%;
    `,
);

export const KeepGoingIconManager = styled(IconManager)`
    position: absolute;
    right: -10px;
    top: -25px;
`;

export const ProgressElementsWrapper = styled.div`
    margin-bottom: 40px;
    width: 100%;
`;

export const EmptyTitle = styled(Title)`
    margin-bottom: 24px;
    text-align: center;
`;

export const GhostIcon = styled(IconManager)`
    margin-bottom: 24px;
`;

export const Description = styled(DefaultText)(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.text_secondary};
        font-size: ${fontSizes.s}px;
        line-height: 1.7;
        margin-bottom: 32px;
        text-align: center;
    `,
);

export const StyledButton = styled(Button)``;
