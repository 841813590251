import * as yup from 'yup';

export const errorMessages = {
    CONFIRM_PASSWORD: 'Passwords must match',
    INVALID_EMAIL_FORMAT:
        'Invalid data format. Please enter for e.g. joedoe@mail.com',
    INVALID_DATE_FORMAT: 'Please enter for e.g. DD/MM/YYYY',
    REPEATED_GROUP_TITLE:
        'A group with that title already exists. Please choose another one',
    REQUIRED: 'This field cannot be empty',
};

const dateRegex = /^(((0[1-9]|[12]\d|3[01])(\/)(0[13578]|1[02])(\/)((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)(\/)(0[13456789]|1[012])(\/)((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])(\/)02(\/)((19|[2-9]\d)\d{2}))|(29(\/)02(\/)((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/;
const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
const passwordSymbolsRegex = /^[A-Za-z\d@$!%*#?&]{1,}$/;

export const emailValidation = yup
    .string()
    .email(errorMessages.INVALID_EMAIL_FORMAT)
    .required(errorMessages.REQUIRED);

export const textValidation = yup.string().required(errorMessages.REQUIRED);

export const textGroupValidation = yup
    .string()
    .required(errorMessages.REQUIRED)
    .max(50, 'A group name cannot exceed 50 characters');

export const selectValidation = yup
    .object()
    .shape({
        label: yup.string().required(errorMessages.REQUIRED),
        value: yup.string().required(errorMessages.REQUIRED),
    })
    .nullable(true)
    .required(errorMessages.REQUIRED);

export const dateValidation = yup
    .string()
    .required(errorMessages.REQUIRED)
    .matches(dateRegex, errorMessages.INVALID_DATE_FORMAT);

export const rateValidation = yup.string().trim();

export const passwordValidation = yup
    .string()
    .required(errorMessages.REQUIRED)
    .matches(
        passwordRegex,
        'Must Contain 8 Characters, One Uppercase, One Number',
    );

export const passwordSymbolsValidation = yup
    .string()
    .required(errorMessages.REQUIRED)
    .matches(
        passwordSymbolsRegex,
        'Password contains not allowed character. Allowed characters: @$!%*#?&',
    );

export const confirmPasswordValidation = yup
    .string()
    .oneOf([yup.ref('newPassword'), null], errorMessages.CONFIRM_PASSWORD);

export const genderValidation = yup.mixed().required(errorMessages.REQUIRED);

export const yearValidation = yup
    .object()
    .shape({
        label: yup.string().required(errorMessages.REQUIRED),
        value: yup.string().required(errorMessages.REQUIRED),
    })
    .nullable(true)
    .required(errorMessages.REQUIRED)
    .test(
        'validNumber',
        'Value must be number in range from 1 to 10',
        (data?: { value?: string }) =>
            !!data &&
            !!data.value &&
            Number.isInteger(+data.value) &&
            +data.value <= 20 &&
            +data.value > 0,
    );

export const multiSelectValidation = yup
    .array()
    .min(1)
    .of(
        yup.object().shape({
            label: yup.string().required(errorMessages.REQUIRED),
            value: yup.string().required(errorMessages.REQUIRED),
        }),
    )
    .nullable(true)
    .required(errorMessages.REQUIRED);
