import React, { useState } from 'react';
import { CreateGroupModal } from '@domains/Teacher/Groups/_modals/CreateGroupModal/CreateGroupModal';
import {
    ButtonWrapper,
    EmptyBox,
    EmptyBoxText,
    EmptyGroupsListWrapper,
    StyledButton,
    TextBold,
} from './EmptyGroupsList.styled';

const EmptyGroupsList: React.FC = () => {
    const [showGroupModal, setShowGroupModal] = useState(false);

    return (
        <EmptyGroupsListWrapper>
            <EmptyBox>
                <EmptyBoxText>
                    <TextBold>
                        It seems you haven't added any student groups yet.
                    </TextBold>
                    Create student group by clicking button below. You can
                    assign students on the group page after you created a group.
                </EmptyBoxText>
                <ButtonWrapper>
                    <StyledButton
                        buttonType={'primary'}
                        onPress={() => setShowGroupModal(true)}
                    >
                        create group
                    </StyledButton>
                </ButtonWrapper>
            </EmptyBox>
            {showGroupModal && (
                <CreateGroupModal setIsModalOpen={setShowGroupModal} />
            )}
        </EmptyGroupsListWrapper>
    );
};

export default EmptyGroupsList;
