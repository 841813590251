import { gql, useQuery } from '@apollo/client';
import {
    GetChildLiveExperienceQuery,
    GetChildLiveExperienceQueryVariables,
} from '@@types/graphql/GetChildLiveExperienceQuery';
import { useHistory, useParams } from 'react-router';
import paths from '@shared/paths';

const GET_CHILD_LIVE_EXPERIENCE_QUERY = gql`
    query GetChildLiveExperienceQuery($id: ID!) {
        getLiveExperience(id: $id) {
            _id
            name
            description
            duration
            startDate
            facilitatedBy {
                id
                name
                surname
            }
        }
    }
`;

export default (id: string) => {
    const history = useHistory();
    const { childId } = useParams<{ childId: string }>();

    return useQuery<
        GetChildLiveExperienceQuery,
        GetChildLiveExperienceQueryVariables
    >(GET_CHILD_LIVE_EXPERIENCE_QUERY, {
        variables: {
            id,
        },
        onError: () => {
            history.push(paths.dashboard_parent(childId));
        },
    });
};
