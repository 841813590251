import React from 'react';
import {
    Content,
    FooterBar,
    HeaderBar,
    NavigationBar,
    VideoViewLayoutWrapper,
} from './VideoViewLayout.styled';

interface IProps {}

export const VideoViewLayout: React.FC<IProps> = ({ children }) => {
    return (
        <VideoViewLayoutWrapper>
            <HeaderBar isTransparent trialIcon />
            <NavigationBar borderLine />
            <Content>
                {children}
                <FooterBar isBackpack isLogo />
            </Content>
        </VideoViewLayoutWrapper>
    );
};
export default VideoViewLayout;
