import React from 'react';
import { StyledButton } from './TextButton.styled';

interface IProps {
    className?: string;
    disabled?: boolean;
    htmlType?: 'button' | 'submit';
    onPress?: () => void;
}

const TextButton: React.FC<IProps> = ({
    children,
    className,
    disabled,
    htmlType,
    onPress,
}) => {
    return (
        <StyledButton
            className={className}
            disabled={!!disabled}
            onClick={onPress}
            type={htmlType}
        >
            {children}
        </StyledButton>
    );
};

export default TextButton;
