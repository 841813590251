import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { Button } from '@components/_universal';

export const LegalTeacherPageWrapper = styled.div``;

export const SectionWrapper = styled.div`
    display: flex;
`;

export const PageTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 48px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ActionButton = styled(Button)`
    height: 40px;
    margin-left: auto;
    min-width: 160px;
    padding: 10px 27px;
`;

export const SectionText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 16px 0 40px;
    max-width: 80%;
`;

export const SectionDivider = styled.div`
    background: ${({ theme }) => theme.colors.background_tertiary};
    height: 2px;
    margin: 48px 0;
    width: 100%;
`;
