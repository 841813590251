import { gql, useMutation } from '@apollo/client';
import {
    MarkVideoProgressMutation,
    MarkVideoProgressMutationVariables,
} from '@@types/graphql/MarkVideoProgressMutation';

const MARK_VIDEO_PROGRESS_MUTATION = gql`
    mutation MarkVideoProgressMutation(
        $standaloneVideoId: String!
        $checkpoint: Int!
    ) {
        markStandaloneVideoProgress(
            standaloneVideoId: $standaloneVideoId
            checkpoint: $checkpoint
        ) {
            id
        }
    }
`;

export default () => {
    return useMutation<
        MarkVideoProgressMutation,
        MarkVideoProgressMutationVariables
    >(MARK_VIDEO_PROGRESS_MUTATION);
};
