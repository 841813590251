import React, { useState } from 'react';
import { Input, InputPassword } from '@components/_form-elements';
import { useCustomRegister } from '@utils/hooks';
import { FieldError, useForm } from 'react-hook-form';
import { useLoginWithPasswordMutation } from '@state/auth/mutations';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import paths from '@shared/paths';
import {
    ButtonRow,
    FormRow,
    InputWrapper,
} from '@domains/Auth/_forms/GlobalForm.styled';
import {
    ForgotPassword,
    LoginPasswordFormWrapper,
    StyledButton,
} from './LoginPasswordForm.styled';

interface IForm {
    email: string;
    password: string;
}

const LoginPasswordForm: React.FC = () => {
    const [inputFocused, setInputFocused] = useState(false);

    const { errors, register, handleSubmit } = useForm<IForm>({
        resolver: yupResolver(validation),
        mode: 'onBlur',
    });
    const { refElement: inputEl } = useCustomRegister<HTMLInputElement>(
        register,
    );
    const [
        loginWithPassword,
        { error, loading },
    ] = useLoginWithPasswordMutation();
    const loginError =
        error?.message && error.message !== 'Internal server error';

    const onSubmit = handleSubmit((formValues: IForm) => {
        if (loading) return;
        inputEl.current?.blur();
        loginWithPassword({
            variables: {
                input: {
                    ...formValues,
                },
            },
        });
    });

    return (
        <LoginPasswordFormWrapper noValidate onSubmit={onSubmit}>
            <FormRow>
                <InputWrapper
                    onFocus={() => !inputFocused && setInputFocused(true)}
                >
                    <InputForm
                        backgroundTypeError
                        errors={errors.email}
                        inputType={'secondary'}
                        isValid={!errors.email}
                        label={`email address`}
                        marginBottom={8}
                        name={'email'}
                        placeholder={'e.g. joedoe@mail.com'}
                        register={register}
                        staticPosition
                        type="email"
                    />
                </InputWrapper>
            </FormRow>
            <FormRow>
                <InputWrapper
                    onFocus={() => !inputFocused && setInputFocused(true)}
                >
                    <InputPasswordForm
                        backgroundTypeError
                        errors={
                            errors.password ||
                            (loginError
                                ? ({
                                      message:
                                          'You have entered an invalid email address or password',
                                  } as FieldError)
                                : undefined)
                        }
                        inputType={'secondary'}
                        isValid={!errors.password && !loginError}
                        label={`password`}
                        marginBottom={16}
                        maxLength={256}
                        name={'password'}
                        placeholder={'Enter your password'}
                        register={inputEl}
                        staticPosition
                    />
                </InputWrapper>
                <ForgotPassword to={paths.forgot_password}>
                    Forgot password?
                </ForgotPassword>
            </FormRow>
            <ButtonRow>
                <StyledButton onPress={() => {}}>log in</StyledButton>
            </ButtonRow>
        </LoginPasswordFormWrapper>
    );
};

const InputForm = FieldMessage(Input);
const InputPasswordForm = FieldMessage(InputPassword);

export default LoginPasswordForm;
