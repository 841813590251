import React from 'react';
import { IconManager } from '@components/_universal';
import dayjs from 'dayjs';
import {
    AdditionalInfo,
    EmptyInfo,
    EmptyWrapper,
    FeedbackText,
    FileBox,
    HeaderBox,
    HeaderWrapper,
    Icon,
    InnerWrapper,
    MentorFeedback,
    MentorFeedbackCardWrapper,
    Name,
} from './MentorFeedbackCard.styled';

interface IProps {
    content?: string;
    fileName?: string;
    getFile: () => void;
    mentorName?: string;
    uploadedDate?: string;
}

const MentorFeedbackCard: React.FC<IProps> = ({
    content,
    fileName,
    getFile,
    mentorName,
    uploadedDate,
}) => {
    return (
        <MentorFeedbackCardWrapper>
            {content ? (
                <MentorFeedback>
                    <HeaderWrapper>
                        <HeaderBox>
                            <Icon name={'Mentor'} size={46} />
                            <InnerWrapper>
                                <Name>{mentorName}</Name>
                                <AdditionalInfo>Your mentor</AdditionalInfo>
                            </InnerWrapper>
                        </HeaderBox>
                        <FileBox onClick={getFile}>
                            <Icon name={'MentorDownload'} size={46} />
                            <InnerWrapper>
                                <Name>{fileName}</Name>
                                <AdditionalInfo>
                                    Uploaded:{' '}
                                    {dayjs(uploadedDate).format(
                                        'DD.MM.YYYY HH:mm',
                                    )}
                                </AdditionalInfo>
                            </InnerWrapper>
                        </FileBox>
                    </HeaderWrapper>
                    <FeedbackText>"{content}".</FeedbackText>
                </MentorFeedback>
            ) : (
                <EmptyWrapper>
                    <IconManager name={'QuestionMark'} size={46} />
                    <EmptyInfo>
                        Here the feedback from the mentor will be displayed
                    </EmptyInfo>
                </EmptyWrapper>
            )}
        </MentorFeedbackCardWrapper>
    );
};

export default MentorFeedbackCard;
