import React from 'react';
import ExperiencesSaveForLaterCarousel from '@domains/Child/Courses/_components/ExperiencesSaveForLater/ExperiencesSaveForLaterCarousel/ExperiencesSaveForLaterCarousel';
import paths from '@shared/paths';
import { CONTENT_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { setLibraryFilter } from '@state/_redux/filters/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSaveForLaterContentQuery } from '@state/lists/queries';
import {
    ButtonWrapper,
    Description,
    ExperiencesWrapper,
    LiveIcon,
    StyledButton,
    StyledTitle,
    TitleWrapper,
} from './ExperiencesSaveForLater.styled';

interface IProps {}

const ExperiencesSaveForLater: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const libraryFilters = useSelector((state) => state.filters.library);
    const { data } = useSaveForLaterContentQuery({ page: 1, limit: 100 });
    const isEmptyState = !data?.getSavedForLaterContent?.filter(
        (element) => element.__typename === 'LiveExperience',
    ).length;

    const handleRedirect = (filterBy: CONTENT_TYPE) => {
        dispatch(setLibraryFilter({ ...libraryFilters, type: [filterBy] }));
        history.push(paths.library);
    };

    return (
        <ExperiencesWrapper>
            <TitleWrapper>
                <LiveIcon name={'Live'} size={50} />
                <StyledTitle>Experiences</StyledTitle>
            </TitleWrapper>
            {isEmptyState ? (
                <>
                    <Description>
                        It looks like you have not save any experience for
                        later. Don’t wait any longer, check what we prepared for
                        you.
                    </Description>
                    <ButtonWrapper>
                        <StyledButton
                            onPress={() =>
                                handleRedirect(CONTENT_TYPE.LIVE_EXPERIENCE)
                            }
                        >
                            Check upcoming experiences
                        </StyledButton>
                    </ButtonWrapper>
                </>
            ) : (
                <ExperiencesSaveForLaterCarousel />
            )}
        </ExperiencesWrapper>
    );
};

export default ExperiencesSaveForLater;
