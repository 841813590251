import styled, { css } from 'styled-components';
import { IconManager } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';

export const HomeworkButtonWrapper = styled.button(
    ({ theme: { borderRadius, colors, transitions } }) => css`
        align-items: center;
        background-color: ${colors.background_secondary};
        border-radius: ${borderRadius.card}px;
        border: 1px solid ${colors.background_tertiary};
        cursor: pointer;
        display: flex;
        flex-shrink: 0;
        padding: 14px 20px 14px 16px;
        position: relative;
        width: 270px;

        &:hover {
            background-color: ${colors.background_quaternary};
            transition: ${transitions.medium}s;
        }
    `,
);

export const HomeworkIcon = styled(IconManager)`
    margin-right: 8px;
`;

export const ButtonTexts = styled.div`
    width: calc(100% - 32px);
`;

export const FileName = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.5;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
);

export const SubText = styled(DefaultText)(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.text_secondary};
        font-size: ${fontSizes.xs}px;
        line-height: 1.65;
        margin: 0;
    `,
);
