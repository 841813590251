import styled from 'styled-components';
import { SubTitle, Title } from '@components/_universal/Typography.styled';

export const LiveExperienceParentPageWrapper = styled.div``;

export const PageTitle = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    padding-top: 34px;
`;

export const SectionHeader = styled(Title)`
    align-items: center;
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 80px 0 48px;
`;

export const DividedContainer = styled.div`
    display: flex;
    margin-bottom: 80px;
`;

export const LeftBox = styled.div`
    flex: 2;
`;

export const RightBox = styled.div`
    flex: 1;
    margin-left: 64px;
`;
