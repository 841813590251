import { gql, useMutation } from '@apollo/client';
import {
    RefreshSessionTokenMutation,
    RefreshSessionTokenMutationVariables,
} from '@@types/graphql/RefreshSessionTokenMutation';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import logout from '@utils/logout';

export const REFRESH_SESSION_TOKEN_MUTATION = gql`
    mutation RefreshSessionTokenMutation($refreshToken: String!) {
        refreshSessionToken(refreshToken: $refreshToken) {
            sessionToken
            refreshToken
        }
    }
`;

export default (refreshToken: string) => {
    return useMutation<
        RefreshSessionTokenMutation,
        RefreshSessionTokenMutationVariables
    >(REFRESH_SESSION_TOKEN_MUTATION, {
        variables: {
            refreshToken,
        },
        // TODO: handle completed action
        onCompleted: (payload) => {
            LocalStorageManager.setValue(
                'sessionToken',
                payload.refreshSessionToken.sessionToken,
            );
            LocalStorageManager.setValue(
                'refreshToken',
                payload.refreshSessionToken.refreshToken,
            );
        },
        onError: () => {
            logout();
        },
    });
};
