import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';

export const TrialInformationWrapper = styled.div`
    position: relative;
    width: fit-content;
`;

export const InnerWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

export const DaysText = styled(DefaultText)<{
    varaint?: 'default' | 'large';
}>`
    font-size: ${({ theme }) => theme.fontSizes.xxs}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: 1.2;
    margin: 0;
    max-width: 70px;
    text-align: center;
    text-transform: uppercase;

    ${({ varaint }) =>
        varaint === 'large' &&
        css`
            font-size: ${({ theme }) => theme.fontSizes.xs}px;
            line-height: 1.5;
        `}
`;

export const TrialText = styled(DaysText)`
    color: ${({ theme }) => theme.colors.notifications};
    text-transform: uppercase;
`;
