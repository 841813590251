import React from 'react';
import { DefaultText } from '@components/_universal/Typography.styled';
import {
    DescriptionBoxWrapper,
    StyledBigHeader,
} from './DescriptionBox.styled';

interface DescriptionBoxProps {
    children: React.ReactNode;
    title: string;
}

export const DescriptionBox = ({ children, title }: DescriptionBoxProps) => {
    return (
        <DescriptionBoxWrapper>
            <StyledBigHeader>{title}</StyledBigHeader>
            <DefaultText>{children}</DefaultText>
        </DescriptionBoxWrapper>
    );
};
