import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';
import Avatar from '@components/_universal/Avatar/Avatar';

export const ProfilPageWrapper = styled.div`
    padding-bottom: 80px;
`;

export const SectionWrapper = styled.div``;

export const EditAvatarSectionWrapper = styled.div`
    margin-top: 45px;
`;

export const EditEmailSectionWrapper = styled.div`
    margin-top: 25px;
`;

export const Icon = styled(IconManager)`
    margin-right: 10px;
`;

export const StyledChosenAvatar = styled(Avatar)`
    cursor: pointer;
    margin: 24px 36px 24px 24px;
`;

export const StyledAvatar = styled(Avatar)`
    cursor: pointer;

    .AvatarImageIcon {
        box-shadow: ${({ theme }) => theme.light_green_shadow};
        border-radius: ${({ theme }) => theme.borderRadius.circle}%;
        transition: all ${({ theme }) => theme.transitions.default}s;

        &:hover {
            box-shadow: ${({ theme }) => theme.dark_green_shadow};
        }
    }

    & + & {
        margin-left: 24px;
    }
`;

export const AvatarsWrapper = styled.div`
    display: flex;
`;

export const SectionDecorators = styled.div`
    align-items: center;
    display: flex;
`;

export const SectionDivider = styled.div`
    background: ${({ theme }) => theme.colors.background_secondary};
    height: 2px;
    margin: 45px 0;
    width: 100%;
`;

export const SectionHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 15px;
    min-height: 40px;
`;

export const Actions = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 48px 0 24px;
`;

export const ActionButton = styled(Button)`
    margin: 0 12px;
    min-width: 160px;
`;

export const SectionButton = styled(Button)`
    height: 40px;
    justify-self: flex-end;
    margin-left: auto;
    min-width: 120px;
`;

export const SectionText = styled(DefaultText)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 24px;
`;

export const SectionSmallText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin-bottom: 45px;
    max-width: 448px;
`;
