import { gql, useLazyQuery } from '@apollo/client';
import {
    StudentActivityQuery,
    StudentActivityQueryVariables,
} from '@@types/graphql/StudentActivityQuery';

const STUDENT_ACTIVITY_QUERY = gql`
    query StudentActivityQuery($input: GetStudentActivityInput!) {
        getStudentActivity(input: $input) {
            result {
                homework {
                    id
                    feedbackGivenAt
                    feedbackBy {
                        name
                        surname
                    }
                    mentorFeedback
                    createdAt
                    files {
                        id
                        filename
                        mimetype
                        type
                    }
                }
                content {
                    ... on Course {
                        _id
                        name
                        type
                    }
                    ... on StandaloneVideo {
                        _id
                        name
                        type
                    }
                    ... on LiveExperience {
                        _id
                        name
                        type
                    }
                }
                createdAt
            }
            counts {
                allCount
                standaloneVideosCount
                coursesCount
                liveExperiencesCount
            }
        }
    }
`;

export default () =>
    useLazyQuery<StudentActivityQuery, StudentActivityQueryVariables>(
        STUDENT_ACTIVITY_QUERY,
        {
            onError: (err) => console.log(err.message),
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true,
        },
    );
