import React from 'react';
import { SwitchInput, SwitchLabel, SwitchWrapper } from './Switch.styled';
import { UseFormMethods } from 'react-hook-form';

interface IProps {
    className?: string;
    disabled?: boolean;
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    register: UseFormMethods['register'];
}

const Switch: React.FC<IProps> = ({
    className,
    disabled,
    name,
    onChange,
    register,
}) => {
    return (
        <SwitchWrapper className={className}>
            <SwitchInput
                disabled={disabled}
                id={name}
                name={name}
                onChange={onChange}
                ref={register}
                type={'checkbox'}
            />
            <SwitchLabel htmlFor={name} />
        </SwitchWrapper>
    );
};

export default Switch;
