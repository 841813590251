import React, { useEffect, useRef } from 'react';
import { Content } from '@domains/Child/Dashboard/_components/Article/Article';
import { useLocation } from 'react-router-dom';
import { useMostPopularContentsQuery } from '@state/lists/queries';
import parseDashboardMostPopularData from '@utils/parseDashboardMostPopularData';
import paths from '@shared/paths';
import {
    ArticleWrapper,
    LatestArticlesAndLibraryWrapper,
    LatestPostsWrapper,
    LoaderWrapper,
    StyledArticle,
    StyledSplitCard,
    StyledTextBoxCard,
    StyledTitle,
} from './LatestPosts.styled';
import Loader from '@components/_universal/Loader/Loader';

interface IProps {
    className?: string;
}

const LatestPosts: React.FC<IProps> = ({ className }) => {
    const { data, loading } = useMostPopularContentsQuery();
    const popularContent = data?.getMostPopularContents;
    const { videos, courses, rest } = parseDashboardMostPopularData(
        popularContent ?? [],
    );
    const ref = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const getContentUrl = (type: string, id: string): string => {
        if (type === 'COURSE') return paths.course(id);
        if (type === 'STANDALONE_VIDEO') return paths.video_view(id);
        return '';
    };

    useEffect(() => {
        location.hash &&
            setTimeout(() => {
                ref.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 1000);
    }, [ref.current, location.hash]);

    return (
        <LatestPostsWrapper className={className}>
            <StyledTitle ref={ref}>
                This is really popular right now!
            </StyledTitle>
            {loading ? (
                <LoaderWrapper>
                    <Loader size={'medium'} center />
                </LoaderWrapper>
            ) : (
                <>
                    <LatestArticlesAndLibraryWrapper>
                        <ArticleWrapper>
                            {rest
                                ?.slice(0, 3)
                                .map(({ _id, name, type }, index) => (
                                    <StyledArticle
                                        key={index}
                                        link={getContentUrl(type, _id)}
                                        type={type as Content}
                                    >
                                        {name}
                                    </StyledArticle>
                                ))}
                        </ArticleWrapper>
                        <ArticleWrapper>
                            {rest
                                ?.slice(3, 6)
                                .map(({ _id, name, type }, index) => (
                                    <StyledArticle
                                        key={index}
                                        link={getContentUrl(type, _id)}
                                        type={type as Content}
                                    >
                                        {name}
                                    </StyledArticle>
                                ))}
                        </ArticleWrapper>
                        <ArticleWrapper withBoxes>
                            {videos
                                ?.slice(0, 2)
                                .map(
                                    ({ _id, name, thumbnail, type }, index) => (
                                        <StyledTextBoxCard
                                            image={thumbnail}
                                            key={index}
                                            link={getContentUrl(type, _id)}
                                            text={name}
                                            type="STANDALONE_VIDEO"
                                            width="100%"
                                        />
                                    ),
                                )}
                        </ArticleWrapper>
                    </LatestArticlesAndLibraryWrapper>
                    <LatestArticlesAndLibraryWrapper>
                        {courses.map(
                            ({ _id, name, thumbnail, type }, index) => (
                                <StyledSplitCard
                                    arrangement="horizontal"
                                    image={thumbnail}
                                    key={index}
                                    link={getContentUrl(type, _id)}
                                    text={name}
                                    width="100%"
                                />
                            ),
                        )}
                        {videos
                            ?.slice(2, 3)
                            .map(({ _id, name, thumbnail, type }, index) => (
                                <StyledTextBoxCard
                                    image={thumbnail}
                                    key={index}
                                    link={getContentUrl(type, _id)}
                                    text={name}
                                    type="STANDALONE_VIDEO"
                                    width="100%"
                                />
                            ))}
                    </LatestArticlesAndLibraryWrapper>
                </>
            )}
        </LatestPostsWrapper>
    );
};

export default LatestPosts;
