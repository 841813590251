import React from 'react';
import { AccountPageWrapper } from './AccountPage.styled';
import { IAppSubRoutes } from '@routes/index';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import SubRoutes from '@routes/SubRoutes';

interface IProps extends IAppSubRoutes {}

const AccountPage: React.FC<IProps> = ({ routes, layoutComponent }) => {
    return (
        <AccountPageWrapper>
            <SubRoutes {...{ routes, layoutComponent }} />
            <ChatWithMentor />
        </AccountPageWrapper>
    );
};

export default AccountPage;
