import { useEffect, useMemo, useState } from 'react';
import { HOMEWORK_FILE_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { Nullable } from '@@types/CommonTypes';
import { useNotificationLiveExperiences } from '@state/queries/notificationLiveExperiences';
import { useParams } from 'react-router';
import { useSaveForLaterMutation } from '@state/savedForLater/mutations';
import dayjs from 'dayjs';
import useRemoveSavedForLater from '@state/savedForLater/mutations/useRemoveSavedForLater';
import {
    useGetLiveExperienceQuery,
    useIsLiveExperienceFinishedQuery,
} from '@state/liveExperience/queries';

export const useLiveExperience = () => {
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();

    const [timeoutIndex, setTimeoutIndex] = useState<Nullable<NodeJS.Timeout>>(
        null,
    );
    const [showModal, setShowModal] = useState(true);
    const [isTimerCompleted, setIsTimerCompleted] = useState(false);

    const { data, loading } = useGetLiveExperienceQuery(liveExperienceId);
    const {
        data: isLiveExperienceFinishedData,
        startPolling,
        stopPolling,
    } = useIsLiveExperienceFinishedQuery(liveExperienceId);
    const liveExperienceData = data?.getLiveExperience;
    const finishedManually =
        isLiveExperienceFinishedData?.getLiveExperience.finishedManually;
    const [
        removeSavedForLater,
        { loading: loadingRemove },
    ] = useRemoveSavedForLater(liveExperienceId, 'LiveExperience');
    const [saveForLater, { loading: loadingSave }] = useSaveForLaterMutation(
        liveExperienceId,
        'LiveExperience',
    );

    const { data: notifications } = useNotificationLiveExperiences();

    const isFromNotify = notifications?.ids?.includes(liveExperienceId);

    const POOLING_INTERVAL = 60 * 1000;

    const startDate =
        data?.getLiveExperience.startDate &&
        dayjs(data?.getLiveExperience.startDate);

    const endDate = useMemo(
        () => liveExperienceData?.endDate && dayjs(liveExperienceData.endDate),
        [liveExperienceData?.endDate],
    );

    const isFullyBooked =
        data &&
        data.getLiveExperience.maxNoParticipants -
            data.getLiveExperience.noOfSignups ===
            0;

    const userHomework = liveExperienceData?.homework?.files?.find(
        (item) => item.type === HOMEWORK_FILE_TYPE.USER_HOMEWORK,
    );

    const isSignedUp = data?.getLiveExperience.isUserSignuped;

    const isConsumed =
        ((endDate &&
            startDate &&
            startDate.diff(dayjs()) <= 0 &&
            endDate.diff(dayjs()) <= 0) ||
            finishedManually) &&
        isSignedUp;

    const isOngoing =
        (startDate && startDate.diff(dayjs()) <= 0) || isTimerCompleted;

    const workFileSubmitted = !!userHomework;

    const onTimerComplete = () => {
        setIsTimerCompleted(true);
    };

    useEffect(() => {
        const isExperienceInProgress =
            isOngoing && !isConsumed && endDate && !timeoutIndex && isSignedUp;
        let intervalIndex: Nullable<NodeJS.Timeout> = null;

        if (isExperienceInProgress) {
            const timeToEnd = endDate.diff(dayjs());
            // Set timeout which force check if isConsumed is true
            // This will end live experience without refresh page
            intervalIndex = setTimeout(() => {
                setTimeoutIndex(null);
                stopPolling();
            }, timeToEnd);
            setTimeoutIndex(intervalIndex);

            startPolling(POOLING_INTERVAL);
        } else {
            stopPolling();
        }

        return () => {
            stopPolling();
            intervalIndex !== null && clearInterval(intervalIndex);
        };
    }, [isOngoing, endDate]);

    useEffect(() => {
        finishedManually && stopPolling();
    }, [finishedManually]);

    return {
        data,
        endDate,
        finishedManually,
        isConsumed,
        isFromNotify,
        isFullyBooked,
        isOngoing,
        isSignedUp,
        liveExperienceData,
        loading,
        loadingRemove,
        loadingSave,
        onTimerComplete,
        removeSavedForLater,
        saveForLater,
        setShowModal,
        setTimeoutIndex,
        showModal,
        workFileSubmitted,
    };
};
