import * as yup from 'yup';
import {
    emailValidation,
    textValidation,
    selectValidation,
} from '@utils/shared/validations/formErrors';

export const validation = yup.object().shape({
    email: emailValidation,
    name: textValidation,
    surname: textValidation,
    address: textValidation,
    country: selectValidation,
    city: textValidation,
    zipCode: textValidation,
});
