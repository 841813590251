import { LIBRARY_VIEW_CONTENT_SORTING } from '@@types/graphql/GraphqlGlobalTypes';

export const sortBy = [
    {
        label: 'Recommended',
        value: LIBRARY_VIEW_CONTENT_SORTING.RECOMMENDED,
    },
    {
        label: 'Recently Added',
        value: LIBRARY_VIEW_CONTENT_SORTING.NEWEST,
    },
    {
        label: 'Most popular',
        value: LIBRARY_VIEW_CONTENT_SORTING.MOST_POPULAR,
    },
];
