import React, { useEffect, useState } from 'react';
import { errorMessages } from '@utils/shared/validations/formErrors';
import { FieldError, useFormContext } from 'react-hook-form';
import { InputPassword } from '@components/_form-elements';
import { ISignupForm } from '@domains/Auth/SignupCreatePage/SignupCreatePage';
import { useMatchMedia, useCustomRegister } from '@utils/hooks';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import PasswordValidation from '@components/_form-elements/PasswordValidation/PasswordValidation';
import useCreateParentAccountMutation from '@state/auth/mutations/useCreateParentAccountMutation';
import {
    CreatePasswordFormWrapper,
    StyledButton,
    StyledFormRow,
} from './CreatePasswordForm.styled';
import {
    ButtonRow,
    InputWrapper,
} from '@domains/Auth/_forms/GlobalForm.styled';

interface IProps {}

const CreatePasswordForm: React.FC<IProps> = () => {
    const { match } = useMatchMedia('md');
    const [createParent, { loading }] = useCreateParentAccountMutation();

    const { errors, handleSubmit, register, watch } = useFormContext();
    const { refElement: confirmInputEl } = useCustomRegister<HTMLInputElement>(
        register,
    );
    const { refElement: passwordInputEl } = useCustomRegister<HTMLInputElement>(
        register,
    );

    const password = watch('newPassword');
    const confirmPassword = watch('confirmPassword');
    const [isValid, setIsValid] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const isValidConfirmPassword = password === confirmPassword;

    const isError =
        !isValid ||
        errors.confirmPassword ||
        errors.newPassword ||
        !isValidConfirmPassword;

    const onSubmit = ({
        country,
        confirmPassword,
        newPassword,
        ...formValues
    }: ISignupForm) => {
        if (loading || isError) return;
        confirmInputEl.current?.blur();
        createParent({
            variables: {
                input: {
                    ...formValues,
                    countryCode: country?.value as string,
                    password: newPassword,
                },
            },
        });
    };

    useEffect(() => {
        const onFocus = () => setIsFocused(true);

        passwordInputEl.current?.addEventListener('focus', onFocus);

        return () => {
            passwordInputEl.current?.removeEventListener('focus', onFocus);
        };
    }, []);

    useEffect(() => {
        if (password?.length > 0) setIsFocused(true);
    }, [password]);

    return (
        <CreatePasswordFormWrapper onSubmit={handleSubmit(onSubmit)}>
            <StyledFormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.newPassword}
                        label={'password'}
                        marginBottom={match || isFocused ? 8 : 24}
                        maxLength={256}
                        name={'newPassword'}
                        placeholder={'Enter your password '}
                        register={passwordInputEl}
                        staticPosition={match}
                        type="password"
                    />
                </InputWrapper>
            </StyledFormRow>
            {isFocused && (
                <PasswordValidation
                    marginTop={errors.newPassword && 16}
                    password={password}
                    setIsValid={setIsValid}
                />
            )}
            <StyledFormRow>
                <InputWrapper>
                    <InputForm
                        errors={
                            errors.confirmPassword ||
                            (!isValidConfirmPassword &&
                                confirmPassword.length > 0)
                                ? ({
                                      message: errorMessages.CONFIRM_PASSWORD,
                                  } as FieldError)
                                : undefined
                        }
                        label={'confirm Password'}
                        marginBottom={match ? 8 : 24}
                        maxLength={256}
                        name={'confirmPassword'}
                        placeholder={'Re-enter your password'}
                        register={confirmInputEl}
                        staticPosition={match}
                        type="password"
                    />
                </InputWrapper>
            </StyledFormRow>
            <ButtonRow>
                <StyledButton disabled={loading || isError} onPress={() => {}}>
                    register
                </StyledButton>
            </ButtonRow>
        </CreatePasswordFormWrapper>
    );
};

const InputForm = FieldMessage(InputPassword);

export default CreatePasswordForm;
