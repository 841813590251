import styled, { css } from 'styled-components';
import { IconManager } from '@components/_universal';
import { TextareaTypes } from '@components/_form-elements/Textarea/Textarea';

const wrapperCss = css`
    display: flex;
    flex-direction: column;
`;

export const TextareaWrapper = styled.div<{ marginBottom?: number }>`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-bottom: ${({ marginBottom }) =>
        typeof marginBottom === 'number' ? marginBottom : 24}px;
    width: 100%;
    ${wrapperCss}
`;

export const TextareaInnerWrapper = styled.div`
    position: relative;
    ${wrapperCss}
`;

export const Label = styled.label<{ inputType?: TextareaTypes }>`
    color: ${({ theme, inputType }) =>
        inputType === 'default' ? theme.colors.text : theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin: 0 0 16px 25px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
`;

const errorInputCss = css`
    border: 1px solid ${({ theme }) => theme.colors.error};
    caret-color: ${({ theme }) => theme.colors.error};
`;

export const StyledTextarea = styled.textarea<{
    disabled?: boolean;
    errors?: boolean;
}>`
    background-color: ${({ theme }) => theme.colors.text_white};
    border-radius: ${({ theme }) => theme.borderRadius.button}px;
    border: 1px solid ${({ theme }) => theme.colors.background_tertiary};
    caret-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    height: 130px;
    line-height: 1.3;
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
    outline: none;
    overflow: hidden;
    padding: 16px 25px;
    resize: none;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${({ theme }) => theme.colors.text_secondary};
        font-size: ${({ theme }) => theme.fontSizes.s}px;
        font-weight: ${({ theme }) => theme.fontWeights.regular};
    }

    &:focus {
        ::placeholder,
        ::-webkit-input-placeholder {
            color: ${({ theme }) => theme.colors.text};
        }
    }

    ${({ errors }) => errors && errorInputCss}
`;

const errorIconCss = css`
    height: 16px;
    position: absolute;
    right: 28px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
`;

export const IconWrapper = styled(IconManager)<{ errors?: boolean }>`
    ${({ errors }) => errors && errorIconCss}
`;
