import styled, { css } from 'styled-components';
import Select from '@components/_form-elements/Select/Select';
import {
    DefaultText,
    LinkText,
} from '@components/_universal/Typography.styled';

export const ClickText = styled(DefaultText)(
    ({ theme: { colors } }) => css`
        color: ${colors.secondary};
        cursor: pointer;
        display: inline;
        text-decoration: none;
    `,
);

export const Link = styled(LinkText)`
    text-decoration: none;
`;

export const InfoSelect = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 152px;
    justify-content: center;
`;

export const BoldMessage = styled.p(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.m}px;
        font-weight: ${fontWeights.bold};
        margin: 0;
        padding: 0;
    `,
);

export const NormalMessage = styled.p(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.m}px;
        font-weight: ${fontWeights.regular};
        margin: 0;
        padding: 0;
    `,
);

export const StyledSelect = styled(Select)`
    width: 100%;
`;
