import { gql, useMutation } from '@apollo/client';
import { USER_ME_QUERY } from '@state/auth/queries/useUserMeQuery';
import {
    ChangeAvatarMutation,
    ChangeAvatarMutationVariables,
} from '@@types/graphql/ChangeAvatarMutation';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

const CHANGE_AVATAR_MUTATION = gql`
    mutation ChangeAvatarMutation($avatarId: Int!) {
        changeAvatar(avatarId: $avatarId)
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<ChangeAvatarMutation, ChangeAvatarMutationVariables>(
        CHANGE_AVATAR_MUTATION,
        {
            // TODO: handle completed action
            onCompleted: () => {
                onCompleted?.();
            },
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER_ME_QUERY,
                },
            ],
        },
    );
};
