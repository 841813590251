import { gql, useQuery } from '@apollo/client';
import { UserSubscriptionInfoQuery } from '@@types/graphql/UserSubscriptionInfoQuery';

export const USER_SUBSCRIPTION_INFO_QUERY = gql`
    query UserSubscriptionInfoQuery {
        getUserSubscriptionInfo {
            billingCycle
            stripeNoLicenses
            isPaymentConfirmed
            plan {
                currency
                currencySymbol
                price
            }
            nextChargeAmount
            isTrialing
            trialEndAt
        }
    }
`;

export default (isNetwork?: boolean) =>
    useQuery<UserSubscriptionInfoQuery>(USER_SUBSCRIPTION_INFO_QUERY, {
        onError: () => {},
        fetchPolicy: isNetwork ? 'network-only' : 'cache-first',
    });
