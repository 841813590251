import { gql, useLazyQuery } from '@apollo/client';
import {
    GetStandaloneVideoQuery,
    GetStandaloneVideoQueryVariables,
} from '@@types/graphql/GetStandaloneVideoQuery';

const GET_STANDALONE_VIDEO_QUERY = gql`
    query GetStandaloneVideoQuery($id: ID!) {
        getStandaloneVideo(id: $id) {
            _id
            createdBy
            description
            featured
            name
            status
            thumbnail
            type
            isSavedForLater
            consumption {
                progress {
                    lastCheckpoint
                    completedLessons
                    homeworkSent
                }
            }
            video {
                _id
                duration
            }
        }
    }
`;

export default (id: string) => {
    return useLazyQuery<
        GetStandaloneVideoQuery,
        GetStandaloneVideoQueryVariables
    >(GET_STANDALONE_VIDEO_QUERY, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });
};
