import { CourseTeacherPage } from '@domains/Teacher/CourseTeacherPage/CourseTeacherPage';
import { GroupPage } from '@domains/Teacher/Groups/GroupPage/GroupPage';
import { LibraryTeacherPage } from '@domains/Teacher/Library/LibraryTeacherPage/LibraryTeacherPage';
import { LiveExperienceTeacherPage } from '@domains/Teacher/LiveExperience/LiveExperienceTeacherPage/LiveExperienceTeacherPage';
import { RouterConfig } from '@routes/index';
import { StudentsPage } from '@domains/Teacher/Students/StudentsPage/StudentsPage';
import { GroupsTeacherPage } from '@domains/Teacher/Groups/GroupsTeacherPage/GroupsTeacherPage';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { VideoTeacherPage } from '@domains/Teacher/VideoTeacherPage/VideoTeacherPage';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import paths from '@shared/paths';
import renderRedirect from '@services/RenderRedirect';
import {
    TeacherAccountLayout,
    TeacherAccountSubLayout,
    TeacherMainLayout,
} from '@components/index';

import {
    AccountTeacherPage,
    DashboardTeacherPage,
    HelpFaqTeacherPage,
    InformationTeacherPage,
    LegalTeacherPage,
    NotificationSettingsTeacherPage,
    StudentDetailsPage,
    UploadStudentsPage,
    UploadStudentsSummaryPage,
} from '@domains/index';

const isAdminAsTeacher = LocalStorageManager.getValue('adminSessionToken');

export const teacherRoutes: RouterConfig = [
    // Dashboard Layout
    {
        allowFor: isAdminAsTeacher ? USER_TYPE.ADMIN : USER_TYPE.TEACHER,
        isPrivate: true,
        routes: [
            {
                component: DashboardTeacherPage,
                exact: true,
                path: paths.dashboard_teacher,
            },
            {
                component: StudentsPage,
                exact: true,
                path: paths.students_teacher,
            },
            {
                component: GroupsTeacherPage,
                exact: true,
                path: paths.teacher_groups,
            },
            {
                component: LibraryTeacherPage,
                exact: true,
                path: paths.library_teacher,
            },
            {
                component: UploadStudentsPage,
                exact: true,
                path: paths.upload_teacher,
            },
            {
                component: UploadStudentsSummaryPage,
                exact: true,
                path: paths.upload_summary_teacher,
            },
            {
                component: GroupPage,
                exact: true,
                path: paths.students_group_teacher(':groupId'),
            },
            {
                component: StudentDetailsPage,
                exact: true,
                path: paths.student_details_teacher(':studentId'),
            },
            {
                component: VideoTeacherPage,
                exact: true,
                path: paths.video_details_teacher(':videoId'),
            },
            {
                component: CourseTeacherPage,
                exact: true,
                path: paths.course_teacher(':courseId'),
            },
            {
                component: LiveExperienceTeacherPage,
                exact: true,
                path: paths.live_experience_teacher(':liveExperienceId'),
            },
        ],
        layoutComponent: TeacherMainLayout,
    },
    // Account Layout
    {
        allowFor: isAdminAsTeacher ? USER_TYPE.ADMIN : USER_TYPE.TEACHER,
        isPrivate: true,
        routes: [
            {
                component: AccountTeacherPage,
                path: paths.account_teacher,
                subRoutes: {
                    routes: [
                        {
                            component: renderRedirect(
                                paths.account_information_teacher,
                            ),
                            exact: true,
                            path: paths.account_teacher,
                        },
                        {
                            component: InformationTeacherPage,
                            exact: true,
                            path: paths.account_information_teacher,
                        },
                        {
                            component: NotificationSettingsTeacherPage,
                            exact: true,
                            path: paths.account_notification_settings_teacher,
                        },
                        {
                            component: HelpFaqTeacherPage,
                            exact: true,
                            path: paths.account_help_and_faq_teacher,
                        },
                        {
                            component: LegalTeacherPage,
                            exact: true,
                            path: paths.account_legal_teacher,
                        },
                    ],
                    layoutComponent: TeacherAccountSubLayout,
                },
            },
        ],
        layoutComponent: TeacherAccountLayout,
    },
];
