import { gql, useMutation } from '@apollo/client';
import { GET_SCHOOL_CLASSES_QUERY } from '@state/teachers/queries/useSchoolClassesQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { SchoolClassesQuery } from '@@types/graphql/SchoolClassesQuery';
import { ToastTypes } from '@@types/CommonTypes';
import {
    UpdateStudentAccountMutation,
    UpdateStudentAccountMutationVariables,
} from '@@types/graphql/UpdateStudentAccountMutation';

const UPDATE_STUDENT_ACCOUNT_MUTATION = gql`
    mutation UpdateStudentAccountMutation($input: UpdateStudentAccountInput!) {
        updateStudentAccount(input: $input) {
            id
            name
            surname
            status
            email
            class {
                id
                year
                className
            }
        }
    }
`;

export default (callback: () => void) => {
    return useMutation<
        UpdateStudentAccountMutation,
        UpdateStudentAccountMutationVariables
    >(UPDATE_STUDENT_ACCOUNT_MUTATION, {
        onCompleted: () => {
            callback();
            notify('Changes have been successfully saved.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
        update: (cache, { data }) => {
            if (!data) return;
            const query = cache.readQuery<SchoolClassesQuery>({
                query: GET_SCHOOL_CLASSES_QUERY,
            });

            if (query) {
                const newSchoolClass = data.updateStudentAccount.class;
                const isExistingClass = !!(
                    newSchoolClass &&
                    query.getSchoolClasses.find(
                        (schoolClass) => schoolClass.id === newSchoolClass.id,
                    )
                );

                !isExistingClass &&
                    newSchoolClass &&
                    cache.writeQuery<SchoolClassesQuery>({
                        query: GET_SCHOOL_CLASSES_QUERY,
                        data: {
                            getSchoolClasses: [
                                ...query.getSchoolClasses,
                                newSchoolClass,
                            ],
                        },
                    });
            }
        },
    });
};
