import React from 'react';
import {
    Content,
    ContentWrapper,
    FooterBar,
    NavigationBar,
    OnboardingLayoutWrapper,
} from './OnboardingLayout.styled';

interface IProps {}

export const OnboardingLayout: React.FC<IProps> = ({ children }) => {
    return (
        <OnboardingLayoutWrapper>
            <NavigationBar />
            <ContentWrapper>
                <Content>{children}</Content>
            </ContentWrapper>
            <FooterBar />
        </OnboardingLayoutWrapper>
    );
};
export default OnboardingLayout;
