import styled, { css } from 'styled-components';
import { SubTitle } from '@components/_universal/Typography.styled';

export const LiveExperienceWrapper = styled.div`
    padding-left: 100px;
`;

export const PageTitle = styled(SubTitle)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        padding-top: 34px;
    `,
);
