import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button } from '@components/_universal';
import { FormRow, InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';

export const EditUserDetailsFormWrapper = styled.div``;

export const StyledButton = styled(Button)`
    justify-content: center;
    margin: 8px auto 0;
    max-width: 302px;
    width: 100%;
`;

export const StyledFormRow = styled(FormRow)`
    @media (max-width: ${breakpoints.sm}px) {
        flex-direction: column;
    }
`;

export const StyledInputWrapper = styled(InputWrapper)`
    @media (max-width: ${breakpoints.sm}px) {
        width: 100%;
    }
`;
