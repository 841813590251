import styled from 'styled-components';
import { breakpoints } from '@shared/constants';

export const TabWrapper = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 48px;
    padding-top: 56px;
    width: 100%;

    @media (max-width: ${breakpoints.sm}px) {
        border-bottom: unset;
    }
`;
