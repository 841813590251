import styled, { keyframes } from 'styled-components';
import { breakpoints } from '@shared/constants';
import { ColorType } from '@@types/CommonTypes';

const fadeIn = keyframes`
    from {
        opacity: 0
    }
    to{
        opacity: 1
    }
`;

export const TipWrapper = styled.div<{ backgroundColor?: ColorType }>`
    animation: ${fadeIn} 0.2s linear forwards;
    background-color: ${({ theme, backgroundColor }) =>
        backgroundColor
            ? theme.colors[backgroundColor]
            : 'rgba(255, 255, 255, 0.25)'};
    border-radius: ${({ theme }) => theme.borderRadius.bubble}px;
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.55;
    padding: 28px 32px;
    position: relative;
    z-index: 9;

    @media (max-width: ${breakpoints.lg}px) {
        font-size: ${({ theme }) => theme.fontSizes.xs}px;
        line-height: 1.5;
        padding: 20px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.xxs}px;
        line-height: 1.5;
        padding: 16px;
    }

    &::after {
        border-right: 16px solid transparent;
        border-top: 14px solid
            ${({ theme, backgroundColor }) =>
                backgroundColor
                    ? theme.colors[backgroundColor]
                    : 'rgba(255, 255, 255, 0.25)'};
        content: '';
        height: 0;
        left: 16px;
        position: absolute;
        top: 100%;
        width: 0;
    }
`;
