import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { USER_ME_QUERY } from '@state/auth/queries/useUserMeQuery';
import {
    UpdateTeacherAccountMutation,
    UpdateTeacherAccountMutationVariables,
} from '@@types/graphql/UpdateTeacherAccountMutation';

const UPDATE_TEACHER_ACCOUNT = gql`
    mutation UpdateTeacherAccountMutation($input: UpdateTeacherAccountInput!) {
        updateTeacherAccount(input: $input) {
            email
            name
            surname
        }
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<
        UpdateTeacherAccountMutation,
        UpdateTeacherAccountMutationVariables
    >(UPDATE_TEACHER_ACCOUNT, {
        onCompleted: () => {
            onCompleted?.();
            notify('Congratulations! Changes have been successfully saved.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: () => {
            notify(
                'Unfortunately, changes haven’t been saved. Please try again later.',
            )[ToastTypes.ERROR]();
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: USER_ME_QUERY,
            },
        ],
        fetchPolicy: 'no-cache',
    });
};
