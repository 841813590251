import { useHistory, useLocation } from 'react-router-dom';

export default () => {
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);

    const addQuery = (key: string, value: string) => {
        searchParams.set(key, value);
        history.push({
            search: searchParams.toString(),
        });
    };

    const removeQuery = (key: string) => {
        searchParams.delete(key);
        history.push({
            search: searchParams.toString(),
        });
    };

    return { searchParams, addQuery, removeQuery };
};
