import React, { useState } from 'react';
import ChangePasswordModal from '@domains/Parent/Account/_modals/ChangePasswordModal/ChangePasswordModal';
import DeleteAccountModal from '@domains/Parent/Account/_modals/DeleteAccountModal/DeleteAccountModal';
import EditAccountInformationForm from '@domains/Parent/Account/_forms/EditAccountInformationForm/EditAccountInformationForm';
import SetPasswordModal from '@domains/Parent/Account/_modals/SetPasswordModal/SetPasswordModal';
import { useDeleteAccountMutation } from '@state/settings/mutations';
import { useUserMeQuery } from '@state/auth/queries';
import {
    InformationPageWrapper,
    PageHeader,
    RowWrapper,
    RowText,
    RowTitle,
    SectionButton,
    SectionDivider,
    SectionHeader,
    SectionRow,
    SectionText,
    SectionTitle,
    SectionWrapper,
} from './InformationParentPage.styled';
import {
    Icon,
    PageTitle,
} from '@domains/Parent/Account/AccountParentPage/AccountParentPage.styled';

interface IProps {}

const InformationPage: React.FC<IProps> = () => {
    const { data } = useUserMeQuery();

    const [deleteModal, setDeleteModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [isAccountInfoEdit, setIsAccountInfoEdit] = useState(false);
    const [deleteAccount] = useDeleteAccountMutation();

    const isSetPassword = data?.me.hasPassword;

    return (
        <>
            <InformationPageWrapper>
                <PageHeader>
                    <PageTitle>Account information</PageTitle>
                    <Icon name={'AccountIcon'} size={40} sizeY={40} />
                </PageHeader>

                {isAccountInfoEdit ? (
                    <EditAccountInformationForm
                        closeEdit={() => setIsAccountInfoEdit(false)}
                    />
                ) : (
                    <>
                        <SectionWrapper>
                            <SectionRow>
                                <RowWrapper>
                                    <RowTitle>
                                        Your personal information
                                    </RowTitle>
                                    {!isAccountInfoEdit && (
                                        <SectionButton
                                            buttonType={'secondary'}
                                            onPress={() =>
                                                setIsAccountInfoEdit(true)
                                            }
                                            size={'small'}
                                        >
                                            Edit
                                        </SectionButton>
                                    )}
                                </RowWrapper>
                                <RowText>
                                    {data?.me.name} {data?.me.surname}
                                </RowText>
                            </SectionRow>
                            <SectionRow>
                                <RowTitle>Your email address</RowTitle>
                                <RowText>{data?.me.email}</RowText>
                            </SectionRow>
                            <SectionRow>
                                <RowTitle>Your address</RowTitle>
                                <RowText>{data?.me.address ?? '-'}</RowText>
                                <RowText>
                                    {data?.me.zipCode} {data?.me.city}
                                </RowText>
                                <RowText>{data?.me.countryCode}</RowText>
                            </SectionRow>
                        </SectionWrapper>
                        <SectionDivider />
                        <SectionWrapper>
                            <RowWrapper>
                                <RowTitle>Your password</RowTitle>
                                {isSetPassword ? (
                                    <SectionButton
                                        buttonType={'secondary'}
                                        size={'small'}
                                        onPress={() =>
                                            setChangePasswordModal(true)
                                        }
                                    >
                                        Change
                                    </SectionButton>
                                ) : (
                                    <SectionButton
                                        buttonType={'secondary'}
                                        size={'small'}
                                        onPress={() => setPasswordModal(true)}
                                    >
                                        Set password
                                    </SectionButton>
                                )}
                            </RowWrapper>

                            {isSetPassword ? (
                                <SectionText>•••••••••••••••</SectionText>
                            ) : (
                                <SectionText>
                                    You don't have a password set. You can do
                                    this at any time by clicking on the 'Set
                                    password' button
                                </SectionText>
                            )}
                        </SectionWrapper>
                        <SectionDivider />
                        <SectionWrapper>
                            <SectionHeader>
                                <SectionTitle>Delete an account</SectionTitle>
                                <SectionButton
                                    buttonType={'secondaryError'}
                                    size={'small'}
                                    onPress={() => setDeleteModal(true)}
                                >
                                    Delete
                                </SectionButton>
                            </SectionHeader>
                            <SectionText>
                                If you decide to delete your account, you will
                                lose all data in the app. Please proceed with
                                caution.
                            </SectionText>
                        </SectionWrapper>
                    </>
                )}
            </InformationPageWrapper>

            {/* MODALS */}
            {deleteModal && (
                <DeleteAccountModal
                    content={
                        "Are you sure you want to delete your account? By deleting an account you will lose irrevocably all data on the parent's account and on all children's accounts connected to that account."
                    }
                    onDelete={deleteAccount}
                    setIsModalOpen={() => setDeleteModal(false)}
                    title={'Delete an account '}
                />
            )}

            {passwordModal && (
                <SetPasswordModal
                    setIsModalOpen={() => setPasswordModal(false)}
                />
            )}

            {changePasswordModal && (
                <ChangePasswordModal
                    setIsModalOpen={() => setChangePasswordModal(false)}
                />
            )}
        </>
    );
};

export default InformationPage;
