import styled from 'styled-components';
import ChoosePayment from '@domains/Auth/_components/ChoosePayment/ChoosePayment';
import { Button, IconManager } from '@components/_universal';
import { breakpoints } from '@shared/constants';
import {
    BigHeader,
    DefaultText,
    Title,
} from '@components/_universal/Typography.styled';

export const MobileSignupPricingPageWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    @media (max-width: ${breakpoints.md}px) {
        padding-top: 0;
    }
`;

export const MobileTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 auto 26px;
    max-width: 520px;
    padding-top: 16px;
    text-align: center;

    @media (max-width: ${breakpoints.md}px) {
        padding-top: 32px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
        line-height: 1.45;
        max-width: 330px;
        padding-top: 0;
    }
`;

export const MobileDescription = styled(BigHeader)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 auto 36px;
    max-width: 532px;
    position: relative;
    text-align: center;
    line-height: 28px;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.m}px;
    }
`;

export const StyledMobileDescription = styled(DefaultText)`
    background: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    color: ${({ theme }) => theme.colors.text_white};
    display: inline-block;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
    padding: 2px 8px;
    position: relative;
`;

export const MobilePricingWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 -16px 100px 0;
    position: relative;
    width: 720px;

    @media (max-width: ${breakpoints.md}px) {
        width: 540px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        display: block;
        margin: 0 0 100px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        width: calc(100vw - 36px);
    }
`;

export const StyledChoosePayment = styled(ChoosePayment)`
    width: 360px;
`;

export const ReadyInfoBox = styled.div`
    margin-top: 125px;
    position: relative;
`;

export const ReadyInfoIcon = styled(IconManager)`
    bottom: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
`;

export const ReadyInfoBoxWrapper = styled.div`
    background: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    padding: 56px 32px;
    position: relative;
    top: -4px;
    z-index: 9;
`;

export const ReadyInfoText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 32px;
`;

export const ReadyInfoButton = styled(Button)`
    width: 100%;

    &:hover {
        box-shadow: ${({ theme }) => theme.blue_shadow};
    }
`;
