import styled, { css } from 'styled-components';
import { BigHeader } from '@components/_universal/Typography.styled';

export const DescriptionBoxWrapper = styled.div`
    margin-bottom: 40px;
`;

export const StyledBigHeader = styled(BigHeader)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 16px;
    `,
);

export const DefaultTextBold = styled.span(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
    `,
);
