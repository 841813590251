import React from 'react';
import paths from '@shared/paths';
import { useHistory } from 'react-router-dom';
import {
    ButtonPricing,
    FreeTrialBackground,
    FreeTrialColumn,
    FreeTrialInnerWrapper,
    FreeTrialStand,
    FreeTrialSunIcon,
    FreeTrialWrapper,
    HumanIcon,
    ListElement,
    ListText,
    ListWrapper,
    StyledBigTitle,
    TickIcon,
} from './FreeTrial.styled';

interface IProps {
    isMobile?: boolean;
    pricingVersion?: boolean;
    setVisible?: () => void;
}

const FreeTrial: React.FC<IProps> = ({
    isMobile,
    pricingVersion,
    setVisible,
}) => {
    const history = useHistory();

    return (
        <FreeTrialWrapper>
            {isMobile && <FreeTrialBackground />}
            {!isMobile && !pricingVersion && (
                <>
                    <FreeTrialColumn />
                    <FreeTrialStand />
                </>
            )}
            <FreeTrialInnerWrapper pricingVersion={pricingVersion}>
                {!pricingVersion && (
                    <HumanIcon name={'FreeTrialHuman'} size={246} sizeY={244} />
                )}
                <FreeTrialSunIcon name={'FreeTrialSun'} size={160} />
                <StyledBigTitle>Start your free trial today</StyledBigTitle>
                <ListWrapper>
                    <ListElement>
                        <TickIcon
                            fill={['secondary']}
                            name={'Tick'}
                            size={24}
                        />
                        <ListText>No credit card required</ListText>
                    </ListElement>
                    <ListElement>
                        <TickIcon
                            fill={['secondary']}
                            name={'Tick'}
                            size={24}
                        />
                        <ListText>
                            Take part in unlimited live experiences
                        </ListText>
                    </ListElement>{' '}
                    <ListElement>
                        <TickIcon
                            fill={['secondary']}
                            name={'Tick'}
                            size={24}
                        />
                        <ListText>
                            Enjoy full access to our videos and courses
                        </ListText>
                    </ListElement>
                    {!pricingVersion && (
                        <ButtonPricing
                            buttonType={'secondary'}
                            onPress={setVisible}
                            size={'small'}
                        >
                            See pricing
                        </ButtonPricing>
                    )}
                </ListWrapper>
            </FreeTrialInnerWrapper>
        </FreeTrialWrapper>
    );
};

export default FreeTrial;
