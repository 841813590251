import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Checkbox } from '@components/_form-elements';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const ChildrenListWrapper = styled.div`
    display: grid;
    grid-gap: 4px;

    @media (max-width: ${breakpoints.lg}px) {
        grid-gap: 8px;
    }
`;

export const ChildElement = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.app_bg};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    display: flex;
    padding: 30px 0px 30px 32px;

    @media (max-width: ${breakpoints.lg}px) {
        padding: 20px 0px 20px 32px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        padding: 12px 0px 12px 32px;
    }
`;

export const Avatar = styled(IconManager)`
    margin-right: 16px;
`;

export const ChildName = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.xl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.m}px;
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    flex-grow: 0;
    margin: 0 32px 0 0;
`;

export const AddChildWrapper = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.app_bg};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    display: flex;
    justify-content: center;
    padding: 28px;

    @media (max-width: ${breakpoints.lg}px) {
        padding: 18px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        padding: 12px;
    }
`;

export const AddChildInner = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    outline: none;
`;

export const AddChildButton = styled.button`
    background-color: ${({ theme }) => theme.colors.icons_hover};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    border: 2px dashed ${({ theme }) => theme.colors.stroke};
    cursor: ${({ theme }) => theme.cursors.pointer};
    padding: 10px;
`;

export const AddChildText = styled(ChildName)`
    margin-left: 18px;

    @media (max-width: ${breakpoints.sm}px) {
        margin-left: 16px;
    }
`;
