import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import { setIsUnreadNotifications, setReadedNotifications } from './actions';

export default createReducer(initialState)
    .handleAction(setIsUnreadNotifications, (state, { payload }) => ({
        ...state,
        isUnread: payload,
    }))
    .handleAction(setReadedNotifications, (state, { payload }) => ({
        ...state,
        readedNotifications: payload,
    }));
