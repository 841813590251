import { useEffect } from 'react';
import { useTypeUserQuery } from '@state/auth/queries';

export default () => {
    const [getTypeUser] = useTypeUserQuery();
    const handleFocus = () => getTypeUser();

    useEffect(() => {
        window.addEventListener('focus', handleFocus);

        return () => window.removeEventListener('focus', handleFocus);
    }, []);
};
