import React from 'react';
import { DetailsCard } from '@components/_universal/DetailsCard/DetailsCard';
import { DetailsInfoWrapper, StyledBigHeader } from './DetailsInfo.styled';

interface IProps {
    className?: string;
    duration: string;
    mentor: string;
    schedule: string;
}

export const DetailsInfo: React.FC<IProps> = ({
    className,
    duration,
    mentor,
    schedule,
}) => {
    return (
        <DetailsInfoWrapper className={className}>
            <StyledBigHeader>Date & time</StyledBigHeader>
            <DetailsCard iconName={'Mentor'} title={mentor}>
                Facilitator
            </DetailsCard>
            <DetailsCard iconName={'Schedule'} title={schedule}>
                Date & Time (UK Time)
            </DetailsCard>
            <DetailsCard iconName={'TimeDuration'} title={duration}>
                Duration
            </DetailsCard>
        </DetailsInfoWrapper>
    );
};

export default DetailsInfo;
