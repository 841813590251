import React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { StudentsListQuery } from '@@types/graphql/StudentsListQuery';
import { useStudentsGroupDetails } from '@state/students/queries/useStudentsGroupDetails';
import { useUpdateStudentsGroup } from '@state/students/mutations/useUpdateStudentsGroup';
import ModalConfirmation from '@components/_modals/ModalConfirmation/ModalConfirmation';

export interface DeleteStudentFromGroupModalProps {
    groupId: string;
    onClose: () => void;
    refetch: () => Promise<ApolloQueryResult<StudentsListQuery>>;
    studentsId: number[];
}

export const DeleteStudentFromGroupModal = ({
    groupId,
    onClose,
    refetch,
    studentsId,
}: DeleteStudentFromGroupModalProps) => {
    const { data: groupData } = useStudentsGroupDetails({
        groupId: +groupId,
    });
    const [updateStudentsGroup] = useUpdateStudentsGroup(() => {
        onClose();
        refetch();
    });

    return (
        <ModalConfirmation
            closeOutside
            firstButton={{
                onClick: onClose,
                text: 'cancel',
                type: 'secondary',
            }}
            onClose={onClose}
            secondButton={{
                onClick: () => {
                    updateStudentsGroup({
                        variables: {
                            input: {
                                groupId: +groupId,
                                removeUsers: studentsId,
                            },
                        },
                    });
                },
                text: 'remove',
                type: 'primary',
            }}
            title="Remove students"
        >
            {studentsId.length > 1
                ? `Are you sure you want to remove those students from the group ‘${groupData?.details.title}’? Instead they will be moved under the ‘No group assigned’ tab.`
                : `Are you sure you want to remove this student from the group ‘${groupData?.details.title}’? Instead this student will be moved under the ‘No group assigned’ tab.`}
        </ModalConfirmation>
    );
};
