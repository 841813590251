import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import {
    BigTitle,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const Error404PageWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 640px;
`;

export const StyledIconManager = styled(IconManager)`
    margin-bottom: 66px;
`;

export const StyledTitle = styled(BigTitle)`
    font-family: ${({ theme }) => theme.fontFamily.sketched};
    font-size: ${({ theme }) => theme.fontSizes.max}px;
    line-height: 0.8;
    margin-bottom: 40px;
    text-align: center;
`;

export const Description = styled(DefaultText)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 48px;
    text-align: center;
`;

export const StyledButton = styled(Button)`
    min-width: 323px;
`;
