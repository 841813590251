import { useEffect, useState } from 'react';
import { LibraryContentQueryVariables } from '@@types/graphql/LibraryContentQuery';
import { useLibraryContent } from '@state/lists/queries/useLibraryContent';
import {
    CONTENT_TYPE,
    LIBRARY_VIEW_CONTENT_SORTING,
} from '@@types/graphql/GraphqlGlobalTypes';

interface LibraryFiltersProps {
    sortBy: {
        label: string;
        value: LIBRARY_VIEW_CONTENT_SORTING;
    } | null;
    type: CONTENT_TYPE | CONTENT_TYPE[];
    topic: string[];
}

interface LibraryContentPageProps {
    generateVariables: (value?: number) => LibraryContentQueryVariables;
    libraryFilters: LibraryFiltersProps;
}

export const useLibraryContentPage = ({
    generateVariables,
    libraryFilters,
}: LibraryContentPageProps) => {
    const [offset, setOffset] = useState(0);
    const [paginateLoading, setPaginateLoading] = useState(false);

    const [libraryContent, { data, loading, fetchMore }] = useLibraryContent();

    const dataLength = data?.content?.length ?? 0;

    const onLoadMore = () => {
        setPaginateLoading(true);

        fetchMore?.({
            variables: generateVariables(dataLength),
        }).then(() => {
            setOffset(dataLength);
            setPaginateLoading(false);
        });
    };

    useEffect(() => {
        setOffset(0);
        libraryContent({
            variables: generateVariables(),
        });
    }, [libraryFilters]);

    return {
        data,
        hasNextPage: !!(dataLength && offset < dataLength),
        loading,
        onLoadMore,
        paginateLoading,
    };
};
