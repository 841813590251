import { DESKTOP_VIEW } from '@shared/constants';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { backgroundTypeErrorStyle } from '@components/_HOC/FieldMessage/FieldMessage.styled';

export default (debounceWait = 500) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    let isMobile = windowWidth < DESKTOP_VIEW;

    const onResize = debounce(() => {
        setWindowWidth(window.innerWidth);
    }, debounceWait);

    useEffect(() => {
        addEventListener('resize', onResize);
        return () => {
            removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        isMobile = windowWidth < DESKTOP_VIEW;
    }, [windowWidth]);
    return { isMobile, windowWidth };
};
