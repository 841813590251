import React, { useEffect } from 'react';
import { setSuccessPayment } from '@state/_redux/signup/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUserSubscriptionInfo } from '@state/auth/queries';
import DesktopSignupPaymentProcessingPage from './__desktop/DesktopSignupPaymentProcessingPage';
import MobileSignupPaymentProcessingPage from './__mobile/MobileSignupPaymentProcessingPage';
import paths from '@shared/paths';
import useMobile from '@utils/hooks/useMobile';

export interface IProps {}

const SignupPaymentProcessingPage: React.FC<IProps> = () => {
    const { isMobile } = useMobile();
    const history = useHistory();
    const dispatch = useDispatch();
    const { data, startPolling, stopPolling } = useUserSubscriptionInfo(true);

    useEffect(() => {
        startPolling(30000);
        dispatch(setSuccessPayment(true));

        return () => {
            stopPolling();
        };
    }, []);

    useEffect(() => {
        data &&
            data.getUserSubscriptionInfo.isPaymentConfirmed &&
            setTimeout(() => history.push(paths.signup_success), 5000);
    }, [data]);

    return isMobile ? (
        <MobileSignupPaymentProcessingPage />
    ) : (
        <DesktopSignupPaymentProcessingPage />
    );
};

export default SignupPaymentProcessingPage;
