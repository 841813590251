import { useRef, useState } from 'react';
import {
    GroupForm,
    StudentsGroupForm,
} from '@domains/Teacher/Groups/_forms/GroupForm/GroupForm';
import { useCreateStudentsGroup } from '@state/students/mutations/useCreateStudentsGroup';
import { useOnClickOutside } from '@utils/hooks';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import Modal from '@components/_universal/Modal/Modal';
import { DefaultText } from '@components/_universal/Typography.styled';
import {
    ModalContent,
    ModalTitle,
} from '@components/_modals/GlobalModals.styled';

export interface CreateGroupModalProps {
    setIsModalOpen: (state: boolean) => void;
}

export const CreateGroupModal = ({ setIsModalOpen }: CreateGroupModalProps) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsModalOpen(false));
    const [isError, setIsError] = useState(false);

    const [createStudentsGroup] = useCreateStudentsGroup(() => {
        setIsModalOpen(false);
    });

    const onSubmit = (data: StudentsGroupForm) => {
        if (isError) return;
        createStudentsGroup({
            variables: {
                input: {
                    title: data.groupTitle,
                    students: [],
                },
            },
        });
    };

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={() => setIsModalOpen(false)} />
                <ModalTitle>Create group</ModalTitle>
                <DefaultText>
                    Create student group by adding group title. You can assign
                    students on the group page after you created a group.
                </DefaultText>
                <GroupForm
                    buttonText="Create"
                    isError={isError}
                    onClose={() => setIsModalOpen(false)}
                    onSubmit={onSubmit}
                    setIsError={setIsError}
                />
            </ModalContent>
        </Modal>
    );
};
