import { createAction } from 'typesafe-actions';

export const setSuccessPayment = createAction(
    '@signup/SET_SUCCESS_PAYMENT',
)<boolean>();

export const setCorrectAccountData = createAction(
    '@signup/SET_CORRECT_ACCOUNT_DATA',
)<boolean>();

export const setIsAdminAsTeacher = createAction(
    '@signup/SET_IS_ADMIN_AS_TEACHER',
)<boolean>();

export const setIsFirstLogin = createAction(
    '@signup/SET_IS_FIRST_LOGIN',
)<boolean>();

export const setSkipSetPassword = createAction(
    '@signup/SET_SKIP_SET_PASSWORD',
)<boolean>();
