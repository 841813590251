import React from 'react';
import Loader from '@components/_universal/Loader/Loader';
import dayjs from 'dayjs';
import {
    DeleteButton,
    DeleteIcon,
    FileIcon,
    FileInfo,
    FileName,
    FileWrapper,
    InnerLink,
    UploadedFileCardWrapper,
} from './UploadedFileCard.styled';

interface IProps {
    canRemove?: boolean;
    fileName?: string;
    getFile: () => void;
    loading?: boolean;
    onRemove?: () => void;
    uploadedDate?: string;
}

const UploadedFileCard: React.FC<IProps> = ({
    canRemove,
    fileName,
    getFile,
    loading,
    onRemove,
    uploadedDate,
}) => {
    return (
        <UploadedFileCardWrapper>
            <InnerLink onClick={getFile}>
                <FileIcon name={'File'} size={46} />
                <FileWrapper>
                    <FileName>{fileName}</FileName>
                    <FileInfo>
                        Uploaded:{' '}
                        {dayjs(uploadedDate).format('DD.MM.YYYY HH:mm')}
                    </FileInfo>
                </FileWrapper>
            </InnerLink>
            {canRemove &&
                (loading ? (
                    <Loader />
                ) : (
                    <DeleteButton onClick={onRemove}>
                        <DeleteIcon name={'Close'} size={24} />
                    </DeleteButton>
                ))}
        </UploadedFileCardWrapper>
    );
};

export default UploadedFileCard;
