import React from 'react';
import {
    CreatePasswordTipWrapper,
    SpeechBubble,
    TipOrnament,
    TipText,
    TipTitle,
} from './CreatePasswordTip.styled';

export interface IProps {}

const CreatePasswordTip: React.FC<IProps> = () => {
    return (
        <CreatePasswordTipWrapper>
            <TipOrnament name="SupportGirl" size={200} />
            <SpeechBubble>
                <TipTitle>Hi there!</TipTitle>
                <TipText>
                    You never created a password, so you can create one now.
                </TipText>
            </SpeechBubble>
        </CreatePasswordTipWrapper>
    );
};

export default CreatePasswordTip;
