import React from 'react';
import {
    BackgroundDecorator,
    DashboardHumanOrnamentIcon,
    OrnamentSectionWrapper,
} from './OrnamentSection.styled';

interface IProps {
    className?: string;
}

const OrnamentSection: React.FC<IProps> = ({ className }) => {
    return (
        <OrnamentSectionWrapper>
            <DashboardHumanOrnamentIcon
                name={'DashboardHumanOrnament'}
                size={618}
                sizeY={258}
            />
            <BackgroundDecorator
                name={'DashboardDecoratorMiddle'}
                size={490}
                sizeY={450}
            />
        </OrnamentSectionWrapper>
    );
};

export default OrnamentSection;
