import styled from 'styled-components';
import { Button } from '@components/_universal';
import {
    BigHeader,
    DefaultText,
    SubTitle,
    Title,
} from '@components/_universal/Typography.styled';

export const LessonPageWrapper = styled.div``;

export const LoaderWrapper = styled.div`
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const PageTitle = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    min-height: 44px;
    padding-top: 34px;
`;

export const SectionHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 80px 0 48px;
`;

export const HeaderButton = styled(Button)`
    margin-right: 32px;
`;

export const SectionTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 24px 0 0;
`;

export const DescriptionTitle = styled(BigHeader)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 30px 0 16px;
`;

export const DescriptionText = styled(DefaultText)`
    margin-bottom: 100px;
`;

export const InnerWrapper = styled.div`
    display: flex;
`;

export const NavigationListWrapper = styled.div`
    margin-left: 32px;
`;

export const ListTitle = styled(BigHeader)`
    display: inline-flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: -8px 0 16px;
`;

export const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
`;
