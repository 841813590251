import { ChildHomeworkQuery_getChildHomeworks_files } from '@@types/graphql/ChildHomeworkQuery';
import { useHomeworkFileQuery } from '@state/files/queries';
import dayjs from 'dayjs';
import React from 'react';
import {
    DownloadFileIcon,
    FileName,
    FileUploaded,
    UploadedFileWrapper,
    WorkFileWrapper,
} from './UploadedFile.styled';

interface IProps {
    file: ChildHomeworkQuery_getChildHomeworks_files;
    date?: Date;
    homeworkId: number;
}

const UploadedFile: React.FC<IProps> = ({ file, homeworkId, date }) => {
    const [getFile] = useHomeworkFileQuery();

    return (
        <UploadedFileWrapper
            onClick={() =>
                getFile({
                    variables: {
                        homeworkId,
                        type: file.type,
                    },
                })
            }
        >
            <DownloadFileIcon name={'MentorDownload'} size={46} sizeY={46} />
            <WorkFileWrapper>
                <FileName>{file.filename}</FileName>
                {date && (
                    <FileUploaded>
                        Uploaded: {dayjs(date).format('DD.MM.YYYY HH.mm')}
                    </FileUploaded>
                )}
            </WorkFileWrapper>
        </UploadedFileWrapper>
    );
};

export default UploadedFile;
