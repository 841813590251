import * as yup from 'yup';
import {
    confirmPasswordValidation,
    passwordSymbolsValidation,
} from '@utils/shared/validations/formErrors';

export const createPasswordValidation = yup.object().shape({
    confirmPassword: confirmPasswordValidation,
    newPassword: passwordSymbolsValidation,
});

export const agreeRulesValidation = yup.object().shape({
    agreeRules: yup.boolean().isTrue('This consent is required.'),
});
