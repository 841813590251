import React from 'react';
import DesktopChooseAvatarPage from './_desktop/DesktopChooseAvatarPage';
import MobileChooseAvatarPage from './_mobile/MobileChooseAvatarPage';
import { IOnboardingForm } from '@domains/Auth/Onboarding/Onboarding';
import { useFormContext } from 'react-hook-form';
import { useMobile } from '@utils/hooks';

interface IProps {}

const ChooseAvatarPage: React.FC<IProps> = () => {
    const { isMobile } = useMobile();
    const { control, setValue, watch } = useFormContext<IOnboardingForm>();
    const avatar = watch('avatar');

    const selectAvatar = (index: number) => {
        setValue('avatar', index);
    };

    return isMobile ? (
        <MobileChooseAvatarPage
            avatar={avatar}
            control={control}
            selectAvatar={selectAvatar}
        />
    ) : (
        <DesktopChooseAvatarPage
            avatar={avatar}
            control={control}
            selectAvatar={selectAvatar}
        />
    );
};

export default ChooseAvatarPage;
