import { gql, useMutation } from '@apollo/client';
import {
    SendLiveExperienceMutation,
    SendLiveExperienceMutationVariables,
} from '@@types/graphql/SendLiveExperienceMutation';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { GET_LIVE_EXPERIENCE_QUERY } from '@state/liveExperience/queries/useGetLiveExperienceQuery';
import { useParams } from 'react-router';
import { GetLiveExperienceQuery } from '@@types/graphql/GetLiveExperienceQuery';

const SEND_LIVE_EXPERIENCE_MUTATION = gql`
    mutation SendLiveExperienceMutation(
        $liveExperienceId: String!
        $homeworkFile: Upload!
    ) {
        sendLiveExperienceHomework(
            liveExperienceId: $liveExperienceId
            homeworkFile: $homeworkFile
        ) {
            id
            feedbackGivenAt
            feedbackBy {
                name
                surname
            }
            mentorFeedback
            createdAt
            files {
                id
                filename
                type
            }
        }
    }
`;

export default (onError?: () => void) => {
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();

    return useMutation<
        SendLiveExperienceMutation,
        SendLiveExperienceMutationVariables
    >(SEND_LIVE_EXPERIENCE_MUTATION, {
        onError: (error) => {
            onError?.();
            notify(error.message)[ToastTypes.ERROR]();
        },
        update: (cache, { data }) => {
            if (!data) return;
            const query = cache.readQuery<GetLiveExperienceQuery>({
                query: GET_LIVE_EXPERIENCE_QUERY,
                variables: {
                    id: liveExperienceId,
                },
            });

            query &&
                cache.writeQuery({
                    query: GET_LIVE_EXPERIENCE_QUERY,
                    variables: { id: liveExperienceId },
                    data: {
                        getLiveExperience: {
                            ...query.getLiveExperience,
                            isConsumed: true,
                            homework: data.sendLiveExperienceHomework,
                        },
                    },
                });
        },
    });
};
