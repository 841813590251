import styled from 'styled-components';
import { Title } from '@components/_universal/Typography.styled';
import Article from '@domains/Child/Dashboard/_components/Article/Article';
import SplitCard from '@components/_universal/Cards/SplitCard/SplitCard';
import TextBoxCard from '@components/_universal/Cards/TextBoxCard/TextBoxCard';

export const LatestPostsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 85px;
    position: relative;
    z-index: 9;
`;

export const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 40px;
    width: 100%;
    z-index: 9;
`;

export const LatestArticlesAndLibraryWrapper = styled.div`
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 32px;
    width: 100%;
`;

export const ArticleWrapper = styled.div<{ withBoxes?: boolean }>`
    align-content: flex-start;
    display: grid;
    gap: ${({ withBoxes }) => (withBoxes ? 32 : 24)}px;
    grid-auto-rows: 1fr;
`;

export const StyledArticle = styled(Article)`
    margin: 0;
`;

export const StyledTextBoxCard = styled(TextBoxCard)`
    width: 100%;
`;

export const StyledSplitCard = styled(SplitCard)`
    grid-column: auto / span 2;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 48px 0;
    width: 100%;
`;
