import { gql, useLazyQuery } from '@apollo/client';
import {
    UserNotificationsQuery,
    UserNotificationsQueryVariables,
} from '@@types/graphql/UserNotificationsQuery';

export const USER_NOTIFICATIONS_QUERY = gql`
    query UserNotificationsQuery($pagination: NotificationPagintation) {
        getUserNotifications(pagination: $pagination) {
            id
            type
            sendAt
            completedAt
            createdAt
            updatedAt
            redirectionId
            payload {
                learner {
                    id
                    name
                    surname
                }
                content {
                    __typename
                    ... on Course {
                        _id
                        type
                        name
                    }
                    ... on StandaloneVideo {
                        _id
                        type
                        name
                    }
                    ... on LiveExperience {
                        _id
                        type
                        name
                        startDate
                    }
                }
            }
            sendBy {
                name
                surname
            }
        }
    }
`;

export default (networkOnly?: boolean) =>
    useLazyQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(
        USER_NOTIFICATIONS_QUERY,
        {
            onError: () => {},
            fetchPolicy: networkOnly ? 'network-only' : 'cache-first',
        },
    );
