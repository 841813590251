import styled, { keyframes } from 'styled-components';
import { IconManager } from '@components/_universal';
import { LoaderSize } from './Loader';

const loaderAnimation = keyframes`
    from {
            -webkit-transform: rotate(0deg);
    }
    to {
            -webkit-transform: rotate(359deg);
    }
`;

const sizeSelector = (size?: LoaderSize) => {
    if (size === 'large') return 'transform:scale(2)';
    if (size === 'medium') return 'transform:scale(1.5)';
    return;
};

export const LoaderWrapper = styled.div<{
    center?: boolean;
    size?: LoaderSize;
}>`
    display: inline-flex;
    justify-content: center;
    margin: ${({ center }) => (center ? '0 auto' : 0)};
    ${({ size }) => sizeSelector(size)}
`;

export const LoaderIcon = styled(IconManager)`
    animation: ${loaderAnimation} 2s infinite linear;
    display: inline-flex;
`;
