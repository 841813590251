import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';
import { boolean } from 'yup';

export const PaymentFormWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
`;

export const StepperWrapper = styled.div`
    margin-bottom: 32px;
    width: 100%;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 24px;
    }
`;

export const StepperInformation = styled(DefaultText)`
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
    padding-top: 12px;
    text-align: center;
`;

export const SumOfPayment = styled(DefaultText)`
    font-size: 100px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1;
    margin-bottom: 30px;
    text-align: center;

    @media (max-width: ${breakpoints.lg}px) {
        font-size: 76px;
        margin-bottom: 36px;
    }
`;

export const Currency = styled.span`
    font-size: 70px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1;

    @media (max-width: ${breakpoints.lg}px) {
        font-size: 50px;
    }
`;

export const StyledButton = styled(Button)<{ withoutSubscribe?: boolean }>`
    flex: 1;
    ${({ withoutSubscribe }) => withoutSubscribe && 'display: none;'}
`;

export const AdditionalInformation = styled(DefaultText)<{
    withoutSubscribe?: boolean;
}>`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    line-height: 1.65;
    margin: 16px auto 0;
    text-align: center;
    ${({ withoutSubscribe }) => withoutSubscribe && 'display: none;'}

    @media (max-width: ${breakpoints.lg}px) {
        margin: 24px auto 0;
    }
`;
