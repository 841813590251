import styled from 'styled-components';
import { Button } from '@components/_universal';
import { DefaultText, Title } from '@components/_universal/Typography.styled';

export const SubscriptionParentPageWrapper = styled.div``;

export const PageTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 48px;
`;

export const SectionWrapper = styled.div``;

export const SectionInfo = styled.div`
    max-width: 400px;
`;

export const SectionActions = styled.div`
    margin-left: 48px;
`;

export const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const SectionDivider = styled.div`
    background: ${({ theme }) => theme.colors.background_tertiary};
    height: 2px;
    margin: 48px 0;
    width: 100%;
`;

export const HeaderText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 16px 0 0;
`;

export const ActionButton = styled(Button)`
    height: 40px;
    justify-self: flex-end;
    margin-left: auto;
    min-width: 198px;
    width: 100%;
`;
