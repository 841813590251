import React from 'react';
import { HOMEWORK_FILE_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { useHomeworkFileQuery } from '@state/files/queries';
import {
    ButtonTexts,
    FileName,
    HomeworkButtonWrapper,
    HomeworkIcon,
    SubText,
} from './HomeworkButton.styled';

export interface IProps {
    className?: string;
    file: {
        filename: string;
        id: number;
        mimetype: string;
        type: HOMEWORK_FILE_TYPE;
    };
    fileSubtext?: string;
    homeworkId: number;
}

const HomeworkButton: React.FC<IProps> = ({
    children,
    className,
    file,
    fileSubtext,
    homeworkId,
}) => {
    const [getFile] = useHomeworkFileQuery();

    return (
        <HomeworkButtonWrapper
            className={className}
            onClick={() =>
                getFile({
                    variables: {
                        homeworkId: homeworkId,
                        type: file.type,
                    },
                })
            }
        >
            {children}
            <HomeworkIcon size={24} name="Download" />
            <ButtonTexts>
                <FileName>{file.filename}</FileName>
                {fileSubtext && <SubText>{fileSubtext}</SubText>}
            </ButtonTexts>
        </HomeworkButtonWrapper>
    );
};

export default HomeworkButton;
