import styled, { css } from 'styled-components';
import { Button } from '@components/_universal';
import { Counter } from '@components/_universal/Counter/Counter';
import {
    BigHeader,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const ExperienceInfoWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 45px;
    position: relative;
`;

export const JoinExperienceWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    height: 100%;
    padding: 0 64px;
`;

export const StyledBigHeader = styled(BigHeader)(
    ({ theme: { colors } }) => css`
        color: ${colors.text_white};
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        margin-bottom: 24px;
        text-align: center;
    `,
);

export const StyledCounter = styled(Counter)`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const ButtonWrapper = styled.div<{ isMoreMargin?: boolean }>`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: ${({ isMoreMargin }) => (!!isMoreMargin ? 42 : 28)}px;
    position: relative;
`;

export const StyledButton = styled(Button)`
    min-width: 240px;
`;

export const SignedUpExperienceWrapper = styled.div``;

export const DoneExperienceWrapper = styled.div`
    max-width: 690px;
    position: relative;
    z-index: 9;
`;

export const DescriptionText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
    text-align: center;
`;

export const JoinExperienceDescription = styled.div``;
