import React from 'react';
import { externalLinks } from '@shared/paths';
import {
    Content,
    FooterBar,
    FooterMobileOrnamentIcon,
    FooterWrapper,
    Header,
    LogoSignupIcon,
    ResetPasswordLayoutWrapper,
    SignUpLink,
} from './ResetPasswordLayout.styled';

interface IProps {}

const ResetPasswordLayout: React.FC<IProps> = ({ children }) => {
    return (
        <ResetPasswordLayoutWrapper>
            <Header>
                <SignUpLink href={externalLinks.homepage} target="_blank">
                    <LogoSignupIcon
                        fill={['background_secondary', 'background_secondary']}
                        name={'LogoWithText'}
                        size={206}
                        sizeY={45}
                    />
                </SignUpLink>
            </Header>
            <Content>{children}</Content>
            <FooterWrapper>
                <FooterMobileOrnamentIcon
                    size={90}
                    sizeY={52}
                    name={'FooterMobileOrnamentIcon'}
                />
                <FooterBar isWhiteText />
            </FooterWrapper>
        </ResetPasswordLayoutWrapper>
    );
};

export default ResetPasswordLayout;
