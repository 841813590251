import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import {
    BigHeader,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const NotificationsWrapper = styled.div`
    background: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    box-shadow: ${({ theme }) => theme.dark_grey_shadow};
    height: 500px;
    overflow: hidden;
    position: absolute;
    right: 35px;
    top: 54px;
    width: 420px;
    z-index: 9;
`;

export const NotificationsHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 24px 32px 8px;
`;

export const HeaderTitle = styled(BigHeader)`
    font-size: ${({ theme }) => theme.fontSizes.xl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
`;

export const HeaderReadAll = styled(DefaultText)`
    cursor: ${({ theme }) => theme.cursors.pointer};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;

    &:hover {
        text-decoration: underline;
        text-underline-position: under;
    }
`;

export const SimpleBarWrapper = styled(SimpleBar)`
    max-height: 440px;
`;

export const Item = styled.div`
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    outline: none;
    width: 100%;

    &:hover {
        background: ${({ theme }) => theme.colors.background_quaternary};
    }
`;

export const ItemInner = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.background_tertiary};
    display: flex;
    justify-content: space-between;
    margin-right: 32px;
    padding: 16px 0;
    width: 100%;

    &:last-child {
        border-bottom: 0;
    }
`;

export const ItemDot = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 22px;
    min-width: 32px;
`;

export const ItemContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ItemTitle = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
`;

export const ItemDescription = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    margin: 0;
`;

export const ItemDateWrapper = styled.div`
    color: ${({ theme }) => theme.colors.text_secondary};
    display: flex;
    flex-shrink: 0;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    margin-top: 4px;
    white-space: nowrap;
`;

// Empty notifications
export const EmptyNotificationsWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 84px;
`;

export const EmptyNotificationsTitle = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.xl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 16px;
    text-align: center;
`;

export const EmptyNotificationsText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    margin-bottom: 60px;
    text-align: center;
`;

export const EmptyNotificationsDescription = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    text-align: center;
`;

export const EmptyNotificationsLink = styled(Link)`
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        text-underline-position: under;
    }
`;

// Loader
export const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
`;
