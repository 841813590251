import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';

export const VideoListWrapper = styled(SimpleBar)<{
    variant: 'primary' | 'secondary';
}>`
    max-height: 540px;
    min-width: 350px;
    padding: 0 32px 0 0;

    .simplebar-scrollbar::before {
        background: ${({ theme }) => theme.colors.primary};
        margin-bottom: 24px;
        width: 6px;
    }

    .simplebar-visible::before {
        opacity: 1;
    }

    .simplebar-track::before {
        background: ${({ variant, theme }) =>
            variant === 'primary'
                ? theme.colors.background_tertiary
                : theme.colors.background_secondary};
        content: '';
        height: 94%;
        left: 3px;
        position: absolute;
        top: 5px;
        width: 3px;
    }
`;

export const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 350px;
`;

export const ListItem = styled(Link)<{
    $active?: boolean;
    variant: 'primary' | 'secondary';
}>`
    align-items: center;
    background: ${({ $active, theme }) =>
        $active ? theme.colors.primary : theme.colors.background_quaternary};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    display: flex;
    height: 90px;
    margin-bottom: 16px;
    padding: 16px;
    position: relative;
    text-decoration: none;

    ${({ theme, variant }) =>
        variant === 'secondary' && `box-shadow:${theme.light_green_shadow}`};

    &:hover {
        background: ${({ $active, theme }) =>
            !$active && theme.colors.background_secondary};
        box-shadow: ${({ theme }) => theme.light_green_shadow};
        cursor: pointer;
    }
`;

export const ListItemIcon = styled(IconManager)`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
`;

export const ListItemContent = styled.div`
    padding: 0 60px 0 16px;
`;

export const ListItemTitle = styled(DefaultText)<{ active?: boolean }>`
    color: ${({ active, theme }) =>
        active ? theme.colors.text_white : theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
`;

export const ListItemTime = styled(DefaultText)<{ active?: boolean }>`
    color: ${({ active, theme }) =>
        active ? theme.colors.text_white : theme.colors.text_assistive};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    margin: 0;
`;

export const ImageOverlay = styled.div`
    background: ${({ theme }) => theme.opacityColors.violet};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    height: 48px;
    position: absolute;
    width: 48px;
    z-index: 9;
`;

export const ListItemImage = styled.img`
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    height: 48px;
    width: 48px;
`;

export const ListItemImageWrapper = styled.div`
    position: relative;
`;

export const ImageDecorator = styled(IconManager)`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const ImageCornerDecorator = styled(IconManager)`
    bottom: -5px;
    position: absolute;
    right: 0;
    transform: translateX(50%);
`;
