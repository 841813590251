import { gql, useQuery } from '@apollo/client';
import { StudentsActivityStatsQuery } from '@@types/graphql/StudentsActivityStatsQuery';

const STUDENTS_ACTIVITY_STATS_QUERY = gql`
    query StudentsActivityStatsQuery {
        getStudentsActivityStats {
            level
            standaloneVideos
            liveExperiences
            courses
            usersCount
            percentage
        }
    }
`;

export default () =>
    useQuery<StudentsActivityStatsQuery>(STUDENTS_ACTIVITY_STATS_QUERY, {
        onError: (err) => console.log(err.message),
        fetchPolicy: 'network-only',
    });
