import React from 'react';
import {
    AccountSubLayoutWrapper,
    BackgroundDecorator,
    Content,
    PageTitle,
    SubNavigationBar,
} from './AccountSubLayout.styled';

interface IProps {}

const AccountSubLayout: React.FC<IProps> = ({ children }) => {
    return (
        <AccountSubLayoutWrapper>
            <PageTitle>My account</PageTitle>
            <SubNavigationBar />
            <Content>
                <BackgroundDecorator
                    name={'AccountBackground'}
                    size={515}
                    sizeY={472}
                />
                {children}
            </Content>
        </AccountSubLayoutWrapper>
    );
};

export default AccountSubLayout;
