import styled, { css } from 'styled-components';

export const UploadStudentsListWrapper = styled.div``;

export const StyledTableCell = styled.td<{ isError?: boolean }>(
    ({ isError, theme: { colors } }) => css`
        ${isError &&
        css`
            color: ${colors.error};
        `};
    `,
);
