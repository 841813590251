import React, { useMemo } from 'react';
import { SchoolFilters } from '@components/SchoolFilters/SchoolFilters';
import ActivityFeed from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/ActivityFeed/ActivityFeed';
import ActivityFeedEmptyState from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/ActivityFeedEmptyState/ActivityFeedEmptyState';
import Loader from '@components/_universal/Loader/Loader';
import Paginate from '@components/_universal/Paginate/Paginate';
import StudentsActivityCard from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/StudentsActivityCard/StudentsActivityCard';
import useDashboardTeacher from './useDashboardTeacher';
import {
    ActivityFeedLoaderWrapper,
    DashboardTeacherWrapper,
    DoubleActivityCards,
    LoaderWrapper,
    SectionTitle,
    SectionTitleWrapper,
    StudentsActivityWrapper,
} from './DashboardTeacher.styled';

export interface IProps {}

const DashboardTeacher: React.FC<IProps> = () => {
    const {
        activityStats,
        feedData,
        getFilters,
        loading,
        onLoadMore,
        paginateLoading,
        resetFilters,
        setResetFilters,
        studentsStatsLoading,
    } = useDashboardTeacher();

    const activityFeedData = useMemo(() => feedData?.getActivityFeeds?.feeds, [
        feedData,
    ]);

    return (
        <DashboardTeacherWrapper>
            <SectionTitle>Students activity</SectionTitle>

            {studentsStatsLoading ? (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            ) : (
                <StudentsActivityWrapper>
                    {activityStats.slice(0, 3).map((stats) => (
                        <StudentsActivityCard
                            key={stats.frequencyValue}
                            {...stats}
                        />
                    ))}
                    <DoubleActivityCards>
                        {activityStats.slice(3, 5).map((stats) => (
                            <StudentsActivityCard
                                key={stats.frequencyValue}
                                {...stats}
                            />
                        ))}
                    </DoubleActivityCards>
                </StudentsActivityWrapper>
            )}
            <SectionTitleWrapper>
                <SectionTitle>Activity feed</SectionTitle>
                <SchoolFilters
                    getFilters={getFilters}
                    reset={resetFilters}
                    setReset={setResetFilters}
                />
            </SectionTitleWrapper>
            {activityFeedData && (
                <Paginate
                    loading={loading}
                    queryLoading={paginateLoading}
                    hasNextPage={
                        feedData?.getActivityFeeds.meta.hasNextPage || false
                    }
                    onLoadMore={onLoadMore}
                >
                    <ActivityFeed activities={activityFeedData} />
                </Paginate>
            )}
            {!activityFeedData && loading && (
                <ActivityFeedLoaderWrapper>
                    <Loader />
                </ActivityFeedLoaderWrapper>
            )}
            {!loading && !activityFeedData?.length && (
                <ActivityFeedEmptyState
                    resetFilters={() => setResetFilters(true)}
                />
            )}
        </DashboardTeacherWrapper>
    );
};

export default DashboardTeacher;
