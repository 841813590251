import styled from 'styled-components';
import { Button } from '@components/_universal';
import { LinkText } from '@components/_universal/Typography.styled';

export const AccountNaviagationWrapper = styled.div`
    background: ${({ theme }) => theme.colors.background_quaternary};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.circle}px;
    border-top-left-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 95px 70px 0;
`;

export const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LogoutButton = styled(Button)`
    margin-top: 25px;
    max-width: 150px;
`;

export const Link = styled(LinkText)`
    color: ${({ theme }) => theme.colors.text};
    line-height: 1.75;
    margin-bottom: 25px;
    text-decoration: none;

    &.accountNavActive {
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        position: relative;

        &::before {
            background: ${({ theme }) => theme.colors.primary};
            border-radius: ${({ theme }) => theme.borderRadius.circle}px;
            content: '';
            height: 12px;
            left: -16px;
            position: absolute;
            top: 50%;
            transform: translate(-100%, -50%);
            width: 12px;
        }
    }
`;
