import React from 'react';
import { useHistory } from 'react-router-dom';
import LoginFooter from '@domains/Auth/_components/LoginFooter/LoginFooter';
import LoginFormBreak from '@domains/Auth/_components/LoginFormBreak/LoginFormBreak';
import LoginPasswordForm from '@domains/Auth/_forms/LoginPasswordForm/LoginPasswordForm';
import paths, { externalLinks } from '@shared/paths';
import {
    ButtonLink,
    FormWrapper,
    MobileHelloIcon,
    MobileLoginLinkPageWrapper,
    MobileLogoIcon,
    MobileLogoLink,
} from './MobileLoginPasswordPage.styled';

interface IProps {}

const MobileLoginLinkPage: React.FC<IProps> = () => {
    const history = useHistory();

    return (
        <MobileLoginLinkPageWrapper>
            <MobileLogoLink href={externalLinks.homepage} target="_blank">
                <MobileLogoIcon
                    fill={['text_white', 'text_white']}
                    name={'LogoWithText'}
                    size={190}
                    sizeY={45}
                />
            </MobileLogoLink>

            <FormWrapper>
                <MobileHelloIcon size={100} name={'HelloMobile'} />
                <LoginPasswordForm />
                <LoginFormBreak />
                <ButtonLink
                    buttonType="tertiary"
                    onPress={() => history.push(paths.link_login)}
                >
                    log in without a password
                </ButtonLink>
            </FormWrapper>
            <LoginFooter />
        </MobileLoginLinkPageWrapper>
    );
};

export default MobileLoginLinkPage;
