import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import { Title } from '@components/_universal/Typography.styled';

export const SetPasswordModalWrapper = styled.div`
    background: ${({ theme }) => theme.colors.app_bg};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    display: flex;
    flex-direction: column;
    padding: 56px 80px;
    position: relative;
    width: 960px;
`;

export const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 32px;
`;

export const IconWrapper = styled(IconManager)`
    position: absolute;
    right: 40px;
    top: 40px;
`;
