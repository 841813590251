import { useHistory, useLocation } from 'react-router';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { useEffect } from 'react';
import logout from '@utils/logout';
import paths from '@shared/paths';
import { useAuthMeQuery } from '@state/auth/queries';
import useTrial from '@utils/hooks/useTrial';
import { setEndedModal } from '@state/_redux/layout/actions';
import { useDispatch, useSelector } from 'react-redux';

export default () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const { data: meData } = useAuthMeQuery();
    const { hadTrialNoPlan, isTrialEnded } = useTrial();
    const isChooseYourPlan = location.pathname === paths.choose_your_plan;

    const trialEndedModal = useSelector(({ layout }) => layout.trialEndedModal);
    const setTrialEndedModal = (value: boolean) =>
        dispatch(setEndedModal(value));

    const setModal = !isChooseYourPlan && hadTrialNoPlan && isTrialEnded;

    const trialEndedModalData: Record<
        USER_TYPE,
        | {
              approveText: string;
              cancelText: string;
              content: string;
              onApprove: () => void;
              onCancel: () => void;
              title: string;
          }
        | undefined
    > = {
        [USER_TYPE.CHILD]: {
            approveText: 'Log in as parent',
            cancelText: 'Ok, i understand',
            content:
                'It looks like your free trial or subscription has ended. To continue using our platform you need to ask your parent to start a new subscription. We will be waiting for you to come back!',
            onApprove: () => logout(),
            onCancel: () => logout(),
            title: 'Oops... your free trial has ended',
        },
        [USER_TYPE.PARENT]: {
            approveText: 'Yes, subscribe now',
            cancelText: 'Maybe later',
            content:
                'It looks like your free trial has ended. Would you like to continue using our platform and give your children a possibility to grow with us? ',
            onApprove: () => history.push(paths.choose_your_plan),
            onCancel: () => logout(),
            title: 'Oops... your free trial has ended',
        },
        [USER_TYPE.ADMIN]: undefined,
        [USER_TYPE.MENTOR]: undefined,
        [USER_TYPE.STUDENT]: undefined,
        [USER_TYPE.TEACHER]: undefined,
    };

    useEffect(() => {
        !isChooseYourPlan && setTrialEndedModal(setModal);
    }, [isChooseYourPlan, hadTrialNoPlan, isTrialEnded]);

    useEffect(() => {
        isChooseYourPlan && setTrialEndedModal(false);
    }, [isChooseYourPlan]);

    return {
        setTrialEndedModal,
        trialEndedData:
            meData?.me.type === USER_TYPE.PARENT
                ? trialEndedModalData.PARENT
                : trialEndedModalData.CHILD,
        trialEndedModal,
    };
};
