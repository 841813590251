import * as yup from 'yup';
import {
    emailValidation,
    selectValidation,
    textValidation,
    yearValidation,
} from '@utils/shared/validations/formErrors';

export const validation = yup.object().shape({
    className: selectValidation,
    email: emailValidation,
    name: textValidation,
    surname: textValidation,
    year: yearValidation,
});
