import {
    ApolloClient,
    ApolloLink,
    from,
    gql,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { API_URL } from '@shared/constants';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import { createUploadLink } from 'apollo-upload-client';
import { NOTIFICATION_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { onError } from '@apollo/client/link/error';
import { REFRESH_SESSION_TOKEN_MUTATION } from '@state/auth/mutations/useRefreshSessionTokenMutation';
import { setEndedModal } from '@state/_redux/layout/actions';
import axios from 'axios';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import promiseToObservable from '@utils/promiseToObservable';
import store from './configureStore';
import {
    RefreshSessionTokenMutation,
    RefreshSessionTokenMutationVariables,
} from '@@types/graphql/RefreshSessionTokenMutation';

const typeDefs = gql`
    extend type Query {
        notificationLiveExperiences: [String!]
    }
`;

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                notificationLiveExperiences(_, { readField, cache }) {
                    const notifications = readField<
                        { __ref: string }[] | undefined
                    >('getUserNotifications', readField('type'))?.map((item) =>
                        cache.readFragment({
                            id: item['__ref'],
                            fragment: gql`
                                fragment PartialNotification on Notification {
                                    type
                                    payload {
                                        content {
                                            ... on LiveExperience {
                                                _id
                                            }
                                        }
                                    }
                                }
                            `,
                        }),
                    );
                    return notifications
                        ?.filter(
                            //TODO add type to item from typePolicies
                            (item: any) =>
                                item.type === NOTIFICATION_TYPE.LE_NOTIFY,
                        )
                        .map((item: any) => item.payload.content?._id);
                },
                getUserNotifications: { keyArgs: false },
                searchForContent: {
                    read(existing) {
                        return existing;
                    },
                    merge(existing, incoming, { args }) {
                        const offset = args?.paginationInput.offset;

                        return existing && offset !== 0
                            ? [...existing, ...incoming]
                            : incoming;
                    },
                },
                getContentsForLibraryView: {
                    read(existing) {
                        return existing;
                    },
                    merge(existing, incoming, { args }) {
                        const offset = args?.paginationInput.offset;

                        return existing && offset !== 0
                            ? [...existing, ...incoming]
                            : incoming;
                    },
                },
                getConsumedContent: {
                    read(existing) {
                        return existing;
                    },
                    merge(existing, incoming, { args }) {
                        const offset = args?.pagination.offset;

                        return existing && offset !== 0
                            ? [...existing, ...incoming]
                            : incoming;
                    },
                },
                getActivityFeeds: {
                    read(existing) {
                        return existing;
                    },
                    merge(existing, incoming, { args }) {
                        const page = args?.input.pagination.page;

                        return existing && page !== 1
                            ? {
                                  feeds: [...existing.feeds, ...incoming.feeds],
                                  meta: incoming.meta,
                              }
                            : incoming;
                    },
                },
                getStudentActivity: {
                    read(existing) {
                        return existing;
                    },
                    merge(existing, incoming, { args }) {
                        const page = args?.input.pagination.page;

                        return existing && page !== 1
                            ? {
                                  result: [
                                      ...existing.result,
                                      ...incoming.result,
                                  ],
                                  counts: incoming.counts,
                              }
                            : incoming;
                    },
                },
            },
        },
    },
});

const httpLink = createUploadLink({
    uri: API_URL,
    // FIXME: remove ts-ignore
    // @ts-ignore
    fetch: buildAxiosFetch(axios, (config, input, init) => ({
        ...config,
        onUploadProgress: init.onUploadProgress,
    })),
});

const refreshTokenLink = onError(({ operation, forward, graphQLErrors }) => {
    const refreshToken = LocalStorageManager.getValue('refreshToken');
    const isRefreshOperation = operation.operationName.includes('Refresh');
    const isUnpaid = graphQLErrors?.[0]?.extensions?.code === 'UNPAID';

    if (isUnpaid) {
        store.dispatch(setEndedModal(true));
    }

    if (!isRefreshOperation && refreshToken && !isUnpaid) {
        const promise = client.mutate<
            RefreshSessionTokenMutation,
            RefreshSessionTokenMutationVariables
        >({
            mutation: REFRESH_SESSION_TOKEN_MUTATION,
            variables: {
                refreshToken: refreshToken,
            },
        });
        return promiseToObservable(promise).flatMap((res) => {
            const response = (res as { data: RefreshSessionTokenMutation }).data
                .refreshSessionToken;
            LocalStorageManager.setValue('sessionToken', response.sessionToken);
            LocalStorageManager.setValue('refreshToken', response.refreshToken);
            operation.setContext({
                headers: {
                    ...operation.getContext().headers,
                    authorization: `Bearer ${response.sessionToken}`,
                },
            });
            return forward(operation);
        });
    }
});

const authMiddleware = new ApolloLink((operation, forward) => {
    const sessionToken = LocalStorageManager.getValue('sessionToken');
    const isRefreshOperation = operation.operationName.includes('Refresh');

    operation.setContext({
        headers: {
            ...(!isRefreshOperation &&
                sessionToken && { authorization: `Bearer ${sessionToken}` }),
        },
    });

    return forward(operation);
});

const client = new ApolloClient({
    name: '8-billion-ideas-frontend-user',
    // FIXME: remove ts-ignore
    // @ts-ignore
    link: from([refreshTokenLink, authMiddleware, httpLink]),
    cache,
    typeDefs,
});

export default client;
