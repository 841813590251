import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const DesktopAgreeRulesPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;

export const AgreeRulesPageContent = styled.div`
    flex: 1;
`;

export const AgreeRulesPageFooter = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;

export const ReadOurRulesIcon = styled(IconManager)`
    margin-left: -10px;
    margin-top: -55px;
`;

export const AgreeRulesOrnamentIcon = styled(IconManager)`
    position: absolute;
    right: -70px;
    top: 20px;
    z-index: 1;
`;

export const AgreeRulesTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 16px;
`;

export const AgreeRulesDescription = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.7;
    max-width: 440px;
`;

export const RulesList = styled.div`
    display: grid;
    grid-column-gap: 32px;
    grid-template-columns: 1fr 1fr;
`;

export const CheckboxWrapper = styled.div`
    margin-bottom: 24px;
    position: relative;
    text-align: center;
    width: 100%;
`;

export const OnboardingCheckboxFieldIcon = styled(IconManager)`
    position: absolute;
`;
