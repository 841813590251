import { gql, useQuery } from '@apollo/client';
import {
    UpcomingLiveExperiencesQuery,
    UpcomingLiveExperiencesQueryVariables,
} from '@@types/graphql/UpcomingLiveExperiencesQuery';

export const UPCOMING_LIVE_EXPERIENCES_QUERY = gql`
    query UpcomingLiveExperiencesQuery(
        $pagination: GetUpcomingLiveExperiencesPagination
    ) {
        getUpcomingLiveExperiences(pagination: $pagination) {
            _id
            featured
            maxNoParticipants
            name
            noOfSignups
            startDate
            thumbnail
            type
        }
    }
`;

export default () =>
    useQuery<
        UpcomingLiveExperiencesQuery,
        UpcomingLiveExperiencesQueryVariables
    >(UPCOMING_LIVE_EXPERIENCES_QUERY);
