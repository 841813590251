import styled, { css } from 'styled-components';
import { Widget } from '@typeform/embed-react';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';

export const TypeformModalWrapper = styled.div(
    ({ theme: { colors } }) => css`
        background: ${colors.secondary};
        height: 100vh;
        left: 0;
        overflow-y: auto;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;
    `,
);

export const StyledWidget = styled(Widget)`
    height: inherit;
`;

export const StyledCloseModalButton = styled(CloseModalButton)(
    ({ theme: { borderRadius, colors } }) => css`
        align-items: center;
        background-color: ${colors.background_secondary};
        border-radius: ${borderRadius.circle}%;
        display: flex;
        height: 34px;
        justify-content: center;
        right: 212px;
        width: 34px;
        z-index: 9;
    `,
);
