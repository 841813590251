import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import { Header } from '@components/_universal/Typography.styled';
import { Link } from 'react-router-dom';

export const WorkDetailsWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-right: 68px;
    flex: 1;
`;

export const WorkTypeInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ImageWrapper = styled.div<{ image: string }>`
    background-image: url(${({ image }) => image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    flex-shrink: 0;
    height: 48px;
    margin-right: 24px;
    position: relative;
    width: 48px;
`;

export const ImageOverlay = styled.div`
    background: ${({ theme }) => theme.opacityColors.violet};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 9;
`;

export const ImageIcon = styled(IconManager)`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const WorkFile = styled.div`
    align-items: center;
    display: flex;
`;

export const WorkName = styled(Header)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 8px;
    width: 100%;
`;

export const WorkInner = styled.div`
    display: flex;
    align-items: center;
`;

export const ArrowIcon = styled(IconManager)`
    margin-left: 4px;
`;

export const ButtonLink = styled(Link)`
    align-items: center;
    color: ${({ theme }) => theme.colors.text_assistive};
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-left: 24px;
    text-decoration: none;
    transition: ${({ theme }) => theme.transitions.default}s;

    &:hover {
        color: ${({ theme }) => theme.colors.primary};

        svg {
            path {
                fill: ${({ theme }) => theme.colors.primary};
            }
        }
    }
`;
