import { gql, useLazyQuery } from '@apollo/client';
import {
    CheckForNewNotificationsQuery,
    CheckForNewNotificationsQueryVariables,
} from '@@types/graphql/CheckForNewNotificationsQuery';

export const CHECK_FOR_NEW_NOTIFICATIONS_QUERY = gql`
    query CheckForNewNotificationsQuery($cursor: Int) {
        checkForNewNotifications(cursor: $cursor)
    }
`;

export default () =>
    useLazyQuery<
        CheckForNewNotificationsQuery,
        CheckForNewNotificationsQueryVariables
    >(CHECK_FOR_NEW_NOTIFICATIONS_QUERY, {
        onError: () => {},
        pollInterval: 60 * 1000,
        fetchPolicy: 'network-only',
    });
