import styled from 'styled-components';
import { IconManager } from '@components/_universal';

export const DesktopSignupPaymentProcessingPageWrapper = styled.div`
    align-items: center;
    align-self: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 620px;
    padding: 80px 54px;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    justify-content: center;
`;

export const LoadingText = styled.div`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.75;
    max-width: 564px;
    text-align: center;
`;

export const LoadingTitle = styled.div`
    color: ${({ theme }) => theme.colors.text_white};
    display: flex;
    font-family: ${({ theme }) => theme.fontFamily.sketched};
    font-size: ${({ theme }) => theme.fontSizes.max}px;
`;

export const LoadingScooterIcon = styled(IconManager)`
    margin: 72px 0;
`;
