import React from 'react';
import {
    NavigationLogoIcon,
    NavigationWrapper,
} from './OnboardingNavigation.styled';

interface IProps {}

const Navigation: React.FC<IProps> = ({}) => {
    return (
        <NavigationWrapper>
            <NavigationLogoIcon name={'Logo'} size={50} />
        </NavigationWrapper>
    );
};

export default Navigation;
