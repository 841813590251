import { gql, useLazyQuery } from '@apollo/client';
import {
    HomeworkFileQuery,
    HomeworkFileQueryVariables,
} from '@@types/graphql/HomeworkFileQuery';

export const CHILD_HOMEWORK_FILE_QUERY = gql`
    query HomeworkFileQuery($homeworkId: Int!, $type: HOMEWORK_FILE_TYPE!) {
        getHomeworkFileLink(homeworkId: $homeworkId, type: $type) {
            link
        }
    }
`;

export default () =>
    useLazyQuery<HomeworkFileQuery, HomeworkFileQueryVariables>(
        CHILD_HOMEWORK_FILE_QUERY,
        {
            onCompleted: (data) => {
                window.open(data.getHomeworkFileLink.link);
            },
            fetchPolicy: 'network-only',
        },
    );
