import React from 'react';
import { IconName } from '@@types/CommonTypes';
import { SectionTitle } from '@domains/Parent/Account/AccountParentPage/AccountParentPage.styled';
import { useHistory } from 'react-router';
import { useUpdatePaymentMethodMutation } from '@state/settings/mutations';
import { useUserDetailsPaymentQuery } from '@state/settings/queries';
import paths from '@shared/paths';
import {
    useStripePublishableKey,
    useUserSubscriptionInfo,
} from '@state/auth/queries';
import {
    ActionButton,
    BolderText,
    CardIcon,
    CardInfo,
    CardNumber,
    ExpiredDate,
    PaymentDetailsWrapper,
    SectionActions,
    SectionHeader,
    SectionInfo,
    SectionLabel,
    SectionPaymentInfo,
    SectionRow,
    SectionSubTitle,
    SectionText,
} from './PaymentDetails.styled';

interface IProps {
    hasSubscription: boolean;
    onCancelSubscription: () => void;
    onRenewSubscription: () => void;
    subscriptionEndDate: string;
}

const creditCardTypes: Record<string, IconName> = {
    amex: 'AmericanExpressCardIcon',
    diners: 'DinersClubCardIcon',
    discover: 'DiscoverNetworkCardIcon',
    jcb: 'JCBCardIcon',
    mastercard: 'MasterCardIcon',
    unionpay: 'UnionPayCardIcon',
    unknown: 'UnknownCardIcon',
    visa: 'VisaCardIcon',
};

const PaymentDetails: React.FC<IProps> = ({
    hasSubscription,
    onCancelSubscription,
    onRenewSubscription,
    subscriptionEndDate,
}) => {
    const history = useHistory();

    const { data: paymentData } = useUserDetailsPaymentQuery();
    const { data: publishableKey } = useStripePublishableKey();
    const { data: subscriptionInfoData } = useUserSubscriptionInfo();
    const [updatePaymentMethod] = useUpdatePaymentMethodMutation(
        publishableKey?.getStripeSetup.publishableKey ?? '',
    );

    const isTrialing = subscriptionInfoData?.getUserSubscriptionInfo.isTrialing;

    return (
        <PaymentDetailsWrapper>
            <SectionHeader>
                <SectionTitle>Payment details</SectionTitle>
                {isTrialing && (
                    <SectionActions>
                        <ActionButton
                            onPress={() => history.push(paths.choose_your_plan)}
                            size={'small'}
                        >
                            subscribe now
                        </ActionButton>
                    </SectionActions>
                )}
            </SectionHeader>
            {!isTrialing ? (
                <>
                    <SectionRow>
                        <SectionInfo>
                            <SectionSubTitle>Next payment</SectionSubTitle>
                            {hasSubscription ? (
                                <>
                                    <SectionText>
                                        Your next payment is{' '}
                                        {
                                            subscriptionInfoData
                                                ?.getUserSubscriptionInfo?.plan
                                                ?.currencySymbol
                                        }
                                        {(subscriptionInfoData
                                            ?.getUserSubscriptionInfo
                                            .nextChargeAmount ?? 0) / 100}
                                        , to be charged on {subscriptionEndDate}
                                    </SectionText>
                                    <SectionText>
                                        Your subscription will be automatically
                                        renewed each{' '}
                                        {subscriptionInfoData?.getUserSubscriptionInfo?.billingCycle?.toLocaleLowerCase()}
                                        .
                                    </SectionText>
                                </>
                            ) : (
                                <SectionText>
                                    You have cancelled your subscription. You
                                    may use the platform until the expiration
                                    date of your current subscription:{' '}
                                    <BolderText>
                                        {subscriptionEndDate}
                                    </BolderText>
                                </SectionText>
                            )}
                        </SectionInfo>
                        <SectionActions>
                            {hasSubscription ? (
                                <ActionButton
                                    buttonType={'secondary'}
                                    onPress={onCancelSubscription}
                                    size={'small'}
                                >
                                    Cancel Subscription
                                </ActionButton>
                            ) : (
                                <ActionButton
                                    buttonType={'primary'}
                                    onPress={onRenewSubscription}
                                    size={'small'}
                                >
                                    Renew Subscription
                                </ActionButton>
                            )}
                        </SectionActions>
                    </SectionRow>
                    <SectionRow>
                        <SectionPaymentInfo>
                            <SectionSubTitle>Payment details</SectionSubTitle>
                            <CardInfo>
                                <CardNumber>
                                    <CardIcon
                                        name={
                                            (creditCardTypes[
                                                paymentData?.getUserDefaultPayment.brand
                                                    .trim()
                                                    .toLowerCase() ?? 'Unknown'
                                            ] as IconName) ?? 'UnknownCardIcon'
                                        }
                                        size={32}
                                    />
                                    **** **** ****{' '}
                                    {
                                        paymentData?.getUserDefaultPayment
                                            .last4Digits
                                    }
                                </CardNumber>
                                <ExpiredDate>
                                    <SectionLabel>Expired:</SectionLabel>
                                    {
                                        paymentData?.getUserDefaultPayment
                                            .expiredMonth
                                    }
                                    /
                                    {
                                        paymentData?.getUserDefaultPayment
                                            .expiredYear
                                    }
                                </ExpiredDate>
                            </CardInfo>
                        </SectionPaymentInfo>
                        <SectionActions>
                            <ActionButton
                                buttonType={'secondary'}
                                onPress={updatePaymentMethod}
                                size={'small'}
                            >
                                Update
                            </ActionButton>
                        </SectionActions>
                    </SectionRow>
                </>
            ) : (
                <SectionRow>
                    <SectionPaymentInfo>
                        <SectionText>
                            Information about your payment will be displayed
                            here. You can choose your plan by clicking the
                            button ‘Subscribe now’.
                        </SectionText>
                    </SectionPaymentInfo>
                </SectionRow>
            )}
        </PaymentDetailsWrapper>
    );
};

export default PaymentDetails;
