import { gql, useMutation } from '@apollo/client';
import { GET_COURSE_QUERY } from '../queries/useCourseQuery';
import { GetCourseQuery } from '@@types/graphql/GetCourseQuery';
import {
    RateCourseMutation,
    RateCourseMutationVariables,
} from '@@types/graphql/RateCourseMutation';

const RATE_COURSE_MUTATION = gql`
    mutation RateCourseMutation(
        $courseId: String!
        $rate: Int!
        $comment: String!
    ) {
        rateCourse(courseId: $courseId, rate: $rate, comment: $comment) {
            id
            contentId
            rate
            comment
            postedBy
        }
    }
`;

export default (courseId: string) => {
    return useMutation<RateCourseMutation, RateCourseMutationVariables>(
        RATE_COURSE_MUTATION,
        {
            update: (cache, { data }) => {
                if (!data) return;
                const query = cache.readQuery<GetCourseQuery>({
                    query: GET_COURSE_QUERY,
                    variables: {
                        id: courseId,
                    },
                });

                query &&
                    cache.writeQuery<GetCourseQuery>({
                        query: GET_COURSE_QUERY,
                        variables: { id: courseId },
                        data: {
                            getCourse: {
                                ...query.getCourse,
                                isRated: true,
                            },
                        },
                    });
            },
        },
    );
};
