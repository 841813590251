import React from 'react';
import DashboardEmpty from './_components/EmptyDashboard/DashboardEmpty';
import DashboardParentEmptyChild from '@domains/Parent/Dashboard/DashboardParentPage/_components/DashboardParentEmptyChild/DashboardParentEmptyChild';
import Feedback from '@domains/Parent/Dashboard/DashboardParentPage/_components/Feedback/Feedback';
import FeedbackButton from '@domains/Parent/Dashboard/DashboardParentPage/_components/FeddbackButton/FeedbackButton';
import Loader from '@components/_universal/Loader/Loader';
import NewChildModal from '@domains/Parent/Account/_modals/NewChildModal/NewChildModal';
import UploadedFile from './_components/UploadedFile/UploadedFile';
import WorkDetails from '@domains/Parent/Dashboard/DashboardParentPage/_components/WorkDetails/WorkDetails';
import useDashboardParentPage from './useDashboardParentPage';
import {
    DashboardParentPageWrapper,
    NoFeedback,
    PageTitle,
    SectionHeader,
    StyledBadgeBorder,
    WorkAdditionalInfo,
    WorkCard,
    WorkCardInner,
} from './DashboardParentPage.styled';

import {
    CONTENT_TYPE,
    HOMEWORK_FILE_TYPE,
} from '@@types/graphql/GraphqlGlobalTypes';

const DashboardParentPage: React.FC = () => {
    const {
        childHomeworkData,
        childId,
        currentChild,
        handleFeedback,
        handleResendEmail,
        isEmptyDashboard,
        loading,
        setShowModal,
        showModal,
        shownFeedbacks,
    } = useDashboardParentPage();

    const renderChildContent = () =>
        childHomeworkData?.getChildHomeworks.length ? (
            <>
                <SectionHeader>Submitted works</SectionHeader>
                {childHomeworkData?.getChildHomeworks
                    .filter(
                        (homework) =>
                            homework.content.type !==
                            CONTENT_TYPE.STANDALONE_VIDEO,
                    )
                    .map((homework) => {
                        const homeworkFile = homework.files.find(
                            (file) =>
                                file.type === HOMEWORK_FILE_TYPE.USER_HOMEWORK,
                        );
                        const feedbackFile = homework.files.find(
                            (file) => file.type === HOMEWORK_FILE_TYPE.FEEDBACK,
                        );
                        const showFeedback = shownFeedbacks.includes(
                            homework.id,
                        );

                        return (
                            <WorkCard key={homework.id}>
                                <WorkCardInner>
                                    <WorkDetails
                                        childId={childId}
                                        homeworkId={homework.content.id}
                                        name={homework.content.name}
                                        thumbnail={homework.content.thumbnail}
                                        type={homework.content.type}
                                    />
                                    <WorkAdditionalInfo>
                                        {homeworkFile && (
                                            <UploadedFile
                                                date={homework.createdAt}
                                                file={homeworkFile}
                                                homeworkId={homework.id}
                                            />
                                        )}
                                        {homework.mentorFeedback ? (
                                            <FeedbackButton
                                                showFeedback={showFeedback}
                                                setShowFeedback={() =>
                                                    handleFeedback(homework.id)
                                                }
                                            />
                                        ) : (
                                            <NoFeedback>
                                                Waiting for a feedback!
                                            </NoFeedback>
                                        )}
                                    </WorkAdditionalInfo>
                                </WorkCardInner>
                                {showFeedback && homework.mentorFeedback && (
                                    <Feedback
                                        date={homework.feedbackGivenAt}
                                        description={homework.mentorFeedback}
                                        file={feedbackFile}
                                        homeworkId={homework.id}
                                        name={homework.feedbackBy?.name ?? ''}
                                        type={
                                            homework.content.type ===
                                            CONTENT_TYPE.LIVE_EXPERIENCE
                                                ? 'Facilitator'
                                                : 'Mentor'
                                        }
                                    />
                                )}
                            </WorkCard>
                        );
                    })}
            </>
        ) : (
            <DashboardParentEmptyChild
                title={'Welcome to your Parent dashboard'}
                subtitle={'So what happens next?'}
                onResendEmail={handleResendEmail}
            />
        );

    return (
        <DashboardParentPageWrapper>
            <PageTitle>
                {currentChild?.name} {currentChild?.surname}{' '}
                {loading && <Loader />}
                <StyledBadgeBorder
                    userStatus={currentChild?.status}
                    deactivateAtCurrentPeriodEnd={
                        currentChild?.deactivateAtCurrentPeriodEnd
                    }
                />
            </PageTitle>
            {!loading &&
                (!isEmptyDashboard ? (
                    renderChildContent()
                ) : (
                    <DashboardParentEmptyChild
                        title={'Welcome to your Parent dashboard'}
                        subtitle={'So what happens next?'}
                        onResendEmail={handleResendEmail}
                    />
                ))}

            {/* MODAL ADD CHILD */}
            {showModal && (
                <NewChildModal setIsModalOpen={() => setShowModal(false)} />
            )}
        </DashboardParentPageWrapper>
    );
};

export default DashboardParentPage;
