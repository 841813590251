import styled from 'styled-components';
import { BadgeText } from '@components/_universal/Badge/Badge.styled';
import { DefaultText } from '@components/_universal/Typography.styled';
import Badge from '@components/_universal/Badge/Badge';

export const BehaviourAgreementPageWrapper = styled.div`
    padding-bottom: 80px;
`;

export const SectionWrapper = styled.div`
    margin-top: 45px;
`;

export const Card = styled.div<{ indent?: number }>`
    align-items: center;
    background: ${({ theme }) => theme.colors.background_quaternary};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-left: ${({ indent }) => (indent ? indent : 0)}px;
    max-width: 70%;
    min-height: 100px;
    position: relative;
`;

export const CardContent = styled.div`
    margin-top: 25px;
    max-width: 410px;
    text-align: center;
`;

export const CardText = styled(DefaultText)`
    display: inline;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 0;
`;

export const StrongText = styled.a`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        text-underline-position: under;
    }
`;

export const CardTextBold = styled(CardText)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
`;

export const CardBadge = styled(Badge)`
    align-items: center;
    display: flex;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: -10px;
    transform: translateX(-50%);

    ${BadgeText} {
        font-size: ${({ theme }) => theme.fontSizes.xs}px;
        white-space: nowrap;
    }
`;
