import React from 'react';
import { CursorType } from '@@types/CommonTypes';
import { StarWrapper, RatingWrapper } from './Rating.styled';
import { Control, useController } from 'react-hook-form';
import IconManager from '@components/_universal/IconManager/IconManager';

export interface IProps {
    children?: React.ReactNode;
    clickable?: boolean;
    control: Control;
    cursorProperty?: CursorType;
    name: string;
    onClick?: () => void;
    size?: number;
}

const Rating: React.FC<IProps> = ({
    children,
    control,
    cursorProperty = 'pointer',
    name,
    onClick,
    size = 55,
}) => {
    const {
        field: { onChange, value },
    } = useController({
        control,
        defaultValue: 3,
        name,
    });

    return (
        <RatingWrapper {...{ onClick }}>
            {[...Array(5).keys()].map((_, index) => (
                <StarWrapper
                    key={index}
                    {...{ onClick: () => onChange(++index) }}
                >
                    <IconManager
                        name={
                            value && index < value
                                ? 'StarActive'
                                : 'StarInactive'
                        }
                        sizeY={size}
                        cursor={cursorProperty}
                        {...{ size }}
                    />
                </StarWrapper>
            ))}

            {children}
        </RatingWrapper>
    );
};

export default Rating;
