import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { IconManager } from '@components/_universal';
import ChoosePayment from '@domains/Auth/_components/ChoosePayment/ChoosePayment';
import TrialLeftInformation from '@components/TrialLeftInformation/TrialLeftInformation';
import {
    BigHeader,
    DefaultText,
    Title,
} from '@components/_universal/Typography.styled';

export const MobileSubscriptionPageWrapper = styled.div``;

export const ChoosePlanHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto 26px;
    max-width: 672px;
    padding-top: 40px;

    @media (max-width: ${breakpoints.md}px) {
        padding-top: 0;
    }

    @media (max-width: ${breakpoints.sm}px) {
        margin: 0 auto 10px;
    }
`;

export const StyledTitle = styled(Title)`
    margin: 0;
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
    }
`;

export const StyledDescription = styled(BigHeader)`
    margin: 0 0 10px;
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.m}px;
        max-width: 315px;
    }
`;

export const ChildrenTitle = styled(DefaultText)`
    margin: 0;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.s}px;
        max-width: 315px;
    }
`;

export const ChoosePlanTexts = styled.div`
    display: grid;
    gap: 16px;
    margin-bottom: 16px;
`;

export const ChoosePlanReminder = styled.div`
    align-items: flex-end;
    background-color: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.course},
        inset 0 0 0 3px ${({ theme }) => theme.colors.secondary};
    display: flex;
    flex-shrink: 0;
    margin: 40px auto 0;
    max-width: 470px;
    padding: 16px 28px;

    @media (max-width: ${breakpoints.sm}px) {
        justify-content: center;
        max-width: 400px;
        padding: 16px 20px;
        width: 100%;
    }
`;

export const ChoosePlanReminderText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.8;
    margin: 0;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.xs}px;
        line-height: 1.65;
        padding-bottom: 2px;
    }
`;

export const ChoosePlanReminderTextWrapper = styled.span`
    @media (max-width: ${breakpoints.sm}px) {
        display: block;
    }
`;

export const Discount = styled.div`
    background-color: ${({ theme }) => theme.colors.live_experience};
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xxs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.4;
    margin-left: 10px;
    padding: 6px 24px;
    text-transform: uppercase;

    @media (max-width: ${breakpoints.sm}px) {
        padding: 6px 14px;
    }
`;

export const ChoosePlanChildren = styled.div`
    align-content: flex-start;
    display: grid;
    gap: 8px;
    margin-bottom: 16px;
    width: 100%;
`;

export const ChildrenListHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const MobileTip = styled.div`
    background-color: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.bubble}px;
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.65;
    margin-top: 40px;
    padding: 32px 24px 28px;
    position: relative;
    text-align: center;

    @media (max-width: ${breakpoints.xs}px) {
        font-size: ${({ theme }) => theme.fontSizes.xs}px;
        line-height: 1.5;
        margin: 24px auto 0;
        max-width: 310px;
        padding: 24px 22px;
    }
`;

export const BulbIcon = styled(IconManager)`
    left: 30px;
    position: absolute;
    top: -26px;

    @media (max-width: ${breakpoints.xs}px) {
        display: none;
    }
`;

export const ChoosePlanContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 672px;
`;

export const TrialInformationWrapper = styled.div`
    position: absolute;
    top: 12px;
    right: 36px;

    @media (max-width: ${breakpoints.md}px) {
        display: none;
    }
`;

export const ChooseTrialLeftInformation = styled(TrialLeftInformation)``;

export const MobileSubscriptionWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 0;
    position: relative;
    width: 720px;

    @media (max-width: ${breakpoints.md}px) {
        width: 540px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        display: block;
    }

    @media (max-width: ${breakpoints.xs}px) {
        width: calc(100vw - 36px);
    }
`;

export const StyledChoosePayment = styled(ChoosePayment)`
    width: 360px;
`;

export const ChildrenListIconWrapper = styled.div`
    margin-right: 12px;
    position: relative;
`;
