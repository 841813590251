import React from 'react';
import { useChildLiveExperienceVisibility } from '@domains/Child/Library/LibraryPage/useChildLiveExperienceVisibility';
import {
    Content,
    HeaderBar,
    LibraryLayoutWrapper,
    NavigationBar,
    Sidebar,
} from './LibraryLayout.styled';

interface IProps {}

export const LibraryLayout: React.FC<IProps> = ({ children }) => {
    useChildLiveExperienceVisibility();

    return (
        <LibraryLayoutWrapper>
            <HeaderBar isTransparent />
            <NavigationBar borderLine />
            <Content>{children}</Content>
            <Sidebar />
        </LibraryLayoutWrapper>
    );
};
export default LibraryLayout;
