import React, { useRef } from 'react';
import { ModalContent } from '@components/_modals/GlobalModals.styled';
import { StyledTitle } from './EditUserDetailsModal.styled';
import { useOnClickOutside } from '@utils/hooks';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import EditUserDetailsForm from '@domains/Parent/Account/_forms/EditUserDetailsForm/EditUserDetailsForm';
import Modal from '@components/_universal/Modal/Modal';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const EditUserDetailsModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsModalOpen(false));

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={() => setIsModalOpen(false)} />
                <StyledTitle>Edit user details</StyledTitle>

                <EditUserDetailsForm callback={() => setIsModalOpen(false)} />
            </ModalContent>
        </Modal>
    );
};

export default EditUserDetailsModal;
