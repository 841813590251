import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { FormRow, InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';

export const CreateAccountFormWrapper = styled.form``;

export const StyledFormRow = styled(FormRow)`
    @media (max-width: ${breakpoints.sm}px) {
        flex-direction: column;
    }
`;

export const StyledInputWrapper = styled(InputWrapper)`
    @media (max-width: ${breakpoints.sm}px) {
        width: 100%;
    }
`;
