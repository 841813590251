import { useState } from 'react';
import { Banner } from '@components/_universal/Banner/Banner';
import { DescriptionBox } from '@components/_universal/DescriptionBox/DescriptionBox';
import { DetailsInfo } from '@components/_universal/DetailsInfo/DetailsInfo';
import { DetailsPageHeader } from '@components/_universal/DetailsPageHeader/DetailsPageHeader';
import { ExperienceInfo } from '@domains/Teacher/LiveExperience/_components/ExperienceInfo/ExperienceInfo';
import { LiveExperienceType } from '@@types/CommonTypes';
import { NotifyBox } from '@domains/Teacher/LiveExperience/_components/NotifyBox/NotifyBox';
import { RecommendTheContentModal } from '@domains/Teacher/Library/LibraryTeacherPage/_modals/RecommendTheContentModal/RecommendTheContentModal';
import { useLiveExperience } from '@domains/Child/LiveExperience/LiveExperiencePage/useLiveExperience';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import ActivityMaterials from '@components/ActivityMaterials/ActivityMaterials';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import {
    DividedContainer,
    LeftBox,
    RightBox,
} from '@components/_universal/commonStyles.styled';
import {
    LiveExperienceWrapper,
    PageTitle,
} from './LiveExperienceTeacherPage.styled';

export const LiveExperienceTeacherPage = () => {
    const {
        data,
        isConsumed,
        isOngoing,
        isSignedUp,
        liveExperienceData,
        loading,
        onTimerComplete,
    } = useLiveExperience();

    const stepVariant = (): LiveExperienceType => {
        if (isConsumed) {
            return 'experienceDone';
        }
        if (isSignedUp && isOngoing) {
            return 'join';
        }
        return 'experienceNotStart';
    };
    const [showModal, setShowModal] = useState(false);

    return (
        <LiveExperienceWrapper>
            <PageTitle>Experience details</PageTitle>
            <DetailsPageHeader
                iconName={'LiveExpIcon'}
                onPress={() => setShowModal(true)}
                recommendButton={stepVariant() !== 'experienceDone'}
                title={data?.getLiveExperience.name || ''}
            />
            {showModal && (
                <RecommendTheContentModal
                    setIsModalOpen={setShowModal}
                    contentType="LiveExperience"
                />
            )}
            <DividedContainer>
                <LeftBox>
                    <Banner background={liveExperienceData?.thumbnail} dark>
                        <ExperienceInfo
                            link={liveExperienceData?.link || ''}
                            loading={loading}
                            stepVariant={stepVariant()}
                            {...{ onTimerComplete }}
                        />
                    </Banner>
                </LeftBox>
                <RightBox>
                    <DetailsInfo userType={USER_TYPE.TEACHER} />
                </RightBox>
            </DividedContainer>
            {stepVariant() === 'experienceDone' && <NotifyBox />}
            <DividedContainer>
                <LeftBox>
                    <DescriptionBox title="Description">
                        {liveExperienceData?.description}
                    </DescriptionBox>
                </LeftBox>
                <RightBox>
                    <ActivityMaterials />
                </RightBox>
            </DividedContainer>
            <ChatWithMentor />
        </LiveExperienceWrapper>
    );
};
