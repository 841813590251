import React from 'react';
import { IconName } from '@@types/CommonTypes';
import IconManager from '@components/_universal/IconManager/IconManager';
import {
    CardTitle,
    DashboardBottomCardWrapper,
    IconArrowWrapper,
} from './DashboardBottomCard.styled';

interface IProps {
    icon: IconName;
    name: string;
    onPress: () => void;
    size: number;
}
const DashboardBottomCard: React.FC<IProps> = ({
    icon,
    name,
    onPress,
    size,
}) => {
    return (
        <DashboardBottomCardWrapper onClick={onPress}>
            <IconManager name={icon} size={size} sizeY={32} />
            <CardTitle>{name}</CardTitle>
            <IconArrowWrapper
                fill={['text']}
                name={'ArrowRight'}
                size={34}
                sizeY={16}
            />
        </DashboardBottomCardWrapper>
    );
};

export default DashboardBottomCard;
