import React from 'react';
import ActivityCard from '@domains/Teacher/StudentDetails/__components/ActivityCard/ActivityCard';
import ChipsInput from '@components/_form-elements/ChipsInput/ChipsInput';
import DetailsCard from '@domains/Teacher/StudentDetails/__components/DetailsCard/DetailsCard';
import EmptyActivityState from '@domains/Teacher/StudentDetails/__components/EmptyActivityState/EmptyActivityState';
import Loader from '@components/_universal/Loader/Loader';
import Paginate from '@components/_universal/Paginate/Paginate';
import useStudentDetails from './useStudentDetails';
import {
    ActivitiCardsWrapper,
    Content,
    ContentWrapper,
    FilterTitle,
    FilterWrapper,
    LoaderWrapper,
    PageTitle,
    StudentDetailsPageWrapper,
    StudentName,
} from './StudentDetailsPage.styled';

export interface IProps {}

const StudentDetailsPage: React.FC<IProps> = () => {
    const {
        activities,
        control,
        hasNextPage,
        loading,
        onLoadMore,
        paginateLoading,
        typeFilter,
        userData,
    } = useStudentDetails();

    return (
        <StudentDetailsPageWrapper>
            <PageTitle>Student details</PageTitle>
            <ContentWrapper>
                <Content>
                    {userData && (
                        <StudentName>{userData.name}’s activity</StudentName>
                    )}
                    <FilterWrapper>
                        <FilterTitle>content Type</FilterTitle>
                        <ChipsInput
                            chips={typeFilter}
                            control={control}
                            name="type"
                        />
                    </FilterWrapper>
                    {!!activities?.length && (
                        <Paginate
                            hasNextPage={hasNextPage}
                            loading={loading}
                            onLoadMore={onLoadMore}
                            queryLoading={paginateLoading}
                        >
                            <ActivitiCardsWrapper>
                                {activities.map((activity, index) => (
                                    <ActivityCard
                                        key={index}
                                        activity={activity}
                                    />
                                ))}
                            </ActivitiCardsWrapper>
                        </Paginate>
                    )}
                    {!activities && loading && (
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    )}
                    {!loading && !activities?.length && userData && (
                        <EmptyActivityState userName={userData.name} />
                    )}
                </Content>
                {userData && (
                    <DetailsCard
                        studentData={{
                            ...userData,
                            class: userData.class?.className,
                            year: userData.class?.year,
                        }}
                    />
                )}
            </ContentWrapper>
        </StudentDetailsPageWrapper>
    );
};

export default StudentDetailsPage;
