import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import {
    StepperButton,
    StepperIcon,
    StepperValue,
    StepperWrapper,
} from './Stepper.styled';

export interface IProps {
    control: any;
    max?: number;
    min?: number;
    name: string;
    readOnlyChildren?: number;
}

const Stepper: React.FC<IProps> = ({
    control,
    max = 9999,
    min = 1,
    name,
    readOnlyChildren,
}) => {
    const {
        field: { onChange, value },
    } = useController({
        control,
        defaultValue: 1,
        name,
    });

    const removeStep = () =>
        value > min ? onChange(value - 1) : onChange(min);
    const addStep = () => (value < max ? onChange(value + 1) : onChange(max));

    const stepperPluralValue =
        value > 1 ? `${value} children accounts` : `${value} child account`;

    useEffect(() => {
        readOnlyChildren !== undefined && onChange(readOnlyChildren);
    }, [readOnlyChildren]);

    return (
        <StepperWrapper>
            {readOnlyChildren === undefined && (
                <StepperButton onClick={removeStep}>
                    <StepperIcon fill={['text']} name={'Minus'} size={10}>
                        Click remove
                    </StepperIcon>
                </StepperButton>
            )}
            <StepperValue>{stepperPluralValue}</StepperValue>
            {readOnlyChildren === undefined && (
                <StepperButton onClick={addStep}>
                    <StepperIcon fill={['text']} name={'Plus'} size={10}>
                        Click add
                    </StepperIcon>
                </StepperButton>
            )}
        </StepperWrapper>
    );
};

export default Stepper;
