import React, { InputHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';
import { FieldTypes } from '@@types/CommonTypes';
import {
    IconWrapper,
    InputInnerWrapper,
    InputWrapper,
    Label,
    StyledInput,
} from './Input.styled';

export type InputTypes = 'default' | 'secondary';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    backgroundTypeError?: boolean;
    className?: string;
    defaultValue?: string;
    disabled?: boolean;
    errors?: FieldError;
    inputType?: InputTypes;
    isValid?: boolean;
    label?: string;
    marginBottom?: number;
    maxLength?: number;
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    register?: any;
    staticPosition?: boolean;
    type?: FieldTypes;
}

const Input: React.FC<IProps> = ({
    backgroundTypeError,
    className,
    defaultValue,
    disabled,
    errors,
    inputType = 'default',
    isValid,
    label,
    marginBottom,
    maxLength,
    name,
    onChange,
    placeholder,
    register,
    staticPosition,
    type = 'text',
}) => {
    return (
        <InputWrapper
            {...{ backgroundTypeError, className, isValid, marginBottom }}
        >
            {label && <Label {...{ inputType }}>{label}</Label>}
            <InputInnerWrapper>
                <StyledInput
                    defaultValue={defaultValue}
                    disabled={disabled}
                    errors={!!errors}
                    maxLength={maxLength}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    ref={register}
                    type={type}
                />
                {errors && (
                    <IconWrapper
                        errors={!!errors}
                        fill={['error']}
                        name={'Alert'}
                        size={14}
                    />
                )}
            </InputInnerWrapper>
        </InputWrapper>
    );
};

export default Input;
