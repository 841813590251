import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';

export const NotificationSettingsPageWrapper = styled.div`
    padding-bottom: 80px;
`;

export const SectionWrapper = styled.div``;

export const SectionDivider = styled.div`
    background: ${({ theme }) => theme.colors.background_tertiary};
    height: 2px;
    margin: 45px 0;
    width: 100%;
`;

export const SectionHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 15px;
`;

export const SectionSubtitle = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 5px;
`;

export const SectionText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin-bottom: 15px;
`;
