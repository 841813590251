import { gql, useMutation } from '@apollo/client';
import {
    UpdateChildAccountMutation,
    UpdateChildAccountMutationVariables,
} from '@@types/graphql/UpdateChildAccountMutation';
import { CHILDS_LIST_QUERY } from '@state/childManagement/queries/useChildsListQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

const UPDATE_CHILD_ACCOUNT_MUTATION = gql`
    mutation UpdateChildAccountMutation(
        $childId: Int!
        $data: UpdateChildAccountData!
    ) {
        updateChildAccount(childId: $childId, data: $data) {
            address
            birthday
            email
            gender
            id
            name
            status
            surname
        }
    }
`;

export default (onCompleted?: () => void) =>
    useMutation<
        UpdateChildAccountMutation,
        UpdateChildAccountMutationVariables
    >(UPDATE_CHILD_ACCOUNT_MUTATION, {
        onCompleted: () => {
            onCompleted?.();
            notify('Congratulations! Changes have been successfully saved.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: () => {
            notify(
                'Unfortunately, changes haven’t been saved. Please try again later!',
            )[ToastTypes.ERROR]();
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: CHILDS_LIST_QUERY,
            },
        ],
    });
