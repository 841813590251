import React from 'react';
import { IconManager } from '@components/_universal';
import {
    ActionButton,
    LegalParentPageWrapper,
    PageTitle,
    SectionDivider,
    SectionHeader,
    SectionText,
    SectionWrapper,
    ContentWrapper,
} from './LegalParentPage.styled';
import {
    Icon,
    PageHeader,
    SectionTitle,
} from '@domains/Parent/Account/AccountParentPage/AccountParentPage.styled';
import { externalLinks } from '@shared/paths';

interface IProps {}

const LegalParentPage: React.FC<IProps> = () => {
    return (
        <LegalParentPageWrapper>
            <PageHeader>
                <PageTitle>Legal</PageTitle>
                <Icon name={'BooksIcon'} size={70} sizeY={40} />
            </PageHeader>
            <SectionWrapper>
                <ContentWrapper>
                    <SectionTitle>Terms & Conditions</SectionTitle>
                    <SectionText>
                        Please read these terms and conditions of use carefully
                        before using our platform.
                    </SectionText>
                </ContentWrapper>
                <ActionButton
                    buttonType={'secondary'}
                    onPress={() => window.open(externalLinks.terms, '_blank')}
                    size={'small'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        isLeft: true,
                        type: 'RedirectIcon',
                    }}
                >
                    Read More
                </ActionButton>
            </SectionWrapper>
            <SectionDivider />
            <SectionWrapper>
                <ContentWrapper>
                    <SectionTitle>Privacy Policy</SectionTitle>
                    <SectionText>
                        Please read our privacy policy carefully to get a clear
                        understanding of how we collect, use and protect your
                        personal information.
                    </SectionText>
                </ContentWrapper>
                <ActionButton
                    buttonType={'secondary'}
                    onPress={() =>
                        window.open(externalLinks.privacy_policy, '_blank')
                    }
                    size={'small'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        isLeft: true,
                        type: 'RedirectIcon',
                    }}
                >
                    Read More
                </ActionButton>
            </SectionWrapper>
        </LegalParentPageWrapper>
    );
};

export default LegalParentPage;
