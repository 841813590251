import React from 'react';
import {
    ArrowIcon,
    DescriptionText,
    DoneExperienceWrapper,
    SumUpText,
    WellDoneIcon,
} from '@domains/Child/LiveExperience/_components/ExperienceInfo/ExperienceInfo.styled';

interface IProps {
    className?: string;
}

export const DoneExperience: React.FC<IProps> = ({ className }) => {
    return (
        <>
            <DoneExperienceWrapper className={className}>
                <SumUpText>We hope you had fun on this experience.</SumUpText>
                <DescriptionText>
                    Remember to submit your project so our Mentors can send you
                    feedback!
                </DescriptionText>
                <ArrowIcon name={'ArrowRight'} size={34} sizeY={16} />
            </DoneExperienceWrapper>
            <WellDoneIcon name={'WellDone'} size={492} sizeY={370} />
        </>
    );
};

export default DoneExperience;
