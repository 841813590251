import React from 'react';
import { BillingCycle } from '@@types/graphql/GraphqlGlobalTypes';
import {
    DescriptionWrapper,
    DesktopSubscriptionPageWrapper,
    StyledDescription,
    StyledTitle,
    SubscriptionWrapper,
    SubscriptionChoosePayment,
} from './DesktopSubscriptionPage.styled';

interface IProps {}

const DesktopSubscriptionPage: React.FC<IProps> = () => {
    return (
        <DesktopSubscriptionPageWrapper>
            <StyledTitle>
                Wide range of possibilities with one easy subscription.
            </StyledTitle>
            <DescriptionWrapper>
                <StyledDescription>
                    Start your child's future today - with us!
                </StyledDescription>
            </DescriptionWrapper>
            <SubscriptionWrapper>
                <SubscriptionChoosePayment
                    buttonType={'secondary'}
                    iconFill={['border']}
                >
                    Monthly
                </SubscriptionChoosePayment>
                <SubscriptionChoosePayment
                    currentValue={320}
                    paymentType={BillingCycle.YEAR}
                >
                    Annual
                </SubscriptionChoosePayment>
            </SubscriptionWrapper>
        </DesktopSubscriptionPageWrapper>
    );
};

export default DesktopSubscriptionPage;
