import React from 'react';
import {
    AuthorizationTokenContent,
    LoadingScooterIcon,
    LoadingTitle,
    LogoIcon,
    MobileAuthorizationTokenPageWrapper,
    StyledDots,
} from './MobileAuthorizationTokenPage.styled';

export interface IProps {}

const DesktopAuthorizationTokenPage: React.FC<IProps> = () => {
    return (
        <MobileAuthorizationTokenPageWrapper>
            <LogoIcon
                fill={['text_white', 'text_white']}
                name={'LogoWithText'}
                size={190}
                sizeY={45}
            />
            <AuthorizationTokenContent>
                <LoadingTitle>
                    Loading
                    <StyledDots />
                </LoadingTitle>

                <LoadingScooterIcon
                    name={'LoadingScooter'}
                    size={390}
                    sizeY={186}
                />
            </AuthorizationTokenContent>
        </MobileAuthorizationTokenPageWrapper>
    );
};

export default DesktopAuthorizationTokenPage;
