import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';
import { breakpoints } from '@shared/constants';

export const ForgotPasswordPageWrapper = styled.div`
    align-items: center;
    align-self: center;
    background: rgba(81, 91, 230, 0.95);
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 910px;
    min-height: 840px;
    overflow: hidden;
    padding: 54px 28px;
    width: 100%;

    @media (max-width: ${breakpoints.lg}px) {
        max-height: 800px;
        min-height: 734px;
    }

    @media (max-width: ${breakpoints.md}px) {
        border-radius: ${({ theme }) => theme.borderRadius.button}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        min-height: 630px;
        padding: 24px 28px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        padding: 24px 16px;
    }
`;

export const LogoIcon = styled(IconManager)`
    @media (max-width: ${breakpoints.sm}px) {
        font-size: 126px;
    }
`;

export const LogoLink = styled.a`
    flex-shrink: 0;
    margin-bottom: 30px;
`;

export const InnerWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin-bottom: 150px;
    max-width: 550px;

    @media (max-width: ${breakpoints.lg}px) {
        justify-content: flex-start;
        margin-bottom: 0;
        padding-top: 12px;
    }
`;

export const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.45;
    margin-bottom: 16px;
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
        margin-bottom: 12px;
    }
`;

export const Subtitle = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 50px;
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.s}px;
        line-height: 1.45;
        margin-bottom: 40px;
    }
`;
