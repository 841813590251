import React, { useEffect, useState } from 'react';
import { Input } from '@components/_form-elements';
import { SelectType } from '@@types/CommonTypes';
import { useForm } from 'react-hook-form';
import { useMatchMedia } from '@utils/hooks';
import { useSchoolClassesQuery } from '@state/teachers/queries';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import CreatableSelect from '@components/_form-elements/CreatableSelect/CreatableSelect';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import {
    ButtonsRow,
    StudentFormWrapper,
    StyledButton,
    StyledFormRow,
    StyledInputWrapper,
} from '@domains/Teacher/Students/_forms/Forms.styled';

export interface IStudentForm {
    className: SelectType;
    email: string;
    name: string;
    surname: string;
    year: SelectType;
}

type ClassesType = { [key: string]: string[] };

interface IProps {
    defaultData?: IStudentForm;
    defaultSchoolClasses?: {
        className: string;
        year: string;
    }[];
    onClose: () => void;
    onSubmit: (data: IStudentForm) => void;
}

const NewChildForm: React.FC<IProps> = ({
    defaultData,
    defaultSchoolClasses,
    onClose,
    onSubmit,
}) => {
    const [classes, setClasses] = useState<ClassesType>({});
    const { match } = useMatchMedia('md');

    const {
        control,
        errors,
        handleSubmit,
        register,
        watch,
    } = useForm<IStudentForm>({
        defaultValues: defaultData,
        resolver: yupResolver(validation),
    });
    const { year } = watch();

    const { data } = useSchoolClassesQuery();
    const classesData = data?.getSchoolClasses;

    const years = Object.keys(classes).map((classData) => ({
        label: classData,
        value: classData,
    }));

    const selectClasses =
        year?.value && classes[year.value]
            ? classes[year.value].map((classData) => ({
                  label: classData,
                  value: classData,
              }))
            : [];

    useEffect(() => {
        if (!classesData) return;
        const classesList = defaultSchoolClasses
            ? [...classesData, ...defaultSchoolClasses]
            : classesData;

        let classes: ClassesType = {};

        classesList.map((classData) => {
            const classTable = classes[`${classData.year}`];

            return (classes[`${classData.year}`] = classTable
                ? [...classTable, classData.className]
                : [classData.className]);
        });

        // Remove duplicated classes from table
        for (const key in classes) {
            classes[key] = [...new Set(classes[key])];
        }

        setClasses(classes);
    }, [classesData, defaultSchoolClasses]);

    return (
        <StudentFormWrapper noValidate={true} onSubmit={handleSubmit(onSubmit)}>
            <StyledFormRow>
                <StyledInputWrapper>
                    <InputForm
                        errors={errors.name}
                        label={'name'}
                        marginBottom={match ? 8 : 24}
                        name={'name'}
                        placeholder={'Enter student name'}
                        register={register}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
                <StyledInputWrapper>
                    <InputForm
                        errors={errors.surname}
                        label={'surname'}
                        marginBottom={match ? 8 : 24}
                        name={'surname'}
                        placeholder={'Enter student surname'}
                        register={register}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
            </StyledFormRow>
            <StyledFormRow>
                <StyledInputWrapper>
                    <InputForm
                        errors={errors.email}
                        label={'email address'}
                        marginBottom={match ? 8 : 24}
                        name={'email'}
                        placeholder={'e.g. joedoe@mail.com'}
                        register={register}
                        staticPosition={match}
                        type={'email'}
                    />
                </StyledInputWrapper>
            </StyledFormRow>
            <StyledFormRow>
                <StyledInputWrapper>
                    <CreatableSelectForm
                        control={control}
                        errors={errors.className}
                        label={'class'}
                        marginBottom={match ? 8 : 24}
                        name={'className'}
                        options={selectClasses}
                        placeholder={'Enter student class'}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
                <StyledInputWrapper>
                    <CreatableSelectForm
                        control={control}
                        errors={errors.year}
                        label={'year'}
                        marginBottom={match ? 8 : 24}
                        name={'year'}
                        options={years}
                        placeholder={'Enter student year'}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
            </StyledFormRow>
            <ButtonsRow>
                <StyledButton
                    buttonType={'secondary'}
                    onPress={onClose}
                    type="button"
                >
                    cancel
                </StyledButton>
                <StyledButton buttonType={'primary'} onPress={() => {}}>
                    {defaultData ? 'save' : 'add student'}
                </StyledButton>
            </ButtonsRow>
        </StudentFormWrapper>
    );
};

const InputForm = FieldMessage(Input);
const CreatableSelectForm = FieldMessage(CreatableSelect);

export default NewChildForm;
