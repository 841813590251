import { StudentsListQuery_getStudentsList_users_groups } from '@@types/graphql/StudentsListQuery';

export const alphabeticalGroupSort = function (
    a: StudentsListQuery_getStudentsList_users_groups | null,
    b: StudentsListQuery_getStudentsList_users_groups | null,
) {
    if (a!.title.toUpperCase() < b!.title.toUpperCase()) {
        return -1;
    }
    if (a!.title.toUpperCase() > b!.title.toUpperCase()) {
        return 1;
    }
    return 0;
};

export const alphabeticalClassSort = function (
    a: { label: string; value: string },
    b: { label: string; value: string },
) {
    if (a!.label.toUpperCase() < b!.label.toUpperCase()) {
        return -1;
    }
    if (a!.label.toUpperCase() > b!.label.toUpperCase()) {
        return 1;
    }
    return 0;
};
