import styled, { css } from 'styled-components';
import lePlaceholder from '@assets/images/le_placeholder.png';

export const InnerVideoPlayerWrapper = styled.div`
    position: relative;
    padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
    height: 0;
    width: 100%;
`;

export const StyledIframe = styled.iframe<{ withBackground?: boolean }>(
    ({ theme: { borderRadius }, withBackground }) => css`
        background-position: center;
        background-size: cover;
        border-radius: ${borderRadius.card}px;
        border: 0;
        height: 100%;
        left: 0;
        margin: 0;
        outline: none;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;

        ${withBackground &&
        css`
            background-image: url(${lePlaceholder});
        `}
    `,
);

export const DividedContainer = styled.div`
    display: flex;
    margin-bottom: 80px;
`;

export const LeftBox = styled.div`
    flex: 2;
`;

export const RightBox = styled.div`
    flex: 1;
    margin-left: 64px;
`;
