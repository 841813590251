import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';
import { Link } from 'react-router-dom';

export const MagicLinkSentPageWrapper = styled.div`
    align-items: center;
    align-self: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-height: 620px;
    padding: 105px 50px 70px;
    width: 100%;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 24px;
    }

    @media (max-width: ${breakpoints.md}px) {
        border-radius: ${({ theme }) => theme.borderRadius.button}px;
        padding: 80px 28px 40px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        padding: 60px 28px 40px;
    }
`;

export const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 16px;
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
        line-height: 1.35;
    }
`;

export const Subtitle = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.55;
    max-width: 550px;
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.s}px;
        line-height: 1.45;
    }
`;

export const WrongEmailText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.5;
    text-align: center;
`;

export const WrongEmailLink = styled(Link)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const OrnamentIcon = styled(IconManager)`
    padding: 56px 0 64px;

    @media (max-width: ${breakpoints.md}px) {
        font-size: 200px;
        padding: 42px 0 48px;
    }
`;
