import { gql, useMutation } from '@apollo/client';
import {
    UpdateNotificationSettingsMutation,
    UpdateNotificationSettingsMutationVariables,
} from '@@types/graphql/UpdateNotificationSettingsMutation';
import { GET_USER_NOTIFICATION_SETTINGS_QUERY } from '@state/notifications/queries';

const UPDATE_NOTIFICATION_SETTINGS_MUTATION = gql`
    mutation UpdateNotificationSettingsMutation(
        $input: NotificationSettingsInput!
    ) {
        updateUserNotificationSettings(input: $input) {
            emailNotificationEnabled
            inAppNotificationEnabled
        }
    }
`;

export default () => {
    return useMutation<
        UpdateNotificationSettingsMutation,
        UpdateNotificationSettingsMutationVariables
    >(UPDATE_NOTIFICATION_SETTINGS_MUTATION, {
        // TODO: handle completed action
        onCompleted: (payload) => {
            console.log(payload);
        },
        // TODO: handle error action
        onError: (error) => {
            console.log(error);
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_USER_NOTIFICATION_SETTINGS_QUERY,
            },
        ],
    });
};
