import React from 'react';
import BadgeBorder from '@components/_universal/BadgeBorder/BadgeBorder';
import useTrial from '@utils/hooks/useTrial';
import { USER_STATUS } from '@@types/graphql/GraphqlGlobalTypes';

interface IProps {
    className?: string;
    deactivateAtCurrentPeriodEnd?: boolean;
    userStatus?: USER_STATUS;
}

const UserStatusBadge: React.FC<IProps> = ({
    className,
    deactivateAtCurrentPeriodEnd,
    userStatus,
}) => {
    const { trialParentInfo } = useTrial();

    if (trialParentInfo?.getUserSubscriptionInfo.isTrialing)
        return <BadgeBorder className={className}>Free trial</BadgeBorder>;

    if (
        (userStatus === USER_STATUS.ACTIVE && deactivateAtCurrentPeriodEnd) ||
        userStatus === USER_STATUS.DEACTIVATED
    )
        return (
            <BadgeBorder className={className} variant="light">
                Inactive
            </BadgeBorder>
        );

    if (userStatus === USER_STATUS.PENDING)
        return (
            <BadgeBorder className={className} variant="gray">
                Pending
            </BadgeBorder>
        );

    if (userStatus === USER_STATUS.ACTIVE)
        return <BadgeBorder className={className}>Active</BadgeBorder>;

    return null;
};

export default UserStatusBadge;
