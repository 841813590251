import React from 'react';
import {
    BackpackIcon,
    FooterLogo,
    FooterText,
    FooterWrapper,
} from './Footer.styled';
import { IconManager } from '@components/_universal';
import { useSelector } from 'react-redux';

interface IProps {
    className?: string;
    isBackpack?: boolean;
    isLogo?: boolean;
    isWhiteText?: boolean;
}

const Footer: React.FC<IProps> = ({
    className,
    isBackpack,
    isLogo,
    isWhiteText,
}) => {
    const { lockLayout } = useSelector((state) => state.layout);

    return (
        <FooterWrapper hideElement={!!lockLayout} {...{ className }}>
            <FooterLogo>
                {isLogo && (
                    <IconManager name={'LogoWithText'} size={118} sizeY={90} />
                )}
            </FooterLogo>
            <FooterText {...{ isWhiteText }}>
                {isBackpack && (
                    <BackpackIcon name={'Backpack'} size={34} sizeY={46} />
                )}{' '}
                © 2021 8billionideas
            </FooterText>
        </FooterWrapper>
    );
};

export default Footer;
