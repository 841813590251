import { setIsUnreadNotifications } from '@state/_redux/notifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { useOnClickOutside } from '@utils/hooks';

export default (matchPath: string) => {
    const { isUnread } = useSelector((state) => state.notifications);
    const [notificationsActive, setNotificationsActive] = useState(false);

    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [accountActive, setAccountActive] = useState(false);

    const refIcon = useRef<HTMLDivElement>(null);
    const refNotifications = useRef<HTMLDivElement>(null);

    const onClose = () => {
        setNotificationsActive(false);
        dispatch(setIsUnreadNotifications(false));
    };

    useOnClickOutside(refIcon, onClose, refNotifications);
    const match = useRouteMatch(matchPath);

    useEffect(() => {
        match ? setAccountActive(true) : setAccountActive(false);
    }, [pathname]);

    return {
        accountActive,
        isUnread,
        notificationsActive,
        onClose,
        refIcon,
        refNotifications,
        setNotificationsActive,
    };
};
