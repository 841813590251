import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { SignUpToLiveExperienceFragment } from '@@types/graphql/SignUpToLiveExperienceFragment';
import { ToastTypes } from '@@types/CommonTypes';
import { UPCOMING_LIVE_EXPERIENCES_CONTENT_QUERY } from '@state/lists/queries/useUpcomingLiveExperiencesContentQuery';
import {
    SignUpToLiveExperienceMutation,
    SignUpToLiveExperienceMutationVariables,
} from '@@types/graphql/SignUpToLiveExperienceMutation';

const SIGN_UP_TO_LIVE_EXPERIENCE_MUTATION = gql`
    mutation SignUpToLiveExperienceMutation($liveExperienceId: String!) {
        signupToLiveExperience(liveExperienceId: $liveExperienceId) {
            _id
            noOfSignups
        }
    }
`;

const fragment = gql`
    fragment SignUpToLiveExperienceFragment on LiveExperience {
        isUserSignuped
        noOfSignups
    }
`;

export default () => {
    return useMutation<
        SignUpToLiveExperienceMutation,
        SignUpToLiveExperienceMutationVariables
    >(SIGN_UP_TO_LIVE_EXPERIENCE_MUTATION, {
        onCompleted: () => {
            notify(
                'Congratulations! You have successfully signed up for this experience.',
            )[ToastTypes.SUCCESS]();
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
        update: (cache, { data }) => {
            if (!data) return;
            cache.writeFragment<SignUpToLiveExperienceFragment>({
                id: `LiveExperience:${data.signupToLiveExperience._id}`,
                fragment,
                data: {
                    isUserSignuped: true,
                    noOfSignups: data.signupToLiveExperience.noOfSignups,
                },
            });
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: UPCOMING_LIVE_EXPERIENCES_CONTENT_QUERY,
            },
        ],
    });
};
