import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import { SubTitle } from '@components/_universal/Typography.styled';
import SplitCard from '@components/_universal/Cards/SplitCard/SplitCard';
import { SplitCardWrapper } from '@components/_universal/Cards/SplitCard/SplitCard.styled';

export const SimilarContentWrapper = styled.div``;

export const HeaderWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 40px;
`;

export const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SubSlot = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 354px;
`;

export const Title = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
    position: relative;
`;

export const Icon = styled(IconManager)`
    position: absolute;
    right: -110px;
    top: 50%;
    transform: translateY(-50%);
`;

export const CourseCard = styled(SplitCard)`
    ${SplitCardWrapper} {
        height: 354px;
    }
`;
