import React from 'react';
import { CreatePasswordPageWrapper } from './CreatePasswordPage.styled';
import { Redirect } from 'react-router';
import { useScrollToTop } from '@utils/hooks';
import { useSelector } from 'react-redux';
import CreatePassword from '@domains/Auth/_components/CreatePassword/CreatePassword';
import paths from '@shared/paths';

export interface IProps {}

const CreatePasswordPage: React.FC<IProps> = () => {
    useScrollToTop();
    const { correctAccountData } = useSelector((state) => state.signup);

    if (!correctAccountData)
        return <Redirect to={paths.signup_create_account} />;

    return (
        <CreatePasswordPageWrapper>
            <CreatePassword />
        </CreatePasswordPageWrapper>
    );
};

export default CreatePasswordPage;
