import styled, { css } from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button, IconButton, Tip } from '@components/_universal';
import IconManager from '@components/_universal/IconManager/IconManager';

export const SignupRegisterWrapper = styled.div``;

export const InnerWrapper = styled.div`
    margin: 0 auto;
    max-width: 750px;
    position: relative;
`;

export const ControlsWrapper = styled.div<{ isMaxChildren: boolean }>`
    align-items: flex-start;
    display: flex;
    justify-content: ${({ isMaxChildren }) =>
        isMaxChildren ? 'center' : 'space-between'};
    margin: 32px auto 0;
    max-width: 640px;

    @media (max-width: ${breakpoints.md}px) {
        max-width: 600px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        align-items: center;
        flex-direction: column;
        margin: 0 auto 32px;
    }
`;

export const AddChild = styled.div`
    align-items: center;
    color: ${({ theme }) => theme.colors.text_white};
    display: flex;
    flex-direction: column;
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    margin: 0 0 80px 46px;
    position: relative;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 50px;
    }

    @media (max-width: ${breakpoints.md}px) {
        margin-left: 70px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.s}px;
        margin: 0 0 20px;
    }
`;

export const CircleIcon = styled(IconManager)`
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: -90px;
    transform: translateX(-50%);
`;

export const AddChildButton = styled(IconButton)`
    margin-top: 8px;

    &:hover {
        box-shadow: ${({ theme }) => theme.blue_shadow};
    }

    @media (max-width: ${breakpoints.sm}px) {
        margin-top: 12px;
    }
`;

export const GetStartedButton = styled(Button)`
    margin-top: 20px;
    min-width: 300px;
    z-index: 9;

    &:hover {
        box-shadow: ${({ theme }) => theme.blue_shadow};
    }

    @media (max-width: ${breakpoints.sm}px) {
        max-width: 360px;
        width: 100%;
    }
`;

const Oranment = css`
    background-color: ${({ theme }) => theme.colors.icons};
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    height: 510px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    @media (max-width: ${breakpoints.md}px) {
        height: 480px;
        width: 60%;
    }

    @media (max-width: ${breakpoints.sm}px) {
        height: 620px;
    }
`;

export const PreviousPageOrnament = styled.div`
    left: -40px;
    ${Oranment}

    @media (max-width: ${breakpoints.md}px) {
        left: 10px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        left: -24px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        left: -12px;
    }
`;

export const NextPageOrnament = styled.div`
    right: -40px;
    ${Oranment}

    @media (max-width: ${breakpoints.md}px) {
        right: 10px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        right: -24px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        right: -12px;
    }
`;

export const MobileStyledTip = styled(Tip)`
    display: none;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 46px;
    margin-left: auto;
    padding: 24px;
    position: relative;
    width: calc(100% - 46px);
    z-index: 99;

    &::after {
        border-right: 16px solid transparent;
        border-left: 16px solid transparent;
        border-top: 20px solid rgba(255, 255, 255, 0.25);
        left: 32px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        display: flex;
    }

    @media (max-width: ${breakpoints.xs}px) {
        font-size: ${({ theme }) => theme.fontSizes.xs}px;
        width: calc(100% - 32px);
    }
`;
