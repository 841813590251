import React from 'react';
import { CONTENT_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { setLibraryFilter } from '@state/_redux/filters/actions';
import { SwiperSlide } from 'swiper/react';
import { useDispatch } from 'react-redux';
import Carousel from '@components/_universal/Carousel/Carousel';
import LiveExperienceCard from '@components/_universal/Cards/LiveExperienceCard/LiveExperienceCard';
import paths from '@shared/paths';
import PlaceholderCard from '@components/_universal/Cards/PlaceholderCard/PlaceholderCard';
import useUpcomingLiveExperiencesQuery from '@state/lists/queries/useUpcomingLiveExperiencesQuery';
import { SwiperWrapper } from '@domains/Child/Dashboard/_components/LiveExperienceCarousel/LiveExperienceCarousel.styled';

export const SignUp = () => {
    const dispatch = useDispatch();
    const { data } = useUpcomingLiveExperiencesQuery();
    const liveExperiences = data?.getUpcomingLiveExperiences ?? [];

    return (
        <SwiperWrapper>
            <Carousel
                arrowsOffset={24}
                disabled={false}
                nextButtonClass={'next--childManagement-experiences'}
                prevButtonClass={'prev--childManagement-experiences'}
                style={{ padding: '0 85px 0 0' }}
            >
                {liveExperiences.map((item, index) => (
                    <SwiperSlide key={index}>
                        <LiveExperienceCard
                            allSlots={item.maxNoParticipants}
                            availableSlots={
                                item.maxNoParticipants - item.noOfSignups
                            }
                            image={item.thumbnail ?? ''}
                            link={paths.live_experience(item._id)}
                            startDate={new Date(item.startDate)}
                            text={item.name}
                        />
                    </SwiperSlide>
                ))}
                {liveExperiences.length < 4 && (
                    <SwiperSlide>
                        <PlaceholderCard
                            link={''}
                            text={'New experiences for you soon!'}
                            topIcon={{
                                name: 'Live',
                                size: 50,
                            }}
                            variant="secondary"
                        />
                    </SwiperSlide>
                )}
                {liveExperiences.length > 9 && (
                    <SwiperSlide>
                        <PlaceholderCard
                            bottomIcon={{
                                fill: ['text_white'],
                                name: 'ArrowRight',
                                size: 32,
                            }}
                            link={paths.library}
                            onClick={() =>
                                dispatch(
                                    setLibraryFilter({
                                        type: [CONTENT_TYPE.LIVE_EXPERIENCE],
                                    }),
                                )
                            }
                            text={'There are more experiences waiting for you!'}
                            topIcon={{
                                name: 'Live',
                                size: 50,
                            }}
                        />
                    </SwiperSlide>
                )}
            </Carousel>
        </SwiperWrapper>
    );
};
