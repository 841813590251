import React, { useState } from 'react';
import { Banner } from '@components/_universal/Banner/Banner';
import { convertTime } from '@components/_universal/InfoTiles/InfoTiles';
import { DescriptionBox } from '@components/_universal/DescriptionBox/DescriptionBox';
import { DetailsPageHeader } from '@components/_universal/DetailsPageHeader/DetailsPageHeader';
import { InfoTile } from '@components/_universal/InfoTile/InfoTile';
import { RecommendTheContentModal } from '@domains/Teacher/Library/LibraryTeacherPage/_modals/RecommendTheContentModal/RecommendTheContentModal';
import { useParams } from 'react-router-dom';
import { useVideoDetails } from '@state/teachers/queries/videoDetails';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import Loader from '@components/_universal/Loader/Loader';
import {
    DividedContainer,
    LeftBox,
    RightBox,
} from '@components/_universal/commonStyles.styled';
import {
    InnerWrapper,
    LoaderWrapper,
    PageTitle,
    TileWrapper,
    VideoDetailsWrapper,
} from './VideoTeacherPage.styled';

export const VideoTeacherPage = () => {
    const { videoId } = useParams<{ videoId: string }>();

    const { data, loading } = useVideoDetails(videoId);
    const [showModal, setShowModal] = useState(false);

    return (
        <VideoDetailsWrapper>
            <PageTitle>Video details</PageTitle>
            {!loading && (
                <DetailsPageHeader
                    iconName="VideoIcon"
                    onPress={() => {
                        setShowModal(true);
                    }}
                    recommendButton={true}
                    title={data?.getStandaloneVideo.name || ''}
                />
            )}
            {showModal && (
                <RecommendTheContentModal
                    contentType="Video"
                    setIsModalOpen={setShowModal}
                />
            )}
            <Banner background={data?.getStandaloneVideo.thumbnail} />
            <InnerWrapper>
                {loading && (
                    <LoaderWrapper>
                        <Loader size={'medium'} />
                    </LoaderWrapper>
                )}{' '}
                <DividedContainer>
                    <LeftBox>
                        <DescriptionBox title="Description">
                            {data?.getStandaloneVideo.description}
                        </DescriptionBox>
                    </LeftBox>
                    <RightBox>
                        <TileWrapper>
                            <InfoTile
                                iconName="TimeDuration"
                                title="Total time"
                                value={`${convertTime(
                                    data?.getStandaloneVideo.video.duration ??
                                        0,
                                )} h`}
                            />
                        </TileWrapper>
                    </RightBox>
                </DividedContainer>
                <ChatWithMentor />
            </InnerWrapper>
        </VideoDetailsWrapper>
    );
};
