import { CardTypes, IMosaicCard } from '@@types/CommonTypes';
import { RecommendedContentsUserQuery_getRecommendedContentsForUser } from '@@types/graphql/RecommendedContentsUserQuery';
import { SavedForLaterContentQuery_getSavedForLaterContent } from '@@types/graphql/SavedForLaterContentQuery';
import { SearchContentQuery_searchForContent } from '@@types/graphql/SearchContentQuery';
import paths from '@shared/paths';

type IParsedData =
    | SearchContentQuery_searchForContent
    | RecommendedContentsUserQuery_getRecommendedContentsForUser
    | SavedForLaterContentQuery_getSavedForLaterContent;
// TODO: this function should be refactored

function parseContentToCards(data: IParsedData[]): IMosaicCard[] {
    return data.map((element) => ({
        featured: element.featured,
        image: element?.thumbnail || '',
        key: element._id,
        availableSlots:
            element.__typename === 'LiveExperience'
                ? element?.maxNoParticipants - element.noOfSignups
                : 0,
        allSlots:
            element.__typename === 'LiveExperience'
                ? element?.maxNoParticipants
                : 0,
        endDate:
            element.__typename === 'LiveExperience'
                ? element.endDate
                : undefined,
        schoolsWithAccess:
            element.__typename === 'LiveExperience'
                ? element.schoolsWithAccess
                : undefined,
        showToChildren:
            element.__typename === 'LiveExperience'
                ? element.showToChildren
                : undefined,
        showToStudents:
            element.__typename === 'LiveExperience'
                ? element.showToStudents
                : undefined,
        startDate:
            element.__typename === 'LiveExperience'
                ? element.startDate
                : undefined,
        text: element.name,
        type: element.type as CardTypes,
        url:
            element.__typename === 'LiveExperience'
                ? paths.live_experience(element._id)
                : element.__typename === 'Course'
                ? paths.course(element._id)
                : element.__typename === 'StandaloneVideo'
                ? paths.video_view(element._id)
                : '',
    }));
}

export default parseContentToCards;
