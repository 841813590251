import React from 'react';
import { Control, FieldError, useController } from 'react-hook-form';
import {
    DropdownIndicator,
    OptionWithIcon,
    ValueContainer,
} from '@components/_form-elements/Select/Select';
import {
    CreatableSelectWrapper,
    InnerWrapper,
    Label,
    StyledCreatableSelect,
} from './CreatableSelect.styled';

interface IOption {
    label: string;
    value?: string;
}

type actionTypes =
    | 'select-option'
    | 'deselect-option'
    | 'remove-value'
    | 'pop-value'
    | 'set-value'
    | 'clear'
    | 'create-option';

export interface IProps {
    className?: string;
    control: Control;
    disabled?: boolean;
    errors?: FieldError;
    isLoading?: boolean;
    label?: string;
    marginBottom?: number;
    name: string;
    options: IOption[];
    placeholder?: string;
}

const CreatableSelect: React.FC<IProps> = ({
    className,
    control,
    disabled,
    errors,
    isLoading,
    label,
    marginBottom,
    name,
    options,
    placeholder,
}) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
        defaultValue: null,
    });

    const handleChange = (
        newValue: IOption,
        actionMeta: { action: actionTypes },
    ) => {
        ['select-option', 'create-option'].includes(actionMeta.action) &&
            onChange({
                label: newValue.label,
                value: newValue.value,
            });
    };

    return (
        <CreatableSelectWrapper {...{ className, marginBottom }}>
            {label && <Label>{label}</Label>}
            <InnerWrapper>
                <StyledCreatableSelect
                    classNamePrefix="react-select"
                    components={{
                        DropdownIndicator,
                        Option: OptionWithIcon,
                        ValueContainer,
                        ClearIndicator: null,
                    }}
                    errors={errors}
                    isClearable
                    isDisabled={disabled}
                    isLoading={isLoading}
                    onChange={handleChange}
                    options={options}
                    placeholder={placeholder}
                    value={value}
                />
            </InnerWrapper>
        </CreatableSelectWrapper>
    );
};

export default CreatableSelect;
