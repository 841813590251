import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { useAuthorize } from '@utils/hooks';
import paths from '@shared/paths';
import {
    LoginWithPasswordMutation,
    LoginWithPasswordMutationVariables,
} from '@@types/graphql/LoginWithPasswordMutation';

const LOGIN_WITH_PASSWORD_MUTATION = gql`
    mutation LoginWithPasswordMutation($input: LoginWithPasswordInput!) {
        loginData: loginWithPassword(input: $input) {
            sessionToken
            refreshToken
            user {
                id
                type
                status
                onboardingCompletedAt
                hasPassword
            }
            operationType
        }
    }
`;

export default () => {
    const history = useHistory();
    const authorize = useAuthorize();

    return useMutation<
        LoginWithPasswordMutation,
        LoginWithPasswordMutationVariables
    >(LOGIN_WITH_PASSWORD_MUTATION, {
        onCompleted: (payload) => {
            history.push(paths.authorization_token);
            const data = payload.loginData;

            authorize({
                data,
            });
        },
        onError: () => {},
    });
};
