import { gql, useQuery } from '@apollo/client';
import { UserDefaultPaymentQuery } from '@@types/graphql/UserDefaultPaymentQuery';

const USER_DETAILS_PAYMENT_QUERY = gql`
    query UserDefaultPaymentQuery {
        getUserDefaultPayment {
            brand
            last4Digits
            expiredMonth
            expiredYear
        }
    }
`;

export default () =>
    useQuery<UserDefaultPaymentQuery>(USER_DETAILS_PAYMENT_QUERY);
