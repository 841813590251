import styled from 'styled-components';
import { Button } from '@components/_universal';

export const ChangePasswordFormWrapper = styled.form``;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 24px 0 46px;
    width: 100%;
`;

export const StyledButton = styled(Button)`
    margin: 0 12px;
    min-width: 160px;
`;
