import React, { useState } from 'react';
import {
    ButtonWrapper,
    ExperienceManIcon,
    SignUpExperienceWrapper,
    SlotLeft,
    SlotText,
    SlotsBgIcon,
    SlotsWrapper,
    StyledBigHeader,
    StyledButton,
    StyledCounter,
} from '@domains/Child/LiveExperience/_components/ExperienceInfo/ExperienceInfo.styled';
import { useParams } from 'react-router-dom';
import { useGetLiveExperienceQuery } from '@state/liveExperience/queries';
import { useSignUpToLiveExperienceMutation } from '@state/liveExperience/mutations';
import dayjs from 'dayjs';

interface IProps {
    className?: string;
}

export const SignUpExperience: React.FC<IProps> = ({ className }) => {
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();
    const { data } = useGetLiveExperienceQuery(liveExperienceId);
    const [
        signUpToLiveExperience,
        { loading },
    ] = useSignUpToLiveExperienceMutation();
    const startDate =
        data?.getLiveExperience.startDate &&
        dayjs(data?.getLiveExperience.startDate);

    const [showButton, setShowButton] = useState(
        startDate?.diff(dayjs()) > 0 ?? false,
    );

    return (
        <SignUpExperienceWrapper className={className}>
            <SlotsWrapper>
                <SlotsBgIcon size={130} sizeY={90} name={'ExperienceSlots'} />
                <SlotLeft>
                    {data?.getLiveExperience &&
                        data.getLiveExperience.maxNoParticipants -
                            data.getLiveExperience.noOfSignups}
                </SlotLeft>
                <SlotText>slots left!</SlotText>
            </SlotsWrapper>
            <ExperienceManIcon
                name={'ExperienceInfoMan'}
                size={143}
                sizeY={139}
            />
            <StyledBigHeader>This experience will start in...</StyledBigHeader>
            <StyledCounter onComplete={() => setShowButton(false)} />
            <ButtonWrapper>
                {showButton && (
                    <StyledButton
                        disabled={loading}
                        onPress={() =>
                            signUpToLiveExperience({
                                variables: {
                                    liveExperienceId:
                                        data?.getLiveExperience._id || '',
                                },
                            })
                        }
                    >
                        Sign up
                    </StyledButton>
                )}
            </ButtonWrapper>
        </SignUpExperienceWrapper>
    );
};

export default SignUpExperience;
