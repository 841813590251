import React from 'react';
import { errorMessages } from '@utils/shared/validations/formErrors';
import { FieldError } from 'react-hook-form';
import { FormRow, InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';
import { InputPassword } from '@components/_form-elements';
import { useChangePasswordMutation } from '@state/auth/mutations';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import PasswordValidation from '@components/_form-elements/PasswordValidation/PasswordValidation';
import usePasswordForm from '@utils/hooks/usePasswordForm';
import {
    ButtonWrapper,
    ChangePasswordFormWrapper,
    StyledButton,
} from './ChangePasswordForm.styled';

interface IProps {
    onClose: () => void;
}

const ChangePasswordForm: React.FC<IProps> = ({ onClose }) => {
    const [changePassword, { loading }] = useChangePasswordMutation(onClose);

    const {
        confirmNewPassword,
        errors,
        isButtonDisabled,
        isFocused,
        isValidConfirmPassword,
        newPassword,
        onSubmit,
        passwordInputEl,
        register,
        setIsValid,
    } = usePasswordForm(
        (values) =>
            changePassword({
                variables: {
                    input: {
                        oldPassword: values.currentPassword ?? '',
                        newPassword: values.newPassword,
                    },
                },
            }),
        loading,
    );

    return (
        <ChangePasswordFormWrapper>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        label={'current password'}
                        maxLength={256}
                        name={'currentPassword'}
                        placeholder={'Enter password'}
                        register={register}
                        staticPosition
                        marginBottom={isFocused ? 8 : 16}
                    />
                </InputWrapper>
            </FormRow>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.newPassword}
                        label={'new password'}
                        maxLength={256}
                        name={'newPassword'}
                        placeholder={'Enter new password'}
                        register={passwordInputEl}
                        staticPosition
                        marginBottom={isFocused ? 8 : 16}
                    />
                </InputWrapper>
            </FormRow>
            {isFocused && (
                <PasswordValidation
                    marginTop={errors.newPassword && 8}
                    password={newPassword}
                    setIsValid={setIsValid}
                />
            )}
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={
                            errors.confirmNewPassword ||
                            (!isValidConfirmPassword &&
                                confirmNewPassword.length > 0)
                                ? ({
                                      message: errorMessages.CONFIRM_PASSWORD,
                                  } as FieldError)
                                : undefined
                        }
                        label={'Confirm new password'}
                        marginBottom={isFocused ? 8 : 16}
                        maxLength={256}
                        name={'confirmNewPassword'}
                        placeholder={'Re-enter new password'}
                        register={register}
                        staticPosition
                    />
                </InputWrapper>
            </FormRow>
            <ButtonWrapper>
                <StyledButton
                    buttonType={'secondary'}
                    onPress={onClose}
                    type="button"
                >
                    Cancel
                </StyledButton>
                <StyledButton
                    buttonType={'primary'}
                    disabled={isButtonDisabled}
                    onPress={onSubmit}
                >
                    Change password
                </StyledButton>
            </ButtonWrapper>
        </ChangePasswordFormWrapper>
    );
};

const InputForm = FieldMessage(InputPassword);

export default ChangePasswordForm;
