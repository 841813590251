import React from 'react';
import { BannerWrapper } from './Banner.styled';

interface BannerProps {
    background?: string;
    children?: React.ReactNode;
    dark?: boolean;
}

export const Banner = ({ background, children, dark = false }: BannerProps) => {
    return (
        <BannerWrapper background={background} dark={dark}>
            {children}
        </BannerWrapper>
    );
};
