import React from 'react';
import {
    SubmittedExperienceWrapper,
    SubmittedIcon,
    SubmittedText,
    SumUpText,
} from '@domains/Child/LiveExperience/_components/ExperienceInfo/ExperienceInfo.styled';

interface IProps {
    className?: string;
}

export const SubmittedExperience: React.FC<IProps> = ({ className }) => {
    return (
        <>
            <SubmittedExperienceWrapper className={className}>
                <SubmittedText>
                    Your work was successfully submited!
                </SubmittedText>
                <SumUpText>
                    Take a break while our Mentors are checking your project. We
                    will notify you as soon as the review is complete!
                </SumUpText>
            </SubmittedExperienceWrapper>
            <SubmittedIcon name={'SubmittedWomen'} size={250} sizeY={166} />
        </>
    );
};

export default SubmittedExperience;
