import styled, { keyframes } from 'styled-components';
import { ColorType, ThemeType } from '@@types/CommonTypes';

export const DotPulseBefore = (theme: ThemeType, color: ColorType) => keyframes`
    0% {
        box-shadow: 9984px 0 0 -5px ${theme.colors[color]};
    }
    30% {
        box-shadow: 9984px 0 0 2px ${theme.colors[color]};
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px ${theme.colors[color]};
    }
`;

export const DotPulse = (theme: ThemeType, color: ColorType) => keyframes`
    0% {
        box-shadow: 9999px 0 0 -5px ${theme.colors[color]};

    }
    30% {
        box-shadow: 9999px 0 0 2px ${theme.colors[color]};
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px ${theme.colors[color]};
    }
`;

export const DotPulseAfter = (theme: ThemeType, color: ColorType) => keyframes`
    0% {
        box-shadow: 10014px 0 0 -5px ${theme.colors[color]};
    }
    30% {
        box-shadow: 10014px 0 0 2px ${theme.colors[color]};
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px ${theme.colors[color]};
    }
`;

export const AnimatedDotsWrapper = styled.div`
    left: 40px;
    position: relative;
    top: 50px;
`;

// dont sort
export const DotsContent = styled.div<{ color: ColorType }>`
    animation: ${({ color, theme }) => DotPulse(theme, color)} 1.5s infinite
        linear;
    animation-delay: 0.25s;
    background-color: ${({ color, theme }) => theme.colors[color]};
    border-radius: 5px;
    color: ${({ theme, color }) => theme.colors[color]};
    height: 5px;
    left: -9999px;
    position: relative;
    width: 5px;

    &:after,
    &:before {
        background-color: ${({ color, theme }) => theme.colors[color]};
        border-radius: 5px;
        color: ${({ theme, color }) => theme.colors[color]};
        content: '';
        display: inline-block;
        height: 5px;
        position: absolute;
        top: 0;
        width: 5px;
    }

    &:after {
        animation: ${({ color, theme }) => DotPulseAfter(theme, color)} 1.5s
            infinite linear;
        animation-delay: 0.5s;
    }
    &:before {
        animation: ${({ color, theme }) => DotPulseBefore(theme, color)} 1.5s
            infinite linear;
        animation-delay: 0s;
    }
`;
