import { gql, useLazyQuery } from '@apollo/client';
import {
    StudentsActivityFeedQuery,
    StudentsActivityFeedQueryVariables,
} from '@@types/graphql/StudentsActivityFeedQuery';

const STUDENTS_ACTIVITY_FEED_QUERY = gql`
    query StudentsActivityFeedQuery($input: GetActivityFeedsInput!) {
        getActivityFeeds(input: $input) {
            feeds {
                _id
                user {
                    id
                    name
                    surname
                    avatar
                }
                date
                type
                liveExperience {
                    _id
                    name
                }
                standaloneVideo {
                    _id
                    name
                }
                course {
                    _id
                    name
                }
                homework {
                    id
                    feedbackGivenAt
                    feedbackBy {
                        name
                        surname
                    }
                    mentorFeedback
                    files {
                        id
                        filename
                        mimetype
                        type
                    }
                }
                contentType
            }
            meta {
                page
                hasNextPage
            }
        }
    }
`;

export default () =>
    useLazyQuery<StudentsActivityFeedQuery, StudentsActivityFeedQueryVariables>(
        STUDENTS_ACTIVITY_FEED_QUERY,
        {
            onError: (err) => console.log(err.message),
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true,
        },
    );
