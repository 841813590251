import { IAppRoutes } from '@routes/index';
import { Redirect, Route, RouteProps, useRouteMatch } from 'react-router-dom';
import { useAuthMeQuery, useHasSubscriptionQuery } from '@state/auth/queries';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { userTypeRoutes } from '@shared/userTypeRoutes';
import { useSelector } from 'react-redux';
import { useWindowFocus } from '@utils/hooks';
import paths from '@shared/paths';
import React, { useEffect } from 'react';
interface IProps extends RouteProps {
    allowFor?: IAppRoutes['allowFor'];
    isPrivate?: IAppRoutes['isPrivate'];
    routeType?: IAppRoutes['type'];
}

const CustomRoute: React.FC<IProps> = ({
    allowFor,
    isPrivate,
    routeType,
    ...routeProps
}) => {
    useWindowFocus();

    const isFirstLogin = useSelector((state) => state.signup.isFirstLogin);
    const authorizationToken = useRouteMatch(paths.authorization_token);
    const chooseYourPlan = useRouteMatch(paths.choose_your_plan);

    const { data, error, loading } = useAuthMeQuery(!!authorizationToken);
    const [
        getHasSubscription,
        { data: subscriptionData },
    ] = useHasSubscriptionQuery();

    const userType = data?.me.type;
    const userRoutes = userType && userTypeRoutes[userType];

    const isOnboardingCompleted = data?.me.onboardingCompletedAt;
    const isInvalid = routeType === 'invalid';
    const isSignupProcess = routeType === 'signUpProcess';
    const isOnboarding = routeType === 'onboarding';

    const redirectToLogin = !!isPrivate && !!error;
    const hasPlan = subscriptionData?.getUserSubscriptionInfo.plan;

    const landingRedirect = () => {
        if (userRoutes?.externalLandingRoute) {
            window.location.href = userRoutes.landingRoute;
            return null;
        }

        if (userRoutes) return <Redirect to={userRoutes.landingRoute} />;

        return <Route {...routeProps} />;
    };

    useEffect(() => {
        userType === USER_TYPE.PARENT && getHasSubscription();
    }, [userType]);

    if (loading) return null;

    if (redirectToLogin) return <Redirect to={paths.login} />;

    // Redirect to landing when user is logged in and try to access to other user route
    if (userType && allowFor && !allowFor.includes(userType))
        return landingRedirect();

    // Redirect to landing when user is logged in and try to access non private route (exception: error page)
    if (!isPrivate && !isInvalid) return landingRedirect();

    // Redirect to landing when user is verified and try to access to first login routes
    if (isSignupProcess && !isFirstLogin) return landingRedirect();

    // Redirect to onboarding route when user can go to onboarding and doesn't have onboarding completed
    if (userRoutes?.onboardingRoute && !isOnboardingCompleted && !isOnboarding)
        return <Redirect to={userRoutes?.onboardingRoute} />;

    // Redirect to landing when user has onboarding completed and try to access to onboarding routes
    if (isOnboarding && isOnboardingCompleted) return landingRedirect();

    // Redirect to landing when user has plan and try to access to choose your plan
    if (hasPlan && chooseYourPlan) return landingRedirect();

    return <Route {...routeProps} />;
};

export default CustomRoute;
