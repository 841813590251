import { useConsumedContentQuery } from '@state/lists/queries';
import { useEffect, useState } from 'react';
import { ConsumedContentQueryVariables } from '@@types/graphql/ConsumedContentQuery';

const SEARCH_LIMIT = 10;

const useCompletedCoursePage = () => {
    const [offset, setOffset] = useState(0);
    const [paginateLoading, setPaginateLoading] = useState(false);

    const generateVariables = (
        offset?: number,
    ): ConsumedContentQueryVariables => ({
        pagination: {
            take: SEARCH_LIMIT,
            offset: offset ?? 0,
        },
    });
    const [
        completedCoursesContent,
        { data, loading, fetchMore },
    ] = useConsumedContentQuery();

    const dataLength = data?.getConsumedContent?.length ?? 0;
    const onLoadMore = () => {
        setPaginateLoading(true);

        fetchMore?.({
            variables: generateVariables(dataLength),
        }).then(() => {
            setOffset(dataLength);
            setPaginateLoading(false);
        });
    };

    useEffect(() => {
        setOffset(0);

        completedCoursesContent({
            variables: generateVariables(),
        });
    }, []);

    return {
        data,
        hasNextPage: !!(dataLength && offset < dataLength),
        loading,
        onLoadMore,
        paginateLoading,
    };
};

export default useCompletedCoursePage;
