import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button, IconManager } from '@components/_universal';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import Avatar from '@components/_universal/Avatar/Avatar';

export const MobileChooseAvatarPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const MobilePageContent = styled.div`
    flex-grow: 1;
`;

export const MobilePageFooter = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
`;

export const MobileAvatarIcon = styled(IconManager)`
    margin-top: -52px;

    @media (max-width: ${breakpoints.xs}px) {
        font-size: 220px;
        margin-bottom: -20px;
    }
`;

export const MobileOrnamentIcon = styled(IconManager)`
    position: absolute;
    right: -90px;
    top: -100px;
    z-index: 9;

    @media (max-width: ${breakpoints.sm}px) {
        display: none;
    }
`;

export const MobileAvatarTitle = styled(Title)`
    font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 8px;

    @media (max-width: ${breakpoints.md}px) {
        max-width: 400px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        font-size: ${({ theme }) => theme.fontSizes.xl}px;
    }
`;

export const MobileAvatarDescription = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.6;
    max-width: 520px;
    position: relative;

    @media (max-width: ${breakpoints.xs}px) {
        font-size: ${({ theme }) => theme.fontSizes.xs}px;
        line-height: 1.45;
    }
`;

export const MobileAvatarList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    width: 600px;

    @media (max-width: ${breakpoints.sm}px) {
        width: 100%;
    }
`;

export const StyledAvatar = styled(Avatar)`
    margin: 16px 0;
    width: 25%;

    @media (max-width: ${breakpoints.sm}px) {
        width: 33.3%;
    }

    @media (max-width: ${breakpoints.xs}px) {
        width: 50%;
    }

    .AvatarImageIcon {
        box-shadow: ${({ theme }) => theme.light_green_shadow};
        border-radius: ${({ theme }) => theme.borderRadius.circle}%;
        transition: all ${({ theme }) => theme.transitions.default}s;

        &:hover {
            box-shadow: ${({ theme }) => theme.dark_green_shadow};
        }
    }
`;

export const StyledContinueButton = styled(Button)`
    @media (max-width: ${breakpoints.xs}px) {
        width: 100%;
    }
`;
