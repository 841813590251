import React from 'react';
import { useSelector } from 'react-redux';
import CreatePasswordModal from './CreatePasswordModal/CreatePasswordModal';
import LeaveStudentsPageModal from './LeaveStudentsPageModal/LeaveStudentsPageModal';
import StudentsUploadModal from './StudentsUploadModal/StudentsUploadModal';
import TeacherCreatePasswordModal from './TeacherCreatePasswordModal/TeacherCreatePasswordModal';
import TooManyStudentsModal from './TooManyStudentsModal/TooManyStudentsModal';
import TrialEndedModal from './TrialEndedModal/TrialEndedModal';
import TypeformModal from './TypeformModal/TypeformModal';
import useCreatePasswordModal from './CreatePasswordModal/useCreatePasswordModal';
import useTeacherCreatePasswordModal from './TeacherCreatePasswordModal/useTeacherCreatePasswordModal';
import useTrialEndedModal from './TrialEndedModal/useTrialEndedModal';

const GlobalModals: React.FC = () => {
    const {
        leaveUploadStudentsModal,
        studentsUploadModal,
        tooManyStudnetsUploadModal,
        typeformModal,
    } = useSelector((state) => state.layout);
    const {
        setTrialEndedModal,
        trialEndedData,
        trialEndedModal,
    } = useTrialEndedModal();

    const { createPasswordModal } = useCreatePasswordModal();

    const { createTeacherPasswordModal } = useTeacherCreatePasswordModal();

    return (
        <>
            {tooManyStudnetsUploadModal && <TooManyStudentsModal />}

            {studentsUploadModal && <StudentsUploadModal />}

            {leaveUploadStudentsModal.isOpen && <LeaveStudentsPageModal />}

            {createTeacherPasswordModal && <TeacherCreatePasswordModal />}

            {createPasswordModal && <CreatePasswordModal />}

            {typeformModal && <TypeformModal />}

            {trialEndedModal && trialEndedData && (
                <TrialEndedModal
                    approveText={trialEndedData.approveText}
                    cancelText={trialEndedData.cancelText}
                    content={trialEndedData.content}
                    onApprove={() => {
                        trialEndedData.onApprove();
                        setTrialEndedModal(false);
                    }}
                    onCancel={() => {
                        trialEndedData.onCancel();
                        setTrialEndedModal(false);
                    }}
                    title={trialEndedData.title}
                />
            )}
        </>
    );
};

export default GlobalModals;
