import { ReactChild } from 'react';
import { GroupStudentsListUploader } from '@components/GroupStudentsListUploader/GroupStudentsListUploader';
import { StudentsListCsvDownloader } from '@components/_universal/StudentsListCsvDownloader/StudentsListCsvDownloader';
import StudentsListUploader from '@components/StudentsListUploader/StudentsListUploader';
import {
    BoxWrapper,
    StyledLinkText,
    StyledUploadText,
    UploadIcon,
    UploadOption,
    UploadOptionWrapper,
} from './UploadBox.styled';

interface UploadBoxProps {
    children?: ReactChild;
    onClick: () => void;
    variant: 'students_page' | 'group_page';
}

export const UploadBox = ({ children, onClick, variant }: UploadBoxProps) => {
    const UploadCsvOption = () => {
        return (
            <UploadOption>
                <UploadIcon name={'File'} size={46} />I want to add students
                using the CSV file
            </UploadOption>
        );
    };
    return (
        <BoxWrapper>
            <UploadOptionWrapper>
                <UploadOption onClick={onClick}>
                    <UploadIcon name={'Article'} size={32} sizeY={46} />I want
                    to add a student using the form
                </UploadOption>
            </UploadOptionWrapper>
            {children}
            <UploadOptionWrapper>
                {variant === 'students_page' ? (
                    <>
                        <StudentsListUploader>
                            <UploadCsvOption />
                        </StudentsListUploader>
                        <StyledLinkText
                            onClick={() => {
                                window.open(
                                    '/students-example.csv',
                                    '_blank',
                                    'noopener,noreferrer',
                                );
                            }}
                        >
                            Download example CSV file
                        </StyledLinkText>
                    </>
                ) : (
                    <>
                        <GroupStudentsListUploader>
                            <UploadCsvOption />
                        </GroupStudentsListUploader>
                        <StudentsListCsvDownloader sampleData>
                            <StyledUploadText>
                                Download sample CSV file
                            </StyledUploadText>
                        </StudentsListCsvDownloader>
                    </>
                )}
            </UploadOptionWrapper>
        </BoxWrapper>
    );
};
