import React from 'react';
import { FeedbackButtonWrapper } from './FeedbackButton.styled';

interface IProps {
    setShowFeedback: () => void;
    showFeedback: boolean;
}

const FeedbackButton: React.FC<IProps> = ({
    setShowFeedback,
    showFeedback,
}) => (
    <FeedbackButtonWrapper
        icon={{
            type: showFeedback ? 'ArrowSelectUp' : 'ArrowSelectDown',
        }}
        onPress={setShowFeedback}
        size={'small'}
    >
        {showFeedback ? 'Hide the feedback' : 'Show the feedback'}
    </FeedbackButtonWrapper>
);

export default FeedbackButton;
