import styled, { css } from 'styled-components';
import { PlaceholderCardTypes } from '@@types/CommonTypes';
import { DefaultText } from '@components/_universal/Typography.styled';
import IconManager from '@components/_universal/IconManager/IconManager';
import { DEFAULT_CARD_HEIGHTS } from '@components/_universal/Cards/Cards.styled';

export const primaryPlaceholderCardCss = css`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text_white};
    cursor: ${({ theme }) => theme.cursors.pointer};
`;

export const secondaryPlaceholderCardCss = css`
    background-color: ${({ theme }) => theme.colors.background_secondary};
    color: ${({ theme }) => theme.colors.text_secondary};
    cursor: ${({ theme }) => theme.cursors.pointer};
`;

export const primaryPlaceholderCardTextCss = css`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.l}px;
    line-height: 1.7;
`;

export const secondaryPlaceholderCardTextCss = css`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    line-height: 1.75;
`;

export const PlaceholderCardStyleTypes = {
    primary: primaryPlaceholderCardCss,
    secondary: secondaryPlaceholderCardCss,
};

export const PlaceholderCardTextStyleTypes = {
    primary: primaryPlaceholderCardTextCss,
    secondary: secondaryPlaceholderCardTextCss,
};

export const PlaceholderCardBottomIcon = styled(IconManager)`
    bottom: 26px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: ${({ theme }) => theme.transitions.default}s;
    will-change: transform;
`;

export const PlaceholderCardWrapper = styled.div<{
    variant: PlaceholderCardTypes;
    withHover: boolean;
}>`
    align-items: flex-end;
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    box-shadow: ${({ theme }) => theme.light_green_shadow};
    display: flex;
    min-height: ${DEFAULT_CARD_HEIGHTS.placeholder}px;
    padding: 24px 24px 128px;
    position: relative;
    transition: ${({ theme }) => theme.transitions.default}s;
    width: 100%;
    will-change: box-shadow;
    ${({ variant }) => PlaceholderCardStyleTypes[variant]};

    &:hover {
        ${({ theme, withHover }) =>
            withHover && `box-shadow: ${theme.dark_green_shadow}`}

        ${PlaceholderCardBottomIcon} {
            ${({ withHover }) =>
                withHover && `transform: translateX(calc(-50% + 8px));`}
        }
    }
`;

export const PlaceholderCardContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
`;

export const PlaceholderCardText = styled(DefaultText)<{
    variant: PlaceholderCardTypes;
}>`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 24px 0 0;
    ${({ variant }) => PlaceholderCardTextStyleTypes[variant]}
`;

export const PlaceholderCardBackground = styled(IconManager)`
    position: absolute;
    right: 6px;
    top: 8px;
`;
