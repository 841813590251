import { contentTypes } from '@shared/keyToCopy';
import { IChip, SelectType } from '@@types/CommonTypes';
import { setLibraryFilter } from '@state/_redux/filters/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMostPopularTypesTags } from '@state/lists/queries/useMostPopularTypesTags';
import { usePrevious } from '@utils/hooks';
import {
    CONTENT_TYPE,
    LIBRARY_VIEW_CONTENT_SORTING,
} from '@@types/graphql/GraphqlGlobalTypes';

interface IForm {
    sortBy: SelectType<LIBRARY_VIEW_CONTENT_SORTING>;
    topic: string[];
    type: CONTENT_TYPE[];
}

const useFilterSidebar = () => {
    const dispatch = useDispatch();
    const libraryFilters = useSelector(({ filters }) => filters.library);
    const { liveExperiencesVisibility } = useSelector(
        (state) => state.liveExperiencesVisibility,
    );
    const { control, watch } = useForm<IForm>({
        defaultValues: libraryFilters,
    });

    const { data } = useMostPopularTypesTags();
    const values = watch();
    const prevValues = usePrevious(values);

    const topicCount = data?.typesAndTags.topicTags.totalCount;
    const topicData = data?.typesAndTags.topicTags.data;

    const typeCount = data?.typesAndTags.contentTypes.totalCount;
    const typeData = data?.typesAndTags.contentTypes.data;

    const contentTypeFilter: IChip[] = liveExperiencesVisibility
        ? typeData
            ? [
                  ...typeData?.map((type) => {
                      return {
                          count: type.count,
                          text: contentTypes[type.name as CONTENT_TYPE],
                          value: type.name,
                      };
                  }),
              ]
            : []
        : [
              {
                  text: contentTypes[CONTENT_TYPE.COURSE],
                  value: CONTENT_TYPE.COURSE,
              },
              {
                  text: contentTypes[CONTENT_TYPE.STANDALONE_VIDEO],
                  value: CONTENT_TYPE.STANDALONE_VIDEO,
              },
          ];

    const typeFilter: IChip[] = [
        {
            text: 'All',
            count: typeCount,
            value: 'ALL',
            all: true,
        },
        ...contentTypeFilter,
    ];

    const topicFilter: IChip[] = [
        {
            count: topicCount,
            text: 'All',
            value: 'ALL',
            all: true,
        },
        ...(topicData?.map((tag) => {
            return {
                count: tag.count,
                text: tag.name,
                value: tag.name,
            };
        }) ?? []),
    ];

    useEffect(() => {
        JSON.stringify(values) !== JSON.stringify(prevValues) &&
            dispatch(setLibraryFilter(values));
    }, [values]);

    return {
        control,
        topicFilter,
        typeFilter,
    };
};

export default useFilterSidebar;
