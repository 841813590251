import styled, { css } from 'styled-components';
import { IconManager } from '@components/_universal';
import {
    DefaultText,
    SubHeader,
    Title,
} from '@components/_universal/Typography.styled';

export const AccountTeacherPageWrapper = styled.div`
    height: 100%;
`;

export const PageTitle = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 25px;
    `,
);

export const SectionTitle = styled(SubHeader)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.text_hover};
        font-size: ${fontSizes.xs}px;
        font-weight: ${fontWeights.bold};
        margin: 0;
        text-transform: uppercase;
    `,
);

export const PageText = styled(DefaultText)(
    ({ theme: { fontSizes } }) => css`
        font-size: ${fontSizes.s}px;
        max-width: 450px;
    `,
);

export const PageHeader = styled.div`
    display: flex;
`;

export const Icon = styled(IconManager)`
    margin-left: 15px;
`;
