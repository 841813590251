import styled, { css } from 'styled-components';
import { SubTitle } from '@components/_universal/Typography.styled';

export const CoursePageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 120px 0;
`;

export const PageTitle = styled(SubTitle)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        padding-top: 34px;
    `,
);

export const CourseLessonsNavigation = styled.div`
    min-width: 416px;
    padding: 154px 30px 0 48px;
    position: relative;
`;

export const CourseWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InnerWrapper = styled.div`
    margin-right: 60px;
    width: 100%;
`;
