import React from 'react';
import { GENDER } from '@@types/graphql/GraphqlGlobalTypes';
import { GENDERS } from '@utils/shared/constans';
import { Input } from '@components/_form-elements';
import { SelectType } from '@@types/CommonTypes';
import { useCreateChildAccountMutation } from '@state/childManagement/mutations';
import { useForm } from 'react-hook-form';
import { useMatchMedia } from '@utils/hooks';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import Select from '@components/_form-elements/Select/Select';
import {
    EditUserDetailsFormWrapper,
    StyledButton,
    StyledFormRow,
    StyledInputWrapper,
} from './NewChildForm.styled';

dayjs.extend(customParseFormat);

interface IProps {
    callback?: () => void;
}

interface IForm {
    birthday: string;
    email: string;
    gender: SelectType<GENDER>;
    name: string;
    surname: string;
}

const NewChildForm: React.FC<IProps> = ({ callback }) => {
    const { match } = useMatchMedia('md');
    const [createChildMutation, { loading }] = useCreateChildAccountMutation(
        callback,
    );

    const { errors, register, handleSubmit, control } = useForm<IForm>({
        resolver: yupResolver(validation),
    });

    const onSubmit = (formValues: IForm) => {
        return createChildMutation({
            variables: {
                input: {
                    birthday: dayjs(
                        formValues.birthday,
                        'DD/MM/YYYY',
                    ).toISOString(),
                    email: formValues?.email,
                    gender: formValues?.gender?.value as GENDER,
                    name: formValues.name,
                    surname: formValues.surname,
                },
            },
        });
    };

    return (
        <EditUserDetailsFormWrapper>
            <StyledFormRow>
                <StyledInputWrapper>
                    <InputForm
                        errors={errors.name}
                        label={'child name'}
                        marginBottom={match ? 8 : 24}
                        name={'name'}
                        placeholder={'Enter child name'}
                        register={register}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
                <StyledInputWrapper>
                    <InputForm
                        errors={errors.surname}
                        label={'child surname'}
                        marginBottom={match ? 8 : 24}
                        name={'surname'}
                        placeholder={'Enter child surname'}
                        register={register}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
            </StyledFormRow>
            <StyledFormRow>
                <StyledInputWrapper>
                    <InputForm
                        errors={errors.email}
                        label={'Child email address'}
                        marginBottom={match ? 8 : 24}
                        name={'email'}
                        placeholder={'e.g. joedoe@mail.com'}
                        register={register}
                        staticPosition={match}
                        type={'email'}
                    />
                </StyledInputWrapper>
            </StyledFormRow>
            <StyledFormRow>
                <StyledInputWrapper>
                    <SelectForm
                        control={control}
                        errors={errors.gender}
                        isSearchable
                        label={'child gender'}
                        marginBottom={match ? 8 : 24}
                        name={'gender'}
                        options={GENDERS}
                        placeholder={'Select...'}
                        register={register}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
                <StyledInputWrapper>
                    <InputForm
                        errors={errors.birthday}
                        label={'child date of birth'}
                        marginBottom={match ? 8 : 24}
                        maxLength={10}
                        name={'birthday'}
                        placeholder={'DD/MM/YYYY'}
                        register={register}
                        staticPosition={match}
                    />
                </StyledInputWrapper>
            </StyledFormRow>

            <StyledButton
                disabled={loading}
                onPress={() => {
                    handleSubmit(onSubmit)();
                }}
            >
                Register
            </StyledButton>
        </EditUserDetailsFormWrapper>
    );
};

const InputForm = FieldMessage(Input);
const SelectForm = FieldMessage(Select);

export default NewChildForm;
