import React from 'react';
import { IconManager } from '@components/_universal';
import { setSkipSetPassword } from '@state/_redux/signup/actions';
import { useDispatch } from 'react-redux';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import CreatePasswordForm from '@components/_modals/GlobalModals/__forms/CreatePasswordForm/CreatePasswordForm';
import CreatePasswordTip from '@components/CreatePasswordTip/CreatePasswordTip';
import Modal from '@components/_universal/Modal/Modal';
import {
    ContentWrapper,
    CreatePasswordModalWrapper,
    FooterText,
    ModalFooter,
    StyledTitle,
} from './CreatePasswordModal.styled';

export interface IProps {}

const CreatePasswordModal: React.FC<IProps> = ({}) => {
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(setSkipSetPassword(true));
    };

    return (
        <Modal>
            <CreatePasswordModalWrapper>
                <ContentWrapper>
                    <CloseModalButton onClose={handleCloseModal} />
                    <StyledTitle>Create your password</StyledTitle>
                    <CreatePasswordForm handleCloseModal={handleCloseModal} />
                    <CreatePasswordTip />
                </ContentWrapper>
                <ModalFooter>
                    <IconManager name="InfoIcon" size={46} />
                    <FooterText>
                        If you choose to skip password creation you can do so in
                        ‘My account’ at any time.
                    </FooterText>
                </ModalFooter>
            </CreatePasswordModalWrapper>
        </Modal>
    );
};

export default CreatePasswordModal;
