import styled, { css } from 'styled-components';
import {
    DefaultText,
    SubTitle,
} from '@components/_universal/Typography.styled';

export const DetailsCardWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background-color: ${colors.even_table};
        border-radius: ${borderRadius.card}px;
        padding: 32px;
        position: relative;
    `,
);

export const StudentName = styled(SubTitle)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin: 24px 0 8px;
        text-align: center;
    `,
);

export const Email = styled(DefaultText)(
    ({ theme: { colors } }) => css`
        color: ${colors.text_secondary};
        line-height: 1.5;
        margin-bottom: 24px;
        text-align: center;
        word-wrap: break-word;
    `,
);

export const EditButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 16px;
`;

export const ClassDetials = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background-color: ${colors.background_secondary};
        border-radius: ${borderRadius.card}px;
        border: 1px solid ${colors.stroke};
        margin-bottom: 8px;
        padding: 8px 24px;
    `,
);

export const ClassDetailsElement = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        border-bottom: 1px solid ${colors.stroke};
        display: flex;
        justify-content: space-between;
        padding: 8px 0;

        & + & {
            border: none;
        }
    `,
);

export const ClassDetailsText = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.7;
        margin: 0;
    `,
);
