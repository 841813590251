import { gql, useQuery } from '@apollo/client';
import { SchoolForStudent } from '@@types/graphql/SchoolForStudent';

const SCHOOL_FOR_STUDENT = gql`
    query SchoolForStudent {
        getSchoolForStudent {
            enableLE
            id
            name
        }
    }
`;

export const useSchoolForStudent = () => {
    return useQuery<SchoolForStudent>(SCHOOL_FOR_STUDENT, {
        fetchPolicy: 'network-only',
    });
};
