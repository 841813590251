import styled, { css } from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';
import { ModalContent } from '@components/_modals/GlobalModals.styled';
import AnimatedDots from '@components/_universal/AnimatedDots/AnimatedDots';

export const StudentsUploadModalWrapper = styled(ModalContent)`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: 36px 80px 64px;
    position: relative;
`;

export const LoadingText = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.m}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.75;
        margin: 0 auto;
        max-width: 582px;
        text-align: center;
    `,
);

export const LoadingTitle = styled(Title)(
    ({ theme: { fontFamily, fontSizes } }) => css`
        display: flex;
        font-family: ${fontFamily.sketched};
        font-size: ${fontSizes.max}px;
        margin: 0;
    `,
);

export const LoadingScooterIcon = styled(IconManager)`
    margin: 32px 0 48px;
`;

export const StyledAnimatedDots = styled(AnimatedDots)`
    left: 30px;
    top: 60px;
`;
