import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { useHistory } from 'react-router';
import paths from '@shared/paths';
import {
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
} from '@@types/graphql/ResetPasswordMutation';

const RESET_PASSWORD_MUTATION = gql`
    mutation ResetPasswordMutation($email: String!) {
        resetPassword(email: $email)
    }
`;

export default (email: string) => {
    const history = useHistory();

    return useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
        RESET_PASSWORD_MUTATION,
        {
            onCompleted: () => {
                history.push(paths.reset_link_sent, {
                    email,
                });
            },
            onError: () => {
                notify(
                    'Unfortunately, something went wrong. Please try again!',
                )[ToastTypes.ERROR]();
            },
        },
    );
};
