import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';
import { Title } from '@components/_universal/Typography.styled';

export const DeleteAccountModalWrapper = styled.div`
    background: ${({ theme }) => theme.colors.app_bg};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    display: flex;
    flex-direction: column;
    padding: 56px 80px;
    position: relative;
    width: 960px;
`;

export const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 16px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const StyledButton = styled(Button)`
    width: 234px;

    & + & {
        margin-left: 16px;
    }
`;

export const StyledText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    margin-bottom: 32px;
`;

export const IconWrapper = styled(IconManager)`
    position: absolute;
    right: 40px;
    top: 40px;
`;
