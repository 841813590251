import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import CardHolder from '@components/_universal/Cards/CardHolder/CardHolder';
import dayjs from 'dayjs';
import IconManager from '@components/_universal/IconManager/IconManager';
import { DEFAULT_CARD_WIDTHS } from '@components/_universal/Cards/Cards.styled';
import {
    lessThan10Percent,
    lessThan12Hours,
} from './LiveExperienceCard.helper';
import {
    Arrow,
    LiveExperienceCardBadge,
    LiveExperienceCardContent,
    LiveExperienceCardDate,
    LiveExperienceCardDetails,
    LiveExperienceCardHurryText,
    LiveExperienceCardImage,
    LiveExperienceCardText,
    LiveExperienceCardTime,
    LiveExperienceCardWrapper,
} from './LiveExperienceCard.styled';

interface LiveExperienceCardProps {
    allSlots: number;
    availableSlots: number;
    className?: string;
    endDate?: Date | number;
    featured?: boolean;
    image: string;
    isDifferentHover?: boolean;
    link: string;
    onClick?: () => void;
    startDate?: Date | number;
    text?: string;
    userType?: USER_TYPE;
    width?: number | string;
}

const LiveExperienceCard = ({
    allSlots,
    availableSlots,
    className,
    endDate,
    featured,
    image,
    isDifferentHover = false,
    link,
    onClick,
    startDate,
    text,
    userType,
    width,
}: LiveExperienceCardProps) => {
    const showHurry =
        startDate &&
        (lessThan12Hours(startDate) ||
            lessThan10Percent(availableSlots, allSlots));

    const isOngoing =
        startDate && dayjs(startDate).valueOf() - dayjs().valueOf() < 0;

    const isFinished =
        endDate && dayjs(endDate).valueOf() - dayjs().valueOf() < 0;

    const cardText = () => {
        if (isOngoing && !isFinished) {
            return 'STARTED';
        } else if (isFinished) {
            return 'FINISHED';
        } else if (availableSlots < 1) {
            return 'FULLY BOOKED';
        } else {
            return `${availableSlots} SLOT${
                availableSlots > 1 ? 'S' : ''
            } left`;
        }
    };

    return (
        <CardHolder
            className={className}
            featured={featured}
            link={link}
            onClick={onClick}
            width={width ?? DEFAULT_CARD_WIDTHS.liveExperience}
        >
            <LiveExperienceCardWrapper isDifferentHover={!!isDifferentHover}>
                <LiveExperienceCardImage image={image} />
                <LiveExperienceCardContent>
                    <LiveExperienceCardBadge
                        backgroundColor="live_experience"
                        textColor="text_white"
                    >
                        {cardText()}
                    </LiveExperienceCardBadge>
                    {showHurry && (
                        <LiveExperienceCardHurryText
                            color="secondary"
                            fontFamily="sketched"
                        >
                            hurry!
                        </LiveExperienceCardHurryText>
                    )}
                    <LiveExperienceCardText center>
                        {text}
                    </LiveExperienceCardText>
                    <LiveExperienceCardDetails userType={userType}>
                        <LiveExperienceCardDate center color="text_secondary">
                            <IconManager name="Watch" size={24} />{' '}
                            {dayjs(startDate).format('ddd, MMM DD, YYYY')}{' '}
                            {userType !== USER_TYPE.TEACHER && (
                                <LiveExperienceCardTime as="span">
                                    {dayjs(startDate).format('h:mm A')}
                                </LiveExperienceCardTime>
                            )}
                        </LiveExperienceCardDate>
                        {userType === USER_TYPE.TEACHER && (
                            <>
                                <Arrow name="ArrowRight" size={32} />
                                <LiveExperienceCardTime as="span">
                                    {dayjs(startDate).format('h:mm A')}
                                </LiveExperienceCardTime>
                            </>
                        )}
                    </LiveExperienceCardDetails>
                </LiveExperienceCardContent>
            </LiveExperienceCardWrapper>
        </CardHolder>
    );
};

export default LiveExperienceCard;
