import React from 'react';
import DesktopAgreeRulesPage from './_desktop/DesktopAgreeRulesPage';
import MobileAgreeRulesPage from './_mobile/MobileAgreeRulesPage';
import { useFinishOnboardingMutation } from '@state/settings/mutations';
import { useFormContext } from 'react-hook-form';
import { useMobile } from '@utils/hooks';
import { IOnboardingForm } from '@domains/Auth/Onboarding/Onboarding';

interface IProps {}

const AgreeRulesPage: React.FC<IProps> = () => {
    const { isMobile } = useMobile();
    const { handleSubmit } = useFormContext<IOnboardingForm>();
    const [finishOnboarding, { loading }] = useFinishOnboardingMutation();

    const onSubmit = () =>
        handleSubmit((values) =>
            finishOnboarding({
                variables: {
                    avatarId: values.avatar,
                    password: values.newPassword,
                },
            }),
        )();
    return isMobile ? (
        <MobileAgreeRulesPage loading={loading} onSubmit={onSubmit} />
    ) : (
        <DesktopAgreeRulesPage loading={loading} onSubmit={onSubmit} />
    );
};

export default AgreeRulesPage;
