import React from 'react';
import { externalLinks } from '@shared/paths';
import EnterEmailForm from '@domains/Auth/ResetPassword/_forms/EnterEmailForm/EnterEmailForm';
import {
    ForgotPasswordPageWrapper,
    InnerWrapper,
    LogoIcon,
    LogoLink,
    StyledTitle,
    Subtitle,
} from './ForgotPasswordPage.styled';

interface IProps {}

const ForgotPasswordPage: React.FC<IProps> = () => {
    return (
        <ForgotPasswordPageWrapper>
            <LogoLink href={externalLinks.homepage} target="_blank">
                <LogoIcon
                    fill={['text_white', 'text_white']}
                    name={'LogoWithText'}
                    size={190}
                    sizeY={45}
                />
            </LogoLink>

            <InnerWrapper>
                <StyledTitle>Reset your password</StyledTitle>
                <Subtitle>
                    To reset your password, enter the email address you use to
                    log in to 8billionideas platform
                </Subtitle>
                <EnterEmailForm />
            </InnerWrapper>
        </ForgotPasswordPageWrapper>
    );
};

export default ForgotPasswordPage;
