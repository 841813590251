import React, { useEffect, useState } from 'react';
import { errorMessages } from '@utils/shared/validations/formErrors';
import { FieldError, useForm } from 'react-hook-form';
import { FormRow, InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';
import { InputPassword } from '@components/_form-elements';
import { useCreatePasswordMutation } from '@state/auth/mutations';
import { useCustomRegister } from '@utils/hooks';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import PasswordValidation from '@components/_form-elements/PasswordValidation/PasswordValidation';
import {
    ButtonWrapper,
    CreatePasswordFormWrapper,
    StyledButton,
} from './CreatePasswordForm.styled';

export interface IProps {
    handleCloseModal?: () => void;
}

interface IForm {
    newPassword: string;
    confirmPassword: string;
}

const CreatePasswordForm: React.FC<IProps> = ({ handleCloseModal }) => {
    const { errors, handleSubmit, register, watch } = useForm<IForm>({
        defaultValues: {
            newPassword: '',
            confirmPassword: '',
        },
        resolver: yupResolver(validation),
    });
    const { refElement: passwordInputEl } = useCustomRegister<HTMLInputElement>(
        register,
    );
    const [createPassword, { loading }] = useCreatePasswordMutation();

    const password = watch('newPassword');
    const confirmPassword = watch('confirmPassword');
    const [isValid, setIsValid] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const isValidConfirmPassword = password === confirmPassword;

    const isError =
        !isValid ||
        errors.confirmPassword ||
        errors.newPassword ||
        !isValidConfirmPassword;

    const onSubmit = handleSubmit(({ newPassword }) => {
        if (isError || loading) return;

        createPassword({
            variables: { input: { password: newPassword } },
        });
    });

    useEffect(() => {
        const onFocus = () => setIsFocused(true);

        passwordInputEl.current?.addEventListener('focus', onFocus);

        return () => {
            passwordInputEl.current?.removeEventListener('focus', onFocus);
        };
    }, []);

    return (
        <CreatePasswordFormWrapper onSubmit={onSubmit}>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.newPassword}
                        label={'password'}
                        marginBottom={isFocused ? 8 : 24}
                        maxLength={256}
                        name={'newPassword'}
                        placeholder={'Enter your password'}
                        register={passwordInputEl}
                        type="password"
                    />
                </InputWrapper>
            </FormRow>
            {isFocused && (
                <PasswordValidation
                    marginTop={errors.newPassword && 16}
                    password={password}
                    setIsValid={setIsValid}
                />
            )}
            <FormRow>
                <InputWrapper>
                    <InputForm
                        errors={
                            errors.confirmPassword ||
                            (!isValidConfirmPassword &&
                                confirmPassword.length > 0)
                                ? ({
                                      message: errorMessages.CONFIRM_PASSWORD,
                                  } as FieldError)
                                : undefined
                        }
                        label={'confirm Password'}
                        marginBottom={24}
                        maxLength={256}
                        name={'confirmPassword'}
                        placeholder={'Re-enter your password'}
                        register={register}
                        type="password"
                    />
                </InputWrapper>
            </FormRow>
            <ButtonWrapper>
                {handleCloseModal && (
                    <StyledButton
                        buttonType={'secondary'}
                        onPress={handleCloseModal}
                        type="button"
                    >
                        skip
                    </StyledButton>
                )}
                <StyledButton
                    buttonType={'primary'}
                    disabled={!!isError || loading}
                    onPress={() => {}}
                >
                    create password
                </StyledButton>
            </ButtonWrapper>
        </CreatePasswordFormWrapper>
    );
};

const InputForm = FieldMessage(InputPassword);

export default CreatePasswordForm;
