import * as yup from 'yup';
import {
    confirmPasswordValidation,
    passwordSymbolsValidation,
} from '@utils/shared/validations/formErrors';

export const validation = yup.object().shape({
    confirmPassword: confirmPasswordValidation,
    newPassword: passwordSymbolsValidation,
});
