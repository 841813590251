import React, { useState } from 'react';
import {
    ButtonSizes,
    ButtonTypes,
    ColorType,
    IconName,
} from '@@types/CommonTypes';
import { ButtonWrapper, StyledIconManager } from './Button.styled';

interface IProps {
    buttonType?: ButtonTypes;
    children?: string;
    className?: string;
    disabled?: boolean;
    disabledWithAction?: boolean;
    icon?: {
        activeColor?: ColorType[];
        color?: ColorType[];
        isLeft?: boolean;
        type: IconName;
        size?: number;
    };
    onPress?: () => void;
    size?: ButtonSizes;
    type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<IProps> = ({
    buttonType = 'primary',
    children,
    className,
    disabled,
    disabledWithAction,
    icon,
    onPress,
    size = 'regular',
    type = 'submit',
}) => {
    const [active, setActive] = useState(false);
    return (
        <ButtonWrapper
            disabled={!!disabled}
            disabledWithAction={disabledWithAction}
            icon={!!icon}
            isChildren={!!children}
            onClick={onPress}
            onMouseOut={() => setActive(false)}
            onMouseOver={() => setActive(true)}
            type={type}
            {...{ buttonType, className, size }}
        >
            {icon && icon?.isLeft && (
                <StyledIconManager
                    activeFill={
                        icon?.activeColor ? icon?.activeColor : ['icon_button']
                    }
                    fill={icon?.color ? icon?.color : ['icon_button']}
                    isActive={active}
                    isLeft={icon.isLeft}
                    name={icon?.type}
                    size={icon?.size ?? 24}
                />
            )}
            {children}
            {icon && !icon?.isLeft && (
                <StyledIconManager
                    activeFill={
                        icon?.activeColor ? icon?.activeColor : ['icon_button']
                    }
                    fill={icon?.color ? icon?.color : ['icon_button']}
                    isActive={active}
                    isLeft={icon.isLeft}
                    name={icon?.type}
                    size={icon?.size ?? 24}
                />
            )}
        </ButtonWrapper>
    );
};

export default Button;
