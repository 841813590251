import React, { useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { DeleteStudentFromGroupModal } from '@domains/Teacher/Groups/GroupPage/_modals/DeleteStudentFromGroup/DeleteStudentFromGroupModal';
import { IconManager } from '@components/_universal';
import { Nullable } from '@@types/CommonTypes';
import { StudentsListQuery } from '@@types/graphql/StudentsListQuery';
import { StudentsTableItem } from '@domains/Teacher/Students/StudentsPage/StudentsPage';
import { TableInstance } from 'react-table';
import { useHistory, useParams } from 'react-router';
import { USER_STATUS } from '@@types/graphql/GraphqlGlobalTypes';
import { useResendEmailMutation } from '@state/settings/mutations';
import DeleteStudentModal from '@domains/Teacher/Students/_modals/DeleteStudentModal/DeleteStudentModal';
import EmptyDataSearch from '@components/_universal/Table/EmptyDataSearch/EmptyDataSearch';
import EmptyStudentsList from '@domains/Teacher/Students/StudentsPage/_components/EmptyStudentsList/EmptyStudentsList';
import Loader from '@components/_universal/Loader/Loader';
import paths from '@shared/paths';
import Table from '@components/_universal/Table/Table';
import {
    IconResend,
    LoaderWrapper,
    TableIconButton,
    TableWrapper,
} from '@components/_universal/Table/Table.styled';
import { StudentsListWrapper, TableBodyRow } from './StudentsList.styled';

interface StudentsListProps {
    data: StudentsTableItem[];
    isExistStudent: boolean;
    isGroupView: boolean;
    refetch: () => Promise<ApolloQueryResult<StudentsListQuery>>;
    studentsListLoading: boolean;
    tableProps: TableInstance<StudentsTableItem>;
}

export const StudentsList = ({
    data,
    isExistStudent,
    isGroupView,
    refetch,
    studentsListLoading,
    tableProps,
}: StudentsListProps) => {
    const [userToDelete, setUserToDelete] = useState<Nullable<number>>(null);
    const [studentsToRemove, setstudentsToRemove] = useState<
        Nullable<number[]>
    >(null);
    const history = useHistory();
    const { groupId } = useParams<{ groupId: string }>();

    const [resendEmail] = useResendEmailMutation();

    if (!isExistStudent && !studentsListLoading) return <EmptyStudentsList />;

    return (
        <StudentsListWrapper>
            <TableWrapper>
                {studentsListLoading ? (
                    <LoaderWrapper>
                        <Loader size={'medium'} center />
                    </LoaderWrapper>
                ) : (
                    <Table<StudentsTableItem> table={tableProps}>
                        {({
                            getTableProps,
                            headerGroups,
                            getTableBodyProps,
                            page,
                            prepareRow,
                        }) =>
                            page.length === 0 ? (
                                <EmptyDataSearch />
                            ) : (
                                <table {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr
                                                {...headerGroup.getHeaderGroupProps()}
                                            >
                                                {headerGroup.headers.map(
                                                    (column, index) => (
                                                        <th
                                                            {...column.getHeaderProps()}
                                                            key={`header ${index}`}
                                                            style={{
                                                                width:
                                                                    column.width,
                                                            }}
                                                        >
                                                            {column.render(
                                                                'Header',
                                                            )}
                                                        </th>
                                                    ),
                                                )}
                                                <th style={{ width: 80 }} />
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, index) => {
                                            prepareRow(row);
                                            return (
                                                <TableBodyRow
                                                    {...row.getRowProps()}
                                                    key={`row ${index}`}
                                                    onClick={() =>
                                                        history.push(
                                                            paths.student_details_teacher(
                                                                data[
                                                                    index
                                                                ].id.toString(),
                                                            ),
                                                        )
                                                    }
                                                >
                                                    {row.cells.map(
                                                        (cell, index) => {
                                                            return (
                                                                <td
                                                                    {...cell.getCellProps()}
                                                                    key={`cell ${index}`}
                                                                >
                                                                    {cell.render(
                                                                        'Cell',
                                                                    )}
                                                                </td>
                                                            );
                                                        },
                                                    )}
                                                    <td
                                                        className={'td_icons'}
                                                        key={'td_icons'}
                                                    >
                                                        {row.values.status ===
                                                            USER_STATUS.PENDING && (
                                                            <TableIconButton
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    resendEmail(
                                                                        {
                                                                            variables: {
                                                                                email:
                                                                                    row
                                                                                        .values
                                                                                        .email,
                                                                            },
                                                                        },
                                                                    );
                                                                }}
                                                            >
                                                                <IconResend
                                                                    cursor="pointer"
                                                                    hoverFill={[
                                                                        'primary',
                                                                    ]}
                                                                    name={
                                                                        'IconResend'
                                                                    }
                                                                    size={24}
                                                                />
                                                            </TableIconButton>
                                                        )}
                                                        {isGroupView ? (
                                                            <TableIconButton
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    setstudentsToRemove(
                                                                        [
                                                                            data[
                                                                                index
                                                                            ]
                                                                                .id,
                                                                        ],
                                                                    );
                                                                }}
                                                            >
                                                                <IconManager
                                                                    hoverFill={[
                                                                        'error',
                                                                    ]}
                                                                    name="RemoveIcon"
                                                                    size={24}
                                                                />
                                                            </TableIconButton>
                                                        ) : (
                                                            <TableIconButton
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    setUserToDelete(
                                                                        data[
                                                                            index
                                                                        ].id,
                                                                    );
                                                                }}
                                                            >
                                                                <IconManager
                                                                    cursor="pointer"
                                                                    hoverFill={[
                                                                        'error',
                                                                    ]}
                                                                    name={
                                                                        'IconTrash'
                                                                    }
                                                                    size={24}
                                                                />
                                                            </TableIconButton>
                                                        )}
                                                    </td>
                                                </TableBodyRow>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )
                        }
                    </Table>
                )}
            </TableWrapper>

            {/* MODALS */}
            {userToDelete && (
                <DeleteStudentModal
                    onClose={() => setUserToDelete(null)}
                    refetch={refetch}
                    studentId={userToDelete}
                />
            )}
            {studentsToRemove && (
                <DeleteStudentFromGroupModal
                    groupId={groupId}
                    onClose={() => setstudentsToRemove(null)}
                    refetch={refetch}
                    studentsId={studentsToRemove}
                />
            )}
        </StudentsListWrapper>
    );
};
