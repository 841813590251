import { ChipsInputWrapper, StyledChip } from './ChipsInput.styled';
import { Control, useController } from 'react-hook-form';
import { IChip } from '@@types/CommonTypes';

export interface IProps {
    chips: IChip[];
    className?: string;
    control: Control;
    name: string;
}

const ChipsInput: React.FC<IProps> = ({ chips, className, control, name }) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
        defaultValue: [],
    });

    const handleChange = (chipValue: string, all?: boolean) => {
        const changeValue = (newValueArray: string[]) =>
            onChange(
                value.includes(chipValue)
                    ? value.filter((item: string) => item !== chipValue)
                    : newValueArray,
            );

        all ? changeValue([]) : changeValue(all ? [] : [...value, chipValue]);
    };

    return (
        <ChipsInputWrapper className={className}>
            {chips.map((chip) => (
                <StyledChip
                    active={
                        chip.all
                            ? value.length === 0
                            : value.includes(chip.value)
                    }
                    key={chip.value}
                    onClick={(chipValue) => handleChange(chipValue, chip.all)}
                    value={chip.value}
                >
                    {chip.text} {chip?.count !== undefined && `(${chip.count})`}
                </StyledChip>
            ))}
        </ChipsInputWrapper>
    );
};

export default ChipsInput;
