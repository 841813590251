import React from 'react';
import AgreeRulesForm from '@domains/Auth/Onboarding/_forms/AgreeRulesForm/AgreeRulesForm';
import Rule from '@domains/Auth/Onboarding/_components/Rule/Rule';
import paths, { externalLinks } from '@shared/paths';
import { RULES } from '@domains/Auth/Onboarding/AgreeRulesPage/AgreeRulesPage.data';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { IOnboardingForm } from '@domains/Auth/Onboarding/Onboarding';
import {
    CheckboxBackground,
    CheckboxWrapper,
    MobileAgreeRulesPageWrapper,
    MobileDescription,
    MobileOrnamentIcon,
    MobilePageContent,
    MobilePageFooter,
    MobileReadOurRulesIcon,
    MobileRulesList,
    MobileTitle,
    StyledButton,
} from './MobileAgreeRulesPage.styled';

export interface IProps {
    loading: boolean;
    onSubmit: () => void;
}

const MobileAgreeRulesPage: React.FC<IProps> = ({ onSubmit, loading }) => {
    const history = useHistory();
    const { watch } = useFormContext<IOnboardingForm>();

    const agreeRules = watch('agreeRules');

    return (
        <MobileAgreeRulesPageWrapper>
            <MobilePageContent>
                <MobileTitle>Good behaviour is essential</MobileTitle>
                <MobileDescription>
                    Let's make 8billionideas a safe and respectful space for
                    everyone. Read through our rules below.
                </MobileDescription>
                <MobileReadOurRulesIcon name={'ReadOurRulesText'} size={190} />
                <MobileOrnamentIcon name={'AgreeRulesOrnaments'} size={280} />

                <MobileRulesList>
                    {RULES.map(({ ruleCard, text, ...badgeProps }, index) => (
                        <Rule
                            badgeProps={badgeProps}
                            key={index}
                            ruleCard={ruleCard}
                            text={text}
                        />
                    ))}
                    <CheckboxWrapper>
                        <CheckboxBackground />
                        <AgreeRulesForm />
                    </CheckboxWrapper>
                </MobileRulesList>
            </MobilePageContent>

            <MobilePageFooter>
                <StyledButton
                    buttonType={'secondary'}
                    onPress={() => history.push(paths.set_password)}
                    size={'small'}
                >
                    go back
                </StyledButton>
                <StyledButton
                    buttonType={'primary'}
                    disabled={loading}
                    disabledWithAction={!agreeRules}
                    onPress={onSubmit}
                    size={'small'}
                >
                    let’s get started
                </StyledButton>
            </MobilePageFooter>
        </MobileAgreeRulesPageWrapper>
    );
};

export default MobileAgreeRulesPage;
