import Chip from '@components/_universal/Chip/Chip';
import styled from 'styled-components';

export const ChipsInputWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    /* NOTE: Negative margins to remove the space around that created by the chips */
    margin: -4px;
`;

export const StyledChip = styled(Chip)`
    margin: 4px;
`;
