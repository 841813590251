import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IAppSubRoutes } from '@routes/index';
import { SelectType } from '@@types/CommonTypes';
import { SignupCreateWrapper } from './SignupCreatePage.styled';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import paths from '@shared/paths';
import SubRoutes from '@routes/SubRoutes';
import {
    createAccountValidation,
    createPasswordValidation,
} from './validation';

export enum creationSteps {
    ACCOUNT,
    PASSWORD,
}

interface IProps extends IAppSubRoutes {}

export interface ISignupForm {
    address: string;
    city: string;
    confirmPassword: string;
    country: SelectType;
    email: string;
    name: string;
    newPassword: string;
    surname: string;
    zipCode: string;
}

const validations = [createAccountValidation, createPasswordValidation];

const steps = {
    [paths.signup_create_account]: creationSteps.ACCOUNT,
    [paths.signup_create_password]: creationSteps.PASSWORD,
};

const SignupCreatePage: React.FC<IProps> = ({ routes, layoutComponent }) => {
    const { pathname } = useLocation();
    const [step, setStep] = useState<creationSteps>(steps[pathname]);

    const formMethods = useForm<ISignupForm>({
        resolver: yupResolver(validations[step]),
        shouldUnregister: false,
    });

    useEffect(() => {
        setStep(steps[pathname]);
    }, [pathname]);

    return (
        <SignupCreateWrapper>
            <FormProvider {...formMethods}>
                <SubRoutes {...{ routes, layoutComponent }} />
            </FormProvider>
        </SignupCreateWrapper>
    );
};

export default SignupCreatePage;
