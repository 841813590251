import styled from 'styled-components';
import {
    BigHeader,
    DefaultText,
} from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const ActivityMaterialsWrapper = styled.div``;

export const StyledBigHeader = styled(BigHeader)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 16px;
`;

export const MaterialsBox = styled.div`
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius.button}px;
    border: 3px solid ${({ theme }) => theme.colors.background_tertiary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 250px;
    padding: 30px 64px;
`;

export const GhostIcon = styled(IconManager)`
    margin-bottom: 24px;
`;

export const DownloadIcon = styled(IconManager)`
    justify-content: center;
    margin-bottom: 16px;
`;

export const EmptyText = styled(DefaultText)`
    color: 3px solid ${({ theme }) => theme.colors.text_secondary};
    margin-bottom: 0;
    text-align: center;
`;

export const MaterialsList = styled.div`
    color: 3px solid ${({ theme }) => theme.colors.text_secondary};
    margin-bottom: 0;
    text-align: center;
`;

export const FileName = styled(DefaultText)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
    text-align: center;
    transition: all ${({ theme }) => theme.transitions.default}s;
`;

export const FileSize = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    text-align: center;
    margin-bottom: 0;
`;

export const MaterialItem = styled.div`
    cursor: pointer;
    padding: 16px 0;

    &:hover {
        ${FileName} {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;
