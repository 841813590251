import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import Textarea from '@components/_form-elements/Textarea/Textarea';
import {
    BigHeader,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const StyledBigHeader = styled(BigHeader)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 48px 0 16px;
    text-align: center;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const RatingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const StyledButton = styled(Button)`
    width: 264px;
`;

export const HeaderWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 8px;
`;

export const StyledText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    margin-bottom: 40px;
`;

export const ArtWrapper = styled(IconManager)`
    position: absolute;
    right: -86px;
    top: 128px;
    z-index: 9;
`;

export const StyledTextArea = styled(Textarea)`
    height: 128px;
    width: 578px;
`;

export const TextAreaWrapper = styled.div`
    margin: 0 auto;
`;
