import { gql, useQuery } from '@apollo/client';
import { StudentsCountQuery } from '@@types/graphql/StudentsCountQuery';

const STUDENTS_COUNT_QUERY = gql`
    query StudentsCountQuery {
        getStudentsCount {
            count
        }
    }
`;

export default () =>
    useQuery<StudentsCountQuery>(STUDENTS_COUNT_QUERY, {
        fetchPolicy: 'network-only',
    });
