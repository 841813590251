import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMatchMedia } from '@utils/hooks';
import paths from '@shared/paths';
import {
    FooterText,
    LoginFooterWrapper,
    StyledLinkText,
    StyledText,
    StyledTrialInformation,
    TextContainer,
} from './LoginFooter.styled';

export interface IProps {}

const LoginFooter: React.FC<IProps> = () => {
    const { match } = useMatchMedia('sm');
    const history = useHistory();

    const handleClick = () => {
        if (!match) return;
        history.push(paths.signup_create_account);
    };

    return (
        <LoginFooterWrapper onClick={handleClick}>
            <StyledTrialInformation daysText="10 DAY" trialText="FREE TRIAL" />
            <TextContainer>
                <FooterText>
                    Are you a parent and don’t have an account?{' '}
                </FooterText>
                <FooterText>
                    {!match ? (
                        <>
                            <StyledLinkText to={paths.signup_create_account}>
                                Sign up here!
                            </StyledLinkText>{' '}
                            and enjoy 10 day free trial!
                        </>
                    ) : (
                        <StyledText>Sign up here!</StyledText>
                    )}
                </FooterText>
            </TextContainer>
        </LoginFooterWrapper>
    );
};

export default LoginFooter;
