import React from 'react';
import DesktopLoginLinkPage from './_desktop/DesktopLoginLinkPage';
import MobileLoginLinkPage from './_mobile/MobileLoginLinkPage';
import useMobile from '@utils/hooks/useMobile';

interface IProps {}

const LoginLinkPage: React.FC<IProps> = () => {
    const { isMobile } = useMobile();

    return isMobile ? <MobileLoginLinkPage /> : <DesktopLoginLinkPage />;
};

export default LoginLinkPage;
