import React, { ReactElement, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { TooltipProps } from 'react-tooltip';
import {
    TooltipText,
    StyledTooltip,
} from '@components/_universal/Tooltip/Tooltip.styled';
import { FontSizes } from '@@types/CommonTypes';

interface ITooltipProps {
    children: ReactElement;
    className?: string;
    fontSize?: FontSizes;
    tooltipContent: string;
    tooltipId: string;
    tooltipProps?: TooltipProps;
}

export const Tooltip: React.FC<ITooltipProps> = ({
    children,
    className,
    fontSize,
    tooltipContent,
    tooltipId,
    tooltipProps,
}) => {
    const decoratedChild = React.cloneElement(children, {
        'data-tip': true,
        'data-for': tooltipId,
    });
    const theme = useContext(ThemeContext);

    tooltipProps = {
        ...tooltipProps,
        effect: tooltipProps?.effect ?? 'solid',
        backgroundColor:
            tooltipProps?.backgroundColor ?? theme.colors.tooltop_background,
        offset: tooltipProps?.offset ?? { top: 0 },
    };

    return (
        <>
            {decoratedChild}
            <StyledTooltip
                className={className}
                id={tooltipId}
                {...tooltipProps}
            >
                <TooltipText fontSize={fontSize}>{tooltipContent}</TooltipText>
            </StyledTooltip>
        </>
    );
};
