import { gql, useQuery } from '@apollo/client';
import {
    RecommendedVideosQuery,
    RecommendedVideosQueryVariables,
} from '@@types/graphql/RecommendedVideosQuery';

const RECOMMENDED_VIDEOS_QUERY = gql`
    query RecommendedVideosQuery(
        $paginationInput: SearchPaginationInput
        $typeFilter: ContentTypeFilter
    ) {
        getRecommendedContentsForUser(
            paginationInput: $paginationInput
            typeFilter: $typeFilter
        ) {
            __typename
            ... on StandaloneVideo {
                _id
                featured
                name
                type
                thumbnail
                video {
                    duration
                    name
                }
            }
        }
    }
`;

export default () => {
    return useQuery<RecommendedVideosQuery, RecommendedVideosQueryVariables>(
        RECOMMENDED_VIDEOS_QUERY,
        {
            variables: {
                paginationInput: {
                    take: 15,
                    offset: 0,
                },
                typeFilter: {
                    STANDALONE_VIDEO: true,
                },
            },
        },
    );
};
