import { UseFormMethods } from 'react-hook-form';
import { useEffect, useRef } from 'react';

export default <RefType>(register: UseFormMethods['register']) => {
    const refElement = useRef<RefType>(null);

    useEffect(() => {
        refElement.current && register(refElement.current);
    }, []);

    return { refElement };
};
