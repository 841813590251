import { LiveExperienceCarousel } from '@domains/Child/Dashboard/_components/LiveExperienceCarousel/LiveExperienceCarousel';
import { PermittedWrapper } from './Permitted.styled';
import { useSelector } from 'react-redux';
import LatestPosts from '@domains/Child/Dashboard/_components/LatestPosts/LatestPosts';
import MoreElements from '@domains/Child/Dashboard/_components/MoreElements/MoreElements';
import OrnamentSection from '@domains/Child/Dashboard/_components/OrnamentSection/OrnamentSection';
import Recommended from '@domains/Child/Dashboard/_components/Recommended/Recommended';

export const Permitted: React.FC = () => {
    const { liveExperiencesVisibility } = useSelector(
        (state) => state.liveExperiencesVisibility,
    );
    return (
        <PermittedWrapper>
            {liveExperiencesVisibility && <LiveExperienceCarousel />}
            <Recommended showLiveExperience={liveExperiencesVisibility} />
            <OrnamentSection />
            <LatestPosts />
            <MoreElements showLiveExperience={liveExperiencesVisibility} />
        </PermittedWrapper>
    );
};
