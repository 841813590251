import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import {
    UpdateStudentsAccountsGroup,
    UpdateStudentsAccountsGroupVariables,
} from '@@types/graphql/UpdateStudentsAccountsGroup';

export const UPDATE_STUDENTS_ACCOUNTS_GROUP = gql`
    mutation UpdateStudentsAccountsGroup(
        $input: UpdateStudentsAccountsGroupsInput!
    ) {
        updateStudentsAccountsGroups(input: $input) {
            id
        }
    }
`;

export const useUpdateStudentsAccountsGroup = (callback: () => void) => {
    return useMutation<
        UpdateStudentsAccountsGroup,
        UpdateStudentsAccountsGroupVariables
    >(UPDATE_STUDENTS_ACCOUNTS_GROUP, {
        onCompleted: () => {
            callback();
            notify('You have successfully updated students groups.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
