import { gql, useQuery } from '@apollo/client';
import { GetStudentsGroupDetailsInput } from '@@types/graphql/GraphqlGlobalTypes';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import {
    StudentsGroupDetails,
    StudentsGroupDetailsVariables,
} from '@@types/graphql/StudentsGroupDetails';

export const STUDENTS_GROUP_DETAILS = gql`
    query StudentsGroupDetails($input: GetStudentsGroupDetailsInput!) {
        details: getStudentsGroupDetails(input: $input) {
            id
            title
            schoolId
            classes {
                id
                year
                className
            }
        }
    }
`;

export const useStudentsGroupDetails = (
    input: GetStudentsGroupDetailsInput,
) => {
    return useQuery<StudentsGroupDetails, StudentsGroupDetailsVariables>(
        STUDENTS_GROUP_DETAILS,
        {
            fetchPolicy: 'network-only',
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
            variables: {
                input,
            },
        },
    );
};
