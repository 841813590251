import { GroupStudentsListUploader } from '@components/GroupStudentsListUploader/GroupStudentsListUploader';
import { StudentsListCsvDownloader } from '@components/_universal/StudentsListCsvDownloader/StudentsListCsvDownloader';
import {
    BoldText,
    ButtonsWrapper,
    EmptyStateSearchWrapper,
    SampleCsvText,
    Text,
    UploadButton,
    UploadWrapper,
} from './EmptyGroupState.styled';

export const EmptyGroupState = () => {
    return (
        <EmptyStateSearchWrapper>
            <BoldText>It seems you haven't assigned any students yet.</BoldText>
            <Text>
                Download the CSV file with students data, once you do, upload it
                to this form and then edit the list by removing students which
                you wouldn't like to be assigned to this group. Make sure to do
                NOT make any changes to the downloaded CSV file.
            </Text>
            <ButtonsWrapper>
                <GroupStudentsListUploader>
                    <UploadWrapper>
                        <UploadButton
                            buttonType={'primary'}
                            icon={{ size: 16, type: 'UploadArrow' }}
                            onPress={() => {}}
                            size={'regular'}
                        >
                            upload a csv file
                        </UploadButton>
                    </UploadWrapper>
                </GroupStudentsListUploader>
            </ButtonsWrapper>
            <StudentsListCsvDownloader sampleData>
                <SampleCsvText>Download sample CSV file</SampleCsvText>
            </StudentsListCsvDownloader>
        </EmptyStateSearchWrapper>
    );
};
