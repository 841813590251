import React from 'react';
import { CARDS } from './BehaviourAgreementPage.data';
import { externalLinks } from '@shared/paths';
import {
    PageText,
    PageTitle,
} from '@domains/Child/Account/AccountPage/AccountPage.styled';
import {
    BehaviourAgreementPageWrapper,
    Card,
    CardBadge,
    CardContent,
    SectionWrapper,
    StrongText,
} from './BehaviourAgreementPage.styled';

interface IProps {}

const BehaviourAgreementPage: React.FC<IProps> = () => {
    return (
        <BehaviourAgreementPageWrapper>
            <PageTitle>Good behaviour agreement</PageTitle>
            <PageText>
                To make sure everybody is comfortable using 8billionideas,
                please read and confirm that you will respect the following
                rules of behaviour.
            </PageText>
            <SectionWrapper>
                {CARDS.map(
                    ({ cardContent, text, indent, ...badgeProps }, index) => (
                        <Card key={index} {...{ indent }}>
                            <CardBadge {...badgeProps}>{text}</CardBadge>
                            <CardContent>{cardContent}</CardContent>
                        </Card>
                    ),
                )}
            </SectionWrapper>
        </BehaviourAgreementPageWrapper>
    );
};

export default BehaviourAgreementPage;
