import * as Icons from '@shared/svgs/Icons';
import defaultTheme from '@shared/theme/default.theme';
import { StateType } from 'typesafe-actions';
import reducers from '@state/_redux/reducers';
import { ValueType } from 'react-select';
import {
    ContentTypeFilter,
    CONTENT_TYPE,
    USER_TYPE,
} from './graphql/GraphqlGlobalTypes';

export enum EnvType {
    development = 'development',
    production = 'production',
}

export enum Theme {
    DEFAULT = 'DEFAULT',
    DARK = 'DARK',
}

export interface RootState extends StateType<typeof reducers> {}

export type ThemeType = typeof defaultTheme;
export type ColorType = keyof ThemeType['colors'];
export type FontWeights = keyof ThemeType['fontWeights'];
export type FontSizes = keyof ThemeType['fontSizes'];
export type FontFamilies = keyof ThemeType['fontFamily'];
export type CursorType = keyof ThemeType['cursors'];

export type IconName = keyof typeof Icons;

export type ButtonTypes =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'primaryError'
    | 'secondaryError';
export type ButtonSizes = 'regular' | 'small';
export type ProgressVariants = 'small' | 'big';
export type Nullable<T = string> = T | null;

// TODO: add proper type form backend
export type CardTypes = 'STANDALONE_VIDEO' | 'LIVE_EXPERIENCE' | 'COURSE';

export type PlaceholderCardTypes = 'primary' | 'secondary';
// TODO: add proper type form backend
export interface IMosaicCard {
    allSlots?: number;
    availableSlots?: number;
    endDate?: Date;
    featured?: boolean;
    image: string;
    key: string;
    schoolsWithAccess?: { id: number }[] | null;
    showToChildren?: boolean | null;
    showToStudents?: boolean | null;
    startDate?: Date;
    text?: string;
    type: CardTypes;
    url: string;
}

export type FieldTypes = 'text' | 'email' | 'number' | 'password';

export type SelectType<V = string, IsMulti extends boolean = false> = ValueType<
    {
        label: string;
        value: V;
    },
    IsMulti
>;

export interface IChip {
    count?: number;
    text: string;
    value: string;
    all?: boolean;
}

export interface IBadge {
    backgroundColor: ColorType;
    className?: string;
    image?: {
        name: IconName;
        size: number;
    };
    textColor?: ColorType;
}

export enum ToastTypes {
    DEFAULT = 'default',
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export type LiveExperienceType =
    | 'experienceDone'
    | 'experienceNotStart'
    | 'join';

export type LiveExperienceSteps =
    | 'experienceDone'
    | 'fullyBooked'
    | 'signedUp'
    | 'signUp'
    | 'ongoing'
    | 'submitted';

export enum RegExpPasswordKeys {
    min_number = 'min_number',
    min_char = 'min_char',
    uppercase = 'uppercase',
    lowercase = 'lowercase',
    min_symbol = 'min_symbol',
    valid_email = 'valid_email',
}

export const RegExpPasswordManager: { [key in RegExpPasswordKeys]: RegExp } = {
    [RegExpPasswordKeys.min_char]: /.{8,}/,
    [RegExpPasswordKeys.uppercase]: /(?=.*[A-Z])/,
    [RegExpPasswordKeys.lowercase]: /(?=.*[a-z])(?=\s*\S)/,
    [RegExpPasswordKeys.min_number]: /(?=.*[0-9])/,
    [RegExpPasswordKeys.min_symbol]: /[-!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/]/,
    [RegExpPasswordKeys.valid_email]: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
};

export const CONTENT_CONSUMER_USER = [USER_TYPE.CHILD, USER_TYPE.STUDENT];

export interface UploadedCSVDataProps {
    data: string[];
    error?: [];
    meta: {
        aborted: boolean;
        cursor: number;
        delimiter: string;
        linebreak: string;
        truncated: boolean;
    };
}
