import React from 'react';
import { useHistory } from 'react-router';
import paths from '@shared/paths';
import {
    BoldedDescription,
    ButtonsWrapper,
    EmailVerifiedCheckIcon,
    HeadDescription,
    InfoInner,
    InfoWrapper,
    InformationItem,
    InformationList,
    SignupEmailVerifiedWrapper,
    StyledBigTitle,
    StyledButton,
    VerifiedIcon,
} from './SignupEmailVerifiedPage.styled';

interface IProps {}

const SignupEmailVerifiedPage: React.FC<IProps> = () => {
    const history = useHistory();

    return (
        <SignupEmailVerifiedWrapper>
            <InfoWrapper>
                <StyledBigTitle>Successfully verified!</StyledBigTitle>
                <InfoInner>
                    <VerifiedIcon
                        size={303}
                        sizeY={277}
                        name={'EmailVerified'}
                    />
                    <InformationList>
                        <InformationItem>
                            <EmailVerifiedCheckIcon
                                name={'EmailVerifiedCheckIcon'}
                                size={34}
                            />
                            Email address verified
                        </InformationItem>
                        <InformationItem>
                            <EmailVerifiedCheckIcon
                                name={'EmailVerifiedCheckIcon'}
                                size={34}
                            />
                            Parent account set up
                        </InformationItem>
                    </InformationList>
                </InfoInner>
                <HeadDescription>
                    Congratulations!{' '}
                    <BoldedDescription>
                        You have successfully verified the email address and
                        your parent account has been set up. Register your
                        children and enjoy{' '}
                    </BoldedDescription>
                    with them unlimited access to all courses and experiences on
                    our platform for the next{' '}
                    <BoldedDescription>10 days</BoldedDescription>.
                </HeadDescription>
                <ButtonsWrapper>
                    <StyledButton
                        buttonType={'secondary'}
                        onPress={() => history.push(paths.dashboard_parent())}
                    >
                        Skip for now
                    </StyledButton>
                    <StyledButton
                        onPress={() => history.push(paths.signup_register)}
                    >
                        Register your children
                    </StyledButton>
                </ButtonsWrapper>
            </InfoWrapper>
        </SignupEmailVerifiedWrapper>
    );
};

export default SignupEmailVerifiedPage;
