import { gql, useQuery } from '@apollo/client';
import {
    ChildHomeworkQuery,
    ChildHomeworkQueryVariables,
} from '@@types/graphql/ChildHomeworkQuery';

const CHILD_HOMEWORK_QUERY = gql`
    query ChildHomeworkQuery($childId: Int!) {
        getChildHomeworks(childId: $childId) {
            id
            content {
                id
                type
                name
                description
                thumbnail
            }
            feedbackGivenAt
            feedbackBy {
                name
                surname
            }
            mentorFeedback
            createdAt
            files {
                id
                filename
                type
            }
        }
    }
`;

export default (childId: string) =>
    useQuery<ChildHomeworkQuery, ChildHomeworkQueryVariables>(
        CHILD_HOMEWORK_QUERY,
        {
            onError: () => {},
            variables: {
                childId: parseInt(childId),
            },
        },
    );
