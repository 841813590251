import React, { useState } from 'react';
import Pagination from '../_components/Pagination/Pagination';
import SetPasswordForm from '@domains/Auth/Onboarding/_forms/SetPasswordForm/SetPasswordForm';
import paths from '@shared/paths';
import { IOnboardingForm } from '@domains/Auth/Onboarding/Onboarding';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMobile } from '@utils/hooks';
import {
    Bulb,
    Description,
    FormWrapper,
    OrnamentIcon,
    PageContent,
    PageFooter,
    SetPasswordPageWrapper,
    SetUpYourPasswordIcon,
    StyledButton,
    StyledTitle,
    Tip,
} from './SetPasswordPage.styled';

interface IProps {}

const SetPasswordPage: React.FC<IProps> = () => {
    const { isMobile } = useMobile();
    const [isValid, setIsValid] = useState(false);
    const history = useHistory();
    const { errors, watch } = useFormContext<IOnboardingForm>();

    const password = watch('newPassword');
    const confirmPassword = watch('confirmPassword');
    const isValidConfirmPassword = password === confirmPassword;

    return (
        <SetPasswordPageWrapper>
            <PageContent>
                <StyledTitle>Welcome to 8billionideas!</StyledTitle>
                <Description>
                    Now set up your 8billionideas account password!
                </Description>
                <SetUpYourPasswordIcon
                    name={'SetUpYourPasswordText'}
                    size={240}
                    sizeY={164}
                />
                <OrnamentIcon name={'ChooseAvatarOrnaments'} size={440} />
                <FormWrapper>
                    <Tip>
                        <Bulb name="Bulb" size={42} fill={['text']} />
                        Don't worry if you forget your password! You can always
                        log in using a link we email into your inbox.
                    </Tip>
                    <SetPasswordForm setIsValid={setIsValid} />
                </FormWrapper>
            </PageContent>
            <PageFooter>
                <StyledButton
                    buttonType={'secondary'}
                    onPress={() => history.push(paths.choose_avatar)}
                    size={'small'}
                >
                    previous step
                </StyledButton>
                {!isMobile && <Pagination>Step 2</Pagination>}
                <StyledButton
                    buttonType={'primary'}
                    disabled={
                        !isValid ||
                        !!errors.confirmPassword ||
                        !!errors.newPassword ||
                        !isValidConfirmPassword
                    }
                    onPress={() => history.push(paths.agree_rules)}
                    size={'small'}
                >
                    Continue
                </StyledButton>
            </PageFooter>
        </SetPasswordPageWrapper>
    );
};

export default SetPasswordPage;
