import React, { useRef } from 'react';
import { IconManager } from '@components/_universal';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRateLiveExperienceMutation } from '@state/liveExperience/mutations';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import Modal from '@components/_universal/Modal/Modal';
import Rating from '@components/_form-elements/Rating/Rating';
import {
    ModalContent,
    ModalTitle,
} from '@components/_modals/GlobalModals.styled';
import {
    ArtWrapper,
    ButtonWrapper,
    HeaderWrapper,
    RatingWrapper,
    StyledBigHeader,
    StyledButton,
    StyledText,
    StyledTextArea,
    TextAreaWrapper,
} from './RateModal.styled';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
    subtitle?: string;
    title?: string;
}

interface IForm {
    comment: string;
    rate: number;
}

const RateModal: React.FC<IProps> = ({ setIsModalOpen, subtitle, title }) => {
    const { control, errors, handleSubmit, register, watch } = useForm<IForm>({
        defaultValues: {
            rate: 0,
            comment: '',
        },
        resolver: yupResolver(validation),
    });
    const rate = watch('rate');
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();
    const ref = useRef(null);
    const [rateLiveExperience] = useRateLiveExperienceMutation();

    const onSubmit = (formData: IForm) => {
        rateLiveExperience({
            variables: {
                liveExperienceId: liveExperienceId,
                ...formData,
            },
        });
        setIsModalOpen(false);
    };

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <ArtWrapper size={64} name={'RateIllustration'} />
                <HeaderWrapper>
                    <ModalTitle>{title}</ModalTitle>
                    <IconManager size={64} name={'GoodFeedback'} />
                </HeaderWrapper>
                <StyledText>{subtitle}</StyledText>
                <RatingWrapper>
                    <Rating {...{ control }} name={'rate'} />
                    <StyledBigHeader>
                        Did you like this experience? Tell us about it!
                    </StyledBigHeader>
                </RatingWrapper>
                <TextAreaWrapper>
                    <TextArea
                        errors={errors.comment}
                        inputType={'secondary'}
                        marginBottom={40}
                        name={'comment'}
                        placeholder={'Enter your answer (optional)'}
                        register={register}
                    />
                </TextAreaWrapper>
                <ButtonWrapper>
                    <StyledButton
                        disabled={!rate}
                        onPress={() => {
                            handleSubmit(onSubmit)();
                        }}
                    >
                        Send
                    </StyledButton>
                </ButtonWrapper>
            </ModalContent>
        </Modal>
    );
};

const TextArea = FieldMessage(StyledTextArea);

export default RateModal;
