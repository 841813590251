import React from 'react';
import { CountdownRenderProps } from 'react-countdown';
import {
    CountDownRendererWrapper,
    GroupNumberWrapper,
    NumberWrapper,
    TimeName,
    TimeSectionWrapper,
} from './CountDownRenderer.styled';

export const CountDownRenderer = ({
    formatted: { days, hours, minutes, seconds },
}: CountdownRenderProps) => {
    // This function takes the formatted date part as a string. And it displays the digits in the right place in the counter. If the number of days is over 99, an additional digit space is displayed
    const countDown = (time: string, name: string) => {
        return (
            <TimeSectionWrapper>
                <GroupNumberWrapper>
                    <NumberWrapper>{time[0]}</NumberWrapper>
                    <NumberWrapper>{time[1]}</NumberWrapper>
                    {time[2] && <NumberWrapper>{time[2]}</NumberWrapper>}
                </GroupNumberWrapper>
                <TimeName>{name}</TimeName>
            </TimeSectionWrapper>
        );
    };

    return (
        <CountDownRendererWrapper>
            {countDown(days, 'Days')}
            {countDown(hours, 'Hours')}
            {countDown(minutes, 'Minutes')}
            {countDown(seconds, 'Seconds')}
        </CountDownRendererWrapper>
    );
};
