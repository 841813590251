import { ColorType, IconName } from '@@types/CommonTypes';
import { CardText, CardTextBold } from './BehaviourAgreementPage.styled';

interface ICard {
    backgroundColor: ColorType;
    className?: string;
    image?: {
        name: IconName;
        size: number;
    };
    text: string;
    textColor?: ColorType;
    cardContent: React.ReactNode;
    indent?: number;
}

export const CARDS: ICard[] = [
    {
        backgroundColor: 'live_experience',
        image: { name: 'Respect', size: 26 },
        text: 'Respect for others',
        textColor: 'text_white',
        cardContent: (
            <>
                <CardTextBold>
                    Speak online as you would in real life.{' '}
                </CardTextBold>
                <CardText>
                    Never be mean, negative, or rude on 8billionideas
                </CardText>
            </>
        ),
    },
    {
        backgroundColor: 'secondary',
        image: { name: 'RightTime', size: 26 },
        indent: 135,
        text: 'Right place, right time',
        textColor: 'text_white',
        cardContent: (
            <>
                <CardTextBold>
                    Show up to Experiences before they go live,{' '}
                </CardTextBold>
                <CardText>so you can be ready for when they start!</CardText>
            </>
        ),
    },
    {
        backgroundColor: 'video',
        image: { name: 'BadLanguage', size: 26 },
        text: 'Never use bad language',
        cardContent: (
            <>
                <CardText>8billionideas is </CardText>
                <CardTextBold>
                    not the place for swearing or rude words.
                </CardTextBold>
            </>
        ),
    },
    {
        backgroundColor: 'article',
        image: { name: 'Player', size: 26 },
        text: 'Keep your video appropiate',
        indent: 95,
        cardContent: (
            <>
                <CardText>
                    Sometimes your video will be shared with facilitators during
                    mentoring.{' '}
                </CardText>
                <CardTextBold>
                    Be sure to keep your feed appropiate.
                </CardTextBold>
            </>
        ),
    },
    {
        backgroundColor: 'course',
        image: { name: 'Doubts', size: 26 },
        text: 'If in doubt, ask',
        textColor: 'text_white',
        indent: 180,
        cardContent: (
            <>
                <CardText>
                    Our team is here to answer you questions. If you need
                    anything at all -{' '}
                </CardText>
                <CardTextBold>feel free to ask them!</CardTextBold>
            </>
        ),
    },
];
