import { IconName } from '@@types/CommonTypes';
import { TileIcon, TileTitle, TileValue } from './InfoTile.styled';

export interface InfoTileProps {
    iconName: IconName;
    title: string;
    value?: string | number;
}

export const InfoTile = ({ iconName, title, value }: InfoTileProps) => {
    return (
        <>
            <TileValue>{value}</TileValue>
            <TileTitle>{title}</TileTitle>
            <TileIcon name={iconName} size={46} />
        </>
    );
};
