import React, { useState } from 'react';
import { PageTitle } from '@domains/Teacher/Account/AccountTeacherPage/AccountTeacherPage.styled';
import { useUserMeQuery } from '@state/auth/queries';
import ChangePasswordForm from '@domains/Teacher/Account/_forms/ChangePasswordForm/ChangePasswordForm';
import EditAccountInformationForm from '@domains/Teacher/Account/_forms/EditAccountInformationForm/EditAccountInformationForm';
import {
    InformationTeacherPageWrapper,
    PageHeader,
    RowText,
    RowTitle,
    RowWrapper,
    SectionButton,
    SectionDivider,
    SectionRow,
    SectionText,
    SectionWrapper,
} from './InformationTeacherPage.styled';

interface IProps {}

enum PAGE_VARIANT {
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    DEFAULT = 'DEFAULT',
    EDIT_ACCOUNT = 'EDIT_ACCOUNT',
}

const pageTitle: Record<PAGE_VARIANT, string> = {
    CHANGE_PASSWORD: 'Change password',
    DEFAULT: 'Account information',
    EDIT_ACCOUNT: 'Edit account information',
};

const InformationTeacherPage: React.FC<IProps> = () => {
    const { data } = useUserMeQuery();

    const [pageVariant, setPageVariant] = useState<PAGE_VARIANT>(
        PAGE_VARIANT.DEFAULT,
    );

    return (
        <>
            <InformationTeacherPageWrapper>
                <PageHeader>
                    <PageTitle>{pageTitle[pageVariant]}</PageTitle>
                </PageHeader>

                {pageVariant === PAGE_VARIANT.DEFAULT && (
                    <>
                        <SectionWrapper>
                            <SectionRow>
                                <RowWrapper>
                                    <RowTitle>
                                        Your personal information
                                    </RowTitle>
                                    {data && (
                                        <SectionButton
                                            buttonType={'secondary'}
                                            onPress={() =>
                                                setPageVariant(
                                                    PAGE_VARIANT.EDIT_ACCOUNT,
                                                )
                                            }
                                            size={'small'}
                                        >
                                            Edit
                                        </SectionButton>
                                    )}
                                </RowWrapper>
                                <RowText>
                                    {data?.me.name} {data?.me.surname}
                                </RowText>
                            </SectionRow>
                            <SectionRow>
                                <RowTitle>Your email address</RowTitle>
                                <RowText>{data?.me.email}</RowText>
                            </SectionRow>
                        </SectionWrapper>
                        <SectionDivider />
                        <SectionWrapper>
                            <RowWrapper>
                                <RowTitle>Your password</RowTitle>
                                <SectionButton
                                    buttonType={'secondary'}
                                    size={'small'}
                                    onPress={() =>
                                        setPageVariant(
                                            PAGE_VARIANT.CHANGE_PASSWORD,
                                        )
                                    }
                                >
                                    Change
                                </SectionButton>
                            </RowWrapper>
                            <SectionText>•••••••••••••••</SectionText>
                        </SectionWrapper>
                    </>
                )}

                {pageVariant === PAGE_VARIANT.EDIT_ACCOUNT && data && (
                    <EditAccountInformationForm
                        closeEdit={() => setPageVariant(PAGE_VARIANT.DEFAULT)}
                        defaultValues={data.me}
                    />
                )}

                {pageVariant === PAGE_VARIANT.CHANGE_PASSWORD && data && (
                    <ChangePasswordForm
                        onClose={() => setPageVariant(PAGE_VARIANT.DEFAULT)}
                    />
                )}
            </InformationTeacherPageWrapper>
        </>
    );
};

export default InformationTeacherPage;
