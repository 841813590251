import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { breakpoints } from '@shared/constants';

export const SignupFailedPaymentWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;

    @media (max-width: ${breakpoints.sm}px) {
        flex-direction: column;
    }
`;

export const PaymentIcon = styled(IconManager)`
    @media (max-width: ${breakpoints.md}px) {
        font-size: 300px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        font-size: 190px;
    }
`;

export const TextWrapper = styled.div`
    margin-left: 32px;

    @media (max-width: ${breakpoints.sm}px) {
        margin: 24px auto 0;
    }
`;

export const InformationText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 16px;
    text-transform: uppercase;

    @media (max-width: ${breakpoints.sm}px) {
        text-align: center;
    }
`;

export const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.xxl}px;
        text-align: center;
    }
`;

export const ButtonsWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 32px -8px 0 -8px;
`;

export const StyledButton = styled(Button)`
    flex: 1;
    height: 56px;
    margin-left: 8px;
    margin-right: 8px;
    width: 140px;

    &:hover {
        box-shadow: ${({ theme }) => theme.blue_shadow};
    }
`;
