import { gql, useMutation } from '@apollo/client';
import {
    StartLessonVideoMutation,
    StartLessonVideoMutationVariables,
} from '@@types/graphql/StartLessonVideoMutation';

const START_LESSON_VIDEO_MUTATION = gql`
    mutation StartLessonVideoMutation($lessonId: String!) {
        startConsumeCourseLesson(lessonId: $lessonId) {
            id
        }
    }
`;

export default () => {
    return useMutation<
        StartLessonVideoMutation,
        StartLessonVideoMutationVariables
    >(START_LESSON_VIDEO_MUTATION, {
        // TODO: handle error
        onError: () => {},
    });
};
