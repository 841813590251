import { palette, global, shadows } from './config';

const defaultTheme = {
    colors: {
        app_bg: palette.white,
        article: palette.cotton_candy,
        background_five_row: palette.off_green,
        background_primary: palette.narvik,
        background_quaternary: palette.cosmic_latte,
        background_secondary: palette.white,
        background_tertiary: palette.athens_gray,
        border: palette.pickled_bluewood,
        chip_color_active: palette.white,
        chip_color_active_hover: palette.emerald,
        course: palette.portage,
        disable: palette.vista_blue,
        details_teacher: palette.selago,
        error: palette.coral_red,
        even_table: palette.white_smoke,
        icon_button: palette.white,
        icon_disabled: palette.gray_chateau,
        icons: palette.moon_raker,
        icons_hover: palette.selago,
        live_experience: palette.wild_watermelon,
        live_experience_teacher: palette.selago,
        logout_border: palette.shiraz,
        notifications: palette.radical_red,
        primary: palette.keppel,
        secondary: palette.royal_blue,
        secondary_hover: palette.persian_blue,
        stroke: palette.mischka,
        success: palette.limeade,
        text: palette.pickled_bluewood,
        text_assistive: palette.gray_chateau,
        text_hover: palette.persian_blue,
        text_secondary: palette.shuttle_gray,
        text_white: palette.white,
        trial_tip_background: palette.portage,
        tooltop_background: palette.portage,
        video: palette.bright_sun,
        warning: palette.dulux_lemon,
    },
    ...global,
    ...shadows,
};

export default defaultTheme;
