import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import CompletedCourses from '@domains/Child/Courses/_components/CompletedCourses/CompletedCourses';
import Experiences from '@domains/Child/Courses/_components/Experiences/Experiences';
import Progress from '@domains/Child/Courses/_components/Progress/Progress';
import Saved from '@domains/Child/Courses/_components/Saved/Saved';
import { useSelector } from 'react-redux';
import { CoursesPageWrapper, PageTitle } from './CoursesPage.styled';

const CoursesPage: React.FC = () => {
    const { liveExperiencesVisibility } = useSelector(
        (state) => state.liveExperiencesVisibility,
    );
    return (
        <CoursesPageWrapper>
            <PageTitle>My content</PageTitle>
            <Progress smallPadding={!liveExperiencesVisibility} />
            {liveExperiencesVisibility && <Experiences />}
            <Saved showLiveExperience={liveExperiencesVisibility} />
            <CompletedCourses showLiveExperience={liveExperiencesVisibility} />
            <ChatWithMentor />
        </CoursesPageWrapper>
    );
};

export default CoursesPage;
