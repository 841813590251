import { IMosaicCard } from '@@types/CommonTypes';
import { useRecommendedContentsUser } from '@state/lists/queries';
import Loader from '@components/_universal/Loader/Loader';
import MosaicContent from '@components/MosaicContent/MosaicContent';
import parseContentToCards from '@utils/parseContentToCards';
import {
    ForYouIcon,
    LoaderWrapper,
    PushPinIcon,
    RecommendedWrapper,
    StyledTitle,
} from './Recommended.styled';

interface IProps {
    className?: string;
    showLiveExperience?: boolean;
}

const MoreElements: React.FC<IProps> = ({ className, showLiveExperience }) => {
    const { data, loading } = useRecommendedContentsUser();
    const parsedContentToCards = parseContentToCards(
        data?.getRecommendedContentsForUser || [],
    );

    return (
        <RecommendedWrapper
            className={className}
            smallMargin={!showLiveExperience}
        >
            <StyledTitle>
                Recommended
                <ForYouIcon name={'ForYou'} size={101} sizeY={55} />
                <PushPinIcon name={'PushPin'} size={45} />
            </StyledTitle>
            {loading ? (
                <LoaderWrapper>
                    <Loader size={'medium'} center />
                </LoaderWrapper>
            ) : (
                <MosaicContent
                    cards={
                        showLiveExperience
                            ? parsedContentToCards
                            : parsedContentToCards.filter(
                                  (item: IMosaicCard) =>
                                      item.type !== 'LIVE_EXPERIENCE',
                              )
                    }
                />
            )}
        </RecommendedWrapper>
    );
};

export default MoreElements;
