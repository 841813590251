import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { SCHOOL_GROUPS } from '../queries/useSchoolGroups';
import { ToastTypes } from '@@types/CommonTypes';
import {
    DeleteStudentsGroup,
    DeleteStudentsGroupVariables,
} from '@@types/graphql/DeleteStudentsGroup';

export const DELETE_STUDENTS_GROUP = gql`
    mutation DeleteStudentsGroup($input: DeleteStudentsGroupInput!) {
        deleteStudentsGroup(input: $input) {
            id
        }
    }
`;

export const useDeleteStudentsGroup = (callback: () => void) => {
    return useMutation<DeleteStudentsGroup, DeleteStudentsGroupVariables>(
        DELETE_STUDENTS_GROUP,
        {
            onCompleted: () => {
                callback();
                notify('Student group has been deleted.')[ToastTypes.DEFAULT]();
            },
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
            refetchQueries: [
                {
                    query: SCHOOL_GROUPS,
                    variables: { input: {} },
                },
            ],
        },
    );
};
