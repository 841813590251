import React from 'react';
import {
    PaymentSuccessIcon,
    RegisterSuccessfulDescription,
    RegisterSuccessfulTitle,
    SignUpRegisterOrnament,
    SignupSuccessfulPaymentWrapper,
    TextWrapper,
} from './SignupRegisterSuccessfulPage.styled';

interface IProps {}

const SignupRegisterSuccessfulPage: React.FC<IProps> = () => {
    return (
        <SignupSuccessfulPaymentWrapper>
            <PaymentSuccessIcon name={'SignUpRegisterSuccessful'} size={455} />
            <TextWrapper>
                <RegisterSuccessfulTitle>
                    Nice work! <br />
                    Now you need check your inbox
                    <SignUpRegisterOrnament
                        name={'SignUpRegisterOrnament'}
                        size={170}
                    />
                </RegisterSuccessfulTitle>
                <RegisterSuccessfulDescription>
                    An email with a link to verify your email address has been
                    sent to your inbox. Follow the instructions in the email.
                </RegisterSuccessfulDescription>
            </TextWrapper>
        </SignupSuccessfulPaymentWrapper>
    );
};

export default SignupRegisterSuccessfulPage;
