import styled from 'styled-components';
import {
    DefaultText,
    SubTitle,
    Title,
} from '@components/_universal/Typography.styled';
import UserStatusBadge from '@components/UserStatusBadge/UserStatusBadge';

export const DashboardParentPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NoFeedback = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    display: flex;
    flex-direction: column;
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-right: 56px;
    text-align: center;
    width: 120px;
`;

export const PageTitle = styled(SubTitle)`
    align-items: center;
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    padding-top: 34px;
`;

export const StyledBadgeBorder = styled(UserStatusBadge)`
    margin-left: 16px;
`;

export const SectionHeader = styled(Title)`
    align-items: center;
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 80px 0 48px;
`;

export const WorkCard = styled.div`
    background: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    border: 1px solid ${({ theme }) => theme.colors.background_tertiary};
    box-shadow: ${({ theme }) => theme.silver_shadow};
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    padding: 40px;
`;

export const WorkCardInner = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
`;

export const WorkAdditionalInfo = styled.div`
    align-items: flex-start;
    display: flex;
    width: 50%;
    justify-content: space-between;
`;

export const TextBoldQuestions = styled.p`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin: 28px 0;
`;

export const TextBold = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-bottom: 28px;
`;

export const TextSpan = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.regular};
`;
