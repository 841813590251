import styled, { css } from 'styled-components';
import { IconManager } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';
import { ProgressVariants } from '@@types/CommonTypes';

export const ProgressElementWrapper = styled.div`
    max-width: 350px;

    & + & {
        margin-top: 32px;
    }
`;

export const ProgressElementInnerWrapper = styled.div<{ isSmall: boolean }>`
    align-items: center;
    display: flex;
    margin-bottom: ${({ isSmall }) => (isSmall ? 20 : 0)}px;
`;

const ImageWrapperSmallCss = css`
    height: 48px;
    width: 48px;
`;

const ImageWrapperBigCss = css`
    height: 100px;
    width: 100px;
`;

const ImageWrapperTypes = {
    big: ImageWrapperBigCss,
    small: ImageWrapperSmallCss,
};

const PlayIconManagerSmallCss = css`
    bottom: -4px;
    right: -8px;
`;

const PlayIconManagerBigCss = css`
    bottom: -10px;
    right: -10px;
`;

const PlayIconManagerTypes = {
    big: PlayIconManagerBigCss,
    small: PlayIconManagerSmallCss,
};

export const ImageWrapper = styled.div<{
    image: string;
    progressVariant: ProgressVariants;
}>`
    background-image: url(${({ image }) => image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    flex-shrink: 0;
    position: relative;
    ${({ progressVariant }) => ImageWrapperTypes[progressVariant]}
`;

export const ImageOverlay = styled.div`
    background: ${({ theme }) => theme.opacityColors.violet};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 9;
`;

export const IconWrapper = styled(IconManager)`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
`;

export const PlayIconManager = styled(IconManager)<{
    progressVariant: ProgressVariants;
}>`
    position: absolute;
    z-index: 9;
    ${({ progressVariant }) => PlayIconManagerTypes[progressVariant]}
`;

export const TextWrapper = styled.div<{ progressVariant: ProgressVariants }>`
    width: 100%;
    margin-left: ${({ progressVariant }) =>
        progressVariant === 'small' ? 16 : 32}px;
`;

export const ProgressType = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 8px;
    text-transform: uppercase;
`;

export const ProgressTitle = styled(DefaultText)<{ isBig?: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: ${({ isBig }) => (isBig ? 12 : 0)}px;
`;
