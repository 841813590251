import { gql, useQuery } from '@apollo/client';
import { ChildTrialInfoQuery } from '@@types/graphql/ChildTrialInfoQuery';

export const CHILD_TRIAL_INFO_QUERY = gql`
    query ChildTrialInfoQuery {
        me {
            subscription {
                plan {
                    price
                }
                isTrialing
                trialEndAt
            }
        }
    }
`;

export default () =>
    useQuery<ChildTrialInfoQuery>(CHILD_TRIAL_INFO_QUERY, {
        fetchPolicy: 'network-only',
    });
