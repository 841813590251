import { createAction } from 'typesafe-actions';
import { ILeaveUploadStudentsModal } from './module';

export const changeLockLayout = createAction(
    '@layout/CHANGE_LOCK_LAYOUT',
)<boolean>();

export const setSearchModal = createAction(
    '@layout/SET_SEARCH_MODAL',
)<boolean>();

export const setEndedModal = createAction('@layout/SET_ENDED_MODAL')<boolean>();

export const setLoginLayoutAnimation = createAction(
    '@layout/SET_LOGIN_LAYOUT_ANIMATION',
)<boolean>();

export const setLeaveUploadStudentsModal = createAction(
    '@layout/SET_LEAVE_UPLOAD_STUDENTS_MODAL',
)<ILeaveUploadStudentsModal>();

export const setTooManyStudnetsUploadModal = createAction(
    '@layout/SET_TOO_MANY_STUDENTS_UPLOAD_MODAL',
)<boolean>();

export const setStudentsUploadModal = createAction(
    '@layout/SET_STUDENTS_UPLOAD_MODAL',
)<boolean>();

export const setTypeformModal = createAction(
    '@layout/SET_TYPEFORM_MODAL',
)<boolean>();
