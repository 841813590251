import React from 'react';
import {
    Content,
    FooterBar,
    HeaderBar,
    InnerWrapper,
    NavigationBar,
    ParentMainLayoutWrapper,
} from './ParentMainLayout.styled';
import ChatWithSupport from '@components/_universal/ChatWithSupport/ChatWithSupport';

interface IProps {}

export const ParentMainLayout: React.FC<IProps> = ({ children }) => {
    return (
        <ParentMainLayoutWrapper>
            <HeaderBar trialIcon />
            <NavigationBar borderLine />
            <Content>
                <InnerWrapper>{children}</InnerWrapper>
                <FooterBar isBackpack isLogo />
            </Content>
            <ChatWithSupport />
        </ParentMainLayoutWrapper>
    );
};
export default ParentMainLayout;
