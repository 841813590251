import styled, { css } from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const CreatePasswordTipWrapper = styled.div`
    position: absolute;
    right: -104px;
    top: 242px;
    width: 358px;
`;

export const SpeechBubble = styled.div(
    ({ theme: { colors, borderRadius } }) => css`
        background-color: ${colors.course};
        border-radius: ${borderRadius.bubble}px;
        padding: 48px 28px;
    `,
);

export const TipTitle = styled(Title)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.text_white};
        font-size: ${fontSizes.l}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.65;
        margin: 0;
    `,
);

export const TipText = styled(DefaultText)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.text_white};
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.5;
        margin: 0;
    `,
);

export const TipOrnament = styled(IconManager)`
    bottom: calc(100% - 25px);
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
`;
