import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Checkbox } from '@components/_form-elements';

export const AgreeRulesFormWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    @media (max-width: ${breakpoints.md}px) {
        margin: 0 auto;
        max-width: 220px;
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-align: left;
`;
