import { gql, useQuery } from '@apollo/client';
import {
    GetCourseQuery,
    GetCourseQueryVariables,
} from '@@types/graphql/GetCourseQuery';

export const GET_COURSE_QUERY = gql`
    query GetCourseQuery($id: ID!) {
        getCourse(id: $id) {
            _id
            thumbnail
            type
            name
            description
            featured
            status
            createdBy
            duration
            isRated
            isSavedForLater
            isConsumed
            consumption {
                consumed
                progress {
                    allLessonsCompleted
                }
            }
            activityMaterials {
                filesize
                filename
            }
            homework {
                id
                feedbackGivenAt
                feedbackBy {
                    name
                    surname
                }
                mentorFeedback
                createdAt
                files {
                    id
                    filename
                    type
                }
            }
            aboutVideo {
                _id
            }
            lessons {
                _id
                name
                description
                consumption {
                    consumed
                }
                video {
                    _id
                    duration
                    name
                    thumbnail
                }
            }
        }
    }
`;

export default (id: string) => {
    return useQuery<GetCourseQuery, GetCourseQueryVariables>(GET_COURSE_QUERY, {
        variables: { id: id },
    });
};
