import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import {
    BigTitle,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const AddNewChildModalWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.app_bg};
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    box-shadow: 5px 5px 16px 0px rgba(36, 48, 224, 0.15);
    margin: 0 auto;
    max-width: 750px;
    padding: 56px;
    position: relative;
    width: calc(100% - 56px);
    z-index: 9;

    @media (max-width: ${breakpoints.sm}px) {
        border-radius: ${({ theme }) => theme.borderRadius.button}px;
        padding: 40px 28px;
        width: calc(100% - 32px);
    }

    @media (max-width: ${breakpoints.xs}px) {
        padding: 40px 16px;
    }
`;

export const InformationText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 8px;
    text-transform: uppercase;

    @media (max-width: ${breakpoints.sm}px) {
        margin-bottom: 4px;
        text-align: center;
    }
`;

export const StyledBigTitle = styled(BigTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 32px;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
        margin-bottom: 16px;
        text-align: center;
    }
`;

export const CloseButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: ${({ theme }) => theme.cursors.pointer};
    height: 33px;
    padding: 0;
    position: absolute;
    right: 32px;
    top: 32px;
    width: 33px;

    @media (max-width: ${breakpoints.sm}px) {
        right: 22px;
        top: 22px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        right: 18px;
        top: 12px;
    }
`;
