import React, { useEffect, useState } from 'react';
import { BillingCycle } from '@@types/graphql/GraphqlGlobalTypes';
import { SubscriptionChildsListQuery_me_childs } from '@@types/graphql/SubscriptionChildsListQuery';
import { useCreateCheckoutSessionMutation } from '@state/auth/mutations';
import { useStripePublishableKey } from '@state/auth/queries';
import { useSubscriptionChildsListQuery } from '@state/childManagement/queries';
import DesktopChooseYourPlanPage from './_desktop/DesktopChooseYourPlanPage';
import MobileChooseYourPlanPage from './_mobile/MobileChooseYourPlanPage';
import useMobile from '@utils/hooks/useMobile';
import AddNewChildModal from '../_modals/AddNewChildModal/AddNewChildModal';

interface IProps {}

export interface IChildsList {
    checked: boolean;
    child: SubscriptionChildsListQuery_me_childs;
    id: number;
}

const ChooseYourPlanPage: React.FC<IProps> = () => {
    const [childs, setChilds] = useState<IChildsList[]>([]);
    const [addChildModal, setAddChildModal] = useState(false);

    const { isMobile } = useMobile();
    const { data } = useStripePublishableKey();
    const [
        createCheckoutSession,
        { loading },
    ] = useCreateCheckoutSessionMutation(
        data?.getStripeSetup.publishableKey ?? '',
    );
    const { data: childrenData } = useSubscriptionChildsListQuery();
    const childrenList = childrenData?.me.childs;
    const checkedChildren = childs.filter((child) => child.checked).length;

    const handleChange = (id: number, value: boolean) => {
        const newChilds = childs.map((child) =>
            child.id === id
                ? {
                      ...child,
                      checked: value,
                  }
                : child,
        );

        setChilds(newChilds);
    };

    const handleSubscribe = (billingCycle: BillingCycle) => {
        if (loading) return;
        const childrenIds = childs
            .filter((child) => child.checked)
            .map((child) => child.id);

        createCheckoutSession({
            variables: {
                input: {
                    billingCycle,
                    childIdsToRenew: childrenIds,
                },
            },
        });
    };

    useEffect(() => {
        if (!childrenList) return;
        const uncheckedUsers = childs
            .filter((child) => !child.checked)
            .map((child) => child.id);

        setChilds(
            childrenList.map((child) => ({
                checked: !uncheckedUsers.includes(child.id),
                child,
                id: child.id,
            })),
        );
    }, [childrenList]);

    return (
        <>
            {addChildModal && (
                <AddNewChildModal setIsModalOpen={setAddChildModal} />
            )}

            {isMobile ? (
                <MobileChooseYourPlanPage
                    checkedChildren={checkedChildren}
                    childrenList={childs}
                    handleChange={handleChange}
                    handleSubscribe={handleSubscribe}
                    setAddChildModal={setAddChildModal}
                />
            ) : (
                <DesktopChooseYourPlanPage
                    checkedChildren={checkedChildren}
                    childrenList={childs}
                    handleChange={handleChange}
                    handleSubscribe={handleSubscribe}
                    setAddChildModal={setAddChildModal}
                />
            )}
        </>
    );
};

export default ChooseYourPlanPage;
