import { gql, useQuery } from '@apollo/client';
import {
    OngoingContentQuery,
    OngoingContentQueryVariables,
} from '@@types/graphql/OngoingContentQuery';

const ONGOING_CONTENT_QUERY = gql`
    query OngoingContentQuery($pagination: OngoingContentPagination) {
        getOngoingContent(pagination: $pagination) {
            id
            name
            type
            consumed
            progress {
                lastCheckpoint
                completedLessons
                homeworkSent
            }
            content {
                ... on Course {
                    _id
                    lessons {
                        _id
                    }
                }
                ... on StandaloneVideo {
                    _id
                    video {
                        duration
                    }
                }
                ... on LiveExperience {
                    _id
                }
            }
            thumbnail
        }
    }
`;

export default (limit?: number) => {
    return useQuery<OngoingContentQuery, OngoingContentQueryVariables>(
        ONGOING_CONTENT_QUERY,
        {
            variables: {
                pagination: {
                    limit,
                },
            },
        },
    );
};
