import React, { useEffect } from 'react';
import { Input } from '@components/_form-elements';
import { ISignupForm } from '@domains/Auth/SignupCreatePage/SignupCreatePage';
import { setCorrectAccountData } from '@state/_redux/signup/actions';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useMatchMedia } from '@utils/hooks';
import { useValidateParentSignup } from '@state/auth/queries';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import Select from '@components/_form-elements/Select/Select';
import useListOfCountriesQuery from '@state/lists/queries/useListOfCountriesQuery';
import {
    CreateAccountFormWrapper,
    StyledButton,
    StyledFormRow,
} from './CreateAccountForm.styled';
import {
    ButtonRow,
    InputWrapper,
} from '@domains/Auth/_forms/GlobalForm.styled';

interface IProps {}

const CreateAccountForm: React.FC<IProps> = () => {
    const { match } = useMatchMedia('md');
    const dispatch = useDispatch();
    const { data: countriesData } = useListOfCountriesQuery();
    const countries = countriesData?.getListOfCountries;
    const [
        validateParentSignup,
        { error, loading },
    ] = useValidateParentSignup();

    const { control, errors, handleSubmit, register } = useFormContext();

    const zipCodeError = error && error.message.search('Postal code') > -1;
    const emailError = error?.message === 'Email is already taken';

    const onSubmit = (data: ISignupForm) => {
        if (loading) return;
        validateParentSignup({
            variables: {
                input: {
                    countryCode: data.country?.value as string,
                    email: data.email,
                    zipCode: data.zipCode,
                },
            },
        });
    };

    useEffect(() => {
        dispatch(setCorrectAccountData(false));
    }, []);

    return (
        <CreateAccountFormWrapper noValidate onSubmit={handleSubmit(onSubmit)}>
            <StyledFormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.name}
                        label={'Parent name'}
                        marginBottom={match ? 8 : 24}
                        name={'name'}
                        placeholder={'Enter your name'}
                        register={register}
                        staticPosition={match}
                    />
                </InputWrapper>
                <InputWrapper>
                    <InputForm
                        errors={errors.surname}
                        label={'Parent surname'}
                        marginBottom={match ? 8 : 24}
                        name={'surname'}
                        placeholder={'Enter your surname'}
                        register={register}
                        staticPosition={match}
                    />
                </InputWrapper>
            </StyledFormRow>
            <StyledFormRow>
                <InputWrapper>
                    <InputForm
                        errors={
                            errors.email ||
                            (emailError
                                ? {
                                      message:
                                          'Provided email address is already taken',
                                  }
                                : undefined)
                        }
                        label={'Parent email address'}
                        marginBottom={match ? 8 : 24}
                        name={'email'}
                        placeholder={'e.g. joedoe@mail.com'}
                        register={register}
                        type={'email'}
                        staticPosition={match}
                    />
                </InputWrapper>
            </StyledFormRow>
            <StyledFormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.address}
                        label={'Parent address'}
                        marginBottom={match ? 8 : 24}
                        name={'address'}
                        placeholder={'Enter your address'}
                        register={register}
                        staticPosition={match}
                    />
                </InputWrapper>
                <InputWrapper>
                    <SelectForm
                        control={control}
                        errors={errors.country}
                        isSearchable
                        label={'Country'}
                        marginBottom={match ? 8 : 24}
                        name={'country'}
                        options={countries?.map((item) => ({
                            label: item.countryName,
                            value: item.countryCode,
                        }))}
                        placeholder={'Select your country'}
                        register={register}
                        staticPosition={match}
                    />
                </InputWrapper>
            </StyledFormRow>
            <StyledFormRow>
                <InputWrapper>
                    <InputForm
                        errors={errors.city}
                        label={'City'}
                        marginBottom={match ? 8 : 24}
                        name={'city'}
                        placeholder={'Enter your city'}
                        register={register}
                        staticPosition={match}
                    />
                </InputWrapper>
                <InputWrapper>
                    <InputForm
                        errors={
                            errors.zipCode ||
                            (zipCodeError
                                ? {
                                      message: 'Invalid Postcode',
                                  }
                                : undefined)
                        }
                        label={'Postcode'}
                        marginBottom={match ? 8 : 24}
                        name={'zipCode'}
                        placeholder={'Enter your postcode'}
                        register={register}
                        staticPosition={match}
                    />
                </InputWrapper>
            </StyledFormRow>
            <ButtonRow>
                <StyledButton disabled={loading} onPress={() => {}}>
                    Continue
                </StyledButton>
            </ButtonRow>
        </CreateAccountFormWrapper>
    );
};

const InputForm = FieldMessage(Input);
const SelectForm = FieldMessage(Select);

export default CreateAccountForm;
