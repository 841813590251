import React from 'react';
import { ColorType } from '@@types/CommonTypes';
import { contentTypes } from '@shared/keyToCopy';
import { FEEDBACK_VIDEO_MIME_TYPES } from '@shared/constants';
import { StudentActivityQuery_getStudentActivity_result } from '@@types/graphql/StudentActivityQuery';
import dayjs from 'dayjs';
import HomeworkButton from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/HomeworkButton/HomeworkButton';
import {
    ActivityCardWrapper,
    CardHeader,
    CardHeaderTexts,
    ContentName,
    DateText,
    FeedbackFile,
    FeedbackInnerWrapper,
    FeedbackText,
    FeedbackWrapper,
    MentorData,
    MentorName,
    MentorSecondaryText,
    SeeMoreButton,
    StyledBadge,
    StyledHomeworkButton,
    VideoFeedbackText,
} from './ActivityCard.styled';
import {
    CONTENT_TYPE,
    HOMEWORK_FILE_TYPE,
} from '@@types/graphql/GraphqlGlobalTypes';

export interface IProps {
    activity: StudentActivityQuery_getStudentActivity_result;
}

const getContentColor = (contentType: CONTENT_TYPE): ColorType => {
    switch (contentType) {
        case CONTENT_TYPE.COURSE:
            return 'course';
        case CONTENT_TYPE.LIVE_EXPERIENCE:
            return 'live_experience';
        case CONTENT_TYPE.STANDALONE_VIDEO:
            return 'video';
    }
};

const getTextColor = (contentType: CONTENT_TYPE): ColorType => {
    switch (contentType) {
        case CONTENT_TYPE.COURSE:
        case CONTENT_TYPE.LIVE_EXPERIENCE:
            return 'text_white';
        case CONTENT_TYPE.STANDALONE_VIDEO:
            return 'text';
    }
};

const ActivityCard: React.FC<IProps> = ({ activity }) => {
    const activityType = activity.content.type as CONTENT_TYPE;
    const homework = activity.homework;

    const homeworkFiles = homework?.files;
    const homeworkFile = homeworkFiles?.find(
        (file) => file.type === HOMEWORK_FILE_TYPE.USER_HOMEWORK,
    );
    const feedbackFile = homeworkFiles?.find(
        (file) => file.type === HOMEWORK_FILE_TYPE.FEEDBACK,
    );

    return (
        <ActivityCardWrapper>
            <StyledBadge
                backgroundColor={getContentColor(activityType)}
                textColor={getTextColor(activityType)}
            >
                {contentTypes[activityType]}
            </StyledBadge>
            <CardHeader>
                <CardHeaderTexts>
                    <ContentName>{activity.content.name}</ContentName>
                    {/* TODO: uncomment when see more button will be needed */}
                    {/* <SeeMoreButton
                        onPress={() => setShowMore((prevState) => !prevState)}
                    >
                        See more{' '}
                        <IconManager size={24} name="ArrowTextButtonRight" />{' '}
                    </SeeMoreButton> */}
                </CardHeaderTexts>
                {homework && homeworkFile ? (
                    <StyledHomeworkButton
                        fileSubtext={dayjs(homework.createdAt).format(
                            'DD.MM.YYYY; HH:mm',
                        )}
                        file={homeworkFile}
                        homeworkId={homework.id}
                    />
                ) : (
                    <DateText>
                        {dayjs(activity.createdAt).format('DD.MM.YYYY; HH:mm')}
                    </DateText>
                )}
            </CardHeader>
            {homework?.feedbackGivenAt && homework.feedbackBy && (
                <FeedbackWrapper>
                    <MentorData>
                        <MentorSecondaryText>Mentor</MentorSecondaryText>
                        <MentorName>
                            {homework.feedbackBy.name}{' '}
                            {homework.feedbackBy.surname}
                        </MentorName>
                        <MentorSecondaryText>
                            {dayjs(homework.feedbackGivenAt).format(
                                'DD.MM.YYYY; HH:mm',
                            )}
                        </MentorSecondaryText>
                    </MentorData>
                    <FeedbackInnerWrapper>
                        {homework.mentorFeedback && (
                            <FeedbackText>
                                {homework.mentorFeedback}
                            </FeedbackText>
                        )}
                        {feedbackFile && (
                            <FeedbackFile>
                                <HomeworkButton
                                    file={feedbackFile}
                                    homeworkId={homework.id}
                                >
                                    {FEEDBACK_VIDEO_MIME_TYPES.includes(
                                        feedbackFile.mimetype,
                                    ) && (
                                        <VideoFeedbackText
                                            fill={['secondary']}
                                            name="VideoFeedbackText"
                                            size={270}
                                            sizeY={38}
                                        />
                                    )}
                                </HomeworkButton>
                            </FeedbackFile>
                        )}
                    </FeedbackInnerWrapper>
                </FeedbackWrapper>
            )}
        </ActivityCardWrapper>
    );
};

export default ActivityCard;
