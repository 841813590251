import { gql, useMutation } from '@apollo/client';
import {
    RemoveSavedForLaterMutation,
    RemoveSavedForLaterMutationVariables,
} from '@@types/graphql/RemoveSavedForLaterMutation';
import client from '@boot/apolloClient';
import { FragmentRemoveSaveForLaterMutation } from '@@types/graphql/FragmentRemoveSaveForLaterMutation';

const REMOVE_SAVED_FOR_LATER_MUTATION = gql`
    mutation RemoveSavedForLaterMutation($contentId: String!) {
        removeSavedForLater(contentId: $contentId) {
            __typename
        }
    }
`;

type ContentType = 'Course' | 'LiveExperience' | 'StandaloneVideo';

const getFragmet = (contentType: ContentType) => {
    if (contentType === 'Course')
        return gql`
            fragment FragmentRemoveSaveForLaterMutation on Course {
                isSavedForLater
            }
        `;
    if (contentType === 'LiveExperience')
        return gql`
            fragment FragmentRemoveSaveForLaterMutation on LiveExperience {
                isSavedForLater
            }
        `;
    return gql`
        fragment FragmentRemoveSaveForLaterMutation on StandaloneVideo {
            isSavedForLater
        }
    `;
};

export default (contentId: string, contentType: ContentType) => {
    return useMutation<
        RemoveSavedForLaterMutation,
        RemoveSavedForLaterMutationVariables
    >(REMOVE_SAVED_FOR_LATER_MUTATION, {
        onCompleted: () => {
            client.writeFragment<FragmentRemoveSaveForLaterMutation>({
                id: `${contentType}:${contentId}`,
                fragment: getFragmet(contentType),
                data: {
                    isSavedForLater: false,
                },
            });
        },
        onError: () => {},
    });
};
