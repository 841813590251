import { gql, useQuery } from '@apollo/client';
import { SchoolClassesQuery } from '@@types/graphql/SchoolClassesQuery';

export const GET_SCHOOL_CLASSES_QUERY = gql`
    query SchoolClassesQuery {
        getSchoolClasses {
            id
            year
            className
        }
    }
`;

export default () =>
    useQuery<SchoolClassesQuery>(GET_SCHOOL_CLASSES_QUERY, {
        onCompleted: () => {},
    });
