import React, { useEffect } from 'react';
import { changeLockLayout, setSearchModal } from '@state/_redux/layout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Notifications from '@components/Notifications/Notifications';
import paths from '@shared/paths';
import SearchModal from '@components/_modals/SearchModal/SearchModal';
import useHeader from '@components/_global/Header/useHeader';
import {
    AccountIcon,
    CloseIcon,
    HeaderTrialLeftInformation,
    HeaderWrapper,
    NotificationIcon,
    NotificationWrapper,
    SearchIcon,
} from '@components/_global/Header/Header.styled';

interface IProps {
    className?: string;
    isTransparent?: boolean;
    trialIcon?: boolean;
}

const Header: React.FC<IProps> = ({ className, isTransparent, trialIcon }) => {
    const {
        accountActive,
        isUnread,
        notificationsActive,
        onClose,
        refIcon,
        refNotifications,
        setNotificationsActive,
    } = useHeader('/settings/*');

    const { lockLayout, searchModal } = useSelector((state) => state.layout);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        searchModal
            ? dispatch(changeLockLayout(true))
            : dispatch(changeLockLayout(false));
    }, [searchModal]);

    return (
        <>
            {searchModal && <SearchModal />}
            <HeaderWrapper
                isTransparent={isTransparent || searchModal}
                hideElement={!searchModal && !!lockLayout}
                {...{ className }}
            >
                {searchModal ? (
                    <CloseIcon
                        cursor="pointer"
                        hoverFill={['icons_hover']}
                        name="CircleClose"
                        onClick={() => dispatch(setSearchModal(false))}
                        size={60}
                        sizeY={60}
                    />
                ) : (
                    <SearchIcon
                        activeFill={['text_white', 'primary']}
                        cursor="pointer"
                        hoverFill={['icons_hover']}
                        isActive={searchModal}
                        name="CircleSearch"
                        onClick={() => {
                            dispatch(setSearchModal(true));
                            setNotificationsActive(false);
                        }}
                        size={40}
                    />
                )}

                <AccountIcon
                    activeFill={['text_white', 'border', 'primary']}
                    activeStroke={3}
                    cursor="pointer"
                    hoverFill={['icons_hover']}
                    isActive={accountActive}
                    isHidden={searchModal}
                    name="CircleProfile"
                    onClick={() => history.push(paths.account)}
                    size={40}
                />

                <NotificationWrapper
                    isHidden={searchModal}
                    ref={refIcon}
                    unreadNotifications={isUnread}
                >
                    <NotificationIcon
                        activeFill={['text_white', 'border']}
                        cursor="pointer"
                        hoverFill={['icons_hover']}
                        isActive={notificationsActive}
                        name="CircleBell"
                        onClick={() =>
                            notificationsActive
                                ? onClose()
                                : setNotificationsActive(true)
                        }
                        size={40}
                    />
                </NotificationWrapper>
                {notificationsActive && (
                    <Notifications
                        linkToSettings={paths.account_notification_settings}
                        ref={refNotifications}
                    />
                )}
                {trialIcon && <HeaderTrialLeftInformation variant="default" />}
            </HeaderWrapper>
        </>
    );
};

export default Header;
