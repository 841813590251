import paths, { externalLinks } from '@shared/paths';
import { useSelector } from 'react-redux';
import StudentsListUploader from '@components/StudentsListUploader/StudentsListUploader';
import {
    ButtonWrapper,
    DashboardSubtitle,
    DashboardTeacherAdditionalWrapper,
    DashboardTeacherInnerWrapper,
    DashboardTeacherOrnament,
    DashboardTeacherWrapper,
    DashboardTitle,
    EnumerationsDescription,
    EnumerationsItemWrapper,
    EnumerationsListWrapper,
    EnumerationsTitle,
    StyledButton,
    StyledLinkText,
} from './DashboardTeacherUploadStudents.styled';

interface IProps {
    isExistStudent: boolean;
    subtitle: string;
    title: string;
}

const DashboardTeacherUploadStudents: React.FC<IProps> = ({
    isExistStudent,
    subtitle,
    title,
}) => {
    const { liveExperiencesVisibility } = useSelector(
        (state) => state.liveExperiencesVisibility,
    );

    return (
        <DashboardTeacherWrapper>
            <DashboardTitle>{title}</DashboardTitle>
            <DashboardSubtitle>{subtitle}</DashboardSubtitle>
            <DashboardTeacherInnerWrapper>
                <EnumerationsListWrapper>
                    {!isExistStudent && (
                        <EnumerationsItemWrapper>
                            <EnumerationsTitle>
                                Upload list of students
                            </EnumerationsTitle>
                            <EnumerationsDescription>
                                Upload a csv file with the division of students
                                into classes and year groups according to the
                                example shown in the{' '}
                                <StyledLinkText
                                    href={externalLinks.upload_csv_video}
                                    target={'_blank'}
                                >
                                    instructional video
                                </StyledLinkText>
                                , and we will send them invitations to the
                                platform.
                            </EnumerationsDescription>
                            <ButtonWrapper>
                                <StyledButton
                                    buttonType={'secondary'}
                                    onPress={() =>
                                        window.open(
                                            '/students-example.csv',
                                            '_blank',
                                            'noopener,noreferrer',
                                        )
                                    }
                                >
                                    Download template
                                </StyledButton>
                                <StudentsListUploader />
                            </ButtonWrapper>
                        </EnumerationsItemWrapper>
                    )}
                    <EnumerationsItemWrapper>
                        <EnumerationsTitle>
                            {liveExperiencesVisibility
                                ? 'Encourage students to sign up to their first experience'
                                : 'Encourage students to take their first course'}
                        </EnumerationsTitle>
                        <EnumerationsDescription>
                            {liveExperiencesVisibility
                                ? 'Our experiences happen around the clock. Take a look through our '
                                : 'Our platform contains interesting courses on various topics. Take a look through our '}
                            {liveExperiencesVisibility ? (
                                <StyledLinkText
                                    href={externalLinks.experiences}
                                    target={'_blank'}
                                >
                                    experiences
                                </StyledLinkText>
                            ) : (
                                <StyledLinkText href={paths.library_teacher}>
                                    courses
                                </StyledLinkText>
                            )}{' '}
                            and suggest to the students that they{' '}
                            {liveExperiencesVisibility
                                ? 'sign up for one of these.'
                                : 'enroll for one of these.'}
                        </EnumerationsDescription>
                    </EnumerationsItemWrapper>
                    <EnumerationsItemWrapper>
                        <EnumerationsTitle>
                            Take a look at their work and feedback from our
                            mentors!
                        </EnumerationsTitle>
                        <EnumerationsDescription>
                            {liveExperiencesVisibility
                                ? ' After an experience or course, '
                                : 'After a course, '}
                            students will be encouraged to submit a project
                            through their dashboard. Once uploaded, our mentor
                            team will provide video feedback. Both the project
                            and feedback will appear in an activity feed.
                        </EnumerationsDescription>
                    </EnumerationsItemWrapper>
                    <EnumerationsItemWrapper>
                        <EnumerationsTitle>
                            Do you have any questions?
                        </EnumerationsTitle>
                        <EnumerationsDescription>
                            Reach out to us via the button in the corner of your
                            screen!
                        </EnumerationsDescription>
                    </EnumerationsItemWrapper>
                </EnumerationsListWrapper>

                <DashboardTeacherAdditionalWrapper>
                    <DashboardTeacherOrnament
                        name={
                            liveExperiencesVisibility
                                ? 'DashboardTeacherOrnament'
                                : 'DashboardTeacherOrnamentWithoutLE'
                        }
                        size={299}
                        sizeY={325}
                    />
                </DashboardTeacherAdditionalWrapper>
            </DashboardTeacherInnerWrapper>
        </DashboardTeacherWrapper>
    );
};

export default DashboardTeacherUploadStudents;
