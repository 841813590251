import { gql, useQuery } from '@apollo/client';
import {
    GetLiveExperienceQuery,
    GetLiveExperienceQueryVariables,
} from '@@types/graphql/GetLiveExperienceQuery';

export const GET_LIVE_EXPERIENCE_QUERY = gql`
    query GetLiveExperienceQuery($id: ID!) {
        getLiveExperience(id: $id) {
            _id
            thumbnail
            type
            name
            description
            featured
            status
            topicTags {
                _id
            }
            skillTags {
                _id
            }
            duration
            maxNoParticipants
            startDate
            endDate
            link
            facilitatedBy {
                id
                name
                surname
            }
            createdBy
            createdAt
            activityMaterials {
                filename
                filesize
            }
            isUserSignuped
            noOfSignups
            isRated
            isConsumed
            isSavedForLater
            homework {
                id
                feedbackGivenAt
                feedbackBy {
                    name
                    surname
                }
                mentorFeedback
                createdAt
                files {
                    id
                    filename
                    type
                }
            }
        }
    }
`;

export default (id: string) =>
    useQuery<GetLiveExperienceQuery, GetLiveExperienceQueryVariables>(
        GET_LIVE_EXPERIENCE_QUERY,
        {
            variables: {
                id: id,
            },
            fetchPolicy: 'cache-first',
        },
    );
