import React, { useRef } from 'react';
import { Description, StyledAgreementText } from './NewChildModal.styled';
import { useOnClickOutside } from '@utils/hooks';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import Modal from '@components/_universal/Modal/Modal';
import NewChildForm from '@domains/Parent/Account/_forms/NewChildForm/NewChildForm';
import {
    ModalContent,
    ModalTitle,
} from '@components/_modals/GlobalModals.styled';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const NewChildModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsModalOpen(false));

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={() => setIsModalOpen(false)} />
                <ModalTitle>New child information</ModalTitle>
                <Description>
                    The registration requires an additional payment.
                </Description>
                <Description>
                    Charges will apply depending on your plan and pricing tier.
                </Description>
                <NewChildForm callback={() => setIsModalOpen(false)} />
                <StyledAgreementText />
            </ModalContent>
        </Modal>
    );
};

export default NewChildModal;
