import React from 'react';
import { SlotWrapper, SubSlot } from './RepetableDisplayCards.styled';
import getCardComponent from '@components/RepetableDisplayCards/getCardComponent';
import { IMosaicCard } from '@@types/CommonTypes';

interface IProps {
    elements: IMosaicCard[][];
}

const RepetableDisplayCards: React.FC<IProps> = ({ elements }) => {
    return (
        <>
            {elements.map((slot, index) => {
                if (slot.length === 2) {
                    return (
                        <SlotWrapper key={index}>
                            <SubSlot>
                                {slot.map((cardData) => {
                                    return (
                                        <React.Fragment key={cardData.key}>
                                            {
                                                getCardComponent(cardData)[
                                                    cardData.type
                                                ]
                                            }
                                        </React.Fragment>
                                    );
                                })}
                            </SubSlot>
                        </SlotWrapper>
                    );
                } else {
                    const [cardData] = slot;
                    return (
                        <SlotWrapper key={cardData.key}>
                            {getCardComponent(cardData)[cardData.type]}
                        </SlotWrapper>
                    );
                }
            })}
        </>
    );
};
export default RepetableDisplayCards;
