import React from 'react';
import prettyBytes from 'pretty-bytes';
import { InfoTile } from '@components/_universal/InfoTile/InfoTile';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import {
    TileDate,
    TileFile,
    InfoTilesContent,
    InfoTileSection,
    InfoTileSectionTitle,
    InfoTilesWrapper,
} from './InfoTiles.styled';

interface InfoTilesProps {
    activityMaterials?: {
        link: string;
        name: string;
        size: number;
    };
    courseDuration?: number;
    lessonsCount?: number;
    userType?: USER_TYPE;
}

export const convertTime = (duration: number) => {
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration % 3600) / 60);
    if (!minutes) return hours;
    return hours + ':' + ('0' + minutes).slice(-2);
};

const InfoTiles = ({
    activityMaterials,
    courseDuration = 0,
    lessonsCount = 0,
    userType = USER_TYPE.CHILD,
}: InfoTilesProps) => {
    return (
        <InfoTilesWrapper>
            <InfoTileSection>
                {userType !== USER_TYPE.TEACHER && (
                    <InfoTileSectionTitle>
                        Course lessons {'&'} duration
                    </InfoTileSectionTitle>
                )}
                <InfoTilesContent>
                    <TileDate userType={userType}>
                        <InfoTile
                            iconName="Lessons"
                            title={lessonsCount < 2 ? 'Lesson' : 'Lessons'}
                            value={lessonsCount}
                        />
                    </TileDate>
                    <TileDate userType={userType}>
                        <InfoTile
                            iconName="TimeDuration"
                            title="Total time"
                            value={`${convertTime(courseDuration)} h`}
                        />
                    </TileDate>
                </InfoTilesContent>
            </InfoTileSection>
            {activityMaterials && activityMaterials.name && (
                <InfoTileSection>
                    <InfoTileSectionTitle>
                        Activity materials
                    </InfoTileSectionTitle>
                    <InfoTilesContent>
                        <TileFile href={activityMaterials.link} target="_blank">
                            <InfoTile
                                iconName="Download"
                                title={prettyBytes(activityMaterials.size)}
                                value={activityMaterials.name}
                            />
                        </TileFile>
                    </InfoTilesContent>
                </InfoTileSection>
            )}
        </InfoTilesWrapper>
    );
};

export default InfoTiles;
