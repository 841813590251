import styled from 'styled-components';
import { Button } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';
import Textarea from '@components/_form-elements/Textarea/Textarea';

export const RateCourseFormWrapper = styled.div`
    margin: 0 auto;
    max-width: 578px;
`;

export const BigHeader = styled(DefaultText)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 48px 0 16px;
    text-align: center;
`;

export const SubmitButton = styled(Button)`
    margin: 0 auto;
    width: 264px;
`;

export const RateTextArea = styled(Textarea)`
    height: 128px;
    margin: 0 auto 40px;
    max-width: 578px;
`;
