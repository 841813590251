import styled from 'styled-components';
import { IconManager } from '@components/_universal';

export const DesktopAuthorizationTokenPageWrapper = styled.div`
    align-items: center;
    align-self: center;
    background: rgba(81, 91, 230, 0.95);
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 800px;
    padding: 54px;
    width: 100%;
`;
export const AuthorizationTokenContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    justify-content: center;
`;

export const LoadingTitle = styled.div`
    color: ${({ theme }) => theme.colors.text_white};
    display: flex;
    font-family: ${({ theme }) => theme.fontFamily.sketched};
    font-size: ${({ theme }) => theme.fontSizes.max}px;
`;

export const LogoIcon = styled(IconManager)``;

export const LoadingScooterIcon = styled(IconManager)`
    margin: 72px 0;
`;
