import { gql, useQuery } from '@apollo/client';
import { ParentTrialInfoQuery } from '@@types/graphql/ParentTrialInfoQuery';

export const PARENT_TRIAL_INFO_QUERY = gql`
    query ParentTrialInfoQuery {
        getUserSubscriptionInfo {
            plan {
                price
            }
            isTrialing
            trialEndAt
        }
    }
`;

export default () => useQuery<ParentTrialInfoQuery>(PARENT_TRIAL_INFO_QUERY);
