export default {
    // IMPORTANT FOR THE FUTURE - user and parent paths cannot start with /board

    // *** AUTH FLOW *** //
    authorization_token: '/authorization',
    forgot_password: '/forgot-password',
    link_login: '/link-login',
    link_sent: '/link-sent',
    login: '/login',
    reset_link_sent: '/reset-link-sent',
    reset_password: '/reset-password',
    signup: '/signup',
    signup_pricing: '/signup/pricing',
    signup_create: '/signup/create',
    signup_create_account: '/signup/create/account',
    signup_create_password: '/signup/create/password',
    signup_register: '/signup/register',
    signup_register_success: '/signup/register-success',
    signup_success: '/signup/success',
    signup_failed: '/signup/failed',
    signup_verified: '/signup/verified',
    signup_payment_processing: '/signup/payment-processing',

    // onboarding
    onboarding: '/onboarding',
    choose_avatar: '/onboarding/choose-avatar',
    set_password: '/onboarding/set-password',
    agree_rules: '/onboarding/agree-rules',

    // *** SUBSCRIPTION *** //
    subscription: '/subscription',
    choose_your_plan: '/choose-your-plan',

    // *** CHILD FLOW *** //
    // library
    library: '/library',

    // courses
    courses: '/courses',
    save_for_later: '/courses/save-for-later',

    // course
    course: (courseId: string) => `/courses/${courseId}`,
    course_lesson: (courseId: string, courseLessonId: string) =>
        `/courses/${courseId}/lesson/${courseLessonId}`,

    // settings
    account: '/settings',
    account_profile: '/settings/profile',
    account_notification_settings: '/settings/notification-settings',
    account_help_and_faq: '/settings/help-and-faq',
    account_behaviour_agreement: '/settings/behaviour-agreement',

    // childManagement
    dashboard: '/',

    // video view
    video_view: (videoId: string) => `/video/${videoId}`,

    // live experience
    live_experience: (liveExperienceId: string) =>
        `/live-experience/${liveExperienceId}`,

    // *** PARENT FLOW *** //
    // childManagement parent
    dashboard_parent: (childId?: string) =>
        childId ? `/parent/${childId}` : '/parent',
    course_parent: (childId: string, courseId: string) =>
        `/parent/${childId}/course/${courseId}`,
    live_experience_parent: (childId: string, liveExperienceId: string) =>
        `/parent/${childId}/live-experience/${liveExperienceId}`,

    // account
    account_parent: '/parent/account',
    account_information_parent: '/parent/account/information',
    account_notification_settings_parent:
        '/parent/account/notification-settings',
    account_subscription_parent: '/parent/account/subscription',
    account_help_and_faq_parent: '/parent/account/help-and-faq',
    account_legal_parent: '/parent/account/legal',

    // *** TEACHER FLOW *** //
    // dashboard
    dashboard_teacher: '/teacher',

    // students
    students_teacher: '/teacher/students',
    student_details_teacher: (studentId: string) =>
        `/teacher/students/${studentId}`,

    // groups
    students_group_teacher: (groupId: string) => `/teacher/groups/${groupId}`,
    teacher_groups: '/teacher/groups',

    // library
    library_teacher: '/teacher/library',
    course_teacher: (courseId: string) =>
        `/teacher/library/courses/${courseId}`,
    live_experience_teacher: (liveExperienceId: string) =>
        `/teacher/library/live-experience/${liveExperienceId}`,
    video_details_teacher: (videoId: string) =>
        `/teacher/library/video/${videoId}`,

    // upload
    upload_teacher: '/teacher/upload',
    upload_summary_teacher: '/teacher/upload-summary',

    // account
    account_teacher: '/teacher/account',
    account_information_teacher: '/teacher/account/information',
    account_notification_settings_teacher:
        '/teacher/account/notification-settings',
    account_help_and_faq_teacher: '/teacher/account/help-and-faq',
    account_legal_teacher: '/teacher/account/legal',
};

export const externalLinks = {
    behaviour: 'https://8billionideas.com/behaviour',
    help_faq_child: 'https://8billionideas.com/help-faq-child',
    help_faq_parent: 'https://8billionideas.com/help-faq-adult',
    help_faq_teacher: 'https://8billionideas.com/help-faq-adult',
    terms: 'https://8billionideas.com/terms',
    privacy_policy: 'https://8billionideas.com/privacy',
    homepage: 'https://www.8billionideas.com',
    upload_csv_video:
        'https://8billionideas.notion.site/How-to-Register-your-Students-with-8billionideas-ee5d5f62171845ccb6624248a2f889d2',
    experiences: 'https://www.8billionideas.com/live-experiences',
};
