import { gql, useQuery } from '@apollo/client';
import { SchoolLicensesQuery } from '@@types/graphql/SchoolLicensesQuery';

const GET_SCHOOL_LICENSES_QUERY = gql`
    query SchoolLicensesQuery {
        getSchool {
            licensesInUse
            noLicenses
        }
    }
`;

export default () =>
    useQuery<SchoolLicensesQuery>(GET_SCHOOL_LICENSES_QUERY, {
        fetchPolicy: 'network-only',
    });
