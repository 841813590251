import React, { useRef } from 'react';
import { IconManager } from '@components/_universal';
import { useOnClickOutside } from '@utils/hooks';
import Modal from '@components/_universal/Modal/Modal';
import ModalConfirmation from '@components/_modals/ModalConfirmation/ModalConfirmation';
import {
    ButtonWrapper,
    HeaderWrapper,
    IconWrapper,
    OopsModalWrapper,
    StyledButton,
    StyledText,
    StyledTitle,
} from './CancelSignedUpModal.styled';
import { useSignOutFromLiveExperienceMutation } from '@state/liveExperience/mutations';
import { useGetLiveExperienceQuery } from '@state/liveExperience/queries';
import { useParams } from 'react-router-dom';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const CancelSignedUpModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();
    const { data } = useGetLiveExperienceQuery(liveExperienceId);
    const [
        signOutFromLiveExperience,
        { loading },
    ] = useSignOutFromLiveExperienceMutation();

    return (
        <ModalConfirmation
            closeOutside
            firstButton={{
                onClick: () => setIsModalOpen(false),
                text: 'Go back',
                type: 'secondary',
            }}
            onClose={() => setIsModalOpen(false)}
            secondButton={{
                onClick: () => {
                    setIsModalOpen(false);
                    signOutFromLiveExperience({
                        variables: {
                            liveExperienceId: data?.getLiveExperience._id || '',
                        },
                    });
                },
                text: 'yes, cancel reservation',
                type: 'primary',
                disabled: loading,
            }}
            title="Are you sure?"
        >
            Are you sure you want to cancel your reservation? If you do it now
            you can sign up again for this experience, but only when there are
            slots available.
        </ModalConfirmation>
    );
};

export default CancelSignedUpModal;
