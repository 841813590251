import styled from 'styled-components';
import Badge from '@components/_universal/Badge/Badge';
import {
    BigHeader,
    DefaultText,
    SubTitle,
    Title,
} from '@components/_universal/Typography.styled';

export const CourseParentPageWrapper = styled.div``;

export const PageTitle = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    padding-top: 34px;
`;

export const SectionHeader = styled(Title)`
    align-items: center;
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 80px 0 48px;
`;

export const CourseWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const PageSubtitle = styled(BigHeader)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 16px;
`;

export const ThumbnailWrapper = styled.div`
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    border: 1px solid ${({ theme }) => theme.colors.background_tertiary};
    height: 365px;
    margin-top: 42px;
    padding: 40px 44px;
    min-width: 416px;
`;

export const InnerWrapper = styled.div`
    margin-right: 60px;
`;

export const CourseDescription = styled(DefaultText)`
    margin-bottom: 40px;
`;

export const ThumbnailBadge = styled(Badge)`
    box-shadow: ${({ theme }) => theme.light_grey_shadow};
    left: -5px;
    position: absolute;
    top: 24px;
`;

export const VideoThumbnail = styled.div`
    position: relative;
    margin-bottom: 32px;

    img {
        height: 100%;
        width: 100%;
    }
`;
