import styled from 'styled-components';
import { breakpoints } from '@shared/constants';

export const TabButton = styled.button<{ active?: boolean }>`
    -webkit-text-stroke: 0.5px transparent;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background_secondary};
    border: none;
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    gap: 8px;
    justify-content: center;
    line-height: 1.5;
    outline: none;
    padding: 0 0 17px;
    position: relative;
    transition: all ${({ theme }) => theme.transitions.medium}s;
    width: 208px;

    @media (max-width: ${breakpoints.md}px) {
        width: 150px;
    }
    @media (max-width: ${breakpoints.sm}px) {
        margin: 5px;
        padding: 0 0 8px;
        width: 100%;
    }
    ${({ active, theme }) =>
        active &&
        `
    ::after {
        background-color: ${theme.colors.secondary};
        bottom: -1px;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
    }`}
`;
