/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ACTIVITY_LEVEL {
    DISCONNECTED = 'DISCONNECTED',
    HIGH = 'HIGH',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    PENDING = 'PENDING',
}

export enum AUTH_OPERATION_TYPE {
    EMAIL_CHANGE = 'EMAIL_CHANGE',
    LOGIN = 'LOGIN',
    REGISTER = 'REGISTER',
    RESET_PASSWORD = 'RESET_PASSWORD',
}

export enum BillingCycle {
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export enum CONTENT_STATUS {
    PUBLISHED = 'PUBLISHED',
    UNPUBLISHED = 'UNPUBLISHED',
}

export enum CONTENT_TYPE {
    COURSE = 'COURSE',
    LIVE_EXPERIENCE = 'LIVE_EXPERIENCE',
    STANDALONE_VIDEO = 'STANDALONE_VIDEO',
}

export enum FEED_TYPE {
    CONSUMED_CONTENT = 'CONSUMED_CONTENT',
    FEEDBACK = 'FEEDBACK',
    FIRST_LOGIN = 'FIRST_LOGIN',
    HOMEWORK = 'HOMEWORK',
    LE_SIGN_UP = 'LE_SIGN_UP',
}

export enum GENDER {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
}

export enum HOMEWORK_FILE_TYPE {
    FEEDBACK = 'FEEDBACK',
    USER_HOMEWORK = 'USER_HOMEWORK',
}

export enum LIBRARY_VIEW_CONTENT_SORTING {
    MOST_POPULAR = 'MOST_POPULAR',
    NEWEST = 'NEWEST',
    RECOMMENDED = 'RECOMMENDED',
}

export enum NOTIFICATION_TYPE {
    CHILD_BEING_BANNED = 'CHILD_BEING_BANNED',
    CHILD_FLAG_FOR_BAN = 'CHILD_FLAG_FOR_BAN',
    CONTENT_RECOMMENDATION = 'CONTENT_RECOMMENDATION',
    HOMEWORK_FEEDBACK_COURSE = 'HOMEWORK_FEEDBACK_COURSE',
    HOMEWORK_FEEDBACK_LE = 'HOMEWORK_FEEDBACK_LE',
    LE_CHILD_SIGNUP = 'LE_CHILD_SIGNUP',
    LE_MENTOR_ASSIGNED_AS_FACILITATOR = 'LE_MENTOR_ASSIGNED_AS_FACILITATOR',
    LE_NOTIFY = 'LE_NOTIFY',
    LE_REMINDER_ONE_DAY = 'LE_REMINDER_ONE_DAY',
    LE_REMINDER_ONE_HOUR = 'LE_REMINDER_ONE_HOUR',
    LE_REMINDER_TEN_MINUTES = 'LE_REMINDER_TEN_MINUTES',
    STUDENT_BEING_BANNED = 'STUDENT_BEING_BANNED',
}

export enum SortLiveExperiencesFieldName {
    createdAt = 'createdAt',
    maxNoParticipants = 'maxNoParticipants',
    name = 'name',
    startDate = 'startDate',
    status = 'status',
}

export enum SortingOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum TAG_TYPE {
    SKILL = 'SKILL',
    TOPIC = 'TOPIC',
}

export enum USER_STATUS {
    ACTIVE = 'ACTIVE',
    BANNED = 'BANNED',
    CONFIRMED = 'CONFIRMED',
    CREATED = 'CREATED',
    DEACTIVATED = 'DEACTIVATED',
    DELETED = 'DELETED',
    PENDING = 'PENDING',
}

export enum USER_TYPE {
    ADMIN = 'ADMIN',
    CHILD = 'CHILD',
    MENTOR = 'MENTOR',
    PARENT = 'PARENT',
    STUDENT = 'STUDENT',
    TEACHER = 'TEACHER',
}

export enum VIDEO_STATUS {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
}

export interface ChangeEmailInput {
    userId?: number | null;
    newEmail: string;
}

export interface ChangePasswordInput {
    oldPassword: string;
    newPassword: string;
}

export interface ConfirmResetPasswordInput {
    token: string;
    password: string;
}

export interface ConsumedContentPagination {
    offset?: number | null;
    take?: number | null;
    order?: number | null;
}

export interface ContentTypeFilter {
    LIVE_EXPERIENCE?: boolean | null;
    COURSE?: boolean | null;
    STANDALONE_VIDEO?: boolean | null;
}

export interface CreateCheckoutSession {
    billingCycle: BillingCycle;
    childIdsToRenew: number[];
}

export interface CreateChildAccount {
    email: string;
    name: string;
    surname: string;
    birthday: any;
    gender?: GENDER | null;
}

export interface CreateManyStudentsInput {
    students: CreateStudentInput[];
}

export interface CreateParentAccountInput {
    email: string;
    name: string;
    surname: string;
    password?: string | null;
    address: string;
    city: string;
    zipCode: string;
    countryCode: string;
}

export interface CreatePasswordInput {
    password: string;
}

export interface CreateStudentInput {
    email: string;
    name: string;
    surname: string;
    className: string;
    year: number;
}

export interface CreateStudentsGroupInput {
    title: string;
    students: number[];
}

export interface DeleteStudentAccountInput {
    studentId: number;
}

export interface DeleteStudentsAccountsInput {
    studentsIds: number[];
}

export interface DeleteStudentsGroupInput {
    groupId: number;
}

export interface ExpectedContentsInput {
    courseCount?: number | null;
    liveExperienceCount?: number | null;
    standaloneVideoCount?: number | null;
}

export interface GetActivityFeedsInput {
    pagination: GetActivityFeedsInputPagination;
    search?: GetActivityFeedsInputSearch | null;
}

export interface GetActivityFeedsInputPagination {
    page: number;
    limit: number;
}

export interface GetActivityFeedsInputSearch {
    className?: string | null;
    year?: number | null;
}

export interface GetAllLiveExperiencesInput {
    pagination: GetAllLiveExperiencesInputPagination;
    search?: GetAllLiveExperiencesSearchInput | null;
    sort?: GetAllLiveExperiencesInputSort | null;
}

export interface GetAllLiveExperiencesInputPagination {
    page: number;
    limit: number;
}

export interface GetAllLiveExperiencesInputSort {
    fieldName: SortLiveExperiencesFieldName;
    order: SortingOrder;
}

export interface GetAllLiveExperiencesSearchInput {
    value?: string | null;
    date?: any | null;
}

export interface GetSchoolGroupsInput {
    search?: GetSchoolGroupsSearchInput | null;
    pagination?: OffsetPagination | null;
}

export interface GetSchoolGroupsSearchInput {
    phrase: string;
}

export interface GetStudentActivityInput {
    studentId: number;
    types: CONTENT_TYPE[];
    pagination?: PaginationInput | null;
}

export interface GetStudentForTeacherInput {
    studentId: number;
}

export interface GetStudentsGroupDetailsInput {
    groupId: number;
}

export interface GetStudentsListInput {
    pagination: OffsetPagination;
    search?: GetStudentsListSearchInput | null;
}

export interface GetStudentsListSearchInput {
    groupId?: number | null;
    className?: string | null;
    year?: number | null;
    phrase?: string | null;
}

export interface GetUpcomingLiveExperiencesPagination {
    page?: number | null;
    limit?: number | null;
}

export interface LoginWithPasswordInput {
    email: string;
    password: string;
}

export interface NotificationPagintation {
    cursor?: number | null;
    limit?: number | null;
}

export interface NotificationSettingsInput {
    emailNotificationEnabled?: boolean | null;
    inAppNotificationEnabled?: boolean | null;
}

export interface NotifyAboutLEInput {
    liveExperienceId: string;
    groupId: number;
}

export interface OffsetPagination {
    offset?: number | null;
    limit?: number | null;
}

export interface OngoingContentPagination {
    cursor?: number | null;
    limit?: number | null;
}

export interface PaginationInput {
    limit?: number | null;
    page?: number | null;
}

export interface RecommendContentInput {
    contentId: string;
    groupId: number;
}

export interface SavedForLaterContentPagination {
    page?: number | null;
    limit?: number | null;
}

export interface SearchPaginationInput {
    take?: number | null;
    offset?: number | null;
}

export interface UpdateChildAccountData {
    name?: string | null;
    surname?: string | null;
    birthday?: any | null;
    gender?: GENDER | null;
}

export interface UpdateParentAccountData {
    name?: string | null;
    surname?: string | null;
    address?: string | null;
    city?: string | null;
    zipCode?: string | null;
    countryCode?: string | null;
}

export interface UpdateStudentAccountInput {
    studentId: number;
    name?: string | null;
    surname?: string | null;
    email?: string | null;
    className?: string | null;
    year?: number | null;
}

export interface UpdateStudentsAccountsClassAndYearInput {
    studentsIds: number[];
    className: string;
    year: number;
}

export interface UpdateStudentsAccountsGroupsInput {
    studentsIds: number[];
    groupsIds: number[];
}

export interface UpdateStudentsGroupInput {
    groupId: number;
    title?: string | null;
    assignUsers?: number[] | null;
    removeUsers?: number[] | null;
}

export interface UpdateTeacherAccountInput {
    name?: string | null;
    surname?: string | null;
}

export interface ValidateParentSignupInput {
    email: string;
    countryCode: string;
    zipCode: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
