import styled, { css } from 'styled-components';
import { Button } from '@components/_universal';
import Pagination from '@mui/material/Pagination';

export const PaginationWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 40px 0;
    position: relative;
`;

export const PaginationButtonPrev = styled(Button)`
    left: 0;
    line-height: unset;
    min-width: 115px;
    position: absolute;

    span {
        margin: 0;
    }
`;

export const PaginationButtonNext = styled(Button)`
    line-height: unset;
    min-width: 115px;
    position: absolute;
    right: 0;

    span {
        margin: 0;
    }
`;

export const StyledPagination = styled(Pagination)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        button {
            font-size: ${fontSizes.s}px;
            font-weight: ${fontWeights.semiBold};

            &:hover {
                background-color: transparent;
                color: ${colors.primary};
            }
        }

        && .Mui-selected {
            background-color: ${colors.primary};
            color: ${colors.text_white};
        }
    `,
);
