import { gql, useLazyQuery } from '@apollo/client';
import {
    SearchContentQuery,
    SearchContentQueryVariables,
} from '@@types/graphql/SearchContentQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

const SEARCH_CONTENT_QUERY = gql`
    query SearchContentQuery(
        $query: String!
        $paginationInput: SearchPaginationInput
    ) {
        searchForContent(query: $query, paginationInput: $paginationInput) {
            __typename
            ... on Course {
                _id
                type
                name
                featured
                thumbnail
            }
            ... on StandaloneVideo {
                _id
                type
                name
                featured
                thumbnail
            }
            ... on LiveExperience {
                _id
                endDate
                featured
                maxNoParticipants
                name
                noOfSignups
                schoolsWithAccess {
                    id
                }
                showToChildren
                showToStudents
                startDate
                thumbnail
                type
            }
        }
    }
`;

export default () => {
    return useLazyQuery<SearchContentQuery, SearchContentQueryVariables>(
        SEARCH_CONTENT_QUERY,
        {
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
        },
    );
};
