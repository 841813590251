import React from 'react';
import { Redirect, useParams } from 'react-router';
import { LocationDescriptor } from 'history';

const renderRedirect = (
    to: LocationDescriptor,
    placeholders?: string[],
) => () => {
    const params = useParams<{ [key: string]: string }>();
    let route: string = to as string;

    placeholders?.forEach((placeholder) => {
        const clearedPlaceholder = placeholder.replace(':', '');
        const param = params[clearedPlaceholder];
        route = route.replace(placeholder, param);
    });

    return <Redirect to={route} />;
};

export default renderRedirect;
