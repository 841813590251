import { createAction } from 'typesafe-actions';
import { IUploadStudentsTableItem } from '@domains/Teacher/UploadStudents/UploadStudentsPage/_components/UploadStudentsList/UploadStudentsList';
import { IUploadStudentsSummaryTableItem } from '@domains/Teacher/UploadStudents/UploadStudentsSummaryPage/_components/UploadStudentsSummaryList/UploadStudentsSummaryList';

export const addStudentsList = createAction('@uploadCSV/ADD_STUDENTS_LIST')<
    IUploadStudentsTableItem[]
>();

export const clearStudentsList = createAction(
    '@uploadCSV/CLEAR_STUDENTS_LIST',
)();

export const editStudentsList = createAction(
    '@uploadCSV/EDIT_STUDENTS_LIST',
)<IUploadStudentsTableItem>();

export const addErrorStudentsList = createAction(
    '@uploadCSV/ADD_ERROR_STUDENTS_LIST',
)<IUploadStudentsSummaryTableItem[]>();

export const editErrorStudentsList = createAction(
    '@uploadCSV/EDIT_ERROR_STUDENTS_LIST',
)<IUploadStudentsSummaryTableItem>();

export const deleteFromErrorStudentsList = createAction(
    '@uploadCSV/DELETE_FROM_ERROR_STUDENTS_LIST',
)<number[]>();
