import styled, { css } from 'styled-components';

export const activeIconButtonCss = css`
    border: none;
    box-shadow: ${({ theme }) => theme.light_green_shadow};

    &:hover {
        box-shadow: ${({ theme }) => theme.dark_green_shadow};
    }
`;

export const disabledIconButtonCss = css`
    border: ${({ theme }) => `1px solid ${theme.colors.background_primary}`};
`;

export const IconButtonWrapper = styled.button<{
    disabled?: boolean;
    onClick?: () => void;
    size?: number;
}>`
    align-items: center;
    background: ${({ theme }) => theme.colors.app_bg};
    border-radius: ${({ theme }) => theme.borderRadius.button}px;
    display: flex;
    height: ${({ size }) => (size ? size : 42)}px;
    justify-content: center;
    outline: none;
    text-align: center;
    width: ${({ size }) => (size ? size : 42)}px;
    ${({ disabled }) =>
        disabled ? disabledIconButtonCss : activeIconButtonCss};
    ${({ onClick, theme }) => onClick && `cursor: ${theme.cursors.pointer}`};
`;
