import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Title } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const CreateAccountPageWrapper = styled.div<{ visible: boolean }>`
    display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const AccountPageInnerWrapper = styled.div`
    display: grid;
    grid-column-gap: 32px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 24px;
    }

    @media (max-width: ${breakpoints.md}px) {
        display: flex;
        flex-direction: column;
        margin: 0 auto 24px;
        max-width: 600px;
        width: 100%;
    }

    @media (max-width: ${breakpoints.sm}px) {
        margin: 0 0 24px;
    }
`;

export const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 auto 64px;
    position: relative;
    text-align: center;
    z-index: 9;

    @media (max-width: ${breakpoints.lg}px) {
        margin: 0 auto 80px;
    }

    @media (max-width: ${breakpoints.md}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
        line-height: 1.45;
        margin: 0 auto 32px;
        max-width: 320px;
        padding-top: 16px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        padding-top: 0;
    }
`;

export const SignupPricingWrapper = styled.div<{ visible: boolean }>`
    display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const ClosePricePage = styled(IconManager)`
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 40px;
`;
