import React, { useEffect } from 'react';
import { regexTest } from '@utils/helpers';
import {
    ColorType,
    RegExpPasswordKeys,
    RegExpPasswordManager,
} from '@@types/CommonTypes';
import {
    PasswordValidationContainer,
    ValidationElement,
    ValidationIcon,
    ValidationList,
    ValidationText,
} from './PasswordValidation.styled';

export interface IProps {
    color?: ColorType;
    marginTop?: number;
    password: string;
    setIsValid: (value: boolean) => void;
}

interface IPasswordRequirement {
    regexp: RegExp;
    title: string;
}

const requirements: IPasswordRequirement[] = [
    {
        regexp: RegExpPasswordManager[RegExpPasswordKeys.min_char],
        title: 'at least 8 characters',
    },
    {
        regexp: RegExpPasswordManager[RegExpPasswordKeys.uppercase],
        title: 'at least 1 uppercase letter',
    },
    {
        regexp: RegExpPasswordManager[RegExpPasswordKeys.min_number],
        title: 'at least 1 number',
    },
];

const PasswordValidation: React.FC<IProps> = ({
    color,
    marginTop,
    password,
    setIsValid,
}) => {
    useEffect(() => {
        let isValid = true;
        requirements.forEach(({ regexp }) => {
            if (!regexTest(regexp, password)) isValid = false;
        });

        setIsValid(isValid);
    }, [password]);

    return (
        <PasswordValidationContainer marginTop={marginTop}>
            <ValidationText color={color}>
                Your password needs to contain:
            </ValidationText>
            <ValidationList color={color}>
                {requirements.map(({ regexp, title }, index) => {
                    const isFulfilled = regexTest(regexp, password);
                    return (
                        <ValidationElement key={index}>
                            <ValidationText color={color} isBold={isFulfilled}>
                                {title}
                                {isFulfilled && (
                                    <ValidationIcon
                                        name="ValidationDoneBadge"
                                        size={11}
                                    />
                                )}
                            </ValidationText>
                        </ValidationElement>
                    );
                })}
            </ValidationList>
        </PasswordValidationContainer>
    );
};

export default PasswordValidation;
