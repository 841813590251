import React, { useEffect, useState } from 'react';
import { Column, useGlobalFilter, usePagination, useTable } from 'react-table';
import { CreateGroupModal } from '@domains/Teacher/Groups/_modals/CreateGroupModal/CreateGroupModal';
import { GroupsList } from './_components/GroupsList/GroupsList';
import { PageWrapper } from '@domains/Teacher/Groups/_components/PageWrapper/PageWrapper';
import { TableHeader } from '@domains/Teacher/Groups/GroupsTeacherPage/_components/TableHeader/TableHeader';
import { Tooltip } from '@components/_universal/Tooltip/Tooltip';
import { useGroupsPage } from './useGroupsTeacherPage';
import { TooltipLabel } from './GroupsTeacherPage.styled';

export interface GroupsTableItem {
    groupTitle: string;
    numberOfStudents?: number;
    students?: string[];
    id: number;
}

const PAGE_LIMIT = 25;

export const GroupsTeacherPage = () => {
    const [showGroupModal, setShowGroupModal] = useState(false);

    const {
        groupsCount,
        groupsList,
        isGroupExists,
        loading,
        search,
        setPaginationState,
        setSearch,
    } = useGroupsPage();

    const numberOfFilteredPages =
        groupsCount && Math.ceil(groupsCount / PAGE_LIMIT);

    const showMore = (data: string[], id: string) => {
        if (data.length <= 4) {
            return data.join(', ');
        } else {
            const displayNames = data.slice(0, 4).join(', ');
            const numberOfMoreNames = data.length - 4;
            const displayRestOfNames = data.slice(4).join(', ');

            return (
                <>
                    <span>{displayNames}</span>
                    <Tooltip tooltipContent={displayRestOfNames} tooltipId={id}>
                        <TooltipLabel> +{numberOfMoreNames} more</TooltipLabel>
                    </Tooltip>
                </>
            );
        }
    };

    const columns = React.useMemo<Column<GroupsTableItem>[]>(
        () => [
            {
                Header: 'Group title',
                accessor: 'groupTitle',
            },
            {
                Header: 'Number of students',
                accessor: 'numberOfStudents',
            },
            {
                Header: 'Students',
                accessor: 'students',
                width: 400,
                Cell: (data: any) =>
                    data.value ? (
                        <>
                            {showMore(
                                data.value,
                                'students' + data.row.original.id,
                            )}
                        </>
                    ) : (
                        '-'
                    ),
            },
        ],
        [],
    );

    const data = React.useMemo<GroupsTableItem[]>(
        () =>
            groupsList?.map((group) => ({
                groupTitle: group.title,
                numberOfStudents: group.students?.length,
                students: group?.students?.map(
                    (student) => `${student.name} ${student.surname}`,
                ),
                id: group?.id,
            })) ?? [],
        [groupsList],
    );

    const tableProps = useTable<GroupsTableItem>(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: PAGE_LIMIT,
            },
            manualPagination: true,
            pageCount: numberOfFilteredPages,
        },
        useGlobalFilter,
        usePagination,
    );

    useEffect(() => {
        setPaginationState({
            pageIndex: tableProps.state.pageIndex,
            pageSize: tableProps.state.pageSize,
        });
    }, [tableProps.state.pageIndex]);

    useEffect(() => {
        tableProps.gotoPage(0);
    }, [search]);

    return (
        <PageWrapper
            groupsCountLoading={loading}
            isGroupExists={isGroupExists}
            setSearch={setSearch}
        >
            <>
                <TableHeader
                    firstButton={{
                        onClick: setShowGroupModal,
                        title: 'create group',
                    }}
                    groupsListLoading={loading}
                    isGroupExists={isGroupExists}
                    title="Groups"
                />

                {showGroupModal && (
                    <CreateGroupModal setIsModalOpen={setShowGroupModal} />
                )}
                <GroupsList
                    data={data}
                    isGroupExists={isGroupExists}
                    groupsListLoading={loading}
                    tableProps={tableProps}
                />
            </>
        </PageWrapper>
    );
};
