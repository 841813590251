import { ChipButton } from './Chip.styled';

interface IProps {
    active?: boolean;
    className?: string;
    onClick: (value: string) => void;
    value: string;
}

const Chip: React.FC<IProps> = ({
    active,
    className,
    children,
    onClick,
    value,
}) => {
    return (
        <ChipButton
            className={className}
            active={active}
            onClick={() => onClick(value)}
        >
            {children}
        </ChipButton>
    );
};

export default Chip;
