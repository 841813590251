import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';

export const DownloadFileIcon = styled(IconManager)`
    margin-right: 16px;
`;

export const WorkFileWrapper = styled.div`
    display: grid;
`;

export const FileName = styled(DefaultText)`
    display: inline-block;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color ${({ theme }) => theme.transitions.medium}s;
`;

export const FileUploaded = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 0;
`;

export const UploadedFileWrapper = styled.span`
    align-items: center;
    display: flex;
    cursor: ${({ theme }) => theme.cursors.pointer};

    &:hover {
        ${FileName} {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;
