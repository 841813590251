import { IconManager } from '@components/_universal';
import styled, { css } from 'styled-components';
import { Title } from '@components/_universal/Typography.styled';

export const RecommendedWrapper = styled.div<{ smallMargin?: boolean }>(
    ({ smallMargin }) => css`
        margin: ${smallMargin ? '0 0 60px 0' : '120px 0 60px 0'};
        padding-right: 85px;
        width: 100%;
    `,
);

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 48px 0;
    width: 100%;
`;

export const StyledTitle = styled(Title)`
    display: inline-flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 40px;
    position: relative;
`;

export const ForYouIcon = styled(IconManager)`
    left: calc(100% + 11px);
    position: absolute;
    top: 10px;
`;

export const PushPinIcon = styled(IconManager)`
    left: calc(100% + 116px);
    position: absolute;
    top: -6px;
`;
