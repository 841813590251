import styled, { css } from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button } from '@components/_universal';
import { DefaultText, Title } from '@components/_universal/Typography.styled';

export const TableOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TableOptionsInner = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    @media (max-width: ${breakpoints.lg}px) {
        align-items: flex-end;
        flex-direction: column;
    }
`;

export const TableInfoWrapper = styled.div`
    align-items: center;
    display: flex;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 16px;
        width: 100%;
    }
`;

export const HeaderText = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 0;
    `,
);

export const Separator = styled.div(
    ({ theme: { colors } }) => css`
        background: ${colors.stroke};
        height: 25px;
        margin: 0 30px;
        position: relative;
        width: 1px;
    `,
);

export const LicencesInfo = styled(DefaultText)`
    align-items: center;
    display: flex;
    margin-bottom: 0;
`;

export const LicenceBold = styled.span(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin: 0 4px;
    `,
);

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    position: relative;
`;

export const DeleteButton = styled(Button)`
    position: absolute;
    right: 0;
    width: 160px;
`;

export const StyledButton = styled(Button)<{ width: number }>(
    ({ width }) => css`
        width: ${width}px;
    `,
);

export const ButtonsInnerWrapper = styled.div`
    display: flex;
    gap: 16px;
`;
