import React from 'react';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/_universal/Carousel/Carousel';
import LiveExperienceCard from '@components/_universal/Cards/LiveExperienceCard/LiveExperienceCard';
import paths from '@shared/paths';
import { SwiperWrapper } from '../LiveExperienceCarousel.styled';

interface DataLiveExperiences {
    _id: string;
    featured: boolean;
    maxNoParticipants: number;
    name: string;
    noOfSignups: number;
    startDate: any;
    thumbnail: string;
    type: string;
}

interface OngoingAndUpcomingProps {
    data: DataLiveExperiences[];
}

export const OngoingAndUpcoming = ({ data }: OngoingAndUpcomingProps) => {
    return (
        <SwiperWrapper>
            <Carousel
                arrowsOffset={24}
                disabled={false}
                nextButtonClass={'next--childManagement-experiences'}
                prevButtonClass={'prev--childManagement-experiences'}
                style={{ padding: '0 85px 0 0' }}
                withArrows={data.length > 3}
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index}>
                        <LiveExperienceCard
                            allSlots={item.maxNoParticipants}
                            availableSlots={
                                item.maxNoParticipants - item.noOfSignups
                            }
                            image={item.thumbnail ?? ''}
                            link={paths.live_experience(item._id)}
                            startDate={new Date(item.startDate)}
                            text={item.name}
                        />
                    </SwiperSlide>
                ))}
            </Carousel>
        </SwiperWrapper>
    );
};
