import React from 'react';
import { BillingCycle } from '@@types/graphql/GraphqlGlobalTypes';
import { SwiperSlide } from 'swiper/react';
import { useMatchMedia } from '@utils/hooks';
import Carousel from '@components/_universal/Carousel/Carousel';
import {
    MobileDescription,
    MobileSubscriptionPageWrapper,
    MobileSubscriptionWrapper,
    MobileTitle,
    StyledChoosePayment,
    StyledMobileDescription,
} from './MobileSubscriptionPage.styled';

export interface IProps {}

const MobileMobileSignupSubscriptionPage: React.FC<IProps> = () => {
    const { match } = useMatchMedia('sm');

    return (
        <MobileSubscriptionPageWrapper>
            <MobileTitle>
                Wide range of possibilities with one easy subscription.
            </MobileTitle>
            <MobileDescription>
                <StyledMobileDescription>
                    Start your child's future today - with us!
                </StyledMobileDescription>
            </MobileDescription>
            <MobileSubscriptionWrapper>
                <Carousel
                    disabled={!match}
                    withArrows={false}
                    spaceBetween={16}
                >
                    <SwiperSlide style={{ width: 320 }}>
                        <StyledChoosePayment
                            currentValue={320}
                            paymentType={BillingCycle.YEAR}
                        >
                            Annual
                        </StyledChoosePayment>
                    </SwiperSlide>
                    <SwiperSlide style={{ width: 320 }}>
                        <StyledChoosePayment
                            buttonType={'secondary'}
                            iconFill={['border']}
                        >
                            Monthly
                        </StyledChoosePayment>
                    </SwiperSlide>
                </Carousel>
            </MobileSubscriptionWrapper>
        </MobileSubscriptionPageWrapper>
    );
};

export default MobileMobileSignupSubscriptionPage;
