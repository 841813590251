import { useSelector } from 'react-redux';
import ChatWithSupport from '@components/_universal/ChatWithSupport/ChatWithSupport';
import React from 'react';
import {
    Content,
    FooterBar,
    HeaderBar,
    NavigationBar,
    TeacherAccountLayoutWrapper,
} from './TeacherAccountLayout.styled';

interface IProps {}

const TeacherAccountLayout: React.FC<IProps> = ({ children }) => {
    const { isAdminAsTeacher } = useSelector((state) => state.signup);

    return (
        <TeacherAccountLayoutWrapper>
            <HeaderBar trialIcon />
            <NavigationBar />
            <Content paddingTop={isAdminAsTeacher}>{children}</Content>
            <FooterBar isBackpack isLogo />
            <ChatWithSupport />
        </TeacherAccountLayoutWrapper>
    );
};

export default TeacherAccountLayout;
