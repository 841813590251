import { Control, FieldErrors, UseFormMethods } from 'react-hook-form';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import { StyledSelect } from './SelectStudentsGroup.styled';

interface SelectStudentsGroupProps {
    control: Control;
    errors?: FieldErrors;
    noOptionsMessage: () => JSX.Element;
    options: { label?: string; value?: number }[];
    register: UseFormMethods['register'];
}

export const SelectStudentsGroup = ({
    control,
    errors,
    noOptionsMessage,
    options,
    register,
}: SelectStudentsGroupProps) => {
    return (
        <SelectForm
            control={control}
            errors={errors}
            isMulti={false}
            isSearchable
            label="Students group"
            name={'group'}
            noOptionsMessage={noOptionsMessage}
            options={options}
            register={register}
        />
    );
};
const SelectForm = FieldMessage(StyledSelect);
