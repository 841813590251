import React from 'react';
import DesktopSubscriptionPage from './_desktop/DesktopSubscriptionPage';
import MobileSubscriptionPage from './_mobile/MobileSubscriptionPage';
import useMobile from '@utils/hooks/useMobile';

interface IProps {}

const SubscriptionPage: React.FC<IProps> = () => {
    const { isMobile } = useMobile();

    return isMobile ? <MobileSubscriptionPage /> : <DesktopSubscriptionPage />;
};

export default SubscriptionPage;
