import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import childManagementReducer from './childManagement/reducer';
import filtersReducer from './filters/reducer';
import layoutReducer from './layout/reducer';
import notificationsReducer from './notifications/reducer';
import signup from './signup/reducer';
import storage from 'redux-persist/lib/storage';
import tabsReducer from './tabs/reducer';
import uploadCSVReducer from './uploadCSV/reducer';
import liveExperiencesVisibilityReducer from './liveExperiencesVisibility/reducer';

const signupReducer = persistReducer(
    {
        key: 'signup',
        storage,
        blacklist: ['successPayment', 'correctAccountData', 'isFirstLogin'],
    },
    signup,
);

export default combineReducers({
    childManagement: childManagementReducer,
    filters: filtersReducer,
    layout: layoutReducer,
    liveExperiencesVisibility: liveExperiencesVisibilityReducer,
    notifications: notificationsReducer,
    signup: signupReducer,
    tabs: tabsReducer,
    uploadCSV: uploadCSVReducer,
});
