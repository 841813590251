import React from 'react';
import { IconManager } from '@components/_universal';
import { useSelector } from 'react-redux';
import {
    CardContent,
    CardFooter,
    ContentValue,
    ContentValueIcon,
    ContentValuesWrapper,
    FrequencyText,
    StudentsActivityCardWrapper,
    StudentsNumber,
} from './StudentsActivityCard.styled';

export interface IStudentsActivityCardProps {
    activityText: string;
    contentValues?: {
        courses: number;
        experiences: number;
        videos: number;
    };
    frequencyValue: string;
    students: number;
    studentsPercentage: number;
}

const StudentsActivityCard: React.FC<IStudentsActivityCardProps> = ({
    activityText,
    contentValues,
    frequencyValue,
    students,
    studentsPercentage,
}) => {
    const { liveExperiencesVisibility } = useSelector(
        (state) => state.liveExperiencesVisibility,
    );
    return (
        <StudentsActivityCardWrapper>
            <CardContent isSmaller={!contentValues}>
                <FrequencyText>{frequencyValue}</FrequencyText>
                <StudentsNumber>
                    {students} student{students !== 1 && 's'} (
                    {studentsPercentage}%)
                </StudentsNumber>
                {contentValues && (
                    <ContentValuesWrapper>
                        {liveExperiencesVisibility && (
                            <ContentValue>
                                <ContentValueIcon color="live_experience">
                                    <IconManager size={16} name="Live" />
                                </ContentValueIcon>
                                {contentValues.experiences} Live experiences
                            </ContentValue>
                        )}
                        <ContentValue>
                            <ContentValueIcon color="course">
                                <IconManager size={16} name="Course" />
                            </ContentValueIcon>
                            {contentValues.courses} Courses
                        </ContentValue>
                        <ContentValue>
                            <ContentValueIcon color="video">
                                <IconManager size={16} name="Video" />
                            </ContentValueIcon>
                            {contentValues.videos} Videos
                        </ContentValue>
                    </ContentValuesWrapper>
                )}
            </CardContent>
            <CardFooter>{activityText}</CardFooter>
        </StudentsActivityCardWrapper>
    );
};

export default StudentsActivityCard;
