import React from 'react';
import { BillingCycle } from '@@types/graphql/GraphqlGlobalTypes';
import { useHistory } from 'react-router-dom';
import ChoosePayment from '@domains/Auth/_components/ChoosePayment/ChoosePayment';
import FreeTrial from '@domains/Auth/_components/FreeTrial/FreeTrial';
import {
    DescriptionWrapper,
    DesktopSignupPricingPageWrapper,
    PricingWrapper,
    StyledTitle,
} from './DesktopSignupPricingPage.styled';

interface IProps {}

const DesktopSignupPricingPage: React.FC<IProps> = () => {
    const history = useHistory();

    return (
        <DesktopSignupPricingPageWrapper>
            <StyledTitle>
                Wide range of possibilities with one easy subscription.
            </StyledTitle>
            <DescriptionWrapper>
                Check out the platform prices if you decide to buy a
                subscription after the 10-day trial
            </DescriptionWrapper>
            <PricingWrapper>
                <ChoosePayment buttonType={'secondary'}>Monthly</ChoosePayment>
                <ChoosePayment
                    currentValue={320}
                    paymentType={BillingCycle.YEAR}
                >
                    Annual
                </ChoosePayment>
                <FreeTrial pricingVersion />
            </PricingWrapper>
        </DesktopSignupPricingPageWrapper>
    );
};

export default DesktopSignupPricingPage;
