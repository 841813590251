import React, { useRef } from 'react';
import { useOnClickOutside } from '@utils/hooks';
import Modal from '@components/_universal/Modal/Modal';
import SetPasswordForm from '@domains/Child/Account/_forms/SetPasswordForm/SetPasswordForm';
import {
    IconWrapper,
    SetPasswordModalWrapper,
    StyledTitle,
} from './SetPasswordModal.styled';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const SetPasswordModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsModalOpen(false));

    return (
        <Modal>
            <SetPasswordModalWrapper {...{ ref }}>
                <StyledTitle>Set password</StyledTitle>

                <SetPasswordForm closeModal={() => setIsModalOpen(false)} />

                <IconWrapper
                    cursor={'pointer'}
                    name={'Close'}
                    onClick={() => setIsModalOpen(false)}
                    size={24}
                />
            </SetPasswordModalWrapper>
        </Modal>
    );
};

export default SetPasswordModal;
