import React, { useEffect, useState } from 'react';
import { Button } from '@components/_universal';
import { emailValidation } from '@utils/shared/validations/formErrors';
import { isBetween } from '@utils/helpers';
import { notify } from '@components/ToastNotification/ToastNotification';
import { setStudentsUploadModal } from '@state/_redux/layout/actions';
import { ToastTypes } from '@@types/CommonTypes';
import { useCreateManyStudentsMutation } from '@state/students/mutations';
import { useDispatch, useSelector } from 'react-redux';
import UploadStudentsList from './_components/UploadStudentsList/UploadStudentsList';
import {
    HeaderDescription,
    HeaderInnerWrapper,
    PageHeader,
    PageTitle,
    UploadStudentsPageWrapper,
} from './UploadStudentsPage.styled';

export interface IProps {}

export enum UPLOAD_STUDENTS_ERRORS {
    EMAIL_ERROR = 'EMAIL_ERROR',
    YEAR_ERROR = 'YEAR_ERROR',
}

export interface IUploadStudentsError {
    id: number;
    type: UPLOAD_STUDENTS_ERRORS;
}

const UploadStudentsPage: React.FC<IProps> = () => {
    const studentsList = useSelector((state) => state.uploadCSV.studentsList);
    const [errorStudents, setErrorStudents] = useState<IUploadStudentsError[]>(
        [],
    );
    const dispatch = useDispatch();

    const [createManyStudents, { loading }] = useCreateManyStudentsMutation();

    const checkForUploadErrors = () => {
        // check if emails and years are valid
        const errors: IUploadStudentsError[] = [];
        studentsList.forEach((student) => {
            if (!emailValidation.isValidSync(student.email))
                errors.push({
                    id: student.id,
                    type: UPLOAD_STUDENTS_ERRORS.EMAIL_ERROR,
                });
            if (!isBetween(Number(student.year), 0, 21))
                errors.push({
                    id: student.id,
                    type: UPLOAD_STUDENTS_ERRORS.YEAR_ERROR,
                });
        });

        setErrorStudents(errors);

        return !!errors.length;
    };

    const handleUploadStudents = () => {
        if (checkForUploadErrors())
            return notify(
                'Some of the data has been entered incorrectly. Make changes to proceed.',
            )[ToastTypes.ERROR]();

        dispatch(setStudentsUploadModal(true));
        createManyStudents({
            variables: {
                input: {
                    students: studentsList.map((student) => ({
                        className: student.className,
                        email: student.email,
                        name: student.name,
                        surname: student.surname,
                        year: +student.year,
                    })),
                },
            },
        });
    };

    useEffect(() => {
        checkForUploadErrors();
    }, [studentsList]);

    return (
        <UploadStudentsPageWrapper>
            <PageHeader>
                <PageTitle>Uploaded list of students</PageTitle>
                <HeaderInnerWrapper>
                    <HeaderDescription>
                        Please check the uploaded student list. If you want to
                        correct the data, click on the student whose data needs
                        correcting. If all the data is correct, click confirm.
                    </HeaderDescription>
                    <Button disabled={loading} onPress={handleUploadStudents}>
                        confirm
                    </Button>
                </HeaderInnerWrapper>
            </PageHeader>
            <UploadStudentsList
                errorStudents={errorStudents}
                setErrorStudents={setErrorStudents}
            />
        </UploadStudentsPageWrapper>
    );
};

export default UploadStudentsPage;
