import { ActivityText, ContentName, NameText } from './ActivityFeedCard.styled';
import { ColorType, IconName, Nullable } from '@@types/CommonTypes';
import { CONTENT_TYPE, FEED_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { ReactFragment } from 'react';
import paths from '@shared/paths';
import {
    StudentsActivityFeedQuery_getActivityFeeds_feeds_course,
    StudentsActivityFeedQuery_getActivityFeeds_feeds_liveExperience,
    StudentsActivityFeedQuery_getActivityFeeds_feeds_standaloneVideo,
    StudentsActivityFeedQuery_getActivityFeeds_feeds,
} from '@@types/graphql/StudentsActivityFeedQuery';

interface IFeedText {
    activity: StudentsActivityFeedQuery_getActivityFeeds_feeds;
}

interface IContent {
    contentName: string;
}

export const contentNames = {
    [CONTENT_TYPE.COURSE]: 'course',
    [CONTENT_TYPE.LIVE_EXPERIENCE]: 'experience',
    [CONTENT_TYPE.STANDALONE_VIDEO]: 'video',
};

export const activityFeedIcons: Record<FEED_TYPE, IconName> = {
    [FEED_TYPE.CONSUMED_CONTENT]: 'Tick',
    [FEED_TYPE.FEEDBACK]: 'FeedbackIcon',
    [FEED_TYPE.FIRST_LOGIN]: 'Login',
    [FEED_TYPE.HOMEWORK]: 'SmallUpload',
    [FEED_TYPE.LE_SIGN_UP]: 'Calendar',
};

export const getContentColor = (
    contentType?: Nullable<CONTENT_TYPE>,
): ColorType | undefined => {
    switch (contentType) {
        case CONTENT_TYPE.COURSE:
            return 'course';
        case CONTENT_TYPE.LIVE_EXPERIENCE:
            return 'live_experience';
        case CONTENT_TYPE.STANDALONE_VIDEO:
            return 'video';
    }
};

export const getUserName = (userId: number, userName: string) => (
    <NameText to={paths.student_details_teacher(userId.toString())}>
        {userName}
    </NameText>
);

export const getContentName = ({ contentName }: IContent) => {
    return <ContentName>‘{contentName}’</ContentName>;
};

export const getContentDetails = (
    activity: StudentsActivityFeedQuery_getActivityFeeds_feeds,
): IContent | undefined => {
    switch (activity.contentType) {
        case CONTENT_TYPE.COURSE:
            const course = activity.course as StudentsActivityFeedQuery_getActivityFeeds_feeds_course;
            return {
                contentName: course?.name,
            };
        case CONTENT_TYPE.LIVE_EXPERIENCE:
            const experience = activity.liveExperience as StudentsActivityFeedQuery_getActivityFeeds_feeds_liveExperience;
            return {
                contentName: experience?.name,
            };
        case CONTENT_TYPE.STANDALONE_VIDEO:
            const video = activity.standaloneVideo as StudentsActivityFeedQuery_getActivityFeeds_feeds_standaloneVideo;
            return {
                contentName: video?.name,
            };
    }
};

export const getFeedText = ({
    activity,
}: IFeedText): Record<FEED_TYPE, ReactFragment> => {
    const constentDetails = getContentDetails(activity);
    const userId = activity.user?.id as number;
    const userName = `${activity.user?.name} ${activity.user?.surname}`;
    const contentType = activity.contentType;

    const UserName = getUserName(userId, userName);
    const ContentLink = constentDetails && getContentName(constentDetails);

    return {
        [FEED_TYPE.CONSUMED_CONTENT]:
            contentType === CONTENT_TYPE.STANDALONE_VIDEO ? (
                <ActivityText>
                    {UserName} watched the video {ContentLink}
                </ActivityText>
            ) : contentType === CONTENT_TYPE.COURSE ? (
                <ActivityText>
                    {UserName} took part in the {ContentLink} course
                </ActivityText>
            ) : (
                <ActivityText>
                    {UserName} took part in the
                    {ContentLink} experience
                </ActivityText>
            ),
        [FEED_TYPE.FEEDBACK]: (
            <ActivityText>
                {UserName} has received feedback for the project: {ContentLink}{' '}
                {contentNames[contentType as CONTENT_TYPE]}
            </ActivityText>
        ),
        [FEED_TYPE.FIRST_LOGIN]: (
            <ActivityText>{UserName} logged in for the first time</ActivityText>
        ),
        [FEED_TYPE.HOMEWORK]: (
            <ActivityText>
                {UserName} has submitted a homework to {ContentLink}{' '}
                {contentNames[contentType as CONTENT_TYPE]}
            </ActivityText>
        ),
        [FEED_TYPE.LE_SIGN_UP]: (
            <ActivityText>
                {UserName} has signed up for {ContentLink} experience
            </ActivityText>
        ),
    };
};
