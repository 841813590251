import React from 'react';
import { Redirect, useLocation } from 'react-router';
import paths from '@shared/paths';
import {
    MagicLinkSentPageWrapper,
    OrnamentIcon,
    StyledTitle,
    Subtitle,
    WrongEmailLink,
    WrongEmailText,
} from './MagicLinkSentPage.styled';

export interface IProps {}

const MagicLinkSentPage: React.FC<IProps> = () => {
    const location = useLocation<{ email?: string }>();

    if (!location?.state?.email) return <Redirect to={paths.link_login} />;

    return (
        <MagicLinkSentPageWrapper>
            <StyledTitle>Magic link has been sent!</StyledTitle>
            <Subtitle>
                Check your {location?.state?.email} inbox for an email from us.
                Click the link in it to log into our platform.
            </Subtitle>
            <OrnamentIcon name={'MailIcon'} size={250} sizeY={200} />
            <WrongEmailText>
                Wrong email address? Please{' '}
                <WrongEmailLink to={paths.link_login}>
                    re-enter email address.
                </WrongEmailLink>
            </WrongEmailText>
        </MagicLinkSentPageWrapper>
    );
};

export default MagicLinkSentPage;
