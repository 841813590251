import * as yup from 'yup';
import { IChild } from './SignupRegisterPage';
import {
    dateValidation,
    emailValidation,
    genderValidation,
    textValidation,
} from '@utils/shared/validations/formErrors';

let childs: IChild[] = [];

export const validation = yup.object().shape({
    childs: yup
        .array()
        .test('pass childs array', '', (value) => {
            childs = value as IChild[];
            return true;
        })
        .of(
            yup.object().shape({
                birthday: dateValidation,
                email: emailValidation.test(
                    'unique',
                    'You can not use the same email for two or more children',
                    (value) => {
                        if (!value) return false;
                        const emails = childs.filter(
                            (child) =>
                                child.email.toLowerCase() ===
                                value.toLowerCase(),
                        );

                        return emails.length === 1;
                    },
                ),
                gender: genderValidation,
                name: textValidation,
                surname: textValidation,
            }),
        ),
});
