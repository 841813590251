import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import {
    ReadNotificationMutation,
    ReadNotificationMutationVariables,
} from '@@types/graphql/ReadNotificationMutation';

export const READ_NOTIFICATION_MUTATION = gql`
    mutation ReadNotificationMutation($deliveryId: Int!) {
        readNotification(deliveryId: $deliveryId) {
            id
        }
    }
`;

export default () => {
    return useMutation<
        ReadNotificationMutation,
        ReadNotificationMutationVariables
    >(READ_NOTIFICATION_MUTATION, {
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
