import React from 'react';
import { IconManager } from '@components/_universal';
import { IconName } from '@@types/CommonTypes';
import { Link } from 'react-router-dom';
import { setTypeformModal } from '@state/_redux/layout/actions';
import { useDispatch } from 'react-redux';
import paths from '@shared/paths';
import useScrollToActiveElement from '@utils/hooks/useScrollToActiveElement';
import {
    NavigationItemIcon,
    NavigationItemText,
    NavigationItemWrapper,
    NavigationList,
    NavigationLogoIcon,
} from '@components/_global/Navigation/Navigation.styled';
import {
    ChildNavigationWrapper,
    ChildSimpleBar,
    SimpleBarWrapper,
    StyledTypeformTip,
    TypeformCircle,
    TypeformIconWrapper,
} from './ChildNavigation.styled';

interface IProps {
    borderLine?: boolean;
    disabledLinks?: boolean;
    disabledLogoLinks?: boolean;
    disabledFormButton?: boolean;
}

interface INavLink {
    iconName: IconName;
    label: string;
    path: string;
}

const NAVIGATION_LINKS: INavLink[] = [
    {
        iconName: 'Dashboard',
        label: 'Dashboard',
        path: paths.dashboard,
    },
    {
        iconName: 'Book',
        label: 'My content',
        path: paths.courses,
    },
    {
        iconName: 'Library',
        label: 'Library',
        path: paths.library,
    },
];

const Navigation: React.FC<IProps> = ({
    borderLine,
    disabledLinks,
    disabledLogoLinks,
    disabledFormButton,
}) => {
    const { navigationRef } = useScrollToActiveElement();

    const dispatch = useDispatch();

    return (
        <ChildNavigationWrapper borderLine={borderLine}>
            {disabledLogoLinks ? (
                <NavigationLogoIcon name={'Logo'} size={50} />
            ) : (
                <Link to={paths.dashboard}>
                    <NavigationLogoIcon name={'Logo'} size={50} />
                </Link>
            )}
            {!disabledLinks && (
                <SimpleBarWrapper>
                    <ChildSimpleBar>
                        <NavigationList ref={navigationRef}>
                            {NAVIGATION_LINKS.map(
                                ({ iconName, label, path }, index) => (
                                    <NavigationItemWrapper
                                        activeClassName={
                                            'navigation-item-active'
                                        }
                                        exact
                                        key={index}
                                        to={path}
                                    >
                                        <NavigationItemIcon
                                            name={iconName}
                                            size={46}
                                        />
                                        <NavigationItemText>
                                            {label}
                                        </NavigationItemText>
                                    </NavigationItemWrapper>
                                ),
                            )}
                        </NavigationList>
                    </ChildSimpleBar>
                </SimpleBarWrapper>
            )}
            {!disabledFormButton && (
                <TypeformIconWrapper>
                    <TypeformCircle
                        onClick={() => dispatch(setTypeformModal(true))}
                    >
                        <IconManager name="Idea" size={25} sizeY={34} />
                        <StyledTypeformTip />
                    </TypeformCircle>
                </TypeformIconWrapper>
            )}
        </ChildNavigationWrapper>
    );
};

export default Navigation;
