import React from 'react';
import ChipsInput from '@components/_form-elements/ChipsInput/ChipsInput';
import Select from '@components/_form-elements/Select/Select';
import useFilterSidebar from './useFilterSidebar';
import { sortBy } from '@shared/filters';
import {
    FilterSidebarWrapper,
    FilterTitle,
    FilterWrapper,
    SimpleBarWrapper,
} from './FilterSidebar.styled';

interface IProps {
    className?: string;
}

const FilterSidebar: React.FC<IProps> = ({ className }) => {
    const { control, topicFilter, typeFilter } = useFilterSidebar();

    return (
        <FilterSidebarWrapper className={className}>
            <SimpleBarWrapper>
                <FilterWrapper>
                    <FilterTitle>Sort by</FilterTitle>
                    <Select
                        control={control}
                        isSearchable={false}
                        name={'sortBy'}
                        options={sortBy}
                    />
                </FilterWrapper>
                <FilterWrapper>
                    <FilterTitle>Type</FilterTitle>
                    <ChipsInput
                        chips={typeFilter}
                        control={control}
                        name="type"
                    />
                </FilterWrapper>
                <FilterWrapper>
                    <FilterTitle>Topic</FilterTitle>
                    <ChipsInput
                        chips={topicFilter}
                        control={control}
                        name="topic"
                    />
                </FilterWrapper>
            </SimpleBarWrapper>
        </FilterSidebarWrapper>
    );
};

export default FilterSidebar;
