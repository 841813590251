import React from 'react';
import ModalConfirmation from '@components/_modals/ModalConfirmation/ModalConfirmation';

export interface IProps {
    approveText: string;
    cancelText: string;
    content: string;
    onApprove: () => void;
    onCancel: () => void;
    title: string;
}

const TrialEndedModal: React.FC<IProps> = ({
    approveText,
    cancelText,
    content,
    onApprove,
    onCancel,
    title,
}) => {
    return (
        <ModalConfirmation
            firstButton={{
                onClick: onCancel,
                text: cancelText,
                type: 'secondary',
            }}
            secondButton={{
                onClick: onApprove,
                text: approveText,
                type: 'primary',
            }}
            title={title}
        >
            {content}
        </ModalConfirmation>
    );
};

export default TrialEndedModal;
