import styled from 'styled-components';
import { Button } from '@components/_universal';
import { InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';

export const EditAccountInformationFormWrapper = styled.form``;

export const Actions = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 10px 0 45px;
`;

export const ActionButton = styled(Button)`
    margin: 0 12px;
    min-width: 160px;
`;

export const InputAdditionalTextWrapper = styled(InputWrapper)`
    margin-bottom: 32px;
`;
