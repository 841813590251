import styled, { css } from 'styled-components';
import IconManager from '@components/_universal/IconManager/IconManager';
import { ButtonSizes, ButtonTypes } from '@@types/CommonTypes';

export const buttonShadowsCss = css`
    box-shadow: ${({ theme }) => theme.light_green_shadow};

    &:hover {
        box-shadow: ${({ theme }) => theme.dark_green_shadow};
    }
`;

export const blueButtonShadowsCss = css`
    &:hover {
        box-shadow: ${({ theme }) => theme.blue_shadow};
    }
`;

export const buttonErrorShadowsCss = css`
    box-shadow: ${({ theme }) => theme.light_red_shadow};

    &:hover {
        box-shadow: ${({ theme }) => theme.dark_red_shadow};
    }
`;

// primary
export const primaryButtonDisabledCss = css`
    background-color: ${({ theme }) => theme.colors.disable};
    border-color: ${({ theme }) => theme.colors.disable};
    opacity: 1;
`;

export const primaryButtonCss = css<{
    disabled: boolean;
    disabledWithAction?: boolean;
}>`
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.app_bg};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-transform: uppercase;
    ${({ disabled, disabledWithAction }) =>
        disabled || disabledWithAction
            ? primaryButtonDisabledCss
            : buttonShadowsCss}
`;

// secondary
export const secondaryButtonDisabledCss = css`
    border-color: ${({ theme }) => theme.colors.disable};
    color: ${({ theme }) => theme.colors.text_assistive};
    opacity: 0.4;
`;

export const secondaryButtonCss = css<{ disabled: boolean }>`
    background-color: ${({ theme }) => theme.colors.app_bg};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    ${({ disabled }) =>
        disabled ? secondaryButtonDisabledCss : buttonShadowsCss}
`;

// tertiary
export const tertiaryButtonDisabledCss = css`
    opacity: 0.4;
`;

export const tertiaryButtonCss = css<{ disabled: boolean }>`
    background-color: ${({ theme }) => theme.colors.secondary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text_white};
    text-transform: uppercase;
    ${({ disabled }) =>
        disabled ? secondaryButtonDisabledCss : blueButtonShadowsCss};
`;

// primary Error
export const primaryErrorButtonDisabledCss = css`
    opacity: 0.4;
`;

export const primaryErrorButtonCss = css<{ disabled: boolean }>`
    background-color: ${({ theme }) => theme.colors.error};
    border: 1px solid ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-transform: uppercase;
    ${({ disabled }) =>
        disabled ? primaryErrorButtonDisabledCss : buttonErrorShadowsCss}
`;

// secondary Error
export const secondaryErrorButtonDisabledCss = css`
    opacity: 0.4;
`;

export const secondaryErrorButtonCss = css<{ disabled: boolean }>`
    background-color: ${({ theme }) => theme.colors.background_secondary};
    border: 1px solid ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.error};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-transform: uppercase;
    ${({ disabled }) =>
        disabled ? secondaryErrorButtonDisabledCss : buttonErrorShadowsCss}
`;

export const buttonWithIconCss = css`
    align-content: center;
    align-items: center;
    display: inline-flex;
`;

export const regularButtonCss = css`
    min-height: 56px;
    padding: 5px 30px;
`;

export const smallButtonCss = css`
    min-height: 40px;
    padding: 5px 30px;
`;

export const ButtonStyleSizes = {
    regular: regularButtonCss,
    small: smallButtonCss,
};

export const ButtonStyleTypes = {
    primary: primaryButtonCss,
    secondary: secondaryButtonCss,
    tertiary: tertiaryButtonCss,
    primaryError: primaryErrorButtonCss,
    secondaryError: secondaryErrorButtonCss,
};

export const ButtonWrapper = styled.button<{
    buttonType?: ButtonTypes;
    disabled: boolean;
    disabledWithAction?: boolean;
    icon?: boolean;
    isChildren?: boolean;
    onClick?: () => void;
    size?: ButtonSizes;
}>`
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius.button}px;
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    justify-content: center;
    letter-spacing: 0.01em;
    line-height: 1.5;
    min-width: 135px;
    outline: none;
    text-align: center;
    transition: all ${({ theme }) => theme.transitions.default}s;
    ${({ onClick, theme }) => onClick && `cursor: ${theme.cursors.pointer}`};
    ${({ buttonType }) => buttonType && ButtonStyleTypes[buttonType]};
    ${({ size }) => size && ButtonStyleSizes[size]};
    ${({ icon }) => icon && buttonWithIconCss};
`;

export const StyledIconManager = styled(IconManager)<{
    isLeft?: boolean;
}>`
    margin: ${({ isLeft }) => (isLeft ? '0 10px 0 0' : '0 0 0 10px')};
`;
