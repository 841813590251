import styled, { css } from 'styled-components';
import IconManager from '@components/_universal/IconManager/IconManager';
import Select from 'react-select';
import { FieldError } from 'react-hook-form';

export const searchableLabelCss = css`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin: 8px 0 8px 25px;
    text-transform: uppercase;
`;

export const SelectWrapper = styled.div<{ marginBottom?: number }>`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin-bottom: ${({ marginBottom }) =>
        typeof marginBottom === 'number' ? marginBottom : 24}px;
`;

export const SelectLabel = styled.label<{ isSearchable?: boolean }>`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;

    ${({ isSearchable }) => isSearchable && searchableLabelCss}
`;

export const StyledSelectHolder = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    width: 100%;
`;

export const IndicatorWrapper = styled.div<{
    isOpen?: boolean;
    variant?: 'primary' | 'secondary';
}>`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 24px;

    ${({ variant }) => variant === 'secondary' && 'margin-right: 8px'}
`;

export const borderSelector = (errors?: boolean) => {
    if (errors)
        return css`
            border: 1px solid ${({ theme }) => theme.colors.error};

            &:hover {
                border: 1px solid ${({ theme }) => theme.colors.error};
            }
        `;
};

export const fillIndicator = (errors?: boolean) => {
    if (errors)
        return css`
            fill: ${({ theme }) => theme.colors.error};
        `;
    return css`
        fill: ${({ theme }) => theme.colors.border};
    `;
};

export const searchableSelectCss = css<{ errors?: FieldError }>`
    .react-select {
        &__control {
            border-radius: ${({ theme }) => theme.borderRadius.button}px;
            border: 1px solid ${({ theme }) => theme.colors.background_tertiary};
            min-height: 54px;

            &:hover {
                border: 1px solid
                    ${({ theme }) => theme.colors.background_tertiary};
            }

            ${({ errors }) => borderSelector(!!errors)};
        }
        &__menu {
            border-radius: ${({ theme }) => theme.borderRadius.button}px;
        }
    }
`;

export const secondarySelectCss = css<{ errors?: FieldError }>`
    .react-select {
        &__control {
            border: 1px solid ${({ theme }) => theme.colors.background_tertiary};
            min-height: 46px;

            &:hover {
                border: 1px solid
                    ${({ theme }) => theme.colors.background_tertiary};
            }

            ${({ errors }) => borderSelector(!!errors)};
        }

        &__value-container {
            padding: 0 16px;
        }
    }
`;

export const StyledSelect = styled(Select)<{
    dropdownHeight?: number;
    errors?: FieldError;
    isDisabled?: boolean;
    isSearchable?: boolean;
    renderInnerIcon?: boolean;
    variant?: 'primary' | 'secondary';
    width?: number;
}>`
    flex: 1;
    opacity: ${({ isDisabled }) => (isDisabled ? '0.4' : 1)};
    outline: none;
    text-align: left;
    ${({ width }) => width && `width: ${width}px`};

    ${IndicatorWrapper} {
        & > svg {
            ${({ errors }) => fillIndicator(!!errors)};
        }
    }

    .react-select {
        &__value-container {
            color: ${({ theme }) => theme.colors.text};
            font-size: ${({ theme }) => theme.fontSizes.s}px;
            line-height: 1.3;
            padding: 2px 22px;

            ${({ renderInnerIcon }) => renderInnerIcon && 'padding-left: 50px'};

            &--has-value {
                color: ${({ theme }) => theme.colors.text};
                font-weight: ${({ theme }) => theme.fontWeights.semiBold};
            }
        }

        &__single-value {
            color: ${({ theme }) => theme.colors.text};
        }

        &__control {
            background: ${({ theme }) => theme.colors.background_secondary};
            border-radius: ${({ theme }) => theme.borderRadius.default}px;
            box-shadow: none;
            caret-color: ${({ theme }) => theme.colors.primary};
            cursor: ${({ theme }) => theme.cursors.pointer};
            font-size: ${({ theme }) => theme.fontSizes.s}px;
            min-height: 45px;
            outline: none;
            text-align: left;

            &:hover {
                border: 1px solid ${({ theme }) => theme.colors.text};
            }

            ${({ width }) => width && `width: ${width}px`};
            ${({ errors }) => borderSelector(!!errors)};

            &--is-focused {
                border: 1px solid ${({ theme }) => theme.colors.text};
            }

            &--is-disabled {
                border: 1px solid ${({ theme }) => theme.colors.text};
                color: ${({ theme }) => theme.colors.text};
            }

            &--menu-is-open {
                .react-select__single-value {
                    color: ${({ theme }) => theme.colors.text_assistive};
                }

                &:hover {
                    border: 1px solid ${({ theme }) => theme.colors.text};
                }
            }
        }

        &__menu {
            background: ${({ theme }) => theme.colors.background_secondary};
            border-radius: ${({ theme }) => theme.borderRadius.default}px;
            box-shadow: ${({ theme }) => theme.dark_grey_shadow};
            font-size: ${({ theme }) => theme.fontSizes.s}px;
            list-style-type: none;
            margin-top: 4px;
            min-width: 10px;
            overflow: hidden;
            position: absolute;
            z-index: 99;
            ${({ width }) => width && `width: ${width}px`};

            &:focus {
                outline: none;
            }
        }

        &__menu-list {
            padding-bottom: 0;
            padding-top: 0;

            ${({ dropdownHeight }) =>
                dropdownHeight && `max-height: ${dropdownHeight}px`};
        }

        &__option {
            align-items: center;
            background: transparent;
            color: ${({ theme }) => theme.colors.text};
            cursor: ${({ theme }) => theme.cursors.pointer};
            display: flex;
            font-size: ${({ theme }) => theme.fontSizes.s}px;
            height: 45px;
            outline: none;
            padding-left: 22px;
            padding-right: 22px;
            text-align: left;

            &:hover {
                background: ${({ theme }) =>
                    theme.colors.background_quaternary};
            }

            &--is-focused {
                background: ${({ theme }) =>
                    theme.colors.background_quaternary};

                &:hover,
                &:focus,
                &:active {
                    background: ${({ theme }) =>
                        theme.colors.background_quaternary};
                }
            }

            &--is-selected {
                background: ${({ theme }) => theme.colors.background_secondary};
                color: ${({ theme }) => theme.colors.text};
                display: flex;
                font-weight: ${({ theme }) => theme.fontWeights.bold};
                justify-content: space-between;

                &:hover,
                &:focus,
                &:active {
                    background: ${({ theme }) =>
                        theme.colors.background_quaternary};
                }
            }
        }

        &__indicator-separator {
            display: none;
        }
    }

    ${({ isSearchable }) => isSearchable && searchableSelectCss};
    ${({ variant }) => variant === 'secondary' && secondarySelectCss}
`;

export const Icon = styled(IconManager)`
    margin-right: 6px;
`;
