import styled, { css } from 'styled-components';
import { BigHeader } from '@components/_universal/Typography.styled';
import { TileValue } from '@components/_universal/InfoTile/InfoTile.styled';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';

export const CourseInfoTileCss = css`
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    padding: 15px 16px;
    position: relative;
`;

export const TileDate = styled.div<{ userType?: USER_TYPE }>(
    ({ userType, theme: { colors } }) => css`
        background: ${colors.course};
        color: ${colors.text_white};
        margin-right: 32px;
        width: 160px;
        ${CourseInfoTileCss}
        ${userType === USER_TYPE.TEACHER &&
        css`
            background: ${colors.details_teacher};
            color: ${colors.text};
            width: 224px;
        `}
    `,
);

export const InfoTilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 48px;
`;

export const InfoTileSectionTitle = styled(BigHeader)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin: 40px 0 16px;
    `,
);

export const TileFile = styled.a(
    ({ theme: { colors } }) => css`
        background: ${colors.course};
        text-decoration: none;
        width: 256px;
        ${CourseInfoTileCss}

        ${TileValue} {
            overflow: hidden;
            padding-right: 52px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    `,
);

export const InfoTileSection = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InfoTilesContent = styled.div`
    display: flex;
`;
