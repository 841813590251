import styled from 'styled-components';
import joinBg from '@assets/images/le_join_bg.svg';
import { Button } from '@components/_universal';
import {
    BigHeader,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const JoinBoxWrapper = styled.div`
    align-items: center;
    background: url(${joinBg}) center center no-repeat;
    background-size: cover;
    border-radius: ${({ theme }) => theme.borderRadius.button}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 80px;
    overflow: hidden;
    padding: 80px 194px;
    position: relative;
`;

export const MainText = styled(BigHeader)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-align: center;
    max-width: 750px;
    margin-bottom: 16px;
`;

export const Text = styled(DefaultText)`
    text-align: center;
    margin-bottom: 32px;
`;

export const StyledButton = styled(Button)`
    min-width: 218px;
`;
