import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyled } from '@components/_global/Global.styled';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from 'src/boot/configureStore';
import { resolveEnv } from '@utils/helpers';
import { RootWrapper } from './App.styled';
import { Theme } from '@@types/CommonTypes';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import client from 'src/boot/apolloClient';
import dayjs from 'dayjs';
import GlobalModals from '@components/_modals/GlobalModals/GlobalModals';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import RootRoutes from 'src/routes/RootRoutes';
import themeVariant from '@utils/themeVariant';
import ToastNotification from '@components/ToastNotification/ToastNotification';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const App = () => {
    const { lockLayout } = useSelector((state) => state.layout);

    return (
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={resolveEnv('PUBLIC_URL')}>
                <ApolloProvider client={client}>
                    <ThemeProvider theme={themeVariant[Theme.DEFAULT]}>
                        <GlobalStyled lockLayout={lockLayout} />
                        <RootWrapper>
                            <RootRoutes />
                            <ToastNotification />
                            <GlobalModals />
                        </RootWrapper>
                    </ThemeProvider>
                </ApolloProvider>
            </BrowserRouter>
        </PersistGate>
    );
};

export default App;
