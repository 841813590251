import React from 'react';
import { setTooManyStudnetsUploadModal } from '@state/_redux/layout/actions';
import { TooManyStudentsModalWrapper } from './TooManyStudentsModal.styled';
import { useDispatch } from 'react-redux';

export interface IProps {}

const TooManyStudentsModal: React.FC<IProps> = () => {
    const dispatch = useDispatch();

    return (
        <TooManyStudentsModalWrapper
            closeOutside
            onClose={() => dispatch(setTooManyStudnetsUploadModal(false))}
            title={'Oops...'}
        >
            It looks like you uploaded a list with too many students. Edit your
            csv file or contact support.
        </TooManyStudentsModalWrapper>
    );
};

export default TooManyStudentsModal;
