import { CONTENT_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { contentTypes } from '@shared/keyToCopy';
import { IChip, Nullable } from '@@types/CommonTypes';
import { StudentActivityQueryVariables } from '@@types/graphql/StudentActivityQuery';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {
    useGetStudentForTeacherQuery,
    useStudentActivityQuery,
} from '@state/students/queries';

interface IForm {
    type: CONTENT_TYPE[];
}

const ACTIVITIES_LIMIT = 6;

const useStudentDetailsFilter = () => {
    const [paginateLoading, setPaginateLoading] = useState(false);
    const [typeFilter, setTypeFilter] = useState<IChip[]>([]);

    const { studentId } = useParams<{ studentId: string }>();
    const { control, watch } = useForm<IForm>();

    const types: IForm['type'] = watch('type');

    const { data: studentData } = useGetStudentForTeacherQuery({
        studentId: Number(studentId),
    });
    const [
        getStudentActivity,
        { data: activityData, loading, fetchMore },
    ] = useStudentActivityQuery();

    const userData = studentData?.getStudentForTeacher;
    const counts = activityData?.getStudentActivity.counts;
    const activities = activityData?.getStudentActivity.result;

    const countByType: Record<CONTENT_TYPE, number> | undefined = counts && {
        [CONTENT_TYPE.LIVE_EXPERIENCE]: counts.liveExperiencesCount,
        [CONTENT_TYPE.COURSE]: counts.coursesCount,
        [CONTENT_TYPE.STANDALONE_VIDEO]: counts.standaloneVideosCount,
    };

    let queryCount = 0;
    // calc total elements returned from query for provided content types
    counts &&
        countByType &&
        (!!types?.length
            ? types.forEach(
                  (contentName) => (queryCount += countByType[contentName]),
              )
            : (queryCount = counts.allCount));

    const hasNextPage = !!(
        activityData &&
        activities?.length &&
        queryCount > activities.length
    );

    const generateVariables = (
        page?: Nullable<number>,
    ): StudentActivityQueryVariables => ({
        input: {
            studentId: Number(studentId),
            pagination: {
                limit: ACTIVITIES_LIMIT,
                page: page ? ++page : 1,
            },
            types:
                types && types.length
                    ? types
                    : [
                          CONTENT_TYPE.LIVE_EXPERIENCE,
                          CONTENT_TYPE.STANDALONE_VIDEO,
                          CONTENT_TYPE.COURSE,
                      ],
        },
    });

    const onLoadMore = () => {
        setPaginateLoading(true);
        const page = activities
            ? Math.ceil(activities.length / ACTIVITIES_LIMIT)
            : 1;

        fetchMore?.({
            variables: generateVariables(page),
        }).then(() => {
            setPaginateLoading(false);
        });
    };

    const { liveExperiencesVisibility } = useSelector(
        (state) => state.liveExperiencesVisibility,
    );

    useEffect(() => {
        counts &&
            setTypeFilter(
                liveExperiencesVisibility
                    ? [
                          {
                              all: true,
                              count: counts.allCount,
                              text: 'All',
                              value: 'ALL',
                          },
                          {
                              count: counts.liveExperiencesCount,
                              text: contentTypes[CONTENT_TYPE.LIVE_EXPERIENCE],
                              value: CONTENT_TYPE.LIVE_EXPERIENCE,
                          },
                          {
                              count: counts.coursesCount,
                              text: contentTypes[CONTENT_TYPE.COURSE],
                              value: CONTENT_TYPE.COURSE,
                          },
                          {
                              count: counts.standaloneVideosCount,
                              text: contentTypes[CONTENT_TYPE.STANDALONE_VIDEO],
                              value: CONTENT_TYPE.STANDALONE_VIDEO,
                          },
                      ]
                    : [
                          {
                              all: true,
                              count: counts.allCount,
                              text: 'All',
                              value: 'ALL',
                          },
                          {
                              count: counts.coursesCount,
                              text: contentTypes[CONTENT_TYPE.COURSE],
                              value: CONTENT_TYPE.COURSE,
                          },
                          {
                              count: counts.standaloneVideosCount,
                              text: contentTypes[CONTENT_TYPE.STANDALONE_VIDEO],
                              value: CONTENT_TYPE.STANDALONE_VIDEO,
                          },
                      ],
            );
    }, [counts]);

    useEffect(() => {
        getStudentActivity({
            variables: generateVariables(),
        });
    }, [types]);

    return {
        activities,
        control,
        hasNextPage,
        loading,
        onLoadMore,
        paginateLoading,
        typeFilter,
        userData,
    };
};

export default useStudentDetailsFilter;
