import { gql, useMutation } from '@apollo/client';
import {
    UpdateParentAccountMutation,
    UpdateParentAccountMutationVariables,
} from '@@types/graphql/UpdateParentAccountMutation';
import { USER_ME_QUERY } from '@state/auth/queries/useUserMeQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

const UPDATE_PARENT_ACCOUNT = gql`
    mutation UpdateParentAccountMutation($input: UpdateParentAccountData!) {
        updateParentAccount(input: $input) {
            address
            city
            email
            name
            surname
            zipCode
        }
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<
        UpdateParentAccountMutation,
        UpdateParentAccountMutationVariables
    >(UPDATE_PARENT_ACCOUNT, {
        onCompleted: () => {
            onCompleted?.();
            notify('Congratulations! Changes have been successfully saved.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: () => {
            notify(
                'Unfortunately, changes haven’t been saved. Please try again later.',
            )[ToastTypes.ERROR]();
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: USER_ME_QUERY,
            },
        ],
        fetchPolicy: 'no-cache',
    });
};
