import React from 'react';
import { useHistory } from 'react-router-dom';
import LoginFooter from '@domains/Auth/_components/LoginFooter/LoginFooter';
import LoginFormBreak from '@domains/Auth/_components/LoginFormBreak/LoginFormBreak';
import LoginPasswordForm from '@domains/Auth/_forms/LoginPasswordForm/LoginPasswordForm';
import paths, { externalLinks } from '@shared/paths';
import {
    ButtonLink,
    DesktopLoginPasswordPageWrapper,
    FormWrapper,
    HelloIcon,
    LogoIcon,
    LogoLink,
} from './DesktopLoginPasswordPage.styled';

export interface IProps {}

const DesktopLoginPasswordPage: React.FC<IProps> = () => {
    const history = useHistory();

    return (
        <DesktopLoginPasswordPageWrapper>
            <LogoLink href={externalLinks.homepage} target="_blank">
                <LogoIcon
                    fill={['text_white', 'text_white']}
                    name={'LogoWithText'}
                    size={190}
                    sizeY={45}
                />
            </LogoLink>

            <FormWrapper>
                <HelloIcon size={242} sizeY={174} name={'Hello'} />
                <LoginPasswordForm />
                <LoginFormBreak />
                <ButtonLink
                    buttonType="tertiary"
                    onPress={() => history.push(paths.link_login)}
                >
                    log in without a password
                </ButtonLink>
            </FormWrapper>
            <LoginFooter />
        </DesktopLoginPasswordPageWrapper>
    );
};

export default DesktopLoginPasswordPage;
