import React, { useEffect, useState } from 'react';
import { ActivityFeedWrapper } from './ActivityFeed.styled';
import { CONTENT_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { Nullable } from '@@types/CommonTypes';
import ActivityFeedCard from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/ActivityFeedCard/ActivityFeedCard';
import dayjs from 'dayjs';
import FeedbackModal from '@domains/Teacher/Dashboard/DashboardTeacherPage/_modals/FeedbackModal/FeedbackModal';
import {
    StudentsActivityFeedQuery_getActivityFeeds_feeds,
    StudentsActivityFeedQuery_getActivityFeeds_feeds_homework,
} from '@@types/graphql/StudentsActivityFeedQuery';

export interface IProps {
    activities: StudentsActivityFeedQuery_getActivityFeeds_feeds[];
}

const getDay = (date: string) => {
    const isToday = dayjs(date).isToday();
    const isYesterday = dayjs(date).isYesterday();

    if (isToday) return 'Today';

    if (isYesterday) return 'Yesterday';

    return dayjs(date).format('ddd, DD.MM.YYYY');
};

export interface IFeedback {
    avatar?: Nullable<number>;
    contentName: string;
    contentType: CONTENT_TYPE;
    homework: StudentsActivityFeedQuery_getActivityFeeds_feeds_homework;
    userId: number;
    username: string;
}

const ActivityFeed: React.FC<IProps> = ({ activities }) => {
    const [feedbackModal, setFeedbackModal] = useState<Nullable<IFeedback>>(
        null,
    );
    const [activityCards, setActivityCards] = useState<React.ReactFragment[]>(
        [],
    );

    useEffect(() => {
        const tempActivityCards = [];

        const sortedActivities: Record<
            string,
            StudentsActivityFeedQuery_getActivityFeeds_feeds[]
        > = {};

        activities.forEach((activity) => {
            const date = getDay(activity.date);

            sortedActivities[date] = sortedActivities[date]
                ? [...sortedActivities[date], activity]
                : [activity];
        });

        for (let key in sortedActivities) {
            tempActivityCards.push(
                <ActivityFeedCard
                    activities={sortedActivities[key]}
                    key={key}
                    period={key}
                    setFeedbackModal={setFeedbackModal}
                />,
            );
        }

        setActivityCards(tempActivityCards);
    }, [activities]);

    return (
        <ActivityFeedWrapper>
            {activityCards}

            {feedbackModal && (
                <FeedbackModal
                    feedback={feedbackModal}
                    setIsModalOpen={setFeedbackModal}
                />
            )}
        </ActivityFeedWrapper>
    );
};

export default ActivityFeed;
