import React, { useRef } from 'react';
import { CSVReader } from 'react-papaparse';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes, UploadedCSVDataProps } from '@@types/CommonTypes';
import { useAllStudentsList } from '@utils/hooks/useAllStudentsList';
import { useParams } from 'react-router';
import { useStudentsListQuery } from '@state/students/queries';
import { useUpdateStudentsGroup } from '@state/students/mutations/useUpdateStudentsGroup';
import { UploadButton } from './GroupStudentsListUploader.styled';

export interface UploadStudentsItem {
    email: string;
    id: number;
    name: string;
    surname: string;
}

interface IProps {}

const STUDENTS_LIST_ROW_LENGTH = 4;

export const GroupStudentsListUploader: React.FC<IProps> = ({ children }) => {
    const { students } = useAllStudentsList();

    const { groupId } = useParams<{ groupId: string }>();

    const { data: groupStudentsListData, refetch } = useStudentsListQuery({
        pagination: {},
        search: {
            groupId: Number(groupId),
        },
    });
    const groupStudentsList = groupStudentsListData?.getStudentsList.users;

    const buttonRef = useRef<CSVReader>(null);

    const [updateStudentsGroup] = useUpdateStudentsGroup(() => {
        refetch();
    });

    const handleOpenDialog: React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    const handleUploadFile = (data: UploadedCSVDataProps[]) => {
        // remove first element (header table)
        data.splice(0, 1);

        // remove empty elements
        data = data.filter(
            (record: { data: string[] }) =>
                record.data.length === STUDENTS_LIST_ROW_LENGTH,
        );

        // parsing data and removing a student if he does not exist in Teacher's student list
        const uploadStudentsList: UploadStudentsItem[] = data
            .map((record: { data: string[] }) => ({
                email: record.data[3],
                id: +record.data[0],
                name: record.data[1],
                surname: record.data[2],
            }))
            .filter((uploadStudent: UploadStudentsItem) =>
                students?.some((student) => uploadStudent.id === student.id),
            );

        //remove duplicated students in list
        const uniqueStudentsId = new Set();
        const unduplicatedStudentsList = uploadStudentsList.filter(
            (element) => {
                const isDuplicate = uniqueStudentsId.has(element.id);

                uniqueStudentsId.add(element.id);

                return !isDuplicate;
            },
        );

        //checking if the uploaded student already exists in the current group and remove if so
        const newStudents = unduplicatedStudentsList?.filter(
            (unduplicatedStudent) =>
                !groupStudentsList?.some(
                    (student) => unduplicatedStudent.id === student.id,
                ),
        );

        !!newStudents.length &&
            updateStudentsGroup({
                variables: {
                    input: {
                        assignUsers: newStudents.map((student) => student.id),
                        groupId: +groupId,
                    },
                },
            });
    };

    const handleUploadError = (err: any) => {
        notify(err)[ToastTypes.ERROR]();
    };

    return (
        <CSVReader
            ref={buttonRef}
            onFileLoad={handleUploadFile}
            onError={handleUploadError}
            noClick
            noDrag
            config={{}}
            style={{}}
        >
            {() => (
                <div onClick={handleOpenDialog}>
                    {children ? (
                        children
                    ) : (
                        <UploadButton
                            icon={{
                                color: ['text_white'],
                                type: 'Upload',
                                size: 16,
                            }}
                        >
                            Upload a csv file
                        </UploadButton>
                    )}
                </div>
            )}
        </CSVReader>
    );
};
