import React, { useState } from 'react';
import { IconManager } from '@components/_universal';
import { Link } from 'react-router-dom';
import { useChildsListQuery } from '@state/childManagement/queries';
import Avatar from '@components/_universal/Avatar/Avatar';
import NewChildModal from '@domains/Parent/Account/_modals/NewChildModal/NewChildModal';
import paths from '@shared/paths';
import UserStatusBadge from '@components/UserStatusBadge/UserStatusBadge';
import useScrollToActiveElement from '@utils/hooks/useScrollToActiveElement';
import {
    StyledNavigationItemText,
    StyledNavigationWrapper,
    StyledParentSimpleBar,
} from './ParentNavigation.styled';
import {
    NavigationItemWrapper,
    NavigationList,
    NavigationLogoIcon,
} from '@components/_global/Navigation/Navigation.styled';

interface IProps {
    borderLine?: boolean;
}

const Navigation: React.FC<IProps> = ({ borderLine }) => {
    const { data } = useChildsListQuery();
    const [showModal, setShowModal] = useState(false);
    const { navigationRef } = useScrollToActiveElement();

    return (
        <StyledNavigationWrapper borderLine={borderLine}>
            <Link to={paths.dashboard_parent()}>
                <NavigationLogoIcon name={'Logo'} size={50} />
            </Link>

            <StyledParentSimpleBar>
                <NavigationList ref={navigationRef}>
                    {data?.getChildsForParent &&
                        data.getChildsForParent.map(
                            (
                                {
                                    avatar,
                                    deactivateAtCurrentPeriodEnd,
                                    id,
                                    name,
                                    status,
                                },
                                index,
                            ) => (
                                <NavigationItemWrapper
                                    activeClassName={'navigation-item-active'}
                                    key={index}
                                    to={paths.dashboard_parent(`${id}`)}
                                >
                                    <Avatar avatar={avatar} iconSize={40} />
                                    <StyledNavigationItemText>
                                        {name}
                                    </StyledNavigationItemText>
                                    <UserStatusBadge
                                        userStatus={status}
                                        deactivateAtCurrentPeriodEnd={
                                            deactivateAtCurrentPeriodEnd
                                        }
                                    />
                                </NavigationItemWrapper>
                            ),
                        )}
                    <NavigationItemWrapper
                        as={'button'}
                        to={'/#'}
                        disabled={data?.getChildsForParent.length === 10}
                        onClick={() => setShowModal(true)}
                    >
                        <IconManager name={'AddNew'} size={40} />
                        <StyledNavigationItemText>
                            Add new
                        </StyledNavigationItemText>
                    </NavigationItemWrapper>
                </NavigationList>
            </StyledParentSimpleBar>

            {/* MODAL ADD CHILD */}
            {showModal && (
                <NewChildModal setIsModalOpen={() => setShowModal(false)} />
            )}
        </StyledNavigationWrapper>
    );
};

export default Navigation;
