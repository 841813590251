import React, { useEffect } from 'react';
import { CONTENT_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import {
    getNotification,
    NotificationTypes,
    SendByType,
} from './notificationHelper';
import { IconManager } from '@components/_universal';
import { setReadedNotifications } from '@state/_redux/notifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useNotificationsQuery } from '@state/notifications/queries';
import dayjs from 'dayjs';
import Loader from '@components/_universal/Loader/Loader';
import {
    useReadManyNotificationsMutation,
    useReadNotificationMutation,
} from '@state/notifications/mutations';
import {
    EmptyNotificationsDescription,
    EmptyNotificationsLink,
    EmptyNotificationsText,
    EmptyNotificationsTitle,
    EmptyNotificationsWrapper,
    HeaderReadAll,
    HeaderTitle,
    Item,
    ItemContentWrapper,
    ItemDateWrapper,
    ItemDescription,
    ItemDot,
    ItemHeader,
    ItemInner,
    ItemTitle,
    LoaderWrapper,
    NotificationsHeader,
    NotificationsWrapper,
    SimpleBarWrapper,
} from './Notifications.styled';

interface IProps {
    linkToSettings?: string;
}

interface LiveExperienceType {
    _id: string;
    type: string;
    name: string;
    startDate: any;
}

const Notifications = React.forwardRef<HTMLDivElement, IProps>(
    ({ linkToSettings }, ref) => {
        const readedNotifications = useSelector(
            (state) => state.notifications.readedNotifications,
        );
        const dispatch = useDispatch();
        const history = useHistory();
        const [
            getNotificationsList,
            { called, data, loading },
        ] = useNotificationsQuery();
        const notificationsData = data?.getUserNotifications;
        const [readNotification] = useReadNotificationMutation();
        const [readManyNotifications] = useReadManyNotificationsMutation();

        const unreadNotifications = notificationsData
            ?.filter((notification) => !notification.completedAt)
            ?.map((unreadItem) => unreadItem.id);

        useEffect(() => {
            getNotificationsList();
            // eslint-disable-next-line
        }, []);

        return (
            <NotificationsWrapper {...{ ref }}>
                {loading ? (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                ) : !loading && !!notificationsData?.length ? (
                    <>
                        <NotificationsHeader>
                            <HeaderTitle>Notifications</HeaderTitle>
                            {!!unreadNotifications?.length && (
                                <HeaderReadAll
                                    onClick={() => {
                                        readManyNotifications({
                                            variables: {
                                                deliveriesIds: unreadNotifications,
                                            },
                                        });
                                        dispatch(
                                            setReadedNotifications(
                                                unreadNotifications,
                                            ),
                                        );
                                    }}
                                >
                                    Mark all as read
                                </HeaderReadAll>
                            )}
                        </NotificationsHeader>

                        <SimpleBarWrapper>
                            {notificationsData?.map((item, key) => {
                                const notification = getNotification(
                                    item.redirectionId,
                                    item.payload.content?.name,
                                    item.payload.content?.type as CONTENT_TYPE,
                                    item?.sendBy,
                                    (item.payload.content as LiveExperienceType)
                                        ?.startDate,
                                    item.payload.learner?.name,
                                    item.payload.learner?.surname,
                                )[item.type as NotificationTypes];
                                return (
                                    <Item
                                        {...{ key }}
                                        onClick={() => {
                                            if (!item.completedAt) {
                                                readNotification({
                                                    variables: {
                                                        deliveryId: item.id,
                                                    },
                                                });
                                                dispatch(
                                                    setReadedNotifications([
                                                        ...readedNotifications,
                                                        item.id,
                                                    ]),
                                                );
                                            }
                                            notification.redirect &&
                                                history.push(
                                                    notification.redirect,
                                                );
                                        }}
                                    >
                                        <ItemDot>
                                            {!item.completedAt &&
                                                !readedNotifications.find(
                                                    (notification) =>
                                                        notification ===
                                                        item.id,
                                                ) && (
                                                    <IconManager
                                                        name={'Notification'}
                                                        size={16}
                                                        sizeY={16}
                                                    />
                                                )}
                                        </ItemDot>
                                        <ItemInner>
                                            <ItemContentWrapper>
                                                <ItemHeader>
                                                    <ItemTitle>
                                                        {notification.title}
                                                    </ItemTitle>
                                                    <ItemDateWrapper>
                                                        {dayjs(
                                                            item.sendAt,
                                                        ).fromNow()}
                                                    </ItemDateWrapper>
                                                </ItemHeader>
                                                <ItemDescription>
                                                    {notification.description}
                                                </ItemDescription>
                                            </ItemContentWrapper>
                                        </ItemInner>
                                    </Item>
                                );
                            })}
                        </SimpleBarWrapper>
                    </>
                ) : (
                    <>
                        {called && (
                            <EmptyNotificationsWrapper>
                                <EmptyNotificationsTitle>
                                    No notifications yet!
                                </EmptyNotificationsTitle>
                                <EmptyNotificationsText>
                                    Stay tuned! We will let you know when there
                                    will be something new for you.
                                </EmptyNotificationsText>
                                {linkToSettings && (
                                    <EmptyNotificationsDescription>
                                        You can always turn app notifications on
                                        or off in your{' '}
                                        <EmptyNotificationsLink
                                            to={linkToSettings}
                                        >
                                            Settings
                                        </EmptyNotificationsLink>
                                        .
                                    </EmptyNotificationsDescription>
                                )}
                            </EmptyNotificationsWrapper>
                        )}
                    </>
                )}
            </NotificationsWrapper>
        );
    },
);

export default Notifications;
