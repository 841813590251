export const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export function resolveEnv(name: string): any {
    return (window as any)?.__env__?.[name] || process.env[name];
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a: any[]) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export function capitalize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export const regexTest = (reg: RegExp, value: string) => {
    if (reg.test(value)) return true;
    return false;
};

export const isBetween = (value: number, a: number, b: number) =>
    value > Math.min(a, b) && value < Math.max(a, b);

export const objectsEqual = (o1: any, o2: any) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

export const compareArrays = (a: any, b: any) =>
    a.length === b.length &&
    a.every((o: any, idx: any) => objectsEqual(o, b[idx]));
