import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { IconManager } from '@components/_universal';

export const ChoosePlanInfoModalWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.bubble}px;
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.7;
    margin: 0 auto;
    max-width: 500px;
    padding: 58px 32px;
    position: relative;
    width: calc(100% - 32px);

    @media (max-width: ${breakpoints.xs}px) {
        padding: 58px 16px;
    }
`;

export const CloseButton = styled(IconManager)`
    position: absolute;
    right: 16px;
    top: 16px;
`;
