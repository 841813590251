import * as yup from 'yup';
import {
    emailValidation,
    textValidation,
} from '@utils/shared/validations/formErrors';

export const validation = yup.object().shape({
    email: emailValidation,
    name: textValidation,
    surname: textValidation,
});
