import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import {
    DefaultText,
    SubHeader,
    Title,
} from '@components/_universal/Typography.styled';

export const PaymentDetailsWrapper = styled.div``;

export const PageTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 48px;
`;

export const SectionRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
`;

export const SectionInfo = styled.div``;

export const SectionActions = styled.div`
    margin-left: 48px;
`;

export const SectionPaymentInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 400px;
`;

export const SectionSubTitle = styled(SubHeader)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 0 4px 0;
`;

export const SectionHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const SectionLabel = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 0 4px 0 0;
`;

export const SectionText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 0;
    max-width: 400px;
`;

export const BolderText = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const ActionButton = styled(Button)`
    height: 40px;
    justify-self: flex-end;
    margin-left: auto;
    min-width: 198px;
    width: 100%;
`;

export const CardInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const CardNumber = styled(DefaultText)`
    align-items: center;
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 0 auto 0 0;
    width: 200px;
`;

export const CardIcon = styled(IconManager)`
    margin-right: 8px;
`;

export const ExpiredDate = styled.div`
    align-items: center;
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
`;
