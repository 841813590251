import React from 'react';
import { DescriptionBox } from '@components/_universal/DescriptionBox/DescriptionBox';
import { useChildLiveExperienceQuery } from '@state/childManagement/queries';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import DetailsInfo from '@domains/Parent/LiveExperience/_components/DetailsInfo/DetailsInfo';
import Loader from '@components/_universal/Loader/Loader';
import {
    DividedContainer,
    LeftBox,
    LiveExperienceParentPageWrapper,
    PageTitle,
    RightBox,
    SectionHeader,
} from './LiveExperienceParentPage.styled';

const LiveExperienceParentPage = () => {
    const { liveExperienceId } = useParams<{
        liveExperienceId: string;
    }>();
    const { data, loading } = useChildLiveExperienceQuery(liveExperienceId);

    return (
        <LiveExperienceParentPageWrapper>
            <PageTitle>Experience details</PageTitle>
            <SectionHeader>
                {loading ? <Loader center /> : data?.getLiveExperience.name}
            </SectionHeader>
            {!loading && (
                <DividedContainer>
                    <LeftBox>
                        <DescriptionBox title="Description">
                            {data?.getLiveExperience.description}
                        </DescriptionBox>
                    </LeftBox>
                    <RightBox>
                        <DetailsInfo
                            duration={`${
                                data?.getLiveExperience.duration ?? '-'
                            } hours`}
                            mentor={`${
                                data?.getLiveExperience.facilitatedBy.name ??
                                '-'
                            }`}
                            schedule={`${
                                dayjs(data?.getLiveExperience.startDate).format(
                                    'MMM DD.MM.YYYY | h:mm a',
                                ) ?? '-'
                            }`}
                        />
                    </RightBox>
                </DividedContainer>
            )}
        </LiveExperienceParentPageWrapper>
    );
};

export default LiveExperienceParentPage;
