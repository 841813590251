import * as React from 'react';
import {
    PaginationButtonNext,
    PaginationButtonPrev,
    PaginationWrapper,
    StyledPagination,
} from './Pagination.styled';
interface PaginationProps {
    canNextPage: boolean;
    canPreviousPage: boolean;
    gotoPage: (pageIndex: number) => void;
    pageCount?: number;
    pageIndex: number;
    tablePages?: number;
}

const Pagination = ({
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount = 1,
    pageIndex,
}: PaginationProps) => {
    return (
        <PaginationWrapper>
            {canPreviousPage && (
                <PaginationButtonPrev
                    buttonType={'secondary'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        isLeft: true,
                        type: 'ChevronLeft',
                    }}
                    onPress={() => gotoPage(pageIndex - 1)}
                    size={'small'}
                >
                    Prev
                </PaginationButtonPrev>
            )}
            <StyledPagination
                color="primary"
                count={pageCount}
                hidePrevButton
                hideNextButton
                onChange={(
                    event: React.ChangeEvent<unknown>,
                    value: number,
                ) => {
                    gotoPage(value - 1);
                }}
                shape="rounded"
                page={pageIndex + 1}
            />
            {canNextPage && (
                <PaginationButtonNext
                    buttonType={'secondary'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        type: 'ChevronRight',
                    }}
                    onPress={() => gotoPage(pageIndex + 1)}
                    size={'small'}
                >
                    Next
                </PaginationButtonNext>
            )}
        </PaginationWrapper>
    );
};

export default Pagination;
