import { SelectType } from '@@types/CommonTypes';
import {
    CONTENT_TYPE,
    LIBRARY_VIEW_CONTENT_SORTING,
} from '@@types/graphql/GraphqlGlobalTypes';
import { sortBy } from '@shared/filters';

export type FiltersModule = {
    library: {
        sortBy: SelectType<LIBRARY_VIEW_CONTENT_SORTING>;
        type: CONTENT_TYPE[];
        topic: string[];
    };
};

export const initialState: FiltersModule = {
    library: {
        sortBy: sortBy[0],
        type: [],
        topic: [],
    },
};
