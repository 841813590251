import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import Select from '@components/_form-elements/Select/Select';

export const SchoolFiltersWrapper = styled.div`
    display: flex;
`;

export const FilterElement = styled.div`
    display: flex;
    align-items: center;

    & + & {
        margin-left: 24px;
    }
`;

export const FilterName = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        margin: 0 16px 0 0;
    `,
);

export const FilterSelect = styled(Select)`
    width: 132px;
`;
