import React from 'react';
import Pagination from '@components/_universal/Table/Pagination/Pagination';
import { TableInstance } from 'react-table';

type ChildrenArgs = Pick<
    TableInstance<any>,
    | 'getTableProps'
    | 'getTableBodyProps'
    | 'headerGroups'
    | 'prepareRow'
    | 'page'
    | 'rows'
>;

interface IProps<T extends object> {
    children: (args: ChildrenArgs) => React.ReactNode;
    table: TableInstance<T>;
}

const Table = <T extends object>({ children, table }: IProps<T>) => {
    const {
        canNextPage,
        canPreviousPage,
        getTableBodyProps,
        getTableProps,
        gotoPage,
        headerGroups,
        page,
        pageCount,
        prepareRow,
        rows,
        state: { pageIndex },
    } = table;

    return (
        <>
            {children({
                getTableBodyProps,
                getTableProps,
                headerGroups,
                page,
                prepareRow,
                rows,
            })}
            {!!pageCount && (
                <Pagination
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    gotoPage={gotoPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                />
            )}
        </>
    );
};

export default Table;
