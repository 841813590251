import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const CompletedCoursesWrapper = styled.div`
    background: ${({ theme }) => theme.colors.background_primary};
    border-radius: ${({ theme }) =>
        `0 ${theme.borderRadius.button}px ${theme.borderRadius.button}px 0`};
    margin-right: 120px;
    padding: 80px 100px 120px 100px;
`;

export const TitleWrapper = styled.div<{ withMosaic?: boolean }>`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ withMosaic }) => (withMosaic ? 40 : 16)}px;
`;

export const TitleIcon = styled(IconManager)`
    right: -110px;
    position: absolute;
    top: -40px;
`;

export const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
    position: relative;
`;

export const TextWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export const Description = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
`;

export const MagnetIcon = styled(IconManager)`
    margin-left: 24px;
`;
