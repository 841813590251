import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import {
    CardImage,
    CardWrapper,
    DEFAULT_CARD_HEIGHTS,
    DEFAULT_CARD_WIDTHS,
} from '@components/_universal/Cards/Cards.styled';

const horizontalWrapperCss = css`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    min-height: ${DEFAULT_CARD_HEIGHTS.splitHorizontal}px;
`;

const horizontalTextCss = css`
    margin-top: 16px;
    text-align: left;
`;

export const SplitCardWrapper = styled(CardWrapper)<{
    isHorizontal?: boolean;
}>`
    display: grid;
    grid-template-rows: 200px 1fr;
    min-height: ${DEFAULT_CARD_HEIGHTS.split}px;
    width: 100%;

    ${({ isHorizontal }) => isHorizontal && horizontalWrapperCss}
`;

export const SplitCardImage = styled(CardImage)`
    width: 100%;
`;

export const SplitCardText = styled(DefaultText)<{ isHorizontal?: boolean }>`
    align-items: center;
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 20px;
    margin: 0;
    padding: 24px;
    text-align: center;

    ${({ isHorizontal }) => isHorizontal && horizontalTextCss}
`;
