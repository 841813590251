import { FragmentSaveForLaterMutation } from '@@types/graphql/FragmentSaveForLaterMutation';
import { gql, useMutation } from '@apollo/client';
import client from '@boot/apolloClient';
import {
    SaveForLaterMutation,
    SaveForLaterMutationVariables,
} from '@@types/graphql/SaveForLaterMutation';

const SAVE_FOR_LATER_MUTATION = gql`
    mutation SaveForLaterMutation($contentId: String!) {
        saveForLater(contentId: $contentId) {
            __typename
        }
    }
`;

type ContentType = 'Course' | 'LiveExperience' | 'StandaloneVideo';

const getFragmet = (contentType: ContentType) => {
    if (contentType === 'Course')
        return gql`
            fragment FragmentSaveForLaterMutation on Course {
                isSavedForLater
            }
        `;
    if (contentType === 'LiveExperience')
        return gql`
            fragment FragmentSaveForLaterMutation on LiveExperience {
                isSavedForLater
            }
        `;
    return gql`
        fragment FragmentSaveForLaterMutation on StandaloneVideo {
            isSavedForLater
        }
    `;
};

export default (contentId: string, contentType: ContentType) => {
    return useMutation<SaveForLaterMutation, SaveForLaterMutationVariables>(
        SAVE_FOR_LATER_MUTATION,
        {
            onCompleted: () => {
                client.writeFragment<FragmentSaveForLaterMutation>({
                    id: `${contentType}:${contentId}`,
                    fragment: getFragmet(contentType),
                    data: {
                        isSavedForLater: true,
                    },
                });
            },
            onError: () => {},
        },
    );
};
