import React from 'react';
import {
    ButtonWrapper,
    ExperienceManIcon,
    StyledButton,
    DescriptionText,
    OngoingExperienceWrapper,
    OngoingExperienceDescription,
    LightDescriptionText,
    OngoingMessageIcon,
} from '@domains/Child/LiveExperience/_components/ExperienceInfo/ExperienceInfo.styled';

interface IProps {
    className?: string;
    link: string;
}

export const SignUpExperience: React.FC<IProps> = ({ className, link }) => {
    return (
        <OngoingExperienceWrapper className={className}>
            <ExperienceManIcon
                name={'ExperienceInfoMan'}
                size={143}
                sizeY={139}
            />
            <OngoingMessageIcon name={'OngoingMessage'} size={344} sizeY={59} />
            <OngoingExperienceDescription>
                <DescriptionText>
                    Hit the join button below to meet your facilitator and get
                    stuck in.{' '}
                    <LightDescriptionText>
                        Remember, we will be recording the class so keep your
                        video and audio feeds appropriate.
                    </LightDescriptionText>
                </DescriptionText>
                <ButtonWrapper>
                    <StyledButton onPress={() => window.open(link)}>
                        Join
                    </StyledButton>
                </ButtonWrapper>
            </OngoingExperienceDescription>
        </OngoingExperienceWrapper>
    );
};

export default SignUpExperience;
