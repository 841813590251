import { EnvType } from '@@types/CommonTypes';
import { resolveEnv } from '@utils/helpers';

export const __DEV__ = process.env.NODE_ENV !== EnvType.production;
export const API_URL = resolveEnv('REACT_APP_GRAPHQL');
export const ADMIN_URL = resolveEnv('REACT_APP_ADMIN_PUBLIC_URL');
export const MENTOR_URL = resolveEnv('REACT_APP_MENTOR_PUBLIC_URL');

export const DESKTOP_VIEW = 1280;

export const breakpoints = {
    xs: 575,
    sm: 767,
    md: 991,
    lg: 1279,
};

export const MAX_HOMEWORK_SIZE = 536870912; // 512 MiB

export const FEEDBACK_VIDEO_MIME_TYPES = [
    'video/mp4',
    'video/webm',
    'video/mpeg',
    'video/h264',
    'video/x-m4v',
    'video/x-matroska',
    'video/quicktime',
];

export const HOMEWORK_MIME_TYPES = [
    // Images
    'image/jpeg',
    'image/png',
    'image/vnd.adobe.photoshop',
    'image/gif',
    // Video
    'video/mp4',
    'video/webm',
    'video/mpeg',
    'video/h264',
    'video/x-m4v',
    'video/x-matroska',
    'video/quicktime',
    // Audio
    'audio/mpeg',
    'audio/x-ms-wma',
    'audio/webm',
    'audio/wav',
    '.mpa',
    // File
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroenabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/postscript',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.formula',
    'application/rtf',
    'application/zip',
    'application/x-zip-compressed',
    'application/pkcs8',
    // Text
    'text/plain',
    'text/csv',
    '.csv',
];
