import { GetVideoLinkQuery } from '@@types/graphql/GetVideoLinkQuery';
import { IconManager } from '../index';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import Loader from '@components/_universal/Loader/Loader';
import {
    InnerVideoPlayerWrapper,
    StyledIframe,
} from '@components/_universal/commonStyles.styled';
import {
    VideoPlaceholder,
    VideoThumbnailWrapper,
} from './VideoThumbnail.styled';

interface VideoThumbnailProps {
    aboutVideoData?: GetVideoLinkQuery;
    aboutVideoLoading: boolean;
    isVideoPlayed: boolean;
    setIsVideoPlayed: (value: boolean) => void;
    userType?: USER_TYPE;
}

export const VideoThumbnail = ({
    aboutVideoData,
    aboutVideoLoading,
    isVideoPlayed,
    setIsVideoPlayed,
    userType = USER_TYPE.CHILD,
}: VideoThumbnailProps) => {
    return (
        <VideoThumbnailWrapper userType={userType}>
            <InnerVideoPlayerWrapper>
                {isVideoPlayed && !aboutVideoLoading ? (
                    <StyledIframe
                        allowFullScreen
                        id={'about_video'}
                        scrolling="no"
                        src={aboutVideoData?.getVideoById.link}
                        withBackground
                    />
                ) : (
                    <VideoPlaceholder onClick={() => setIsVideoPlayed(true)}>
                        {isVideoPlayed ? (
                            <Loader size={'medium'} fill={['text_white']} />
                        ) : (
                            <IconManager name="LightPlay" size={30} />
                        )}
                    </VideoPlaceholder>
                )}
            </InnerVideoPlayerWrapper>
        </VideoThumbnailWrapper>
    );
};
