import styled from 'styled-components';
import { BigHeader, SubTitle } from '@components/_universal/Typography.styled';

export const SubmitWorkWrapper = styled.div``;

export const InnerWrapper = styled.div`
    display: flex;
    margin-bottom: 80px;
`;

export const UploadedFiles = styled.div`
    display: flex;
    flex-direction: column;
    flex: 4;
    margin-right: 32px;
`;

export const MentorsFeedback = styled.div`
    display: flex;
    flex-direction: column;
    flex: 5;
`;

export const Subtitle = styled(BigHeader)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 16px;
`;

export const Title = styled(SubTitle)`
    margin: 80px 0 40px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
