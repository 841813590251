import React from 'react';
import { RouteProps } from 'react-router-dom';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { authRoutes } from './specific/authRoutes';
import { contentConsumerRoutes } from './specific/contentConsumerRoutes';
import { commonRoutes } from './specific/commonRoutes';
import { parentRoutes } from './specific/parentRoutes';
import { subscriptionRoutes } from './specific/subscriptionRoutes';
import { teacherRoutes } from './specific/teacherRoutes';

interface IRouteConfig extends RouteProps {
    path: string;
}
export interface IMainRouteConfig extends IRouteConfig {
    subRoutes?: IAppSubRoutes;
}
export interface IAppRoutes {
    allowFor?: USER_TYPE | USER_TYPE[];
    isPrivate?: boolean;
    layoutComponent: React.ElementType;
    routes?: IMainRouteConfig[];
    type?: 'invalid' | 'onboarding' | 'signUpProcess';
}

export interface IAppSubRoutes {
    layoutComponent: React.ElementType;
    routes?: IRouteConfig[];
}

export type RouterConfig = IAppRoutes[];

const routerConfig: RouterConfig = [
    ...authRoutes,
    ...subscriptionRoutes,
    ...parentRoutes,
    ...contentConsumerRoutes,
    ...teacherRoutes,
    ...commonRoutes,
];

export default routerConfig;
