import React, { useState } from 'react';
import { BillingCycle } from '@@types/graphql/GraphqlGlobalTypes';
import { IChildsList } from '@domains/Subscription/ChooseYourPlanPage/ChooseYourPlanPage';
import { IconManager } from '@components/_universal';
import { SwiperSlide } from 'swiper/react';
import { useMatchMedia } from '@utils/hooks';
import Carousel from '@components/_universal/Carousel/Carousel';
import ChildrenList from '@domains/Subscription/_components/ChildrenList/ChildrenList';
import ChoosePlanInfoModal from '@domains/Subscription/_modals/ChoosePlanInfoModal/ChoosePlanInfoModal';
import {
    BulbIcon,
    ChildrenListHeader,
    ChildrenListIconWrapper,
    ChildrenTitle,
    ChoosePlanChildren,
    ChoosePlanContent,
    ChoosePlanHeader,
    ChoosePlanReminder,
    ChoosePlanReminderText,
    ChoosePlanReminderTextWrapper,
    ChoosePlanTexts,
    ChooseTrialLeftInformation,
    Discount,
    MobileSubscriptionPageWrapper,
    MobileSubscriptionWrapper,
    MobileTip,
    StyledChoosePayment,
    StyledDescription,
    StyledTitle,
    TrialInformationWrapper,
} from './MobileChooseYourPlanPage.styled';

export interface IProps {
    checkedChildren: number;
    childrenList: IChildsList[];
    handleChange: (id: number, value: boolean) => void;
    handleSubscribe: (billingCycle: BillingCycle) => void;
    setAddChildModal: (state: boolean) => void;
}

const MobileMobileSignupSubscriptionPage: React.FC<IProps> = ({
    checkedChildren,
    childrenList,
    handleChange,
    handleSubscribe,
    setAddChildModal,
}) => {
    const [choosePlanInfoModal, setChoosePlanInfoModal] = useState(false);
    const { match } = useMatchMedia('sm');

    return (
        <MobileSubscriptionPageWrapper>
            {choosePlanInfoModal && (
                <ChoosePlanInfoModal setIsModalOpen={setChoosePlanInfoModal} />
            )}

            <TrialInformationWrapper>
                <ChooseTrialLeftInformation variant="big-green" withoutAction />
            </TrialInformationWrapper>
            <ChoosePlanHeader>
                <ChoosePlanTexts>
                    <StyledTitle color="text_white" fontWeight="semiBold">
                        Choose your plan
                    </StyledTitle>
                    <StyledDescription
                        color="text_white"
                        fontSize="l"
                        fontWeight="semiBold"
                    >
                        To stay with us after the free trial period, subscribe
                        to our platform.
                    </StyledDescription>
                </ChoosePlanTexts>
            </ChoosePlanHeader>
            <ChoosePlanContent>
                <ChoosePlanChildren>
                    <ChildrenListHeader>
                        <ChildrenTitle color="text_white" fontWeight="semiBold">
                            Your children accounts:
                        </ChildrenTitle>
                        <ChildrenListIconWrapper
                            onClick={() => setChoosePlanInfoModal(true)}
                        >
                            <IconManager
                                cursor="pointer"
                                name="InfoIcon"
                                size={38}
                            />
                        </ChildrenListIconWrapper>
                    </ChildrenListHeader>

                    <ChildrenList
                        childrenList={childrenList}
                        handleChange={handleChange}
                        setAddChildModal={setAddChildModal}
                    />

                    <ChoosePlanReminder>
                        <ChoosePlanReminderText>
                            <ChoosePlanReminderTextWrapper>
                                Remember,
                            </ChoosePlanReminderTextWrapper>{' '}
                            with the annual plan you
                        </ChoosePlanReminderText>
                        <Discount>Get 4 months free</Discount>
                    </ChoosePlanReminder>
                </ChoosePlanChildren>
                <MobileSubscriptionWrapper>
                    <Carousel
                        disabled={!match}
                        withArrows={false}
                        spaceBetween={16}
                    >
                        <SwiperSlide style={{ width: 320 }}>
                            <StyledChoosePayment
                                currentValue={320}
                                handleSubscribe={handleSubscribe}
                                paymentType={BillingCycle.YEAR}
                                readOnlyChildren={checkedChildren}
                            >
                                Annual
                            </StyledChoosePayment>
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 320 }}>
                            <StyledChoosePayment
                                buttonType={'secondary'}
                                handleSubscribe={handleSubscribe}
                                iconFill={['border']}
                                readOnlyChildren={checkedChildren}
                            >
                                Monthly
                            </StyledChoosePayment>
                        </SwiperSlide>
                    </Carousel>
                </MobileSubscriptionWrapper>
            </ChoosePlanContent>
        </MobileSubscriptionPageWrapper>
    );
};

export default MobileMobileSignupSubscriptionPage;
