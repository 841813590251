import React, { useState } from 'react';
import {
    ButtonWrapper,
    ExclamationMarkIcon,
    ExperienceManIcon,
    InfoText,
    SignedUpExperienceWrapper,
    StyledBigHeader,
    StyledButton,
    StyledCounter,
} from '@domains/Child/LiveExperience/_components/ExperienceInfo/ExperienceInfo.styled';
import CancelSignedUpModal from '@domains/Child/LiveExperience/_modals/CancelSignedUpModal/CancelSignedUpModal';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useGetLiveExperienceQuery } from '@state/liveExperience/queries';

interface IProps {
    className?: string;
    onTimerComplete: () => void;
}

export const SignedUpExperience: React.FC<IProps> = ({
    className,
    onTimerComplete,
}) => {
    const [showModal, setShowModal] = useState(false);
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();
    const { data } = useGetLiveExperienceQuery(liveExperienceId);
    const startDate =
        data?.getLiveExperience.startDate &&
        dayjs(data?.getLiveExperience.startDate);

    const [showButton, setShowButton] = useState(
        startDate?.diff(dayjs()) > 0 ?? false,
    );

    return (
        <SignedUpExperienceWrapper className={className}>
            <ExperienceManIcon
                name={'ExperienceInfoMan'}
                size={143}
                sizeY={139}
            />
            <StyledBigHeader>This experience will start in...</StyledBigHeader>
            <StyledCounter
                onComplete={() => {
                    onTimerComplete();
                    setShowButton(false);
                }}
            />
            <ButtonWrapper isMoreMargin>
                {showButton && (
                    <>
                        <ExclamationMarkIcon
                            name={'ExclamationMark'}
                            size={69}
                            sizeY={71}
                        />
                        <InfoText>If you wont be able to participate</InfoText>

                        <StyledButton
                            buttonType={'secondary'}
                            onPress={() => setShowModal(true)}
                            size={'small'}
                        >
                            Cancel reservation
                        </StyledButton>
                    </>
                )}
            </ButtonWrapper>

            {showModal && (
                <CancelSignedUpModal
                    setIsModalOpen={() => setShowModal(false)}
                />
            )}
        </SignedUpExperienceWrapper>
    );
};

export default SignedUpExperience;
