import { gql, useQuery } from '@apollo/client';
import { NotificationLiveExperiences } from '@@types/graphql/NotificationLiveExperiences';

export const NOTIFICATION_LIVE_EXPERIENCES = gql`
    query NotificationLiveExperiences {
        ids: notificationLiveExperiences @client
    }
`;

export const useNotificationLiveExperiences = () =>
    useQuery<NotificationLiveExperiences>(NOTIFICATION_LIVE_EXPERIENCES, {
        onError: (error) => {
            console.error('useNotificationLiveExperiences::', error);
        },
    });
