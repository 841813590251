import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { Button, IconManager } from '@components/_universal';

export const ExperiencesWrapper = styled.div`
    background: ${({ theme }) => theme.colors.background_primary};
    border-radius: ${({ theme }) =>
        `${theme.borderRadius.button}px 0 0 ${theme.borderRadius.button}px`};
    padding: 80px 100px 120px;
    position: relative;
`;

export const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 24px;
`;

export const Description = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 32px;
    max-width: 544px;
`;

export const ButtonWrapper = styled.div``;

export const StyledButton = styled(Button)``;

export const CoursesOrnamentIcon = styled(IconManager)`
    right: 200px;
    position: absolute;
    top: -95px;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 80px;
    padding-right: 120px;
    width: 100%;
`;
