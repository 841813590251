import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';

export const DesktopLoginPasswordPageWrapper = styled.div`
    align-items: center;
    align-self: center;
    background: rgba(81, 91, 230, 0.95);
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 910px;
    min-height: 840px;
    overflow: hidden;
    padding-top: 54px;
    width: 100%;
`;

export const LogoIcon = styled(IconManager)``;

export const LogoLink = styled.a``;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    width: 554px;
    margin-top: -100px;

    @media (max-height: 1100px) {
        justify-content: flex-start;
        padding-top: 76px;
    }
`;

export const HelloIcon = styled(IconManager)`
    left: -10px;
    position: relative;
`;

export const ButtonLink = styled(Button)`
    margin: 24px 0 32px;
    width: 100%;
`;
