import React from 'react';
import { CountDownRenderer } from './CountDownRenderer/CountDownRenderer';
import { CountDownRendererWhite } from './CountDownRenderer/CountDownRendererWhite';
import { useGetLiveExperienceQuery } from '@state/liveExperience/queries';
import { useParams } from 'react-router-dom';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import Countdown from 'react-countdown';
import dayjs from 'dayjs';
import { CounterWrapper } from './Counter.styled';

interface CounterProps {
    className?: string;
    onComplete?: () => void;
    userType?: USER_TYPE;
}

export const Counter = ({
    className,
    onComplete,
    userType = USER_TYPE.CHILD,
}: CounterProps) => {
    const { liveExperienceId } = useParams<{ liveExperienceId: string }>();
    const { data, loading } = useGetLiveExperienceQuery(liveExperienceId);
    const date =
        data?.getLiveExperience.startDate &&
        dayjs(data?.getLiveExperience.startDate).toDate();
    return (
        <CounterWrapper className={className}>
            {!loading && (
                <Countdown
                    autoStart
                    date={date}
                    onComplete={onComplete}
                    renderer={
                        userType === USER_TYPE.TEACHER
                            ? CountDownRendererWhite
                            : CountDownRenderer
                    }
                />
            )}
        </CounterWrapper>
    );
};
