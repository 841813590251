import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { FieldTypes } from '@@types/CommonTypes';
import { IconManager } from '@components/_universal';
import {
    InputInnerWrapper,
    InputWrapper,
    Label,
    RevealButton,
    StyledInput,
} from './InputPassword.styled';

export type InputTypes = 'default' | 'secondary';

interface IProps {
    backgroundTypeError?: boolean;
    className?: string;
    disabled?: boolean;
    errors?: FieldError;
    inputType?: InputTypes;
    isValid?: boolean;
    label?: string;
    marginBottom?: number;
    maxLength?: number;
    name: string;
    placeholder?: string;
    register?: any;
    staticPosition?: boolean;
    type?: FieldTypes;
}

const InputPassword: React.FC<IProps> = ({
    backgroundTypeError,
    className,
    disabled,
    errors,
    inputType = 'default',
    isValid,
    label,
    marginBottom,
    maxLength,
    name,
    placeholder,
    register,
    staticPosition,
    type = 'text',
}) => {
    const [isRevealPassword, setIsRevealPassword] = useState(false);

    return (
        <InputWrapper
            {...{ backgroundTypeError, className, isValid, marginBottom }}
        >
            {label && <Label {...{ inputType }}>{label}</Label>}
            <InputInnerWrapper>
                <StyledInput
                    disabled={disabled}
                    errors={!!errors}
                    maxLength={maxLength}
                    name={name}
                    placeholder={placeholder}
                    ref={register}
                    type={isRevealPassword ? 'text' : 'password'}
                />
                <RevealButton
                    onClick={() => setIsRevealPassword((state) => !state)}
                    tabIndex={-1}
                    type={'button'}
                >
                    <IconManager
                        name={
                            isRevealPassword
                                ? 'IconHiddenEye'
                                : 'IconVisibleEye'
                        }
                        size={25}
                    />
                </RevealButton>
            </InputInnerWrapper>
        </InputWrapper>
    );
};

export default InputPassword;
