import { useSynchronizeRef } from '@utils/hooks/useSynchronizeRefs';
import { forwardRef } from 'react';
import { CheckboxInput, CheckboxWrapper } from './IndeterminateCheckbox.styled';

interface IndeterminateCheckboxProps {
    indeterminate?: boolean;
    name: string;
}

export const IndeterminateCheckbox = forwardRef<
    HTMLInputElement,
    IndeterminateCheckboxProps
>(({ indeterminate = false, name, ...rest }, ref) => {
    const synchronizeRefs = useSynchronizeRef(ref, indeterminate);

    return (
        <CheckboxWrapper>
            <CheckboxInput
                {...rest}
                id={name}
                name={name}
                type="checkbox"
                ref={synchronizeRefs}
            />
        </CheckboxWrapper>
    );
});
