import React, { useState } from 'react';
import { CreateGroupModal } from '@domains/Teacher/Groups/_modals/CreateGroupModal/CreateGroupModal';
import { SelectStudentsGroup } from '@components/SelectStudentsGroup/SelectStudentsGroup';
import { useContentRecommend } from '@domains/Teacher/Library/LibraryTeacherPage/_modals/RecommendTheContentModal/useContentRecommend';
import {
    BoldMessage,
    ClickText,
    InfoSelect,
    NormalMessage,
} from '@components/SelectStudentsGroup/SelectStudentsGroup.styled';
import {
    ButtonWrapper,
    NotifyButton,
    NotifyForm,
    NotifyWrapper,
    StyledTitle,
} from './NotifyBox.styled';

export const NotifyBox = () => {
    const {
        control,
        errors,
        groups,
        handleNotifyAboutLE,
        handleSubmit,
        register,
        reset,
    } = useContentRecommend();

    const handleRecommendSubmit = () => {
        handleNotifyAboutLE();
        reset({ group: [] });
    };
    const [showGroupModal, setShowGroupModal] = useState(false);

    const noOptionsMessage = () => {
        return (
            <InfoSelect>
                <BoldMessage>
                    It seems there are no students groups created yet.
                </BoldMessage>
                <NormalMessage>
                    <ClickText onClick={() => setShowGroupModal(true)}>
                        Click here
                    </ClickText>{' '}
                    to create the first group.
                </NormalMessage>
            </InfoSelect>
        );
    };

    return (
        <NotifyWrapper>
            <NotifyForm
                noValidate={true}
                onSubmit={handleSubmit(handleRecommendSubmit)}
            >
                <StyledTitle>
                    Please select a students group you would like to notify.
                </StyledTitle>
                <SelectStudentsGroup
                    control={control}
                    errors={errors.group}
                    noOptionsMessage={() => noOptionsMessage()}
                    options={groups}
                    register={register}
                />
                <ButtonWrapper>
                    <NotifyButton>notify students</NotifyButton>
                </ButtonWrapper>
            </NotifyForm>
            {showGroupModal && (
                <CreateGroupModal setIsModalOpen={setShowGroupModal} />
            )}
        </NotifyWrapper>
    );
};
