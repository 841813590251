import styled from 'styled-components';
import IconManager from '@components/_universal/IconManager/IconManager';
import MosaicContent from '@components/MosaicContent/MosaicContent';
import { BigHeader } from '@components/_universal/Typography.styled';

export const SearchModalWrapper = styled.div`
    background: ${({ theme }) => theme.colors.secondary};
    height: 100vh;
    overflow-y: auto;
    padding: 172px 160px 110px;
    position: fixed;
    width: 100%;
    z-index: 99;
`;

export const SearchModalTitle = styled(BigHeader)`
    font-size: ${({ theme }) => theme.fontSizes.max}px;
    margin-bottom: 22px;
`;

export const SearchImage = styled(IconManager)`
    bottom: 85px;
    position: fixed;
    right: 120px;
`;

export const SearchResults = styled(MosaicContent)`
    margin-top: 90px;
`;
