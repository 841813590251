import { useState } from 'react';
import { DeleteGroupModal } from '@domains/Teacher/Groups/GroupPage/_modals/DeleteGroupModal/DeleteGroupModal';
import { GroupToDelete } from '@domains/Teacher/Groups/GroupsTeacherPage/_components/GroupsList/GroupsList';
import { useParams } from 'react-router';
import { useSchoolLicensesQuery } from '@state/teachers/queries';
import { SchoolFilters } from '@components/SchoolFilters/SchoolFilters';
import {
    ButtonsWrapper,
    GroupButton,
    HeaderText,
    TableHeaderInner,
    TableHeaderWrapper,
    TableInfoWrapper,
} from '@components/_universal/Table/Table.styled';
import {
    ButtonsInnerWrapper,
    DeleteButton,
    LicenceBold,
    LicencesInfo,
    Separator,
    StyledButton,
} from './TableOptions.styled';

interface ButtonProps {
    onClick: (value: boolean) => void;
    title: string;
}

export interface TableOptionsProps {
    deleteButtonProps?: ButtonProps;
    editButtonProps?: ButtonProps;
    firstButtonProps: ButtonProps;
    getFilters: (className?: string, groupId?: number, year?: string) => void;
    groupName?: string;
    isGroupView: boolean;
    removeStudents: (value: boolean) => void;
    secondButtonProps: ButtonProps;
    showGroupFilter?: boolean;
    showRemoveStudentsButton?: boolean;
    showSelectButton?: boolean;
    title: string;
}

export const TableOptions = ({
    deleteButtonProps,
    editButtonProps,
    firstButtonProps,
    getFilters,
    groupName,
    isGroupView,
    removeStudents,
    secondButtonProps,
    showGroupFilter,
    showRemoveStudentsButton,
    showSelectButton,
    title,
}: TableOptionsProps) => {
    const { groupId } = useParams<{ groupId: string }>();
    const { data, loading } = useSchoolLicensesQuery();

    const [groupToDelete, setGroupToDelete] = useState<GroupToDelete | null>(
        null,
    );

    return (
        <TableHeaderWrapper>
            <TableHeaderInner>
                <TableInfoWrapper>
                    <HeaderText>{title}</HeaderText>
                    {!isGroupView && (
                        <>
                            <Separator />
                            <LicencesInfo>
                                licences:{' '}
                                <LicenceBold>
                                    {(!loading &&
                                        data?.getSchool.licensesInUse) ??
                                        0}
                                </LicenceBold>{' '}
                                of
                                <LicenceBold>
                                    {(!loading && data?.getSchool.noLicenses) ??
                                        0}
                                </LicenceBold>
                            </LicencesInfo>
                        </>
                    )}
                </TableInfoWrapper>
                <SchoolFilters
                    getFilters={getFilters}
                    showGroupFilter={showGroupFilter}
                />
            </TableHeaderInner>
            <ButtonsWrapper>
                <ButtonsInnerWrapper>
                    <GroupButton
                        buttonType={'primary'}
                        onPress={() => firstButtonProps?.onClick(true)}
                        size={'small'}
                    >
                        {firstButtonProps?.title}
                    </GroupButton>
                    <GroupButton
                        buttonType={'secondary'}
                        onPress={() => secondButtonProps?.onClick(true)}
                        size={'small'}
                    >
                        {secondButtonProps?.title}
                    </GroupButton>{' '}
                </ButtonsInnerWrapper>
                {showSelectButton && (
                    <ButtonsInnerWrapper>
                        <StyledButton
                            icon={{
                                activeColor: ['text'],
                                color: ['text'],
                                type: 'IconEdit',
                                isLeft: true,
                            }}
                            buttonType={'secondary'}
                            onPress={() => editButtonProps?.onClick(true)}
                            size={'small'}
                            width={204}
                        >
                            {editButtonProps?.title}
                        </StyledButton>
                        <StyledButton
                            buttonType={'secondaryError'}
                            onPress={() => deleteButtonProps?.onClick(true)}
                            size={'small'}
                            width={110}
                        >
                            {deleteButtonProps?.title}
                        </StyledButton>
                    </ButtonsInnerWrapper>
                )}
                {isGroupView && showRemoveStudentsButton && (
                    <StyledButton
                        buttonType="secondary"
                        icon={{
                            type: 'RemoveIcon',
                            isLeft: true,
                            color: ['text'],
                        }}
                        onPress={() => removeStudents(true)}
                        size="small"
                        type="button"
                        width={218}
                    >
                        Remove students
                    </StyledButton>
                )}

                {isGroupView && !showRemoveStudentsButton && (
                    <DeleteButton
                        buttonType="secondaryError"
                        onPress={() =>
                            setGroupToDelete({
                                groupId: +groupId,
                                groupTitle: groupName ?? '',
                            })
                        }
                        size="small"
                        type="button"
                    >
                        Delete group
                    </DeleteButton>
                )}
            </ButtonsWrapper>
            {groupToDelete && (
                <DeleteGroupModal
                    groupId={groupToDelete.groupId}
                    groupTitle={groupToDelete.groupTitle}
                    onClose={() => setGroupToDelete(null)}
                />
            )}
        </TableHeaderWrapper>
    );
};
