import { gql, useLazyQuery } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import {
    LibraryContent,
    LibraryContentVariables,
} from '@@types/graphql/LibraryContent';

export const LIBRARY_CONTENT = gql`
    query LibraryContent(
        $paginationInput: SearchPaginationInput
        $sorting: LIBRARY_VIEW_CONTENT_SORTING
        $typeFilter: ContentTypeFilter
        $topicFilter: [String!]
    ) {
        content: getContentsForLibraryView(
            paginationInput: $paginationInput
            sorting: $sorting
            typeFilter: $typeFilter
            topicFilter: $topicFilter
        ) {
            __typename
            ... on Course {
                _id
                type
                name
                featured
                thumbnail
            }
            ... on StandaloneVideo {
                _id
                type
                name
                featured
                thumbnail
            }
            ... on LiveExperience {
                _id
                type
                name
                featured
                endDate
                startDate
                maxNoParticipants
                noOfSignups
                thumbnail
                showToChildren
                showToStudents
                schoolsWithAccess {
                    id
                }
            }
        }
    }
`;

export const useLibraryContent = (variables?: LibraryContentVariables) => {
    return useLazyQuery<LibraryContent, LibraryContentVariables>(
        LIBRARY_CONTENT,
        {
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
            variables,
        },
    );
};
