import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button } from '@components/_universal';
import { FormRow } from '@domains/Auth/_forms/GlobalForm.styled';

export const CreateAccountFormWrapper = styled.form``;

export const StyledButton = styled(Button)`
    margin-top: 8px;
    max-width: 362px;
    width: 100%;

    @media (max-width: ${breakpoints.md}px) {
        margin: 16px 16px 0;
    }

    @media (max-width: ${breakpoints.sm}px) {
        max-width: unset;
    }
`;

export const StyledFormRow = styled(FormRow)`
    @media (max-width: ${breakpoints.sm}px) {
        align-items: stretch;
        flex-direction: column;
    }
`;
