import styled, { css } from 'styled-components';
import Footer from '@components/_global/Footer/Footer';
import Navigation from '@components/_global/Navigation/TeacherNavigation/TeacherNavigation';
import TeacherHeader from '@components/_global/Header/TeacherHeader/TeacherHeader';

export const TeacherAccountLayoutWrapper = styled.div`
    display: grid;
    grid-template-areas:
        'navigation header header'
        'navigation content .'
        'navigation footer .';
    grid-template-columns: 120px 1fr 100px;
    grid-template-rows: 0 1fr 90px;
    min-height: 100%;
`;

export const HeaderBar = styled(TeacherHeader)`
    grid-area: header;
`;

export const NavigationBar = styled(Navigation)`
    grid-area: navigation;
`;

export const Content = styled.div<{ paddingTop?: boolean }>(
    ({ paddingTop }) => css`
        grid-area: content;
        padding-top: ${paddingTop ? '80px' : '0'};
    `,
);

export const FooterBar = styled(Footer)`
    grid-area: footer;
`;
