import React from 'react';
import { TipWrapper } from './Tip.styled';
import { ColorType } from '@@types/CommonTypes';

export interface IProps {
    backgroundColor?: ColorType;
    className?: string;
}

const Tip: React.FC<IProps> = ({ backgroundColor, children, className }) => {
    return (
        <TipWrapper {...{ backgroundColor, className }}>{children}</TipWrapper>
    );
};

export default Tip;
