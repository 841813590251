import { CreateChatVisitorTokenQuery } from '@@types/graphql/CreateChatVisitorTokenQuery';
import { ToastTypes } from '@@types/CommonTypes';
import { gql, useQuery } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';

const CREATE_CHAT_VISITOR_TOKEN_QUERY = gql`
    query CreateChatVisitorTokenQuery {
        createChatVisitorToken
    }
`;

export default () => {
    return useQuery<CreateChatVisitorTokenQuery>(
        CREATE_CHAT_VISITOR_TOKEN_QUERY,
        {
            onError: (error) => {
                error.message !== 'SCHOOL_WITH_DISABLED_CHATS' &&
                    notify(error.message)[ToastTypes.ERROR]();
            },
        },
    );
};
