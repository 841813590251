import { useEffect } from 'react';
import { setLiveExperiencesVisibility } from '@state/_redux/liveExperiencesVisibility/actions';
import { useDispatch } from 'react-redux';
import { useSchoolForStudent } from '@state/students/queries/schoolForStudent';

export const useChildLiveExperienceVisibility = () => {
    const { data } = useSchoolForStudent();
    const dispatch = useDispatch();

    useEffect(() => {
        data &&
            dispatch(
                setLiveExperiencesVisibility(
                    data?.getSchoolForStudent.enableLE,
                ),
            );
    }, [data]);
};
