import React from 'react';
import { ProgressVariants } from '@@types/CommonTypes';
import { useHistory } from 'react-router-dom';
import ProgressBar from '@components/_universal/ProgressBar/ProgressBar';
import {
    IconWrapper,
    ImageOverlay,
    ImageWrapper,
    PlayIconManager,
    ProgressElementInnerWrapper,
    ProgressElementWrapper,
    ProgressTitle,
    ProgressType,
    TextWrapper,
} from './ProgressElement.styled';

interface IProps {
    className?: string;
    image: string;
    isVideo?: boolean;
    progress?: number;
    progressType?: 'Course' | 'Video' | 'Article' | 'Live';
    progressVariant?: ProgressVariants;
    url: string;
}

const ProgressElement: React.FC<IProps> = ({
    children = 'How to find a job - but make it fun',
    image,
    url,
    isVideo = true,
    progress = 0,
    progressType = 'Course',
    progressVariant = 'small',
    className,
}) => {
    const history = useHistory();
    const isBig = progressVariant === 'big';
    const isSmall = progressVariant === 'small';

    return (
        <ProgressElementWrapper
            onClick={() => history.push(url)}
            className={className}
        >
            <ProgressElementInnerWrapper isSmall={isSmall}>
                <ImageWrapper progressVariant={progressVariant} image={image}>
                    <ImageOverlay />
                    <IconWrapper name={progressType} size={isBig ? 40 : 24} />
                    {isVideo && (
                        <PlayIconManager
                            progressVariant={progressVariant}
                            name={'Play'}
                            size={isBig ? 24 : 16}
                        />
                    )}
                </ImageWrapper>
                <TextWrapper progressVariant={progressVariant}>
                    {isBig && <ProgressType>{progressType}</ProgressType>}
                    <ProgressTitle isBig={isBig}>{children}</ProgressTitle>
                    {isBig && <ProgressBar progress={progress} />}
                </TextWrapper>
            </ProgressElementInnerWrapper>
            {isSmall && <ProgressBar progress={progress} />}
        </ProgressElementWrapper>
    );
};

export default ProgressElement;
