import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import AgreementText from '@components/AgreementText/AgreementText';

export const Description = styled(DefaultText)`
    margin-bottom: 0;

    & + & {
        margin-bottom: 48px;
    }
`;

export const StyledAgreementText = styled(AgreementText)`
    margin-top: 16px;
`;
