import styled, { css } from 'styled-components';
import { Title } from '@components/_universal/Typography.styled';

export const DashboardTeacherWrapper = styled.div``;

export const SectionTitleWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;
`;

export const SectionTitle = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        line-height: 1.45;
        margin: 0;
    `,
);

export const StudentsActivityWrapper = styled.div`
    display: grid;
    grid-column-gap: 32px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin: 32px 0 80px;
    min-height: 324px;
`;

export const DoubleActivityCards = styled.div`
    display: grid;
    grid-row-gap: 12px;
    grid-template-rows: 1fr 1fr;
`;

export const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 436px;
`;

export const ActivityFeedLoaderWrapper = styled(LoaderWrapper)`
    height: 200px;
`;
