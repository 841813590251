import styled, { css } from 'styled-components';
import { Button } from '@components/_universal';
import {
    DefaultText,
    SubTitle,
} from '@components/_universal/Typography.styled';

export const InformationTeacherPageWrapper = styled.div``;

export const PageHeader = styled.div`
    display: flex;
    margin-bottom: 24px;
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RowWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    min-height: 40px;
`;

export const RowTitle = styled(DefaultText)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.text_hover};
        font-size: ${fontSizes.xs}px;
        font-weight: ${fontWeights.bold};
        margin-bottom: 24px;
        text-transform: uppercase;
    `,
);

export const SectionRow = styled.div`
    margin-bottom: 40px;
`;

export const RowText = styled(DefaultText)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin: 0;
    `,
);

export const SectionDivider = styled.div(
    ({ theme: { colors } }) => css`
        background: ${colors.background_tertiary};
        height: 2px;
        margin: 8px 0 48px;
        width: 100%;
    `,
);

export const SectionText = styled(DefaultText)(
    ({ theme: { fontSizes } }) => css`
        font-size: ${fontSizes.s}px;
        margin-bottom: 45px;
        max-width: 448px;
    `,
);

export const SectionButton = styled(Button)`
    height: 40px;
    justify-self: flex-end;
    margin-left: auto;
    min-width: 120px;
`;
