import styled, { css } from 'styled-components';
import { IconManager } from '@components/_universal';
import {
    BigHeader,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const InfoTilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
`;

export const InfoTilesSection = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TileIcon = styled(IconManager)`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
`;

export const CourseInfoTileCss = css`
    background: ${({ theme }) => theme.colors.course};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    color: ${({ theme }) => theme.colors.text_white};
    padding: 15px 16px;
    position: relative;
`;

export const TileDate = styled.div`
    margin-right: 32px;
    width: 224px;
    ${CourseInfoTileCss}
`;

export const TileValue = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
`;

export const InfoTileSectionTitle = styled(BigHeader)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 16px;
`;

export const InfoTileSection = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InfoTilesContent = styled.div`
    display: flex;
`;

export const TileTitle = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
    text-transform: uppercase;
`;
