import React, { useEffect, useState } from 'react';
import { GetStandaloneVideoQuery_getStandaloneVideo } from '@@types/graphql/GetStandaloneVideoQuery';
import { RecommendedVideosQuery_getRecommendedContentsForUser_StandaloneVideo } from '@@types/graphql/RecommendedVideosQuery';
import { useParams } from 'react-router-dom';
import { useRecommendedVideos } from '@state/lists/queries';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import paths from '@shared/paths';
import useVideo from '@utils/hooks/useVideo';
import SaveForLater from '@components/_universal/SaveForLater/SaveForLater';
import VideoList from '@components/_universal/VideoList/VideoList';
import {
    InnerVideoPlayerWrapper,
    StyledIframe,
} from '@components/_universal/commonStyles.styled';
import {
    useRemoveSavedForLaterMutation,
    useSaveForLaterMutation,
} from '@state/savedForLater/mutations';
import {
    DescriptionText,
    DescriptionTitle,
    InnerWrapper,
    LoaderWrapper,
    ListTitle,
    NavigationHeaderIcon,
    NavigationListWrapper,
    PageTitle,
    SectionHeader,
    SectionTitle,
    VideoViewPageWrapper,
    VideoWrapper,
    WrapperVideoPlayer,
} from './VideoViewPage.styled';
import Loader from '@components/_universal/Loader/Loader';

interface IProps {}

const VideoViewPage: React.FC<IProps> = () => {
    const { videoId } = useParams<{ videoId: string }>();
    const { data, loading: loadingRecommended } = useRecommendedVideos();
    const filterData = data?.getRecommendedContentsForUser?.filter(
        (video) =>
            video.__typename === 'StandaloneVideo' && video._id !== videoId,
    ) as
        | RecommendedVideosQuery_getRecommendedContentsForUser_StandaloneVideo[]
        | undefined;

    const { currentVideo, link, loading } = useVideo(videoId, 'player');

    const videoData = currentVideo as
        | GetStandaloneVideoQuery_getStandaloneVideo
        | undefined;

    const [
        removeSavedForLater,
        { loading: loadingRemove },
    ] = useRemoveSavedForLaterMutation(videoId, 'StandaloneVideo');
    const [saveForLater, { loading: loadingSave }] = useSaveForLaterMutation(
        videoId,
        'StandaloneVideo',
    );
    const [listLoading, setListLoading] = useState(true);

    useEffect(() => {
        setListLoading(true);
        const timeout = setTimeout(() => {
            setListLoading(false);
        }, 300);

        return () => clearTimeout(timeout);
    }, [videoId]);

    return (
        <VideoViewPageWrapper>
            <PageTitle>Video details</PageTitle>
            <SectionHeader>
                {videoData && (
                    <>
                        <SectionTitle>{videoData?.name}</SectionTitle>
                        <SaveForLater
                            disabled={loadingRemove || loadingSave}
                            isActive={!!videoData?.isSavedForLater}
                            onClick={() => {
                                videoData?.isSavedForLater
                                    ? removeSavedForLater({
                                          variables: {
                                              contentId: videoData?._id || '',
                                          },
                                      })
                                    : saveForLater({
                                          variables: {
                                              contentId: videoData?._id || '',
                                          },
                                      });
                            }}
                            size={32}
                        />
                    </>
                )}
            </SectionHeader>
            <InnerWrapper>
                <VideoWrapper>
                    <WrapperVideoPlayer>
                        {(loading || !link) && (
                            <LoaderWrapper>
                                <Loader size={'medium'} />
                            </LoaderWrapper>
                        )}
                        <InnerVideoPlayerWrapper>
                            {link && (
                                <StyledIframe
                                    allowFullScreen
                                    id={'player'}
                                    src={link}
                                />
                            )}
                        </InnerVideoPlayerWrapper>
                    </WrapperVideoPlayer>
                    <DescriptionTitle>Description</DescriptionTitle>
                    <DescriptionText>{videoData?.description}</DescriptionText>
                </VideoWrapper>
                <ChatWithMentor />
                <NavigationListWrapper>
                    <ListTitle>
                        Recommended for you
                        <NavigationHeaderIcon name={'TryIt'} size={60} />
                    </ListTitle>
                    <VideoList
                        loading={loadingRecommended || listLoading}
                        list={filterData?.map((item) => ({
                            id: item._id,
                            duration: item.video.duration,
                            title: item.name,
                            thumbnail: item.thumbnail,
                            link: paths.video_view(item._id),
                        }))}
                        variant={'primary'}
                    />
                </NavigationListWrapper>
            </InnerWrapper>
        </VideoViewPageWrapper>
    );
};

export default VideoViewPage;
