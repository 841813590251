import React, { useRef } from 'react';
import { DefaultText } from '@components/_universal/Typography.styled';
import { ModalForm } from './RecommendTheContentModal.styled';
import { SelectStudentsGroup } from '@components/SelectStudentsGroup/SelectStudentsGroup';
import { useContentRecommend } from './useContentRecommend';
import { useOnClickOutside } from '@utils/hooks';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import Modal from '@components/_universal/Modal/Modal';
import paths from '@shared/paths';
import {
    Link,
    BoldMessage,
    InfoSelect,
    NormalMessage,
} from '@components/SelectStudentsGroup/SelectStudentsGroup.styled';
import {
    ButtonsWrapper,
    ModalButton,
    ModalContent,
    ModalTitle,
} from '@components/_modals/GlobalModals.styled';

export interface RecommendTheContentModalProps {
    contentType: string;
    setIsModalOpen: (state: boolean) => void;
}

export const RecommendTheContentModal = ({
    contentType,
    setIsModalOpen,
}: RecommendTheContentModalProps) => {
    const ref = useRef(null);

    useOnClickOutside(ref, () => setIsModalOpen(false));

    const {
        control,
        errors,
        groups,
        handleRecommend,
        handleSubmit,
        register,
    } = useContentRecommend();

    const handleContentSubmited = () => {
        handleRecommend(contentType);
        setIsModalOpen(false);
    };
    const noOptionsMessage = () => {
        return (
            <InfoSelect>
                <BoldMessage>
                    It seems there are no students groups created yet.
                </BoldMessage>
                <NormalMessage>
                    To create the first group, please go to the{' '}
                    <Link href={paths.students_teacher}>‘Students list’</Link>{' '}
                    page.
                </NormalMessage>
            </InfoSelect>
        );
    };

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={() => setIsModalOpen(false)} />
                <ModalTitle>Recommend the content</ModalTitle>
                <DefaultText>
                    Please select a students group you would like to recommend
                    this content to.
                </DefaultText>
                <ModalForm
                    noValidate={true}
                    onSubmit={handleSubmit(handleContentSubmited)}
                >
                    <SelectStudentsGroup
                        control={control}
                        errors={errors.group}
                        noOptionsMessage={() => noOptionsMessage()}
                        options={groups}
                        register={register}
                    />
                    <ButtonsWrapper>
                        <ModalButton
                            buttonType="secondary"
                            onPress={() => setIsModalOpen(false)}
                            type="button"
                        >
                            Cancel
                        </ModalButton>
                        <ModalButton onPress={() => {}}>
                            Send recommendation
                        </ModalButton>
                    </ButtonsWrapper>
                </ModalForm>
            </ModalContent>
        </Modal>
    );
};
