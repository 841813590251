import styled, { css } from 'styled-components';
import { Button } from '@components/_universal/index';
import { Title } from '@components/_universal/Typography.styled';

export const SectionHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    margin-top: 86px;
`;

export const SectionTitle = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 0;
        margin-left: 24px;
        min-height: 44px;
    `,
);

export const TitleWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export const RecomendedButton = styled(Button)`
    width: 176px;
    & + & {
        margin-left: 16px;
    }
`;
