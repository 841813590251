import { gql, useMutation } from '@apollo/client';
import { AUTH_ME_QUERY } from '@state/auth/queries/useAuthMeQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { useAuthorize } from '@utils/hooks';
import { useHistory } from 'react-router';
import paths from '@shared/paths';
import {
    ConfirmResetPasswordMutation,
    ConfirmResetPasswordMutationVariables,
} from '@@types/graphql/ConfirmResetPasswordMutation';

const CONFIRM_RESET_PASSWORD_MUTATION = gql`
    mutation ConfirmResetPasswordMutation($input: ConfirmResetPasswordInput!) {
        confirmResetPassword(input: $input) {
            sessionToken
            refreshToken
            user {
                id
                type
                status
                onboardingCompletedAt
                hasPassword
            }
            operationType
        }
    }
`;

export default () => {
    const history = useHistory();
    const authorize = useAuthorize();

    return useMutation<
        ConfirmResetPasswordMutation,
        ConfirmResetPasswordMutationVariables
    >(CONFIRM_RESET_PASSWORD_MUTATION, {
        onCompleted: (payload) => {
            const data = payload.confirmResetPassword;

            authorize({
                data,
                timeout: 0,
            });

            notify('The password has been successfully changed.')[
                ToastTypes.SUCCESS
            ]();
        },
        refetchQueries: [
            {
                query: AUTH_ME_QUERY,
            },
        ],
        onError: (error) => {
            setTimeout(() => {
                history.push(paths.login);
                notify(error.message)[ToastTypes.ERROR]();
            }, 3500);
        },
    });
};
