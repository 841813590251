import React, { useRef } from 'react';
import { useOnClickOutside } from '@utils/hooks';
import Modal from '@components/_universal/Modal/Modal';
import {
    ChoosePlanInfoModalWrapper,
    CloseButton,
} from './ChoosePlanInfoModal.styled';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const ChoosePlanInfoModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, () => setIsModalOpen(false));

    return (
        <Modal>
            <ChoosePlanInfoModalWrapper ref={ref}>
                <CloseButton
                    fill={['text_white']}
                    name="Close"
                    onClick={() => setIsModalOpen(false)}
                    size={33}
                />
                If you want to cancel any account, uncheck it and see the
                proposed calculation for monthly and annually plan.
            </ChoosePlanInfoModalWrapper>
        </Modal>
    );
};

export default ChoosePlanInfoModal;
