import { gql, useQuery } from '@apollo/client';
import { GetAllLiveExperiencesQuery } from '@@types/graphql/GetAllLiveExperiencesQuery';
import { GetAllLiveExperiencesInput } from '@@types/graphql/GraphqlGlobalTypes';

const GET_ALL_LIVE_EXPERIENCES_QUERY = gql`
    query GetAllLiveExperiencesQuery($input: GetAllLiveExperiencesInput!) {
        getAllLiveExperiences(input: $input) {
            liveExperiences {
                _id
                thumbnail
                type
                name
                description
                featured
                status
                topicTags {
                    _id
                    name
                }
                skillTags {
                    _id
                }
                duration
                maxNoParticipants
                startDate
                link
                facilitatedBy {
                    id
                }
                createdBy
                createdAt
                activityMaterials {
                    filename
                    filesize
                }
                homeworkMaterials {
                    filename
                    filesize
                }
                isUserSignuped
                noOfSignups
            }
            meta {
                page
                limit
                nextPage
                prevPage
                hasPrevPage
                hasNextPage
                totalPages
                totalDocs
            }
        }
    }
`;

export default (input: GetAllLiveExperiencesInput) =>
    useQuery<GetAllLiveExperiencesQuery>(GET_ALL_LIVE_EXPERIENCES_QUERY, {
        variables: { input },
    });
