import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import Button from '@components/_universal/Button/Button';

export const BannedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 85px;
`;

export const BannedTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 24px;
`;

export const BannedDescription = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-align: center;
    max-width: 660px;
    margin-bottom: 40px;
`;

export const StyledButton = styled(Button)`
    padding-left: 48px;
    padding-right: 48px;
`;
