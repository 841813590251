import React from 'react';
import {
    DashboardEmptyAdditionalWrapper,
    DashboardEmptyInnerWrapper,
    DashboardEmptySmallText,
    DashboardParentEmptyChildWrapper,
    DashboardParentEmptyOrnament,
    EmptySubtitle,
    EmptyTitle,
    EnumerationsItemWrapper,
    EnumerationsListWrapper,
    QuestionMarkIcon,
    SendLink,
    TextBold,
    TextSpan,
} from './DashboardParentEmptyChild.styled';

interface IProps {
    onResendEmail?: () => void;
    title: string;
    subtitle: string;
}

const DashboardParentEmptyChild: React.FC<IProps> = ({
    onResendEmail,
    title,
    subtitle,
}) => {
    return (
        <DashboardParentEmptyChildWrapper>
            <EmptyTitle>{title}</EmptyTitle>
            <EmptySubtitle>{subtitle}</EmptySubtitle>
            <DashboardEmptyInnerWrapper>
                <EnumerationsListWrapper>
                    <EnumerationsItemWrapper>
                        <TextBold>Go to your child's email inbox</TextBold>
                        <TextSpan>
                            Just like you, they have an email from us containing
                            a link to set up their account. Click it, and follow
                            the steps!
                        </TextSpan>
                    </EnumerationsItemWrapper>
                    <EnumerationsItemWrapper>
                        <TextBold>
                            Encourage them to sign up to their first experience.
                        </TextBold>
                        <TextSpan>
                            Our experiences happen around the clock. Take a look
                            through your child’s dashboard with them, and sign
                            up for something that interests them!
                        </TextSpan>
                    </EnumerationsItemWrapper>
                    <EnumerationsItemWrapper>
                        <TextBold>
                            Take a look at their work, and feedback from our
                            facilitators!
                        </TextBold>
                        <TextSpan>
                            After an experience, your child will be encouraged
                            to submit a project through their dashboard. Once
                            uploaded, our mentor team will provide video
                            feedback. Both the project and feedback will appear
                            here - so check back often to see the amazing things
                            your child will be doing!
                        </TextSpan>
                    </EnumerationsItemWrapper>
                    <EnumerationsItemWrapper>
                        <TextBold>Do you have any questions?</TextBold>
                        <TextSpan>
                            Reach out to us via the button in the corner of your
                            screen!
                        </TextSpan>
                    </EnumerationsItemWrapper>
                </EnumerationsListWrapper>

                <DashboardEmptyAdditionalWrapper>
                    <DashboardParentEmptyOrnament
                        name={'DashboardParentEmptyOrnament'}
                        size={324}
                        sizeY={300}
                    />
                    <DashboardEmptySmallText>
                        <QuestionMarkIcon
                            name={'QuestionMarkDashboardIcon'}
                            size={38}
                        />
                        Did you child not receive an invitation?
                        <SendLink onClick={onResendEmail}>
                            Send it again!
                        </SendLink>
                    </DashboardEmptySmallText>
                </DashboardEmptyAdditionalWrapper>
            </DashboardEmptyInnerWrapper>
        </DashboardParentEmptyChildWrapper>
    );
};

export default DashboardParentEmptyChild;
