import { Permitted } from '@domains/Child/Dashboard/DashboardPage/Permitted/Permitted';
import { useUserMeQuery } from '@state/auth/queries';
import Banned from '@domains/Child/Dashboard/DashboardPage/Banned/Banned';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import {
    AvatarIcon,
    AvatarWrapper,
    DashboardDescription,
    DashboardHeader,
    DashboardHeaderWrapper,
    DashboardOrnament,
    DashboardPageContent,
    DashboardPageWrapper,
    DashboardTitle,
    PersonName,
    TrumpetIcon,
} from './DashboardPage.styled';

const DashboardPage: React.FC = () => {
    const { data, loading } = useUserMeQuery();
    const isBanned = data?.me.status === 'BANNED';

    return (
        <DashboardPageWrapper>
            <DashboardHeaderWrapper>
                <AvatarWrapper>
                    {!loading && (
                        <AvatarIcon avatar={data?.me?.avatar} iconSize={76} />
                    )}
                </AvatarWrapper>
                <DashboardHeader>
                    <DashboardTitle>
                        Hello <PersonName>{data?.me?.name}</PersonName>
                        <TrumpetIcon name={'Trumpet'} size={71} sizeY={40} />
                    </DashboardTitle>
                    <DashboardDescription>
                        Welcome to your Dashboard! What do you want to do today?
                    </DashboardDescription>
                </DashboardHeader>
                <DashboardOrnament
                    name={'DashboardOrnament'}
                    size={328}
                    sizeY={227}
                />
            </DashboardHeaderWrapper>
            <DashboardPageContent>
                {isBanned ? <Banned /> : <Permitted />}
            </DashboardPageContent>
            <ChatWithMentor />
        </DashboardPageWrapper>
    );
};

export default DashboardPage;
