import React from 'react';
import { useCancelSubscriptionMutation } from '@state/settings/mutations/index';
import ModalConfirmation from '@components/_modals/ModalConfirmation/ModalConfirmation';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const CancelSubscriptionModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const [
        cancelSubscription,
        { loading },
    ] = useCancelSubscriptionMutation(() => setIsModalOpen(false));

    return (
        <ModalConfirmation
            closeOutside
            firstButton={{
                onClick: () => setIsModalOpen(false),
                text: 'Go back',
                type: 'secondary',
            }}
            onClose={() => setIsModalOpen(false)}
            secondButton={{
                onClick: cancelSubscription,
                text: 'Yes, I understood',
                type: 'primary',
                disabled: loading,
            }}
            title="Cancel subscription"
        >
            Are you sure you want to cancel subscription? If you do it before
            the next payment date you won’t be charged subscription fees. In
            case you want to stay with us after the cancelation, you can renew
            the subscription until it is still valid.
        </ModalConfirmation>
    );
};

export default CancelSubscriptionModal;
