import React from 'react';
import { IconName } from '@@types/CommonTypes';
import { Link, useLocation } from 'react-router-dom';
import { setLeaveUploadStudentsModal } from '@state/_redux/layout/actions';
import { useDispatch } from 'react-redux';
import paths from '@shared/paths';
import useScrollToActiveElement from '@utils/hooks/useScrollToActiveElement';
import {
    NavigationItemIcon,
    NavigationItemText,
    NavigationItemWrapper,
    NavigationList,
    NavigationLogoIcon,
    NavigationWrapper,
    StyledSimpleBar,
} from '@components/_global/Navigation/Navigation.styled';

interface IProps {
    borderLine?: boolean;
    disabledLinks?: boolean;
    disabledLogoLinks?: boolean;
}

interface INavLink {
    exact?: boolean;
    iconName: IconName;
    label: string;
    path: string;
}

const NAVIGATION_LINKS: INavLink[] = [
    {
        exact: true,
        iconName: 'IconDashboardTeacher',
        label: 'Dashboard',
        path: paths.dashboard_teacher,
    },
    {
        iconName: 'IconStudentsTeacher',
        label: 'Students',
        path: paths.students_teacher,
    },
    {
        iconName: 'GroupsIcon',
        label: 'Groups',
        path: paths.teacher_groups,
    },
    {
        iconName: 'IconLibraryTeacher',
        label: 'Library',
        path: paths.library_teacher,
    },
];

const Navigation: React.FC<IProps> = ({
    disabledLinks,
    disabledLogoLinks,
    borderLine,
}) => {
    const { navigationRef } = useScrollToActiveElement();
    const location = useLocation();
    const dispatch = useDispatch();

    const isUploadLocation = [
        paths.upload_teacher,
        paths.upload_summary_teacher,
    ].includes(location.pathname);

    const handleClickDisabledLink = (
        e: React.MouseEvent<HTMLAnchorElement>,
        path: string,
    ) => {
        if (!isUploadLocation) return;

        e.preventDefault();
        dispatch(
            setLeaveUploadStudentsModal({
                isOpen: true,
                redirectTo: path,
            }),
        );
    };

    return (
        <NavigationWrapper borderLine={borderLine}>
            {disabledLogoLinks ? (
                <NavigationLogoIcon name={'Logo'} size={50} />
            ) : (
                <Link to={paths.dashboard}>
                    <NavigationLogoIcon name={'Logo'} size={50} />
                </Link>
            )}
            {!disabledLinks && (
                <StyledSimpleBar>
                    <NavigationList ref={navigationRef}>
                        {NAVIGATION_LINKS.map(
                            ({ exact, iconName, label, path }, index) => (
                                <NavigationItemWrapper
                                    activeClassName={'navigation-item-active'}
                                    exact={exact}
                                    key={index}
                                    onClick={(e) =>
                                        handleClickDisabledLink(e, path)
                                    }
                                    to={path}
                                >
                                    <NavigationItemIcon
                                        name={iconName}
                                        size={32}
                                    />
                                    <NavigationItemText>
                                        {label}
                                    </NavigationItemText>
                                </NavigationItemWrapper>
                            ),
                        )}
                    </NavigationList>
                </StyledSimpleBar>
            )}
        </NavigationWrapper>
    );
};

export default Navigation;
