import React, { useMemo } from 'react';
import { IconManager } from '@components/_universal';
import parseContentToCards from '@utils/parseContentToCards';
import parseRepetableDisplayData from '@utils/parseRepetableDisplayData';
import RepetableDisplayCards from '@components/RepetableDisplayCards/RepetableDisplayCards';
import useCalcElementsNumber from '@utils/hooks/useCalcElementsNumber';
import {
    HeaderWrapper,
    Icon,
    SimilarContentWrapper,
    Title,
    InnerWrapper,
} from './SimilarContent.styled';
import useSimilarContentsQuery from '@state/lists/queries/useSimilarContentsQuery';
import { useParams } from 'react-router';

interface IProps {}

export enum ContentType {
    liveExperience = 'LIVE_EXPERIENCE',
    course = 'COURSE',
    video = 'STANDALONE_VIDEO',
}

const SimilarContent: React.FC<IProps> = () => {
    let { courseId } = useParams<{ courseId: string }>();
    const { data } = useSimilarContentsQuery(courseId);
    const { ref, numberElements } = useCalcElementsNumber({});
    const similarContentData = data?.getSimilarContents ?? [];
    const parsedData = useMemo(
        () =>
            parseRepetableDisplayData(parseContentToCards(similarContentData)),
        [data],
    );
    return (
        <SimilarContentWrapper>
            <HeaderWrapper>
                <Title>
                    Similar content
                    <Icon name={'CheckThem'} size={98} sizeY={60} />
                </Title>
                <IconManager name={'Grass'} size={418} sizeY={54} />
            </HeaderWrapper>
            <InnerWrapper ref={ref}>
                <RepetableDisplayCards
                    elements={[...parsedData].slice(0, numberElements)}
                />
            </InnerWrapper>
        </SimilarContentWrapper>
    );
};

export default SimilarContent;
