import React from 'react';
import logout from '@utils/logout';
import { IconManager } from '@components/_universal';
import { LINKS } from './AccountNavigation.data';
import { NavLink } from 'react-router-dom';
import {
    AccountNaviagationWrapper,
    InnerWrapper,
    Link,
    LogoutButton,
} from './AccountNavigation.styled';

interface IProps {}

const AccountNavigation: React.FC<IProps> = () => {
    return (
        <AccountNaviagationWrapper>
            <InnerWrapper>
                {LINKS.map(({ label, path }, index) => (
                    <Link
                        activeClassName={'accountNavActive'}
                        key={index}
                        to={path}
                        as={NavLink}
                    >
                        {label}
                    </Link>
                ))}
                <LogoutButton
                    buttonType={'secondary'}
                    onPress={() => logout()}
                    size={'small'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        isLeft: true,
                        type: 'Logout',
                    }}
                >
                    log out
                </LogoutButton>
            </InnerWrapper>
            <IconManager size={220} sizeY={140} name={'Hands'} />
        </AccountNaviagationWrapper>
    );
};

export default AccountNavigation;
