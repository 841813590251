import React from 'react';
import { useChildLiveExperienceVisibility } from '@domains/Child/Library/LibraryPage/useChildLiveExperienceVisibility';
import {
    Content,
    CoursesLayoutWrapper,
    FooterBar,
    HeaderBar,
    NavigationBar,
} from './CoursesLayout.styled';

interface IProps {}

export const CoursesLayout: React.FC<IProps> = ({ children }) => {
    useChildLiveExperienceVisibility();

    return (
        <CoursesLayoutWrapper>
            <HeaderBar trialIcon />
            <NavigationBar borderLine />
            <Content>
                {children}
                <FooterBar isBackpack isLogo />
            </Content>
        </CoursesLayoutWrapper>
    );
};

export default CoursesLayout;
