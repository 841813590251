import styled from 'styled-components';
import { Button } from '@components/_universal';

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 32px;
`;

export const StyledButton = styled(Button)`
    min-width: 234px;

    & + & {
        margin-left: 16px;
    }
`;
