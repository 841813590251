import React from 'react';
import { Switch } from '@components/_form-elements';
import useNotificationSettingsForm from '@utils/hooks/useNotificationSettingsForm';
import {
    PageHeader,
    PageTitle,
    SectionTitle,
} from '@domains/Parent/Account/AccountParentPage/AccountParentPage.styled';
import {
    NotificationSettingsTeacherPageWrapper,
    SectionDivider,
    SectionHeader,
    SectionSubtitle,
    SectionText,
    SectionWrapper,
} from './NotificationSettingsTeacherPage.styled';

interface IProps {}

const NotificationSettingsTeacherPage: React.FC<IProps> = () => {
    const { handleChange, register } = useNotificationSettingsForm();

    return (
        <NotificationSettingsTeacherPageWrapper>
            <PageHeader>
                <PageTitle>Notification Settings</PageTitle>
            </PageHeader>
            <SectionWrapper>
                <SectionHeader>
                    <SectionTitle>in app notifications</SectionTitle>
                    <Switch
                        onChange={handleChange}
                        name={'appNotification'}
                        register={register}
                    />
                </SectionHeader>
                <SectionSubtitle>Ban notifications</SectionSubtitle>
                <SectionText>
                    Receive notifications informing you that your student has
                    been banned.
                </SectionText>
            </SectionWrapper>
            <SectionDivider />
            <SectionWrapper>
                <SectionHeader>
                    <SectionTitle>email notifications</SectionTitle>
                    <Switch
                        onChange={handleChange}
                        name={'emailNotification'}
                        register={register}
                    />
                </SectionHeader>
                <SectionSubtitle>Ban notifications</SectionSubtitle>
                <SectionText>
                    Receive emails informing you that your child has been
                    banned.
                </SectionText>
                <SectionSubtitle>Messages from mentors</SectionSubtitle>
                <SectionText>
                    Receive emails with information about your students directly
                    from the mentors.
                </SectionText>
            </SectionWrapper>
        </NotificationSettingsTeacherPageWrapper>
    );
};

export default NotificationSettingsTeacherPage;
