import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import { breakpoints } from '@shared/constants';
import {
    BigTitle,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const SignupEmailVerifiedWrapper = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    justify-content: center;
    padding: 56px;

    @media (max-width: ${breakpoints.sm}px) {
        padding: 40px 24px;
    }
`;

export const InfoWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 720px;
`;

export const StyledBigTitle = styled(BigTitle)`
    line-height: 1.4;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-align: center;
    margin-bottom: 0;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.xxl}px;
        text-align: center;
    }
`;

export const VerifiedIcon = styled(IconManager)`
    svg {
        @media (max-height: 768px) {
            font-size: 250px;
            height: 250px;
        }

        @media (max-width: ${breakpoints.sm}px) {
            font-size: 150px;
            height: 150px;
        }
    }

    @media (max-width: ${breakpoints.md}px) {
        margin-bottom: 16px;
    }
`;

export const InfoInner = styled.div`
    align-items: center;
    display: flex;

    @media (max-width: ${breakpoints.sm}px) {
        flex-direction: column;
    }
`;

export const InformationList = styled.div`
    position: relative;
    left: -50px;

    @media (max-width: ${breakpoints.sm}px) {
        left: unset;
        margin-bottom: 24px;
    }
`;

export const InformationItem = styled.div`
    background: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    border: 1px solid ${({ theme }) => theme.colors.stroke};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 8px 0;
    padding: 12px 36px;
    position: relative;
`;

export const EmailVerifiedCheckIcon = styled(IconManager)`
    left: -17px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

export const Description = styled(DefaultText)`
    text-align: center;
    margin-bottom: 0;

    @media (max-width: ${breakpoints.md}px) {
        text-align: left;
    }
`;

export const HeadDescription = styled(DefaultText)`
    margin-bottom: 0;
    text-align: center;

    @media (max-width: ${breakpoints.md}px) {
        margin-bottom: 16px;
    }
`;

export const BoldedDescription = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const ButtonsWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 32px -8px 0 -8px;

    @media (max-width: ${breakpoints.sm}px) {
        flex-direction: column;
    }
`;

export const StyledButton = styled(Button)`
    margin: 0 8px;
    min-width: 278px;

    @media (max-width: ${breakpoints.sm}px) {
        & + & {
            margin-top: 16px;
        }
    }
`;
