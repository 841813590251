import styled, { css } from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import { LiveExperienceSteps } from '@@types/CommonTypes';
import doneBg from '@assets/images/done_bg.png';
import experienceBg from '@assets/images/experience_signup_bg.png';
import ongoingExperienceBg from '@assets/images/experience_ongoing_bg.png';
import submitBg from '@assets/images/submit_bg.png';
import {
    BigHeader,
    DefaultText,
} from '@components/_universal/Typography.styled';
import { Counter } from '@components/_universal/Counter/Counter';

const experienceDoneCss = css`
    background: url(${doneBg}) no-repeat center
        ${({ theme }) => theme.colors.background_primary};
    background-size: cover;
`;
const fullyBookedCss = css`
    background: url(${experienceBg}) no-repeat center
        ${({ theme }) => theme.colors.background_primary};
    background-size: cover;
`;
const signedUpCss = css`
    background: ${({ theme }) => theme.colors.background_quaternary};
`;
const signUpCss = css`
    background: url(${experienceBg}) no-repeat center
        ${({ theme }) => theme.colors.background_primary};
    background-size: cover;
`;
const submittedCss = css`
    background: url(${submitBg}) no-repeat center
        ${({ theme }) => theme.colors.background_primary};
    background-size: cover;
`;
const ongoingCss = css`
    background: url(${ongoingExperienceBg}) no-repeat center
        ${({ theme }) => theme.colors.background_primary};
    background-size: cover;
`;

const experienceBackgroundVariation = {
    experienceDone: experienceDoneCss,
    fullyBooked: fullyBookedCss,
    signedUp: signedUpCss,
    signUp: signUpCss,
    submitted: submittedCss,
    ongoing: ongoingCss,
};

export const ExperienceInfoWrapper = styled.div<{
    backgroundVariant: LiveExperienceSteps;
}>`
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 45px;
    position: relative;

    ${({ backgroundVariant }) =>
        experienceBackgroundVariation[backgroundVariant]}
`;

export const SignUpExperienceWrapper = styled.div``;

export const FullyBookedExperienceWrapper = styled.div``;

export const OngoingExperienceWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    height: 100%;
    padding: 0 64px;
`;

export const SlotsWrapper = styled.div`
    height: 90px;
    padding: 10px 0 10px 18px;
    position: absolute;
    right: 32px;
    top: -12px;
    width: 130px;
    z-index: 9;
`;

export const SlotsBgIcon = styled(IconManager)`
    position: absolute;
    right: 0;
    top: 0;
`;

export const SlotIcon = styled(IconManager)``;

export const SlotLeft = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-family: ${({ theme }) => theme.fontFamily.sketched};
    font-size: ${({ theme }) => theme.fontSizes.big}px;
    line-height: 0.65;
    margin-bottom: 0;
    position: relative;
    text-align: center;
    transform: rotate(-1.78deg);
    z-index: 9;
`;

export const SlotText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
    position: relative;
    text-align: center;
    z-index: 9;
`;

export const StyledBigHeader = styled(BigHeader)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 24px;
    text-align: center;
`;

export const StyledCounter = styled(Counter)`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const ButtonWrapper = styled.div<{ isMoreMargin?: boolean }>`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: ${({ isMoreMargin }) => (!!isMoreMargin ? 42 : 28)}px;
    position: relative;
`;

export const StyledButton = styled(Button)`
    min-width: 240px;
`;

export const ExperienceManIcon = styled(IconManager)`
    bottom: 80px;
    left: -40px;
    pointer-events: none;
    position: absolute;
`;

export const FullyBookedIcon = styled(IconManager)`
    pointer-events: none;
    position: absolute;
    right: 33px;
    top: -32px;
    z-index: 9;
`;

export const SignedUpExperienceWrapper = styled.div``;

export const ExclamationMarkIcon = styled(IconManager)`
    left: -50px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

export const InfoText = styled(DefaultText)`
    margin-bottom: 0;
    margin-right: 16px;
    position: relative;
    z-index: 9;
`;

export const DoneExperienceWrapper = styled.div`
    max-width: 350px;
    position: relative;
    z-index: 9;
`;

export const SumUpText = styled(DefaultText)`
    margin-bottom: 20px;
    text-align: center;
`;

export const DescriptionText = styled(DefaultText)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
    text-align: center;
`;

export const LightDescriptionText = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const WellDoneIcon = styled(IconManager)`
    left: -30px;
    pointer-events: none;
    position: absolute;
    top: 34px;
`;

export const OngoingMessageIcon = styled(IconManager)`
    left: 48px;
    pointer-events: none;
    position: absolute;
    top: 40px;
`;

export const ArrowIcon = styled(IconManager)`
    bottom: -50px;
    left: 50%;
    pointer-events: none;
    position: absolute;
    transform: translateX(-50%) rotate(90deg);
`;

export const SubmittedExperienceWrapper = styled.div`
    max-width: 480px;
    padding-bottom: 72px;
    position: relative;
`;

export const SubmittedText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.xl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.6;
    text-align: center;
`;

export const SubmittedIcon = styled(IconManager)`
    bottom: -46px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
`;

export const OngoingExperienceDescription = styled.div`
    max-width: 430px;
`;
