import styled from 'styled-components';
import { ColorType } from '@@types/CommonTypes';

export const ProgressBarWrapper = styled.div<{ color?: ColorType }>`
    background: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.background_tertiary};
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    height: 6px;
    width: 100%;
`;

export const ProgressBarContent = styled.div<{
    progress: number;
    color?: ColorType;
}>`
    background: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.secondary};
    border-radius: ${({ theme }) => `${theme.borderRadius.default}px`};
    height: 6px;
    transition: width ${({ theme }) => theme.transitions.default}s;
    width: ${({ progress }) => progress}%;
`;
