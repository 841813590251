import { IMosaicCard } from '@@types/CommonTypes';
import MosaicContent from '@components/MosaicContent/MosaicContent';
import Paginate from '@components/_universal/Paginate/Paginate';
import parseContentToCards from '@utils/parseContentToCards';
import useCompletedCoursesPage from './useCompletedCoursesPage';
import {
    CompletedCoursesWrapper,
    Description,
    MagnetIcon,
    StyledTitle,
    TextWrapper,
    TitleIcon,
    TitleWrapper,
} from './CompletedCourses.styled';

interface IProps {
    showLiveExperience?: boolean;
}

const CompletedCourses: React.FC<IProps> = ({ showLiveExperience }) => {
    const {
        data,
        hasNextPage,
        loading,
        onLoadMore,
        paginateLoading,
    } = useCompletedCoursesPage();

    const dataLength = data?.getConsumedContent?.length ?? 0;
    const parsedContentToCards = parseContentToCards(
        data?.getConsumedContent ?? [],
    );

    return (
        <CompletedCoursesWrapper>
            <TitleWrapper withMosaic={!!dataLength}>
                <StyledTitle>
                    Completed content
                    <TitleIcon name={'GoodJobIcon'} size={142} sizeY={72} />
                </StyledTitle>
            </TitleWrapper>
            {!!dataLength && (
                <Paginate
                    loading={paginateLoading}
                    queryLoading={loading}
                    hasNextPage={hasNextPage}
                    onLoadMore={onLoadMore}
                >
                    <MosaicContent
                        cards={
                            showLiveExperience
                                ? parsedContentToCards
                                : parsedContentToCards.filter(
                                      (item: IMosaicCard) =>
                                          item.type !== 'LIVE_EXPERIENCE',
                                  )
                        }
                    />
                </Paginate>
            )}
            {!dataLength && (
                <>
                    <TextWrapper>
                        <Description>
                            {showLiveExperience
                                ? 'All your completed courses, videos and live experiences will be displayed here.'
                                : 'All your completed courses and videos will be displayed here.'}
                        </Description>
                        <MagnetIcon name={'Magnet'} size={79} sizeY={46} />
                    </TextWrapper>
                </>
            )}
        </CompletedCoursesWrapper>
    );
};

export default CompletedCourses;
