import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';

export const NumberWrapper = styled(DefaultText)(
    ({ theme: { borderRadius, colors, fontSizes, fontWeights } }) => css`
        background: ${colors.background_secondary};
        border-radius: ${borderRadius.card}px;
        border: 1px solid ${colors.background_primary};
        font-size: ${fontSizes.xxl}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.6;
        margin-bottom: 0;
        padding: 12px;
        position: relative;

        & + & {
            margin-left: 8px;
        }
    `,
);

export const TimeName = styled(DefaultText)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.secondary};
        font-size: ${fontSizes.xs}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.5;
        margin-bottom: 0;
        margin-top: 10px;
        text-align: center;
        text-transform: uppercase;
    `,
);

export const TimeNameWhite = styled(TimeName)(
    ({ theme: { colors } }) => css`
        color: ${colors.text_white};
    `,
);

export const GroupNumberWrapper = styled.div`
    display: flex;
`;

export const TimeSectionWrapper = styled.div`
    & + & {
        margin-left: 24px;
    }
`;

export const CountDownRendererWrapper = styled.div`
    display: flex;
`;
