import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateNotificationSettingsMutation } from '@state/notifications/mutations';
import { useNotificationSettingsQuery } from '@state/notifications/queries';

interface IForm {
    appNotification: boolean;
    emailNotification: boolean;
}

export default () => {
    const { handleSubmit, register, reset } = useForm<IForm>();
    const { data } = useNotificationSettingsQuery();
    const [
        updateNotificationSettings,
    ] = useUpdateNotificationSettingsMutation();

    const onSubmit = (values: IForm) => {
        updateNotificationSettings({
            variables: {
                input: {
                    emailNotificationEnabled: values.emailNotification,
                    inAppNotificationEnabled: values.appNotification,
                },
            },
        });
    };

    useEffect(() => {
        reset({
            appNotification:
                data?.getUserNotificationSettings.inAppNotificationEnabled,
            emailNotification:
                data?.getUserNotificationSettings.emailNotificationEnabled,
        });
    }, [data]);

    return {
        register,
        handleChange: handleSubmit(onSubmit),
    };
};
