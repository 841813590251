import React, { useRef } from 'react';
import { useOnClickOutside } from '@utils/hooks';
import Modal from '@components/_universal/Modal/Modal';
import {
    ButtonWrapper,
    DeleteAccountModalWrapper,
    IconWrapper,
    StyledButton,
    StyledText,
    StyledTitle,
} from './DeactivateUserModal.styled';
import { DefaultText } from '@components/_universal/Typography.styled';

export interface IProps {
    childName: string;
    loading?: boolean;
    onDeactivate: () => void;
    setIsModalOpen: (state: boolean) => void;
}

const DeactivateUserModal: React.FC<IProps> = ({
    childName,
    loading,
    onDeactivate,
    setIsModalOpen,
}) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsModalOpen(false));

    return (
        <Modal>
            <DeleteAccountModalWrapper {...{ ref }}>
                <StyledTitle>Deactivate user</StyledTitle>
                <StyledText>
                    Are you sure you want to proceed? After deactivation{' '}
                    <DefaultText
                        as="span"
                        fontWeight="semiBold"
                        color="text_secondary"
                    >
                        {childName}
                    </DefaultText>{' '}
                    could use the platform until next payment. After that period
                    the child will be no longer able to enter the platform.
                </StyledText>
                <ButtonWrapper>
                    <StyledButton
                        buttonType={'secondary'}
                        onPress={() => setIsModalOpen(false)}
                    >
                        Go back
                    </StyledButton>
                    <StyledButton
                        buttonType={'primary'}
                        disabled={loading}
                        onPress={() => {
                            onDeactivate();
                        }}
                    >
                        Yes, deactivate user
                    </StyledButton>
                </ButtonWrapper>
                <IconWrapper
                    cursor={'pointer'}
                    name={'Close'}
                    onClick={() => setIsModalOpen(false)}
                    size={24}
                />
            </DeleteAccountModalWrapper>
        </Modal>
    );
};

export default DeactivateUserModal;
