import { IconName } from '@@types/CommonTypes';
import React from 'react';
import {
    AvatarWrapper,
    AvatarImageIcon,
    AvatarSelectedIcon,
} from './Avatar.styled';

interface IProps {
    avatar?: number | null;
    avatarFrame?: IconName;
    className?: string;
    iconSelectedSize?: number;
    iconSize: number;
    isActive?: boolean;
    onClick?: () => void;
}

const Avatar: React.FC<IProps> = ({
    avatar,
    avatarFrame = 'AvatarFrame',
    className,
    iconSelectedSize,
    iconSize,
    isActive,
    onClick,
}) => {
    return (
        <AvatarWrapper className={className} onClick={onClick}>
            {isActive && iconSelectedSize && (
                <AvatarSelectedIcon
                    className={'AvatarSelectedIcon'}
                    name={avatarFrame}
                    size={iconSelectedSize}
                />
            )}
            <AvatarImageIcon
                className={'AvatarImageIcon'}
                name={
                    avatar === null
                        ? 'PlaceholderAvatar'
                        : (`Avatar_${avatar}` as IconName)
                }
                size={iconSize}
            />
        </AvatarWrapper>
    );
};

export default Avatar;
