import styled from 'styled-components';
import checkIcon from '@assets/images/checkIconSmall.svg';
import checkIconIndeterminate from '@assets/images/checkIconIndeterminate.svg';

export const CheckboxWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    position: relative;
    width: auto;
`;

export const CheckboxInput = styled.input`
    appearance: none;
    background: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.small}px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    cursor: pointer;
    height: 16px;
    width: 16px;

    &:checked {
        background: url(${checkIcon}) ${({ theme }) => theme.colors.primary}
            center no-repeat;
        border-color: ${({ theme }) => theme.colors.primary};
    }

    &:not(:checked):not(:indeterminate):hover {
        background: ${({ theme }) => theme.colors.background_primary};
    }

    &:indeterminate {
        background: url(${checkIconIndeterminate})
            ${({ theme }) => theme.colors.primary} center no-repeat;

        &:checked {
            background: url(${checkIconIndeterminate})
                ${({ theme }) => theme.colors.primary} center no-repeat;
            border-radius: ${({ theme }) => theme.borderRadius.small}px;
        }
    }
`;
