import React from 'react';
import { OngoingAndUpcoming } from '@domains/Child/Dashboard/_components/LiveExperienceCarousel/OngoingAndUpcoming/OngoingAndUpcoming';
import { SignUp } from '@domains/Child/Dashboard/_components/LiveExperienceCarousel/SignUp/SignUp';
import { useUpcomingLiveExperiencesContentQuery } from '@state/lists/queries';
import Loader from '@components/_universal/Loader/Loader';
import {
    LoaderWrapper,
    BackgroundDecorator,
    ExperiencesCarouselWrapper,
    StyledTitle,
} from './LiveExperienceCarousel.styled';

export const LiveExperienceCarousel = () => {
    const { data, loading } = useUpcomingLiveExperiencesContentQuery();

    const liveExperiences = data?.getYourUpcomingLiveExperiences ?? [];

    return (
        <ExperiencesCarouselWrapper>
            {loading ? (
                <LoaderWrapper>
                    <Loader size={'medium'} center />
                </LoaderWrapper>
            ) : (
                <>
                    <StyledTitle>
                        {liveExperiences.length > 0
                            ? `Ongoing & upcoming Experiences`
                            : 'Sign up for your next Experience'}
                    </StyledTitle>

                    {liveExperiences.length > 0 ? (
                        <OngoingAndUpcoming data={liveExperiences} />
                    ) : (
                        <SignUp />
                    )}
                </>
            )}
            <BackgroundDecorator
                name={'DashboardDecoratorTop'}
                size={515}
                sizeY={472}
            />
        </ExperiencesCarouselWrapper>
    );
};
