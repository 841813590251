import ProgressElement from '@components/_universal/ProgressElement/ProgressElement';
import styled from 'styled-components';

export const SwiperWrapper = styled.div`
    overflow: hidden;
    width: calc(100vw - 200px);
`;

export const StyledProgressElement = styled(ProgressElement)`
    min-width: 350px;
`;
