import React from 'react';
import { Line, LoginFormBreakWrapper, Text } from './LoginFormBreak.styled';

export interface IProps {}

const LoginFormBreak: React.FC<IProps> = () => {
    return (
        <LoginFormBreakWrapper>
            <Line />
            <Text>or</Text>
            <Line />
        </LoginFormBreakWrapper>
    );
};

export default LoginFormBreak;
