import React from 'react';
import { externalLinks } from '@shared/paths';
import { useCreateCheckoutSessionMutation } from '@state/auth/mutations';
import { useStripePublishableKey } from '@state/auth/queries';
import {
    ButtonsWrapper,
    InformationText,
    PaymentIcon,
    SignupFailedPaymentWrapper,
    StyledButton,
    StyledTitle,
    TextWrapper,
} from './SignupFailedPaymentPage.styled';

interface IProps {}

const SignupFailedPaymentPage: React.FC<IProps> = () => {
    const { data } = useStripePublishableKey();

    const [
        createCheckoutSession,
        { loading },
    ] = useCreateCheckoutSessionMutation(
        data?.getStripeSetup.publishableKey ?? '',
    );

    return (
        <SignupFailedPaymentWrapper>
            <PaymentIcon size={455} name={'PaymentFailed'} />
            <TextWrapper>
                <InformationText>Payment set up failed</InformationText>
                <StyledTitle>Oops...</StyledTitle>
                <StyledTitle>Something went wrong.</StyledTitle>
                <ButtonsWrapper>
                    <StyledButton
                        buttonType={'secondary'}
                        disabled={loading}
                        onPress={() => createCheckoutSession()}
                        size={'small'}
                    >
                        Try again
                    </StyledButton>
                    <StyledButton
                        buttonType={'secondary'}
                        onPress={() =>
                            window.open(externalLinks.homepage, '_blank')
                        }
                        size={'small'}
                    >
                        Contact us
                    </StyledButton>
                </ButtonsWrapper>
            </TextWrapper>
        </SignupFailedPaymentWrapper>
    );
};

export default SignupFailedPaymentPage;
