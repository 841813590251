import React from 'react';
import { useDeleteStudentsGroup } from '@state/students/mutations/useDeleteStudentsGroup';
import { useHistory } from 'react-router';
import ModalConfirmation from '@components/_modals/ModalConfirmation/ModalConfirmation';
import paths from '@shared/paths';
import { BoldText } from '@components/_modals/GlobalModals.styled';

export interface DeleteGroupModalProps {
    groupId: number;
    groupTitle: string;
    onClose: () => void;
}

export const DeleteGroupModal = ({
    groupId,
    groupTitle,
    onClose,
}: DeleteGroupModalProps) => {
    const history = useHistory();

    const [deleteGroup, { loading }] = useDeleteStudentsGroup(() => {
        onClose();
        history.push(paths.teacher_groups);
    });

    return (
        <ModalConfirmation
            closeOutside
            firstButton={{
                onClick: onClose,
                text: 'cancel',
                type: 'secondary',
            }}
            onClose={onClose}
            secondButton={{
                onClick: () => {
                    deleteGroup({
                        variables: {
                            input: {
                                groupId,
                            },
                        },
                    });
                },
                text: 'Delete',
                type: 'primaryError',
                disabled: loading,
            }}
            title="Delete group"
        >
            Are you sure you want to delete the group '{groupTitle}' ? Please
            note all users assigned to this group{' '}
            <BoldText>won't be deleted.</BoldText> Instead they will moved under
            the 'No group assigned' tab.
        </ModalConfirmation>
    );
};
