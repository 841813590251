import React from 'react';
import { IconManager } from '@components/_universal';
import {
    EmptyActivityStateWrapper,
    InfoText,
} from './EmptyActivityState.styled';

export interface IProps {
    userName: string;
}

const EmptyActivityState: React.FC<IProps> = ({ userName }) => {
    return (
        <EmptyActivityStateWrapper>
            <IconManager size={122} sizeY={90} name="EntertainmentBanner" />
            <InfoText>
                It seems {userName} hasn’t completed or consumed any content yet
            </InfoText>
        </EmptyActivityStateWrapper>
    );
};

export default EmptyActivityState;
