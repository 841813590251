import React from 'react';
import {
    ButtonWrapper,
    DescriptionText,
    JoinExperienceDescription,
    JoinExperienceWrapper,
    StyledButton,
} from '../ExperienceInfo.styled';

interface JoinExperienceProps {
    className?: string;
    link: string;
}

export const JoinExperience = ({ className, link }: JoinExperienceProps) => {
    return (
        <JoinExperienceWrapper className={className}>
            <JoinExperienceDescription>
                <DescriptionText>
                    This experience has began! Click the join button below to
                    take part in it.
                </DescriptionText>
                <ButtonWrapper>
                    <StyledButton onPress={() => window.open(link)}>
                        Join
                    </StyledButton>
                </ButtonWrapper>
            </JoinExperienceDescription>
        </JoinExperienceWrapper>
    );
};
