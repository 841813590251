import styled, { css } from 'styled-components';
import Badge from '@components/_universal/Badge/Badge';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import {
    CardWrapper,
    CardImage,
    DEFAULT_CARD_HEIGHTS,
} from '@components/_universal/Cards/Cards.styled';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';

export const LiveExperienceCardWrapper = styled(CardWrapper)`
    background: ${({ theme }) => theme.colors.background_secondary};
    display: flex;
    flex-direction: column;
    min-height: ${DEFAULT_CARD_HEIGHTS.liveExperience}px;
    overflow: hidden;
    width: 100%;
    will-change: box-shadow;
`;

export const LiveExperienceCardImage = styled(CardImage)<{ image: string }>`
    flex-shrink: 0;
    height: 150px;
`;

export const LiveExperienceCardContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 38px 16px 30px;
    position: relative;
`;

export const LiveExperienceCardText = styled(DefaultText)`
    flex-grow: 1;
    font-size: ${({ theme }) => theme.fontSizes.m};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.55;
    margin-bottom: 16px;
`;

export const LiveExperienceCardDate = styled(DefaultText)`
    align-items: center;
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 0;
`;

export const LiveExperienceCardTime = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    letter-spacing: 0.02em;
    margin-bottom: 0;
    margin-left: 16px;
    padding-right: 4px;
    position: relative;

    &:before {
        background: ${({ theme }) => theme.colors.text_secondary};
        content: '';
        height: 14px;
        left: -8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
    }
`;

export const LiveExperienceCardDetails = styled.div<{ userType?: USER_TYPE }>(
    ({ userType }) => css`
        align-items: center;
        display: flex;
        justify-content: center;
        ${userType === USER_TYPE.TEACHER &&
        css`
            align-items: flex-start;
            flex-direction: column;

            ${LiveExperienceCardTime} {
                margin-left: 24px;

                &:before {
                    content: none;
                }
            }
        `}
    `,
);

export const Arrow = styled(IconManager)`
    bottom: 46px;
    position: absolute;
    right: 20px;
`;

export const LiveExperienceCardBadge = styled(Badge)`
    left: 50%;
    padding: 6px 16px;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
`;

export const LiveExperienceCardHurryText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.xxl}px;
    left: 12px;
    position: absolute;
    top: 0;
    transform: rotate(-20deg);
`;
