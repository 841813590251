import { useEffect, useState } from 'react';
import { ACTIVITY_LEVEL } from '@@types/graphql/GraphqlGlobalTypes';
import { IStudentsActivityCardProps } from '@domains/Teacher/Dashboard/DashboardTeacherPage/_components/StudentsActivityCard/StudentsActivityCard';
import { Nullable } from '@@types/CommonTypes';
import { StudentsActivityFeedQueryVariables } from '@@types/graphql/StudentsActivityFeedQuery';
import {
    useStudentsActivityFeedQuery,
    useStudentsActivityStatsQuery,
} from '@state/students/queries';

interface IFilters {
    className?: string;
    groupId?: number;
    year?: string;
}

const statsTexts: Record<ACTIVITY_LEVEL, string> = {
    [ACTIVITY_LEVEL.HIGH]: 'activity in the last 4 weeks',
    [ACTIVITY_LEVEL.MEDIUM]: 'activity in the last 8 weeks',
    [ACTIVITY_LEVEL.LOW]: 'activity in the last 12 weeks',
    [ACTIVITY_LEVEL.DISCONNECTED]: 'activity over 12 weeks ago',
    [ACTIVITY_LEVEL.PENDING]: 'no activity',
};

const FEED_LIMIT = 20;

export default () => {
    const [resetFilters, setResetFilters] = useState(false);
    const [filters, setFilters] = useState<IFilters>({});
    const [paginateLoading, setPaginateLoading] = useState(false);

    const {
        data: studentsStatsData,
        loading: studentsStatsLoading,
    } = useStudentsActivityStatsQuery();
    const [
        activityFeed,
        { data: feedData, loading, fetchMore },
    ] = useStudentsActivityFeedQuery();

    const getFilters = (
        className?: string,
        groupId?: number,
        year?: string,
    ) => {
        setFilters({
            className,
            groupId,
            year,
        });
    };

    const generateVariables = (
        page?: Nullable<number>,
    ): StudentsActivityFeedQueryVariables => ({
        input: {
            pagination: {
                limit: FEED_LIMIT,
                page: page ? ++page : 1,
            },
            search: {
                className: filters.className,
                year: filters.year ? +filters.year : undefined,
            },
        },
    });

    const onLoadMore = () => {
        setPaginateLoading(true);

        fetchMore?.({
            variables: generateVariables(feedData?.getActivityFeeds.meta.page),
        }).then(() => {
            setPaginateLoading(false);
        });
    };

    const activityStats: IStudentsActivityCardProps[] =
        studentsStatsData?.getStudentsActivityStats.map((stats) => ({
            activityText: statsTexts[stats.level],
            contentValues: [
                ACTIVITY_LEVEL.DISCONNECTED,
                ACTIVITY_LEVEL.PENDING,
            ].includes(stats.level)
                ? undefined
                : {
                      courses: Number(stats.courses),
                      experiences: Number(stats.liveExperiences),
                      videos: Number(stats.standaloneVideos),
                  },
            frequencyValue: stats.level,
            students: stats.usersCount,
            studentsPercentage: stats.percentage,
        })) ?? [];

    useEffect(() => {
        activityFeed({
            variables: generateVariables(),
        });
    }, [filters]);

    return {
        activityStats,
        feedData,
        getFilters,
        loading,
        onLoadMore,
        paginateLoading,
        resetFilters,
        setResetFilters,
        studentsStatsLoading,
    };
};
