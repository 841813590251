import styled, { css } from 'styled-components';
import TypeformTip from '@components/TypeformTip/TypeformTip';
import {
    NavigationWrapper,
    StyledSimpleBar,
} from '@components/_global/Navigation/Navigation.styled';

export const ChildNavigationWrapper = styled(NavigationWrapper)`
    display: grid;
    grid-template-rows: 80px 1fr 96px;
    height: 100vh;
    z-index: 9;
`;

export const SimpleBarWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ChildSimpleBar = styled(StyledSimpleBar)`
    max-height: calc(100vh - 176px);
    position: relative;
    top: 0;
    transform: none;
`;

export const StyledTypeformTip = styled(TypeformTip)`
    display: none;
`;

export const TypeformIconWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    position: relative;
`;

export const TypeformCircle = styled.div(
    ({
        theme: {
            borderRadius,
            colors,
            icon_shadow_light,
            icon_shadow,
            transitions,
        },
    }) => css`
        align-items: center;
        background-color: ${colors.icons};
        border-radius: ${borderRadius.circle}%;
        box-shadow: ${icon_shadow_light};
        cursor: pointer;
        display: flex;
        height: 52px;
        justify-content: center;
        margin-bottom: 30px;
        transition: ${transitions.default}s;
        width: 52px;

        &:hover {
            box-shadow: ${icon_shadow};

            ${StyledTypeformTip} {
                display: block;
            }
        }
    `,
);
