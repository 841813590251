import React, { useRef } from 'react';
import { ModalContent } from '@components/_modals/GlobalModals.styled';
import { StyledTitle } from './ChangePasswordModal.styled';
import { useOnClickOutside } from '@utils/hooks';
import ChangePasswordForm from '@domains/Parent/Account/_forms/ChangePasswordForm/ChangePasswordForm';
import CloseModalButton from '@components/_universal/CloseModalButton/CloseModalButton';
import Modal from '@components/_universal/Modal/Modal';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const ChangePasswordModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsModalOpen(false));

    return (
        <Modal>
            <ModalContent {...{ ref }}>
                <CloseModalButton onClose={() => setIsModalOpen(false)} />
                <StyledTitle>Change password</StyledTitle>

                <ChangePasswordForm closeModal={() => setIsModalOpen(false)} />
            </ModalContent>
        </Modal>
    );
};

export default ChangePasswordModal;
