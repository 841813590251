import React from 'react';
import {
    DescriptionText,
    DoneExperienceWrapper,
} from '../ExperienceInfo.styled';

interface DoneExperienceProps {
    className?: string;
}

export const DoneExperience = ({ className }: DoneExperienceProps) => {
    return (
        <DoneExperienceWrapper className={className}>
            <DescriptionText>
                Thanks for your participation. Now you can notify your students
                so they can complete the experience.
            </DescriptionText>
        </DoneExperienceWrapper>
    );
};
