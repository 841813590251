import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import {
    DefaultText,
    SubTitle,
} from '@components/_universal/Typography.styled';

export const RateCourseWrapper = styled.div`
    margin-top: 80px;
`;

export const InnerWrapper = styled.div``;

export const Title = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0 16px 0 0;
`;

export const Text = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    margin-bottom: 40px;
`;

export const RateSubmitedText = styled(DefaultText)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 14px 0 14px;
    max-width: 290px;
    text-align: center;
`;

export const RateSubmitedBakcgound = styled(IconManager)`
    position: absolute;
    top: -60px;
    z-index: -9;
`;

export const RateSubmitedWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
`;
