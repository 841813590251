import styled from 'styled-components';
import Footer from '@components/_global/Footer/Footer';
import Navigation from '@components/_global/Navigation/ChildNavigation/ChildNavigation';
import ChildHeader from '@components/_global/Header/ChildHeader/ChildHeader';

export const CoursesLayoutWrapper = styled.div`
    display: grid;
    grid-template-areas:
        'navigation content'
        'navigation content'
        'navigation content';
    grid-template-columns: 120px 1fr;
    grid-template-rows: 30px 1fr;
    min-height: 100%;
    width: 100%;
`;

export const HeaderBar = styled(ChildHeader)``;

export const NavigationBar = styled(Navigation)`
    grid-area: navigation;
`;

export const Content = styled.div`
    display: grid;
    grid-area: content;
    grid-template-columns: auto;
    grid-template-rows: 1fr 90px;
`;

export const FooterBar = styled(Footer)`
    margin-right: 100px;
`;
