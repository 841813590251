import React from 'react';
import { ColorType } from '@@types/CommonTypes';
import { LoaderIcon, LoaderWrapper } from './Loader.styled';

interface IProps {
    center?: boolean;
    className?: string;
    fill?: ColorType[];
    size?: LoaderSize;
}

export type LoaderSize = 'small' | 'medium' | 'large';

const Loader: React.FC<IProps> = ({ center, className, fill, size }) => {
    return (
        <LoaderWrapper size={size} center={center} className={className}>
            <LoaderIcon fill={fill} name={'Loader'} size={24} />
        </LoaderWrapper>
    );
};

export default Loader;
