import React, { useEffect } from 'react';
import { setLiveExperiencesVisibility } from '@state/_redux/liveExperiencesVisibility/actions';
import { useDispatch } from 'react-redux';
import { useSchoolQuery } from '@state/teachers/queries';

export const useLiveExperienceVisibility = () => {
    const { data, loading } = useSchoolQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        data &&
            dispatch(setLiveExperiencesVisibility(data?.getSchool.enableLE));
    }, [data]);

    return loading;
};
