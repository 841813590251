import React from 'react';
import { AgreeRulesFormWrapper, StyledCheckbox } from './AgreeRulesForm.styled';
import { FormRow, InputWrapper } from '@domains/Auth/_forms/GlobalForm.styled';
import { useFormContext } from 'react-hook-form';

interface IProps {}

const AgreeRulesForm: React.FC<IProps> = () => {
    const { errors, register } = useFormContext();

    return (
        <AgreeRulesFormWrapper>
            <FormRow>
                <InputWrapper>
                    <StyledCheckbox
                        errors={errors.agreeRules}
                        marginBottom={0}
                        name={'agreeRules'}
                        register={register}
                    >
                        Yes, I agree to the good behaviour agreement.
                    </StyledCheckbox>
                </InputWrapper>
            </FormRow>
        </AgreeRulesFormWrapper>
    );
};

export default AgreeRulesForm;
