import styled from 'styled-components';
import { SubTitle } from '@components/_universal/Typography.styled';

export const CoursesPageWrapper = styled.div``;

export const PageTitle = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    padding-top: 34px;
    padding-left: 100px;
`;
