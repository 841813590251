import { SignupLayout } from '@components/index';
import { ChooseYourPlanPage, SubscriptionPage } from '@domains/index';
import { RouterConfig } from '..';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import paths from '@shared/paths';

export const subscriptionRoutes: RouterConfig = [
    {
        allowFor: USER_TYPE.PARENT,
        isPrivate: true,
        routes: [
            {
                component: SubscriptionPage,
                exact: true,
                path: paths.subscription,
            },
        ],
        layoutComponent: SignupLayout,
    },
    {
        allowFor: USER_TYPE.PARENT,
        isPrivate: true,
        routes: [
            {
                component: ChooseYourPlanPage,
                exact: true,
                path: paths.choose_your_plan,
            },
        ],
        layoutComponent: (props) => (
            <SignupLayout {...props} backgroundPosition="right" />
        ),
    },
];
