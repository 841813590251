import { gql, useMutation } from '@apollo/client';
import {
    DeactivateChildAccountMutation,
    DeactivateChildAccountMutationVariables,
} from '@@types/graphql/DeactivateChildAccountMutation';
import { ToastTypes } from '@@types/CommonTypes';
import { notify } from '@components/ToastNotification/ToastNotification';
import { SetUserStatusFragment } from '@@types/graphql/SetUserStatusFragment';
import { USER_STATUS } from '@@types/graphql/GraphqlGlobalTypes';
import client from '@boot/apolloClient';

const DEACTIVATE_CHILD_ACCOUNT_MUTATION = gql`
    mutation DeactivateChildAccountMutation($childId: Int!) {
        deactivateChildAccount(childId: $childId)
    }
`;

const FRAGMENT = gql`
    fragment SetUserStatusFragment on User {
        status
        deactivateAtCurrentPeriodEnd
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<
        DeactivateChildAccountMutation,
        DeactivateChildAccountMutationVariables
    >(DEACTIVATE_CHILD_ACCOUNT_MUTATION, {
        onCompleted: (data) => {
            onCompleted?.();
            if (!data) return;
            client.writeFragment<SetUserStatusFragment>({
                id: `User:${data.deactivateChildAccount}`,
                fragment: FRAGMENT,
                data: {
                    status: USER_STATUS.DEACTIVATED,
                    deactivateAtCurrentPeriodEnd: true,
                },
            });
            notify(`Child's account has been deactivated.`)[
                ToastTypes.DEFAULT
            ]();
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
