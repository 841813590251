import styled from 'styled-components';
import { IconManager } from '@components/_universal';

export const OrnamentSectionWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 48px 85px 96px 0;
    position: relative;
`;

export const DashboardHumanOrnamentIcon = styled(IconManager)`
    z-index: 9;
`;

export const BackgroundDecorator = styled(IconManager)`
    position: absolute;
    left: -70px;
    top: -50px;
`;
