import styled, { css } from 'styled-components';
import { Button } from '@components/_universal';
import {
    DefaultText,
    LinkText,
    Title,
} from '@components/_universal/Typography.styled';

export const EmptyStudentsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const EmptyBox = styled.div(
    ({ theme: { colors, borderRadius } }) => css`
        align-items: center;
        background: ${colors.background_secondary};
        border-radius: ${borderRadius.button}px;
        border: 1px solid ${colors.stroke};
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 56px 30px;
        position: relative;
    `,
);

export const EmptyBoxText = styled.div(
    ({ theme: { colors } }) => css`
        color: ${colors.text_secondary};
        margin: 0;
        max-width: 700px;
        text-align: center;
    `,
);

export const HeaderText = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 32px;
    `,
);

export const TextBold = styled(DefaultText)(
    ({ theme: { colors, fontWeights } }) => css`
        color: ${colors.text_secondary};
        font-weight: ${fontWeights.semiBold};
        margin: 0;
        max-width: 700px;
        text-align: center;
    `,
);

export const StyledLinkText = styled(LinkText)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.primary};
        font-size: ${fontSizes.m}px;
        font-weight: ${fontWeights.bold};
        line-height: 1.65;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            text-underline-position: under;
        }
    `,
);

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 32px;
`;

export const StyledButton = styled(Button)`
    margin-right: 16px;
`;
