import styled from 'styled-components';
import { breakpoints } from '@shared/constants';

export const CreatePasswordPageWrapper = styled.div`
    max-width: 748px;
    margin: 0 auto;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 24px;
    }

    @media (max-width: ${breakpoints.md}px) {
        max-width: 600px;
    }
`;
