import { gql, useMutation } from '@apollo/client';
import {
    StartVideoMutation,
    StartVideoMutationVariables,
} from '@@types/graphql/StartVideoMutation';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

const START_VIDEO_MUTATION = gql`
    mutation StartVideoMutation($standaloneVideoId: String!) {
        startConsumeStandaloneVideo(standaloneVideoId: $standaloneVideoId) {
            id
        }
    }
`;

export default () => {
    return useMutation<StartVideoMutation, StartVideoMutationVariables>(
        START_VIDEO_MUTATION,
        {
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
        },
    );
};
