import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import Avatar from '@components/_universal/Avatar/Avatar';

export const DesktopChooseAvatarPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;

export const ChooseAvatarPageContent = styled.div`
    flex: 1;
`;

export const ChooseAvatarPageFooter = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    @media (max-height: 768px) {
        margin-top: 16px;
    }
`;

export const ChooseYourAvatarIcon = styled(IconManager)`
    margin-top: -30px;

    @media (max-height: 768px) {
        margin-top: -52px;
    }
`;

export const ChooseAvatarOrnamentIcon = styled(IconManager)`
    position: absolute;
    right: -150px;
    top: -80px;
    z-index: 9;
`;

export const ChooseAvatarTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 16px;
`;

export const ChooseAvatarDescription = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.7;
    max-width: 585px;
    position: relative;
    z-index: 9;
`;

export const AvatarList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    width: 600px;
    position: relative;
    z-index: 9;
`;

export const StyledAvatar = styled(Avatar)`
    margin: 24px 0;
    width: 25%;

    @media (max-height: 768px) {
        margin: 16px 0;
    }

    .AvatarSelectedIcon {
        @media (max-height: 768px) {
            font-size: 90px;
        }
    }

    .AvatarImageIcon {
        box-shadow: ${({ theme }) => theme.light_green_shadow};
        border-radius: ${({ theme }) => theme.borderRadius.circle}%;
        transition: all ${({ theme }) => theme.transitions.default}s;

        &:hover {
            box-shadow: ${({ theme }) => theme.dark_green_shadow};
        }

        @media (max-height: 768px) {
            font-size: 60px;
        }
    }
`;

export const StyledContinueButton = styled(Button)`
    z-index: 9;
`;
