import React from 'react';
import { IconManager } from '@components/_universal';
import Modal from '@components/_universal/Modal/Modal';
import NewChildForm from '@domains/Parent/Account/_forms/NewChildForm/NewChildForm';
import {
    AddNewChildModalWrapper,
    CloseButton,
    InformationText,
    StyledBigTitle,
} from './AddNewChildModal.styled';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const AddNewChildModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    return (
        <Modal>
            <AddNewChildModalWrapper>
                <CloseButton onClick={() => setIsModalOpen(false)}>
                    <IconManager name="Close" size={33} />
                </CloseButton>
                <InformationText>child personal information</InformationText>
                <StyledBigTitle>Add child</StyledBigTitle>
                <NewChildForm callback={() => setIsModalOpen(false)} />
            </AddNewChildModalWrapper>
        </Modal>
    );
};

export default AddNewChildModal;
