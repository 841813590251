import styled from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { breakpoints } from '@shared/constants';

export const ResetPasswordPageWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 68px 0 150px;

    @media (max-width: ${breakpoints.lg}px) {
        margin: 68px 0 100px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        align-self: center;
        margin: 28px 0 60px;
    }
`;

export const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.45;
    margin-bottom: 16px;
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
        margin-bottom: 12px;
    }
`;

export const Subtitle = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 50px;
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.s}px;
        line-height: 1.45;
        margin-bottom: 40px;
    }
`;
