import React, { useRef } from 'react';
import { addStudentsList } from '@state/_redux/uploadCSV/actions';
import { CSVReader } from 'react-papaparse';
import { IUploadStudentsTableItem } from '@domains/Teacher/UploadStudents/UploadStudentsPage/_components/UploadStudentsList/UploadStudentsList';
import { setTooManyStudnetsUploadModal } from '@state/_redux/layout/actions';
import { UploadButton } from './StudentsListUploader.styled';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useSchoolLicensesQuery } from '@state/teachers/queries';
import paths from '@shared/paths';

interface IProps {}

const STUDENTS_LIST_ROW_LENGTH = 5;

const StudentsListUploader: React.FC<IProps> = ({ children }) => {
    const { data } = useSchoolLicensesQuery();
    const licencesData = data?.getSchool;

    const history = useHistory();
    const dispatch = useDispatch();
    const buttonRef = useRef<any>();

    const handleOpenDialog = (e?: any) => {
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    const handleUploadFile = (data: any) => {
        // remove first element (header table)
        data.splice(0, 1);

        // remove empty elements
        data = data.filter(
            (record: { data: string[] }) =>
                record.data.length === STUDENTS_LIST_ROW_LENGTH,
        );

        // parsing data
        const studentsList: IUploadStudentsTableItem[] = data.map(
            (record: { data: string[] }, index: number) => ({
                className: record.data[3],
                email: record.data[2],
                id: index,
                name: record.data[0],
                surname: record.data[1],
                year: record.data[4],
            }),
        );

        if (
            licencesData &&
            licencesData.licensesInUse + studentsList.length >
                licencesData.noLicenses
        ) {
            dispatch(setTooManyStudnetsUploadModal(true));
        } else {
            dispatch(addStudentsList(studentsList));
            history.push(paths.upload_teacher);
        }
    };

    const handleUploadError = (
        err: any,
        file: any,
        inputElem: any,
        reason: any,
    ) => {
        console.log('Upload error :: ', err);
        console.log('Upload file :: ', file);
        console.log('Upload inputElem :: ', inputElem);
        console.log('Upload reason :: ', reason);
    };

    return (
        <CSVReader
            config={{}}
            noClick
            noDrag
            onError={handleUploadError}
            onFileLoad={handleUploadFile}
            ref={buttonRef}
            style={{}}
        >
            {({ file }: any) => (
                <div onClick={handleOpenDialog}>
                    {children ? (
                        children
                    ) : (
                        <UploadButton
                            icon={{
                                color: ['text_white'],
                                size: 16,
                                type: 'Upload',
                            }}
                        >
                            Upload a csv file
                        </UploadButton>
                    )}
                </div>
            )}
        </CSVReader>
    );
};

export default StudentsListUploader;
