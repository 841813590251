import { DefaultText } from '../Typography.styled';
import { FontSizes } from '@@types/CommonTypes';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';

export const StyledTooltip = styled(ReactTooltip)`
    &.__react_component_tooltip {
        border-radius: 20px;
        max-width: 600px;
        padding: 32px;
    }

    &.__react_component_tooltip.show {
        opacity: 1;
    }
`;

export const TooltipText = styled(DefaultText)<{ fontSize?: FontSizes }>(
    ({ theme: { colors, fontSizes }, fontSize }) => css`
        color: ${colors.text_white};
        font-size: ${fontSize ? fontSize : fontSizes.s}px;
        line-height: 1.16;
        margin-bottom: 0;
    `,
);
