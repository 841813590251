import {
    ParentAccountLayout,
    ParentAccountSubLayout,
    ParentMainLayout,
} from '@components/index';
import {
    AccountPage,
    CourseParentPage,
    DashboardParentPage,
    HelpFaqParentPage,
    InformationParentPage,
    LegalParentPage,
    LiveExperienceParentPage,
    NotificationSettingsParentPage,
    SubscriptionParentPage,
} from '@domains/index';
import { RouterConfig } from '..';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import paths from '@shared/paths';
import renderRedirect from '@services/RenderRedirect';

export const parentRoutes: RouterConfig = [
    {
        allowFor: USER_TYPE.PARENT,
        isPrivate: true,
        routes: [
            {
                component: AccountPage,
                path: paths.account_parent,
                subRoutes: {
                    routes: [
                        {
                            component: renderRedirect(
                                paths.account_information_parent,
                            ),
                            exact: true,
                            path: paths.account_parent,
                        },
                        {
                            component: InformationParentPage,
                            exact: true,
                            path: paths.account_information_parent,
                        },
                        {
                            component: NotificationSettingsParentPage,
                            exact: true,
                            path: paths.account_notification_settings_parent,
                        },
                        {
                            component: SubscriptionParentPage,
                            exact: true,
                            path: paths.account_subscription_parent,
                        },
                        {
                            component: HelpFaqParentPage,
                            exact: true,
                            path: paths.account_help_and_faq_parent,
                        },
                        {
                            component: LegalParentPage,
                            exact: true,
                            path: paths.account_legal_parent,
                        },
                    ],
                    layoutComponent: ParentAccountSubLayout,
                },
            },
        ],
        layoutComponent: ParentAccountLayout,
    },
    {
        allowFor: USER_TYPE.PARENT,
        isPrivate: true,
        routes: [
            {
                component: CourseParentPage,
                exact: true,
                path: paths.course_parent(':childId', ':courseId'),
            },
        ],
        layoutComponent: ParentMainLayout,
    },
    {
        allowFor: USER_TYPE.PARENT,
        isPrivate: true,
        routes: [
            {
                component: LiveExperienceParentPage,
                exact: true,
                path: paths.live_experience_parent(
                    ':childId',
                    ':liveExperienceId',
                ),
            },
        ],
        layoutComponent: ParentMainLayout,
    },
    {
        allowFor: USER_TYPE.PARENT,
        isPrivate: true,
        routes: [
            {
                component: DashboardParentPage,
                exact: true,
                path: paths.dashboard_parent(),
            },
            {
                component: DashboardParentPage,
                exact: true,
                path: paths.dashboard_parent(':childId'),
            },
        ],
        layoutComponent: ParentMainLayout,
    },
];
