import { Button } from '@components/_universal';
import styled from 'styled-components';

export const CreatePasswordFormWrapper = styled.form`
    width: 536px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 12px;
`;

export const StyledButton = styled(Button)`
    min-width: 260px;

    & + & {
        margin-left: 16px;
    }
`;
