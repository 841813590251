import React from 'react';
import { CloseModalButtonWrapper } from './CloseModalButton.styled';
import { IconManager } from '@components/_universal';

export interface IProps {
    className?: string;
    onClose: () => void;
}

const CloseModalButton: React.FC<IProps> = ({ className, onClose }) => {
    return (
        <CloseModalButtonWrapper className={className} onClick={onClose}>
            <IconManager name="Close" size={24} />
        </CloseModalButtonWrapper>
    );
};

export default CloseModalButton;
