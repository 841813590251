import React from 'react';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import {
    CardIcon,
    Description,
    DetailsCardWrapper,
    DetailsWrapper,
    IconWrapper,
    StyledTitle,
} from './DetailsCard.styled';

interface DetailsCardProps {
    className?: string;
    children: string;
    iconName: 'Mentor' | 'TimeDuration' | 'Schedule';
    title: string;
    userType?: USER_TYPE;
}

export const DetailsCard = ({
    children,
    className,
    iconName = 'Mentor',
    title = '',
    userType,
}: DetailsCardProps) => {
    return (
        <DetailsCardWrapper className={className}>
            <IconWrapper userType={userType}>
                <CardIcon name={iconName} size={46} />
            </IconWrapper>
            <DetailsWrapper>
                <StyledTitle>{title}</StyledTitle>
                <Description>{children}</Description>
            </DetailsWrapper>
        </DetailsCardWrapper>
    );
};
