import styled, { css } from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';
import { Button, IconManager } from '@components/_universal';

export const ProgressWrapper = styled.div<{ smallPadding?: boolean }>(
    ({ smallPadding }) => css`
        padding: ${smallPadding
            ? '80px 100px 20px 100px'
            : '80px 100px 150px 100px'};
    `,
);

export const StyledTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 40px;
`;

export const Description = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 32px;
    max-width: 480px;
`;

export const ButtonWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export const StyledButton = styled(Button)``;

export const LoupeIcon = styled(IconManager)`
    margin-left: 24px;
`;
