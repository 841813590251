import styled from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';

export const UploadAreaWrapper = styled.div`
    margin-bottom: 80px;
`;

export const UploadAreaInput = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.icons_hover};
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    border: 3px dashed ${({ theme }) => theme.colors.stroke};
    display: flex;
    flex-direction: column;
    height: 210px;
    justify-content: center;
    outline: none;
    width: 100%;
`;

export const UploadAreaText = styled(DefaultText)`
    display: inline;
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 20px 0 0;
    text-transform: uppercase;
`;

export const UploadAreaHighlightedText = styled(UploadAreaText)`
    color: ${({ theme }) => theme.colors.secondary};
`;

export const UploadAreaInfoText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    margin: 0;
`;

export const UploadProgressWrapper = styled.div`
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    border: 3px solid ${({ theme }) => theme.colors.icons_hover};
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: center;
    outline: none;
    padding: 0 64px;
    width: 100%;
`;

export const UploadProgressInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
`;

export const UploadProgressFileName = styled(DefaultText)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
`;

export const UploadProgressValue = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
`;

export const UploadProgressInfoText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 16px auto 0;
`;
