import { useLiveExperienceVisibility } from '@domains/Teacher/Library/LibraryTeacherPage/useLiveExperienceVisibility';
import { useSelector } from 'react-redux';
import ChatWithSupport from '@components/_universal/ChatWithSupport/ChatWithSupport';
import React from 'react';
import {
    Content,
    FooterBar,
    HeaderBar,
    InnerWrapper,
    NavigationBar,
    TeacherMainLayoutWrapper,
} from './TeacherMainLayout.styled';

interface IProps {}

export const TeacherMainLayout: React.FC<IProps> = ({ children }) => {
    const loading = useLiveExperienceVisibility();
    const { isAdminAsTeacher } = useSelector((state) => state.signup);

    return (
        <TeacherMainLayoutWrapper>
            <HeaderBar trialIcon />
            <NavigationBar borderLine />
            {!loading && (
                <Content>
                    <InnerWrapper paddingTop={isAdminAsTeacher}>
                        {children}
                    </InnerWrapper>
                    <FooterBar isLogo />
                </Content>
            )}
            <ChatWithSupport />
        </TeacherMainLayoutWrapper>
    );
};

export default TeacherMainLayout;
