import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { DefaultText } from '@components/_universal/Typography.styled';
import { Link } from 'react-router-dom';
import TrialInformation from '@components/_universal/TrialInformation/TrialInformation';

export const LoginFooterWrapper = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.secondary};
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    padding: 12px;
    position: relative;
    width: 100%;

    @media (max-width: ${breakpoints.sm}px) {
        margin-top: 42px;
        padding: 24px 12px;
    }
`;

export const TextContainer = styled.div`
    margin-left: 16px;

    @media (max-width: ${breakpoints.sm}px) {
        margin-left: 0;
    }
`;

export const FooterText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    line-height: 1.5;
    margin-bottom: 0;

    @media (max-width: ${breakpoints.sm}px) {
        text-align: center;
    }
`;

export const StyledText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    @media (max-width: ${breakpoints.sm}px) {
        text-align: center;
    }
`;

export const StyledLinkText = styled(Link)`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-decoration: none;
    text-underline-position: under;

    &:hover {
        text-decoration: underline;
    }
`;

export const StyledTrialInformation = styled(TrialInformation)`
    @media (max-width: ${breakpoints.sm}px) {
        left: 50%;
        position: absolute;
        top: -64px;
        transform: translateX(-50%) scale(0.7);
    }
`;
