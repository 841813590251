import { DefaultText } from '@components/_universal/Typography.styled';
import styled, { css } from 'styled-components';

export const StyledTooltip = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background: ${colors.tooltop_background};
        border-radius: ${borderRadius.bubble}px;
        display: grid;
        grid-gap: 16px;
        height: 225px;
        max-width: 468px;
        padding: 32px;
        position: relative;

        &:before {
            border-bottom: 10px solid transparent;
            border-right: 15px solid ${colors.tooltop_background};
            border-top: 10px solid transparent;
            bottom: 32px;
            content: '';
            height: 0;
            left: -15px;
            position: absolute;
            width: 0;
        }
    `,
);

export const TooltipText = styled(DefaultText)(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        color: ${colors.text_white};
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.5;
        margin-bottom: 0;
    `,
);
