import { gql, useMutation } from '@apollo/client';
import { CancelSubscriptionMutation } from '@@types/graphql/CancelSubscriptionMutation';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import { CHILDS_LIST_QUERY } from '@state/childManagement/queries/useChildsListQuery';

const CANCEL_SUBSCRIPTION_MUTATION = gql`
    mutation CancelSubscriptionMutation {
        cancelSubscription
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<CancelSubscriptionMutation>(
        CANCEL_SUBSCRIPTION_MUTATION,
        {
            onCompleted: async () => {
                onCompleted?.();
                notify('Your subscription has been successfully cancelled.')[
                    ToastTypes.SUCCESS
                ]();
            },
            onError: () => {
                notify(
                    'Unfortunately, we cannot cancel your subscription now. Please try again later or contact our support!',
                )[ToastTypes.ERROR]();
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: CHILDS_LIST_QUERY,
                },
            ],
        },
    );
};
