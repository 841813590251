import React from 'react';
import { ColorType, IconName, PlaceholderCardTypes } from '@@types/CommonTypes';
import IconManager from '@components/_universal/IconManager/IconManager';
import {
    PlaceholderCardBackground,
    PlaceholderCardBottomIcon,
    PlaceholderCardContent,
    PlaceholderCardText,
    PlaceholderCardWrapper,
} from './PlaceholderCard.styled';
import { DEFAULT_CARD_WIDTHS } from '@components/_universal/Cards/Cards.styled';
import CardHolder from '@components/_universal/Cards/CardHolder/CardHolder';

interface Icon {
    fill?: ColorType[];
    name: IconName;
    size: number;
    sizeY?: number;
}

export interface IProps {
    bottomIcon?: Icon;
    className?: string;
    featured?: boolean;
    link: string;
    onClick?: () => void;
    text: string;
    topIcon?: Icon;
    variant?: PlaceholderCardTypes;
    width?: number | string;
}

const PlaceholderCard: React.FC<IProps> = ({
    bottomIcon,
    className,
    featured,
    link,
    onClick,
    text,
    topIcon,
    variant = 'primary',
    width,
}) => {
    const isPrimary = variant === 'primary';

    return (
        <CardHolder
            className={className}
            featured={featured}
            link={link}
            onClick={onClick}
            width={width ?? DEFAULT_CARD_WIDTHS.placeholder}
        >
            <PlaceholderCardWrapper variant={variant} withHover={isPrimary}>
                {isPrimary && (
                    <PlaceholderCardBackground
                        name="PlaceholderCardBackground"
                        size={242}
                    />
                )}
                <PlaceholderCardContent>
                    {topIcon && <IconManager {...topIcon} />}
                    <PlaceholderCardText center variant={variant}>
                        {text}
                    </PlaceholderCardText>
                </PlaceholderCardContent>
                {bottomIcon && <PlaceholderCardBottomIcon {...bottomIcon} />}
            </PlaceholderCardWrapper>
        </CardHolder>
    );
};

export default PlaceholderCard;
