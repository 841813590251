import paths from '@shared/paths';
import {
    useChildHomeworkQuery,
    useChildsListQuery,
} from '@state/childManagement/queries';
import { useResendEmailMutation } from '@state/settings/mutations';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

export default () => {
    const { push } = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [shownFeedbacks, setShownFeedbacks] = useState<number[]>([]);
    const { childId } = useParams<{ childId: string }>();

    const {
        data: childsListData,
        loading: childsListLoading,
    } = useChildsListQuery();
    const {
        data: childHomeworkData,
        loading: childsHomeworkLoading,
    } = useChildHomeworkQuery(childId);

    const [resendEmail] = useResendEmailMutation();

    const loading = childsListLoading || childsHomeworkLoading;
    const currentChild = childsListData?.getChildsForParent.find(
        (child) => child.id === parseInt(childId),
    );
    const currentEmail =
        childsListData?.getChildsForParent.find(
            (child) => child?.id === Number(childId),
        )?.email ?? '';
    const isEmptyDashboard = !childsListData?.getChildsForParent.length;

    const handleFeedback = (id: number) =>
        setShownFeedbacks((prevState) =>
            prevState.includes(id)
                ? prevState.filter((item) => item !== id)
                : [...prevState, id],
        );

    const handleResendEmail = () =>
        resendEmail({
            variables: { email: currentEmail },
        });

    useEffect(() => {
        ((!childId && !isEmptyDashboard) || (!loading && !currentChild)) &&
            childsListData?.getChildsForParent?.[0]?.id &&
            push(
                paths.dashboard_parent(
                    `${childsListData?.getChildsForParent?.[0]?.id}`,
                ),
            );
    }, [childId, isEmptyDashboard, loading, currentChild]);

    return {
        childHomeworkData,
        childId,
        currentChild,
        handleFeedback,
        handleResendEmail,
        isEmptyDashboard,
        loading,
        setShowModal,
        showModal,
        shownFeedbacks,
    };
};
