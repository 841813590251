import React from 'react';
import {
    CarouselWrapper,
    NextButton,
    PrevButton,
    StyledIconButton,
    SwiperWrapper,
} from './Carousel.styled';
import { Swiper } from 'swiper/swiper-react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface IProps extends Swiper {
    arrowsOffset?: number;
    className?: string;
    disabled?: boolean;
    nextButtonClass?: string;
    prevButtonClass?: string;
    sliderRows?: number;
    slidesPerView?: number;
    spaceBetween?: number;
    withArrows?: boolean;
}

const Carousel: React.FC<IProps> = ({
    arrowsOffset,
    children,
    className,
    disabled,
    nextButtonClass,
    prevButtonClass,
    sliderRows = 1,
    slidesPerView,
    spaceBetween = 32,
    withArrows = true,
    ...rest
}) => {
    return (
        <CarouselWrapper {...{ className, arrowsOffset }}>
            <SwiperWrapper
                allowSlideNext={!disabled}
                allowSlidePrev={!disabled}
                allowTouchMove={!disabled}
                navigation={
                    !disabled && {
                        nextEl: `.${nextButtonClass}`,
                        prevEl: `.${prevButtonClass}`,
                    }
                }
                noSwiping={disabled}
                spaceBetween={spaceBetween}
                slidesPerView={slidesPerView ?? 'auto'}
                slidesPerColumnFill={sliderRows > 1 ? 'row' : 'column'}
                slidesPerColumn={sliderRows ? sliderRows : 1}
                {...rest}
            >
                {children}
            </SwiperWrapper>
            {withArrows && (
                <>
                    <PrevButton
                        className={`swiper-button-prev ${prevButtonClass}`}
                    >
                        <StyledIconButton
                            icon={{
                                activeColor: ['text_white'],
                                color: ['text_white'],
                                type: 'ChevronLeft',
                            }}
                        />
                    </PrevButton>
                    <NextButton
                        className={`swiper-button-next ${nextButtonClass}`}
                    >
                        <StyledIconButton
                            icon={{
                                activeColor: ['text_white'],
                                color: ['text_white'],
                                type: 'ChevronRight',
                            }}
                        />
                    </NextButton>
                </>
            )}
        </CarouselWrapper>
    );
};

export default Carousel;
