import { useMemo } from 'react';
import { IMosaicCard } from '@@types/CommonTypes';
import { useHistory } from 'react-router-dom';
import Loader from '@components/_universal/Loader/Loader';
import parseContentToCards from '@utils/parseContentToCards';
import parseRepetableDisplayData from '@utils/parseRepetableDisplayData';
import paths from '@shared/paths';
import RepetableDisplayCards from '@components/RepetableDisplayCards/RepetableDisplayCards';
import useCalcElementsNumber from '@utils/hooks/useCalcElementsNumber';
import useSavedForLaterContentQuery from '@state/lists/queries/useSaveForLaterContentQuery';
import {
    Description,
    HeartIcon,
    InnerWrapper,
    LoaderWrapper,
    SavedWrapper,
    StyledButton,
    StyledSeeAllButton,
    StyledTitle,
    TitleWrapper,
} from './Saved.styled';

interface IProps {
    showLiveExperience?: boolean;
}

const Saved: React.FC<IProps> = ({ showLiveExperience }) => {
    const { data, loading } = useSavedForLaterContentQuery();
    const { ref, numberElements } = useCalcElementsNumber({});
    const similarContentData = data?.getSavedForLaterContent ?? [];
    const history = useHistory();
    const parsedContentToCards = parseContentToCards(similarContentData);
    const parsedData = useMemo(
        () =>
            parseRepetableDisplayData(
                showLiveExperience
                    ? parsedContentToCards
                    : parsedContentToCards.filter(
                          (item: IMosaicCard) =>
                              item.type !== 'LIVE_EXPERIENCE',
                      ),
            ),
        [data],
    );

    const showMosaic = !!parsedData.length;
    return (
        <SavedWrapper>
            <TitleWrapper withMosaic={showMosaic}>
                <StyledTitle>Saved for later</StyledTitle>
                <HeartIcon name={'SaveHeart'} size={45} sizeY={46} />
                {showMosaic && (
                    <StyledSeeAllButton
                        buttonType="secondary"
                        onPress={() => history.push(paths.save_for_later)}
                        size="small"
                    >
                        see all
                    </StyledSeeAllButton>
                )}
            </TitleWrapper>

            <InnerWrapper ref={ref}>
                {loading ? (
                    <LoaderWrapper>
                        <Loader size={'medium'} center />
                    </LoaderWrapper>
                ) : (
                    <>
                        {showMosaic && (
                            <RepetableDisplayCards
                                elements={[...parsedData].slice(
                                    0,
                                    numberElements,
                                )}
                            />
                        )}
                    </>
                )}
            </InnerWrapper>
            {!loading && !showMosaic && (
                <>
                    <Description>
                        You have not yet saved up courses and others to learn.
                        When you save something for later, it will be easier for
                        you to find it and come back to it at any time.
                    </Description>
                    <StyledButton
                        onPress={() =>
                            history.push(paths.dashboard + '#mostPopular')
                        }
                    >
                        See the most popular
                    </StyledButton>
                </>
            )}
        </SavedWrapper>
    );
};

export default Saved;
