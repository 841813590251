import { useEffect, useState } from 'react';
import { useSchoolGroups } from '@state/students/queries/useSchoolGroups';

const PAGE_LIMIT = 25;

export const useGroupsPage = () => {
    const [paginationState, setPaginationState] = useState({
        pageIndex: 0,
        pageSize: PAGE_LIMIT,
    });

    const [search, setSearch] = useState('');

    const { data: groupsList, loading } = useSchoolGroups({
        pagination: {
            offset: paginationState.pageIndex * paginationState.pageSize,
            limit: paginationState.pageSize,
        },
        search: {
            phrase: search ?? '',
        },
    });

    const [isGroupExists, setIsGroupExists] = useState(
        !!groupsList?.getSchoolGroups.count,
    );

    useEffect(() => {
        if (search) {
            return;
        }
        groupsList && setIsGroupExists(!!groupsList.getSchoolGroups.count);
    }, [groupsList, search]);

    return {
        groupsCount: groupsList?.getSchoolGroups.count,
        groupsList: groupsList?.getSchoolGroups.groups,
        isGroupExists,
        loading,
        search,
        setPaginationState,
        setSearch,
    };
};
