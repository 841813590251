import React, { useEffect } from 'react';
import { errorMessages } from '@utils/shared/validations/formErrors';
import { FieldError, useForm } from 'react-hook-form';
import { Input } from '@components/_form-elements';
import { useMatchMedia } from '@utils/hooks';
import { useSchoolGroups } from '@state/students/queries/useSchoolGroups';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import {
    ButtonsRow,
    GroupFormWrapper,
    StyledButton,
    StyledInputWrapper,
} from '@domains/Teacher/Students/_forms/Forms.styled';

export interface StudentsGroupForm {
    groupTitle: string;
}

interface GroupFormProps {
    buttonText: string;
    defaultData?: StudentsGroupForm;
    isError: boolean;
    onClose: () => void;
    onSubmit: (data: StudentsGroupForm) => void;
    setIsError: (value: boolean) => void;
}

export const GroupForm = ({
    buttonText,
    defaultData,
    isError,
    onClose,
    onSubmit,
    setIsError,
}: GroupFormProps) => {
    const { match } = useMatchMedia('md');

    const {
        errors,
        handleSubmit,
        register,
        watch,
    } = useForm<StudentsGroupForm>({
        defaultValues: defaultData,
        resolver: yupResolver(validation),
    });
    const groupTitle = watch('groupTitle');

    const { data: groupsList } = useSchoolGroups({
        search: {
            phrase: groupTitle ?? '',
        },
    });
    const groupsNames =
        groupsList?.getSchoolGroups.groups.map((group) => group.title) ?? [];

    useEffect(() => {
        const groupTitleExists = groupsNames.includes(groupTitle);

        if (defaultData?.groupTitle === groupTitle) {
            setIsError(false);
            return;
        }

        setIsError(groupTitleExists);
    }, [groupTitle, groupsNames]);

    return (
        <GroupFormWrapper noValidate onSubmit={handleSubmit(onSubmit)}>
            <StyledInputWrapper>
                <InputForm
                    errors={
                        isError
                            ? ({
                                  message: errorMessages.REPEATED_GROUP_TITLE,
                              } as FieldError)
                            : errors.groupTitle
                    }
                    label={'group title'}
                    marginBottom={match ? 8 : 24}
                    name={'groupTitle'}
                    placeholder={'Enter group title'}
                    register={register}
                    staticPosition={match}
                />
            </StyledInputWrapper>
            <ButtonsRow>
                <StyledButton
                    buttonType={'secondary'}
                    onPress={onClose}
                    type="button"
                >
                    cancel
                </StyledButton>
                <StyledButton buttonType={'primary'} onPress={() => {}}>
                    {buttonText}
                </StyledButton>
            </ButtonsRow>
        </GroupFormWrapper>
    );
};

const InputForm = FieldMessage(Input);
