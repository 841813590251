import { gql, useQuery } from '@apollo/client';
import { UserMeQuery } from '@@types/graphql/UserMeQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

export const USER_ME_QUERY = gql`
    query UserMeQuery {
        me {
            avatar
            id
            name
            surname
            type
            status
            gender
            email
            birthday
            address
            city
            zipCode
            countryCode
            parent {
                id
            }
            onboardingCompletedAt
            createdAt
            updatedAt
            hasPassword
            deactivateAtCurrentPeriodEnd
            subscriptionCurrentPeriodEnd
        }
    }
`;

export default () =>
    useQuery<UserMeQuery>(USER_ME_QUERY, {
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
