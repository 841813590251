import styled, { css } from 'styled-components';
import { breakpoints } from '@shared/constants';
import { Button } from '@components/_universal';
import { DefaultText, Title } from '@components/_universal/Typography.styled';

export const ModalContent = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background-color: ${colors.background_secondary};
        border-radius: ${borderRadius.card}px;
        box-shadow: 5px 5px 16px 0px rgba(36, 48, 224, 0.15);
        padding: 56px 80px;
        position: relative;
        width: 100%;
        margin: 0 auto;

        @media (max-width: ${breakpoints.md}px) {
            width: calc(100% - 56px);
        }

        @media (max-width: ${breakpoints.sm}px) {
            border-radius: ${({ theme }) => theme.borderRadius.button}px;
            padding: 40px 28px;
            width: calc(100% - 32px);
        }

        @media (max-width: ${breakpoints.xs}px) {
            padding: 40px 16px;
        }
    `,
);

export const ModalTitle = styled(Title)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.xxxl}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.45;
        margin-bottom: 16px;
    `,
);

export const ModalText = styled(DefaultText)`
    line-height: 1.75;
    margin: 0;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

export const ModalButton = styled(Button)`
    width: 234px;
`;

export const BoldText = styled.span(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        line-height: 1.75;
    `,
);
