import { gql, useLazyQuery } from '@apollo/client';
import {
    GetVideoLinkQuery,
    GetVideoLinkQueryVariables,
} from '@@types/graphql/GetVideoLinkQuery';

export const GET_VIDEO_LINK_QUERY = gql`
    query GetVideoLinkQuery($id: ID!) {
        getVideoById(_id: $id) {
            link
        }
    }
`;

export default () => {
    return useLazyQuery<GetVideoLinkQuery, GetVideoLinkQueryVariables>(
        GET_VIDEO_LINK_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );
};
