import React, { useMemo, useState } from 'react';
import { Cell, Column, useTable } from 'react-table';
import { editStudentsList } from '@state/_redux/uploadCSV/actions';
import { IconManager } from '@components/_universal';
import { IStudentForm } from '@domains/Teacher/Students/_forms/StudentForm/StudentForm';
import { Nullable } from '@@types/CommonTypes';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import EditStudentModal from '@domains/Teacher/Students/_modals/EditStudentModal/EditStudentModal';
import paths from '@shared/paths';
import Table from '@components/_universal/Table/Table';
import {
    IUploadStudentsError,
    UPLOAD_STUDENTS_ERRORS,
} from '@domains/Teacher/UploadStudents/UploadStudentsPage/UploadStudentsPage';
import {
    TableIconButton,
    TableWrapper,
} from '@components/_universal/Table/Table.styled';
import {
    StyledTableCell,
    UploadStudentsListWrapper,
} from './UploadStudentsList.styled';

export interface IProps {
    errorStudents: IUploadStudentsError[];
    setErrorStudents: (students: IUploadStudentsError[]) => void;
}

export interface IUploadStudentsTableItem {
    className: string;
    email: string;
    id: number;
    name: string;
    number: number;
    surname: string;
    year: string;
}

const UploadStudentsList: React.FC<IProps> = ({
    errorStudents,
    setErrorStudents,
}) => {
    const studentsList = useSelector((state) => state.uploadCSV.studentsList);
    const [editedStudent, setEditedStudent] = useState<
        Nullable<IUploadStudentsTableItem>
    >(null);

    const dispatch = useDispatch();

    const columns = useMemo<Column<IUploadStudentsTableItem>[]>(
        () => [
            {
                Header: 'No.',
                accessor: 'number',
                width: 60,
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Surname',
                accessor: 'surname',
            },
            {
                Header: 'E-mail',
                accessor: 'email',
                width: 300,
            },
            {
                Header: 'Class',
                accessor: 'className',
                width: 100,
            },
            {
                Header: 'Year',
                accessor: 'year',
                width: 100,
            },
        ],
        [],
    );

    const data = useMemo<IUploadStudentsTableItem[]>(
        () =>
            studentsList.map((student, index) => ({
                ...student,
                number: index + 1,
            })),
        [studentsList],
    );

    const schoolClasses = data.map((student) => ({
        className: student.className,
        year: student.year,
    }));

    const tableProps = useTable<IUploadStudentsTableItem>({
        columns,
        data,
    });

    const handleEditStudent = (data: IStudentForm) => {
        if (!editedStudent) return;

        dispatch(
            editStudentsList({
                ...data,
                className: data.className?.value as string,
                id: editedStudent.id,
                number: editedStudent.number,
                year: data.year?.value as string,
            }),
        );
        setEditedStudent(null);
        setErrorStudents(
            errorStudents.filter((student) => student.id !== editedStudent.id),
        );
    };

    const cellValidation = (cell: Cell, index: number): boolean => {
        const dataId = data[index]?.id;
        const columnId = cell.column.id;

        const isWrongEmail = !!(
            columnId === 'email' &&
            errorStudents.find(
                ({ id, type }) =>
                    id === dataId &&
                    type === UPLOAD_STUDENTS_ERRORS.EMAIL_ERROR,
            )
        );
        const isWrongYear = !!(
            columnId === 'year' &&
            errorStudents.find(
                ({ id, type }) =>
                    id === dataId && type === UPLOAD_STUDENTS_ERRORS.YEAR_ERROR,
            )
        );

        return isWrongEmail || isWrongYear;
    };

    if (!studentsList.length) return <Redirect to={paths.dashboard_teacher} />;

    return (
        <UploadStudentsListWrapper>
            {editedStudent && (
                <EditStudentModal
                    defaultData={{
                        ...editedStudent,
                        className: {
                            label: editedStudent.className,
                            value: editedStudent.className,
                        },
                        year: {
                            label: editedStudent.year,
                            value: editedStudent.year,
                        },
                    }}
                    defaultSchoolClasses={schoolClasses}
                    onClose={() => setEditedStudent(null)}
                    onSubmit={handleEditStudent}
                />
            )}
            <TableWrapper>
                <Table<IUploadStudentsTableItem> table={tableProps}>
                    {({
                        getTableBodyProps,
                        getTableProps,
                        headerGroups,
                        prepareRow,
                        rows,
                    }) => (
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(
                                            (column, index) => (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    key={`header ${index}`}
                                                    style={{
                                                        width: column.width,
                                                    }}
                                                >
                                                    {column.render('Header')}
                                                </th>
                                            ),
                                        )}
                                        <th style={{ width: 80 }} />
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, index) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            key={`row ${index}`}
                                        >
                                            {row.cells.map(
                                                (cell, cellIndex) => {
                                                    return (
                                                        <StyledTableCell
                                                            {...cell.getCellProps()}
                                                            isError={cellValidation(
                                                                cell,
                                                                index,
                                                            )}
                                                            key={`cell ${cellIndex}`}
                                                        >
                                                            {cell.render(
                                                                'Cell',
                                                            )}
                                                        </StyledTableCell>
                                                    );
                                                },
                                            )}
                                            <td
                                                className={'td_icons'}
                                                key={'td_icons'}
                                            >
                                                <TableIconButton
                                                    onClick={() =>
                                                        setEditedStudent(
                                                            data[index],
                                                        )
                                                    }
                                                >
                                                    <IconManager
                                                        cursor="pointer"
                                                        hoverFill={['primary']}
                                                        name={'IconEdit'}
                                                        size={24}
                                                    />
                                                </TableIconButton>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </Table>
            </TableWrapper>
        </UploadStudentsListWrapper>
    );
};

export default UploadStudentsList;
