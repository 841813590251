import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import {
    DefaultText,
    SubHeader,
    Title,
} from '@components/_universal/Typography.styled';

export const AccountParentPageWrapper = styled.div`
    height: 100%;
`;

export const PageTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 25px;
`;

export const SectionTitle = styled(SubHeader)`
    color: ${({ theme }) => theme.colors.text_hover};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin: 0;
    text-transform: uppercase;
`;

export const PageText = styled(DefaultText)`
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    max-width: 450px;
`;

export const PageHeader = styled.div`
    display: flex;
`;

export const Icon = styled(IconManager)`
    margin-left: 15px;
`;
