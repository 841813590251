import styled from 'styled-components';
import { breakpoints } from '@shared/constants';

export const SetPasswordFormWrapper = styled.div`
    width: 100%;

    @media (max-width: ${breakpoints.lg}px) {
        margin-top: 16px;
    }

    @media (max-width: ${breakpoints.md}px) {
        margin-top: 0;
    }
`;
