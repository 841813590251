import styled, { css } from 'styled-components';
import { breakpoints } from '@shared/constants';
import { IconManager } from '@components/_universal';
import Footer from '@components/_global/Footer/Footer';
import crossBg from '@assets/images/cross_bg.svg';
import crossBgInverted from '@assets/images/cross_bg_inverted.svg';
import crossBgMobile from '@assets/images/cross_bg_mobile.svg';
import crossBgMobileInverted from '@assets/images/cross_bg_mobile_inverted.svg';
import crossBgTablet from '@assets/images/cross_bg_tablet.svg';

const backgrounds: Record<
    'desktop' | 'tablet' | 'mobile',
    { right: string; left: string }
> = {
    desktop: {
        right: crossBgInverted,
        left: crossBg,
    },
    tablet: {
        right: crossBgInverted,
        left: crossBgTablet,
    },
    mobile: {
        right: crossBgMobileInverted,
        left: crossBgMobile,
    },
};

//don't sort
export const SignupLayoutWrapper = styled.div<{
    backgroundPosition: 'right' | 'left';
}>(
    ({ backgroundPosition, theme }) => css`
        background: ${theme.colors.secondary}
            url(${backgrounds.desktop[backgroundPosition]}) top
            ${backgroundPosition} repeat-y;
        display: grid;
        grid-template-areas:
            '. header .'
            '. content .'
            'footer footer footer';
        grid-template-columns: 1fr 1140px 1fr;
        grid-template-rows: 160px 1fr 90px;
        min-height: 100%;
        overflow: hidden;
        width: 100%;

        @media (min-width: ${breakpoints.lg}px) and (max-height: 768px) {
            grid-template-rows: 100px 1fr 60px;
        }

        @media (max-width: ${breakpoints.lg}px) {
            grid-template-columns: 1fr 960px 1fr;
            grid-template-rows: 100px 1fr 60px;

            background: ${theme.colors.secondary}
                url(${backgrounds.tablet[backgroundPosition]}) top
                ${backgroundPosition} repeat-y;
        }

        @media (max-width: ${breakpoints.md}px) {
            grid-template-columns: 1fr 720px 1fr;
        }

        @media (max-width: ${breakpoints.sm}px) {
            background: ${theme.colors.secondary}
                url(${backgrounds.mobile[backgroundPosition]}) top
                ${backgroundPosition} repeat-y;
            grid-template-columns: 1fr 540px 1fr;
            grid-template-rows: 60px 1fr 60px;
        }

        @media (max-width: ${breakpoints.xs}px) {
            grid-template-columns: 1fr calc(100% - 32px) 1fr;
        }
    `,
);

export const LogoSignupIcon = styled(IconManager)`
    @media (max-height: 768px) {
        font-size: 170px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        font-size: 130px;
    }
`;

export const SignUpLink = styled.a``;

export const Header = styled.div`
    align-items: flex-end;
    display: flex;
    grid-area: header;
    justify-content: center;
    padding: 56px 0;

    @media (min-width: ${breakpoints.lg}px) and (max-height: 768px) {
        padding: 32px 0;
    }

    @media (max-width: ${breakpoints.lg}px) {
        padding: 24px 0;
    }

    @media (max-width: ${breakpoints.sm}px) {
        padding: 0;
    }
`;

export const Content = styled.div`
    align-items: center;
    display: grid;
    grid-area: content;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 54px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        align-items: flex-start;
        margin-top: 42px;
    }
`;

export const FooterWrapper = styled.div`
    grid-area: footer;
    position: relative;
`;

export const FooterMobileOrnamentIcon = styled(IconManager)`
    bottom: 40px;
    display: none;
    position: absolute;
    right: 200px;

    @media (max-width: ${breakpoints.lg}px) {
        display: block;
    }
`;

export const FooterBar = styled(Footer)`
    padding-right: 100px;

    @media (max-width: ${breakpoints.lg}px) {
        padding-right: 50px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        padding-right: 16px;
    }
`;
