import styled from 'styled-components';
import Footer from '@components/_global/Footer/Footer';
import ParentHeader from '@components/_global/Header/ParentHeader/ParentHeader';
import Navigation from '@components/_global/Navigation/ParentNavigation/ParentNavigation';

export const ParentAccountLayoutWrapper = styled.div`
    display: grid;
    grid-template-areas:
        'navigation header header'
        'navigation content .'
        'navigation footer .';
    grid-template-columns: 120px 1fr 100px;
    grid-template-rows: 0 1fr 90px;
    min-height: 100%;
`;

export const HeaderBar = styled(ParentHeader)`
    grid-area: header;
`;

export const NavigationBar = styled(Navigation)`
    grid-area: navigation;
`;

export const Content = styled.div`
    grid-area: content;
`;

export const FooterBar = styled(Footer)`
    grid-area: footer;
`;
