import styled, { css } from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';

export const UploadStudentsPageWrapper = styled.div`
    padding: 120px 0 50px;
`;

export const PageHeader = styled.div`
    margin-bottom: 48px;
`;

export const HeaderInnerWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 200px;
    align-items: flex-start;
`;

export const PageTitle = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 32px;
    `,
);

export const HeaderDescription = styled(DefaultText)(
    ({ theme: { fontSizes } }) => css`
        font-size: ${fontSizes.s}px;
        line-height: 1.7;
        margin: 0;
        max-width: 720px;
        padding: 4px 32px 0 0;
    `,
);
