import React from 'react';
import Carousel from '@components/_universal/Carousel/Carousel';
import LiveExperienceCard from '@components/_universal/Cards/LiveExperienceCard/LiveExperienceCard';
import { SavedForLaterContentQuery_getSavedForLaterContent_LiveExperience } from '@@types/graphql/SavedForLaterContentQuery';
import { SwiperSlide } from 'swiper/react';
import { SwiperWrapper } from './ExperiencesSaveForLaterCarousel.styled';
import { useSaveForLaterContentQuery } from '@state/lists/queries';
import paths from '@shared/paths';

interface IProps {}

const ExperiencesSaveForLaterCarousel: React.FC<IProps> = () => {
    const { data } = useSaveForLaterContentQuery({ page: 1, limit: 100 });
    const filteredData = data?.getSavedForLaterContent?.filter(
        (element) => element.__typename === 'LiveExperience',
    ) as SavedForLaterContentQuery_getSavedForLaterContent_LiveExperience[];

    const dataLength = filteredData.length ?? 0;

    return (
        <SwiperWrapper>
            <Carousel
                disabled={dataLength <= 2}
                nextButtonClass={'next--experiences-save-for-later'}
                prevButtonClass={'prev--experiences-save-for-later'}
                style={{ padding: '0 200px 20px 0' }}
            >
                {filteredData?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <LiveExperienceCard
                            allSlots={item.maxNoParticipants}
                            availableSlots={
                                item.maxNoParticipants - item.noOfSignups
                            }
                            image={item.thumbnail}
                            link={paths.live_experience(item._id)}
                            startDate={item.startDate}
                            text={item.name}
                        />
                    </SwiperSlide>
                ))}
            </Carousel>
        </SwiperWrapper>
    );
};

export default ExperiencesSaveForLaterCarousel;
