import React from 'react';
import { BadgeBorderText, BadgeBorderWrapper } from './BadgeBorder.styled';

export interface BadgeBorderProps {
    className?: string;
    variant?: 'default' | 'light' | 'gray';
}

const BadgeBorder: React.FC<BadgeBorderProps> = ({
    children,
    className,
    variant,
}) => {
    return (
        <BadgeBorderWrapper variant={variant} className={className}>
            <BadgeBorderText variant={variant}>{children}</BadgeBorderText>
        </BadgeBorderWrapper>
    );
};

export default BadgeBorder;
