import styled, { css } from 'styled-components';
import { SubTitle } from '@components/_universal/Typography.styled';

export const DashboardTeacherPageWrapper = styled.div<{
    removePadding?: boolean;
}>(
    ({ removePadding }) => css`
        display: flex;
        flex-direction: column;
        padding-top: ${removePadding ? '0' : '120px'};
    `,
);

export const PageTitle = styled(SubTitle)(
    ({ theme: { fontWeights } }) => css`
        align-items: center;
        display: flex;
        font-weight: ${fontWeights.semiBold};
        padding-top: 34px;
    `,
);
