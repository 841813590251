import { SubTitle } from '@components/_universal/Typography.styled';
import styled from 'styled-components';

export const LibraryPageWrapper = styled.div`
    padding: 34px 100px;
`;

export const PageTitle = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 76px;
`;
