import React from 'react';
import { Input } from '@components/_form-elements';
import { useForm } from 'react-hook-form';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import {
    ChangeEmailFormWrapper,
    ActionButton,
    Actions,
} from './ChangeEmailForm.styled';
import { useChangeEmailMutation } from '@state/settings/mutations';

interface IProps {
    closeEdit: () => void;
}

interface IForm {
    email: string;
}

const ChangeEmailForm: React.FC<IProps> = ({ closeEdit }) => {
    const { register, handleSubmit } = useForm<IForm>();
    const [changeEmail] = useChangeEmailMutation(closeEdit);

    const onSubmit = (formValues: IForm) => {
        changeEmail({
            variables: {
                input: {
                    newEmail: formValues.email,
                },
            },
        });
    };

    return (
        <ChangeEmailFormWrapper>
            <InputForm
                name={'email'}
                placeholder={'Enter your email'}
                register={register}
                additionalText={
                    'Email address is necessary to login to the 8Billionideas platform.'
                }
            />
            <Actions>
                <ActionButton
                    buttonType={'secondary'}
                    onPress={() => closeEdit()}
                >
                    Cancel
                </ActionButton>
                <ActionButton
                    buttonType={'primary'}
                    onPress={handleSubmit(onSubmit)}
                >
                    Save changes
                </ActionButton>
            </Actions>
        </ChangeEmailFormWrapper>
    );
};

const InputForm = FieldMessage(Input);

export default ChangeEmailForm;
