import styled, { css } from 'styled-components';
import { IconManager } from '@components/_universal';
import { breakpoints } from '@shared/constants';
import { Title } from '../Typography.styled';
import Button from '@components/_universal/Button/Button';

export const TableWrapper = styled.div(
    ({ theme: { colors, fontSizes, fontWeights } }) => css`
        table {
            border-spacing: 0;
            font-size: ${fontSizes.s}px;
            width: 100%;

            th {
                background: ${colors.text};
                color: ${colors.text_white};
                font-weight: ${fontWeights.semiBold};
                text-align: left;
            }

            tr {
                transition: all ${({ theme }) => theme.transitions.medium}s;

                &:nth-child(even) {
                    background: ${colors.even_table};
                }

                &:hover {
                    background: ${colors.icons_hover};
                }
            }

            th,
            td {
                margin: 0;
                padding: 17px 20px;
            }
        }

        .banned {
            color: ${colors.notifications};
        }

        .pending {
            color: ${colors.secondary};
        }

        .td_icons {
            padding: 11px 20px;
            text-align: right;
        }
    `,
);

export const TableIconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 4px;
    outline: none;
    padding: 0;
`;

export const IconResend = styled(IconManager)(
    ({ theme: { fontWeights } }) => css`
        align-items: center;
        display: flex;
        font-weight: ${fontWeights.semiBold};
    `,
);

export const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 300px;
    flex: 1;
    justify-content: center;
    width: 100%;
`;

export const TableHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TableHeaderInner = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    @media (max-width: ${breakpoints.lg}px) {
        align-items: flex-end;
        flex-direction: column;
    }
`;

export const TableInfoWrapper = styled.div`
    align-items: center;
    display: flex;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 16px;
        width: 100%;
    }
`;

export const HeaderText = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 0;
    `,
);

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-bottom: 8px;
    position: relative;
`;

export const GroupButton = styled(Button)`
    width: 175px;
`;
