import styled, { css } from 'styled-components';
import { DefaultText, Title } from '@components/_universal/Typography.styled';

export const UploadStudentsSummaryPageWrapper = styled.div`
    padding: 120px 0 50px;
`;

export const PageHeader = styled.div`
    margin-bottom: 48px;
`;

export const PageTitle = styled(Title)(
    ({ theme: { fontWeights } }) => css`
        font-weight: ${fontWeights.semiBold};
        margin-bottom: 32px;
    `,
);

export const UploadSummary = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        align-items: center;
        background-color: ${colors.background_quaternary};
        border-radius: ${borderRadius.button}px;
        display: flex;
        justify-content: center;
        padding: 25px 0;
    `,
);

export const UploadText = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.xl}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.6;
        margin: 0 0 0 24px;
    `,
);

export const CheckInfo = styled.div`
    margin-bottom: 32px;
`;

export const InfoTitle = styled(Title)(
    ({ theme: { fontWeights, fontSizes } }) => css`
        font-size: ${fontSizes.xl}px;
        font-weight: ${fontWeights.semiBold};
        line-height: 1.6;
        margin-bottom: 16px;
    `,
);

export const InfoText = styled(DefaultText)(
    ({ theme: { fontSizes } }) => css`
        font-size: ${fontSizes.s}px;
        line-height: 1.7;
        margin: 0;
        max-width: 720px;
    `,
);

export const ResendButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
`;
