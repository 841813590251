import React from 'react';
import { useHistory } from 'react-router-dom';
import { useUpcomingLiveExperiencesContentQuery } from '@state/lists/queries';
import ExperiencesCarousel from '@domains/Child/Courses/_components/Experiences/ExperiencesCarousel/ExperiencesCarousel';
import Loader from '@components/_universal/Loader/Loader';
import paths from '@shared/paths';
import {
    ButtonWrapper,
    CoursesOrnamentIcon,
    Description,
    ExperiencesWrapper,
    LoaderWrapper,
    StyledButton,
    StyledTitle,
} from './Experiences.styled';

interface IProps {}

const Experiences: React.FC<IProps> = () => {
    const { data, loading } = useUpcomingLiveExperiencesContentQuery();
    const isEmptyState = !data?.getYourUpcomingLiveExperiences?.length;
    const history = useHistory();

    return (
        <ExperiencesWrapper>
            <CoursesOrnamentIcon
                name={'CoursesOrnament'}
                size={453}
                sizeY={206}
            />
            <StyledTitle>Your upcoming experiences</StyledTitle>
            {loading ? (
                <LoaderWrapper>
                    <Loader size={'medium'} center />
                </LoaderWrapper>
            ) : (
                <>
                    {isEmptyState ? (
                        <>
                            <Description>
                                It looks like you have not signed up for any
                                experience. Do not hesitate, the number of
                                places in each of them is limited.
                            </Description>
                            <ButtonWrapper>
                                <StyledButton
                                    onPress={() =>
                                        history.push(paths.dashboard)
                                    }
                                >
                                    Check upcoming experiences
                                </StyledButton>
                            </ButtonWrapper>
                        </>
                    ) : (
                        <ExperiencesCarousel />
                    )}
                </>
            )}
        </ExperiencesWrapper>
    );
};

export default Experiences;
