import { LinkText } from '@components/_universal/Typography.styled';
import styled, { css } from 'styled-components';
import IconManager from '@components/_universal/IconManager/IconManager';

export const BoxWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const UploadBox = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background: ${colors.background_quaternary};
        border-radius: ${borderRadius.card}px;
        display: flex;
        justify-content: center;
        padding: 20px 24px 16px;
        position: relative;
    `,
);

export const UploadOptionWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const UploadIcon = styled(IconManager)`
    margin-right: 16px;
`;

export const UploadOption = styled.div(
    ({ theme: { borderRadius, colors, cursors, fontWeights } }) => css`
        align-items: center;
        background: ${colors.background_secondary};
        border-radius: ${borderRadius.card}px;
        border: 1px solid ${colors.stroke};
        color: ${colors.text};
        cursor: ${cursors.pointer};
        display: flex;
        font-weight: ${fontWeights.semiBold};
        margin: 0 12px 8px;
        min-width: 428px;
        padding: 20px 32px;
    `,
);

export const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
`;
const uploadTextStyle = css`
    color: ${({ theme }) => theme.colors.primary};
    cursor: ${({ theme }) => theme.cursors.pointer};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.65;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        text-underline-position: under;
    }
`;
export const StyledLinkText = styled(LinkText)`
    ${uploadTextStyle}
`;

export const StyledUploadText = styled.p`
    ${uploadTextStyle}
`;

export const Info = styled.span(
    ({ theme: { colors } }) => css`
        color: ${colors.text_assistive};
    `,
);

export const GroupName = styled.span(
    ({ theme: { colors } }) => css`
        color: ${colors.secondary};
        display: inline-block;
        margin-right: 8px;

        &:not(:last-child) {
            &:after {
                content: ',';
            }
        }
    `,
);
