import React from 'react';
import { useForm } from 'react-hook-form';
import {
    SearchFormClearButton,
    SearchFormInput,
    SearchFormWrapper,
} from './SearchForm.styled';

interface IForm {
    search: string;
}

export interface IProps {
    onSearch: (value: string) => void;
}

const SearchForm: React.FC<IProps> = ({ onSearch }) => {
    const { handleSubmit, register, reset, watch } = useForm<IForm>();
    const search = watch('search');

    const onSubmit = async () => {
        onSearch(search);
    };

    return (
        <SearchFormWrapper onSubmit={handleSubmit(onSubmit)}>
            <SearchFormInput
                autoComplete="off"
                name="search"
                placeholder={'Search'}
                ref={register}
                spellCheck={false}
            />
            {search && (
                <SearchFormClearButton
                    htmlType="button"
                    onPress={() => {
                        reset();
                    }}
                >
                    Clear
                </SearchFormClearButton>
            )}
        </SearchFormWrapper>
    );
};

export default SearchForm;
