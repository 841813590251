import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import {
    BigTitle,
    DefaultText,
} from '@components/_universal/Typography.styled';

export const CreateAccountWrapper = styled.div`
    background: ${({ theme }) => theme.colors.background_secondary};
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    max-width: 750px;
    padding: 54px 54px 56px;
    position: relative;

    @media (max-width: ${breakpoints.lg}px) {
        padding: 40px 40px 48px;
    }

    @media (max-width: ${breakpoints.md}px) {
        margin-bottom: 220px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        border-radius: ${({ theme }) => theme.borderRadius.button}px;
        margin-bottom: 180px;
        padding: 40px 28px 48px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        padding: 40px 16px 48px;
    }
`;

export const InformationText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    margin-bottom: 8px;
    text-transform: uppercase;

    @media (max-width: ${breakpoints.lg}px) {
        margin-bottom: 4px;
        text-align: center;
    }
`;

export const StyledBigTitle = styled(BigTitle)`
    font-size: ${({ theme }) => theme.fontSizes.xxl}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 32px;

    @media (max-width: ${breakpoints.lg}px) {
        font-size: ${({ theme }) => theme.fontSizes.mobile_title}px;
        line-height: 1.45;
        margin-bottom: 24px;
        text-align: center;
    }
`;
