import React from 'react';
import { AccountTeacherPageWrapper } from './AccountTeacherPage.styled';
import { IAppSubRoutes } from '@routes/index';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import SubRoutes from '@routes/SubRoutes';

interface IProps extends IAppSubRoutes {}

const AccountTeacherPage: React.FC<IProps> = ({ layoutComponent, routes }) => {
    return (
        <AccountTeacherPageWrapper>
            <SubRoutes {...{ layoutComponent, routes }} />
            <ChatWithMentor />
        </AccountTeacherPageWrapper>
    );
};

export default AccountTeacherPage;
