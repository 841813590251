import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import { Title } from '@components/_universal/Typography.styled';

export const MoreElementsWrapper = styled.div`
    margin-top: 96px;
    padding-right: 85px;
    position: relative;
    width: 100%;
`;

export const StyledTitle = styled(Title)`
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 40px;
    position: relative;
    z-index: 9;
`;

export const IconWrapper = styled(IconManager)`
    margin-left: 16px;
`;

export const ElementsRow = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
`;

export const BackgroundDecorator = styled(IconManager)`
    position: absolute;
    right: 40px;
    top: 10px;
`;
