import React, { InputHTMLAttributes } from 'react';
import { FieldTypes } from '@@types/CommonTypes';
import IconManager from '@components/_universal/IconManager/IconManager';
import {
    InputSearchInnerWrapper,
    InputSearchWrapper,
    StyledIconClearButton,
    StyledIconSearch,
    StyledInputSearch,
} from './InputSearch.styled';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    inputRef?: any;
    isFillInput: boolean;
    marginBottom?: number;
    maxLength?: number;
    name?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
    placeholder?: string;
}

const InputSearch: React.FC<IProps> = ({
    className,
    inputRef,
    isFillInput,
    marginBottom,
    maxLength,
    name,
    onChange,
    onClear,
    placeholder,
    value,
}) => {
    return (
        <InputSearchWrapper {...{ className, marginBottom }}>
            <InputSearchInnerWrapper>
                <StyledIconSearch name={'IconSearch'} size={24} />
                <StyledInputSearch
                    maxLength={maxLength}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    ref={inputRef}
                    value={value}
                />
                {isFillInput && (
                    <StyledIconClearButton onClick={onClear}>
                        <IconManager name={'Close'} size={24} />
                    </StyledIconClearButton>
                )}
            </InputSearchInnerWrapper>
        </InputSearchWrapper>
    );
};

export default InputSearch;
