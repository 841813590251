import { gql, useQuery } from '@apollo/client';
import { SavedForLaterContentPagination } from '@@types/graphql/GraphqlGlobalTypes';
import {
    SavedForLaterContentQuery,
    SavedForLaterContentQueryVariables,
} from '@@types/graphql/SavedForLaterContentQuery';

const SAVED_FOR_LATER_CONTENT_QUERY = gql`
    query SavedForLaterContentQuery(
        $pagination: SavedForLaterContentPagination
    ) {
        getSavedForLaterContent(pagination: $pagination) {
            __typename
            ... on Course {
                _id
                type
                name
                featured
                thumbnail
            }
            ... on StandaloneVideo {
                _id
                type
                name
                featured
                thumbnail
            }
            ... on LiveExperience {
                _id
                endDate
                featured
                maxNoParticipants
                name
                noOfSignups
                schoolsWithAccess {
                    id
                }
                showToChildren
                showToStudents
                startDate
                thumbnail
                type
            }
        }
    }
`;

export default (pagination?: SavedForLaterContentPagination) =>
    useQuery<SavedForLaterContentQuery, SavedForLaterContentQueryVariables>(
        SAVED_FOR_LATER_CONTENT_QUERY,
        {
            variables: {
                pagination: pagination,
            },
            onCompleted: () => {},
            onError: () => {},
        },
    );
