import React from 'react';
import {
    Icon,
    PageHeader,
    PageText,
    PageTitle,
    SectionTitle,
} from '@domains/Child/Account/AccountPage/AccountPage.styled';
import {
    ActionButton,
    ContentWrapper,
    HelpFaqPageWrapper,
    SectionText,
    SectionWrapper,
} from './HelpFaqPage.styled';
import { externalLinks } from '@shared/paths';

interface IProps {}

const HelpFaqPage: React.FC<IProps> = () => {
    return (
        <HelpFaqPageWrapper>
            <PageHeader>
                <PageTitle>Help {'&'} FAQ</PageTitle>{' '}
                <Icon name={'HeartMessage'} size={70} sizeY={40} />
            </PageHeader>

            <PageText>
                Feel free to talk with our support. To do it click on the chat
                widget in the bottom right corner of the screen.
            </PageText>
            <PageText>
                If you are concerned about someone else's behavior, inform the
                8Billionideas team.
            </PageText>
            <SectionWrapper>
                <ContentWrapper>
                    <SectionTitle>FAQ</SectionTitle>
                    <SectionText>
                        Explore our Frequently Asked Questions and find answers
                        to them!
                    </SectionText>
                </ContentWrapper>
                <ActionButton
                    buttonType={'secondary'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        isLeft: true,
                        type: 'RedirectIcon',
                    }}
                    onPress={() =>
                        window.open(externalLinks.help_faq_child, '_blank')
                    }
                    size={'small'}
                >
                    Read More
                </ActionButton>
            </SectionWrapper>
        </HelpFaqPageWrapper>
    );
};

export default HelpFaqPage;
