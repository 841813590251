import { useEffect } from 'react';
import { contentTypes } from '@shared/keyToCopy';
import { IChip, SelectType } from '@@types/CommonTypes';
import { setLibraryTabs } from '@state/_redux/tabs/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useMostPopularTypesTags } from '@state/lists/queries/useMostPopularTypesTags';
import { usePrevious } from '@utils/hooks';
import {
    CONTENT_TYPE,
    LIBRARY_VIEW_CONTENT_SORTING,
} from '@@types/graphql/GraphqlGlobalTypes';

export interface TabsProps {
    sortBy: SelectType<LIBRARY_VIEW_CONTENT_SORTING>;
    topic: string[];
    type: CONTENT_TYPE;
}

export const useFilterContent = () => {
    const dispatch = useDispatch();
    const tabs = useSelector(({ tabs }) => tabs.library);

    const { liveExperiencesVisibility } = useSelector(
        (state) => state.liveExperiencesVisibility,
    );

    const { control, reset, watch } = useForm<TabsProps>({
        defaultValues: {
            topic: [],
            type: liveExperiencesVisibility ? tabs.type : CONTENT_TYPE.COURSE,
        },
    });

    const { data } = useMostPopularTypesTags({
        typeFilter: { [tabs.type]: true },
    });

    const values = watch();
    const prevValues = usePrevious(values);

    const topicCount = data?.typesAndTags.contentTypes.data?.find(
        (count) => count.name === tabs.type,
    );

    const topicData = data?.typesAndTags.topicTags.data;

    const typeFilter: IChip[] = liveExperiencesVisibility
        ? [
              {
                  text: contentTypes[CONTENT_TYPE.LIVE_EXPERIENCE],
                  value: CONTENT_TYPE.LIVE_EXPERIENCE,
              },
              {
                  text: contentTypes[CONTENT_TYPE.COURSE],
                  value: CONTENT_TYPE.COURSE,
              },
              {
                  text: contentTypes[CONTENT_TYPE.STANDALONE_VIDEO],
                  value: CONTENT_TYPE.STANDALONE_VIDEO,
              },
          ]
        : [
              {
                  text: contentTypes[CONTENT_TYPE.COURSE],
                  value: CONTENT_TYPE.COURSE,
              },
              {
                  text: contentTypes[CONTENT_TYPE.STANDALONE_VIDEO],
                  value: CONTENT_TYPE.STANDALONE_VIDEO,
              },
          ];

    const topicFilter: IChip[] = [
        {
            count: topicCount?.count,
            text: 'All',
            value: 'ALL',
            all: true,
        },
        ...(topicData?.map((tag) => {
            return {
                count: tag.count,
                text: tag.name,
                value: tag.name,
            };
        }) ?? []),
    ];

    useEffect(() => {
        !liveExperiencesVisibility &&
            dispatch(setLibraryTabs({ type: CONTENT_TYPE.COURSE }));
        !liveExperiencesVisibility && reset({ type: CONTENT_TYPE.COURSE });
    }, [liveExperiencesVisibility]);

    useEffect(() => {
        reset({
            topic: tabs.topic,
        });
    }, [tabs.type]);

    useEffect(() => {
        JSON.stringify(values) !== JSON.stringify(prevValues) &&
            dispatch(setLibraryTabs(values));
    }, [values]);

    return {
        control,
        tabs,
        topicFilter,
        typeFilter,
    };
};
