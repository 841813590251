import { gql, useQuery } from '@apollo/client';
import { AuthMeQuery } from '@@types/graphql/AuthMeQuery';

export const AUTH_ME_QUERY = gql`
    query AuthMeQuery {
        me {
            type
            status
            onboardingCompletedAt
            hasPassword
        }
    }
`;

export default (isNetwork?: boolean) =>
    useQuery<AuthMeQuery>(AUTH_ME_QUERY, {
        fetchPolicy: isNetwork ? 'network-only' : 'cache-first',
    });
