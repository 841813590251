import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import {
    setCorrectAccountData,
    setIsAdminAsTeacher,
    setIsFirstLogin,
    setSkipSetPassword,
    setSuccessPayment,
} from './actions';

export default createReducer(initialState)
    .handleAction(setSuccessPayment, (state, { payload }) => {
        return {
            ...state,
            successPayment: payload,
        };
    })
    .handleAction(setCorrectAccountData, (state, { payload }) => {
        return {
            ...state,
            correctAccountData: payload,
        };
    })
    .handleAction(setIsAdminAsTeacher, (state, { payload }) => {
        return {
            ...state,
            isAdminAsTeacher: payload,
        };
    })
    .handleAction(setIsFirstLogin, (state, { payload }) => {
        return {
            ...state,
            isFirstLogin: payload,
        };
    })
    .handleAction(setSkipSetPassword, (state, { payload }) => {
        return {
            ...state,
            skipSetPassword: payload,
        };
    });
