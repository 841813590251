import React from 'react';
import CreateAccountForm from '@domains/Auth/_forms/CreateAccountForm/CreateAccountForm';
import {
    CreateAccountWrapper,
    InformationText,
    StyledBigTitle,
} from './CreateAccount.styled';

interface IProps {}

const CreateAccount: React.FC<IProps> = () => {
    return (
        <CreateAccountWrapper>
            <InformationText>PARENT SIGNUP - step 1</InformationText>
            <StyledBigTitle>Create your Parent Account</StyledBigTitle>
            <CreateAccountForm />
        </CreateAccountWrapper>
    );
};

export default CreateAccount;
