import React from 'react';
import { setLeaveUploadStudentsModal } from '@state/_redux/layout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ModalConfirmation from '@components/_modals/ModalConfirmation/ModalConfirmation';

export interface IProps {}

const LeaveStudentsPageModal: React.FC<IProps> = () => {
    const { leaveUploadStudentsModal } = useSelector((state) => state.layout);

    const history = useHistory();
    const dispatch = useDispatch();

    const onClose = () =>
        dispatch(
            setLeaveUploadStudentsModal({
                isOpen: false,
                redirectTo: '',
            }),
        );

    return (
        <ModalConfirmation
            closeOutside
            firstButton={{
                onClick: onClose,
                text: 'stay on this page',
                type: 'secondary',
            }}
            onClose={onClose}
            secondButton={{
                onClick: () => {
                    history.push(leaveUploadStudentsModal.redirectTo);
                    onClose();
                },
                text: 'Yes, leave page',
                type: 'primary',
            }}
            title={'Are you sure?'}
        >
            You are in the process of adding students, if you leave this page
            now you will not add students to the platform. Are you sure you want
            to do this?
        </ModalConfirmation>
    );
};

export default LeaveStudentsPageModal;
