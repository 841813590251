import { GENDER } from '@@types/graphql/GraphqlGlobalTypes';

export const GENDERS = [
    {
        label: 'Prefer not to say',
        value: undefined,
    },
    {
        label: 'Male',
        value: GENDER.MALE,
    },
    {
        label: 'Female',
        value: GENDER.FEMALE,
    },
];
