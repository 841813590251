import React from 'react';
import { IconManager } from '../index';
import { IconName } from '@@types/CommonTypes';
import {
    RecomendedButton,
    SectionHeader,
    SectionTitle,
    TitleWrapper,
} from './DetailsPageHeader.styled';

export interface DetailPageHeaderProps {
    iconName: IconName;
    onPress: () => void;
    recommendButton: boolean;
    title: string;
}

export const DetailsPageHeader = ({
    iconName,
    onPress,
    recommendButton,
    title,
}: DetailPageHeaderProps) => {
    return (
        <SectionHeader>
            <TitleWrapper>
                <IconManager name={iconName} size={50} />
                <SectionTitle>{title}</SectionTitle>
            </TitleWrapper>
            {recommendButton && (
                <RecomendedButton
                    buttonType="secondary"
                    icon={{
                        isLeft: true,
                        type: 'LikeIcon',
                        size: 24,
                    }}
                    onPress={onPress}
                    size="small"
                >
                    Recommend
                </RecomendedButton>
            )}
        </SectionHeader>
    );
};
