import React from 'react';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/_universal/Carousel/Carousel';
import useOngoingContentQuery from '@state/lists/queries/useOngoingContentQuery';
import getContentProgress from '@utils/getContentProgress';
import {
    StyledProgressElement,
    SwiperWrapper,
} from './ProgressCarousel.styled';
import paths from '@shared/paths';

interface IProps {}

const returnContentType = (type: string) =>
    type === 'COURSE' ? 'Course' : 'Video';

const getUrl = (type: string, id: string) =>
    type === 'COURSE'
        ? paths.course(id)
        : type === 'STANDALONE_VIDEO'
        ? paths.video_view(id)
        : '';
const ProgressCarousel: React.FC<IProps> = () => {
    const { data } = useOngoingContentQuery();
    const ongoingContent = data?.getOngoingContent;

    return (
        <SwiperWrapper>
            <Carousel
                nextButtonClass={'next--progress'}
                prevButtonClass={'prev--progress'}
                sliderRows={2}
                style={{ padding: '0 200px 20px 0' }}
            >
                {ongoingContent?.map(
                    ({ content, id, name, progress, thumbnail, type }) => (
                        <SwiperSlide key={id}>
                            <StyledProgressElement
                                image={thumbnail ?? ''}
                                isVideo={type === 'STANDALONE_VIDEO'}
                                url={getUrl(type, content._id)}
                                progress={getContentProgress(
                                    content,
                                    progress,
                                    type,
                                )}
                                progressType={returnContentType(type)}
                                progressVariant={'big'}
                            >
                                {name}
                            </StyledProgressElement>
                        </SwiperSlide>
                    ),
                )}
            </Carousel>
        </SwiperWrapper>
    );
};

export default ProgressCarousel;
