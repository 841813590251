import styled from 'styled-components';
import { breakpoints } from '@shared/constants';
import { IconManager } from '@components/_universal';
import AnimatedDots from '@components/_universal/AnimatedDots/AnimatedDots';

export const MobileSignupPaymentProcessingPageWrapper = styled.div`
    align-items: center;
    align-self: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 36px;
    max-height: 620px;
    padding: 80px 54px;
    width: 100%;

    @media (max-width: ${breakpoints.md}px) {
        margin-top: 24px;
        padding: 54px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        padding: 32px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        margin-top: 0px;
        padding: 24px;
    }
`;

export const Content = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
`;

export const LoadingText = styled.div`
    color: ${({ theme }) => theme.colors.text_white};
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.75;
    max-width: 564px;
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.s}px;
    }
`;

export const LoadingTitle = styled.div`
    color: ${({ theme }) => theme.colors.text_white};
    display: flex;
    font-family: ${({ theme }) => theme.fontFamily.sketched};
    font-size: ${({ theme }) => theme.fontSizes.max}px;

    @media (max-width: ${breakpoints.sm}px) {
        font-size: 50px;
        padding-right: 16px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        font-size: 40px;
        padding-right: 30px;
    }
`;

export const LoadingScooterIcon = styled(IconManager)`
    margin: 72px 0;

    @media (max-width: ${breakpoints.md}px) {
        font-size: 300px;
        margin: 54px 0 32px;
    }

    @media (max-width: ${breakpoints.sm}px) {
        margin: 48px 0 24px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        font-size: 220px;
        margin: 30px 0 16px;
    }
`;

export const StyledAnimatedDots = styled(AnimatedDots)`
    @media (max-width: ${breakpoints.sm}px) {
        top: 42px;
        left: 30px;
    }

    @media (max-width: ${breakpoints.xs}px) {
        left: 26px;
        top: 30px;
        transform: scale(0.8);
    }
`;
