import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';

export const PaginationWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 50%;
    margin-top: 30px;
    position: absolute;
    transform: translateX(-50%);
`;

export const PaginationItemWrapper = styled.div`
    display: flex;
`;

export const fillCss = css`
    background: ${({ theme }) => theme.colors.icons};
`;

export const activeCss = css`
    background: ${({ theme }) => theme.colors.secondary};
`;

export const PaginationItem = styled.div<{ active?: boolean; $fill: boolean }>`
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: ${({ theme }) => theme.borderRadius.circle}px;
    margin: 0 6px;
    height: 8px;
    width: 8px;
    ${({ $fill }) => $fill && fillCss};
    ${({ active }) => active && activeCss};
`;

export const PaginationText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSizes.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-top: 8px;
    text-transform: uppercase;
`;
