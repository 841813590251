import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { SignOutFromLiveExperienceFragment } from '@@types/graphql/SignOutFromLiveExperienceFragment';
import { ToastTypes } from '@@types/CommonTypes';
import { UPCOMING_LIVE_EXPERIENCES_CONTENT_QUERY } from '@state/lists/queries/useUpcomingLiveExperiencesContentQuery';
import {
    SignOutFromLiveExperienceMutation,
    SignOutFromLiveExperienceMutationVariables,
} from '@@types/graphql/SignOutFromLiveExperienceMutation';

const SIGN_OUT_FROM_LIVE_EXPERIENCE_MUTATION = gql`
    mutation SignOutFromLiveExperienceMutation($liveExperienceId: String!) {
        signOutFromLiveExperience(liveExperienceId: $liveExperienceId) {
            _id
            noOfSignups
        }
    }
`;

const fragment = gql`
    fragment SignOutFromLiveExperienceFragment on LiveExperience {
        isUserSignuped
        noOfSignups
    }
`;

export default (onCompleted?: () => void) => {
    return useMutation<
        SignOutFromLiveExperienceMutation,
        SignOutFromLiveExperienceMutationVariables
    >(SIGN_OUT_FROM_LIVE_EXPERIENCE_MUTATION, {
        onCompleted: () => {
            onCompleted?.();
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
        update: (cache, { data }) => {
            if (!data) return;
            cache.writeFragment<SignOutFromLiveExperienceFragment>({
                id: `LiveExperience:${data.signOutFromLiveExperience._id}`,
                fragment,
                data: {
                    isUserSignuped: false,
                    noOfSignups: data.signOutFromLiveExperience.noOfSignups,
                },
            });
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: UPCOMING_LIVE_EXPERIENCES_CONTENT_QUERY,
            },
        ],
    });
};
