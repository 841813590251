import client from '@boot/apolloClient';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import paths from '@shared/paths';

export default (afterLogout?: () => void) => {
    LocalStorageManager.removeValue('sessionToken');
    LocalStorageManager.removeValue('refreshToken');
    LocalStorageManager.removeValue('adminSessionToken');
    LocalStorageManager.removeValue('adminRefreshToken');
    client.clearStore();
    afterLogout?.();
    location.href = location.origin + paths.login;
};
