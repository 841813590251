import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import {
    NotifyAboutLE,
    NotifyAboutLEVariables,
} from '@@types/graphql/NotifyAboutLE';

export const NOTIFY_ABOUT_LE = gql`
    mutation NotifyAboutLE($input: NotifyAboutLEInput!) {
        notifyAboutLE(input: $input) {
            status
        }
    }
`;

export const useNotifyAboutLE = () => {
    return useMutation<NotifyAboutLE, NotifyAboutLEVariables>(NOTIFY_ABOUT_LE, {
        onCompleted: () => {
            notify('You have successfully notified students.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
