import { gql, useMutation } from '@apollo/client';
import { DeleteAccountMutation } from '@@types/graphql/DeleteAccountMutation';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import logout from '@utils/logout';

const DELETE_ACCOUNT = gql`
    mutation DeleteAccountMutation {
        deleteParent
    }
`;

export default () => {
    return useMutation<DeleteAccountMutation>(DELETE_ACCOUNT, {
        onCompleted: () => {
            logout();
        },
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
    });
};
