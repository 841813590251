import styled from 'styled-components';
import { SubTitle } from '@components/_universal/Typography.styled';
import AccountNavigation from '@domains/Parent/Account/_components/AccountNavigation/AccountNavigation';

export const ParentAccountSubLayoutWrapper = styled.div`
    display: grid;
    gap: 0 4px;
    grid-template-areas: 'title title' 'navigation content';
    grid-template-columns: 340px 1fr;
    grid-template-rows: 110px 1fr;
    height: 100%;
`;

export const PageTitle = styled(SubTitle)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    grid-area: title;
    padding-left: 100px;
    padding-top: 34px;
`;

export const SubNavigationBar = styled(AccountNavigation)`
    grid-area: navigation;
`;

export const Content = styled.div`
    background: ${({ theme }) => theme.colors.background_secondary};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.circle}px;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.circle}px;
    border: 1px solid ${({ theme }) => theme.colors.background_tertiary};
    box-shadow: ${({ theme }) => theme.silver_shadow};
    grid-area: content;
    padding: 80px 95px 0;
`;
