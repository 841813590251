import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GENDER } from '@@types/graphql/GraphqlGlobalTypes';
import { SelectType } from '@@types/CommonTypes';
import { useCreateMultipleAccountsMutation } from '@state/childManagement/mutations';
import { useMobile, useQuery } from '@utils/hooks';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import RegisterChild from '@domains/Auth/_components/RegisterChild/RegisterChild';
import {
    DeepMap,
    FieldError,
    FormProvider,
    useFieldArray,
    useForm,
} from 'react-hook-form';
import {
    AddChild,
    AddChildButton,
    CircleIcon,
    ControlsWrapper,
    GetStartedButton,
    InnerWrapper,
    NextPageOrnament,
    PreviousPageOrnament,
    SignupRegisterWrapper,
    MobileStyledTip,
} from './SignupRegisterPage.styled';

dayjs.extend(customParseFormat);

interface IProps {}

export interface IChild {
    birthday: string;
    email: string;
    gender: SelectType;
    name: string;
    surname: string;
}

export interface ICreateChildForm {
    childs: [IChild];
}

const MAX_CHILDREN = 10;
const EMAIL_TAKEN_MESSAGE = 'The following childs emails are invalid: ';

const SignupRegisterPage: React.FC<IProps> = () => {
    const cardElement = useRef<HTMLDivElement>(null);
    const [removingProcess, setRemovingProcess] = useState(false);
    const [currentChild, setCurrentChild] = useState(0);
    const [isFormChecked, setIsFormChecked] = useState(false);

    const { isMobile } = useMobile();
    const { addQuery, removeQuery, searchParams } = useQuery();
    const currentPage = searchParams.get('page');

    const formMethods = useForm<ICreateChildForm>({
        defaultValues: {
            childs: [
                {
                    birthday: '',
                    email: '',
                    gender: null,
                    name: '',
                    surname: '',
                },
            ],
        },
        resolver: yupResolver(validation),
    });
    const { append, fields, remove } = useFieldArray<IChild>({
        control: formMethods.control,
        name: 'childs',
    });
    const total = fields.length;

    const [
        createMultipleAccounts,
        { loading, error },
    ] = useCreateMultipleAccountsMutation();
    const emailsAlreadyTaken: string[] = useMemo(
        () =>
            (error &&
                error.message.search(EMAIL_TAKEN_MESSAGE) > -1 &&
                error.message.slice(EMAIL_TAKEN_MESSAGE.length).split(',')) ||
            [],
        [error],
    );

    const handleChangePage = (page: number) => {
        addQuery('page', (page + 1).toString());

        if (isMobile) {
            const offset = cardElement.current?.offsetTop;
            offset && window.scrollTo(0, offset - 50);
        }
    };

    const handleAddAnotherChild = () => {
        append({});
        handleChangePage(total);
    };

    const handleRomoveChild = (index: number) => {
        index === total - 1 && setRemovingProcess(true);
        handleChangePage(index - 1);
        setCurrentChild(index);
        setTimeout(() => {
            remove(index);
            isFormChecked && formMethods.trigger('childs');
            setRemovingProcess(false);
        }, 1);
    };

    const onSubmit = formMethods.handleSubmit(
        (data: ICreateChildForm) => {
            setIsFormChecked(true);
            createMultipleAccounts({
                variables: {
                    childs: data.childs.map(
                        ({ birthday, gender, ...formValues }) => ({
                            ...formValues,
                            birthday: dayjs(
                                birthday,
                                'DD/MM/YYYY',
                            ).toISOString(),
                            gender: gender?.value as GENDER,
                        }),
                    ),
                },
            });
        },
        (errors: DeepMap<ICreateChildForm, FieldError>) => {
            setIsFormChecked(true);
            const errorIndex = errors.childs?.findIndex((err) => err);
            if (errorIndex !== undefined && errorIndex > -1)
                handleChangePage(errorIndex);
        },
    );

    // HANDLE CHANGE PAGE AFTER CHANGE QUERY PARAM
    useEffect(() => {
        if (!currentPage) {
            setCurrentChild(0);
        } else if (+currentPage > total) {
            removeQuery('page');
        } else {
            setCurrentChild(+currentPage - 1);
        }
    }, [currentPage]);

    // REDIRECT TO RIGHT CHILD IF EMAIL ALREADY TAKEN
    useEffect(() => {
        const emails = formMethods
            .getValues()
            .childs.map((child) => child.email);

        for (const key in emails) {
            if (emailsAlreadyTaken.includes(emails[key])) {
                handleChangePage(+key);
                break;
            }
        }
    }, [emailsAlreadyTaken]);

    useEffect(() => {
        removeQuery('page');
    }, []);

    return (
        <SignupRegisterWrapper>
            <MobileStyledTip backgroundColor="course">
                Now it's time to register your child! We need your child email
                address so they can log in to personalised account. Once your
                child has signed up, they will receive an email to log in, set
                up an account, and sign up to the first experience!
            </MobileStyledTip>
            <InnerWrapper ref={cardElement}>
                {currentChild > 0 && <PreviousPageOrnament />}
                {total > 1 && currentChild < total - 1 && !removingProcess && (
                    <NextPageOrnament />
                )}
                <FormProvider {...formMethods}>
                    {fields.map((child, index) => (
                        <RegisterChild
                            child={child}
                            currentChild={index}
                            emailsAlreadyTaken={emailsAlreadyTaken}
                            handleChangePage={handleChangePage}
                            isVisible={index === currentChild}
                            key={child.id}
                            remove={handleRomoveChild}
                            removingProcess={removingProcess}
                            total={total}
                        />
                    ))}
                </FormProvider>
            </InnerWrapper>
            <ControlsWrapper isMaxChildren={total === MAX_CHILDREN}>
                {total < MAX_CHILDREN && (
                    <AddChild
                        onClick={() => isMobile && handleAddAnotherChild()}
                    >
                        <CircleIcon
                            name={'SkipForNowCircle'}
                            size={277}
                            sizeY={271}
                        />
                        Add another child
                        <AddChildButton
                            icon={{
                                type: 'Plus',
                                size: 17,
                            }}
                            onPress={handleAddAnotherChild}
                        />
                    </AddChild>
                )}
                <GetStartedButton disabled={loading} onPress={onSubmit}>
                    get started
                </GetStartedButton>
            </ControlsWrapper>
        </SignupRegisterWrapper>
    );
};

export default SignupRegisterPage;
