import styled from 'styled-components';

export const PermittedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-right: 85px;
    margin: 48px 0;
    width: 100%;
`;
