import React, { useEffect, useState } from 'react';
import {
    useChangeAvatarMutation,
    useDeleteAccountMutation,
} from '@state/settings/mutations';
import { useUserMeQuery } from '@state/auth/queries';
import ChangeEmailForm from '@domains/Child/Account/_forms/ChangeEmailForm/ChangeEmailForm';
import {
    PageText,
    PageTitle,
    SectionTitle,
} from '@domains/Child/Account/AccountPage/AccountPage.styled';
import {
    ActionButton,
    Actions,
    AvatarsWrapper,
    EditAvatarSectionWrapper,
    EditEmailSectionWrapper,
    Icon,
    ProfilPageWrapper,
    SectionButton,
    SectionDecorators,
    SectionDivider,
    SectionHeader,
    SectionSmallText,
    SectionText,
    SectionWrapper,
    StyledAvatar,
    StyledChosenAvatar,
} from './ProfilPage.styled';
import SetPasswordModal from '@domains/Child/Account/_modals/SetPasswordModal/SetPasswordModal';
import ChangePasswordModal from '@domains/Child/Account/_modals/ChangePasswordModal/ChangePasswordModal';

interface IProps {}

const ProfilPage: React.FC<IProps> = () => {
    const { data, loading } = useUserMeQuery();

    const [isAvatarEdit, setIsAvatarEdit] = useState(false);
    const [isEmailEdit, setIsEmailEdit] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [currentAvatar, setCurrentAvatar] = useState(0);
    const [changeAvatar] = useChangeAvatarMutation(() =>
        setIsAvatarEdit(false),
    );

    const isSetPassword = data?.me.hasPassword;

    useEffect(() => {
        setCurrentAvatar(data?.me?.avatar ?? 0);
    }, [data?.me?.avatar]);

    const handleSave = () => {
        changeAvatar({
            variables: {
                avatarId: currentAvatar,
            },
        });
    };

    const getPageText = () => {
        if (isAvatarEdit)
            return 'Here you can change your avatar. Choose one of the avatars prepared by us.';
        if (isEmailEdit)
            return "Need to change the email address you use to log into 8billionideas? You can do so by hitting 'edit' below. Remember, your adult's account will update to show the new email address - so speak to them if you are confused about making changes!";

        return 'Here you can change your avatar and your basic account information.';
    };

    return (
        <ProfilPageWrapper>
            <PageTitle>Profile</PageTitle>
            <PageText>{getPageText()}</PageText>
            {!loading && !isEmailEdit && (
                <SectionWrapper>
                    <SectionHeader>
                        <SectionTitle>Your avatar</SectionTitle>
                        {!isAvatarEdit && (
                            <SectionButton
                                buttonType={'secondary'}
                                onPress={() => setIsAvatarEdit(true)}
                                size={'small'}
                            >
                                Edit
                            </SectionButton>
                        )}
                    </SectionHeader>
                    <SectionDecorators>
                        <StyledChosenAvatar
                            avatar={currentAvatar ?? data?.me?.avatar}
                            avatarFrame={
                                isAvatarEdit
                                    ? 'AvatarChosenFrame'
                                    : 'AvatarFrame'
                            }
                            iconSelectedSize={164}
                            iconSize={110}
                            isActive
                        />
                        <Icon
                            name={'SoMuchFunDialogBox'}
                            size={328}
                            sizeY={128}
                        />
                    </SectionDecorators>
                </SectionWrapper>
            )}
            {isAvatarEdit && (
                <EditAvatarSectionWrapper>
                    <SectionText>
                        To edit an avatar choose one of those:
                    </SectionText>
                    <AvatarsWrapper>
                        {[...Array(8).keys()].map((item, index) => {
                            if (index === currentAvatar) return null;
                            return (
                                <StyledAvatar
                                    avatar={index}
                                    iconSize={75}
                                    key={index}
                                    onClick={() => setCurrentAvatar(index)}
                                />
                            );
                        })}
                    </AvatarsWrapper>
                    <Actions>
                        <ActionButton
                            buttonType={'secondary'}
                            onPress={() => {
                                setIsAvatarEdit(false);
                                setCurrentAvatar(data?.me?.avatar ?? 0);
                            }}
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            buttonType={'primary'}
                            onPress={() => handleSave()}
                        >
                            Save changes
                        </ActionButton>
                    </Actions>
                </EditAvatarSectionWrapper>
            )}

            {!isAvatarEdit && !isEmailEdit && <SectionDivider />}

            {!isAvatarEdit && (
                <SectionWrapper>
                    <SectionHeader>
                        <SectionTitle>Your email address</SectionTitle>
                        {!isEmailEdit && (
                            <SectionButton
                                buttonType={'secondary'}
                                onPress={() => setIsEmailEdit(true)}
                                size={'small'}
                            >
                                Edit
                            </SectionButton>
                        )}
                    </SectionHeader>
                    {!isEmailEdit && (
                        <SectionText>{data?.me.email}</SectionText>
                    )}
                </SectionWrapper>
            )}

            {isEmailEdit && (
                <EditEmailSectionWrapper>
                    <ChangeEmailForm closeEdit={() => setIsEmailEdit(false)} />
                </EditEmailSectionWrapper>
            )}

            {!isAvatarEdit && !isEmailEdit && <SectionDivider />}

            {!isAvatarEdit && !isEmailEdit && (
                <SectionWrapper>
                    <SectionHeader>
                        <SectionTitle>Your password</SectionTitle>
                        {isSetPassword ? (
                            <SectionButton
                                buttonType={'secondary'}
                                size={'small'}
                                onPress={() => setChangePasswordModal(true)}
                            >
                                Change
                            </SectionButton>
                        ) : (
                            <SectionButton
                                buttonType={'secondary'}
                                size={'small'}
                                onPress={() => setPasswordModal(true)}
                            >
                                Set password
                            </SectionButton>
                        )}
                    </SectionHeader>
                    {isSetPassword ? (
                        <SectionText>•••••••••••••••</SectionText>
                    ) : (
                        <SectionSmallText>
                            You don't have a password set. You can do this at
                            any time by clicking on the 'Set password' button
                        </SectionSmallText>
                    )}
                </SectionWrapper>
            )}

            {/* MODALS */}
            {passwordModal && (
                <SetPasswordModal
                    setIsModalOpen={() => setPasswordModal(false)}
                />
            )}

            {changePasswordModal && (
                <ChangePasswordModal
                    setIsModalOpen={() => setChangePasswordModal(false)}
                />
            )}
        </ProfilPageWrapper>
    );
};

export default ProfilPage;
