import styled, { css } from 'styled-components';
import { Button } from '@components/_universal';
import { LinkText } from '@components/_universal/Typography.styled';

export const AccountNavigationWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background: ${colors.background_secondary};
        border-bottom-left-radius: ${borderRadius.circle}px;
        border-top-left-radius: ${borderRadius.circle}px;
        border: 1px solid ${colors.background_tertiary};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 95px 70px 0;
    `,
);

export const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Link = styled(LinkText)(
    ({ theme: { borderRadius, colors, fontWeights } }) => css`
        color: ${colors.text};
        line-height: 1.75;
        margin-bottom: 25px;
        text-decoration: none;

        &.accountNavActive {
            font-weight: ${fontWeights.semiBold};
            position: relative;

            &::before {
                background: ${colors.primary};
                border-radius: ${borderRadius.circle}px;
                content: '';
                height: 12px;
                left: -16px;
                position: absolute;
                top: 50%;
                transform: translate(-100%, -50%);
                width: 12px;
            }
        }
    `,
);

export const LogoutButton = styled(Button)`
    margin-top: 25px;
    max-width: 150px;
`;
