import { gql, useQuery } from '@apollo/client';
import { GetStudentsListInput } from '@@types/graphql/GraphqlGlobalTypes';
import {
    StudentsListQuery,
    StudentsListQueryVariables,
} from '@@types/graphql/StudentsListQuery';

const STUDENTS_LIST_QUERY = gql`
    query StudentsListQuery($input: GetStudentsListInput!) {
        getStudentsList(input: $input) {
            users {
                id
                name
                surname
                status
                email
                class {
                    id
                    className
                    year
                }
                groups {
                    id
                    title
                }
            }
            count
        }
    }
`;

export default (input: GetStudentsListInput) =>
    useQuery<StudentsListQuery, StudentsListQueryVariables>(
        STUDENTS_LIST_QUERY,
        {
            fetchPolicy: 'network-only',
            variables: {
                input,
            },
        },
    );
