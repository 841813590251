import styled from 'styled-components';
import { Button, IconManager } from '@components/_universal';
import {
    BigHeader,
    DefaultText,
    Title,
} from '@components/_universal/Typography.styled';

export const DashboardParentEmptyChildWrapper = styled.div`
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 80px;
`;

export const DashboardEmptyInnerWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const EmptyTitle = styled(Title)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 8px;
`;

export const EmptySubtitle = styled(BigHeader)`
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 32px;
`;

export const EnumerationsListWrapper = styled.div`
    counter-reset: listCounter;
    flex: 1;
    margin-right: 88px;
`;

export const EnumerationsItemWrapper = styled.div`
    background: ${({ theme }) => theme.colors.background_quaternary};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    counter-increment: listCounter;
    padding: 32px 56px;

    & + & {
        margin-top: 24px;
    }
`;

export const TextBold = styled(DefaultText)`
    align-items: flex-start;
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.l}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 4px;
    position: relative;

    &:before {
        content: counter(listCounter) '.';
        font-size: ${({ theme }) => theme.fontSizes.l}px;
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        left: -32px;
        position: absolute;
    }
`;

export const TextSpan = styled(DefaultText)``;

export const DashboardEmptyAdditionalWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const DashboardParentEmptyOrnament = styled(IconManager)`
    justify-content: center;
`;

export const QuestionMarkIcon = styled(IconManager)`
    justify-content: center;
    margin-bottom: 4px;
`;

export const DashboardEmptySmallText = styled(DefaultText)`
    display: flex;
    flex-direction: column;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin-top: 48px;
`;

export const SendLink = styled.button`
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin: 0;
    outline: none;
    padding: 0;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        text-underline-position: under;
    }
`;

export const TextWrapper = styled.div``;

export const TextBoldQuestions = styled.p`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin: 28px 0;
`;
