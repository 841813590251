import React from 'react';
import { IMosaicCard } from '@@types/CommonTypes';
import { CONTENT_TYPE, USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { useSchoolQuery } from '@state/teachers/queries';
import { XMasonry } from 'react-xmasonry';
import getCardComponent from './getCardComponent';
import { MosaicContentWrapper } from './MosaicContent.styled';

interface MosaicContentProps {
    cards: IMosaicCard[];
    className?: string;
    isDifferentHover?: boolean;
    userType?: USER_TYPE;
}

const COLUMN_WIDTH = 280;

export const MosaicContent = ({
    cards = [],
    className,
    isDifferentHover = false,
    userType,
}: MosaicContentProps) => {
    const { data: school } = useSchoolQuery();

    const currentSchool = school?.getSchool.id;

    const hideCard = (card: IMosaicCard) => {
        const notLiveExperienceCard =
            card.type === CONTENT_TYPE.COURSE ||
            card.type === CONTENT_TYPE.STANDALONE_VIDEO;

        const notShowForSchools =
            !card.showToStudents && userType === USER_TYPE.TEACHER;

        const notAllSchoolsVisiblity =
            card.showToStudents &&
            card?.schoolsWithAccess?.length &&
            userType === USER_TYPE.TEACHER;

        if (notLiveExperienceCard) {
            return false;
        } else if (notShowForSchools) {
            return true;
        } else if (notAllSchoolsVisiblity) {
            const schoolIsInclude = card?.schoolsWithAccess?.some(
                (school) => school.id == currentSchool,
            );
            return !schoolIsInclude;
        } else {
            return false;
        }
    };

    return (
        <MosaicContentWrapper className={className}>
            <XMasonry center={false} targetBlockWidth={COLUMN_WIDTH}>
                {cards.map((card) =>
                    hideCard(card)
                        ? null
                        : getCardComponent(card, isDifferentHover, userType)[
                              card.type
                          ],
                )}
            </XMasonry>
        </MosaicContentWrapper>
    );
};

export default MosaicContent;
