import React from 'react';
import { Switch } from '@components/_form-elements';
import useNotificationSettingsForm from '@utils/hooks/useNotificationSettingsForm';
import {
    Icon,
    PageHeader,
    PageTitle,
    SectionTitle,
} from '@domains/Child/Account/AccountPage/AccountPage.styled';
import {
    NotificationSettingsPageWrapper,
    SectionDivider,
    SectionHeader,
    SectionSubtitle,
    SectionText,
    SectionWrapper,
} from './NotificationSettingsPage.styled';

const NotificationSettingsPage: React.FC = () => {
    const { handleChange, register } = useNotificationSettingsForm();

    return (
        <NotificationSettingsPageWrapper>
            <PageHeader>
                <PageTitle>Notification Settings</PageTitle>
                <Icon size={70} sizeY={40} name={'Trumpet'} />
            </PageHeader>
            <SectionWrapper>
                <SectionHeader>
                    <SectionTitle>in app notifications</SectionTitle>
                    <Switch
                        onChange={handleChange}
                        name={'appNotification'}
                        register={register}
                    />
                </SectionHeader>
                <SectionSubtitle>Experiences notifications</SectionSubtitle>
                <SectionText>
                    Notifications you receive 1 day, 1 hour, and 10 minutes
                    before the start of Live Experience.
                </SectionText>
                <SectionSubtitle>Feedback notifications</SectionSubtitle>
                <SectionText>
                    Notifications you receive after feedback on the homework
                    sent to live experience or self serve course has been added.
                </SectionText>
            </SectionWrapper>
            <SectionDivider />
            <SectionWrapper>
                <SectionHeader>
                    <SectionTitle>email notifications</SectionTitle>
                    <Switch
                        onChange={handleChange}
                        name={'emailNotification'}
                        register={register}
                    />
                </SectionHeader>
                <SectionSubtitle>Experiences notifications</SectionSubtitle>
                <SectionText>
                    Notifications you receive 1 day, 1 hour, and 10 minutes
                    before the start of live experience.
                </SectionText>
                <SectionSubtitle>Feedback notifications</SectionSubtitle>
                <SectionText>
                    Notifications you receive after feedback on the homework
                    sent to live experience or self serve course has been added.
                </SectionText>
                <SectionSubtitle>Ban notifications</SectionSubtitle>
                <SectionText>
                    Notifications you receive in case you have been banned.
                </SectionText>
            </SectionWrapper>
        </NotificationSettingsPageWrapper>
    );
};

export default NotificationSettingsPage;
