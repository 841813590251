import React from 'react';
import ChatWithMentor from '@components/_universal/ChatWithMentor/ChatWithMentor';
import {
    DescriptionText,
    DescriptionTitle,
    HeaderButton,
    InnerWrapper,
    LessonPageWrapper,
    ListTitle,
    LoaderWrapper,
    NavigationListWrapper,
    PageTitle,
    SectionHeader,
    SectionTitle,
    VideoWrapper,
} from './LessonPage.styled';
import { useParams } from 'react-router-dom';
import paths from '@shared/paths';
import useVideo from '@utils/hooks/useVideo';
import {
    InnerVideoPlayerWrapper,
    StyledIframe,
} from '@components/_universal/commonStyles.styled';
import VideoList from '@components/_universal/VideoList/VideoList';
import { useCourseQuery } from '@state/selfServeCourse/queries';
import { useHistory } from 'react-router';
import Loader from '@components/_universal/Loader/Loader';

interface IProps {}

const LessonPage: React.FC<IProps> = () => {
    const history = useHistory();
    const { courseId, courseLessonId } = useParams<{
        courseId: string;
        courseLessonId: string;
    }>();
    const { currentVideo, link } = useVideo(courseId, 'player', courseLessonId);

    const { data, loading: loadingLessons } = useCourseQuery(courseId);
    const courseData = data?.getCourse;

    return (
        <LessonPageWrapper>
            <PageTitle>{data?.getCourse.name}</PageTitle>
            <SectionHeader>
                <SectionTitle>{currentVideo?.name}</SectionTitle>
                <HeaderButton
                    buttonType={'secondary'}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        type: 'ChevronLeft',
                        isLeft: true,
                    }}
                    onPress={() => history.push(paths.course(courseId))}
                    size={'small'}
                >
                    Go back to course details
                </HeaderButton>
            </SectionHeader>
            <InnerWrapper>
                <VideoWrapper>
                    {!link && (
                        <LoaderWrapper>
                            <Loader size={'medium'} />
                        </LoaderWrapper>
                    )}
                    <InnerVideoPlayerWrapper>
                        {link && (
                            <StyledIframe
                                allowFullScreen
                                id={'player'}
                                src={link}
                            />
                        )}
                    </InnerVideoPlayerWrapper>
                    {!loadingLessons && (
                        <>
                            <DescriptionTitle>Description</DescriptionTitle>
                            <DescriptionText>
                                {courseData?.description}
                            </DescriptionText>
                        </>
                    )}
                </VideoWrapper>
                <ChatWithMentor />
                <NavigationListWrapper>
                    <ListTitle>Lessons</ListTitle>
                    <VideoList
                        loading={loadingLessons}
                        currentVideo={courseLessonId}
                        list={courseData?.lessons?.map((item) => ({
                            done: item.consumption?.consumed,
                            duration: item.video.duration,
                            id: item._id,
                            link: paths.course_lesson(courseId, item._id),
                            thumbnail: item.video.thumbnail || '',
                            title: item.name,
                        }))}
                        variant={'primary'}
                    />
                </NavigationListWrapper>
            </InnerWrapper>
        </LessonPageWrapper>
    );
};

export default LessonPage;
