import { gql, useMutation } from '@apollo/client';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import {
    UpdateStudentsGroup,
    UpdateStudentsGroupVariables,
} from '@@types/graphql/UpdateStudentsGroup';

export const UPDATE_STUDENTS_GROUP = gql`
    mutation UpdateStudentsGroup($input: UpdateStudentsGroupInput!) {
        updateStudentsGroup(input: $input) {
            id
            title
            schoolId
            classes {
                id
                year
                className
            }
        }
    }
`;

export const useUpdateStudentsGroup = (callback: () => void) => {
    return useMutation<UpdateStudentsGroup, UpdateStudentsGroupVariables>(
        UPDATE_STUDENTS_GROUP,
        {
            onCompleted: () => {
                callback();
                notify('You have successfully updated this group.')[
                    ToastTypes.SUCCESS
                ]();
            },
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
        },
    );
};
