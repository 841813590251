import { useEffect, useState } from 'react';
import { useCreateChatVisitorTokenQuery } from '@state/hubspot/queries';
import { useUserMeQuery } from '@state/auth/queries';
import { resolveEnv } from '@utils/helpers';

export default () => {
    useEffect(() => {
        const hubSpot = document.createElement('script');
        const hubSpotUrl = resolveEnv('REACT_APP_HUBSPOT_LINK');

        hubSpot.src = hubSpotUrl;
        hubSpot.id = 'hs-script-loader';
        hubSpot.async = true;
        hubSpot.defer = true;

        window.hsConversationsSettings = {
            loadImmediately: false,
        };

        document.body.appendChild(hubSpot);
    }, []);

    const { data } = useCreateChatVisitorTokenQuery();
    const { data: userMeData } = useUserMeQuery();
    const [showChatIcon, setShowChatIcon] = useState(true);

    const onConversationsAPIReady = async () => {
        // @ts-ignore
        window.hsConversationsSettings = {
            identificationEmail: userMeData?.me.email,
            identificationToken: data?.createChatVisitorToken,
        };
        // @ts-ignore
        window.HubSpotConversations.widget.load();
    };

    useEffect(() => {
        if (!userMeData?.me.email || !data?.createChatVisitorToken) return;
        (async () => {
            // @ts-ignore
            if (window.HubSpotConversations) {
                await onConversationsAPIReady();
            } else {
                // @ts-ignore
                window.hsConversationsOnReady = [onConversationsAPIReady];
            }

            setShowChatIcon(false);
        })();
    }, [userMeData?.me, data?.createChatVisitorToken]);

    return { showChatIcon };
};
