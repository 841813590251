import { gql, useMutation } from '@apollo/client';
import { GET_COURSE_QUERY } from '@state/selfServeCourse/queries/useCourseQuery';
import { GET_LIVE_EXPERIENCE_QUERY } from '@state/liveExperience/queries/useGetLiveExperienceQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';
import {
    DeleteHomeworkMutation,
    DeleteHomeworkMutationVariables,
} from '@@types/graphql/DeleteHomeworkMutation';

const DELETE_HOMEWORK_MUTATION = gql`
    mutation DeleteHomeworkMutation($homeworkId: Int!) {
        deleteHomework(homeworkId: $homeworkId) {
            id
        }
    }
`;

type ContentType = 'Course' | 'LiveExperience';

export default (contentId: string, contentType: ContentType) => {
    const queryType =
        contentType === 'Course' ? GET_COURSE_QUERY : GET_LIVE_EXPERIENCE_QUERY;

    return useMutation<DeleteHomeworkMutation, DeleteHomeworkMutationVariables>(
        DELETE_HOMEWORK_MUTATION,
        {
            onError: (error) => {
                notify(error.message)[ToastTypes.ERROR]();
            },
            update: (cache, { data }) => {
                if (!data) return;
                const query = cache.readQuery({
                    query: queryType,
                    variables: {
                        id: contentId,
                    },
                }) as object;

                if (query) {
                    const queryData = Object.values(query)[0];
                    const key = Object.keys(query)[0];
                    cache.writeQuery({
                        query: queryType,
                        variables: { id: contentId },
                        data: {
                            [key]: {
                                ...queryData,
                                homework: null,
                            },
                        },
                    });
                }
            },
        },
    );
};
