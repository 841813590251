import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';

export const EmptyActivityStateWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        align-items: center;
        border-radius: ${borderRadius.card}px;
        border: 1px solid ${colors.stroke};
        display: flex;
        justify-content: center;
        padding: 30px;
    `,
);

export const InfoText = styled(DefaultText)(
    ({ theme: { colors, fontWeights } }) => css`
        color: ${colors.text_secondary};
        font-weight: ${fontWeights.semiBold};
        margin: 0 0 0 40px;
    `,
);
