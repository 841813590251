import { gql, useQuery } from '@apollo/client';
import { GetAllTagsQuery } from '@@types/graphql/GetAllTagsQuery';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

const GET_ALL_TAGS_QUERY = gql`
    query GetAllTagsQuery {
        getAllTags {
            _id
            type
            name
        }
    }
`;

export default () => {
    return useQuery<GetAllTagsQuery>(GET_ALL_TAGS_QUERY, {
        onError: (error) => {
            notify(error.message)[ToastTypes.ERROR]();
        },
        variables: {},
    });
};
