import React from 'react';
import { Controller, Control, FieldError, FieldErrors } from 'react-hook-form';
import { IconName } from '@@types/CommonTypes';
import { SelectComponentsProps } from 'react-select/base';
import IconManager from '@components/_universal/IconManager/IconManager';
import {
    components,
    IndicatorProps,
    OptionProps,
    OptionsType,
    OptionTypeBase,
    ValueContainerProps,
} from 'react-select';
import {
    Icon,
    IndicatorWrapper,
    SelectLabel,
    SelectWrapper,
    StyledSelect,
    StyledSelectHolder,
} from './Select.styled';

interface IProps extends SelectComponentsProps {
    className?: string;
    closeMenuOnSelect?: boolean;
    control: Control;
    disabled?: boolean;
    errors?: FieldError | FieldErrors;
    hideSelectedOptions?: boolean;
    icon?: IconName;
    isLoading?: boolean;
    isMulti?: boolean;
    isSearchable?: boolean;
    label?: string;
    marginBottom?: number;
    noOptionsMessage?: Element;
    placeholder?: string;
    variant?: 'primary' | 'secondary';
    width?: number;
}

export const DropdownIndicator = ({
    selectProps,
}: IndicatorProps<OptionTypeBase, boolean>) => {
    const { menuIsOpen, variant } = selectProps;

    return (
        <IndicatorWrapper isOpen={menuIsOpen} variant={variant}>
            <IconManager
                rotate={menuIsOpen ? 180 : 0}
                size={24}
                name={'ChevronDown'}
                fill={menuIsOpen ? ['text_assistive'] : ['text']}
            />
        </IndicatorWrapper>
    );
};

export const OptionWithIcon = ({
    ...props
}: OptionProps<OptionTypeBase, boolean>) => {
    return (
        <components.Option {...props}>
            {props.data.label}
            {props.isSelected && (
                <Icon name={'Tick'} fill={['secondary']} size={20} />
            )}
        </components.Option>
    );
};

export const ValueContainer = ({
    children,
    ...props
}: ValueContainerProps<OptionTypeBase, boolean>) => {
    const renderInnerIcon = props.selectProps.renderInnerIcon;
    return (
        <components.ValueContainer {...props}>
            {!!children && renderInnerIcon && <>{renderInnerIcon()}</>}
            {children}
        </components.ValueContainer>
    );
};

const renderInnerIcon = (icon?: IconName) =>
    icon ? () => <Icon size={26} name={icon} fill={['text']} /> : undefined;

const Select: React.FC<IProps> = ({
    className,
    closeMenuOnSelect,
    control,
    disabled,
    hideSelectedOptions,
    icon,
    isLoading,
    isMulti = false,
    isSearchable,
    label,
    marginBottom,
    meta,
    noOptionsMessage,
    options,
    placeholder,
    variant = 'primary',
    width,
    ...rest
}) => {
    const getOptionValue = (option: { label: string; value: string }) =>
        option.value;
    return (
        <SelectWrapper {...{ className, marginBottom }}>
            {label && (
                <SelectLabel isSearchable={isSearchable}>{label}</SelectLabel>
            )}
            <StyledSelectHolder>
                <Controller
                    as={StyledSelect}
                    blurInputOnSelect={false}
                    className={`${className ?? ''} react-select-container`}
                    classNamePrefix={'react-select'}
                    closeMenuOnSelect={closeMenuOnSelect ?? true}
                    components={{
                        DropdownIndicator,
                        Option: OptionWithIcon,
                        ValueContainer,
                    }}
                    getOptionValue={getOptionValue}
                    hideSelectedOptions={hideSelectedOptions ?? false}
                    isDisabled={disabled}
                    isLoading={isLoading}
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    renderInnerIcon={renderInnerIcon(icon)}
                    noOptionsMessage={noOptionsMessage}
                    {...{ control, options, placeholder, variant, width }}
                    {...meta}
                    {...rest}
                />
            </StyledSelectHolder>
        </SelectWrapper>
    );
};

export default Select;
