import React from 'react';
import CoursesCarousel from '@domains/Child/Courses/_components/Courses/CoursesCarousel/CoursesCarousel';
import paths from '@shared/paths';
import { CONTENT_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { setLibraryFilter } from '@state/_redux/filters/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSaveForLaterContentQuery } from '@state/lists/queries';
import {
    Description,
    CoursesWrapper,
    StyledButton,
    CourseIcon,
    StyledTitle,
    TitleWrapper,
} from './Courses.styled';

interface IProps {}

const Courses: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const libraryFilters = useSelector((state) => state.filters.library);
    const { data } = useSaveForLaterContentQuery({ page: 1, limit: 100 });
    const isEmptyState = !data?.getSavedForLaterContent?.filter(
        (element) => element.__typename === 'Course',
    ).length;

    const handleRedirect = (filterBy: CONTENT_TYPE) => {
        dispatch(setLibraryFilter({ ...libraryFilters, type: [filterBy] }));
        history.push(paths.library);
    };

    return (
        <CoursesWrapper>
            <TitleWrapper>
                <CourseIcon name={'Course'} size={50} />
                <StyledTitle>Courses</StyledTitle>
            </TitleWrapper>
            {isEmptyState ? (
                <>
                    <Description>
                        It looks like you have not save any courses for later.
                        Remember, it's always easier to return to courses saved
                        in here. Browse through the courses offered for you.
                    </Description>
                    <StyledButton
                        onPress={() => handleRedirect(CONTENT_TYPE.COURSE)}
                    >
                        Go to courses list
                    </StyledButton>
                </>
            ) : (
                <CoursesCarousel />
            )}
        </CoursesWrapper>
    );
};

export default Courses;
