import { useEffect, useState } from 'react';
import { useAuthMeQuery } from '@state/auth/queries';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';

export default () => {
    const { data } = useAuthMeQuery();

    const [
        createTeacherPasswordModal,
        setCreateTeacherPasswordModal,
    ] = useState(false);

    const isTeacher = data?.me.type === USER_TYPE.TEACHER;
    const hasPassword = data?.me.hasPassword;

    useEffect(() => {
        if (!data) return;

        if (!hasPassword && isTeacher) {
            setTimeout(() => {
                setCreateTeacherPasswordModal(true);
            }, 4000);
        } else {
            setCreateTeacherPasswordModal(false);
        }
    }, [data, hasPassword]);

    return {
        createTeacherPasswordModal,
    };
};
