import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useCustomRegister from './useCustomRegister';
import {
    confirmPasswordValidation,
    passwordSymbolsValidation,
} from '@utils/shared/validations/formErrors';

interface IForm {
    currentPassword?: string;
    newPassword: string;
    confirmNewPassword: string;
}

export const validation = yup.object().shape({
    newPassword: passwordSymbolsValidation,
    confirmNewPassword: confirmPasswordValidation,
});

export default (onSubmitFunc: (values: IForm) => void, loading?: boolean) => {
    const [isValid, setIsValid] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const { errors, handleSubmit, register, watch } = useForm<IForm>({
        resolver: yupResolver(validation),
        // mode: 'onBlur',
    });
    const confirmNewPassword = watch('confirmNewPassword');
    const newPassword = watch('newPassword');

    const isValidConfirmPassword = newPassword === confirmNewPassword;

    const isButtonDisabled =
        loading ||
        !isValid ||
        !!errors.confirmNewPassword ||
        !!errors.newPassword ||
        !isValidConfirmPassword;

    const { refElement: passwordInputEl } = useCustomRegister<HTMLInputElement>(
        register,
    );

    const onSubmit = () => handleSubmit((values) => onSubmitFunc(values))();

    useEffect(() => {
        const onFocus = () => setIsFocused(true);

        passwordInputEl.current?.addEventListener('focus', onFocus);

        return () => {
            passwordInputEl.current?.removeEventListener('focus', onFocus);
        };
    }, []);

    return {
        confirmNewPassword,
        errors,
        isButtonDisabled,
        isFocused,
        isValid,
        isValidConfirmPassword,
        newPassword,
        onSubmit,
        passwordInputEl,
        register,
        setIsValid,
    };
};
