import { useRef } from 'react';

export default (activeClass: string = 'navigation-item-active') => {
    const navigationRef = useRef<HTMLDivElement>(null);

    const activeElement =
        navigationRef?.current?.children &&
        [...navigationRef?.current?.children]?.find((e) =>
            e?.className?.includes(activeClass),
        );

    activeElement && activeElement.scrollIntoView({ behavior: 'smooth' });

    return { navigationRef };
};
