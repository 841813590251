import styled from 'styled-components';

export const StyledButton = styled.button<{
    disabled: boolean;
    onClick?: () => void;
}>`
    background: none;
    border: none;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    outline: none;
    padding: 11px 30px;
    text-align: center;
    transition: all ${({ theme }) => theme.transitions.default}s;
    ${({ disabled, theme }) =>
        disabled && `color: ${theme.colors.text_assistive}`};
    ${({ onClick, theme }) => onClick && `cursor: ${theme.cursors.pointer}`};

    &:hover {
        text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
    }
`;
