import React from 'react';
import paths from '@shared/paths';
import { useHistory } from 'react-router-dom';
import {
    EmptyStateSearchLink,
    EmptyStateSearchPencilsImage,
    EmptyStateSearchText,
    EmptyStateSearchTitle,
    EmptyStateSearchWrapper,
} from './EmptyStateSearch.styled';

export interface IProps {}

const EmptyStateSearch: React.FC<IProps> = () => {
    const history = useHistory();

    return (
        <EmptyStateSearchWrapper>
            <EmptyStateSearchTitle>Oops...</EmptyStateSearchTitle>
            <EmptyStateSearchText center>
                We couldn’t find what you were looking for. Try searching for
                other keywords or try browsing the library.
            </EmptyStateSearchText>
            <EmptyStateSearchLink
                buttonType="secondary"
                size="small"
                onPress={() => history.push(paths.library)}
            >
                browse library
            </EmptyStateSearchLink>
            <EmptyStateSearchPencilsImage name="Pencils" size={112} />
        </EmptyStateSearchWrapper>
    );
};

export default EmptyStateSearch;
