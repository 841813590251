import {
    ButtonsWrapper,
    HeaderText,
    TableHeaderInner,
    TableHeaderWrapper,
    TableInfoWrapper,
} from '@components/_universal/Table/Table.styled';
import { StyledGroupButton } from './TableHeader.styled';

interface ButtonProps {
    onClick: (value: boolean) => void;
    title: string;
}

export interface TableHeaderProps {
    firstButton: ButtonProps;
    groupsListLoading: boolean;
    isGroupExists?: boolean;
    title: string;
}

export const TableHeader = ({
    firstButton,
    groupsListLoading,
    isGroupExists,
    title,
}: TableHeaderProps) => {
    return (
        <TableHeaderWrapper>
            <TableHeaderInner>
                <TableInfoWrapper>
                    <HeaderText>{title}</HeaderText>
                </TableInfoWrapper>
            </TableHeaderInner>
            {!(!isGroupExists && !groupsListLoading) && (
                <ButtonsWrapper>
                    <StyledGroupButton
                        buttonType={'primary'}
                        onPress={() => firstButton.onClick(true)}
                        size={'small'}
                    >
                        {firstButton.title}
                    </StyledGroupButton>
                </ButtonsWrapper>
            )}
        </TableHeaderWrapper>
    );
};
