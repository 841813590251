import { gql, useQuery } from '@apollo/client';
import {
    IsLiveExperienceFinishedQuery,
    IsLiveExperienceFinishedQueryVariables,
} from '@@types/graphql/IsLiveExperienceFinishedQuery';

export const IS_LIVE_EXPERIENCE_FINISHED_QUERY = gql`
    query IsLiveExperienceFinishedQuery($id: ID!) {
        getLiveExperience(id: $id) {
            finishedManually
        }
    }
`;

export default (id: string) =>
    useQuery<
        IsLiveExperienceFinishedQuery,
        IsLiveExperienceFinishedQueryVariables
    >(IS_LIVE_EXPERIENCE_FINISHED_QUERY, {
        variables: {
            id: id,
        },
    });
