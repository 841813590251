import React from 'react';
import { externalLinks } from '@shared/paths';
import { AgreementTextWrapper, StyledLinkText } from './AgreementText.styled';

export interface IProps {
    className?: string;
}

const AgreementText: React.FC<IProps> = ({ className }) => {
    return (
        <AgreementTextWrapper className={className}>
            By registering you agree to our{' '}
            <StyledLinkText href={externalLinks.terms} target={'_blank'}>
                Terms and Conditions
            </StyledLinkText>{' '}
            and{' '}
            <StyledLinkText
                href={externalLinks.privacy_policy}
                target={'_blank'}
            >
                Privacy Policy
            </StyledLinkText>
            .
        </AgreementTextWrapper>
    );
};

export default AgreementText;
