import React, { useEffect } from 'react';
import { changeLockLayout } from '@state/_redux/layout/actions';
import { ColorType } from '@@types/CommonTypes';
import { Content, ModalWrapper } from './Modal.styled';
import { useDispatch } from 'react-redux';

export interface IProps {
    backgroundColor?: ColorType;
    backgroundOpacity?: number;
    className?: string;
}

const Modal: React.FC<IProps> = ({
    backgroundColor = 'secondary',
    backgroundOpacity = 0.95,
    children,
    className,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeLockLayout(true));

        return () => {
            dispatch(changeLockLayout(false));
        };
    }, []);

    return (
        <ModalWrapper
            backgroundColor={backgroundColor}
            backgroundOpacity={backgroundOpacity}
            className={className}
        >
            <Content>{children}</Content>
        </ModalWrapper>
    );
};

export default Modal;
