import React from 'react';
import { Input } from '@components/_form-elements';
import { LoginFormWrapper, StyledButton } from './LoginLinkForm.styled';
import { useCustomRegister } from '@utils/hooks';
import { useForm } from 'react-hook-form';
import { validation } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldMessage from '@components/_HOC/FieldMessage/FieldMessage';
import useSendLoginEmailMutation from '@state/auth/mutations/useSendLoginEmailMutation';
import {
    ButtonRow,
    FormRow,
    InputWrapper,
} from '@domains/Auth/_forms/GlobalForm.styled';

interface IProps {}

interface IForm {
    email: string;
}

const LoginForm: React.FC<IProps> = () => {
    const { errors, register, handleSubmit, watch } = useForm<IForm>({
        resolver: yupResolver(validation),
        mode: 'onBlur',
    });
    const { refElement: inputEl } = useCustomRegister<HTMLInputElement>(
        register,
    );
    const email = watch('email');
    const [sendLoginEmail, { loading }] = useSendLoginEmailMutation(email);

    const onSubmit = handleSubmit((formValues: IForm) => {
        if (loading) return;
        inputEl.current?.blur();
        sendLoginEmail({
            variables: { email: formValues.email },
        });
    });

    return (
        <LoginFormWrapper noValidate onSubmit={onSubmit}>
            <FormRow>
                <InputWrapper>
                    <InputForm
                        backgroundTypeError
                        errors={errors.email}
                        inputType={'secondary'}
                        isValid={!errors.email}
                        label={`email address`}
                        marginBottom={16}
                        name={'email'}
                        placeholder={'e.g. joedoe@mail.com'}
                        register={inputEl}
                        staticPosition
                        type="email"
                    />
                </InputWrapper>
            </FormRow>
            <ButtonRow>
                <StyledButton onPress={() => {}}>
                    send me a magic link
                </StyledButton>
            </ButtonRow>
        </LoginFormWrapper>
    );
};

const InputForm = FieldMessage(Input);

export default LoginForm;
