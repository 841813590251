import { useState } from 'react';
import {
    useStudentsCountQuery,
    useStudentsListQuery,
} from '@state/students/queries';

interface FiltersProps {
    className?: string;
    groupId?: number;
    year?: string;
}

export const useStudentsPage = (groupId?: number) => {
    const PAGE_LIMIT = 25;
    const [paginationState, setPaginationState] = useState({
        pageIndex: 0,
        pageSize: PAGE_LIMIT,
    });

    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState<FiltersProps>({});

    const {
        data: studentsListData,
        loading: studentsListLoading,
        refetch,
    } = useStudentsListQuery({
        pagination: {
            offset: paginationState.pageIndex * paginationState.pageSize,
            limit: paginationState.pageSize,
        },
        search: {
            className: filters.className,
            groupId: groupId ?? filters.groupId,
            phrase: search,
            year: filters.year ? +filters.year : undefined,
        },
    });

    const {
        data: studentsCount,
        loading: studentsCountLoading,
    } = useStudentsCountQuery();

    return {
        filters,
        refetch,
        search,
        setFilters,
        setPaginationState,
        setSearch,
        studentsCount,
        studentsCountLoading,
        studentsFilteredCount: studentsListData?.getStudentsList.count,
        studentsList: studentsListData?.getStudentsList.users,
        studentsListData,
        studentsListLoading,
        studentsUploadCount: studentsCount?.getStudentsCount.count,
    };
};
