import React, { useRef } from 'react';
import { useOnClickOutside } from '@utils/hooks';
import ChangePasswordForm from '@domains/Child/Account/_forms/ChangePasswordForm/ChangePasswordForm';
import Modal from '@components/_universal/Modal/Modal';
import {
    ChangePasswordModalWrapper,
    IconWrapper,
    StyledTitle,
} from './ChangePasswordModal.styled';

export interface IProps {
    setIsModalOpen: (state: boolean) => void;
}

const ChangePasswordModal: React.FC<IProps> = ({ setIsModalOpen }) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsModalOpen(false));

    return (
        <Modal>
            <ChangePasswordModalWrapper {...{ ref }}>
                <StyledTitle>Change password</StyledTitle>

                <ChangePasswordForm closeModal={() => setIsModalOpen(false)} />

                <IconWrapper
                    cursor={'pointer'}
                    name={'Close'}
                    onClick={() => setIsModalOpen(false)}
                    size={24}
                />
            </ChangePasswordModalWrapper>
        </Modal>
    );
};

export default ChangePasswordModal;
