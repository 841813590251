import { gql, useQuery } from '@apollo/client';
import {
    RecommendedContentsUserQuery,
    RecommendedContentsUserQueryVariables,
} from '@@types/graphql/RecommendedContentsUserQuery';

const RECOMMENDED_CONTENTS_USER_QUERY = gql`
    query RecommendedContentsUserQuery(
        $paginationInput: SearchPaginationInput
    ) {
        getRecommendedContentsForUser(paginationInput: $paginationInput) {
            __typename
            ... on Course {
                _id
                featured
                name
                type
                thumbnail
            }
            ... on StandaloneVideo {
                _id
                featured
                name
                type
                thumbnail
            }
            ... on LiveExperience {
                _id
                endDate
                featured
                maxNoParticipants
                name
                noOfSignups
                schoolsWithAccess {
                    id
                }
                showToChildren
                showToStudents
                startDate
                thumbnail
                type
            }
        }
    }
`;

export default () => {
    return useQuery<
        RecommendedContentsUserQuery,
        RecommendedContentsUserQueryVariables
    >(RECOMMENDED_CONTENTS_USER_QUERY);
};
