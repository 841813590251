import React from 'react';
import {
    InfoTileSection,
    InfoTileSectionTitle,
    InfoTilesContent,
    InfoTilesWrapper,
    TileDate,
    TileIcon,
    TileTitle,
    TileValue,
} from './InfoTiles.styled';

interface IProps {
    lessons: number;
    totalTime: string;
}

const InfoTiles: React.FC<IProps> = ({ lessons, totalTime }) => {
    return (
        <InfoTilesWrapper>
            <InfoTileSection>
                <InfoTileSectionTitle>
                    Course lessons {'&'} duration
                </InfoTileSectionTitle>
                <InfoTilesContent>
                    <TileDate>
                        <TileValue>{lessons}</TileValue>
                        <TileTitle>Lessons</TileTitle>
                        <TileIcon name={'Lessons'} size={46} />
                    </TileDate>
                    <TileDate>
                        <TileValue>{totalTime}</TileValue>
                        <TileTitle>Total time</TileTitle>
                        <TileIcon name={'TimeDuration'} size={46} />
                    </TileDate>
                </InfoTilesContent>
            </InfoTileSection>
        </InfoTilesWrapper>
    );
};

export default InfoTiles;
