import { CONTENT_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { setLibraryFilter } from '@state/_redux/filters/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DashboardBottomCard from '@components/_universal/DashboardBottomCard/DashboardBottomCard';
import paths from '@shared/paths';
import {
    BackgroundDecorator,
    ElementsRow,
    IconWrapper,
    MoreElementsWrapper,
    StyledTitle,
} from './MoreElements.styled';

interface IProps {
    className?: string;
    showLiveExperience?: boolean;
}

const MoreElements: React.FC<IProps> = ({ className, showLiveExperience }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const libraryFilters = useSelector((state) => state.filters.library);

    const handleRedirect = (filterBy: CONTENT_TYPE) => {
        dispatch(setLibraryFilter({ ...libraryFilters, type: [filterBy] }));
        history.push(paths.library);
    };

    return (
        <MoreElementsWrapper className={className}>
            <StyledTitle>
                Want more? Browse the library
                <IconWrapper name={'Magnifier'} size={53} />
            </StyledTitle>
            <ElementsRow>
                <DashboardBottomCard
                    icon={'Course'}
                    name={'Courses'}
                    onPress={() => handleRedirect(CONTENT_TYPE.COURSE)}
                    size={32}
                />
                {showLiveExperience && (
                    <DashboardBottomCard
                        icon={'Live'}
                        name={'Experiences'}
                        onPress={() =>
                            handleRedirect(CONTENT_TYPE.LIVE_EXPERIENCE)
                        }
                        size={32}
                    />
                )}
                <DashboardBottomCard
                    icon={'Video'}
                    name={'Videos'}
                    onPress={() =>
                        handleRedirect(CONTENT_TYPE.STANDALONE_VIDEO)
                    }
                    size={32}
                />
            </ElementsRow>
            <BackgroundDecorator
                name={'DashboardDecoratorBottom'}
                size={515}
                sizeY={472}
            />
        </MoreElementsWrapper>
    );
};

export default MoreElements;
