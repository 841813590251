import styled from 'styled-components';
import { IconManager } from '@components/_universal';
import { DefaultText } from '@components/_universal/Typography.styled';

export const FeedbackWrapper = styled.div`
    align-items: flex-start;
    background: ${({ theme }) => theme.colors.background_primary};
    border-radius: ${({ theme }) => theme.borderRadius.card}px;
    display: flex;
    margin-top: 34px;
    padding: 40px 72px;
    width: 100%;
`;

export const MentorInfo = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
`;

export const MentorIcon = styled(IconManager)`
    margin-right: 16px;
`;

export const MentorWrapper = styled.div`
    display: grid;
`;

export const MentorName = styled(DefaultText)`
    display: block;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const MentorAdditionalInfo = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text_secondary};
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    margin: 0;
`;

export const MentorFeedback = styled.div`
    flex: 2;
    padding-left: 68px;
`;

export const MentorFeedbackText = styled(DefaultText)`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.m}px;
    margin-bottom: 34px;
`;
