import { gql, useQuery } from '@apollo/client';
import { ListOfCountriesQuery } from '@@types/graphql/ListOfCountriesQuery';

const LIST_OF_COUNTRIES_QUERY = gql`
    query ListOfCountriesQuery {
        getListOfCountries {
            countryName
            countryCode
        }
    }
`;

export default () => {
    return useQuery<ListOfCountriesQuery>(LIST_OF_COUNTRIES_QUERY);
};
