import React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { StudentsListQuery } from '@@types/graphql/StudentsListQuery';
import { useDeleteStudentsAccounts } from '@state/students/mutations';
import ModalConfirmation from '@components/_modals/ModalConfirmation/ModalConfirmation';

export interface IProps {
    onClose: () => void;
    refetch: () => Promise<ApolloQueryResult<StudentsListQuery>>;
    studentsIds: number[];
}

const DeleteStudentsModal: React.FC<IProps> = ({
    onClose,
    studentsIds,
    refetch,
}) => {
    const [deleteUsers, { loading }] = useDeleteStudentsAccounts(() => {
        onClose();
        refetch();
    });

    return (
        <ModalConfirmation
            closeOutside
            firstButton={{
                onClick: onClose,
                text: 'cancel',
                type: 'secondary',
            }}
            onClose={onClose}
            secondButton={{
                onClick: () =>
                    deleteUsers({
                        variables: {
                            input: {
                                studentsIds,
                            },
                        },
                    }),
                text: 'Delete',
                type: 'primaryError',
                disabled: loading,
            }}
            title="Delete students"
        >
            Are you sure you want to delete those students? After deletion you
            will not be able to undo this action.
        </ModalConfirmation>
    );
};

export default DeleteStudentsModal;
