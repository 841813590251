import React from 'react';
import { Button } from '@components/_universal';
import { StudentsListCsvDownloader } from '@components/_universal/StudentsListCsvDownloader/StudentsListCsvDownloader';
import { StyledTooltip, TooltipText } from './FakeTooltip.styled';

export const FakeTooltip = () => {
    return (
        <>
            <StyledTooltip>
                <TooltipText>
                    Download the CSV file with students data, once you do,
                    upload it to this form and then edit the list by removing
                    students which you wouldn't like to be assigned to this
                    group. Make sure to do NOT make any changes to the
                    downloaded CSV file.
                </TooltipText>
                <StudentsListCsvDownloader>
                    <Button
                        buttonType={'secondary'}
                        onPress={() => {
                            undefined;
                        }}
                        size={'small'}
                    >
                        Download students data CSV File
                    </Button>
                </StudentsListCsvDownloader>
            </StyledTooltip>
        </>
    );
};
