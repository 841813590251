import styled from 'styled-components';
import Footer from '@components/_global/Footer/Footer';
import ChildHeader from '@components/_global/Header/ChildHeader/ChildHeader';
import Navigation from '@components/_global/Navigation/ChildNavigation/ChildNavigation';
import ProgressSidebar from '@components/_global/ProgressSidebar/ProgressSidebar';

export const MainLayoutWrapper = styled.div`
    display: grid;
    grid-template-areas:
        'navigation content header'
        'navigation content sidebar'
        'navigation content sidebar';
    grid-template-columns: 120px 1fr 320px;
    grid-template-rows: 30px 1fr;
    min-height: 100%;
    width: 100%;
`;

export const HeaderBar = styled(ChildHeader)`
    grid-area: header;
`;

export const NavigationBar = styled(Navigation)`
    grid-area: navigation;
`;

export const Content = styled.div`
    display: grid;
    grid-area: content;
    grid-template-columns: auto;
    grid-template-rows: 1fr 90px;
`;

export const Sidebar = styled(ProgressSidebar)`
    grid-area: sidebar;
`;

export const FooterBar = styled(Footer)``;
