import React from 'react';
import { FieldError } from 'react-hook-form';
import {
    IconWrapper,
    TextareaInnerWrapper,
    TextareaWrapper,
    Label,
    StyledTextarea,
} from './Textarea.styled';

export type TextareaTypes = 'default' | 'secondary';

interface IProps {
    className?: string;
    disabled?: boolean;
    errors?: FieldError;
    inputType?: TextareaTypes;
    label?: string;
    marginBottom?: number;
    name: string;
    placeholder?: string;
    register?: any;
}

const Textarea: React.FC<IProps> = ({
    className,
    disabled,
    errors,
    inputType = 'default',
    label,
    marginBottom,
    name,
    placeholder,
    register,
}) => {
    return (
        <TextareaWrapper {...{ className, marginBottom }}>
            {label && <Label {...{ inputType }}>{label}</Label>}
            <TextareaInnerWrapper>
                <StyledTextarea
                    ref={register}
                    errors={!!errors}
                    {...{
                        disabled,
                        name,
                        placeholder,
                    }}
                />
                {errors && (
                    <IconWrapper
                        errors={!!errors}
                        fill={['error']}
                        name={'Alert'}
                        size={14}
                    />
                )}
            </TextareaInnerWrapper>
        </TextareaWrapper>
    );
};

export default Textarea;
