import * as yup from 'yup';
import {
    confirmPasswordValidation,
    emailValidation,
    passwordSymbolsValidation,
    selectValidation,
    textValidation,
} from '@utils/shared/validations/formErrors';

export const createAccountValidation = yup.object().shape({
    address: textValidation,
    city: textValidation,
    country: selectValidation,
    email: emailValidation,
    name: textValidation,
    surname: textValidation,
    zipCode: textValidation,
});

export const createPasswordValidation = yup.object().shape({
    confirmPassword: confirmPasswordValidation,
    newPassword: passwordSymbolsValidation,
});
