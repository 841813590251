import styled, { css } from 'styled-components';
import { IconManager } from '@components/_universal';

export const UploadWrapper = styled.div(
    ({ theme: { borderRadius, colors } }) => css`
        background: ${colors.background_quaternary};
        border-radius: ${borderRadius.card}px;
        display: flex;
        justify-content: center;
        margin: 4px 0 8px;
        padding: 20px 24px 16px;
        position: relative;
    `,
);

export const IconClose = styled(IconManager)(
    ({ theme: { cursors } }) => css`
        cursor: ${cursors.pointer};
        margin-left: 30px;
        position: absolute;
        right: 24px;
        top: 16px;
    `,
);

export const Info = styled.span(
    ({ theme: { colors } }) => css`
        color: ${colors.text_assistive};
    `,
);

export const GroupName = styled.span(
    ({ theme: { colors } }) => css`
        color: ${colors.secondary};
        display: inline-block;
        margin-right: 8px;

        &:not(:last-child) {
            ::after {
                content: ',';
            }
        }
    `,
);
