import styled, { css } from 'styled-components';
import { DefaultText } from '@components/_universal/Typography.styled';
import { IconManager } from '@components/_universal';
import { NavLink } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

export const NavigationWrapper = styled.div<{ borderLine?: boolean }>(
    ({ borderLine, theme: { colors } }) => css`
        border-right: 2px solid
            ${borderLine ? colors.background_primary : 'transparent'};
        height: 100%;
        padding: 28px 0;
        position: fixed;
        width: 120px;
    `,
);

export const NavigationLogoIcon = styled(IconManager)`
    margin-left: 30px;
`;

export const StyledSimpleBar = styled(SimpleBar)(
    ({ theme: { colors } }) => css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-height: 600px;
        width: 100%;

        .simplebar-scrollbar::before {
            background: ${colors.primary};
            opacity: 1;
        }
    `,
);

export const NavigationList = styled.div`
    width: 100%;
`;

export const NavigationItemWrapper = styled(NavLink)(
    ({ theme: { borderRadius, colors } }) => css`
        align-items: center;
        background: none;
        border: 0;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 120px;
        justify-content: center;
        position: relative;
        text-decoration: none;
        width: 100%;

        &:focus {
            outline: none;
        }

        &.navigation-item-active {
            &:before {
                background: ${colors.primary};
                border-radius: ${borderRadius.default}px;
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                width: 4px;
            }
        }
    `,
);

export const NavigationItemIcon = styled(IconManager)`
    justify-content: center;
`;

export const NavigationItemText = styled(DefaultText)(
    ({ theme: { fontSizes, fontWeights } }) => css`
        font-size: ${fontSizes.s}px;
        font-weight: ${fontWeights.semiBold};
        margin: 0;
        text-align: center;
    `,
);
