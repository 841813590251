import React from 'react';
import { AnimatedDotsWrapper, DotsContent } from './AnimatedDots.styled';
import { ColorType } from '@@types/CommonTypes';

export interface IProps {
    className?: string;
    color?: ColorType;
}

const AnimatedDots: React.FC<IProps> = ({
    className,
    color = 'text_white',
}) => {
    return (
        <AnimatedDotsWrapper className={className}>
            <DotsContent color={color} />
        </AnimatedDotsWrapper>
    );
};

export default AnimatedDots;
