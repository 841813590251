import React from 'react';
import Carousel from '@components/_universal/Carousel/Carousel';
import { SwiperSlide } from 'swiper/react';
import { SwiperWrapper, StyledTextBoxCard } from './VideosCarousel.styled';
import { useSaveForLaterContentQuery } from '@state/lists/queries';
import { SavedForLaterContentQuery_getSavedForLaterContent_StandaloneVideo } from '@@types/graphql/SavedForLaterContentQuery';
import paths from '@shared/paths';

interface IProps {}

const VideosCarousel: React.FC<IProps> = () => {
    const { data } = useSaveForLaterContentQuery({ page: 1, limit: 100 });
    const filteredData = data?.getSavedForLaterContent?.filter(
        (element) => element.__typename === 'StandaloneVideo',
    ) as SavedForLaterContentQuery_getSavedForLaterContent_StandaloneVideo[];

    const dataLength = filteredData.length ?? 0;

    return (
        <SwiperWrapper>
            <Carousel
                disabled={dataLength <= 2}
                nextButtonClass={'next--videos-save-for-later'}
                prevButtonClass={'prev--videos-save-for-later'}
                style={{ padding: '0 200px 20px 0' }}
                sliderRows={2}
            >
                {filteredData.map((item, index) => (
                    <SwiperSlide key={index}>
                        <StyledTextBoxCard
                            image={item.thumbnail}
                            link={paths.video_view(item._id)}
                            text={item.name}
                            type="STANDALONE_VIDEO"
                            width="100%"
                        />
                    </SwiperSlide>
                ))}
            </Carousel>
        </SwiperWrapper>
    );
};

export default VideosCarousel;
