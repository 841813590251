import React, { useEffect } from 'react';
import client from '@boot/apolloClient';
import DesktopAuthorizationTokenPage from './_desktop/DesktopAuthorizationTokenPage';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import MobileAuthorizationTokenPage from './_mobile/MobileAuthorizationTokenPage';
import useAuthTokenMutation from '@state/auth/mutations/useAuthTokenMutation';
import useMobile from '@utils/hooks/useMobile';

interface IProps {}

const AuthorizationTokenPage: React.FC<IProps> = () => {
    const token = new URLSearchParams(window.location.search).get('token');
    const isFirstLogin =
        new URLSearchParams(window.location.search).get('first-login') ===
        'true';

    const { isMobile } = useMobile();
    const [authToken] = useAuthTokenMutation(isFirstLogin);

    useEffect(() => {
        if (token) {
            LocalStorageManager.removeValue('sessionToken');
            LocalStorageManager.removeValue('refreshToken');
            client.clearStore();
            authToken({ variables: { token: token ?? '' } });
        }
    }, []);

    return isMobile ? (
        <MobileAuthorizationTokenPage />
    ) : (
        <DesktopAuthorizationTokenPage />
    );
};

export default AuthorizationTokenPage;
