import React from 'react';
import {
    Content,
    CourseViewLayoutWrapper,
    FooterBar,
    HeaderBar,
    NavigationBar,
} from './CourseViewLayout.styled';

interface IProps {}

export const CourseViewLayout: React.FC<IProps> = ({ children }) => {
    return (
        <CourseViewLayoutWrapper>
            <HeaderBar trialIcon />
            <NavigationBar borderLine />
            <Content>
                {children}
                <FooterBar isBackpack isLogo />
            </Content>
        </CourseViewLayoutWrapper>
    );
};
export default CourseViewLayout;
