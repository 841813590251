import styled from 'styled-components';

export const SubSlot = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 354px;
`;

export const SlotWrapper = styled.div`
    width: 255px;
`;
