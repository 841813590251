import React, { useState } from 'react';
import { Banner } from '@components/_universal/Banner/Banner';
import { DescriptionBox } from '@components/_universal/DescriptionBox/DescriptionBox';
import { DetailsPageHeader } from '@components/_universal/DetailsPageHeader/DetailsPageHeader';
import { RecommendTheContentModal } from '../Library/LibraryTeacherPage/_modals/RecommendTheContentModal/RecommendTheContentModal';
import { useCourse } from '@domains/Child/Course/CoursePage/useCourse';
import { useParams } from 'react-router-dom';
import { USER_TYPE } from '@@types/graphql/GraphqlGlobalTypes';
import { VideoThumbnail } from '@components/_universal/VideoThumbnail/VideoThumbnail';
import InfoTiles from '@components/_universal/InfoTiles/InfoTiles';
import Loader from '@components/_universal/Loader/Loader';
import {
    CourseLessonsNavigation,
    CoursePageWrapper,
    CourseWrapper,
    InnerWrapper,
    LoaderWrapper,
    PageContent,
    PageTitle,
} from './CourseTeacherPage.styled';

export const CourseTeacherPage = () => {
    const { courseId } = useParams<{ courseId: string }>();
    const {
        aboutVideoData,
        aboutVideoLoading,
        courseData,
        isVideoPlayed,
        loading,
        setIsVideoPlayed,
    } = useCourse(courseId);

    const [showModal, setShowModal] = useState(false);

    return (
        <CoursePageWrapper>
            <PageTitle>Course details</PageTitle>
            {!loading && (
                <DetailsPageHeader
                    iconName="CourseIcon"
                    onPress={() => setShowModal(true)}
                    recommendButton={true}
                    title={courseData?.name || ''}
                />
            )}
            {showModal && (
                <RecommendTheContentModal
                    contentType="Course"
                    setIsModalOpen={setShowModal}
                />
            )}
            <Banner background={courseData?.thumbnail} />
            <PageContent>
                <CourseWrapper>
                    <InnerWrapper>
                        {!loading && (
                            <InfoTiles
                                courseDuration={courseData?.duration}
                                lessonsCount={courseData?.lessons.length}
                                userType={USER_TYPE.TEACHER}
                            />
                        )}
                        {loading && (
                            <LoaderWrapper>
                                <Loader size={'medium'} />
                            </LoaderWrapper>
                        )}
                        {!loading && (
                            <DescriptionBox title="Course description">
                                {courseData?.description}
                            </DescriptionBox>
                        )}
                    </InnerWrapper>
                    <CourseLessonsNavigation>
                        <VideoThumbnail
                            aboutVideoData={aboutVideoData}
                            aboutVideoLoading={aboutVideoLoading}
                            isVideoPlayed={isVideoPlayed}
                            setIsVideoPlayed={setIsVideoPlayed}
                            userType={USER_TYPE.TEACHER}
                        />
                    </CourseLessonsNavigation>
                </CourseWrapper>
            </PageContent>
        </CoursePageWrapper>
    );
};
