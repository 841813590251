import styled, { css } from 'styled-components';

export const StudentsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
`;

export const TableBodyRow = styled.tr`
    cursor: pointer;
`;
