import styled, { keyframes } from 'styled-components';
import { ColorType } from '@@types/CommonTypes';
import { rgba } from 'polished';

export const ModalFadeIn = keyframes`
    from{
        opacity: 0
    }
    to{
        opacity: 1
    }
`;

export const ModalWrapper = styled.div<{
    backgroundColor: ColorType;
    backgroundOpacity: number;
}>`
    animation: ${ModalFadeIn} ${({ theme }) => theme.transitions.medium}s
        forwards;
    height: 100vh;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    will-change: opacity;
    z-index: 999;

    ${({ backgroundColor, backgroundOpacity, theme }) =>
        `background-color: ${rgba(
            theme.colors[backgroundColor],
            backgroundOpacity,
        )}`};
`;

export const Content = styled.div`
    align-items: center;
    display: flex;
    margin: 32px auto;
    max-width: 960px;
    min-height: calc(100vh - 64px);
    position: relative;
`;
