import React from 'react';
import { IBadge } from '@@types/CommonTypes';
import { setSearchModal } from '@state/_redux/layout/actions';
import { useDispatch } from 'react-redux';
import {
    CardHolderWrapper,
    CardHolderBadge,
    FeaturedIcon,
} from './CardHolder.styled';

interface CardHolderProps {
    badgeOptions?: IBadge;
    badgeText?: string;
    children: React.ReactNode;
    className?: string;
    featured?: boolean;
    link: string;
    onClick?: () => void;
    width: number | string;
}

const CardHolder = ({
    badgeOptions,
    badgeText,
    children,
    className,
    featured,
    link,
    onClick,
    width,
}: CardHolderProps) => {
    const dispatch = useDispatch();

    return (
        <CardHolderWrapper
            className={className}
            onClick={() => {
                onClick?.();
                dispatch(setSearchModal(false));
            }}
            to={link}
            width={width}
        >
            {children}
            {badgeText && badgeOptions && (
                <CardHolderBadge {...badgeOptions}>{badgeText}</CardHolderBadge>
            )}
            {featured && <FeaturedIcon name="FeaturedContentBadge" size={30} />}
        </CardHolderWrapper>
    );
};

export default CardHolder;
