import React from 'react';
import { Button, IconManager } from '@components/_universal';
import { setStudentsUploadModal } from '@state/_redux/layout/actions';
import { useCreateManyStudentsMutation } from '@state/students/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import UploadStudentsSummaryList from './_components/UploadStudentsSummaryList/UploadStudentsSummaryList';
import {
    CheckInfo,
    InfoText,
    InfoTitle,
    PageHeader,
    PageTitle,
    ResendButtonWrapper,
    UploadStudentsSummaryPageWrapper,
    UploadSummary,
    UploadText,
} from './UploadStudentsSummaryPage.styled';

export interface IProps {}

const UploadStudentsSummaryPage: React.FC<IProps> = () => {
    const errorStudentsList = useSelector(
        (state) => state.uploadCSV.errorStudentsList,
    );
    const location = useLocation<{ count: number }>();
    const totalAddedSudents = location.state.count;
    const dispatch = useDispatch();

    const [createManyStudents, { loading }] = useCreateManyStudentsMutation(
        true,
    );

    const handleUploadStudents = () => {
        dispatch(setStudentsUploadModal(true));
        createManyStudents({
            variables: {
                input: {
                    students: errorStudentsList.map((student) => ({
                        className: student.className,
                        email: student.email,
                        name: student.name,
                        surname: student.surname,
                        year: +student.year,
                    })),
                },
            },
        });
    };

    return (
        <UploadStudentsSummaryPageWrapper>
            <PageHeader>
                <PageTitle>Upload summary</PageTitle>
                {!!totalAddedSudents && (
                    <UploadSummary>
                        <IconManager name="CircleGreenTick" size={48} />
                        <UploadText>
                            You have successfully added {totalAddedSudents}{' '}
                            student{totalAddedSudents > 1 && 's'}.
                        </UploadText>
                    </UploadSummary>
                )}
            </PageHeader>
            <CheckInfo>
                <InfoTitle>Check and change</InfoTitle>
                <InfoText>
                    There was a problem adding the following students. Please
                    verify that the data you entered is correct, edit the data
                    or delete it. Resend the corrected data or go to your
                    dashboard.
                </InfoText>
            </CheckInfo>
            <UploadStudentsSummaryList />
            <ResendButtonWrapper>
                <Button
                    buttonType="secondary"
                    disabled={loading}
                    icon={{
                        activeColor: ['text'],
                        color: ['text'],
                        isLeft: true,
                        size: 24,
                        type: 'ResendIcon',
                    }}
                    onPress={handleUploadStudents}
                    size="small"
                >
                    resend
                </Button>
            </ResendButtonWrapper>
        </UploadStudentsSummaryPageWrapper>
    );
};

export default UploadStudentsSummaryPage;
