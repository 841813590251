import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

const ELEMENT_WIDTH = 255;
const ELEMENT_MARGIN = 32;

interface IProps {
    elementWidth?: number;
    elementMargin?: number;
}

export default ({
    elementWidth = ELEMENT_WIDTH,
    elementMargin = ELEMENT_MARGIN,
}: IProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [numberElements, setNumberElements] = useState(0);

    const calcElements = () => {
        const clientWith = ref?.current?.clientWidth;
        if (clientWith)
            setNumberElements(
                Math.trunc(
                    (clientWith - elementWidth) /
                        (elementWidth + elementMargin) +
                        1,
                ),
            );
    };

    useEffect(() => {
        calcElements();

        const handleResize = debounce(calcElements, 100);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { ref, numberElements };
};
