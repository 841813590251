import { gql, useMutation } from '@apollo/client';
import {
    ResendEmailMutation,
    ResendEmailMutationVariables,
} from '@@types/graphql/ResendEmailMutation';
import { notify } from '@components/ToastNotification/ToastNotification';
import { ToastTypes } from '@@types/CommonTypes';

const RESEND_EMAIL_MUTATION = gql`
    mutation ResendEmailMutation($email: String!) {
        resendConfirmationEmail(email: $email)
    }
`;

export default () => {
    return useMutation<ResendEmailMutation, ResendEmailMutationVariables>(
        RESEND_EMAIL_MUTATION,
        {
            onCompleted: () => {
                notify(
                    'Congratulations! The invitation has been successfully resent.',
                )[ToastTypes.SUCCESS]();
            },
            onError: () => {
                notify(
                    'Unfortunately, we cannot resend the invitation now. Please try again later!',
                )[ToastTypes.ERROR]();
            },
        },
    );
};
