import { gql, useMutation } from '@apollo/client';
import {
    CreateChildAccountMutation,
    CreateChildAccountMutationVariables,
} from '@@types/graphql/CreateChildAccountMutation';
import { CHILDS_LIST_QUERY } from '@state/childManagement/queries/useChildsListQuery';
import { SUBSCRIPTION_CHILDS_LIST_QUERY } from '@state/childManagement/queries/useSubscriptionChildsListQuery';
import { ToastTypes } from '@@types/CommonTypes';
import { notify } from '@components/ToastNotification/ToastNotification';

const CREATE_CHILD_ACCOUNT_MUTATION = gql`
    mutation CreateChildAccountMutation($input: CreateChildAccount!) {
        createChildAccount(input: $input) {
            child {
                id
            }
        }
    }
`;

export default (onCompleted?: () => void) =>
    useMutation<
        CreateChildAccountMutation,
        CreateChildAccountMutationVariables
    >(CREATE_CHILD_ACCOUNT_MUTATION, {
        onCompleted: () => {
            onCompleted?.();
            notify('Congratulations! New child has been successfully added.')[
                ToastTypes.SUCCESS
            ]();
        },
        onError: (error) => {
            const message =
                error.message === 'Email is already taken'
                    ? 'E-mail is already taken. Please use a different one.'
                    : 'Unfortunately, we cannot add new child now. Please try again later!';
            notify(message)[ToastTypes.ERROR]();
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: CHILDS_LIST_QUERY,
            },
            {
                query: SUBSCRIPTION_CHILDS_LIST_QUERY,
            },
        ],
    });
