import React from 'react';
import { ArticleType, ArticleWrapper, StyledTitle } from './Article.styled';

// TODO: add proper type form backend
export enum Content {
    COURSE = 'COURSE',
    STANDALONE_VIDEO = 'STANDALONE_VIDEO',
}
interface IProps {
    className?: string;
    link: string;
    type: Content;
}

const ArticleName = {
    [Content.COURSE]: 'Course',
    [Content.STANDALONE_VIDEO]: 'Video',
};

const Article: React.FC<IProps> = ({ children, className, link, type }) => {
    return (
        <ArticleWrapper className={className} to={link}>
            <ArticleType>{ArticleName[type]}</ArticleType>
            <StyledTitle>{children}</StyledTitle>
        </ArticleWrapper>
    );
};

export default Article;
