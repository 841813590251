import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import {
    addErrorStudentsList,
    addStudentsList,
    clearStudentsList,
    deleteFromErrorStudentsList,
    editErrorStudentsList,
    editStudentsList,
} from './actions';

export default createReducer(initialState)
    .handleAction(addStudentsList, (state, { payload }) => ({
        ...state,
        studentsList: payload,
    }))
    .handleAction(clearStudentsList, (state) => ({
        ...state,
        studentsList: [],
    }))
    .handleAction(editStudentsList, (state, { payload }) => ({
        ...state,
        studentsList: state.studentsList.map((student) =>
            student.id === payload.id ? payload : student,
        ),
    }))
    .handleAction(addErrorStudentsList, (state, { payload }) => ({
        ...state,
        errorStudentsList: payload,
    }))
    .handleAction(deleteFromErrorStudentsList, (state, { payload }) => ({
        ...state,
        errorStudentsList: state.errorStudentsList
            .filter((student) => !payload.includes(student.id))
            .map((student, index) => ({
                ...student,
                id: index++,
                number: index++,
            })),
    }))
    .handleAction(editErrorStudentsList, (state, { payload }) => ({
        ...state,
        errorStudentsList: state.errorStudentsList.map((student) =>
            student.id === payload.id ? payload : student,
        ),
    }));
